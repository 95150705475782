import React from "react";

export default function inputFieldGenerator(fieldMetaData, value, onChange) {
  switch (fieldMetaData?.fieldDataType.trim()) {
    case "text":
      if (fieldMetaData.entityAttributeFieldKey == "description") {
        return (
          <>
            <label>
              {fieldMetaData.fieldName}
              {fieldMetaData.isRequired === 1 && (
                <span className="required">*</span>
              )}
            </label>

            <textarea
              value={value}
              onChange={(e) =>
                onChange(
                  fieldMetaData.entityAttributeFieldKey,
                  e.target.value,
                  e
                )
              }
              required={fieldMetaData.isRequired === 1}
              type="text"
              name={fieldMetaData.entityAttributeFieldKey}
              defaultValue={fieldMetaData.fieldValue}
              className="form-control col-12"
            />
          </>
        );
      } else {
        return (
          <>
            <label>
              {fieldMetaData.fieldName}
              {fieldMetaData.isRequired === 1 && (
                <span className="required">*</span>
              )}
            </label>
            <input
              value={value}
              onChange={(e) =>
                onChange(
                  fieldMetaData.entityAttributeFieldKey,
                  e.target.value,
                  e
                )
              }
              required={fieldMetaData.isRequired === 1}
              type="text"
              name={fieldMetaData.entityAttributeFieldKey}
              defaultValue={fieldMetaData.fieldValue}
              className="form-control col-12"
            />
          </>
        );
      }

    case "textarea":
      return (
        <>
          <label>
            {fieldMetaData.fieldName}
            {fieldMetaData.isRequired === 1 && <span className="required">*</span>}
          </label>
          <textarea
            value={value}
            onChange={(e) =>
              onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)
            }
            required={fieldMetaData.isRequired === 1}
            type="text"
            name={fieldMetaData.entityAttributeFieldKey}
            defaultValue={fieldMetaData.fieldValue}
            className="form-control col-12"
          />
        </>
      );

    case "integer":
      return (
        <>
          <label>
            {fieldMetaData.fieldName}
            {fieldMetaData.isRequired === 1 && <span className="required">*</span>}
          </label>
          <input
            type="number"
            name={fieldMetaData.entityAttributeFieldKey}
            defaultValue={fieldMetaData.fieldValue}
            value={value}
            required={fieldMetaData.isRequired === 1}
            onChange={(e) =>
              onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)
            }
            className="form-control col-12"
          />
        </>
      );

    case "decimal":
      return (
        <>
          <label>
            {fieldMetaData.fieldName}
            {fieldMetaData.isRequired === 1 && <span className="required">*</span>}
          </label>
          <input
            type="number"
            name={fieldMetaData.entityAttributeFieldKey}
            defaultValue={fieldMetaData.fieldValue}
            value={value}
            required={fieldMetaData.isRequired === 1}
            onChange={(e) =>
              onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)
            }
            className="form-control col-12"
          />
        </>
      );

    case "boolean":
      return (
        <>
          <label>
            {fieldMetaData.fieldName}
            {fieldMetaData.isRequired === 1 && <span className="required">*</span>}
          </label>
          <input
            type="text"
            name={fieldMetaData.entityAttributeFieldKey}
            defaultValue={fieldMetaData.fieldValue}
            value={value}
            required={fieldMetaData.isRequired === 1}
            onChange={(e) =>
              onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)
            }
            className="form-control col-12"
          />
        </>
      );

    case "datetime":
      return (
        <>
          <label>
            {fieldMetaData.fieldName}
            {fieldMetaData.isRequired === 1 && <span className="required">*</span>}
          </label>
          <input
            type="date"
            name={fieldMetaData.entityAttributeFieldKey}
            defaultValue={fieldMetaData.fieldValue}
            value={value}
            required={fieldMetaData.isRequired === 1}
            onChange={(e) =>
              onChange(fieldMetaData.entityAttributeFieldKey, e.target.value, e)
            }
            className="form-control col-12"
          />
        </>
      );

    default:
      return null;

    // We have to add more type based on requirement
  }
}
