import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import company from "../../public/images/defaultJob.png";
import price from "../../public/images/price.svg";
import jobloaction from "../../public/images/joblocation.svg";
import Jobpkg from "../../public/images/job-pkg.svg";
import RightArrow from "../../public/img/rightArrow.png";
import { Link, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getJobDetailsLanding } from "../../actions/common";

const Intersted = (props) => {

    const { entityId , userProfileId } = useParams();
    const [loading,setLoading] = useState(false);
    const [job,setJob] = useState({})

    useEffect(()=>{
        getJobDetails()
    },[])

    const getJobDetails = () => {

        setLoading(true);
        const params = {
            entityId : entityId,
            userProfileId : userProfileId
        }

        props.getJobDetailsLanding(params, (res) => {
          setLoading(false);
          if (res.status) {
            console.log("Jobs Details", res.data);
            setJob(res.data)
          } else {
            setJob({});
          }
        });

    }

    return (
        <>
            <section className="Apply_jobs">
                <div className="container">
                    {loading ? (
                    <>
                        <div className="row" style={{ width: "100%", height: "80vh" }}>
                        <div
                            className="col-12"
                            style={{ width: "100%", height: "100%" }}
                        >
                            <div
                            style={{
                                width: "100%",
                                height: "100%",
                                textAlign: "center",
                                verticalAlign: "middle",
                                lineHeight: "40vh",
                            }}
                            >
                            <div
                                className="spinner-grow"
                                role="status"
                                style={{ background: "#006838" }}
                            ></div>
                            </div>
                        </div>
                        </div>
                    </>
                    )
                    :(
                    <>
                        <div className="Apply_Nav">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item" aria-current="page"><Link to="/" >Home</Link></li>
                                    <li className="breadcrumb-item" aria-current="page"><Link to="/search-job" >Search Job</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{job.jobDetail?.title} || {job.jobDetail?.name}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="interested_main">
                            <div className="interested_nav">
                                <div className="interested_icon">
                                    <img src={job.userProfileImageUrl ?? company} alt="logo" className="img-fluid" />
                                </div>
                                <div className="interested_content">
                                    <h5 className="job_heading">{job.jobDetail?.title} || {job.jobDetail?.name}</h5>
                                    <span className="job_Subheading">{job.jobProfile?.entityName}</span>
                                    <div className="job_location">
                                        <a>
                                            <img src={jobloaction} alt="logo" />
                                            <span>{job.jobProfile?.address}</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="interested_button">
                                    <Link to={`/interested/${entityId}/${userProfileId}`}><button className="sliderFormBtn" type="submit">I'm interested</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="Company_description">
                            <div className="company_heading">
                                <img src={Jobpkg} alt="icon" className="img-fluid" />
                                <span>Company Description</span>
                            </div>
                            <div className="company_content">
                                <p>{job.jobDetail?.description}</p>
                            </div>
                            {/* <div className="company_heading">
                                <span>Responsibilities</span>
                            </div>
                            <div className="company_content">
                                <ul className="JobDescriptionListing">
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Competent professional with over 1 - 6 years of rich experience in Recruiting, Temp Staffing.</span>
                                    </li>
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Strong expertise in Talent Acquisition sites.</span>
                                    </li>
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Meeting TA demand and supply</span>
                                    </li>
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Proficiency with Boolean Search techniques & XRay searching capabilities.</span>
                                    </li>
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Proven expertise in building and managing large-scale Recruiting & Staffing Operations.</span>
                                    </li>
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Ability to meet daily targets.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="company_heading">
                                <img src={Jobpkg} alt="icon" className="img-fluid" />
                                <span>You Have</span>
                            </div>
                            <div className="company_content">
                                <ul className="JobDescriptionListing">
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Competent professional with over 1 - 6 years of rich experience in Recruiting, Temp Staffing.</span>
                                    </li>
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Strong expertise in Talent Acquisition sites.</span>
                                    </li>
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Meeting TA demand and supply</span>
                                    </li>
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Proficiency with Boolean Search techniques & XRay searching capabilities.</span>
                                    </li>
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Proven expertise in building and managing large-scale Recruiting & Staffing Operations.</span>
                                    </li>
                                    <li>
                                        <img src={RightArrow} alt="arrow" className="img-fluid" />
                                        <span>Ability to meet daily targets.</span>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="interested_button">
                                <Link to={`/interested/${entityId}/${userProfileId}`}><button className="sliderFormBtn" type="submit">I'm interested</button></Link>
                            </div>
                        </div>
                    </>
                    )}
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
    getJobDetailsLanding:bindActionCreators(getJobDetailsLanding,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Intersted);
