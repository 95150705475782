import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import contact_left from "../public/images/contact_left.png";
import left_small from "../public/images/left_small.png";
import { getContactUsTypeActives, contactUs } from "../actions/common";
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ContactUs = (props) => {
  const history = useHistory();
  const divRef = useRef(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNo, setPhoneNo]= useState("");
  const [phoneCode, SetPhoneCode]=useState("");
  const [countryName, setCountryName]=useState("");
  const [contactUsCategory, setContactUsCategory] = useState([]);
  const [contactUsTypeId, setContactUsTypeId] = useState();
  const [loading, setLoading] = useState(false);

  const changeInputValue = (e, flag) => {
    let value = e.target.value;
    if (flag === "firstName") {
      setFirstName(value.trimStart());
    }
    else if (flag === "lastName") {
      setLastName(value.trimStart());
    }
    else if (flag === "email") {
      setEmail(value.trim());
    }
    else if (flag === "category") {
      setContactUsTypeId(value);
    }
    else if (flag === "subject") {
      setSubject(value.trimStart());
    }
    else if (flag === "message"){
      setMessage(value.trimStart());
    } 
  };

const handleOnSubmit = (e) => {
  e.preventDefault();
  if (!firstName) {
    toast.error("Please enter your first name");
    return;
  }
  else if (!lastName) {
    toast.error("Please enter your last name");
    return;
  }
  else if (!email) {
    toast.error("Please enter your email address");
    return;
  }
  else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
    toast.error("Please enter valid email id");
    return;
  }
  else if (!phoneNo) {
    toast.error("Please enter your phone number");
    return;
  }
  else if (phoneNo.length <= 4) {
    toast.error("Please enter correct phon no.");
    return;
  }
  else if (!contactUsTypeId) {
    toast.error("Please select your category");
    return;
  }
  else if (!subject) {
    toast.error("Please enter your subject");
    return;
  }
  else if (!message) {
    toast.error("Please enter your message");
    return;
  }

  let params = {
    userIdHash: props.auth.token,
    firstname: firstName,
    lastname: lastName,
    email: email,
    phoneCode: phoneCode,
    phoneNo: phoneNo,
    countryName: countryName,
    contactUsTypeId: contactUsTypeId,
    subject: subject,
    message: message,
    city: "",
    contactUs: "",
    state: "",
  }
   console.log("params",params);
  setLoading(true);
  props.contactUs(params, res => {
    setLoading(false);
    if( res.status){
      toast.success(res.message); 
      setFirstName("");
      setLastName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setPhoneNo("");
      setContactUsTypeId("");
      }
      else {
      toast.error(res.message);
      }
  },
  err => {
    setLoading(false);
    toast.error("Something went wrong!");
  })
};

const onChangePhoneNumber = (value, data, event, formattedValue) => {
  let phoneCode = data.dialCode;
  let countryName = data.name;
  let phoneNumber = value.slice(data.dialCode.length);
  setPhoneNo(phoneNumber);
  SetPhoneCode(phoneCode); 
  setCountryName(countryName);
  //console.log(data.dialCode, "==========data.dialCode", value.slice(data.dialCode.length) , "data", data);
}

  const getContactUsTypeCategory = () => {
    props.getContactUsTypeActives({}, (res) => {
      console.log("res",res);
      if (res.status) {
        setContactUsCategory(res.data);
      } else {
        setContactUsCategory([]);
      }
    });
  };

  
  useEffect(() => {
    getContactUsTypeCategory();
    // divRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="container py-5 my-5">
      <div ref={divRef} />
        <div className="row">
          <div className="col-6">
            <div className="left_img5">
              <img src={contact_left} style={{height:'940px'}} />
            </div>
          </div>
          <div className="col-6">
            <div className="contact_right7" style={{ height: "fit-content" }}>
              <h4>Contact Us</h4>
              <div className="email_outer">
                <label>First Name</label>
                <input type="text" placeholder="Enter here" value={firstName} onChange={(e) => { changeInputValue(e, 'firstName') }} />
              </div>
              <div className="email_outer">
                <label>Last Name</label>
                <input type="text" placeholder="Enter here" value={lastName} onChange={(e) => { changeInputValue(e, 'lastName') }} />
              </div>
              <div className="email_outer">
                <label>Email</label>
                <input type="text" placeholder="Enter here" value={email} onChange={(e) => { changeInputValue(e, 'email') }}/>
              </div>
              <div className="email_outer">
                <label>Phone Number</label>
                {/* <input type="number" placeholder="Enter here" value={phone} onChange={(e) => { changeInputValue(e, 'phone') }}/> */}
                <PhoneInput
                        //className="form-control"
                        //international
                        country="us"
                        //value={phoneNo}
                        placeholder="Phone no."
                        onChange={(value, data, event, formattedValue) => {onChangePhoneNumber(value, data, event, formattedValue)}}
                      />
              </div>
              <div className="email_outer custom_pu56">
                <label>Select Category</label>
                <select
                  onChange={(e) => {changeInputValue(e, "category") }}
                >
                  <option selected disabled>
                    Select Category
                  </option>
                  {contactUsCategory.length && (
                    <>
                      {contactUsCategory.map((value, index) => {
                        return (
                          <>
                            <option value={value.id}>{value.name}</option>
                          </>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
              <div className="email_outer">
                <label>Subject</label>
                <input type="text" placeholder="Enter here" value={subject} onChange={(e) => { changeInputValue(e, 'subject') }}/>
              </div>
              <div className="email_outer custom_pu56 dect6">
                <label>Message</label>
                <textarea placeholder="Enter here" value={message} onChange={(e) => { changeInputValue(e, 'message') }}></textarea>
              </div>
              <div className="forgott6">
                      <button className="btn" onClick={(e) => {handleOnSubmit(e)}}>
                        {loading && (
                          <span className="spinner-grow "></span>
                        )}
                        &nbsp;&nbsp;
                          <span>Submit</span>
                      </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getContactUsTypeActives: bindActionCreators(getContactUsTypeActives,dispatch),
  contactUs: bindActionCreators(contactUs, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
