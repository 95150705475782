import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getServiceTermList } from "../../actions/common"
import notification from "../../public/images/notification.png";
import profile from "../../public/images/profile.png";
import FooterLogo from "../../public/images/footer-logo.svg";
import WhiteInsta from "../../public/images/white_insta_icon_footer.svg";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";
import { bindActionCreators } from "redux";

const LandingFooter = (props) => {
  const history = useHistory();

  const [footerList, setFooterList] = useState([]);
  
  const getFooterList = () => {
    props.getServiceTermList({}, res => {
      if(res.status) {
        setFooterList(res.data)
      } else {
        setFooterList([])
      }
    })
  }

  const footerMenuClicked = (e, value) => {
    //console.log("value",value);
    e.preventDefault();
    if(value.serviceTag === 'contact_us' && value.isContactUsFlag === true) {
      history.push('/contactUs')
    }
    else if(value.serviceTag === 'terms_condition' && value.isContactUsFlag == false) {
      history.push({pathname : '/terms_n_conditions', state : value})
    }
    else if(value.serviceTag === 'privacy_policy' && value.isContactUsFlag == false) {
      history.push({pathname : '/privacy_policy', state : value})
    }
  }

  useEffect(() => {
    getFooterList()
  }, [])


  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="footer_logo">
                <a href="#">
                  <img src={FooterLogo} alt="" />
                </a>
              </div>
            </div>
            <div className="col-md-5 moblie_width">
              <h4> MENU</h4>
              <ul className="footer_menu">
                <li>
                  <a href="#">About </a>
                </li>
              </ul>
            </div>

            <div className="col-md-2 moblie_text_c">
              <h4> Social </h4>
              <ul className="footer_socail">
                <li>
                  <a href="#">
                  <i className="fab fa-facebook-f"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="#">
                  <i className="fab fa-twitter"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={WhiteInsta} alt="" />{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright">
            <div className="row">
              <div className="col-md-6">
                <div className="copyright_text">
                  Copyright © 2022 Timelyhire. All Rights Reserved.
                </div>
              </div>
              <div className="col-md-6">
                <ul className="copyright_link">
                  {footerList.length ? (
                  <>
                  {footerList.map((value, index) => {
                    return (
                      <li key={index}>
                        <a href="#" onClick={(e) => {footerMenuClicked(e, value)}}> {value.name}</a>
                      </li>  
                    )
                  })}
                  </>) : (<></>)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getServiceTermList: bindActionCreators(getServiceTermList, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingFooter);
