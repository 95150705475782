import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";
import company from "../../public/images/CompanyLogo2.svg";
import Delete from "../../public/images/delete.svg";
import price from "../../public/images/price.svg";
import jobloaction from "../../public/images/joblocation.svg";
import RightArrow from "../../public/img/rightArrow.png";
import jobPkg from "../../public/images/job-pkg.svg";
import { getUserFavouriteJobList,removeFromFavouriteList } from "../../actions/common";
import zeroFollowingImage from "../../public/images/zeroFollowingImageSmall.png";
import { bindActionCreators } from "redux";
import CompanyLogo1 from "../../public/images/defaultJob.png";
import $ from "jquery";
import { toast } from "react-toastify";

const FavJob = (props) => {

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState([]);

    useEffect(()=>{
        getUserFavouriteJobs()
    },[]);

    const getUserFavouriteJobs = () => {
        setLoading(true);
        props.getUserFavouriteJobList({userIdHash: props.auth.userProfile.userIdHash}, (res) => {
        console.log("Favoutite=============",res);
        setLoading(false);
        if (res.status) {
            if(res.data.length){
                setJobs(res.data)  
            }
            else{
                setJobs([])
            }
        } else {
            toast.error("Something went wrong");
        }
        });
    }

    const removeFromFavourite = (entityId) => {

        const params = {
          'entityId' : entityId,
          'userIdHash' :props.auth.userProfile.userIdHash,
        }
        
        props.removeFromFavouriteList(params, (res) => {
            console.log("API response========",res)
            if(res.status){
                toast.success('Deleted form favourite');
                getUserFavouriteJobs()
            }
            else{
                toast.warn('Something went wrong');
            }
        });
    
    } 


    return (
    <>
        <section className="favroite_job_description">
            <div className="container">
                <h3 className="jobHeading"></h3>
                <p className="jobs_foryou">You have {jobs && jobs.length} Favourite jobs</p>
                <div className="favroite_jobDetails">
                {loading ? (
                    <>
                        <div className="row" style={{ width: "100%", height: "80vh" }}>
                        <div
                            className="col-12"
                            style={{ width: "100%", height: "100%" }}
                        >
                            <div
                            style={{
                                width: "100%",
                                height: "100%",
                                textAlign: "center",
                                verticalAlign: "middle",
                                lineHeight: "40vh",
                            }}
                            >
                            <div
                                className="spinner-grow"
                                role="status"
                                style={{ background: "#006838" }}
                            ></div>
                            </div>
                        </div>
                        </div>
                    </>
                ) : (
                    <div className="row">
                        {jobs && jobs.length > 0 ?
                        <>
                            <div className="col-lg-6">
                                <div className="nav flex-column nav-pills" id="Favorite-tab" role="tablist" aria-orientation="vertical">               
                                {jobs.map((value,index)=>{
                                    return(
                                    <div className="nav-link" id="favroiteJobOne-tab" data-toggle="pill" href={'#favroiteJob'+(index)}
                                        role="tab" aria-controls="favroiteJobOne" aria-selected="true">
                                            <div className="job_detail">
                                                <div className="job_detailsNav">
                                                    <div className="orgnisation_icon" href="javascript:void();">
                                                        <img src={value.userProfileData.selectedUserData.userProfileImageUrl ? value.userProfileData.selectedUserData.userProfileImageUrl : CompanyLogo1} alt="logo" className="img-fluid" />
                                                    </div>
                                                    <div className="jobDescription">
                                                        <h5 className="job_heading">
                                                            {value.userProfileData.selectedEntityData.title} | {value.userProfileData.selectedEntityTypeData.profession}
                                                        </h5>
                                                        <span className="job_Subheading">
                                                            {value.userProfileData.selectedUserData.entityName}{" "}
                                                        </span>
                                                    </div>
                                                    {props.auth && props.auth.isLoggedIn ? (
                                                    <div className="Remove_box" onClick={()=>{
                                                        removeFromFavourite(value.userProfileData?.selectedEntityData?.id)}
                                                        }>
                                                        <img src={Delete} alt="logo" className="img-fluid" />
                                                        <span>Remove</span>
                                                    </div>
                                                    ):null}
                                                </div>
                                                <div className="job_location">
                                                    <a href="javascript:void();">
                                                        <img src={jobloaction} alt="logo" />
                                                        <span>{" "}{value.userProfileData.selectedUserData.state}{", "}{value.userProfileData.selectedUserData.country}</span>
                                                    </a>
                                                    {/* <span>
                                                        <a href="javascript:void();">
                                                            <img src={price} alt="logo" />
                                                            <span className="salery">$700 - $1500 p.a.</span>
                                                        </a>
                                                    </span> */}
                                                </div>
                                                <div className="job_detailsContent">
                                                    {/* <p> Job Description. Responsibilities. Competent professional with over 1. 6 years of rich experience
                                                        in Recruiting, Temp Staffing. Strong expertise in Talent Acquisition sites. Meeting TA demand a...
                                                    </p> */}
                                                </div>
                                            </div>
                                    </div>
                                    );
                                })}
                                </div>
                                
                            </div>
                            <div className="col-lg-6">
                                <div className="tab-content" id="Favorite-tabContent">
                                {jobs.map((value,index)=>{
                                    return(
                                    <div className="tab-pane fade show"  id={'favroiteJob'+(index)} role="tabpanel" aria-labelledby="favroiteJobOne-tab">
                                        <div className="fav_jobDeatil">
                                            <div className="fav_jobNav">
                                                <div className="jobDescription">
                                                    <h5 className="job_heading">
                                                    {value.userProfileData.selectedEntityData.title} | {value.userProfileData.selectedEntityTypeData.profession}
                                                    </h5>
                                                    <span className="job_Subheading">
                                                    {value.userProfileData.selectedUserData.entityName}{" "}
                                                    </span>
                                                </div>
                                                <div className="job_location">
                                                    <a href="javascript:void();">
                                                        <img src={jobloaction} alt="logo" />
                                                        <span>{" "}{value.userProfileData.selectedUserData.state}{", "}{value.userProfileData.selectedUserData.country}</span>
                                                    </a>
                                                    {/* <span>
                                                        <a href="javascript:void();">
                                                            <img src={price} alt="logo" />
                                                            <span className="salery">$700 - $1500 p.a.</span>
                                                        </a>
                                                    </span> */}
                                                </div>
                                                <button className="sliderFormBtn" type="submit">Apply for Job</button>
                                            </div>                                   
                                            <div className="job_Description">
                                            <div className="job_main">
                                                <img src={jobPkg} alt="logo" />
                                                <small>Job Description</small>
                                            </div>
                                            <p>Responsibilities</p>
                                            <ul className="JobDescriptionListing">
                                            {value.userProfileData.selectedEntityData.description}
                                                    {/* <li>
                                                    <img src={RightArrow} alt="arrow" className="img-fluid" />
                                                    <span>Competent professional with over 1 - 6 years of rich experience in Recruiting, Temp Staffing.</span>
                                                    </li> */}
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                    );
                                })}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className={jobs.length > 0 ? "col-sm-12 col-md-12 col-lg-12" : "col-sm-12 col-md-12 col-lg-12"}>
                                <div
                                    className="nav-link"
                                    data-toggle="pill"
                                    role="tab"
                                    aria-selected="true"
                                    >
                                    <div className="row mb-5 h-100 w-100">
                                    <div
                                        className="col-12 text-center"
                                        style={{
                                            position: "relative",
                                        }}
                                    >
                                        <img
                                        className="mb-3"
                                        src={zeroFollowingImage}
                                        alt="No Data Found"
                                        />
                                        <p>No jobs found!</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                )
                }
                </div>
            </div>
        </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
    getUserFavouriteJobList :  bindActionCreators(getUserFavouriteJobList,dispatch),
    removeFromFavouriteList : bindActionCreators(removeFromFavouriteList,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FavJob);
