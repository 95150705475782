import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

export const getOpenStateData = (params, cb) => {
    return (dispatch) => {
      RestClient.get(
        `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/open`,
        {},
        true
      )
        .then((result) => {
          if (result.status === 200 && result.data) {
            let res = {
              status: true,
              message: result.message ? result.message : message.success,
              statusCode: result.status,
              type: message.success,
              data: result.data,
            };
            cb(res);
          } else {
            let res = {
              status: false,
              message: result.message ? result.message : message.commonError,
              type: message.error,
            };
            cb(res);
          }
        })
        .catch((error) => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error,
          };
          cb(res);
        });
    };
  };

export const getOpenStateDataWithEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/open?entityTypeId=${params.entityTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getInterestedStateData = (params, cb) => {
    return (dispatch) => {
      RestClient.get(
        `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/interested`,
        {},
        true
      )
        .then((result) => {
          if (result.status === 200 && result.data) {
            let res = {
              status: true,
              message: result.message ? result.message : message.success,
              statusCode: result.status,
              type: message.success,
              data: result.data,
            };
            cb(res);
          } else {
            let res = {
              status: false,
              message: result.message ? result.message : message.commonError,
              type: message.error,
            };
            cb(res);
          }
        })
        .catch((error) => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error,
          };
          cb(res);
        });
    };
  };

export const getInterestedStateDataWithEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/interested?entityTypeId=${params.entityTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getProspectStateData = (params, cb) => {
    return (dispatch) => {
      RestClient.get(
        `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/prospect`,
        {},
        true
      )
        .then((result) => {
          if (result.status === 200 && result.data) {
            let res = {
              status: true,
              message: result.message ? result.message : message.success,
              statusCode: result.status,
              type: message.success,
              data: result.data,
            };
            cb(res);
          } else {
            let res = {
              status: false,
              message: result.message ? result.message : message.commonError,
              type: message.error,
            };
            cb(res);
          }
        })
        .catch((error) => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error,
          };
          cb(res);
        });
    };
  };

export const getProspectStateDataWithEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/prospect?entityTypeId=${params.entityTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getInterviewingStateData = (params, cb) => {
    return (dispatch) => {
      RestClient.get(
        `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/interviewing`,
        {},
        true
      )
        .then((result) => {
          if (result.status === 200 && result.data) {
            let res = {
              status: true,
              message: result.message ? result.message : message.success,
              statusCode: result.status,
              type: message.success,
              data: result.data,
            };
            cb(res);
          } else {
            let res = {
              status: false,
              message: result.message ? result.message : message.commonError,
              type: message.error,
            };
            cb(res);
          }
        })
        .catch((error) => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error,
          };
          cb(res);
        });
    };
  };

export const getInterviewingStateDataWithEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/interviewing?entityTypeId=${params.entityTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getHiredStateData = (params, cb) => {
    return (dispatch) => {
      RestClient.get(
        `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/hired`,
        {},
        true
      )
        .then((result) => {
          if (result.status === 200 && result.data) {
            let res = {
              status: true,
              message: result.message ? result.message : message.success,
              statusCode: result.status,
              type: message.success,
              data: result.data,
            };
            cb(res);
          } else {
            let res = {
              status: false,
              message: result.message ? result.message : message.commonError,
              type: message.error,
            };
            cb(res);
          }
        })
        .catch((error) => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error,
          };
          cb(res);
        });
    };
  };

export const getHiredStateDataWithEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/hired?entityTypeId=${params.entityTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getBacklogStateData = (params, cb) => {
    return (dispatch) => {
      RestClient.get(
        `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/Backlog`,
        {},
        true
      )
        .then((result) => {
          if (result.status === 200 && result.data) {
            let res = {
              status: true,
              message: result.message ? result.message : message.success,
              statusCode: result.status,
              type: message.success,
              data: result.data,
            };
            cb(res);
          } else {
            let res = {
              status: false,
              message: result.message ? result.message : message.commonError,
              type: message.error,
            };
            cb(res);
          }
        })
        .catch((error) => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error,
          };
          cb(res);
        });
    };
  };

export const getBacklogStateDataWithEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/Backlog?entityTypeId=${params.entityTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const reopenBacklogProfile = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/interested/${params.applyIdHash}?userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const reapplyBacklogProfile = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/apply/open/${params.applyIdHash}?userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const rejectOpenProfile = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/apply/open/${params.applyIdHash}?userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const moveForwardToInterested = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/interested/${params.applyIdHash}?userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const moveBackFromInterested = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/backStep/interested/${params.applyIdHash}?userIdHash=${params.userIdHash}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const moveForwardToProspect = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/prospect/${params.applyIdHash}?userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const moveBackFromProspect = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/backStep/prospect/${params.applyIdHash}?userIdHash=${params.userIdHash}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const moveForwardToInterviewing = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/interviewing/${params.applyIdHash}?userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const moveBackFromInterviewing = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/backStep/interviewing/${params.applyIdHash}?userIdHash=${params.userIdHash}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const moveForwardToHired = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/hired/${params.applyIdHash}?userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const moveBackFromHired = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/applyBooking/backStep/hired/${params.applyIdHash}?userIdHash=${params.userIdHash}`,
      {},
      true
    )
      .then((result) => {
        console.log('RESULT', result)
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};