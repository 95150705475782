import React from "react";
import { connect } from "react-redux";
import EmployeeDashboard from "./employeeDashboard";
import EmployerDashboard from "./employerDashboard";
import "../../public/css/providerStyles.css";

const Dashboard = (props) => {
  return (
    <>
      {props.auth.userProfile.userType.userTypeId == 1 ? (
        <EmployerDashboard />
      ) : (
        <EmployeeDashboard />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
