import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Authenticator = (props) => {
  const history = useHistory();
  const [pass,setPass] = useState(null);

  const authenticateUser = (isAuthenticationRequired) => {


    if(props.auth && props.auth.isLoggedIn && props.auth.token){
      // If user are logedin then we can perform any action here
      setPass(true)
    }
    else if(isAuthenticationRequired){
      // console.log('false----')
      // If user are not login then redirect him to the login page
      setPass(false)
      history.push('/');
    }
    else{
      setPass(true)
    }
  }

  useEffect(() => {
    authenticateUser(props.isAuthenticationRequired);
  }, []);



  return (
    <>
      {pass?    
      props.children
      :null}
    </>
  )

}

const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticator);