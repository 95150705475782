import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {Modal,Button}from 'react-bootstrap';
import { bindActionCreators } from "redux";
import { getAllNotification } from "../../../actions/user";
import Cancel from  "../../../public/images/cancel.svg"; 
import { toast } from "react-toastify";
import moment from 'moment-timezone';
import ViewNotificationModal from "./viewNotificationModal";
import Notification from  "../../../public/images/noti.svg"; 


const NotificationModal = (props) => {
    const {show, toggleShow} = props;
    const [notification, setNotification] = useState("");
    const [showViewModal, setShowViewModal] = useState(false);
    const [notificationId, setNotificationId] = useState({});
    const [loading, setLoading] = useState(false);


    const toggleviewShow = () => setShowViewModal(!showViewModal);

    const getAllNotification = () => {
        let params = {
            userIdHash: props.auth.token,
        }
          setLoading(true);
          props.getAllNotification(params, res => {
            setLoading(false);
            if (res.status) {
               let notifcation = res.data
               setNotification(notifcation);
            }
            else {
              toast.error(res.message);
            }
          },
          err => {
            setLoading(false);
            toast.error('Something went wrong! Please try again later.');
          });
      }
    

    useEffect(() => {
    getAllNotification();
    }, []);

    return(
        <>
        <Modal show={show} 
        className="noti_Main"
        onHide={toggleShow}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={toggleShow}>
            <img src={Cancel} alt="cancel"/>
          </button>
        </Modal.Header>
        <Modal.Body>  
                {loading ? (
                        <div className="col-md-12 text-center">
                            <span className="spinner-grow spinner-border-sm"></span>
                        </div>
                    ) :
                    <>
                        {notification && notification.length <= 0 ? 
                            <div>
                                <p className="subtext2_">no notifcation found</p>
                            </div>  
                            :
                            <>
                                {notification && notification.map((data, index ) =>
                                <div className="notification_" key={index} onClick={() => {setShowViewModal(!showViewModal); setNotificationId(data.id)}}>
                                    <div className="notification_icon">
                                        <img src={Notification} alt="noti"/>
                                    </div>  
                                    <div className="notification_content">
                                        <h6>Message</h6>
                                        <p>{data.message.length > 30 ? data.message.substring(0,30) + " ...": data.message}</p>
                                        <span className="notification_footer">
                                            <a href="javascript:void(0);" className={data.notificationPriorityTypeColorStatus === "yellow" ? "low_priority": data.notificationPriorityTypeColorStatus === "green" ? "medium_priority": 
                                                data.notificationPriorityTypeColorStatus === "blue" ? "attention_priority": data.notificationPriorityTypeColorStatus === "red" ? "high_priority":
                                                data.notificationPriorityTypeColorStatus === "gray" ? "gray_priority": ""}>{data.subject} </a> 
                                            <a href="javascript:void(0);">{moment(data.createdDate).format('dddd, hh:mm a')}</a>
                                        </span>
                                    </div>
                                </div>
                                )}
                            </>
                        }
                        {/* <div className="notification_">
                            <div className="notification_icon">
                                <img src={Notification} alt="noti"/>
                            </div>
                            <div className="notification_content">
                                <h6>Message</h6>
                                <p>You have  recieved new message from Kewin William.</p>
                                <span className="notification_footer">
                                    <a href="javascript:void(0);" className="medium_priority">Medium priority </a>
                                    <a href="javascript:void(0);">1 hour ago</a>
                                </span>
                            </div>
                        </div> */}
                        {/* <div className="notification_">
                            <div className="notification_icon">
                                <img src={Notification} alt="noti"/>
                            </div>
                            <div className="notification_content">
                                <h6>Message</h6>
                                <p>You have  recieved new message from Kewin William.</p>
                                <span className="notification_footer">
                                    <a href="javascript:void(0);" className="low_priority">Low priority </a>
                                    <a href="javascript:void(0);">28 Jun, 2022</a>
                                </span>
                            </div>
                        </div>  */}

                    </> 
                }
        </Modal.Body>
       
        
      </Modal>
            {
            showViewModal ?
            <ViewNotificationModal show={showViewModal} toggleviewShow={toggleviewShow} notificationId={notificationId} /> 
            :null
            }
      </>
        
    )
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
    getAllNotification: bindActionCreators(getAllNotification, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);