import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getProfessionsList,
  getProfessionsChildrenList,
  getSearchJobListForLandingPage,
} from "../../actions/common";
import JobPkg from "../../public/img/job-pkg.svg";
import Category from "../../public/img/category.png";
import Location from "../../public/img/location.png";
import BannerVector from "../../public/img/bannerVector.png";
import RightArrow from "../../public/img/rightArrow.png";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import { useState } from "react";
import JobLocation from "../../public/img/joblocation.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getStateBounds,
} from "react-places-autocomplete";
import { toast } from "react-toastify";

const CarouselComp = (props) => {
  const history = useHistory();


  const [professionsList, setProfessionsList] = useState([]);
  const [childrenprofessionsList, setChildrenProfessionsList] = useState([]);
  const [profession, setProfession] = useState();
  const [childProfession, setChildProfession] = useState();
  const [professionTitle, setProfessionTitle] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [zipCode, setZipCode] = useState();
  const [address, setAddress] = useState();
  const [searchJobFlag, setSearchJobFlag] = useState();
  const [childFlag1, setChildFlag1] = useState();
  const [error, setError] = useState()
  const [errorMsg, setErrorMsg] = useState()

  useEffect(() => {
    getProfessions();
    setChildFlag1(false)
    setError(false)
    setErrorMsg()
    document.getElementById("searchTextField").value = "";
  }, []);

  // const errorMsg = 'Please search a place you are looking for a job in'

  const getProfessions = () => {
    props.getProfessionsList({}, (res) => {
      console.log("PROFESSIONS", res);
      if (res.status) {
        setProfessionsList(res.data);
      } else {
        setProfessionsList([]);
      }
    });
  };

  const onChangeInput = (e, flag) => {
    e.preventDefault();
    // console.log(e.target.value);
    let value = e.target.value;
    if (flag === "profession") {
      setProfession(value);
      props.getProfessionsChildrenList({entityTypeName: value}, res => {
        if(res.status) {
          console.log('CHILDREN', res)
          if(res.data.length) {
            setChildrenProfessionsList(res.data)
            setChildFlag1(true)
          } else {
            setChildrenProfessionsList([])
            setChildFlag1(false)
            setChildProfession()
          }
        }
      })
      setError(false)
    } else if (flag === 'professionChild') {
      setChildProfession(value)
      setProfession(profession + ',' + value)
      setError(false)
    } else if (flag === 'professionTitle') {
      setProfessionTitle(value)
    }
  };

  const autoCompleteHandleChange = (address) => {
    setAddress(address);
    if(address === '') {
      setCity()
      setState()
      setCountry()
      setErrorMsg('Please search a place you are looking for a job in')
      setError(true)
    } else {
      setError(false)
    }
  };

  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        document.getElementById("searchTextField").blur();
        // setTimeout(() => {
        //   document.getElementById("searchTextField").value = "";
        // }, 10);
        fillAddressDetails(results);
      })
      .catch((error) => {});
  };

  const google = (window.google = window.google ? window.google : {});

  const searchOptions = {
    // location: new this.google.maps.LatLng(51.509865, -0.118092),
    // radius: 424000,
    //types: ["address", "uk"],
    componentRestrictions: { country: "" },
  };

  const fillAddressDetails = (results) => {
    setAddress(results[0].formatted_address);
    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        setZipCode(results[0].address_components[j].short_name);
      } else if (results[0].address_components[j].types[0] == "locality") {
        setCity(results[0].address_components[j].long_name);
      } else if (
        results[0].address_components[j].types[0] ==
        "administrative_area_level_1"
      ) {
        setState(results[0].address_components[j].long_name);
      } else if (results[0].address_components[j].types[0] == "country") {
        setCountry(results[0].address_components[j].long_name);
      }
    }
  };

  const searchJob = (e) => {
    e.preventDefault();
    if(!professionTitle && !profession && !city && !state && !country) {
      setErrorMsg('Please search a place you are looking for a job in')
      setError(true)
      return
    }
    if(!city && !state && !country) {
      setErrorMsg('Please search a place you are looking for a job in')
      setError(true)
      return
    }
    if(childFlag1) {
      if(!childProfession) {
        setErrorMsg('Please select a child profession from the list')
        setError(true)
        return
      }
    }
    setSearchJobFlag(true);

    var url = new URL(window.location.href + `search-job`);
    if(profession){
      url.searchParams.append('profession', profession); 
    }
    if(childProfession){
      url.searchParams.append('childProfession', childProfession); 
    }
    if(professionTitle){
      url.searchParams.append('professionTitle', professionTitle); 
    }
    if(city){
      url.searchParams.append('city', city); 
    }
    if(state){
      url.searchParams.append('state', state); 
    }
    if(country){
      url.searchParams.append('country', country); 
    }
  
    history.push(url.pathname+url.search);
 
  };

  return (
    <>
      <section className="banner-carousel">
        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-ride="carousel"
        >
          <ul className="carousel-indicators">
            <li
              type="button"
              data-target="#carouselExampleCaptions"
              data-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></li>
            <li
              type="button"
              data-target="#carouselExampleCaptions"
              data-slide-to="1"
              aria-label="Slide 2"
            ></li>
            <li
              type="button"
              data-target="#carouselExampleCaptions"
              data-slide-to="2"
              aria-label="Slide 3"
            ></li>
            <li
              type="button"
              data-target="#carouselExampleCaptions"
              data-slide-to="3"
              aria-label="Slide 4"
            ></li>
          </ul>
          <div className="carousel-inner">
            <div className="carousel-item active crouselContainer">
              <div className="sliderContainer">
                <div className="sliderCol-1">
                  <h3 className="subhead_2">
                    The Easiest way <span>To Get Your</span>
                  </h3>
                  <h2 className="subtitle_2">
                    Dream <span>Job</span>
                  </h2>
                  <p className="subtext_2">
                    {" "}
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <div className="feature_container">
                    <h3 className="feature_head">Search Job</h3>
                    <form className="search-job">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="e.g job name..."
                          className="form-control"
                          name=""
                          onChange={(e) => {
                            onChangeInput(e, "professionTitle");
                          }}
                        />
                        <img src={JobPkg} className="img-fluid overlap-icon" />
                      </div>
                      <div className="form-group">
                        <select
                          id="cate"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            onChangeInput(e, "profession");
                          }}
                        >
                          <option value="cate1" disabled selected>
                            Profession
                          </option>
                          {professionsList.length ? (
                            <>
                              {professionsList.map((value, index) => {
                                return (
                                  <>
                                    <option value={value.name}>
                                      {value.name}
                                    </option>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img
                          src={Category}
                          className="img-fluid overlap-icon"
                        />
                      </div>
                      {childFlag1 && (
                      <>
                      <div className="form-group">
                        <select
                          id="cate"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            onChangeInput(e, "professionChild");
                          }}
                        >
                          <option value="cate1" disabled selected>
                            Child Profession
                          </option>
                          {childrenprofessionsList.length ? (
                            <>
                              {childrenprofessionsList.map((value, index) => {
                                return (
                                  <>
                                    <option value={value.name}>
                                      {value.name}
                                    </option>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img
                          src={Category}
                          className="img-fluid overlap-icon"
                        />
                      </div>
                      </>)}

                      <div className="job_search">
                      <div className="form-group">
                        <img
                          src={JobLocation}
                          className="img-fluid overlap-icon"
                          style={{top:'13px'}}
                        />
                        <PlacesAutocomplete
                          value={address}
                          onChange={autoCompleteHandleChange}
                          onSelect={autoCompleteHandleSelect}
                          searchOptions={searchOptions}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                id="searchTextField"
                                {...getInputProps({
                                  placeholder: "Search Place...",
                                  className:
                                    "location-search-input form-control inputStyle",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#41b6e6",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                      </div>

                      {error ? (
                      <>
                      <p style={{color: 'red'}}>{errorMsg}</p>
                      </>) : (<></>)}
                      <div className="btn_ text-center">
                        <button
                          className="sliderFormBtn"
                          type="submit"
                          onClick={(e) => {
                            searchJob(e);
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="sliderCol-2">
                  <div className="sliderRight" data-aos={"slide-center"}>
                    <img src={BannerVector} />
                    <div className="interested-container">
                      <h3 className="intrestedHeading mt-5">
                        Explore roles you are interested in
                      </h3>
                      <ul className="interested-list">
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Medical Assistant
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Project Manager
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Librarian
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Dog Trainer
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Nursing Assistant
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Account Executive
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="carousel-item crouselContainer">
              <div className="sliderContainer">
                <div className="sliderCol-1">
                  <h3 className="subhead_2">
                    The Easiest way <span>To Get Your</span>
                  </h3>
                  <h2 className="subtitle_2">
                    Dream <span>Job</span>
                  </h2>
                  <p className="subtext_2">
                    {" "}
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <div className="feature_container">
                    <h3 className="feature_head">Search Job</h3>
                    <form className="search-job">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="e.g job name..."
                          className="form-control"
                          name=""
                        />
                        <img src={JobPkg} className="img-fluid overlap-icon" />
                      </div>
                      <div className="form-group">
                        <select
                          id="cate"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            onChangeInput(e, "profession");
                          }}
                        >
                          <option value="cate1" disabled selected>
                            Profession
                          </option>
                          {professionsList.length ? (
                            <>
                              {professionsList.map((value, index) => {
                                return (
                                  <>
                                    <option value={value.name}>
                                      {value.name}
                                    </option>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img
                          src={Category}
                          className="img-fluid overlap-icon"
                        />
                      </div>
                      <div className="form-group">
                        <select
                          id="cate"
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value="cate1" selected>
                            Location
                          </option>
                          <option value="cate1">demo</option>
                          <option value="cate1">demo</option>
                        </select>
                        <img
                          src={Location}
                          className="img-fluid overlap-icon"
                        />
                      </div>
                      <div className="btn_ text-center">
                        <button className="sliderFormBtn" type="submit">
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="sliderCol-2">
                  <div className="sliderRight">
                    <img src={BannerVector} />
                    <div className="interested-container">
                      <h3 className="intrestedHeading mt-5">
                        Explore roles you are interested in
                      </h3>
                      <ul className="interested-list">
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Medical Assistant
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Project Manager
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Librarian
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Dog Trainer
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Nursing Assistant
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Account Executive
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item crouselContainer">
              <div className="sliderContainer">
                <div className="sliderCol-1">
                  <h3 className="subhead_2">
                    The Easiest way <span>To Get Your</span>
                  </h3>
                  <h2 className="subtitle_2">
                    Dream <span>Job</span>
                  </h2>
                  <p className="subtext_2">
                    {" "}
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <div className="feature_container">
                    <h3 className="feature_head">Search Job</h3>
                    <form className="search-job">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="e.g job name..."
                          className="form-control"
                          name=""
                        />
                        <img src={JobPkg} className="img-fluid overlap-icon" />
                      </div>
                      <div className="form-group">
                        <select
                          id="cate"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            onChangeInput(e, "profession");
                          }}
                        >
                          <option value="cate1" disabled selected>
                            Profession
                          </option>
                          {professionsList.length ? (
                            <>
                              {professionsList.map((value, index) => {
                                return (
                                  <>
                                    <option value={value.name}>
                                      {value.name}
                                    </option>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img
                          src={Category}
                          className="img-fluid overlap-icon"
                        />
                      </div>
                      <div className="form-group">
                        <select
                          id="cate"
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value="cate1" selected>
                            Location
                          </option>
                          <option value="cate1">demo</option>
                          <option value="cate1">demo</option>
                        </select>
                        <img
                          src={Location}
                          className="img-fluid overlap-icon"
                        />
                      </div>
                      <div className="btn_ text-center">
                        <button className="sliderFormBtn" type="submit">
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="sliderCol-2">
                  <div className="sliderRight">
                    <img src={BannerVector} />
                    <div className="interested-container">
                      <h3 className="intrestedHeading mt-5">
                        Explore roles you are interested in
                      </h3>
                      <ul className="interested-list">
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Medical Assistant
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Project Manager
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Librarian
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Dog Trainer
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Nursing Assistant
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Account Executive
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item crouselContainer">
              <div className="sliderContainer">
                <div className="sliderCol-1">
                  <h3 className="subhead_2">
                    The Easiest way <span>To Get Your</span>
                  </h3>
                  <h2 className="subtitle_2">
                    Dream <span>Job</span>
                  </h2>
                  <p className="subtext_2">
                    {" "}
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <div className="feature_container">
                    <h3 className="feature_head">Search Job</h3>
                    <form className="search-job">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="e.g job name..."
                          className="form-control"
                          name=""
                        />
                        <img src={JobPkg} className="img-fluid overlap-icon" />
                      </div>
                      <div className="form-group">
                        <select
                          id="cate"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            onChangeInput(e, "profession");
                          }}
                        >
                          <option value="cate1" disabled selected>
                            Profession
                          </option>
                          {professionsList.length ? (
                            <>
                              {professionsList.map((value, index) => {
                                return (
                                  <>
                                    <option value={value.name}>
                                      {value.name}
                                    </option>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img
                          src={Category}
                          className="img-fluid overlap-icon"
                        />
                      </div>
                      <div className="form-group">
                        <select
                          id="cate"
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value="cate1" selected>
                            Location
                          </option>
                          <option value="cate1">demo</option>
                          <option value="cate1">demo</option>
                        </select>
                        <img
                          src={Location}
                          className="img-fluid overlap-icon"
                        />
                      </div>
                      <div className="btn_ text-center">
                        <button className="sliderFormBtn" type="submit">
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="sliderCol-2">
                  <div className="sliderRight">
                    <img src={BannerVector} />
                    <div className="interested-container">
                      <h3 className="intrestedHeading mt-5">
                        Explore roles you are interested in
                      </h3>
                      <ul className="interested-list">
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Medical Assistant
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Project Manager
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Librarian
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Dog Trainer
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Nursing Assistant
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" className="inter-links">
                            <img src={RightArrow} /> Account Executive
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getProfessionsList: bindActionCreators(getProfessionsList, dispatch),
  getProfessionsChildrenList: bindActionCreators(getProfessionsChildrenList, dispatch),
  getSearchJobListForLandingPage: bindActionCreators(
    getSearchJobListForLandingPage,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarouselComp);
