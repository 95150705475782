import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import location from "../../public/images/location.png";
import downArrow from "../../public/images/downArrow.svg";
import { getUserProfileList } from "../../actions/common";
import { bindActionCreators } from "redux";
import "../../public/css/providerStyles.css";
import { showBadge } from "../../../utilities/helpers";
import { Button, Form, Modal } from "react-bootstrap";
import { getActiveDaysList, getDaysList, updateWeeklyShiftDataForActiveDaysList, updateWeeklyShiftDataForEmptyActiveDaysList } from "../../actions/employer/talent";
import Switch from "react-switch";
import TimePicker from "react-time-picker";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DatePicker from "react-date-picker";
import Calendar from "react-calendar";
import { getDailyShiftDetails, postDailyShift } from "../../actions/employee/availability";
import moment from "moment";

const Availability = (props) => {
  const history = useHistory();

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [loadingShiftBody, setLoadingShiftBody] = useState(false);

  const [shiftModal, setShiftModal] = useState(false);
  const [daysListData, setDaysListData] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [userData, setUserData] = useState({});
  const [updateWeeklyShiftDataDisabled, setUpdateWeeklyShiftDataDisabled] = useState(true);
  const [isSundayChecked, setIsSundayChecked] = useState(false);
  const [isMondayChecked, setIsMondayChecked] = useState(false);
  const [isTuesdayChecked, setIsTuesdayChecked] = useState(false);
  const [isWednesdayChecked, setIsWednesdayChecked] = useState(false);
  const [isThursdayChecked, setIsThursdayChecked] = useState(false);
  const [isFridayChecked, setIsFridayChecked] = useState(false);
  const [isSaturdayChecked, setIsSaturdayChecked] = useState(false);
  const [sundayStartTime, setSundayStartTime] = useState();
  const [mondayStartTime, setMondayStartTime] = useState();
  const [tuesdayStartTime, setTuesdayStartTime] = useState();
  const [wednesdayStartTime, setWednesdayStartTime] = useState();
  const [thursdayStartTime, setThursdayStartTime] = useState();
  const [fridayStartTime, setFridayStartTime] = useState();
  const [saturdayStartTime, setSaturdayStartTime] = useState();
  const [sundayEndTime, setSundayEndTime] = useState();
  const [mondayEndTime, setMondayEndTime] = useState();
  const [tuesdayEndTime, setTuesdayEndTime] = useState();
  const [wednesdayEndTime, setWednesdayEndTime] = useState();
  const [thursdayEndTime, setThursdayEndTime] = useState();
  const [fridayEndTime, setFridayEndTime] = useState();
  const [saturdayEndTime, setSaturdayEndTime] = useState();
  const [sundayTimeObject, setSundayTimeObject] = useState({});
  const [mondayTimeObject, setMondayTimeObject] = useState({});
  const [tuesdayTimeObject, setTuesdayTimeObject] = useState({});
  const [wednesdayTimeObject, setWednesdayTimeObject] = useState({});
  const [thursdayTimeObject, setThursdayTimeObject] = useState({});
  const [fridayTimeObject, setFridayTimeObject] = useState({});
  const [saturdayTimeObject, setSaturdayTimeObject] = useState({});
  const [weeklyShiftPayload, setWeeklyShiftPayload] = useState([]);
  const [initialWeeklyShiftPayload, setInitialWeeklyShiftPayload] = useState([]);
  const [loadingUpdateWeeklyShiftData, setLoadingUpdateWeeklyShiftData] = useState(false);

  const [postModal, setPostModal] = useState(false);
  const [gettingField, setGettingField] = useState(false);
  const [employmentTypeData, setEmploymentTypeData] = useState([]);
  const [createDisabled, setCreateDisabled] = useState(true);
  const [closeDisabled, setCloseDisabled] = useState(false);

  const [contractStartDate, setContractStartDate] = useState()
  const [showContractStartDate, setShowContractStartDate] = useState()
  const [contractEndDate, setContractEndDate] = useState()
  const [showContractEndDate, setShowContractEndDate] = useState()
  const [contractStartTime, setContractStartTime] = useState()
  const [contractEndTime, setContractEndTime] = useState()
  const [finalContractStartDate, setFinalContractStartDate] = useState()
  const [finalContractEndDate, setFinalContractEndDate] = useState()
  const [contractProviderCount, setContractProviderCount] = useState()
  const [contractStartTimeFlag, setContractStartTimeFlag] = useState(false);
  const [contractEndTimeFlag, setContractEndTimeFlag] = useState(false);

  const [fullTimeStartDate, setFullTimeStartDate] = useState()
  const [showFullTimeStartDate, setShowFullTimeStartDate] = useState()
  const [fullTimeProviderCount, setFullTimeProviderCount] = useState()
  const [fullTimeStartTimeFlag, setFullTimeStartTimeFlag] = useState(false);
  const [finalfullTimeStartDate, setFinalFullTimeStartDate] = useState()
  const [fullTimeStartTime, setFullTimeStartTime] = useState()

  const [partTimeStartDate, setPartTimeStartDate] = useState()
  const [finalPartTimeStartDate, setFinalPartTimeStartDate] = useState()
  const [partTimeInputDateFlag, setPartTimeInputDateFlag] = useState(false)

  const [partTimeStartTime1, setPartTimeStartTime1] = useState()
  const [finalPartTimeStartDate1, setFinalPartTimeStartDate1] = useState()
  const [partTimeEndTime1, setPartTimeEndTime1] = useState()
  const [finalPartTimeEndDate1, setFinalPartTimeEndDate1] = useState()
  const [partTimeStartTimeFlag1, setPartTimeStartTimeFlag1] = useState(false)
  const [partTimeStartTime1Modal, setPartTimeStartTime1Modal] = useState(false)
  const [partTimeEndTimeFlag1, setPartTimeEndTimeFlag1] = useState(false)
  const [partTimeEndTime1Modal, setPartTimeEndTime1Modal] = useState(false)
  const [partTimeProviderCount1, setPartTimeProviderCount1] = useState()

  const [partTimeStartTime2, setPartTimeStartTime2] = useState()
  const [finalPartTimeStartDate2, setFinalPartTimeStartDate2] = useState()
  const [partTimeEndTime2, setPartTimeEndTime2] = useState()
  const [finalPartTimeEndDate2, setFinalPartTimeEndDate2] = useState()
  const [partTimeStartTimeFlag2, setPartTimeStartTimeFlag2] = useState(false)
  const [partTimeStartTime2Modal, setPartTimeStartTime2Modal] = useState(false)
  const [partTimeEndTimeFlag2, setPartTimeEndTimeFlag2] = useState(false)
  const [partTimeEndTime2Modal, setPartTimeEndTime2Modal] = useState(false)
  const [partTimeProviderCount2, setPartTimeProviderCount2] = useState()

  const [partTimeStartTime3, setPartTimeStartTime3] = useState()
  const [finalPartTimeStartDate3, setFinalPartTimeStartDate3] = useState()
  const [partTimeEndTime3, setPartTimeEndTime3] = useState()
  const [finalPartTimeEndDate3, setFinalPartTimeEndDate3] = useState()
  const [partTimeStartTimeFlag3, setPartTimeStartTimeFlag3] = useState(false)
  const [partTimeStartTime3Modal, setPartTimeStartTime3Modal] = useState(false)
  const [partTimeEndTimeFlag3, setPartTimeEndTimeFlag3] = useState(false)
  const [partTimeEndTime3Modal, setPartTimeEndTime3Modal] = useState(false)
  const [partTimeProviderCount3, setPartTimeProviderCount3] = useState()

  const [partTimeStartTime4, setPartTimeStartTime4] = useState()
  const [finalPartTimeStartDate4, setFinalPartTimeStartDate4] = useState()
  const [partTimeEndTime4, setPartTimeEndTime4] = useState()
  const [finalPartTimeEndDate4, setFinalPartTimeEndDate4] = useState()
  const [partTimeStartTimeFlag4, setPartTimeStartTimeFlag4] = useState(false)
  const [partTimeStartTime4Modal, setPartTimeStartTime4Modal] = useState(false)
  const [partTimeEndTimeFlag4, setPartTimeEndTimeFlag4] = useState(false)
  const [partTimeEndTime4Modal, setPartTimeEndTime4Modal] = useState(false)
  const [partTimeProviderCount4, setPartTimeProviderCount4] = useState()

  const [partTimeStartTime5, setPartTimeStartTime5] = useState()
  const [finalPartTimeStartDate5, setFinalPartTimeStartDate5] = useState()
  const [partTimeEndTime5, setPartTimeEndTime5] = useState()
  const [finalPartTimeEndDate5, setFinalPartTimeEndDate5] = useState()
  const [partTimeStartTimeFlag5, setPartTimeStartTimeFlag5] = useState(false)
  const [partTimeStartTime5Modal, setPartTimeStartTime5Modal] = useState(false)
  const [partTimeEndTimeFlag5, setPartTimeEndTimeFlag5] = useState(false)
  const [partTimeEndTime5Modal, setPartTimeEndTime5Modal] = useState(false)
  const [partTimeProviderCount5, setPartTimeProviderCount5] = useState()

  const [shift2Flag, setShift2Flag] = useState(false)
  const [shift3Flag, setShift3Flag] = useState(false)
  const [shift4Flag, setShift4Flag] = useState(false)
  const [shift5Flag, setShift5Flag] = useState(false)

  const [fullTimeData, setFullTimeData] = useState([]);
  const [partTimeData, setPartTimeData] = useState([]);
  const [contractData, setContractData] = useState([]);

  const [shiftCount, setShiftCount] = useState(0)

  const getUserProfileList = () => {
    setListLoading(true);
    props.getUserProfileList({ userIdHash: props.auth.token }, (res) => {
      setListLoading(false);
      if (res.status) {
        setList(res.data);
      }
    });
  };

  const getDailyShiftDetails = (data) => {
    setGettingField(true);
    props.getDailyShiftDetails(data, res => {
      setGettingField(false);
      if (res.status) {
        if (res.data) {
          if (res.data['1']) {
            setFullTimeData(res.data['1'].shifts)
          }
          if (res.data['2']) {
            setPartTimeData(res.data['2'].shifts)
          }
          if (res.data['3']) {
            setContractData(res.data['3'].shifts)
          }
        }
      }
    })
  }

  const getDaysList = () => {
    setLoadingShiftBody(true);
    props.getDaysList({}, (res) => {
      setLoadingShiftBody(false);
      if (res.status) {
        setDaysListData(res.data);
      }
    });
  };

  const getActiveDaysList = (id) => {
    setLoadingShiftBody(true);
    props.getActiveDaysList({ userProfileId: id }, (res) => {
      setLoadingShiftBody(false);
      if (res.status) {
        let hourArrayForPm = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 12]
        let payloadArray = []
        for (let i = 0; i < res.data.length; i++) {
          payloadArray.push(res.data[i])
        }
        setWeeklyShiftPayload(payloadArray)
        setInitialWeeklyShiftPayload(payloadArray)
        daysListData.map(day => {
          res.data.map(activeDay => {
            let timeObj = {}
            if (activeDay.day == (day.day).toLowerCase()) {
              //toggleSwitch(activeDay.day)
              if (activeDay.day == 'sunday') {
                setIsSundayChecked(true)
              } else if (activeDay.day == 'monday') {
                setIsMondayChecked(true)
              } else if (activeDay.day == 'tuesday') {
                setIsTuesdayChecked(true)
              } else if (activeDay.day == 'wednesday') {
                setIsWednesdayChecked(true)
              } else if (activeDay.day == 'thursday') {
                setIsThursdayChecked(true)
              } else if (activeDay.day == 'friday') {
                setIsFridayChecked(true)
              } else if (activeDay.day == 'saturday') {
                setIsSaturdayChecked(true)
              }
              if ((activeDay.shiftStartMeridian).toLowerCase() == 'pm') {
                changeTime('start', activeDay.day, hourArrayForPm[activeDay.startHour - 1] + ':' + activeDay.startMinute + ' ' + activeDay.shiftStartMeridian)
                // let newValue = new Date(moment(hourArrayForPm[activeDay.startHour - 1] + ':' + activeDay.startMinute).format())
                // changeTime('start', activeDay.day, newValue.getHours() + ':' + newValue.getMinutes() + ' ' + activeDay.shiftStartMeridian)
                timeObj.startHour = activeDay.startHour
                timeObj.startMinute = activeDay.startMinute
                timeObj.shiftStartMeridian = 'pm'
              } else {
                changeTime('start', activeDay.day, activeDay.startHour + ':' + activeDay.startMinute + ' ' + activeDay.shiftStartMeridian)
                timeObj.startHour = activeDay.startHour
                timeObj.startMinute = activeDay.startMinute
                timeObj.shiftStartMeridian = 'am'
              }

              if ((activeDay.shiftEndMeridian).toLowerCase() == 'pm') {
                changeTime('end', activeDay.day, hourArrayForPm[activeDay.endHour - 1] + ':' + activeDay.endMinute + ' ' + activeDay.shiftEndMeridian)
                timeObj.endHour = activeDay.endHour
                timeObj.endMinute = activeDay.endMinute
                timeObj.shiftEndMeridian = 'pm'
              } else {
                changeTime('end', activeDay.day, activeDay.endHour + ':' + activeDay.endMinute + ' ' + activeDay.shiftEndMeridian)
                timeObj.endHour = activeDay.endHour
                timeObj.endMinute = activeDay.endMinute
                timeObj.shiftEndMeridian = 'am'
              }

              timeObj.day = activeDay.day
              setTimeObject(timeObj)
              setLoadingShiftBody(false)
            }
          })
        })
      }
    });
  };

  const setTimeObject = (obj) => {
    if (obj) {
      if (obj.day == 'sunday') {
        setSundayTimeObject(obj)
      } else if (obj.day == 'monday') {
        setMondayTimeObject(obj)
      } else if (obj.day == 'tuesday') {
        setTuesdayTimeObject(obj)
      } else if (obj.day == 'wednesday') {
        setWednesdayTimeObject(obj)
      } else if (obj.day == 'thursday') {
        setThursdayTimeObject(obj)
      } else if (obj.day == 'friday') {
        setFridayTimeObject(obj)
      } else if (obj.day == 'saturday') {
        setSaturdayTimeObject(obj)
      }
    }
  }

  const changeTime = (type, day, value) => {
    if (value == null) {
      return
    }

    let hourArrayForPm = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    setUpdateWeeklyShiftDataDisabled(false)
    if (day == 'sunday') {
      let arr = weeklyShiftPayload
      let obj = sundayTimeObject
      if (type == 'start') {
        setSundayStartTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.startHour = 12
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.startHour = value.substring(1, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftStartMeridian = 'pm'
          obj.startMinute = value.substring(3, 5)
        } else {
          obj.startHour = value.substring(0, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        }
      } else {
        setSundayEndTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftEndMeridian = 'pm'
          obj.endMinute = value.substring(3, 5)
        } else {
          obj.endHour = value.substring(0, 2)
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        }
      }
      if (arr.length > 0) {
        arr.map(a => {
          if (a.day == 'sunday') {
            a.endHour = obj.endHour ? obj.endHour : 0
            a.endMinute = obj.endMinute ? obj.endMinute : 0
            a.startHour = obj.startHour ? obj.startHour : 0
            a.startMinute = obj.startMinute ? obj.startMinute : 0
            a.shiftEndMeridian = obj.shiftEndMeridian
            a.shiftStartMeridian = obj.shiftStartMeridian
          }
        })
      }
      setSundayTimeObject({ ...obj })
    } else if (day == 'monday') {
      let arr = weeklyShiftPayload
      let obj = mondayTimeObject
      if (type == 'start') {
        setMondayStartTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.startHour = 12
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.startHour = value.substring(1, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftStartMeridian = 'pm'
          obj.startMinute = value.substring(3, 5)
        } else {
          obj.startHour = value.substring(0, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        }
      } else {
        setMondayEndTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftEndMeridian = 'pm'
          obj.endMinute = value.substring(3, 5)
        } else {
          obj.endHour = value.substring(0, 2)
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        }
      }
      if (arr.length > 0) {
        arr.map(a => {
          if (a.day == 'monday') {
            a.endHour = obj.endHour ? obj.endHour : 0
            a.endMinute = obj.endMinute ? obj.endMinute : 0
            a.startHour = obj.startHour ? obj.startHour : 0
            a.startMinute = obj.startMinute ? obj.startMinute : 0
            a.shiftEndMeridian = obj.shiftEndMeridian
            a.shiftStartMeridian = obj.shiftStartMeridian
          }
        })
      }
      setMondayTimeObject({ ...obj })
    } else if (day == 'tuesday') {
      let arr = weeklyShiftPayload
      let obj = tuesdayTimeObject
      if (type == 'start') {
        setTuesdayStartTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.startHour = 12
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.startHour = value.substring(1, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftStartMeridian = 'pm'
          obj.startMinute = value.substring(3, 5)
        } else {
          obj.startHour = value.substring(0, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        }
      } else {
        setTuesdayEndTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftEndMeridian = 'pm'
          obj.endMinute = value.substring(3, 5)
        } else {
          obj.endHour = value.substring(0, 2)
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        }
      }
      if (arr.length > 0) {
        arr.map(a => {
          if (a.day == 'tuesday') {
            a.endHour = obj.endHour ? obj.endHour : 0
            a.endMinute = obj.endMinute ? obj.endMinute : 0
            a.startHour = obj.startHour ? obj.startHour : 0
            a.startMinute = obj.startMinute ? obj.startMinute : 0
            a.shiftEndMeridian = obj.shiftEndMeridian
            a.shiftStartMeridian = obj.shiftStartMeridian
          }
        })
      }
      setTuesdayTimeObject({ ...obj })
    } else if (day == 'wednesday') {
      let arr = weeklyShiftPayload
      let obj = wednesdayTimeObject
      if (type == 'start') {
        setWednesdayStartTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.startHour = 12
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.startHour = value.substring(1, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftStartMeridian = 'pm'
          obj.startMinute = value.substring(3, 5)
        } else {
          obj.startHour = value.substring(0, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        }
      } else {
        setWednesdayEndTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftEndMeridian = 'pm'
          obj.endMinute = value.substring(3, 5)
        } else {
          obj.endHour = value.substring(0, 2)
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        }
      }
      if (arr.length > 0) {
        arr.map(a => {
          if (a.day == 'wednesday') {
            a.endHour = obj.endHour ? obj.endHour : 0
            a.endMinute = obj.endMinute ? obj.endMinute : 0
            a.startHour = obj.startHour ? obj.startHour : 0
            a.startMinute = obj.startMinute ? obj.startMinute : 0
            a.shiftEndMeridian = obj.shiftEndMeridian
            a.shiftStartMeridian = obj.shiftStartMeridian
          }
        })
      }
      setWednesdayTimeObject({ ...obj })
    } else if (day == 'thursday') {
      let arr = weeklyShiftPayload
      let obj = thursdayTimeObject
      if (type == 'start') {
        setThursdayStartTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.startHour = 12
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.startHour = value.substring(1, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftStartMeridian = 'pm'
          obj.startMinute = value.substring(3, 5)
        } else {
          obj.startHour = value.substring(0, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        }
      } else {
        setThursdayEndTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftEndMeridian = 'pm'
          obj.endMinute = value.substring(3, 5)
        } else {
          obj.endHour = value.substring(0, 2)
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        }
      }
      if (arr.length > 0) {
        arr.map(a => {
          if (a.day == 'thursday') {
            a.endHour = obj.endHour ? obj.endHour : 0
            a.endMinute = obj.endMinute ? obj.endMinute : 0
            a.startHour = obj.startHour ? obj.startHour : 0
            a.startMinute = obj.startMinute ? obj.startMinute : 0
            a.shiftEndMeridian = obj.shiftEndMeridian
            a.shiftStartMeridian = obj.shiftStartMeridian
          }
        })
      }
      setThursdayTimeObject({ ...obj })
    } else if (day == 'friday') {
      let arr = weeklyShiftPayload
      let obj = fridayTimeObject
      if (type == 'start') {
        setFridayStartTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.startHour = 12
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.startHour = value.substring(1, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftStartMeridian = 'pm'
          obj.startMinute = value.substring(3, 5)
        } else {
          obj.startHour = value.substring(0, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        }
      } else {
        setFridayEndTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftEndMeridian = 'pm'
          obj.endMinute = value.substring(3, 5)
        } else {
          obj.endHour = value.substring(0, 2)
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        }
      }
      if (arr.length > 0) {
        arr.map(a => {
          if (a.day == 'friday') {
            a.endHour = obj.endHour ? obj.endHour : 0
            a.endMinute = obj.endMinute ? obj.endMinute : 0
            a.startHour = obj.startHour ? obj.startHour : 0
            a.startMinute = obj.startMinute ? obj.startMinute : 0
            a.shiftEndMeridian = obj.shiftEndMeridian
            a.shiftStartMeridian = obj.shiftStartMeridian
          }
        })
      }
      setFridayTimeObject({ ...obj })
    } else if (day == 'saturday') {
      let arr = weeklyShiftPayload
      let obj = saturdayTimeObject
      if (type == 'start') {
        setSaturdayStartTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.startHour = 12
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.startHour = value.substring(1, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftStartMeridian = 'pm'
          obj.startMinute = value.substring(3, 5)
        } else {
          obj.startHour = value.substring(0, 2)
          obj.shiftStartMeridian = 'am'
          obj.startMinute = value.substring(3, 5)
        }
      } else {
        setSaturdayEndTime(value)
        if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 1) == 0) {
          obj.endHour = 12
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        } else if (value.substring(0, 2) > 12) {
          obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
          obj.shiftEndMeridian = 'pm'
          obj.endMinute = value.substring(3, 5)
        } else {
          obj.endHour = value.substring(0, 2)
          obj.shiftEndMeridian = 'am'
          obj.endMinute = value.substring(3, 5)
        }
      }
      if (arr.length > 0) {
        arr.map(a => {
          if (a.day == 'saturday') {
            a.endHour = obj.endHour ? obj.endHour : 0
            a.endMinute = obj.endMinute ? obj.endMinute : 0
            a.startHour = obj.startHour ? obj.startHour : 0
            a.startMinute = obj.startMinute ? obj.startMinute : 0
            a.shiftEndMeridian = obj.shiftEndMeridian
            a.shiftStartMeridian = obj.shiftStartMeridian
          }
        })
      }
      setSaturdayTimeObject({ ...obj })
    }
  }

  const updateWeeklyShiftData = () => {
    if (weeklyShiftPayload.length == 0) {
      toast.error('Weekly Shift data cannot be empty')
      return
    }
    if (isSundayChecked) {
      if (sundayTimeObject.startHour == undefined) {
        toast.error('Hour field for start time cannot be empty for Sunday')
        return
      } else if (sundayTimeObject.startMinute == undefined) {
        toast.error('Minute field for start time cannot be empty for Sunday')
        return
      } else if (sundayTimeObject.endHour == undefined) {
        toast.error('Hour field for end time cannot be empty for Sunday')
        return
      } else if (sundayTimeObject.endMinute == undefined) {
        toast.error('Minute field for end time cannot be empty for Sunday')
        return
      } else if (sundayTimeObject.shiftStartMeridian == undefined) {
        toast.error('Meridian field for start time cannot be empty for Sunday')
        return
      } else if (sundayTimeObject.shiftEndMeridian == undefined) {
        toast.error('Meridian field for end time cannot be empty for Sunday')
        return
      }
    }
    if (isMondayChecked) {
      if (mondayTimeObject.startHour == undefined) {
        toast.error('Hour field for start time cannot be empty for Monday')
        return
      } else if (mondayTimeObject.startMinute == undefined) {
        toast.error('Minute field for start time cannot be empty for Monday')
        return
      } else if (mondayTimeObject.endHour == undefined) {
        toast.error('Hour field for end time cannot be empty for Monday')
        return
      } else if (mondayTimeObject.endMinute == undefined) {
        toast.error('Minute field for end time cannot be empty for Monday')
        return
      } else if (mondayTimeObject.shiftStartMeridian == undefined) {
        toast.error('Meridian field for start time cannot be empty for Monday')
        return
      } else if (mondayTimeObject.shiftEndMeridian == undefined) {
        toast.error('Meridian field for end time cannot be empty for Monday')
        return
      }
    }
    if (isTuesdayChecked) {
      if (tuesdayTimeObject.startHour == undefined) {
        toast.error('Hour field for start time cannot be empty for Tuesday')
        return
      } else if (tuesdayTimeObject.startMinute == undefined) {
        toast.error('Minute field for start time cannot be empty for Tuesday')
        return
      } else if (tuesdayTimeObject.endHour == undefined) {
        toast.error('Hour field for end time cannot be empty for Tuesday')
        return
      } else if (tuesdayTimeObject.endMinute == undefined) {
        toast.error('Minute field for end time cannot be empty for Tuesday')
        return
      } else if (tuesdayTimeObject.shiftStartMeridian == undefined) {
        toast.error('Meridian field for start time cannot be empty for Tuesday')
        return
      } else if (tuesdayTimeObject.shiftEndMeridian == undefined) {
        toast.error('Meridian field for end time cannot be empty for Tuesday')
        return
      }
    }
    if (isWednesdayChecked) {
      if (wednesdayTimeObject.startHour == undefined) {
        toast.error('Hour field for start time cannot be empty for Wednesday')
        return
      } else if (wednesdayTimeObject.startMinute == undefined) {
        toast.error('Minute field for start time cannot be empty for Wednesday')
        return
      } else if (wednesdayTimeObject.endHour == undefined) {
        toast.error('Hour field for end time cannot be empty for Wednesday')
        return
      } else if (wednesdayTimeObject.endMinute == undefined) {
        toast.error('Minute field for end time cannot be empty for Wednesday')
        return
      } else if (wednesdayTimeObject.shiftStartMeridian == undefined) {
        toast.error('Meridian field for start time cannot be empty for Wednesday')
        return
      } else if (wednesdayTimeObject.shiftEndMeridian == undefined) {
        toast.error('Meridian field for end time cannot be empty for Wednesday')
        return
      }
    }
    if (isThursdayChecked) {
      if (thursdayTimeObject.startHour == undefined) {
        toast.error('Hour field for start time cannot be empty for Thursday')
        return
      } else if (thursdayTimeObject.startMinute == undefined) {
        toast.error('Minute field for start time cannot be empty for Thursday')
        return
      } else if (thursdayTimeObject.endHour == undefined) {
        toast.error('Hour field for end time cannot be empty for Thursday')
        return
      } else if (thursdayTimeObject.endMinute == undefined) {
        toast.error('Minute field for end time cannot be empty for Thursday')
        return
      } else if (thursdayTimeObject.shiftStartMeridian == undefined) {
        toast.error('Meridian field for start time cannot be empty for Thursday')
        return
      } else if (thursdayTimeObject.shiftEndMeridian == undefined) {
        toast.error('Meridian field for end time cannot be empty for Thursday')
        return
      }
    }
    if (isFridayChecked) {
      if (fridayTimeObject.startHour == undefined) {
        toast.error('Hour field for start time cannot be empty for Friday')
        return
      } else if (fridayTimeObject.startMinute == undefined) {
        toast.error('Minute field for start time cannot be empty for Friday')
        return
      } else if (fridayTimeObject.endHour == undefined) {
        toast.error('Hour field for end time cannot be empty for Friday')
        return
      } else if (fridayTimeObject.endMinute == undefined) {
        toast.error('Minute field for end time cannot be empty for Friday')
        return
      } else if (fridayTimeObject.shiftStartMeridian == undefined) {
        toast.error('Meridian field for start time cannot be empty for Friday')
        return
      } else if (fridayTimeObject.shiftEndMeridian == undefined) {
        toast.error('Meridian field for end time cannot be empty for Friday')
        return
      }
    }
    if (isSaturdayChecked) {
      if (saturdayTimeObject.startHour == undefined) {
        toast.error('Hour field for start time cannot be empty for Saturday')
        return
      } else if (saturdayTimeObject.startMinute == undefined) {
        toast.error('Minute field for start time cannot be empty for Saturday')
        return
      } else if (saturdayTimeObject.endHour == undefined) {
        toast.error('Hour field for end time cannot be empty for Saturday')
        return
      } else if (saturdayTimeObject.endMinute == undefined) {
        toast.error('Minute field for end time cannot be empty for Saturday')
        return
      } else if (saturdayTimeObject.shiftStartMeridian == undefined) {
        toast.error('Meridian field for start time cannot be empty for Saturday')
        return
      } else if (saturdayTimeObject.shiftEndMeridian == undefined) {
        toast.error('Meridian field for end time cannot be empty for Saturday')
        return
      }
    }

    let params = {
      userProfileId: userData.id,
      payload: weeklyShiftPayload
    }

    setLoadingUpdateWeeklyShiftData(true)
    setUpdateWeeklyShiftDataDisabled(true)

    if (initialWeeklyShiftPayload.length > 0) {
      props.updateWeeklyShiftDataForActiveDaysList(params, res => {
        if (res.status) {
          setShiftModal(false)
          setLoadingUpdateWeeklyShiftData(false)
          swal({
            title: "Weekly Shift data updated!",
            text: "",
            icon: "success"
          })
        }
      })
    } else {
      props.updateWeeklyShiftDataForEmptyActiveDaysList(params, res => {
        if (res.status) {
          setShiftModal(false)
          setLoadingUpdateWeeklyShiftData(false)
          swal({
            title: "Weekly Shift data updated!",
            text: "",
            icon: "success"
          })
        }
      })
    }
  }

  useEffect(() => {
    getUserProfileList();
  }, []);

  const openAvailModal = (item) => {
    setPostModal(true)
    getDailyShiftDetails({ userProfileId: item.id })
  }

  const closeAvailModal = () => {
    setPostModal(false)
    setPartTimeData([])
    setContractData([])
    setFullTimeData([])
    // setCreateDisabled(true)
    // setContractStartDate()
    // setContractStartTime()
    // setShowContractStartDate()
    // setFinalContractStartDate()
    // setContractEndDate()
    // setContractEndTime()
    // setShowContractEndDate()
    // setFinalContractEndDate()
    // setFullTimeStartDate()
    // setFullTimeStartTime()
    // setShowFullTimeStartDate()
    // setFinalFullTimeStartDate()

    // setPartTimeStartDate()
    // setPartTimeInputDateFlag(false)

    // setShiftCount(0)

    // setPartTimeStartTime1()
    // setPartTimeEndTime1()
    // setPartTimeStartTime2()
    // setPartTimeEndTime2()
    // setPartTimeStartTime3()
    // setPartTimeEndTime3()
    // setPartTimeStartTime4()
    // setPartTimeEndTime4()
    // setPartTimeStartTime5()
    // setPartTimeEndTime5()

    // setFinalPartTimeStartDate1()
    // setFinalPartTimeEndDate1()
    // setFinalPartTimeStartDate2()
    // setFinalPartTimeEndDate2()
    // setFinalPartTimeStartDate3()
    // setFinalPartTimeEndDate3()
    // setFinalPartTimeStartDate4()
    // setFinalPartTimeEndDate4()
    // setFinalPartTimeStartDate5()
    // setFinalPartTimeEndDate5()

    // setPartTimeProviderCount1()
    // setPartTimeProviderCount2()
    // setPartTimeProviderCount3()
    // setPartTimeProviderCount4()
    // setPartTimeProviderCount5()

    // setPartTimeStartTimeFlag1(false)
    // setPartTimeEndTimeFlag1(false)
    // setPartTimeStartTimeFlag2(false)
    // setPartTimeEndTimeFlag2(false)
    // setPartTimeStartTimeFlag3(false)
    // setPartTimeEndTimeFlag3(false)
    // setPartTimeStartTimeFlag4(false)
    // setPartTimeEndTimeFlag4(false)
    // setPartTimeStartTimeFlag5(false)
    // setPartTimeEndTimeFlag5(false)

    // setShift2Flag(false)
    // setShift3Flag(false)
    // setShift4Flag(false)
    // setShift5Flag(false)
    // setPostModal(!postModal);
    document.getElementById('kt_content').setAttribute('style', '')

  }

  const openShiftModal = (item) => {
    setShiftModal(true);
    setUserData(item);
    getDaysList();
    getActiveDaysList(item.id);
    setWeeklyShiftPayload([])
    setInitialWeeklyShiftPayload([])
    setSundayStartTime()
    setMondayStartTime()
    setTuesdayStartTime()
    setWednesdayStartTime()
    setThursdayStartTime()
    setFridayStartTime()
    setSaturdayStartTime()
    setSundayEndTime()
    setMondayEndTime()
    setTuesdayEndTime()
    setWednesdayEndTime()
    setThursdayEndTime()
    setFridayEndTime()
    setSaturdayEndTime()
    setSundayTimeObject({})
    setMondayTimeObject({})
    setTuesdayTimeObject({})
    setWednesdayTimeObject({})
    setThursdayTimeObject({})
    setFridayTimeObject({})
    setSaturdayTimeObject({})
  }

  const closeShiftModal = () => {
    setShiftModal(false);
    setUserData({});
    setIsSundayChecked(false)
    setIsMondayChecked(false)
    setIsTuesdayChecked(false)
    setIsWednesdayChecked(false)
    setIsThursdayChecked(false)
    setIsFridayChecked(false)
    setIsSaturdayChecked(false)
    setShiftModal(false)
  }

  const toggleSwitch = (day) => {
    setUpdateWeeklyShiftDataDisabled(false)
    let arr = weeklyShiftPayload
    if (day == 'sunday') {
      if (isSundayChecked) {
        arr = weeklyShiftPayload.filter(x => x.day !== day)
        setWeeklyShiftPayload(arr)
      } else {
        arr.push({
          active: 1,
          day: day,
          endHour: sundayTimeObject.endHour,
          endMinute: sundayTimeObject.endMinute,
          shiftEndMeridian: sundayTimeObject.shiftEndMeridian,
          shiftStartMeridian: sundayTimeObject.shiftStartMeridian,
          startHour: sundayTimeObject.startHour,
          startMinute: sundayTimeObject.startMinute,
        })
      }
      setWeeklyShiftPayload(arr)
      setIsSundayChecked(!isSundayChecked)
    } else if (day == 'monday') {
      if (isMondayChecked) {
        arr = weeklyShiftPayload.filter(x => x.day !== day)
        setWeeklyShiftPayload(arr)
      } else {
        arr.push({
          active: 1,
          day: day,
          endHour: mondayTimeObject.endHour,
          endMinute: mondayTimeObject.endMinute,
          shiftEndMeridian: mondayTimeObject.shiftEndMeridian,
          shiftStartMeridian: mondayTimeObject.shiftStartMeridian,
          startHour: mondayTimeObject.startHour,
          startMinute: mondayTimeObject.startMinute,
        })
      }
      setWeeklyShiftPayload(arr)
      setIsMondayChecked(!isMondayChecked)
    } else if (day == 'tuesday') {
      if (isTuesdayChecked) {
        arr = weeklyShiftPayload.filter(x => x.day !== day)
        setWeeklyShiftPayload(arr)
      } else {
        arr.push({
          active: 1,
          day: day,
          endHour: tuesdayTimeObject.endHour,
          endMinute: tuesdayTimeObject.endMinute,
          shiftEndMeridian: tuesdayTimeObject.shiftEndMeridian,
          shiftStartMeridian: tuesdayTimeObject.shiftStartMeridian,
          startHour: tuesdayTimeObject.startHour,
          startMinute: tuesdayTimeObject.startMinute,
        })
      }
      setWeeklyShiftPayload(arr)
      setIsTuesdayChecked(!isTuesdayChecked)
    } else if (day == 'wednesday') {
      if (isWednesdayChecked) {
        arr = weeklyShiftPayload.filter(x => x.day !== day)
        setWeeklyShiftPayload(arr)
      } else {
        arr.push({
          active: 1,
          day: day,
          endHour: wednesdayTimeObject.endHour,
          endMinute: wednesdayTimeObject.endMinute,
          shiftEndMeridian: wednesdayTimeObject.shiftEndMeridian,
          shiftStartMeridian: wednesdayTimeObject.shiftStartMeridian,
          startHour: wednesdayTimeObject.startHour,
          startMinute: wednesdayTimeObject.startMinute,
        })
      }
      setWeeklyShiftPayload(arr)
      setIsWednesdayChecked(!isWednesdayChecked)
    } else if (day == 'thursday') {
      if (isThursdayChecked) {
        arr = weeklyShiftPayload.filter(x => x.day !== day)
        setWeeklyShiftPayload(arr)
      } else {
        arr.push({
          active: 1,
          day: day,
          endHour: thursdayTimeObject.endHour,
          endMinute: thursdayTimeObject.endMinute,
          shiftEndMeridian: thursdayTimeObject.shiftEndMeridian,
          shiftStartMeridian: thursdayTimeObject.shiftStartMeridian,
          startHour: thursdayTimeObject.startHour,
          startMinute: thursdayTimeObject.startMinute,
        })
      }
      setWeeklyShiftPayload(arr)
      setIsThursdayChecked(!isThursdayChecked)
    } else if (day == 'friday') {
      if (isFridayChecked) {
        arr = weeklyShiftPayload.filter(x => x.day !== day)
        setWeeklyShiftPayload(arr)
      } else {
        arr.push({
          active: 1,
          day: day,
          endHour: fridayTimeObject.endHour,
          endMinute: fridayTimeObject.endMinute,
          shiftEndMeridian: fridayTimeObject.shiftEndMeridian,
          shiftStartMeridian: fridayTimeObject.shiftStartMeridian,
          startHour: fridayTimeObject.startHour,
          startMinute: fridayTimeObject.startMinute,
        })
      }
      setWeeklyShiftPayload(arr)
      setIsFridayChecked(!isFridayChecked)
    } else if (day == 'saturday') {
      if (isSaturdayChecked) {
        arr = weeklyShiftPayload.filter(x => x.day !== day)
        setWeeklyShiftPayload(arr)
      } else {
        arr.push({
          active: 1,
          day: day,
          endHour: saturdayTimeObject.endHour,
          endMinute: saturdayTimeObject.endMinute,
          shiftEndMeridian: saturdayTimeObject.shiftEndMeridian,
          shiftStartMeridian: saturdayTimeObject.shiftStartMeridian,
          startHour: saturdayTimeObject.startHour,
          startMinute: saturdayTimeObject.startMinute,
        })
      }
      setWeeklyShiftPayload(arr)
      setIsSaturdayChecked(!isSaturdayChecked)
    }
  }

  let contractObject = {}
  let fullTimeObject = {}
  let partTimeObject1 = {}
  let partTimeObject2 = {}
  let partTimeObject3 = {}
  let partTimeObject4 = {}
  let partTimeObject5 = {}

  const changeStartDateForContract = (value) => {
    setContractStartDate(value)
    setContractStartTimeFlag(true)
    setContractStartTime()
  }
  const changeStartTimeForContract = (value) => {
    setContractStartTime(value)
  }
  const selectStartTimeForContract = () => {
    if (contractStartTime == null) {
      toast.error('Please select Contract Start Time')
      return
    }
    setFinalContractStartDate(contractStartDate + ', ' + contractStartTime)
    setShowContractStartDate(contractStartDate.toString().substring(4, 15) + ', ' + contractStartTime)
    setContractStartTimeFlag(false)
  }

  //CONTRACT SHIFT END DATE AND TIME
  const changeEndDateForContract = (value) => {
    setContractEndDate(value)
    setContractEndTimeFlag(true)
    setContractEndTime()
  }
  const changeEndTimeForContract = (value) => {
    setContractEndTime(value)
  }
  const selectEndTimeForContract = () => {
    if (contractEndTime == null) {
      toast.error('Please select Contract End Time')
      return
    }
    setFinalContractEndDate(contractEndDate + ', ' + contractEndTime)
    setShowContractEndDate(contractEndDate.toString().substring(4, 15) + ', ' + contractEndTime)
    setContractEndTimeFlag(false)
  }

  //CONTRACT PROVIDER COUNT
  const changeContractProviderCount = (e) => {
    e.preventDefault()
    setContractProviderCount(parseInt(e.target.value))
  }

  //FULL TIME SHIFT START DATE AND TIME
  const changeStartDateForFullTime = (value) => {
    setFullTimeStartDate(value)
    setFullTimeStartTimeFlag(true)
    setFullTimeStartTime()
  }
  const changeStartTimeForFullTime = (value) => {
    setFullTimeStartTime(value)
  }
  const selectStartTimeForFullTime = () => {
    if (fullTimeStartTime == null) {
      toast.error('Please select Full Time Start Time')
      return
    }
    setFinalFullTimeStartDate(fullTimeStartDate + ', ' + fullTimeStartTime)
    setShowFullTimeStartDate(fullTimeStartDate.toString().substring(4, 15) + ', ' + fullTimeStartTime)
    setFullTimeStartTimeFlag(false)
  }

  //FULL TIME PROVIDER COUNT
  const changeFullTimeProviderCount = (e) => {
    e.preventDefault()
    setFullTimeProviderCount(parseInt(e.target.value))
  }

  //PART TIME START TIME SHIFT 1
  const changeDateForPartTime = (value) => {
    console.log('PART TIME', value)
    setFinalPartTimeStartDate(value)
    setPartTimeStartDate(value.toString().substring(4, 15))
    setPartTimeInputDateFlag(true)
  }
  const selectStartTimeForPartTime1 = () => {
    if (partTimeStartTime1 == null) {
      toast.error('Please select Part Time Start Time for Shift 1')
      return
    }
    setFinalPartTimeStartDate1(finalPartTimeStartDate + ', ' + partTimeStartTime1)
    setPartTimeStartTimeFlag1(true)
    setPartTimeStartTime1Modal(false)
  }
  const openPartTimeStartTime1Modal = () => {
    setPartTimeStartTime1Modal(true)
  }
  const changeStartTime1ForPartTime = (value) => {
    setPartTimeStartTime1(value)
  }
  //PART TIME END TIME SHIFT 1
  const selectEndTimeForPartTime1 = () => {
    if (partTimeEndTime1 == null) {
      toast.error('Please select Part Time End Time for Shift 1')
      return
    }
    setFinalPartTimeEndDate1(finalPartTimeStartDate + ', ' + partTimeEndTime1)
    setPartTimeEndTimeFlag1(true)
    setPartTimeEndTime1Modal(false)
  }
  const openPartTimeEndTime1Modal = () => {
    setPartTimeEndTime1Modal(true)
  }
  const changeEndTime1ForPartTime = (value) => {
    setPartTimeEndTime1(value)
  }
  //PART TIME PROVIDER COUNT 1
  const changePartTimeProviderCount1 = (e) => {
    e.preventDefault()
    setPartTimeProviderCount1(parseInt(e.target.value))
  }

  //PART TIME START TIME SHIFT 2
  const selectStartTimeForPartTime2 = () => {
    if (partTimeStartTime2 == null) {
      toast.error('Please select Part Time Start Time for Shift 2')
      return
    }
    setFinalPartTimeStartDate2(finalPartTimeStartDate + ', ' + partTimeStartTime2)
    setPartTimeStartTimeFlag2(true)
    setPartTimeStartTime2Modal(false)
  }
  const openPartTimeStartTime2Modal = () => {
    setPartTimeStartTime2Modal(true)
  }
  const changeStartTime2ForPartTime = (value) => {
    setPartTimeStartTime2(value)
  }
  //PART TIME END TIME SHIFT 1
  const selectEndTimeForPartTime2 = () => {
    if (partTimeEndTime2 == null) {
      toast.error('Please select Part Time End Time for Shift 2')
      return
    }
    setFinalPartTimeEndDate2(finalPartTimeStartDate + ', ' + partTimeEndTime2)
    setPartTimeEndTimeFlag2(true)
    setPartTimeEndTime2Modal(false)
  }
  const openPartTimeEndTime2Modal = () => {
    setPartTimeEndTime2Modal(true)
  }
  const changeEndTime2ForPartTime = (value) => {
    setPartTimeEndTime2(value)
  }
  //PART TIME PROVIDER COUNT 1
  const changePartTimeProviderCount2 = (e) => {
    e.preventDefault()
    setPartTimeProviderCount2(parseInt(e.target.value))
  }

  //PART TIME START TIME SHIFT 3
  const selectStartTimeForPartTime3 = () => {
    if (partTimeStartTime3 == null) {
      toast.error('Please select Part Time Start Time for Shift 3')
      return
    }
    setFinalPartTimeStartDate3(finalPartTimeStartDate + ', ' + partTimeStartTime3)
    setPartTimeStartTimeFlag3(true)
    setPartTimeStartTime3Modal(false)
  }
  const openPartTimeStartTime3Modal = () => {
    setPartTimeStartTime3Modal(true)
  }
  const changeStartTime3ForPartTime = (value) => {
    setPartTimeStartTime3(value)
  }
  //PART TIME END TIME SHIFT 1
  const selectEndTimeForPartTime3 = () => {
    if (partTimeEndTime3 == null) {
      toast.error('Please select Part Time End Time for Shift 3')
      return
    }
    setFinalPartTimeEndDate3(finalPartTimeStartDate + ', ' + partTimeEndTime3)
    setPartTimeEndTimeFlag3(true)
    setPartTimeEndTime3Modal(false)
  }
  const openPartTimeEndTime3Modal = () => {
    setPartTimeEndTime3Modal(true)
  }
  const changeEndTime3ForPartTime = (value) => {
    setPartTimeEndTime3(value)
  }
  //PART TIME PROVIDER COUNT 1
  const changePartTimeProviderCount3 = (e) => {
    e.preventDefault()
    setPartTimeProviderCount3(parseInt(e.target.value))
  }

  //PART TIME START TIME SHIFT 4
  const selectStartTimeForPartTime4 = () => {
    if (partTimeStartTime4 == null) {
      toast.error('Please select Part Time Start Time for Shift 4')
      return
    }
    setFinalPartTimeStartDate4(finalPartTimeStartDate + ', ' + partTimeStartTime4)
    setPartTimeStartTimeFlag4(true)
    setPartTimeStartTime4Modal(false)
  }
  const openPartTimeStartTime4Modal = () => {
    setPartTimeStartTime4Modal(true)
  }
  const changeStartTime4ForPartTime = (value) => {
    setPartTimeStartTime4(value)
  }
  //PART TIME END TIME SHIFT 4
  const selectEndTimeForPartTime4 = () => {
    if (partTimeEndTime4 == null) {
      toast.error('Please select Part Time End Time for Shift 4')
      return
    }
    setFinalPartTimeEndDate4(finalPartTimeStartDate + ', ' + partTimeEndTime4)
    setPartTimeEndTimeFlag4(true)
    setPartTimeEndTime4Modal(false)
  }
  const openPartTimeEndTime4Modal = () => {
    setPartTimeEndTime4Modal(true)
  }
  const changeEndTime4ForPartTime = (value) => {
    setPartTimeEndTime4(value)
  }
  //PART TIME PROVIDER COUNT 4
  const changePartTimeProviderCount4 = (e) => {
    e.preventDefault()
    setPartTimeProviderCount4(parseInt(e.target.value))
  }

  //PART TIME START TIME SHIFT 5
  const selectStartTimeForPartTime5 = () => {
    if (partTimeStartTime5 == null) {
      toast.error('Please select Part Time Start Time for Shift 5')
      return
    }
    setFinalPartTimeStartDate5(finalPartTimeStartDate + ', ' + partTimeStartTime5)
    setPartTimeStartTimeFlag5(true)
    setPartTimeStartTime5Modal(false)
  }
  const openPartTimeStartTime5Modal = () => {
    setPartTimeStartTime5Modal(true)
  }
  const changeStartTime5ForPartTime = (value) => {
    setPartTimeStartTime5(value)
  }
  //PART TIME END TIME SHIFT 5
  const selectEndTimeForPartTime5 = () => {
    if (partTimeEndTime5 == null) {
      toast.error('Please select Part Time End Time for Shift 5')
      return
    }
    setFinalPartTimeEndDate5(finalPartTimeStartDate + ', ' + partTimeEndTime5)
    setPartTimeEndTimeFlag5(true)
    setPartTimeEndTime5Modal(false)
  }
  const openPartTimeEndTime5Modal = () => {
    setPartTimeEndTime5Modal(true)
  }
  const changeEndTime5ForPartTime = (value) => {
    setPartTimeEndTime5(value)
  }
  //PART TIME PROVIDER COUNT 5
  const changePartTimeProviderCount5 = (e) => {
    e.preventDefault()
    setPartTimeProviderCount5(parseInt(e.target.value))
  }

  const addAnotherShift = (e) => {
    if (shiftCount == 0) {
      setShift2Flag(true)
      setShiftCount(1)
    } else if (shiftCount == 1) {
      setShift3Flag(true)
      setShiftCount(2)
    } else if (shiftCount == 2) {
      setShift4Flag(true)
      setShiftCount(3)
    } else if (shiftCount == 3) {
      setShift5Flag(true)
      setShiftCount(4)
    }
  }

  const onPostDateAndTime = () => {
    if (employmentTypeData.length > 0) {
      let arr = []
      for (let i = 0; i < employmentTypeData.length; i++) {
        if (employmentTypeData[i].employmentTypeId == 3) {
          if (finalContractStartDate == null || finalContractStartDate == undefined) {
            toast.error('Please select start date and time for Contract')
            break
          } else if (finalContractEndDate == null || finalContractEndDate == undefined) {
            toast.error('Please select end date and time for Contract')
            break
          } else if (contractProviderCount == null || contractProviderCount == undefined) {
            toast.error('Please enter number of providers for Contract')
            break
          } else {
            contractObject.employmentTypeId = 3
            contractObject.startTime = finalContractStartDate.toString().substring(0, 3) + ', ' + finalContractStartDate.toString().substring(8, 10) + ' ' + finalContractStartDate.toString().substring(4, 7) + ' ' + finalContractStartDate.toString().substring(11, 15) + ' ' + contractStartTime + ':00 IST'
            contractObject.endTime = finalContractEndDate.toString().substring(0, 3) + ', ' + finalContractEndDate.toString().substring(8, 10) + ' ' + finalContractEndDate.toString().substring(4, 7) + ' ' + finalContractEndDate.toString().substring(11, 15) + ' ' + contractEndTime + ':00 IST'
            contractObject.providerCount = contractProviderCount
            arr.push(contractObject)
          }


        }
        if (employmentTypeData[i].employmentTypeId == 1) {
          if (finalfullTimeStartDate == null || finalfullTimeStartDate == undefined) {
            toast.error('Please select start date and time for Full Time')
            break
          } else if (fullTimeProviderCount == null || fullTimeProviderCount == undefined) {
            toast.error('Please enter number of providers for Full Time')
            break
          } else {
            fullTimeObject.employmentTypeId = 1
            fullTimeObject.startTime = finalfullTimeStartDate.toString().substring(0, 3) + ', ' + finalfullTimeStartDate.toString().substring(8, 10) + ' ' + finalfullTimeStartDate.toString().substring(4, 7) + ' ' + finalfullTimeStartDate.toString().substring(11, 15) + ' ' + fullTimeStartTime + ':00 IST'
            fullTimeObject.endTime = finalfullTimeStartDate.toString().substring(0, 3) + ', ' + finalfullTimeStartDate.toString().substring(8, 10) + ' ' + finalfullTimeStartDate.toString().substring(4, 7) + ' ' + finalfullTimeStartDate.toString().substring(11, 15) + ' ' + fullTimeStartTime + ':00 IST'
            fullTimeObject.providerCount = fullTimeProviderCount
            arr.push(fullTimeObject)
          }


        }
        if (employmentTypeData[i].employmentTypeId == 2) {
          if (finalPartTimeStartDate1 == null || finalPartTimeStartDate1 == undefined) {
            toast.error('Please select start date and time for Part Time Shift 1')
            break
          } else if (finalPartTimeEndDate1 == null || finalPartTimeEndDate1 == undefined) {
            toast.error('Please select end date and time for Part Time Shift 1')
            break
          } else if (partTimeProviderCount1 == null || partTimeProviderCount1 == undefined) {
            toast.error('Please enter number of providers for Part Time Shift 1')
            break
          } else {
            console.log(finalPartTimeStartDate1)
            partTimeObject1.employmentTypeId = 2
            partTimeObject1.startTime = finalPartTimeStartDate1.toString().substring(0, 3) + ', ' + finalPartTimeStartDate1.toString().substring(8, 10) + ' ' + finalPartTimeStartDate1.toString().substring(4, 7) + ' ' + finalPartTimeStartDate1.toString().substring(11, 15) + ' ' + partTimeStartTime1 + ':00 IST'
            partTimeObject1.endTime = finalPartTimeEndDate1.toString().substring(0, 3) + ', ' + finalPartTimeEndDate1.toString().substring(8, 10) + ' ' + finalPartTimeEndDate1.toString().substring(4, 7) + ' ' + finalPartTimeEndDate1.toString().substring(11, 15) + ' ' + partTimeEndTime1 + ':00 IST'
            partTimeObject1.providerCount = partTimeProviderCount1
            arr.push(partTimeObject1)
          }

          if (finalPartTimeStartDate2 != undefined || finalPartTimeEndDate2 != undefined) {
            if (finalPartTimeStartDate2 == null || finalPartTimeStartDate2 == undefined) {
              toast.error('Please select start date and time for Part Time Shift 2')
              break
            } else if (finalPartTimeEndDate2 == null || finalPartTimeEndDate2 == undefined) {
              toast.error('Please select end date and time for Part Time Shift 2')
              break
            } else if (partTimeProviderCount2 == null || partTimeProviderCount2 == undefined) {
              toast.error('Please enter number of providers for Part Time Shift 2')
              break
            } else {
              partTimeObject2.employmentTypeId = 2
              partTimeObject2.startTime = finalPartTimeStartDate2.toString().substring(0, 3) + ', ' + finalPartTimeStartDate2.toString().substring(8, 10) + ' ' + finalPartTimeStartDate2.toString().substring(4, 7) + ' ' + finalPartTimeStartDate2.toString().substring(11, 15) + ' ' + partTimeStartTime2 + ':00 IST'
              partTimeObject2.endTime = finalPartTimeEndDate2.toString().substring(0, 3) + ', ' + finalPartTimeEndDate2.toString().substring(8, 10) + ' ' + finalPartTimeEndDate2.toString().substring(4, 7) + ' ' + finalPartTimeEndDate2.toString().substring(11, 15) + ' ' + partTimeEndTime2 + ':00 IST'
              partTimeObject2.providerCount = partTimeProviderCount2
              arr.push(partTimeObject2)
            }


          }
          if (finalPartTimeStartDate3 != undefined || finalPartTimeEndDate3 != undefined) {
            if (finalPartTimeStartDate3 == null || finalPartTimeStartDate3 == undefined) {
              toast.error('Please select start date and time for Part Time Shift 3')
              break
            } else if (finalPartTimeEndDate3 == null || finalPartTimeEndDate3 == undefined) {
              toast.error('Please select end date and time for Part Time Shift 3')
              break
            } else if (partTimeProviderCount3 == null || partTimeProviderCount3 == undefined) {
              toast.error('Please enter number of providers for Part Time Shift 3')
              break
            } else {
              partTimeObject3.employmentTypeId = 2
              partTimeObject3.startTime = finalPartTimeStartDate3.toString().substring(0, 3) + ', ' + finalPartTimeStartDate3.toString().substring(8, 10) + ' ' + finalPartTimeStartDate3.toString().substring(4, 7) + ' ' + finalPartTimeStartDate3.toString().substring(11, 15) + ' ' + partTimeStartTime3 + ':00 IST'
              partTimeObject3.endTime = finalPartTimeEndDate3.toString().substring(0, 3) + ', ' + finalPartTimeEndDate3.toString().substring(8, 10) + ' ' + finalPartTimeEndDate3.toString().substring(4, 7) + ' ' + finalPartTimeEndDate3.toString().substring(11, 15) + ' ' + partTimeEndTime3 + ':00 IST'
              partTimeObject3.providerCount = partTimeProviderCount3
              arr.push(partTimeObject3)
            }


          }
          if (finalPartTimeStartDate4 != undefined || finalPartTimeEndDate4 != undefined) {
            if (finalPartTimeStartDate4 == null || finalPartTimeStartDate4 == undefined) {
              toast.error('Please select start date and time for Part Time Shift 4')
              break
            } else if (finalPartTimeEndDate4 == null || finalPartTimeEndDate4 == undefined) {
              toast.error('Please select end date and time for Part Time Shift 4')
              break
            } else if (partTimeProviderCount4 == null || partTimeProviderCount4 == undefined) {
              toast.error('Please enter number of providers for Part Time Shift 4')
              break
            } else {
              partTimeObject4.employmentTypeId = 2
              partTimeObject4.startTime = finalPartTimeStartDate4.toString().substring(0, 3) + ', ' + finalPartTimeStartDate4.toString().substring(8, 10) + ' ' + finalPartTimeStartDate4.toString().substring(4, 7) + ' ' + finalPartTimeStartDate4.toString().substring(11, 15) + ' ' + partTimeStartTime4 + ':00 IST'
              partTimeObject4.endTime = finalPartTimeEndDate4.toString().substring(0, 3) + ', ' + finalPartTimeEndDate4.toString().substring(8, 10) + ' ' + finalPartTimeEndDate4.toString().substring(4, 7) + ' ' + finalPartTimeEndDate4.toString().substring(11, 15) + ' ' + partTimeEndTime4 + ':00 IST'
              partTimeObject4.providerCount = partTimeProviderCount4
              arr.push(partTimeObject4)
            }


          }
          if (finalPartTimeStartDate5 != undefined || finalPartTimeEndDate5 != undefined) {
            if (finalPartTimeStartDate5 == null || finalPartTimeStartDate5 == undefined) {
              toast.error('Please select start date and time for Part Time Shift 5')
              break
            } else if (finalPartTimeEndDate5 == null || finalPartTimeEndDate5 == undefined) {
              toast.error('Please select end date and time for Part Time Shift 5')
              break
            } else if (partTimeProviderCount5 == null || partTimeProviderCount5 == undefined) {
              toast.error('Please enter number of providers for Part Time Shift 5')
              break
            } else {
              partTimeObject5.employmentTypeId = 2
              partTimeObject5.startTime = finalPartTimeStartDate5.toString().substring(0, 3) + ', ' + finalPartTimeStartDate5.toString().substring(8, 10) + ' ' + finalPartTimeStartDate5.toString().substring(4, 7) + ' ' + finalPartTimeStartDate5.toString().substring(11, 15) + ' ' + partTimeStartTime5 + ':00 IST'
              partTimeObject5.endTime = finalPartTimeEndDate5.toString().substring(0, 3) + ', ' + finalPartTimeEndDate5.toString().substring(8, 10) + ' ' + finalPartTimeEndDate5.toString().substring(4, 7) + ' ' + finalPartTimeEndDate5.toString().substring(11, 15) + ' ' + partTimeEndTime5 + ':00 IST'
              partTimeObject5.providerCount = partTimeProviderCount5
              arr.push(partTimeObject5)
            }


          }
        }
      }

      let params = {
        userProfileId: 12,
        entityId: 1,
        data: arr
      }

      if (arr.length >= employmentTypeData.length) {
        postDateAndTime(params)
      }

    }

  }

  const postDateAndTime = (params) => {
    setGettingField(true)
    setCreateDisabled(true)
    props.postJobDateAndTime(params, res => {
      if (res.status) {
        if (res.data.length) {
          setPostModal(!postModal);
          swal({
            title: "Availability updated successfully",
            text: "",
            icon: "success"
          }).then(() => {
            setCreateDisabled(true)
            setCloseDisabled(false)
            setContractStartDate()
            setContractStartTime()
            setShowContractStartDate()
            setFinalContractStartDate()
            setContractEndDate()
            setContractEndTime()
            setShowContractEndDate()
            setFinalContractEndDate()
            setFullTimeStartDate()
            setFullTimeStartTime()
            setShowFullTimeStartDate()
            setFinalFullTimeStartDate()

            setPartTimeStartDate()
            setPartTimeInputDateFlag(false)

            setShiftCount(0)

            setPartTimeStartTime1()
            setPartTimeEndTime1()
            setPartTimeStartTime2()
            setPartTimeEndTime2()
            setPartTimeStartTime3()
            setPartTimeEndTime3()
            setPartTimeStartTime4()
            setPartTimeEndTime4()
            setPartTimeStartTime5()
            setPartTimeEndTime5()

            setFinalPartTimeStartDate1()
            setFinalPartTimeEndDate1()
            setFinalPartTimeStartDate2()
            setFinalPartTimeEndDate2()
            setFinalPartTimeStartDate3()
            setFinalPartTimeEndDate3()
            setFinalPartTimeStartDate4()
            setFinalPartTimeEndDate4()
            setFinalPartTimeStartDate5()
            setFinalPartTimeEndDate5()

            setPartTimeProviderCount1()
            setPartTimeProviderCount2()
            setPartTimeProviderCount3()
            setPartTimeProviderCount4()
            setPartTimeProviderCount5()

            setPartTimeStartTimeFlag1(false)
            setPartTimeEndTimeFlag1(false)
            setPartTimeStartTimeFlag2(false)
            setPartTimeEndTimeFlag2(false)
            setPartTimeStartTimeFlag3(false)
            setPartTimeEndTimeFlag3(false)
            setPartTimeStartTimeFlag4(false)
            setPartTimeEndTimeFlag4(false)
            setPartTimeStartTimeFlag5(false)
            setPartTimeEndTimeFlag5(false)

            setShift2Flag(false)
            setShift3Flag(false)
            setShift4Flag(false)
            setShift5Flag(false)
            document.getElementById('kt_content').setAttribute('style', '')
          })
        }
      }
    })
  }

  return (
    <>
      <div
        className="content d-flex flex-column flex-column-fluid pt-5 pb-0"
        id="kt_content"
      >
        <div className="d-flex flex-column-fluid">
          <div className="custom-container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="custom_skki8">
                  <h3>Availability</h3>
                </div>
              </div>
            </div>
            <div className="Profile_form mt-5">
              <div className="row">
                {listLoading ? (
                  <div className="row" style={{ width: '100%', height: '30vh' }}>
                    <div className="col-12" style={{ width: '100%', height: '100%' }}>
                      <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                        <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                      </div>
                    </div>
                  </div>
                ) : list.length > 0 ? (
                  list.map((item) => (
                    <div className="col-md-4" key={item.id}>
                      <div className="blush_outer">
                        <div className="geoge_outer">
                          <div className="circleName custom789">{item.userProfileDataTypes.UserProfileEntityTypeIsSelected?.entityTypeNameAbbreviation ? item.userProfileDataTypes.UserProfileEntityTypeIsSelected?.entityTypeNameAbbreviation : '---'}</div>
                          <img src={item.isVerified ? verified : unVerified} alt="verify" style={{ position: "absolute", top: '58px', left: '58px' }} />
                          <div className="google_inner">
                            <div className="d-flex justify-content-between align-items-center">
                              <h5>{item.userProfileDataTypes.employmentType?.length ? item.userProfileDataTypes.employmentType.map((item, index, array) => index + 1 === array.length ? `${item.name}` : `${item.name}, `) : "---"}</h5>
                              <img src={showBadge(item.userProfileDataTypes.selectedUserDataRating)} alt="badge" className="mr-6" />
                            </div>
                            <p className="my-2" style={{fontSize:'14px', color:'grey'}}>{item.userProfileDataTypes.Profession ? item.userProfileDataTypes.Profession : '---'}</p>
                            <p className="custom_png">
                              <img className="custom78" src={location} alt="" />
                              {item.city ? item.city + ', ' : '---'} {item.country ? item.country : '---'}
                            </p>
                          </div>
                        </div>
                        <div className="button_list">
                          <a onClick={() => openAvailModal(item)} className="about_btn" href="javascript:void(0)">Availability</a>
                          <a onClick={() => openShiftModal(item)} className="about_btn shift_outer5" href="javascript:void(0)">Shift Type</a>
                          {/* <a onClick={() => alert("Work in progress")} className="about_btn shift_outer5" href="javascript:void(0)">Invitation</a> */}
                        </div>
                      </div>
                    </div>
                  ))
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={shiftModal} size="md" centered style={{ background: "rgb(95 95 95 / 77%)" }}>
        <div className='text-center m-5'>
          <Modal.Title><b>Weekly Shift</b></Modal.Title>
        </div>
        <Modal.Body>
          {loadingShiftBody ?
            <div className="row" style={{ width: '100%', height: '30vh' }}>
              <div className="col-12" style={{ width: '100%', height: '100%' }}>
                <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '30vh' }}>
                  <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                </div>
              </div>
            </div>
            :
            (<>
              <div className="row mt-3 mb-3">
                <div className="col-12">
                  <div className="talent_details_shift">
                    <a className={
                      filterShow
                        ? "panel-heading heading_links_ accordion_title_info_ d-flex justify-content-between"
                        : "panel-heading heading_links_ accordion_title_info_ collapsed d-flex justify-content-between"
                    }
                      onClick={() => setFilterShow(!filterShow)}
                      style={{ cursor: 'pointer' }}
                    >
                      <span style={{ fontWeight: '600', color: '#3abd00' }}>Skill Profile Id : <span style={{ color: 'black' }}>{userData?.id}</span></span>
                      <img src={downArrow} />
                    </a>
                    <div
                      className={
                        filterShow ? "collapse show" : "collapse"
                      }
                      id="acceptance"
                    >
                      <div
                        className="card border-0 pt-2"
                        style={{
                          "max-height": "200px",
                          overflow: "auto",
                        }}
                      >
                        <div style={{ paddingTop: '0px' }}>
                          <table className='table' style={{ width: '100%' }}>
                            <tbody>
                              <tr>
                                <td className='table-shift-td-1' style={{ border: 'none' }}>Business Name</td>
                                <td className='table-shift-td-2' style={{ border: 'none' }}>{userData?.entityName ? userData?.entityName : '_'}</td>
                              </tr>
                              <tr>
                                <td className='table-shift-td-1'>Location</td>
                                <td className='table-shift-td-2'>{userData?.address1}</td>
                              </tr>
                              <tr>
                                <td className='table-shift-td-1'>Facility Type</td>
                                <td className='table-shift-td-2'>{userData.userProfileData?.facilityType?.length ? Array.from(userData.userProfileData?.facilityType, x => x.name).join(", ") : "_"}</td>
                              </tr>
                              <tr>
                                <td className='table-shift-td-1'>Payroll Type</td>
                                <td className='table-shift-td-2'>{userData.userProfileData?.payrollType?.length ? Array.from(userData.userProfileData?.payrollType, x => x.name).join(", ") : "_"}</td>
                              </tr>
                              <tr>
                                <td className='table-shift-td-1'>Schedule Type</td>
                                <td className='table-shift-td-2'>{userData.userProfileData?.scheduleType?.length ? Array.from(userData.userProfileData?.scheduleType, x => x.name).join(", ") : "_"}</td>
                              </tr>
                              <tr>
                                <td className='table-shift-td-1'>Shift Type</td>
                                <td className='table-shift-td-2'>{userData.userProfileData?.shiftType?.length ? Array.from(userData.userProfileData?.shiftType, x => x.name).join(", ") : "_"}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Form>
                <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                  <div className='row my-3'>
                    <div className='col-6'>
                      <h5 style={{ color: '#006838' }}><b>Sunday</b></h5>
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <Switch onChange={() => { toggleSwitch('sunday') }} checked={isSundayChecked} />
                    </div>
                  </div>
                  <div className='row my-3'>
                    <div className='col-6'>
                      <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'sunday', value)} value={sundayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />

                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'sunday', value)} value={sundayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                  </div>
                </Form.Group>
                <hr className='my-5'></hr>
                <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h5 style={{ color: '#006838' }}><b>Monday</b></h5>
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <Switch onChange={() => { toggleSwitch('monday') }} checked={isMondayChecked} />
                    </div>
                  </div>
                  <div className='row my-3'>
                    <div className='col-6'>
                      <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'monday', value)} value={mondayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'monday', value)} value={mondayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                  </div>
                </Form.Group>
                <hr className='my-5'></hr>
                <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h5 style={{ color: '#006838' }}><b>Tuesday</b></h5>
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <Switch onChange={() => { toggleSwitch('tuesday') }} checked={isTuesdayChecked} />
                    </div>
                  </div>
                  <div className='row my-3'>
                    <div className='col-6'>
                      <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'tuesday', value)} value={tuesdayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'tuesday', value)} value={tuesdayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                  </div>
                </Form.Group>
                <hr className='my-5'></hr>
                <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h5 style={{ color: '#006838' }}><b>Wednesday</b></h5>
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <Switch onChange={() => { toggleSwitch('wednesday') }} checked={isWednesdayChecked} />
                    </div>
                  </div>
                  <div className='row my-3'>
                    <div className='col-6'>
                      <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'wednesday', value)} value={wednesdayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'wednesday', value)} value={wednesdayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                  </div>
                </Form.Group>
                <hr className='my-5'></hr>
                <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h5 style={{ color: '#006838' }}><b>Thursday</b></h5>
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <Switch onChange={() => { toggleSwitch('thursday') }} checked={isThursdayChecked} />
                    </div>
                  </div>
                  <div className='row my-3'>
                    <div className='col-6'>
                      <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'thursday', value)} value={thursdayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'thursday', value)} value={thursdayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                  </div>
                </Form.Group>
                <hr className='my-5'></hr>
                <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h5 style={{ color: '#006838' }}><b>Friday</b></h5>
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <Switch onChange={() => { toggleSwitch('friday') }} checked={isFridayChecked} />
                    </div>
                  </div>
                  <div className='row my-3'>
                    <div className='col-6'>
                      <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'friday', value)} value={fridayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'friday', value)} value={fridayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                  </div>
                </Form.Group>
                <hr className='my-5'></hr>
                <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h5 style={{ color: '#006838' }}><b>Saturday</b></h5>
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <Switch onChange={() => { toggleSwitch('saturday') }} checked={isSaturdayChecked} />
                    </div>
                  </div>
                  <div className='row my-3'>
                    <div className='col-6'>
                      <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'saturday', value)} value={saturdayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                    <div className='col-6' style={{ textAlign: 'end' }}>
                      <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'saturday', value)} value={saturdayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </>)}
        </Modal.Body>
        <div className='text-center m-5'>
          <Button className="mx-3" variant="danger" onClick={closeShiftModal}>Close</Button>
          <Button className="mx-3" variant="primary" onClick={updateWeeklyShiftData} disabled={updateWeeklyShiftDataDisabled}
          >
            {loadingUpdateWeeklyShiftData && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Update</Button>
        </div>
      </Modal>

      <Modal show={postModal} size="md" centered style={{ background: "rgb(95 95 95 / 77%)" }}>
        <div className="text-center m-5">
          <div className='text-center m-5'>
            <Modal.Title>
              <b>Your Availability</b>
            </Modal.Title>
          </div>
        </div>

        {gettingField ? (
          <div className="row" style={{ width: '100%', height: '30vh' }}>
            <div className="col-12" style={{ width: '100%', height: '100%' }}>
              <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '25vh' }}>
                <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {contractData.length && partTimeData.length && fullTimeData.length ? <div className='card'>
              <div className='card-body'>
                {contractData.length > 0 && contractData.map(item =>
                  <div className='row pt-5 pb-5'>
                    <div className='col-12 p-0'>
                      <label style={{ fontSize: '15px' }}><b>Contract Start and End Date : </b></label>
                      <div>
                        <div className='d-flex'>
                          {/* Contract Start Date and Time */}
                          <label className='mr-3' style={{ paddingTop: '5px' }}>Start Date : </label>
                          <input type='text' placeholder='Select Start Date' style={{ border: 'none', width: '30%' }} value={moment(item.startTime).format('D MMM YYYY, hh:mm A')} disabled />
                          <DatePicker onChange={(val) => console.log(val)} value={new Date(item.startTime)} className='start-date-calendar' />
                          {/* {contractStartTimeFlag ?
                            (<>
                              <Modal show={contractStartTimeFlag}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeStartTimeForContract} value={contractStartTime} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setContractStartTimeFlag(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForContract}>
                                    Ok
                                  </Button>
                                </Modal.Body>

                              </Modal>

                            </>) : (<></>)} */}

                          {/* Contract End Date and Time */}
                          <label className='mr-3 ml-5' style={{ paddingTop: '5px' }}>End Date : </label>
                          <input type='text' placeholder='Select End Date' style={{ border: 'none', width: '30%' }} value={moment(item.endTime).format('D MMM YYYY, hh:mm A')} disabled />
                          <DatePicker onChange={(val) => console.log(val)} value={new Date(item.endTime)} className='end-date-calendar' />
                          {/* {contractEndTimeFlag ?
                            (<>
                              <Modal show={contractEndTimeFlag}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeEndTimeForContract} value={contractEndTime} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setContractEndTimeFlag(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForContract}>
                                    Ok
                                  </Button>
                                </Modal.Body>

                              </Modal>

                            </>) : (<></>)} */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {fullTimeData.length > 0 && fullTimeData.map(item =>
                  <div className='row pt-5 pb-5'>
                    <div className='col-12 p-0'>
                      <label style={{ fontSize: '15px' }}><b>Full Time Date : </b></label>
                      <div>
                        <div className='d-flex'>
                          {/* Full-Time Start Date and Time */}
                          <label className='mr-3' style={{ paddingTop: '5px' }}>Start Date : </label>
                          <input type='text' placeholder='Select Start Date' style={{ border: 'none', width: '30%' }} value={showFullTimeStartDate} disabled />
                          <DatePicker onChange={changeStartDateForFullTime} value={fullTimeStartDate} className='start-date-calendar' />
                          {fullTimeStartTimeFlag ?
                            (<>
                              <Modal show={fullTimeStartTimeFlag}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeStartTimeForFullTime} value={fullTimeStartTime} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setFullTimeStartTimeFlag(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForFullTime}>
                                    Ok
                                  </Button>
                                </Modal.Body>

                              </Modal>

                            </>) : (<></>)}
                        </div>
                        <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changeFullTimeProviderCount(e) }} value={fullTimeProviderCount} />
                      </div>
                    </div>
                  </div>
                )}

                {partTimeData.length > 0 &&
                  <>
                    <div className='row pt-5 pb-5'>
                      <div className='col-12 p-0'>
                        <label style={{ fontSize: '15px' }}><b>Daily Date Picker for Part Time : </b></label>
                        <div className='mb-3'>
                          <Calendar className='part-time-calendar' onChange={changeDateForPartTime} defaultValue={new Date(partTimeData[0].startTime)} />
                        </div>
                      </div>
                    </div>

                    {partTimeData.map(item =>
                      <div className='row pt-5 pb-5'>
                        <div className='col-12 p-0'>
                          {partTimeInputDateFlag ?
                            (<>
                              <Modal show={partTimeStartTime1Modal}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeStartTime1ForPartTime} value={partTimeStartTime1} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setPartTimeStartTime1Modal(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime1}>
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>
                              <Modal show={partTimeEndTime1Modal}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeEndTime1ForPartTime} value={partTimeEndTime1} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setPartTimeEndTime1Modal(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime1}>
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>

                              <Modal show={partTimeStartTime2Modal}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeStartTime2ForPartTime} value={partTimeStartTime2} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setPartTimeStartTime2Modal(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime2}>
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>
                              <Modal show={partTimeEndTime2Modal}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeEndTime2ForPartTime} value={partTimeEndTime2} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setPartTimeEndTime2Modal(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime2}>
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>

                              <Modal show={partTimeStartTime3Modal}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeStartTime3ForPartTime} value={partTimeStartTime3} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setPartTimeStartTime3Modal(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime3}>
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>
                              <Modal show={partTimeEndTime3Modal}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeEndTime3ForPartTime} value={partTimeEndTime3} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setPartTimeEndTime3Modal(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime3}>
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>

                              <Modal show={partTimeStartTime4Modal}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeStartTime4ForPartTime} value={partTimeStartTime4} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setPartTimeStartTime4Modal(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime4}>
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>
                              <Modal show={partTimeEndTime4Modal}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeEndTime4ForPartTime} value={partTimeEndTime4} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setPartTimeEndTime4Modal(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime4}>
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>

                              <Modal show={partTimeStartTime5Modal}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeStartTime5ForPartTime} value={partTimeStartTime5} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setPartTimeStartTime5Modal(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime5}>
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>
                              <Modal show={partTimeEndTime5Modal}
                                size="sm"
                                centered
                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                <Modal.Body style={{ padding: '20px' }}>
                                  <TimePicker onChange={changeEndTime5ForPartTime} value={partTimeEndTime5} disableClock={true} />
                                  <br></br>
                                  <Button
                                    className="mx-3"
                                    variant="danger"
                                    onClick={() => { setPartTimeEndTime5Modal(false) }}
                                  >Cancel</Button>
                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime5}>
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>
                              <div className='card'>
                                <div className='card-body' style={{ padding: '10px' }}>
                                  <label><b>{partTimeStartDate}</b></label>
                                  <div>
                                    <label>Add Shift 1 : </label>
                                    {partTimeStartTimeFlag1 ?
                                      (<>
                                        <span className='ml-3'> {partTimeStartTime1} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime1Modal() }}></i>
                                      </>) :
                                      (<>
                                        <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime1Modal() }}></i>
                                      </>)}
                                    {partTimeEndTimeFlag1 ?
                                      (<>
                                        <span className='ml-3'> {partTimeEndTime1} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime1Modal() }}></i>
                                      </>) :
                                      (<>
                                        <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime1Modal() }}></i>
                                      </>)}
                                    <div>
                                      <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount1(e) }} value={partTimeProviderCount1} />
                                    </div>
                                  </div>

                                  {shift2Flag ?
                                    (<>
                                      <div className='pt-3'>
                                        <label>Add Shift 2 : </label>
                                        {partTimeStartTimeFlag2 ?
                                          (<>
                                            <span className='ml-3'> {partTimeStartTime2} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime2Modal() }}></i>
                                          </>) :
                                          (<>
                                            <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime2Modal() }}></i>
                                          </>)}
                                        {partTimeEndTimeFlag2 ?
                                          (<>
                                            <span className='ml-3'> {partTimeEndTime2} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime2Modal() }}></i>
                                          </>) :
                                          (<>
                                            <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime2Modal() }}></i>
                                          </>)}
                                        <div>
                                          <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount2(e) }} value={partTimeProviderCount2} />
                                        </div>
                                      </div>
                                    </>) : (<></>)}

                                  {shift3Flag ?
                                    (<>
                                      <div className='pt-3'>
                                        <label>Add Shift 3 : </label>
                                        {partTimeStartTimeFlag3 ?
                                          (<>
                                            <span className='ml-3'> {partTimeStartTime3} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime3Modal() }}></i>
                                          </>) :
                                          (<>
                                            <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime3Modal() }}></i>
                                          </>)}
                                        {partTimeEndTimeFlag3 ?
                                          (<>
                                            <span className='ml-3'> {partTimeEndTime3} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime3Modal() }}></i>
                                          </>) :
                                          (<>
                                            <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime3Modal() }}></i>
                                          </>)}
                                        <div>
                                          <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount3(e) }} value={partTimeProviderCount3} />
                                        </div>
                                      </div>
                                    </>) : (<></>)}

                                  {shift4Flag ?
                                    (<>
                                      <div className='pt-3'>
                                        <label>Add Shift 4 : </label>
                                        {partTimeStartTimeFlag4 ?
                                          (<>
                                            <span className='ml-3'> {partTimeStartTime4} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime4Modal() }}></i>
                                          </>) :
                                          (<>
                                            <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime4Modal() }}></i>
                                          </>)}
                                        {partTimeEndTimeFlag4 ?
                                          (<>
                                            <span className='ml-3'> {partTimeEndTime4} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime4Modal() }}></i>
                                          </>) :
                                          (<>
                                            <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime4Modal() }}></i>
                                          </>)}
                                        <div>
                                          <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount4(e) }} value={partTimeProviderCount4} />
                                        </div>
                                      </div>
                                    </>) : (<></>)}

                                  {shift5Flag ?
                                    (<>
                                      <div className='pt-3'>
                                        <label>Add Shift 5 : </label>
                                        {partTimeStartTimeFlag5 ?
                                          (<>
                                            <span className='ml-3'> {partTimeStartTime5} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime5Modal() }}></i>
                                          </>) :
                                          (<>
                                            <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime5Modal() }}></i>
                                          </>)}
                                        {partTimeEndTimeFlag5 ?
                                          (<>
                                            <span className='ml-3'> {partTimeEndTime5} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime5Modal() }}></i>
                                          </>) :
                                          (<>
                                            <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime5Modal() }}></i>
                                          </>)}
                                        <div>
                                          <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount5(e) }} value={partTimeProviderCount5} />
                                        </div>
                                      </div>
                                    </>) : (<></>)}

                                  {shiftCount == 4 ? (<></>) :
                                    (<>
                                      <div>
                                        <label className='mt-3' style={{ color: 'blue' }} onClick={(e) => { addAnotherShift(e) }}>Add Another Shift</label>
                                      </div>
                                    </>)}

                                </div>
                              </div>
                            </>) :
                            (<></>)}
                          <div></div>
                        </div>
                      </div>
                    )}</>}

              </div>
            </div> :
              <div className='col-12 p-0 text-center'>
                <label style={{ fontSize: '15px' }}><b>No Details available</b></label>
              </div>}

          </>
        )}
        <div className="text-center m-5">
          <Button className="mx-3" variant="danger" onClick={closeAvailModal} disabled={closeDisabled}>
            Close
          </Button>
          {/* <Button className="mx-3" variant="primary" onClick={onPostDateAndTime} disabled={createDisabled}>
            Post
          </Button> */}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getUserProfileList: bindActionCreators(getUserProfileList, dispatch),
  getDaysList: bindActionCreators(getDaysList, dispatch),
  getActiveDaysList: bindActionCreators(getActiveDaysList, dispatch),
  updateWeeklyShiftDataForEmptyActiveDaysList: bindActionCreators(updateWeeklyShiftDataForEmptyActiveDaysList, dispatch),
  updateWeeklyShiftDataForActiveDaysList: bindActionCreators(updateWeeklyShiftDataForActiveDaysList, dispatch),
  getDailyShiftDetails: bindActionCreators(getDailyShiftDetails, dispatch),
  postDailyShift: bindActionCreators(postDailyShift, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Availability);
