import React from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  getProfessionsList,
  getSearchJobListForLandingPage,
  addToFavourite,
  removeFromFavouriteList
} from "../../actions/common";
import JobPkg from "../../public/img/job-pkg.svg";
import Category from "../../public/img/category.png";
import Location from "../../public/img/location.png";
import BannerVector from "../../public/img/bannerVector.png";
import RightArrow from "../../public/img/rightArrow.png";
import Skill from "../../public/img/skill.svg";
import JobLocation from "../../public/img/joblocation.svg";
import BtnFilter from "../../public/img/btnFilter.svg";
import CompanyLogo1 from "../../public/images/defaultJob.png";
import CompanyLogo2 from "../../public/img/CompanyLogo2.svg";
import CompanyLogo3 from "../../public/img/CompanyLogo3.svg";
import zeroFollowingImage from "../../public/images/zeroFollowingImageSmall.png";
import Favroite from "../../public/images/favroite.svg";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getStateBounds,
} from "react-places-autocomplete";
import { toast } from "react-toastify";
import LandingFooter from "../../components/shared/LandingFooter";
import RightMenuJob from "./rightMenu.js"

import $ from "jquery";

const SearchJob = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [professionsList, setProfessionsList] = useState([]);
  const [profession, setProfession] = useState();
  const [professionTitle, setProfessionTitle] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [savedcity, setsavedCity] = useState();
  const [savedstate, setsavedState] = useState();
  const [savedcountry, setsavedCountry] = useState();
  const [zipCode, setZipCode] = useState();
  const [address, setAddress] = useState();
  const [jobs, setJobs] = useState([]);
  const [addressFlag, setAddressFlag] = useState(true);
  const [jobId, setJobId] = useState();
  const [error, setError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [latestData, setLatestData] = useState();
  const [removedFavourite , setRemovedFavourite] = useState([]);
  const [addedFavourite , setAddedFavourite] = useState([]);

  const queryParams = new URLSearchParams(window.location.search)

  useEffect(() => {

    setLoading(true);
    getProfessions();
    getListOfJobs();
    setError(false);
    queryParams.get("city")
      ? setAddress(
            queryParams.get("city") +
            ", " +
            queryParams.get("state") +
            ", " +
            queryParams.get("country")
        )
      : setAddress();
    
    setProfessionTitle(queryParams.get("professionTitle") ? queryParams.get("professionTitle") : professionTitle)
    setCity(queryParams.get("city") ? queryParams.get("city") : city);
    setState(queryParams.get("state") ? queryParams.get("state") : state);
    setCountry(queryParams.get("country") ? queryParams.get("country") : country);
  }, []);

  const checkForFavourite = (e, checkObject, entityId) => {
    
    if(addedFavourite.includes(entityId)){
      removeFromFavourite(e,entityId)
      setAddedFavourite(addedFavourite.filter( (item) => item !== entityId ));
      setRemovedFavourite(oldArray => [...oldArray,entityId] );
    }
    else if(removedFavourite.includes(entityId)){
      addToFavourite(e,entityId)  
      setRemovedFavourite(removedFavourite.filter( (item) => item !== entityId ));
      setAddedFavourite(oldArray => [...oldArray,entityId]);
    }
    else{
      if(checkObject > 0){
        removeFromFavourite(e,entityId)
        setRemovedFavourite(oldArray => [...oldArray,entityId] );
      }
      else{
        addToFavourite(e,entityId)  
        setAddedFavourite(oldArray => [...oldArray,entityId]);
      }
    }
  }


  const addToFavourite = (e,entityId) => {

    e.currentTarget.classList.add('active');

    const params = {
      'entityId' : entityId,
      'userIdHash' :props.auth.userProfile.userIdHash,
      'data' : {}
    }

    props.addToFavourite(params, (res) => {
        // console.log("add response========",res)
        if(res.status){
          if(res.data == ""){
            toast.success('Already added to favourite');
          }
          else{
            toast.success('Added to favourite');
          }
        }
    });

  } 

  const removeFromFavourite = (e,entityId) => {

    e.currentTarget.classList.remove('active');
   
    const params = {
      'entityId' : entityId,
      'userIdHash' :props.auth.userProfile.userIdHash,
    }
    
    props.removeFromFavouriteList(params, (res) => {
        console.log("remove response========",res)
        if(res.status){
            toast.success('Removed form favourite');
        }
        else{
            toast.warn('Something went wrong');
        }
    });



} 

  const getProfessions = () => {
    props.getProfessionsList({}, (res) => {
      // console.log("PROFESSIONS", res);
      if (res.status) {
        setProfessionsList(res.data);
      } else {
        setProfessionsList([]);
      }
    });
  };


  const getListOfJobs = () => {
    let params = {
      city:  queryParams.get("city") ?  queryParams.get("city") : city,
      state:  queryParams.get("state") ? queryParams.get("state") : state,
      country: queryParams.get("country") ? queryParams.get("country")  : country,
    };  
    if (queryParams.get("professionTitle")){
      params = {
        ...params,
        professionTitle:queryParams.get("professionTitle"),
      };
    }
    if (queryParams.get("profession")){
      params = {
        ...params,
        profession:queryParams.get("profession"),
      };
    }

    // console.log("PARAMS", params);

    setLoading(true);
    props.getSearchJobListForLandingPage(params, (res) => {
      setLoading(false);
      if (res.status) {
        console.log("LIST OF JOBS", res.data);
        setJobs(res.data);
        setLatestData(res.data[0]);
      } else {
        setJobs([]);
      }
    });
  };

  const setjobes = (index) => {
    //console.log("index",index)
    setLatestData(jobs[index]);
  }
  //console.log("latest",latestData)

  const onChangeInput = (e, flag) => {
    e.preventDefault();
    console.log(e.target.value);
    let value = e.target.value;
    setError(false);
    if (flag === "profession") {
      setProfession(value);
    } else if (flag === "professionTitle") {
      setProfessionTitle(value);
    }
    setPrarmsInUrl(flag,value)

  };

  const setPrarmsInUrl = (key,value) => {
    const url = new URL(window.location.href);
    if(value){
      url.searchParams.set(key,value);
    }else{
      url.searchParams.delete(key);
    }
    window.history.replaceState(null, null, url);
  }

  const autoCompleteHandleChange = (address) => {
    if (address === "") {
      setCity();
      setPrarmsInUrl('city','');
      setState();
      setPrarmsInUrl('state','');
      setCountry();
      setPrarmsInUrl('country','');
      setErrorMsg("Please search a place you are looking for a job in");
      setError(true);
    } else {
      setError(false);
    }
    setAddress(address);
  };

  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        document.getElementById("searchTextField").blur();
        fillAddressDetails(results);
      })
      .catch((error) => {});
  };

  const google = (window.google = window.google ? window.google : {});

  const searchOptions = {
    // location: new this.google.maps.LatLng(51.509865, -0.118092),
    // radius: 424000,
    //types: ["address", "uk"],
    componentRestrictions: { country: "" },
  };

  const fillAddressDetails = (results) => {
    
    
    setAddress(results[0].formatted_address);

    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        setZipCode(results[0].address_components[j].short_name);
      } else if (results[0].address_components[j].types[0] == "locality") {
        setCity(results[0].address_components[j].long_name);
        setPrarmsInUrl('city',results[0].address_components[j].long_name);
        // setsavedCity(results[0].address_components[j].long_name);
      } else if (
        results[0].address_components[j].types[0] ==
        "administrative_area_level_1"
      ) {
        setState(results[0].address_components[j].long_name);
        setPrarmsInUrl('state',results[0].address_components[j].long_name);
        // setsavedState(results[0].address_components[j].long_name);
      } else if (results[0].address_components[j].types[0] == "country") {
        setCountry(results[0].address_components[j].long_name);
        setPrarmsInUrl('country',results[0].address_components[j].long_name);
        // setsavedCountry(results[0].address_components[j].long_name);
      }
    }
  };

  const searchJob = (e) => {
    e.preventDefault();

    if (!professionTitle && !profession && !city && !state && !country) {
      setErrorMsg("Please search a place you are looking for a job in");
      setError(true);
      return;
    }
    if (!city && !state && !country) {
      setErrorMsg("Please search a place you are looking for a job in");
      setError(true);
      return;
    }
    // if(childFlag1) {
    //   if(!childProfession) {
    //     toast.error('Please select the child profession from the list')
    //     return
    //   }
    // }

    let params = {
      city: city,
      state: state,
      country: country,
    };
    
    if (profession) {
      params = { ...params, profession: profession };
    }
    if (professionTitle) {
      params = {
        ...params,
        professionTitle: professionTitle,
      };
    }

    // console.log(params)
    
    setsavedCity(city);
    setsavedState(state);
    setsavedCountry(country);

    setLoading(true);
    props.getSearchJobListForLandingPage(params, (res) => {
      setLoading(false);
      if (res.status) {
        console.log("LIST OF JOBS", res.data);
        setJobs(res.data);
        setLatestData(res.data[0]);
        if (window.location.pathname !== "/search-job") {
          history.push("/search-job");
        }
        
      } else {
        setJobs([]);
      }
    });
  };


  $('body').on('click','.jobDescriptionTab',function () {
    // console.log($(this).attr("data-id"), "====this");
    let dataId = $(this).attr("data-id");
    $(".tab-pane").removeClass("showTabsdetails");
    $("#jobdetails_" + dataId).addClass("showTabsdetails");
  });



  const jobClicked = (e, value, jobDetails) => {
    e.preventDefault();
    console.log(value);
    setJobId(jobDetails.id);
    console.log("Job Id", jobDetails.id);
    // let elemSel = document.getElementById(value)
    // let dataId = elemSel.getAttribute('data-id')
    // console.log('DATA ID', dataId)
    // let childElem = document.getElementById(`child${dataId}`)
    // console.log('CHILD ELEMENT', childElem)
    // //childElem.classList.remove('showTabsdetails')
    // childElem.classList.add('showTabsdetails')
  };

  return (
    <>
      <section className="findJob_">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-5">
              <div className="findjob_deatils">
                <p>What?</p>
                <span className="jon_skills d-flex">
                  <a>
                    {" "}
                    <img src={Skill} alt="skillIcon" />
                  </a>
                  <input
                    type="text"
                    placeholder="keywords, company, skills..."
                    onChange={(e) => {
                      onChangeInput(e, "professionTitle");
                    }}
                    value={professionTitle}
                    // style={{ height: "45px" }}
                  />
                </span>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-5">
              <div className="findjob_deatils">
                <p>Where?</p>
                <span className="jon_skills d-flex">
                  <a>
                    {" "}
                    <img
                      src={JobLocation}
                      alt="skillIcon"
                      style={{ position: "relative", top: "0px" }}
                    />
                  </a>
                  {/* <input type="text" placeholder="city, state, country ..." /> */}
                  <div className="form-group w-100 mb-0" style={{ height: "10px" }}>
                    <PlacesAutocomplete
                      value={address}
                      onChange={autoCompleteHandleChange}
                      onSelect={autoCompleteHandleSelect}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            id="searchTextField"
                            {...getInputProps({
                              placeholder: "Search Places...",
                              className:
                                "location-search-input form-control inputStyle",
                            })}
                            // style={{ height: "45px" }}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#41b6e6",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </span>
                {error ? (
                  <>
                    <p
                      style={{
                        position: "absolute",
                        top: "88px",
                        left: "39px",
                        fontSize: "15px",
                        color: "red",
                      }}
                    >
                      {errorMsg}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2">
              <div className="findjob_deatils d-flex">
                <div className="jobbtn_ text-center">
                  <button
                    className="jobbtn_main"
                    type="submit"
                    style={{ position: "relative", top: "19px" }}
                    onClick={(e) => {
                      searchJob(e);
                    }}
                  >
                    Find Job
                  </button>
                </div>
                {/* <div className="filterbtn_ text-center">
                  <button
                    className="filterbtn_main"
                    type="submit"
                    style={{ position: "relative", top: "19px" }}
                  >
                    <span>
                      <img src={BtnFilter} alt="filter" className="img-fluid" />
                    </span>
                    Find Job
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="jobdescription_">
        <div className="container">
          <>
            {queryParams.get("city") ? (
              <>
                <h5>
                  Jobs In{" "}
                  {
                    queryParams.get("city") +
                    ", " +
                    queryParams.get("state") +
                    ", " +
                    queryParams.get("country")
                    }
                </h5>
              </>
            ) : savedcity ? (
              <>
                <h5>
                  Jobs In {savedcity + ", " + savedstate + ", " + savedcountry}
                </h5>
              </>
            ) : (
              <></>
            )}
          </>
          <div className="row">
            {loading ? (
              <>
                <div className="row" style={{ width: "100%", height: "80vh" }}>
                  <div
                    className="col-12"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                        verticalAlign: "middle",
                        lineHeight: "40vh",
                      }}
                    >
                      <div
                        className="spinner-grow"
                        role="status"
                        style={{ background: "#006838" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={jobs.length > 0 ? "col-sm-12 col-md-12 col-lg-6" : "col-sm-12 col-md-12 col-lg-12"}>
                  <div
                    className="nav flex-column nav-pills jobdescription_links"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {jobs && jobs.length > 0 ? (
                      <>
                        {jobs.map((value, index) => {
                          return (
                            <>
                              <div
                                className="nav-link jobDescriptionTab"
                                onClick={()=>{
                                  setjobes(index)
                                }}
                                data-id={index + 1}
                                data-toggle="pill"
                                href={"#jobdetails" + (index + 1)}
                                role="tab"
                                aria-controls={"jobdetails" + (index + 1)}
                                aria-selected="true"
                              >
                                <div className="job_detail">
                                        <div className="job_detailsNav">
                                            <div className="orgnisation_icon" href="javascript:void();">
                                            <img src={value.userProfileData.selectedUserData.userProfileImageUrl ? value.userProfileData.selectedUserData.userProfileImageUrl : CompanyLogo1}
                                                alt="logo" className="img-fluid" />
                                            </div>
                                            <div className="jobDescription">
                                                <h5 className="job_heading">{value.userProfileData.selectedEntityData.title} | {value.userProfileData.selectedEntityTypeData.profession}</h5>
                                                <span className="job_Subheading">{value.userProfileData.selectedUserData.entityName}{" "}</span>
                                            </div>
                                              {props.auth && props.auth.isLoggedIn && props.auth.userProfile?.userType?.userTypeId == 2 ? (
                                                <div className={Object.keys(value?.userProfileData?.selectedEntityFavoriteData).length > 0  ?"active Remove_box": "Remove_box"} onClick={(e)=>{ 
                                                checkForFavourite(e, Object.keys(value?.userProfileData?.selectedEntityFavoriteData).length ,value.userProfileData?.selectedEntityData?.id )
                                              }}>
                                                  <img src={Favroite} alt="logo" className="img-fluid" />
                                                  {/* <span>Add to Favorite</span> */}
                                              </div>
                                              ):
                                                <>
                                                {(props.auth.userProfile?.userType?.userTypeId == 2) ? 
                                                  <div className="Remove_box" 
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#mySignin"
                                                  >
                                                    <img src={Favroite} alt="logo" className="img-fluid" />
                                                      {/* <span>Add to Favorite</span> */}
                                                  </div>
                                                :null}
                                                </>
                                              }
                                        </div>
                                        <div className="job_location">
                                        <a href="javascript:void();">
                                        <img
                                          src={JobLocation}
                                          alt="logo"
                                        />
                                        <span>{" "}{value.userProfileData.selectedUserData.state}{", "}{value.userProfileData.selectedUserData.country}</span>
                                      </a>
                                        </div>
                                        <div className="job_detailsContent">
                                            
                                        </div>
                                    </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div
                              className="nav-link"
                              data-toggle="pill"
                              role="tab"
                              aria-selected="true"
                            >
                            <div className="row mb-5 h-100 w-100">
                              <div
                                className="col-6 text-center"
                                style={{
                                  position: "relative",
                                  left: "25%",
                                  // height: "45vh",
                                }}
                              >
                                <img
                                  className="mb-3"
                                  src={zeroFollowingImage}
                                  alt="No Data Found"
                                />
                                <p>No jobs found!</p>
                              </div>
                            </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                
                {jobs.length > 0 ?
                  <> 
                      <div className="col-sm-12 col-md-12 col-lg-6 findJob_Box">
                        <div className="tab-content " id="v-pills-tabContent">
                        {latestData?
                          <RightMenuJob job={latestData}/>
                        :null}
                        </div>
                      </div>
                  </>
                  :
                  null
                }
              </>
            )}
          </div>
        </div>
      </section>

        {/* <div className="pageNavigation">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link " href="#">
                        &laquo;
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link active" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        &raquo;
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getProfessionsList: bindActionCreators(getProfessionsList, dispatch),
  getSearchJobListForLandingPage: bindActionCreators(
    getSearchJobListForLandingPage,
    dispatch
  ),
  addToFavourite: bindActionCreators(addToFavourite, dispatch),
  removeFromFavouriteList : bindActionCreators(removeFromFavouriteList,dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(SearchJob);
