if (process.env.NODE_ENV === "production") {
  module.exports = {
    API_URL: 'http://api.timelyhire.com:8080/rest',
    // EXPRESS_MIDDLEWARE_API_URL: 'http://admin.timelyhire.com:5000'
    EXPRESS_MIDDLEWARE_API_URL: 'http://webtimelyhire.itechnolabs.tech:5000'
  };
} else {
  module.exports = {
    API_URL: 'http://api.timelyhire.com:8080/rest',
    EXPRESS_MIDDLEWARE_API_URL: 'http://localhost:5000'
  };
}
