import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Switch from "react-switch";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css'
import 'react-clock/dist/Clock.css';
import PlacesAutocomplete, {
    geocodeByAddress,
    getStateBounds,
} from "react-places-autocomplete";
import Multiselect from 'multiselect-react-dropdown';
import { getTalents, getProfession, getSkill, getEmployment, getSchedule, getFacility, getPayroll, getShift, getPayment, createTalent, getTalentProfile, updateTalent, getDaysList, getActiveDaysList, updateWeeklyShiftDataForEmptyActiveDaysList, updateWeeklyShiftDataForActiveDaysList } from "../../actions/employer/talent";
import { getUserInformation, updateUserInformation, addUserInformation } from "../../actions/user";
import talent_edit from '../../public/images/talent_edit.png';
import locationImg from '../../public/images/location.png';
import downArrow from "../../public/images/downArrow.svg";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import { showBadge } from '../../../utilities/helpers';

const Talent = (props) => {
    const [userIdHash, setUserIdHash] = useState("");
    const [userProfileId, setUserProfileId] = useState("");
    const [userTypeId, setUserTypeId] = useState("");
    const [userId, setUserId] = useState("");

    const [loading, setLoading] = useState(true);
    const [loadingEditBody, setLoadingEditBody] = useState(false);
    const [loadingShiftBody, setLoadingShiftBody] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loadingUpdateWeeklyShiftData, setLoadingUpdateWeeklyShiftData] = useState(false);
    const [createDisabled, setCreateDisabled] = useState(true);
    const [updateDisabled, setUpdateDisabled] = useState(true);
    const [updateWeeklyShiftDataDisabled, setUpdateWeeklyShiftDataDisabled] = useState(true);
    const [loadingUpdateUserInfo, setLoadingUpdateUserInfo] = useState(false)

    const [talentData, setTalentData] = useState([]);

    const [businessName, setBusinessName] = useState();
    const [professionData, setProfessionData] = useState([]);
    const [professionId, setProfessionId] = useState([]);

    const [skillChildren, setSkillChildren] = useState([])

    const [skillData1, setSkillData1] = useState([]);
    const [skillData2, setSkillData2] = useState([]);
    const [skillData3, setSkillData3] = useState([]);
    const [skillData4, setSkillData4] = useState([]);
    const [skillData5, setSkillData5] = useState([]);
    const [skillData6, setSkillData6] = useState([]);
    const [skillData7, setSkillData7] = useState([]);
    const [skillData8, setSkillData8] = useState([]);
    const [skillData9, setSkillData9] = useState([]);
    const [skillData10, setSkillData10] = useState([]);

    const [skillFlag1, setSkillFlag1] = useState(false);
    const [skillFlag2, setSkillFlag2] = useState(false);
    const [skillFlag3, setSkillFlag3] = useState(false);
    const [skillFlag4, setSkillFlag4] = useState(false);
    const [skillFlag5, setSkillFlag5] = useState(false);
    const [skillFlag6, setSkillFlag6] = useState(false);
    const [skillFlag7, setSkillFlag7] = useState(false);
    const [skillFlag8, setSkillFlag8] = useState(false);
    const [skillFlag9, setSkillFlag9] = useState(false);
    const [skillFlag10, setSkillFlag10] = useState(false);

    const [skillId1, setSkillId1] = useState();
    const [skillId2, setSkillId2] = useState();
    const [skillId3, setSkillId3] = useState();
    const [skillId4, setSkillId4] = useState();
    const [skillId5, setSkillId5] = useState();
    const [skillId6, setSkillId6] = useState();
    const [skillId7, setSkillId7] = useState();
    const [skillId8, setSkillId8] = useState();
    const [skillId9, setSkillId9] = useState();
    const [skillId10, setSkillId10] = useState();

    const [employmentData, setEmploymentData] = useState([]);
    const [employmentIds, setEmploymentIds] = useState([]);
    const [scheduleData, setScheduleData] = useState([]);
    const [scheduleIds, setScheduleIds] = useState([]);
    const [facilityData, setFacilityData] = useState([]);
    const [facilityIds, setFacilityIds] = useState([]);
    const [payrollData, setPayrollData] = useState([]);
    const [payrollIds, setPayrollIds] = useState([]);
    const [shiftData, setShiftData] = useState([]);
    const [shiftIds, setShiftIds] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [paymentIds, setPaymentIds] = useState([]);
    const [address, setAddress] = useState();
    const [address1, setAddress1] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [zipCode, setZipCode] = useState();
    const [country, setCountry] = useState();
    const [unitId, setUnitId] = useState();
    const [about, setAbout] = useState();

    const [employmentDataForEdit, setEmploymentDataForEdit] = useState([]);
    const [scheduleDataForEdit, setScheduleDataForEdit] = useState([]);
    const [facilityDataForEdit, setFacilityDataForEdit] = useState([]);
    const [payrollDataForEdit, setPayrollDataForEdit] = useState([]);
    const [shiftDataForEdit, setShiftDataForEdit] = useState([]);
    const [paymentDataForEdit, setPaymentDataForEdit] = useState([]);

    const [employmentDataForShift, setEmploymentDataForShift] = useState([]);
    const [scheduleDataForShift, setScheduleDataForShift] = useState([]);
    const [facilityDataForShift, setFacilityDataForShift] = useState([]);
    const [payrollDataForShift, setPayrollDataForShift] = useState([]);
    const [shiftDataForShift, setShiftDataForShift] = useState([]);
    const [paymentDataForShift, setPaymentDataForShift] = useState([]);

    const [daysListData, setDaysListData] = useState([]);
    const [activeDaysListData, setActiveDaysListData] = useState([]);
    const [isSundayChecked, setIsSundayChecked] = useState(false);
    const [isMondayChecked, setIsMondayChecked] = useState(false);
    const [isTuesdayChecked, setIsTuesdayChecked] = useState(false);
    const [isWednesdayChecked, setIsWednesdayChecked] = useState(false);
    const [isThursdayChecked, setIsThursdayChecked] = useState(false);
    const [isFridayChecked, setIsFridayChecked] = useState(false);
    const [isSaturdayChecked, setIsSaturdayChecked] = useState(false);
    const [sundayStartTime, setSundayStartTime] = useState();
    const [mondayStartTime, setMondayStartTime] = useState();
    const [tuesdayStartTime, setTuesdayStartTime] = useState();
    const [wednesdayStartTime, setWednesdayStartTime] = useState();
    const [thursdayStartTime, setThursdayStartTime] = useState();
    const [fridayStartTime, setFridayStartTime] = useState();
    const [saturdayStartTime, setSaturdayStartTime] = useState();
    const [sundayEndTime, setSundayEndTime] = useState();
    const [mondayEndTime, setMondayEndTime] = useState();
    const [tuesdayEndTime, setTuesdayEndTime] = useState();
    const [wednesdayEndTime, setWednesdayEndTime] = useState();
    const [thursdayEndTime, setThursdayEndTime] = useState();
    const [fridayEndTime, setFridayEndTime] = useState();
    const [saturdayEndTime, setSaturdayEndTime] = useState();
    const [sundayTimeObject, setSundayTimeObject] = useState({});
    const [mondayTimeObject, setMondayTimeObject] = useState({});
    const [tuesdayTimeObject, setTuesdayTimeObject] = useState({});
    const [wednesdayTimeObject, setWednesdayTimeObject] = useState({});
    const [thursdayTimeObject, setThursdayTimeObject] = useState({});
    const [fridayTimeObject, setFridayTimeObject] = useState({});
    const [saturdayTimeObject, setSaturdayTimeObject] = useState({});
    const [weeklyShiftPayload, setWeeklyShiftPayload] = useState([]);
    const [initialWeeklyShiftPayload, setInitialWeeklyShiftPayload] = useState([]);

    const [filterShow, setFilterShow] = useState(false);

    const [userInformation, setUserInformation] = useState();

    const [verifyModal, setVerifyModal] = useState(false);
    const [editVerifyModal, setEditVerifyModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [shiftModal, setShiftModal] = useState(false);

    const [isBusinessNameFieldDisabled, setIsBusinessNameFieldDisabled] = useState(false)

    const history = useHistory();
    const location = useLocation();
    const { state1 } = location

    useEffect(() => {
        let params = {}
        if (props.auth.isLoggedIn) {
            if (props.auth.userProfile) {
                setUserIdHash(props.auth.userProfile.userIdHash)
                params = {
                    userIdHash: props.auth.userProfile.userIdHash
                }
                if (props.auth.userProfile.userCategoryInfo.isOpenUserProfile == '0') {
                    setIsBusinessNameFieldDisabled(true)
                } else {
                    setIsBusinessNameFieldDisabled(false)
                }
                getUserInformationData()
            }
        }

        props.getTalents(params, res => {
            if (res.status) {
                if (res.data.length > 0) {
                    setTalentData(res.data)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            }
        })

        props.getProfession(params, res => {
            if (res.status) {
                if (res.data.length > 0) {
                    setProfessionData(res.data)
                    setProfessionId(res.data[0].id)
                } else {
                    setProfessionData([])
                }
            } else {
                setProfessionData([])
            }
        })

        props.getEmployment(params, res => {
            if (res.status) {
                if (res.data.length > 0) {
                    setEmploymentData(res.data)
                }
            }
        })

        props.getSchedule(params, res => {
            if (res.status) {
                if (res.data.length > 0) {
                    setScheduleData(res.data)
                }
            }
        })

        props.getFacility(params, res => {
            if (res.status) {
                if (res.data.length > 0) {
                    setFacilityData(res.data)
                }
            }
        })

        props.getPayroll(params, res => {
            if (res.status) {
                if (res.data.length > 0) {
                    setPayrollData(res.data)
                }
            }
        })

        props.getShift(params, res => {
            if (res.status) {
                if (res.data.length > 0) {
                    setShiftData(res.data)
                }
            }
        })

        props.getPayment(params, res => {
            if (res.status) {
                if (res.data.length > 0) {
                    setPaymentData(res.data)
                }
            }
        })

        props.getDaysList(params, res => {
            if (res.status) {
                if (res.data.length > 0) {
                    setDaysListData(res.data)
                }
            }
        })

        if (location.state?.fromLogin) {
            openCreateTalentModal()
        }
    }, [])

    const getUserInformationData = () => {
        props.getUserInformation({}, res => {
            if (res.status) {
                setUserInformation(res.data)
                if (res.data.entityName !== null) {
                    setBusinessName(res.data?.entityName)
                }
            }
        })
    }

    const openTalentDetailsPage = (e, value) => {
        e.preventDefault()
        history.push('/talent/' + value.id)
    }

    function openVerifyModal(e = null) {
        if (e) {
            e.preventDefault();
        }
        if (props.auth.userProfile.userCategoryInfo.isOpenUserProfile == '0') {
            setIsBusinessNameFieldDisabled(true)
        } else {
            setIsBusinessNameFieldDisabled(false)
        }
        getUserInformationData()
        setAddress()
        setAddress1()
        setCity()
        setState()
        setCountry()
        setZipCode()
        setVerifyModal(true)
    }

    function closeVerifyModal(e = null) {
        if (e) {
            e.preventDefault();
        }
        setVerifyModal(false)
    }

    function openEditVerifyModal(e = null) {
        if (e) {
            e.preventDefault();
        }
        setVerifyModal(false)
        setEditVerifyModal(true)
    }

    function backToVerifyModal(e = null) {
        if (e) {
            e.preventDefault();
        }
        setEditVerifyModal(false)
        setVerifyModal(true)
    }

    function updatePrimaryInformation(e) {
        e.preventDefault();
        if (businessName == '' || businessName == null || businessName == undefined) {
            toast.error('Business Name must not be empty')
            return
        }

        let params = {
            data: {
                address: address ? address : userInformation.address,
                address1: address1,
                city: city ? city : userInformation.city,
                country: country ? country : userInformation.country,
                countryAbbr: "IN",
                entityName: businessName ? businessName : userInformation.businessName,
                firstName: userInformation.firstName,
                id: 0,
                isDefault: 0,
                isValidated: 0,
                isVendorManaged: 0,
                isVerified: 0,
                lastName: userInformation.lastName,
                middleName: "MN",
                phoneNumber: "6666666666",
                state: state ? state : userInformation.state,
                stateAbbr: "SA",
                unitId: unitId ? unitId : userInformation.unitId,
                uploadDirectory: "UD",
                zipCode: zipCode ? zipCode : userInformation.zipCode
            },
            userInformationId: userInformation.id,
            userIdHash: userIdHash,
            token: userIdHash
        }

        setLoadingUpdateUserInfo(true)

        props.updateUserInformation(params, res => {
            if (res.status) {
                getUserInformationData()
                setLoadingUpdateUserInfo(false)
                setEditVerifyModal(false)
                setVerifyModal(true)
            }
        })
    }

    function openCreateTalentModal(e = null) {
        if (e) {
            e.preventDefault();
        }
        if (props.auth.userProfile.userCategoryInfo.isOpenUserProfile == '0') {
            setIsBusinessNameFieldDisabled(true)
        } else {
            setIsBusinessNameFieldDisabled(false)
        }
        getUserInformationData()
        setVerifyModal(false)
        setCreateModal(true)
        setProfessionId()
        setSkillData1([])
        setSkillFlag1(false)
        setSkillData2([])
        setSkillFlag2(false)
        setSkillData3([])
        setSkillFlag3(false)
        setSkillData4([])
        setSkillFlag4(false)
        setSkillData5([])
        setSkillFlag5(false)
        setSkillData6([])
        setSkillFlag6(false)
        setSkillData7([])
        setSkillFlag7(false)
        setSkillData8([])
        setSkillFlag8(false)
        setSkillData9([])
        setSkillData9(false)
        setSkillData10([])
        setSkillFlag10(false)
        setSkillId1()
        setSkillId2()
        setSkillId3()
        setSkillId4()
        setSkillId5()
        setSkillId6()
        setSkillId7()
        setSkillId8()
        setSkillId9()
        setSkillId10()
        // setAddress()
        // setAddress1()
        // setCity()
        // setState()
        // setCountry()
        // setZipCode()
        setAbout()
        setUnitId()
        setEmploymentIds([])
        setPaymentIds([])
        setPayrollIds([])
        setShiftIds([])
        setFacilityIds([])
        setScheduleIds([])
    }

    function closeCreateTalentModal(e) {
        e.preventDefault();
        setCreateModal(false)
    }

    const autoCompleteHandleChange = (address) => {
        setAddress(address);
    };

    const autoCompleteHandleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                document.getElementById("searchTextField").blur();
                setTimeout(() => {
                    document.getElementById("searchTextField").value = "";
                }, 10);
                fillAddressDetails(results);
            })
            .catch((error) => { });
    };

    const google = (window.google = window.google ? window.google : {});

    const searchOptions = {
        // location: new this.google.maps.LatLng(51.509865, -0.118092),
        // radius: 424000,
        //types: ["address", "uk"],
        componentRestrictions: { country: "" },
    };

    const fillAddressDetails = (results) => {
        setAddress(results[0].formatted_address)
        for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] == "postal_code") {
                setZipCode(results[0].address_components[j].short_name);
            } else if (results[0].address_components[j].types[0] == "locality") {
                setCity(results[0].address_components[j].long_name);
            } else if (results[0].address_components[j].types[0] == "administrative_area_level_1") {
                setState(results[0].address_components[j].long_name)
            } else if (results[0].address_components[j].types[0] == "country") {
                setCountry(results[0].address_components[j].long_name)
            }
        }
        setUpdateDisabled(false)
    };

    const onEmploymentTypeSelect = (selectedList) => {
        let arr = [];
        for (let i = 0; i < selectedList.length; i++) { arr.push(selectedList[i].id) }
        setEmploymentIds(arr)
        setUpdateDisabled(false)
    }

    const onScheduleTypeSelect = (selectedList) => {
        let arr = [];
        for (let i = 0; i < selectedList.length; i++) { arr.push(selectedList[i].id) }
        setScheduleIds(arr)
        setUpdateDisabled(false)
    }

    const onFacilityTypeSelect = (selectedList) => {
        let arr = [];
        for (let i = 0; i < selectedList.length; i++) { arr.push(selectedList[i].id) }
        setFacilityIds(arr)
        setUpdateDisabled(false)
    }

    const onPayrollTypeSelect = (selectedList) => {
        let arr = [];
        for (let i = 0; i < selectedList.length; i++) { arr.push(selectedList[i].id) }
        setPayrollIds(arr)
        setUpdateDisabled(false)
    }

    const onShiftTypeSelect = (selectedList) => {
        let arr = [];
        for (let i = 0; i < selectedList.length; i++) { arr.push(selectedList[i].id) }
        setShiftIds(arr)
        setUpdateDisabled(false)
    }

    const onPaymentTypeSelect = (selectedList) => {
        let arr = [];
        for (let i = 0; i < selectedList.length; i++) { arr.push(selectedList[i].id) }
        setPaymentIds(arr)
        setUpdateDisabled(false)
    }

    //changeInputValue Function
    function changeInputvalue(e, flag) {
        const value = e.target.value
        setCreateDisabled(false)
        setUpdateDisabled(false)

        if (flag == 'business') { setBusinessName(value) }
        else if (flag == 'profession') {
            setProfessionId(value)

            setSkillData1([])
            setSkillFlag1(false)
            setSkillData2([])
            setSkillFlag2(false)
            setSkillData3([])
            setSkillFlag3(false)
            setSkillData4([])
            setSkillFlag4(false)
            setSkillData5([])
            setSkillFlag5(false)
            setSkillData6([])
            setSkillFlag6(false)
            setSkillData7([])
            setSkillFlag7(false)
            setSkillData8([])
            setSkillFlag8(false)
            setSkillData9([])
            setSkillData9(false)
            setSkillData10([])
            setSkillFlag10(false)
            setSkillId1()
            setSkillId2()
            setSkillId3()
            setSkillId4()
            setSkillId5()
            setSkillId6()
            setSkillId7()
            setSkillId8()
            setSkillId9()
            setSkillId10()

            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {
                        setSkillData1(res.data)
                        setSkillFlag1(true)
                    }
                }
            })
        }
        else if (flag == 'skill1') {
            setSkillId1(value)
            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {
                        setSkillData2(res.data)
                        setSkillFlag2(true)
                    }
                } else {
                    setSkillData2([])
                    setSkillFlag2(false)
                    setSkillData3([])
                    setSkillFlag3(false)
                    setSkillData4([])
                    setSkillFlag4(false)
                    setSkillData5([])
                    setSkillFlag5(false)
                    setSkillData6([])
                    setSkillFlag6(false)
                    setSkillData7([])
                    setSkillFlag7(false)
                    setSkillData8([])
                    setSkillFlag8(false)
                    setSkillData9([])
                    setSkillData9(false)
                    setSkillData10([])
                    setSkillFlag10(false)
                    setSkillId2()
                    setSkillId3()
                    setSkillId4()
                    setSkillId5()
                    setSkillId6()
                    setSkillId7()
                    setSkillId8()
                    setSkillId9()
                    setSkillId10()
                }
            })
        }
        else if (flag == 'skill2') {
            setSkillId2(value)
            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {
                        setSkillData3(res.data)
                        setSkillFlag3(true)
                    }
                } else {
                    setSkillData3([])
                    setSkillFlag3(false)
                    setSkillData4([])
                    setSkillFlag4(false)
                    setSkillData5([])
                    setSkillFlag5(false)
                    setSkillData6([])
                    setSkillFlag6(false)
                    setSkillData7([])
                    setSkillFlag7(false)
                    setSkillData8([])
                    setSkillFlag8(false)
                    setSkillData9([])
                    setSkillData9(false)
                    setSkillData10([])
                    setSkillFlag10(false)
                    setSkillId3()
                    setSkillId4()
                    setSkillId5()
                    setSkillId6()
                    setSkillId7()
                    setSkillId8()
                    setSkillId9()
                    setSkillId10()
                }
            })
        }
        else if (flag == 'skill3') {
            setSkillId3(value)
            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {
                        setSkillData4(res.data)
                        setSkillFlag4(true)
                    }
                } else {
                    setSkillData4([])
                    setSkillFlag4(false)
                    setSkillData5([])
                    setSkillFlag5(false)
                    setSkillData6([])
                    setSkillFlag6(false)
                    setSkillData7([])
                    setSkillFlag7(false)
                    setSkillData8([])
                    setSkillFlag8(false)
                    setSkillData9([])
                    setSkillData9(false)
                    setSkillData10([])
                    setSkillFlag10(false)
                    setSkillId4()
                    setSkillId5()
                    setSkillId6()
                    setSkillId7()
                    setSkillId8()
                    setSkillId9()
                    setSkillId10()
                }
            })
        }
        else if (flag == 'skill4') {
            setSkillId4(value)
            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {
                        setSkillData5(res.data)
                        setSkillFlag5(true)
                    }
                } else {
                    setSkillData5([])
                    setSkillFlag5(false)
                    setSkillData6([])
                    setSkillFlag6(false)
                    setSkillData7([])
                    setSkillFlag7(false)
                    setSkillData8([])
                    setSkillFlag8(false)
                    setSkillData9([])
                    setSkillData9(false)
                    setSkillData10([])
                    setSkillFlag10(false)
                    setSkillId5()
                    setSkillId6()
                    setSkillId7()
                    setSkillId8()
                    setSkillId9()
                    setSkillId10()
                }
            })
        }
        else if (flag == 'skill5') {
            setSkillId5(value)
            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {
                        setSkillData6(res.data)
                        setSkillFlag6(true)
                    }
                } else {
                    setSkillData6([])
                    setSkillFlag6(false)
                    setSkillData7([])
                    setSkillFlag7(false)
                    setSkillData8([])
                    setSkillFlag8(false)
                    setSkillData9([])
                    setSkillData9(false)
                    setSkillData10([])
                    setSkillFlag10(false)
                    setSkillId6()
                    setSkillId7()
                    setSkillId8()
                    setSkillId9()
                    setSkillId10()
                }
            })
        }
        else if (flag == 'skill6') {
            setSkillId6(value)
            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {
                        setSkillData7(res.data)
                        setSkillFlag7(true)
                    }
                } else {
                    setSkillData7([])
                    setSkillFlag7(false)
                    setSkillData8([])
                    setSkillFlag8(false)
                    setSkillData9([])
                    setSkillData9(false)
                    setSkillData10([])
                    setSkillFlag10(false)
                    setSkillId7()
                    setSkillId8()
                    setSkillId9()
                    setSkillId10()
                }
            })
        }
        else if (flag == 'skill7') {
            setSkillId7(value)
            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {
                        setSkillData8(res.data)
                        setSkillFlag8(true)
                    }
                } else {
                    setSkillData8([])
                    setSkillFlag8(false)
                    setSkillData9([])
                    setSkillData9(false)
                    setSkillData10([])
                    setSkillFlag10(false)
                    setSkillId8()
                    setSkillId9()
                    setSkillId10()
                }
            })
        }
        else if (flag == 'skill8') {
            setSkillId8(value)
            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {
                        setSkillData9(res.data)
                        setSkillFlag9(true)
                    }
                } else {
                    setSkillData9([])
                    setSkillData9(false)
                    setSkillData10([])
                    setSkillFlag10(false)
                    setSkillId9()
                    setSkillId10()
                }
            })
        }
        else if (flag == 'skill9') {
            setSkillId9(value)
            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {
                        setSkillData10(res.data)
                        setSkillFlag10(true)
                    }
                } else {
                    setSkillData10([])
                    setSkillFlag10(false)
                    setSkillId10()
                }
            })
        }
        else if (flag == 'skill10') {
            setSkillId10(value)
            props.getSkill({ professionId: value }, res => {
                if (res.status) {
                    if (res.data.length) {

                    }
                } else {

                }
            })
        }
        else if (flag == 'address') { setAddress(value) }
        else if (flag == 'address1') { setAddress1(value) }
        else if (flag == 'city') { setCity(value) }
        else if (flag == 'state') { setState(value) }
        else if (flag == 'zip') { setZipCode(value) }
        else if (flag == 'country') { setCountry(value) }
        else if (flag == 'unit') { setUnitId(value) }
        else if (flag == 'about') { setAbout(value) }
    }

    const createTalentProfile = (e) => {
        e.preventDefault()

        if (businessName == '' || businessName == undefined) {
            toast.error('Business Name cannot be empty')
            return
        } else if (professionId == '' || professionId == undefined) {
            toast.error('Please select profession')
            return
        } else if (address == '' || address == undefined) {
            toast.error('Address cannot be empty')
            return
        } else if (city == '' || city == undefined) {
            toast.error('City cannot be empty')
            return
        } else if (state == '' || state == undefined) {
            toast.error('State cannot be empty')
            return
        } else if (zipCode == '' || zipCode == undefined) {
            toast.error('Zip Code cannot be empty')
            return
        } else if (country == '' || country == undefined) {
            toast.error('Country cannot be empty')
            return
        } else if (employmentIds.length == 0) {
            toast.error('Please select Employment Type')
            return
        } else if (facilityIds.length == 0) {
            toast.error('Please select Facility Type')
            return
        } else if (paymentIds.length == 0) {
            toast.error('Please select Payment Type')
            return
        } else if (payrollIds.length == 0) {
            toast.error('Please select Payroll Type')
            return
        } else if (scheduleIds.length == 0) {
            toast.error('Please select Schedule Type')
            return
        } else if (shiftIds.length == 0) {
            toast.error('Please select Shift Type')
            return
        }

        let arr = []
        if (professionId) arr.push(professionId)
        if (skillId1) arr.push(skillId1)
        if (skillId2) arr.push(skillId2)
        if (skillId3) arr.push(skillId3)
        if (skillId4) arr.push(skillId4)
        if (skillId5) arr.push(skillId5)
        if (skillId6) arr.push(skillId6)
        if (skillId7) arr.push(skillId7)
        if (skillId8) arr.push(skillId8)
        if (skillId9) arr.push(skillId9)
        if (skillId10) arr.push(skillId10)

        let params = {
            userTypeId: props.auth.userProfile.userType.userTypeId,
            employmentTypeIds: employmentIds.toString(),
            facilityTypeIds: facilityIds.toString(),
            paymentTypeIds: paymentIds.toString(),
            payrollTypeIds: payrollIds.toString(),
            scheduleTypeIds: scheduleIds.toString(),
            shiftTypeIds: shiftIds.toString(),
            selectedEntityTypeIds: arr.toString(),
            entityName: businessName,
            about: about,
            address: address,
            address1: address1,
            city: city,
            state: state,
            zipCode: zipCode,
            country: country,
            unitId: unitId
        }

        setLoadingCreate(true)
        props.createTalent(params, res => {
            if (res.status) {
                setCreateModal(false)
                setLoadingCreate(false)
                swal({
                    title: "Talent Profile created successfully!",
                    text: "",
                    icon: "success"
                }).then(onUpdate => {
                    e.preventDefault()
                    setLoading(true)
                    props.getTalents({
                        userIdHash: userIdHash
                    }, res => {
                        if (res.status) {
                            if (res.data.length > 0) {
                                setLoading(false);
                                setTalentData(res.data);
                            } else {
                                setLoading(false)
                            }
                        }
                    })
                })

            } else {
                setLoadingCreate(false)
                toast.error(res.message)
            }
        })

    }

    const openEditModal = (e, value) => {
        e.preventDefault()
        if (props.auth.userProfile.userCategoryInfo.isOpenUserProfile == '0') {
            setIsBusinessNameFieldDisabled(true)
        } else {
            setIsBusinessNameFieldDisabled(false)
        }
        setEditModal(true)
        setUpdateDisabled(true)
        setLoadingEditBody(true)
        setUserProfileId(value.id)
        let params = {
            userProfileId: value.id
        }
        props.getTalentProfile(params, res => {
            if (res.status) {
                setBusinessName(res.data.entityName)
                setAddress(res.data.address)
                setAddress1(res.data.address1)
                setCity(res.data.city)
                setState(res.data.state)
                setZipCode(res.data.zipCode)
                setCountry(res.data.country)
                setAbout(res.data.about)

                if (employmentData.length > 0) {
                    let arr = []
                    if (res.data.userProfileDataTypes.employmentType.length > 0) {
                        employmentData.map(emp => {
                            res.data.userProfileDataTypes.employmentType.map(empType => {
                                if (empType.employmentTypeId == emp.id) {
                                    arr.push(emp)
                                }
                            })
                        })
                    }
                    setEmploymentDataForEdit(arr)
                }

                let arrEmp = [];
                for (let i = 0; i < res.data.userProfileDataTypes.employmentType.length; i++) {
                    arrEmp.push(res.data.userProfileDataTypes.employmentType[i].employmentTypeId)
                }
                setEmploymentIds(arrEmp)

                if (facilityData.length > 0) {
                    let arr = []
                    if (res.data.userProfileDataTypes.facilityType.length > 0) {
                        facilityData.map(emp => {
                            res.data.userProfileDataTypes.facilityType.map(empType => {
                                if (empType.facilityTypeId == emp.id) {
                                    arr.push(emp)
                                }
                            })
                        })
                    }
                    setFacilityDataForEdit(arr)
                }

                let arrFac = [];
                for (let i = 0; i < res.data.userProfileDataTypes.facilityType.length; i++) {
                    arrFac.push(res.data.userProfileDataTypes.facilityType[i].facilityTypeId)
                }
                setFacilityIds(arrFac)

                if (paymentData.length > 0) {
                    let arr = []
                    if (res.data.userProfileDataTypes.paymentType.length > 0) {
                        paymentData.map(emp => {
                            res.data.userProfileDataTypes.paymentType.map(empType => {
                                if (empType.paymentTypeId == emp.id) {
                                    arr.push(emp)
                                }
                            })
                        })
                    }
                    setPaymentDataForEdit(arr)
                }

                let arrPayment = [];
                for (let i = 0; i < res.data.userProfileDataTypes.paymentType.length; i++) {
                    arrPayment.push(res.data.userProfileDataTypes.paymentType[i].paymentTypeId)
                }
                setPaymentIds(arrPayment)

                if (payrollData.length > 0) {
                    let arr = []
                    if (res.data.userProfileDataTypes.payrollType.length > 0) {
                        payrollData.map(emp => {
                            res.data.userProfileDataTypes.payrollType.map(empType => {
                                if (empType.payrollTypeId == emp.id) {
                                    arr.push(emp)
                                }
                            })
                        })
                    }
                    setPayrollDataForEdit(arr)
                }

                let arrPayroll = [];
                for (let i = 0; i < res.data.userProfileDataTypes.payrollType.length; i++) {
                    arrPayroll.push(res.data.userProfileDataTypes.payrollType[i].payrollTypeId)
                }
                setPayrollIds(arrPayroll)

                if (scheduleData.length > 0) {
                    let arr = []
                    if (res.data.userProfileDataTypes.scheduleType.length > 0) {
                        scheduleData.map(emp => {
                            res.data.userProfileDataTypes.scheduleType.map(empType => {
                                if (empType.scheduleTypeId == emp.id) {
                                    arr.push(emp)
                                }
                            })
                        })
                    }
                    setScheduleDataForEdit(arr)
                }

                let arrSch = [];
                for (let i = 0; i < res.data.userProfileDataTypes.scheduleType.length; i++) {
                    arrSch.push(res.data.userProfileDataTypes.scheduleType[i].scheduleTypeId)
                }
                setScheduleIds(arrSch)

                if (shiftData.length > 0) {
                    let arr = []
                    if (res.data.userProfileDataTypes.shiftType.length > 0) {
                        shiftData.map(emp => {
                            res.data.userProfileDataTypes.shiftType.map(empType => {
                                if (empType.shiftTypeId == emp.id) {
                                    arr.push(emp)
                                }
                            })
                        })
                    }
                    setShiftDataForEdit(arr)
                }

                let arrShift = [];
                for (let i = 0; i < res.data.userProfileDataTypes.shiftType.length; i++) {
                    arrShift.push(res.data.userProfileDataTypes.shiftType[i].shiftTypeId)
                }
                setShiftIds(arrShift)

                setLoadingEditBody(false)
            } else {
                setLoadingEditBody(false)
                setEditModal(false)
            }
        })

    }

    const closeEditModal = () => {
        setEditModal(false)
        setUserProfileId()
    }

    const updateTalentProfile = (e) => {
        if (employmentIds.length == 0) {
            toast.error('Please select Employment Type')
            return
        } else if (facilityIds.length == 0) {
            toast.error('Please select Facility Type')
            return
        } else if (paymentIds.length == 0) {
            toast.error('Please select Payment Type')
            return
        } else if (payrollIds.length == 0) {
            toast.error('Please select Payroll Type')
            return
        } else if (scheduleIds.length == 0) {
            toast.error('Please select Schedule Type')
            return
        } else if (shiftIds.length == 0) {
            toast.error('Please select Shift Type')
            return
        }

        let params = {
            userProfileId: userProfileId,
            employmentTypeIds: employmentIds.toString(),
            facilityTypeIds: facilityIds.toString(),
            paymentTypeIds: paymentIds.toString(),
            payrollTypeIds: payrollIds.toString(),
            scheduleTypeIds: scheduleIds.toString(),
            shiftTypeIds: shiftIds.toString(),
            // selectedEntityTypeIds: arr.toString(),
            entityName: businessName,
            about: about,
            address: address == null ? '' : address,
            address1: address1 == null ? '' : address1,
            city: city,
            state: state,
            zipCode: zipCode,
            country: country,
            unitId: unitId
        }

        setLoadingUpdate(true)
        props.updateTalent(params, res => {
            if (res.status) {
                setEditModal(false)
                setLoadingUpdate(false)
                swal({
                    title: "Talent Profile updated successfully!",
                    text: "",
                    icon: "success"
                }).then(onUpdate => {
                    e.preventDefault()
                    setLoading(true)
                    props.getTalents({
                        userIdHash: userIdHash
                    }, res => {
                        if (res.status) {
                            if (res.data.length > 0) {
                                setTalentData(res.data)
                                setLoading(false)
                            } else {
                                setLoading(false)
                            }
                        }
                    })
                })

            } else {
                setLoadingUpdate(false)
                toast.error(res.message)
            }
        })
    }

    const openShiftModal = (e, value) => {
        e.preventDefault()

        setUserProfileId(value.id)
        setUserTypeId(value.userTypeId)
        setFilterShow(false)
        setUserId(value.userId)
        setBusinessName(value.entityName)
        setAddress(value.address1 ? value.address1 : value.address)
        if (value.userProfileDataTypes.employmentType.length > 0) {
            let arr = []
            for (let i = 0; i < value.userProfileDataTypes.employmentType.length; i++) {
                arr.push(value.userProfileDataTypes.employmentType[i].name)
            }
            setEmploymentDataForShift(arr)
        }
        if (value.userProfileDataTypes.facilityType.length > 0) {
            let arr = []
            for (let i = 0; i < value.userProfileDataTypes.facilityType.length; i++) {
                arr.push(value.userProfileDataTypes.facilityType[i].name)
            }
            setFacilityDataForShift(arr)
        }
        if (value.userProfileDataTypes.paymentType.length > 0) {
            let arr = []
            for (let i = 0; i < value.userProfileDataTypes.paymentType.length; i++) {
                arr.push(value.userProfileDataTypes.paymentType[i].name)
            }
            setPaymentDataForShift(arr)
        }
        if (value.userProfileDataTypes.scheduleType.length > 0) {
            let arr = []
            for (let i = 0; i < value.userProfileDataTypes.scheduleType.length; i++) {
                arr.push(value.userProfileDataTypes.scheduleType[i].name)
            }
            setScheduleDataForShift(arr)
        }
        if (value.userProfileDataTypes.shiftType.length > 0) {
            let arr = []
            for (let i = 0; i < value.userProfileDataTypes.shiftType.length; i++) {
                arr.push(value.userProfileDataTypes.shiftType[i].name)
            }
            setShiftDataForShift(arr)
        }
        if (value.userProfileDataTypes.payrollType.length > 0) {
            let arr = []
            for (let i = 0; i < value.userProfileDataTypes.payrollType.length; i++) {
                arr.push(value.userProfileDataTypes.payrollType[i].name)
            }
            setPayrollDataForShift(arr)
        }
        setActiveDaysListData([])
        setWeeklyShiftPayload([])
        setInitialWeeklyShiftPayload([])
        setSundayStartTime()
        setMondayStartTime()
        setTuesdayStartTime()
        setWednesdayStartTime()
        setThursdayStartTime()
        setFridayStartTime()
        setSaturdayStartTime()
        setSundayEndTime()
        setMondayEndTime()
        setTuesdayEndTime()
        setWednesdayEndTime()
        setThursdayEndTime()
        setFridayEndTime()
        setSaturdayEndTime()
        setSundayTimeObject({})
        setMondayTimeObject({})
        setTuesdayTimeObject({})
        setWednesdayTimeObject({})
        setThursdayTimeObject({})
        setFridayTimeObject({})
        setSaturdayTimeObject({})
        document.getElementById('kt_content').setAttribute('style', 'position:fixed; width:85%')
        setShiftModal(true)
        setLoadingShiftBody(true)
        setLoadingUpdateWeeklyShiftData(false)

        let params = {
            userProfileId: value.id
        }
        let hourArrayForPm = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 12]

        props.getActiveDaysList(params, res => {
            if (res.status) {
                if (res.data.length > 0) {
                    setActiveDaysListData(res.data)
                    let payloadArray = []
                    for (let i = 0; i < res.data.length; i++) {
                        payloadArray.push(res.data[i])
                    }
                    setWeeklyShiftPayload(payloadArray)
                    setInitialWeeklyShiftPayload(payloadArray)
                    daysListData.map(day => {
                        res.data.map(activeDay => {
                            let timeObj = {}
                            if (activeDay.day == (day.day).toLowerCase()) {
                                //toggleSwitch(activeDay.day)
                                if (activeDay.day == 'sunday') {
                                    setIsSundayChecked(true)
                                } else if (activeDay.day == 'monday') {
                                    setIsMondayChecked(true)
                                } else if (activeDay.day == 'tuesday') {
                                    setIsTuesdayChecked(true)
                                } else if (activeDay.day == 'wednesday') {
                                    setIsWednesdayChecked(true)
                                } else if (activeDay.day == 'thursday') {
                                    setIsThursdayChecked(true)
                                } else if (activeDay.day == 'friday') {
                                    setIsFridayChecked(true)
                                } else if (activeDay.day == 'saturday') {
                                    setIsSaturdayChecked(true)
                                }
                                if ((activeDay.shiftStartMeridian).toLowerCase() == 'pm') {
                                    changeTime('start', activeDay.day, hourArrayForPm[activeDay.startHour - 1] + ':' + activeDay.startMinute + ' ' + activeDay.shiftStartMeridian)
                                    // let newValue = new Date(moment(hourArrayForPm[activeDay.startHour - 1] + ':' + activeDay.startMinute).format())
                                    // changeTime('start', activeDay.day, newValue.getHours() + ':' + newValue.getMinutes() + ' ' + activeDay.shiftStartMeridian)
                                    timeObj.startHour = activeDay.startHour
                                    timeObj.startMinute = activeDay.startMinute
                                    timeObj.shiftStartMeridian = 'pm'
                                } else {
                                    changeTime('start', activeDay.day, activeDay.startHour + ':' + activeDay.startMinute + ' ' + activeDay.shiftStartMeridian)
                                    timeObj.startHour = activeDay.startHour
                                    timeObj.startMinute = activeDay.startMinute
                                    timeObj.shiftStartMeridian = 'am'
                                }

                                if ((activeDay.shiftEndMeridian).toLowerCase() == 'pm') {
                                    changeTime('end', activeDay.day, hourArrayForPm[activeDay.endHour - 1] + ':' + activeDay.endMinute + ' ' + activeDay.shiftEndMeridian)
                                    timeObj.endHour = activeDay.endHour
                                    timeObj.endMinute = activeDay.endMinute
                                    timeObj.shiftEndMeridian = 'pm'
                                } else {
                                    changeTime('end', activeDay.day, activeDay.endHour + ':' + activeDay.endMinute + ' ' + activeDay.shiftEndMeridian)
                                    timeObj.endHour = activeDay.endHour
                                    timeObj.endMinute = activeDay.endMinute
                                    timeObj.shiftEndMeridian = 'am'
                                }

                                timeObj.day = activeDay.day
                                setTimeObject(timeObj)
                                setLoadingShiftBody(false)
                            }
                        })
                    })
                } else {
                    setLoadingShiftBody(false)
                    setInitialWeeklyShiftPayload([])
                }
            }
        })

    }

    const closeShiftModal = (e) => {
        e.preventDefault()
        setUserProfileId('')
        setUserTypeId('')
        setBusinessName()
        setAddress()
        setEmploymentDataForShift([])
        setFacilityDataForShift([])
        setPaymentDataForShift([])
        setPayrollDataForShift([])
        setScheduleDataForShift([])
        setShiftDataForShift([])
        setIsSundayChecked(false)
        setIsMondayChecked(false)
        setIsTuesdayChecked(false)
        setIsWednesdayChecked(false)
        setIsThursdayChecked(false)
        setIsFridayChecked(false)
        setIsSaturdayChecked(false)
        setShiftModal(false)
        document.getElementById('kt_content').setAttribute('style', '')
    }

    const setTimeObject = (obj) => {
        if (obj) {
            if (obj.day == 'sunday') {
                setSundayTimeObject(obj)
            } else if (obj.day == 'monday') {
                setMondayTimeObject(obj)
            } else if (obj.day == 'tuesday') {
                setTuesdayTimeObject(obj)
            } else if (obj.day == 'wednesday') {
                setWednesdayTimeObject(obj)
            } else if (obj.day == 'thursday') {
                setThursdayTimeObject(obj)
            } else if (obj.day == 'friday') {
                setFridayTimeObject(obj)
            } else if (obj.day == 'saturday') {
                setSaturdayTimeObject(obj)
            }
        }
    }

    const toggleSwitch = (day) => {
        setUpdateWeeklyShiftDataDisabled(false)
        let arr = weeklyShiftPayload
        if (day == 'sunday') {
            if (isSundayChecked) {
                arr = weeklyShiftPayload.filter(x => x.day !== day)
                setWeeklyShiftPayload(arr)
                setSundayStartTime()
                setSundayEndTime()
            } else {
                arr.push({
                    active: 1,
                    day: day,
                    endHour: sundayTimeObject.endHour,
                    endMinute: sundayTimeObject.endMinute,
                    shiftEndMeridian: sundayTimeObject.shiftEndMeridian,
                    shiftStartMeridian: sundayTimeObject.shiftStartMeridian,
                    startHour: sundayTimeObject.startHour,
                    startMinute: sundayTimeObject.startMinute,
                    userId: userId,
                    userProfileId: userProfileId,
                    userTypeId: userTypeId
                })
            }
            setWeeklyShiftPayload(arr)
            setIsSundayChecked(!isSundayChecked)
        } else if (day == 'monday') {
            if (isMondayChecked) {
                arr = weeklyShiftPayload.filter(x => x.day !== day)
                setWeeklyShiftPayload(arr)
                setMondayStartTime()
                setMondayEndTime()
            } else {
                arr.push({
                    active: 1,
                    day: day,
                    endHour: mondayTimeObject.endHour,
                    endMinute: mondayTimeObject.endMinute,
                    shiftEndMeridian: mondayTimeObject.shiftEndMeridian,
                    shiftStartMeridian: mondayTimeObject.shiftStartMeridian,
                    startHour: mondayTimeObject.startHour,
                    startMinute: mondayTimeObject.startMinute,
                    userId: userId,
                    userProfileId: userProfileId,
                    userTypeId: userTypeId
                })
            }
            setWeeklyShiftPayload(arr)
            setIsMondayChecked(!isMondayChecked)
        } else if (day == 'tuesday') {
            if (isTuesdayChecked) {
                arr = weeklyShiftPayload.filter(x => x.day !== day)
                setWeeklyShiftPayload(arr)
                setTuesdayStartTime()
                setTuesdayEndTime()
            } else {
                arr.push({
                    active: 1,
                    day: day,
                    endHour: tuesdayTimeObject.endHour,
                    endMinute: tuesdayTimeObject.endMinute,
                    shiftEndMeridian: tuesdayTimeObject.shiftEndMeridian,
                    shiftStartMeridian: tuesdayTimeObject.shiftStartMeridian,
                    startHour: tuesdayTimeObject.startHour,
                    startMinute: tuesdayTimeObject.startMinute,
                    userId: userId,
                    userProfileId: userProfileId,
                    userTypeId: userTypeId
                })
            }
            setWeeklyShiftPayload(arr)
            setIsTuesdayChecked(!isTuesdayChecked)
        } else if (day == 'wednesday') {
            if (isWednesdayChecked) {
                arr = weeklyShiftPayload.filter(x => x.day !== day)
                setWeeklyShiftPayload(arr)
                setWednesdayStartTime()
                setWednesdayEndTime()
            } else {
                arr.push({
                    active: 1,
                    day: day,
                    endHour: wednesdayTimeObject.endHour,
                    endMinute: wednesdayTimeObject.endMinute,
                    shiftEndMeridian: wednesdayTimeObject.shiftEndMeridian,
                    shiftStartMeridian: wednesdayTimeObject.shiftStartMeridian,
                    startHour: wednesdayTimeObject.startHour,
                    startMinute: wednesdayTimeObject.startMinute,
                    userId: userId,
                    userProfileId: userProfileId,
                    userTypeId: userTypeId
                })
            }
            setWeeklyShiftPayload(arr)
            setIsWednesdayChecked(!isWednesdayChecked)
        } else if (day == 'thursday') {
            if (isThursdayChecked) {
                arr = weeklyShiftPayload.filter(x => x.day !== day)
                setWeeklyShiftPayload(arr)
                setThursdayStartTime()
                setThursdayEndTime()
            } else {
                arr.push({
                    active: 1,
                    day: day,
                    endHour: thursdayTimeObject.endHour,
                    endMinute: thursdayTimeObject.endMinute,
                    shiftEndMeridian: thursdayTimeObject.shiftEndMeridian,
                    shiftStartMeridian: thursdayTimeObject.shiftStartMeridian,
                    startHour: thursdayTimeObject.startHour,
                    startMinute: thursdayTimeObject.startMinute,
                    userId: userId,
                    userProfileId: userProfileId,
                    userTypeId: userTypeId
                })
            }
            setWeeklyShiftPayload(arr)
            setIsThursdayChecked(!isThursdayChecked)
        } else if (day == 'friday') {
            if (isFridayChecked) {
                arr = weeklyShiftPayload.filter(x => x.day !== day)
                setWeeklyShiftPayload(arr)
                setFridayStartTime()
                setFridayEndTime()
            } else {
                arr.push({
                    active: 1,
                    day: day,
                    endHour: fridayTimeObject.endHour,
                    endMinute: fridayTimeObject.endMinute,
                    shiftEndMeridian: fridayTimeObject.shiftEndMeridian,
                    shiftStartMeridian: fridayTimeObject.shiftStartMeridian,
                    startHour: fridayTimeObject.startHour,
                    startMinute: fridayTimeObject.startMinute,
                    userId: userId,
                    userProfileId: userProfileId,
                    userTypeId: userTypeId
                })
            }
            setWeeklyShiftPayload(arr)
            setIsFridayChecked(!isFridayChecked)
        } else if (day == 'saturday') {
            if (isSaturdayChecked) {
                arr = weeklyShiftPayload.filter(x => x.day !== day)
                setWeeklyShiftPayload(arr)
                setSaturdayStartTime()
                setSaturdayEndTime()
            } else {
                arr.push({
                    active: 1,
                    day: day,
                    endHour: saturdayTimeObject.endHour,
                    endMinute: saturdayTimeObject.endMinute,
                    shiftEndMeridian: saturdayTimeObject.shiftEndMeridian,
                    shiftStartMeridian: saturdayTimeObject.shiftStartMeridian,
                    startHour: saturdayTimeObject.startHour,
                    startMinute: saturdayTimeObject.startMinute,
                    userId: userId,
                    userProfileId: userProfileId,
                    userTypeId: userTypeId
                })
            }
            setWeeklyShiftPayload(arr)
            setIsSaturdayChecked(!isSaturdayChecked)
        }

    }

    const changeTime = (type, day, value) => {
        if (value == null) {
            return
        }

        let hourArrayForPm = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        setUpdateWeeklyShiftDataDisabled(false)
        if (day == 'sunday') {
            setIsSundayChecked(true)
            let arr = weeklyShiftPayload
            let obj = sundayTimeObject
            arr.push({
                active: 1,
                day: day,
                endHour: sundayTimeObject.endHour,
                endMinute: sundayTimeObject.endMinute,
                shiftEndMeridian: sundayTimeObject.shiftEndMeridian,
                shiftStartMeridian: sundayTimeObject.shiftStartMeridian,
                startHour: sundayTimeObject.startHour,
                startMinute: sundayTimeObject.startMinute,
                userId: userId,
                userProfileId: userProfileId,
                userTypeId: userTypeId
            })
            if (type == 'start') {
                setSundayStartTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.startHour = 12
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.startHour = value.substring(1, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftStartMeridian = 'pm'
                    obj.startMinute = value.substring(3, 5)
                } else {
                    obj.startHour = value.substring(0, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                }
            } else {
                setSundayEndTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftEndMeridian = 'pm'
                    obj.endMinute = value.substring(3, 5)
                } else {
                    obj.endHour = value.substring(0, 2)
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                }
            }
            if (arr.length > 0) {
                arr.map(a => {
                    if (a.day == 'sunday') {
                        a.endHour = obj.endHour ? obj.endHour : 0
                        a.endMinute = obj.endMinute ? obj.endMinute : 0
                        a.startHour = obj.startHour ? obj.startHour : 0
                        a.startMinute = obj.startMinute ? obj.startMinute : 0
                        a.shiftEndMeridian = obj.shiftEndMeridian
                        a.shiftStartMeridian = obj.shiftStartMeridian
                    }
                })
            }
            setSundayTimeObject({ ...obj })
        } else if (day == 'monday') {
            setIsMondayChecked(true)
            let arr = weeklyShiftPayload
            let obj = mondayTimeObject
            arr.push({
                active: 1,
                day: day,
                endHour: mondayTimeObject.endHour,
                endMinute: mondayTimeObject.endMinute,
                shiftEndMeridian: mondayTimeObject.shiftEndMeridian,
                shiftStartMeridian: mondayTimeObject.shiftStartMeridian,
                startHour: mondayTimeObject.startHour,
                startMinute: mondayTimeObject.startMinute,
                userId: userId,
                userProfileId: userProfileId,
                userTypeId: userTypeId
            })
            if (type == 'start') {
                setMondayStartTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.startHour = 12
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.startHour = value.substring(1, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftStartMeridian = 'pm'
                    obj.startMinute = value.substring(3, 5)
                } else {
                    obj.startHour = value.substring(0, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                }
            } else {
                setMondayEndTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftEndMeridian = 'pm'
                    obj.endMinute = value.substring(3, 5)
                } else {
                    obj.endHour = value.substring(0, 2)
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                }
            }
            if (arr.length > 0) {
                arr.map(a => {
                    if (a.day == 'monday') {
                        a.endHour = obj.endHour ? obj.endHour : 0
                        a.endMinute = obj.endMinute ? obj.endMinute : 0
                        a.startHour = obj.startHour ? obj.startHour : 0
                        a.startMinute = obj.startMinute ? obj.startMinute : 0
                        a.shiftEndMeridian = obj.shiftEndMeridian
                        a.shiftStartMeridian = obj.shiftStartMeridian
                    }
                })
            }
            setMondayTimeObject({ ...obj })
        } else if (day == 'tuesday') {
            setIsTuesdayChecked(true)
            let arr = weeklyShiftPayload
            let obj = tuesdayTimeObject
            arr.push({
                active: 1,
                day: day,
                endHour: tuesdayTimeObject.endHour,
                endMinute: tuesdayTimeObject.endMinute,
                shiftEndMeridian: tuesdayTimeObject.shiftEndMeridian,
                shiftStartMeridian: tuesdayTimeObject.shiftStartMeridian,
                startHour: tuesdayTimeObject.startHour,
                startMinute: tuesdayTimeObject.startMinute,
                userId: userId,
                userProfileId: userProfileId,
                userTypeId: userTypeId
            })
            if (type == 'start') {
                setTuesdayStartTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.startHour = 12
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.startHour = value.substring(1, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftStartMeridian = 'pm'
                    obj.startMinute = value.substring(3, 5)
                } else {
                    obj.startHour = value.substring(0, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                }
            } else {
                setTuesdayEndTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftEndMeridian = 'pm'
                    obj.endMinute = value.substring(3, 5)
                } else {
                    obj.endHour = value.substring(0, 2)
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                }
            }
            if (arr.length > 0) {
                arr.map(a => {
                    if (a.day == 'tuesday') {
                        a.endHour = obj.endHour ? obj.endHour : 0
                        a.endMinute = obj.endMinute ? obj.endMinute : 0
                        a.startHour = obj.startHour ? obj.startHour : 0
                        a.startMinute = obj.startMinute ? obj.startMinute : 0
                        a.shiftEndMeridian = obj.shiftEndMeridian
                        a.shiftStartMeridian = obj.shiftStartMeridian
                    }
                })
            }
            setTuesdayTimeObject({ ...obj })
        } else if (day == 'wednesday') {
            setIsWednesdayChecked(true)
            let arr = weeklyShiftPayload
            let obj = wednesdayTimeObject
            arr.push({
                active: 1,
                day: day,
                endHour: wednesdayTimeObject.endHour,
                endMinute: wednesdayTimeObject.endMinute,
                shiftEndMeridian: wednesdayTimeObject.shiftEndMeridian,
                shiftStartMeridian: wednesdayTimeObject.shiftStartMeridian,
                startHour: wednesdayTimeObject.startHour,
                startMinute: wednesdayTimeObject.startMinute,
                userId: userId,
                userProfileId: userProfileId,
                userTypeId: userTypeId
            })
            if (type == 'start') {
                setWednesdayStartTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.startHour = 12
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.startHour = value.substring(1, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftStartMeridian = 'pm'
                    obj.startMinute = value.substring(3, 5)
                } else {
                    obj.startHour = value.substring(0, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                }
            } else {
                setWednesdayEndTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftEndMeridian = 'pm'
                    obj.endMinute = value.substring(3, 5)
                } else {
                    obj.endHour = value.substring(0, 2)
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                }
            }
            if (arr.length > 0) {
                arr.map(a => {
                    if (a.day == 'wednesday') {
                        a.endHour = obj.endHour ? obj.endHour : 0
                        a.endMinute = obj.endMinute ? obj.endMinute : 0
                        a.startHour = obj.startHour ? obj.startHour : 0
                        a.startMinute = obj.startMinute ? obj.startMinute : 0
                        a.shiftEndMeridian = obj.shiftEndMeridian
                        a.shiftStartMeridian = obj.shiftStartMeridian
                    }
                })
            }
            setWednesdayTimeObject({ ...obj })
        } else if (day == 'thursday') {
            setIsThursdayChecked(true)
            let arr = weeklyShiftPayload
            let obj = thursdayTimeObject
            arr.push({
                active: 1,
                day: day,
                endHour: thursdayTimeObject.endHour,
                endMinute: thursdayTimeObject.endMinute,
                shiftEndMeridian: thursdayTimeObject.shiftEndMeridian,
                shiftStartMeridian: thursdayTimeObject.shiftStartMeridian,
                startHour: thursdayTimeObject.startHour,
                startMinute: thursdayTimeObject.startMinute,
                userId: userId,
                userProfileId: userProfileId,
                userTypeId: userTypeId
            })
            if (type == 'start') {
                setThursdayStartTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.startHour = 12
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.startHour = value.substring(1, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftStartMeridian = 'pm'
                    obj.startMinute = value.substring(3, 5)
                } else {
                    obj.startHour = value.substring(0, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                }
            } else {
                setThursdayEndTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftEndMeridian = 'pm'
                    obj.endMinute = value.substring(3, 5)
                } else {
                    obj.endHour = value.substring(0, 2)
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                }
            }
            if (arr.length > 0) {
                arr.map(a => {
                    if (a.day == 'thursday') {
                        a.endHour = obj.endHour ? obj.endHour : 0
                        a.endMinute = obj.endMinute ? obj.endMinute : 0
                        a.startHour = obj.startHour ? obj.startHour : 0
                        a.startMinute = obj.startMinute ? obj.startMinute : 0
                        a.shiftEndMeridian = obj.shiftEndMeridian
                        a.shiftStartMeridian = obj.shiftStartMeridian
                    }
                })
            }
            setThursdayTimeObject({ ...obj })
        } else if (day == 'friday') {
            setIsFridayChecked(true)
            let arr = weeklyShiftPayload
            let obj = fridayTimeObject
            arr.push({
                active: 1,
                day: day,
                endHour: fridayTimeObject.endHour,
                endMinute: fridayTimeObject.endMinute,
                shiftEndMeridian: fridayTimeObject.shiftEndMeridian,
                shiftStartMeridian: fridayTimeObject.shiftStartMeridian,
                startHour: fridayTimeObject.startHour,
                startMinute: fridayTimeObject.startMinute,
                userId: userId,
                userProfileId: userProfileId,
                userTypeId: userTypeId
            })
            if (type == 'start') {
                setFridayStartTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.startHour = 12
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.startHour = value.substring(1, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftStartMeridian = 'pm'
                    obj.startMinute = value.substring(3, 5)
                } else {
                    obj.startHour = value.substring(0, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                }
            } else {
                setFridayEndTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftEndMeridian = 'pm'
                    obj.endMinute = value.substring(3, 5)
                } else {
                    obj.endHour = value.substring(0, 2)
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                }
            }
            if (arr.length > 0) {
                arr.map(a => {
                    if (a.day == 'friday') {
                        a.endHour = obj.endHour ? obj.endHour : 0
                        a.endMinute = obj.endMinute ? obj.endMinute : 0
                        a.startHour = obj.startHour ? obj.startHour : 0
                        a.startMinute = obj.startMinute ? obj.startMinute : 0
                        a.shiftEndMeridian = obj.shiftEndMeridian
                        a.shiftStartMeridian = obj.shiftStartMeridian
                    }
                })
            }
            setFridayTimeObject({ ...obj })
        } else if (day == 'saturday') {
            setIsSaturdayChecked(true)
            let arr = weeklyShiftPayload
            let obj = saturdayTimeObject
            arr.push({
                active: 1,
                day: day,
                endHour: saturdayTimeObject.endHour,
                endMinute: saturdayTimeObject.endMinute,
                shiftEndMeridian: saturdayTimeObject.shiftEndMeridian,
                shiftStartMeridian: saturdayTimeObject.shiftStartMeridian,
                startHour: saturdayTimeObject.startHour,
                startMinute: saturdayTimeObject.startMinute,
                userId: userId,
                userProfileId: userProfileId,
                userTypeId: userTypeId
            })
            if (type == 'start') {
                setSaturdayStartTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.startHour = 12
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.startHour = value.substring(1, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.startHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftStartMeridian = 'pm'
                    obj.startMinute = value.substring(3, 5)
                } else {
                    obj.startHour = value.substring(0, 2)
                    obj.shiftStartMeridian = 'am'
                    obj.startMinute = value.substring(3, 5)
                }
            } else {
                setSaturdayEndTime(value)
                if (value.substring(0, 1) == 0 && value.substring(1, 2) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 1) == 0) {
                    obj.endHour = 12
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                } else if (value.substring(0, 2) > 12) {
                    obj.endHour = hourArrayForPm[value.substring(0, 2) - 12 - 1]
                    obj.shiftEndMeridian = 'pm'
                    obj.endMinute = value.substring(3, 5)
                } else {
                    obj.endHour = value.substring(0, 2)
                    obj.shiftEndMeridian = 'am'
                    obj.endMinute = value.substring(3, 5)
                }
            }
            if (arr.length > 0) {
                arr.map(a => {
                    if (a.day == 'saturday') {
                        a.endHour = obj.endHour ? obj.endHour : 0
                        a.endMinute = obj.endMinute ? obj.endMinute : 0
                        a.startHour = obj.startHour ? obj.startHour : 0
                        a.startMinute = obj.startMinute ? obj.startMinute : 0
                        a.shiftEndMeridian = obj.shiftEndMeridian
                        a.shiftStartMeridian = obj.shiftStartMeridian
                    }
                })
            }
            setSaturdayTimeObject({ ...obj })
        }
    }

    const updateWeeklyShiftData = () => {
        if (weeklyShiftPayload.length == 0) {
            toast.error('Weekly Shift data cannot be empty')
            return
        }
        if (isSundayChecked) {
            if (sundayTimeObject.startHour == undefined) {
                toast.error('Hour field for start time cannot be empty for Sunday')
                return
            } else if (sundayTimeObject.startMinute == undefined) {
                toast.error('Minute field for start time cannot be empty for Sunday')
                return
            } else if (sundayTimeObject.endHour == undefined) {
                toast.error('Hour field for end time cannot be empty for Sunday')
                return
            } else if (sundayTimeObject.endMinute == undefined) {
                toast.error('Minute field for end time cannot be empty for Sunday')
                return
            } else if (sundayTimeObject.shiftStartMeridian == undefined) {
                toast.error('Meridian field for start time cannot be empty for Sunday')
                return
            } else if (sundayTimeObject.shiftEndMeridian == undefined) {
                toast.error('Meridian field for end time cannot be empty for Sunday')
                return
            }
        }
        if (isMondayChecked) {
            if (mondayTimeObject.startHour == undefined) {
                toast.error('Hour field for start time cannot be empty for Monday')
                return
            } else if (mondayTimeObject.startMinute == undefined) {
                toast.error('Minute field for start time cannot be empty for Monday')
                return
            } else if (mondayTimeObject.endHour == undefined) {
                toast.error('Hour field for end time cannot be empty for Monday')
                return
            } else if (mondayTimeObject.endMinute == undefined) {
                toast.error('Minute field for end time cannot be empty for Monday')
                return
            } else if (mondayTimeObject.shiftStartMeridian == undefined) {
                toast.error('Meridian field for start time cannot be empty for Monday')
                return
            } else if (mondayTimeObject.shiftEndMeridian == undefined) {
                toast.error('Meridian field for end time cannot be empty for Monday')
                return
            }
        }
        if (isTuesdayChecked) {
            if (tuesdayTimeObject.startHour == undefined) {
                toast.error('Hour field for start time cannot be empty for Tuesday')
                return
            } else if (tuesdayTimeObject.startMinute == undefined) {
                toast.error('Minute field for start time cannot be empty for Tuesday')
                return
            } else if (tuesdayTimeObject.endHour == undefined) {
                toast.error('Hour field for end time cannot be empty for Tuesday')
                return
            } else if (tuesdayTimeObject.endMinute == undefined) {
                toast.error('Minute field for end time cannot be empty for Tuesday')
                return
            } else if (tuesdayTimeObject.shiftStartMeridian == undefined) {
                toast.error('Meridian field for start time cannot be empty for Tuesday')
                return
            } else if (tuesdayTimeObject.shiftEndMeridian == undefined) {
                toast.error('Meridian field for end time cannot be empty for Tuesday')
                return
            }
        }
        if (isWednesdayChecked) {
            if (wednesdayTimeObject.startHour == undefined) {
                toast.error('Hour field for start time cannot be empty for Wednesday')
                return
            } else if (wednesdayTimeObject.startMinute == undefined) {
                toast.error('Minute field for start time cannot be empty for Wednesday')
                return
            } else if (wednesdayTimeObject.endHour == undefined) {
                toast.error('Hour field for end time cannot be empty for Wednesday')
                return
            } else if (wednesdayTimeObject.endMinute == undefined) {
                toast.error('Minute field for end time cannot be empty for Wednesday')
                return
            } else if (wednesdayTimeObject.shiftStartMeridian == undefined) {
                toast.error('Meridian field for start time cannot be empty for Wednesday')
                return
            } else if (wednesdayTimeObject.shiftEndMeridian == undefined) {
                toast.error('Meridian field for end time cannot be empty for Wednesday')
                return
            }
        }
        if (isThursdayChecked) {
            if (thursdayTimeObject.startHour == undefined) {
                toast.error('Hour field for start time cannot be empty for Thursday')
                return
            } else if (thursdayTimeObject.startMinute == undefined) {
                toast.error('Minute field for start time cannot be empty for Thursday')
                return
            } else if (thursdayTimeObject.endHour == undefined) {
                toast.error('Hour field for end time cannot be empty for Thursday')
                return
            } else if (thursdayTimeObject.endMinute == undefined) {
                toast.error('Minute field for end time cannot be empty for Thursday')
                return
            } else if (thursdayTimeObject.shiftStartMeridian == undefined) {
                toast.error('Meridian field for start time cannot be empty for Thursday')
                return
            } else if (thursdayTimeObject.shiftEndMeridian == undefined) {
                toast.error('Meridian field for end time cannot be empty for Thursday')
                return
            }
        }
        if (isFridayChecked) {
            if (fridayTimeObject.startHour == undefined) {
                toast.error('Hour field for start time cannot be empty for Friday')
                return
            } else if (fridayTimeObject.startMinute == undefined) {
                toast.error('Minute field for start time cannot be empty for Friday')
                return
            } else if (fridayTimeObject.endHour == undefined) {
                toast.error('Hour field for end time cannot be empty for Friday')
                return
            } else if (fridayTimeObject.endMinute == undefined) {
                toast.error('Minute field for end time cannot be empty for Friday')
                return
            } else if (fridayTimeObject.shiftStartMeridian == undefined) {
                toast.error('Meridian field for start time cannot be empty for Friday')
                return
            } else if (fridayTimeObject.shiftEndMeridian == undefined) {
                toast.error('Meridian field for end time cannot be empty for Friday')
                return
            }
        }
        if (isSaturdayChecked) {
            if (saturdayTimeObject.startHour == undefined) {
                toast.error('Hour field for start time cannot be empty for Saturday')
                return
            } else if (saturdayTimeObject.startMinute == undefined) {
                toast.error('Minute field for start time cannot be empty for Saturday')
                return
            } else if (saturdayTimeObject.endHour == undefined) {
                toast.error('Hour field for end time cannot be empty for Saturday')
                return
            } else if (saturdayTimeObject.endMinute == undefined) {
                toast.error('Minute field for end time cannot be empty for Saturday')
                return
            } else if (saturdayTimeObject.shiftStartMeridian == undefined) {
                toast.error('Meridian field for start time cannot be empty for Saturday')
                return
            } else if (saturdayTimeObject.shiftEndMeridian == undefined) {
                toast.error('Meridian field for end time cannot be empty for Saturday')
                return
            }
        }

        let params = {
            userProfileId: userProfileId,
            payload: weeklyShiftPayload
        }

        setLoadingUpdateWeeklyShiftData(true)
        setUpdateWeeklyShiftDataDisabled(true)

        if (initialWeeklyShiftPayload.length > 0) {
            props.updateWeeklyShiftDataForActiveDaysList(params, res => {
                if (res.status) {
                    setShiftModal(false)
                    setLoadingUpdateWeeklyShiftData(false)
                    swal({
                        title: "Weekly Shift data updated!",
                        text: "",
                        icon: "success"
                    }).then(onUpdate => {
                        document.getElementById('kt_content').setAttribute('style', '')
                        // setLoading(true)
                        // props.getTalents({
                        //     userIdHash: userIdHash
                        // }, res => {
                        //     if (res.status) {
                        //         if (res.data.length > 0) {
                        //             setTalentData(res.data)

                        //             if (res.data.length > 0) {
                        //                 let division = res.data.length / 2
                        //                 let colA_Array = []
                        //                 let colB_Array = []
                        //                 for (let i = 0; i < Math.ceil(division); i++) {
                        //                     colA_Array.push(res.data[i])
                        //                 }
                        //                 setTalentDataColA(colA_Array)
                        //                 for (let i = Math.ceil(division); i < res.data.length; i++) {
                        //                     colB_Array.push(res.data[i])
                        //                 }
                        //                 setTalentDataColB(colB_Array)
                        //                 setLoading(false)
                        //             }
                        //         } else {
                        //             setLoading(false)
                        //         }
                        //     }
                        // })
                    })
                }
            })
        } else {
            props.updateWeeklyShiftDataForEmptyActiveDaysList(params, res => {
                if (res.status) {
                    setShiftModal(false)
                    setLoadingUpdateWeeklyShiftData(false)
                    swal({
                        title: "Weekly Shift data updated!",
                        text: "",
                        icon: "success"
                    }).then(onUpdate => {

                        // setLoading(true)
                        // props.getTalents({
                        //     userIdHash: userIdHash
                        // }, res => {
                        //     if (res.status) {
                        //         if (res.data.length > 0) {
                        //             setTalentData(res.data)

                        //             if (res.data.length > 0) {
                        //                 let division = res.data.length / 2
                        //                 let colA_Array = []
                        //                 let colB_Array = []
                        //                 for (let i = 0; i < Math.ceil(division); i++) {
                        //                     colA_Array.push(res.data[i])
                        //                 }
                        //                 setTalentDataColA(colA_Array)
                        //                 for (let i = Math.ceil(division); i < res.data.length; i++) {
                        //                     colB_Array.push(res.data[i])
                        //                 }
                        //                 setTalentDataColB(colB_Array)
                        //                 setLoading(false)
                        //             }
                        //         } else {
                        //             setLoading(false)
                        //         }
                        //     }
                        // })
                    })
                }
            })
        }

    }

    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
                <div className="d-flex flex-column-fluid">
                    <div className="custom-container" style={{ height: 'auto' }}>
                        <div className="row pb-5">
                            <div className="col-md-6">
                                <div className="inner_txecy">
                                    <h4>My Talents </h4>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="talen_outre">
                                    <button className="btn" onClick={(e) => { openVerifyModal(e) }}>+ CREATE TALENT</button>
                                </div>
                            </div>
                        </div>

                        <div className="Profile_form">
                            {loading ?
                                (<>
                                    <div className="row" style={{ width: '100%', height: '30vh' }}>
                                        <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </>) :
                                (<>
                                    {talentData.length == 0 ? (<><div className='row h-100 w-100'><div className='col-12 text-center m-auto'>No Data Found</div></div></>) :
                                        (<>
                                            <div className="row">
                                                {talentData.map((value, index) => {
                                                    return (
                                                        <>
                                                            <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                                                                <div className="blush_outer">
                                                                    <div className="edit_outer">
                                                                        <a onClick={(e) => openEditModal(e, value)} style={{ cursor: 'pointer' }}><img src={talent_edit} alt="" /></a>
                                                                    </div>
                                                                    <div className="geoge_outer">
                                                                        <div className='custom789 circleName'>{value.userProfileDataTypes.UserProfileEntityTypeIsSelected ? value.userProfileDataTypes.UserProfileEntityTypeIsSelected.entityTypeNameAbbreviation : 'N/A'}</div>
                                                                        <img src={value.isVerified ? verified : unVerified} alt="verify" style={{ position: "absolute", top: '55px', left: '55px' }} />
                                                                        <div className="google_inner">
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <h5>{value.entityName}</h5>
                                                                                <img className='badgeClass mr-6' src={showBadge(value.userProfileDataTypes.selectedUserDataRating)} alt="badge" />
                                                                            </div>
                                                                            <p style={{fontSize:'14px', color:'grey'}}>{value.userProfileDataTypes.Profession}</p>
                                                                            <p className="custom_png"><img className="custom78" src={locationImg} alt="" />{value.city}, {value.country}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="button_list">
                                                                        <a className="about_btn" href="javascript:void(0)" onClick={(e) => { openTalentDetailsPage(e, value) }}>About</a>
                                                                        <a className="about_btn shift_outer5" href="javascript:void(0)" onClick={(e) => { openShiftModal(e, value) }}>Shift Type</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div >
                                        </>)}
                                </>)}
                        </div >
                        <Modal show={verifyModal} size="md"
                            centered
                            style={{ background: "rgb(95 95 95 / 77%)" }}>
                            <div className='text-center m-5'>
                                <Modal.Title><b>Verify Primary Information</b></Modal.Title>
                                <img src={talent_edit} alt="Edit" style={{ width: '5%', position: 'absolute', top: '15px', right: '22px', cursor: 'pointer' }} onClick={openEditVerifyModal} />
                            </div>
                            <Modal.Body>
                                <Form>
                                    {/* <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>First Name</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.firstName : ''} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Last Name</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.lastName : ''} disabled />
                                        </div>
                                    </Form.Group> */}
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Business Name</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.entityName : ''} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.address : ''} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address 1</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.address1 : ''} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>City</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.city : ''} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>State</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.state : ''} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Zip Code</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.zipCode : ''} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Country</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.country : ''} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Unit Id</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.unitId : ''} disabled />
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <div className='text-center m-5'>
                                <Button className="mx-3" variant="danger" onClick={(e) => { closeVerifyModal(e) }}>Close</Button>
                                <Button className="mx-3" variant="primary" onClick={(e) => { openCreateTalentModal(e) }}>Continue to Create Talent</Button>
                            </div>
                        </Modal>

                        <Modal show={editVerifyModal} size="md"
                            centered
                            style={{ background: "rgb(95 95 95 / 77%)" }}>
                            <div className='text-center m-5'>
                                <Modal.Title><b>Edit Primary Information</b></Modal.Title>
                                <i className='fa fa-arrow-left' style={{ color: 'green', fontSize: '20px', position: 'absolute', top: '20px', left: '22px', cursor: 'pointer' }} onClick={backToVerifyModal}></i>
                            </div>
                            <Modal.Body>
                                <Form>
                                    {/* <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>First Name</span></Form.Label>
                                        <div className="form-group">
                                            {isBusinessNameFieldDisabled ? 
                                            (<>
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.firstName : ''} disabled />
                                            </>) : 
                                            (<>
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.firstName : ''}  />
                                            </>)}
                                            
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Last Name</span></Form.Label>
                                        <div className="form-group">
                                            {isBusinessNameFieldDisabled ? 
                                            (<>
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.lastName : ''} disabled />
                                            </>) : 
                                            (<>
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.lastName : ''}  />
                                            </>)}
                                            
                                        </div>
                                    </Form.Group> */}
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Business Name</span></Form.Label>
                                        <div className="form-group">
                                            {isBusinessNameFieldDisabled ?
                                                (<>
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.entityName : ''} disabled />
                                                </>) :
                                                (<>
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.entityName : ''} />
                                                </>)}

                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Pick Address</span></Form.Label>
                                        <div className="form-group">
                                            <PlacesAutocomplete
                                                value={address}
                                                onChange={autoCompleteHandleChange}
                                                onSelect={autoCompleteHandleSelect}
                                                searchOptions={searchOptions}
                                            >
                                                {({
                                                    getInputProps,
                                                    suggestions,
                                                    getSuggestionItemProps,
                                                    loading,
                                                }) => (
                                                    <div>
                                                        <input
                                                            id="searchTextField"
                                                            {...getInputProps({
                                                                placeholder: "Search Places ...",
                                                                className:
                                                                    "location-search-input form-control inputStyle",
                                                            })}
                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map((suggestion) => {
                                                                const className = suggestion.active
                                                                    ? "suggestion-item--active"
                                                                    : "suggestion-item";
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? { backgroundColor: "#41b6e6", cursor: "pointer" }
                                                                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'address') }} value={address ? address : userInformation ? userInformation.address : ''} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address 1</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'address1') }} value={address1} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>City</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'city') }} value={city ? city : userInformation ? userInformation.city : ''} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>State</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'state') }} value={state ? state : userInformation ? userInformation.state : ''} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Zip Code</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'zip') }} value={zipCode ? zipCode : userInformation ? userInformation.zipCode : ''} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Country</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'country') }} value={country ? country : userInformation ? userInformation.country : ''} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Unit Id</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'unit') }} value={unitId ? unitId : userInformation ? userInformation.unitId : ''} />
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <div className='text-center m-5'>
                                <Button className="mx-3" variant="danger" onClick={(e) => { backToVerifyModal(e) }}>Close</Button>
                                <Button className="mx-3" variant="primary" onClick={updatePrimaryInformation}>
                                    {loadingUpdateUserInfo && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Update</Button>
                            </div>
                        </Modal>

                        <Modal show={createModal} size="md"
                            centered
                            style={{ background: "rgb(95 95 95 / 77%)" }}>
                            <div className='text-center m-5'>
                                <Modal.Title><b>Create a Talent Profile</b></Modal.Title>
                            </div>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Company Name</span></Form.Label>
                                        <div className="form-group">
                                            {isBusinessNameFieldDisabled ?
                                                (<>
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'business') }} value={businessName} disabled />
                                                </>) :
                                                (<>
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'business') }} required />
                                                </>)}

                                        </div>
                                    </Form.Group>

                                    {professionData.length > 0 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Profession</span></Form.Label>
                                                <div className="form-group">
                                                    <select className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'profession') }}>
                                                        <option defaultValue='' disabled selected>Select Profession</option>
                                                        {professionData.map((value, index) => {
                                                            return (
                                                                <option value={value.id}>{value.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}

                                    {skillFlag1 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Skill</span></Form.Label>
                                                <div className="form-group">
                                                    <select className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'skill1') }}>
                                                        <option defaultValue='' disabled selected>Select Skill</option>
                                                        {skillData1.map((value, index) => {
                                                            return (
                                                                <option value={value.id}>{value.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </Form.Group>
                                        </>) : (<></>)}

                                    {skillFlag2 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Skill</span></Form.Label>
                                                <div className="form-group">
                                                    <select className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'skill2') }}>
                                                        <option defaultValue='' disabled selected>Select Skill</option>
                                                        {skillData2.map((value, index) => {
                                                            return (
                                                                <option value={value.id}>{value.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </Form.Group>
                                        </>) : (<></>)}

                                    {skillFlag3 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Skill</span></Form.Label>
                                                <div className="form-group">
                                                    <select className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'skill3') }}>
                                                        <option defaultValue='' disabled selected>Select Skill</option>
                                                        {skillData3.map((value, index) => {
                                                            return (
                                                                <option value={value.id}>{value.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </Form.Group>
                                        </>) : (<></>)}

                                    {skillFlag4 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Skill</span></Form.Label>
                                                <div className="form-group">
                                                    <select className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'skill3') }}>
                                                        <option defaultValue='' disabled selected>Select Skill</option>
                                                        {skillData4.map((value, index) => {
                                                            return (
                                                                <option value={value.id}>{value.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </Form.Group>
                                        </>) : (<></>)}

                                    {skillFlag5 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Skill</span></Form.Label>
                                                <div className="form-group">
                                                    <select className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'skill3') }}>
                                                        <option defaultValue='' disabled selected>Select Skill</option>
                                                        {skillData5.map((value, index) => {
                                                            return (
                                                                <option value={value.id}>{value.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </Form.Group>
                                        </>) : (<></>)}

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Pick Address</span></Form.Label>
                                        <div className="form-group">
                                            <PlacesAutocomplete
                                                value={address}
                                                onChange={autoCompleteHandleChange}
                                                onSelect={autoCompleteHandleSelect}
                                                searchOptions={searchOptions}
                                            >
                                                {({
                                                    getInputProps,
                                                    suggestions,
                                                    getSuggestionItemProps,
                                                    loading,
                                                }) => (
                                                    <div>
                                                        <input
                                                            id="searchTextField"
                                                            {...getInputProps({
                                                                placeholder: "Search Places ...",
                                                                className:
                                                                    "location-search-input form-control inputStyle",
                                                            })}
                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map((suggestion) => {
                                                                const className = suggestion.active
                                                                    ? "suggestion-item--active"
                                                                    : "suggestion-item";
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? { backgroundColor: "#41b6e6", cursor: "pointer" }
                                                                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'address') }} value={address} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address 1</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'address1') }} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>City</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'city') }} value={city} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>State</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'state') }} value={state} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Zip Code</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'zip') }} value={zipCode} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Country</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'country') }} value={country} />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Unit Id</span></Form.Label>
                                        <div className="form-group">
                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'unit') }} />
                                        </div>
                                    </Form.Group>

                                    {employmentData.length > 0 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Employment Type</span></Form.Label>
                                                <div className="form-group">
                                                    <Multiselect
                                                        options={employmentData} // Options to display in the dropdown
                                                        //selectedValues={} // Preselected value to persist in dropdown
                                                        showCheckbox={true}
                                                        placeholder="Select Employment Type"
                                                        onSelect={onEmploymentTypeSelect} // Function will trigger on select event
                                                        onRemove={onEmploymentTypeSelect} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </div>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <>

                                        </>
                                    )}
                                    {scheduleData.length > 0 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Schedule Type</span></Form.Label>
                                                <div className="form-group">
                                                    <Multiselect
                                                        options={scheduleData} // Options to display in the dropdown
                                                        //selectedValues={} // Preselected value to persist in dropdown
                                                        showCheckbox={true}
                                                        placeholder="Select Schedule Type"
                                                        onSelect={onScheduleTypeSelect} // Function will trigger on select event
                                                        onRemove={onScheduleTypeSelect} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </div>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <>

                                        </>
                                    )}
                                    {facilityData.length > 0 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Facility Type</span></Form.Label>
                                                <div className="form-group">
                                                    <Multiselect
                                                        options={facilityData} // Options to display in the dropdown
                                                        //selectedValues={} // Preselected value to persist in dropdown
                                                        showCheckbox={true}
                                                        placeholder="Select Facility Type"
                                                        onSelect={onFacilityTypeSelect} // Function will trigger on select event
                                                        onRemove={onFacilityTypeSelect} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </div>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <>

                                        </>
                                    )}
                                    {payrollData.length > 0 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Payroll Type</span></Form.Label>
                                                <div className="form-group">
                                                    <Multiselect
                                                        options={payrollData} // Options to display in the dropdown
                                                        //selectedValues={} // Preselected value to persist in dropdown
                                                        showCheckbox={true}
                                                        placeholder="Select Payroll Type"
                                                        onSelect={onPayrollTypeSelect} // Function will trigger on select event
                                                        onRemove={onPayrollTypeSelect} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </div>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <>

                                        </>
                                    )}

                                    {shiftData.length > 0 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Shift Type</span></Form.Label>
                                                <div className="form-group">
                                                    <Multiselect
                                                        options={shiftData} // Options to display in the dropdown
                                                        //selectedValues={} // Preselected value to persist in dropdown
                                                        showCheckbox={true}
                                                        placeholder="Select Shift Type"
                                                        onSelect={onShiftTypeSelect} // Function will trigger on select event
                                                        onRemove={onShiftTypeSelect} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </div>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <>

                                        </>
                                    )}

                                    {paymentData.length > 0 ? (
                                        <>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Payment Type</span></Form.Label>
                                                <div className="form-group">
                                                    <Multiselect
                                                        options={paymentData} // Options to display in the dropdown
                                                        //selectedValues={} // Preselected value to persist in dropdown
                                                        showCheckbox={true}
                                                        placeholder="Select Payment Type"
                                                        onSelect={onPaymentTypeSelect} // Function will trigger on select event
                                                        onRemove={onPaymentTypeSelect} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </div>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <>

                                        </>
                                    )}

                                    <Form.Group controlId="formBasicVenue">
                                        <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>About</span></Form.Label>
                                        <div className="form-group">
                                            <textarea className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'about') }}>
                                            </textarea>
                                        </div>
                                    </Form.Group>

                                </Form>
                            </Modal.Body>
                            <div className='text-center m-5'>
                                <Button className="mx-3" variant="danger" onClick={(e) => { closeCreateTalentModal(e) }}>Close</Button>
                                <Button className="mx-3" variant="primary" onClick={createTalentProfile} disabled={createDisabled}>
                                    {loadingCreate && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Add</Button>
                            </div>
                        </Modal>

                        <Modal show={editModal} size="md"
                            centered
                            style={{ background: "rgb(95 95 95 / 77%)" }}>
                            <div className='text-center m-5'>
                                <Modal.Title><b>Edit Profile</b></Modal.Title>
                            </div>
                            <Modal.Body>
                                {loadingEditBody ?
                                    (<>
                                        <div className="row" style={{ width: '100%', height: '30vh' }}>
                                            <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '30vh' }}>
                                                    <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>) :
                                    (<>
                                        <Form>
                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Business Name</span></Form.Label>
                                                <div className="form-group">
                                                    {isBusinessNameFieldDisabled ?
                                                        (<>
                                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'business') }} value={businessName} disabled />
                                                        </>) :
                                                        (<>
                                                            <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'business') }} value={businessName} required />
                                                        </>)}

                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Pick Address</span></Form.Label>
                                                <div className="form-group">
                                                    <PlacesAutocomplete
                                                        value={address}
                                                        onChange={autoCompleteHandleChange}
                                                        onSelect={autoCompleteHandleSelect}
                                                        searchOptions={searchOptions}
                                                    >
                                                        {({
                                                            getInputProps,
                                                            suggestions,
                                                            getSuggestionItemProps,
                                                            loading,
                                                        }) => (
                                                            <div>
                                                                <input
                                                                    id="searchTextField"
                                                                    {...getInputProps({
                                                                        placeholder: "Search Places ...",
                                                                        className:
                                                                            "location-search-input form-control inputStyle",
                                                                    })}
                                                                />
                                                                <div className="autocomplete-dropdown-container">
                                                                    {loading && <div>Loading...</div>}
                                                                    {suggestions.map((suggestion) => {
                                                                        const className = suggestion.active
                                                                            ? "suggestion-item--active"
                                                                            : "suggestion-item";
                                                                        // inline style for demonstration purpose
                                                                        const style = suggestion.active
                                                                            ? { backgroundColor: "#41b6e6", cursor: "pointer" }
                                                                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                                        return (
                                                                            <div
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style,
                                                                                })}
                                                                            >
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address</span></Form.Label>
                                                <div className="form-group">
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'address') }} value={address} />
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address 1</span></Form.Label>
                                                <div className="form-group">
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'address1') }} value={address1} />
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>City</span></Form.Label>
                                                <div className="form-group">
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'city') }} value={city} />
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>State</span></Form.Label>
                                                <div className="form-group">
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'state') }} value={state} />
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Zip Code</span></Form.Label>
                                                <div className="form-group">
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'zip') }} value={zipCode} />
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Country</span></Form.Label>
                                                <div className="form-group">
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'country') }} value={country} />
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Unit Id</span></Form.Label>
                                                <div className="form-group">
                                                    <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'unit') }} value={unitId} />
                                                </div>
                                            </Form.Group>


                                            <>
                                                <Form.Group controlId="formBasicVenue">
                                                    <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Employment Type</span></Form.Label>
                                                    <div className="form-group">
                                                        <Multiselect
                                                            options={employmentData} // Options to display in the dropdown
                                                            selectedValues={employmentDataForEdit} // Preselected value to persist in dropdown
                                                            showCheckbox={true}
                                                            placeholder="Select Employment Type"
                                                            onSelect={onEmploymentTypeSelect} // Function will trigger on select event
                                                            onRemove={onEmploymentTypeSelect} // Function will trigger on remove event
                                                            displayValue="name" // Property name to display in the dropdown options
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </>


                                            <>
                                                <Form.Group controlId="formBasicVenue">
                                                    <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Schedule Type</span></Form.Label>
                                                    <div className="form-group">
                                                        <Multiselect
                                                            options={scheduleData} // Options to display in the dropdown
                                                            selectedValues={scheduleDataForEdit} // Preselected value to persist in dropdown
                                                            showCheckbox={true}
                                                            placeholder="Select Schedule Type"
                                                            onSelect={onScheduleTypeSelect} // Function will trigger on select event
                                                            onRemove={onScheduleTypeSelect} // Function will trigger on remove event
                                                            displayValue="name" // Property name to display in the dropdown options
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </>


                                            <>
                                                <Form.Group controlId="formBasicVenue">
                                                    <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Facility Type</span></Form.Label>
                                                    <div className="form-group">
                                                        <Multiselect
                                                            options={facilityData} // Options to display in the dropdown
                                                            selectedValues={facilityDataForEdit} // Preselected value to persist in dropdown
                                                            showCheckbox={true}
                                                            placeholder="Select Facility Type"
                                                            onSelect={onFacilityTypeSelect} // Function will trigger on select event
                                                            onRemove={onFacilityTypeSelect} // Function will trigger on remove event
                                                            displayValue="name" // Property name to display in the dropdown options
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </>


                                            <>
                                                <Form.Group controlId="formBasicVenue">
                                                    <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Payroll Type</span></Form.Label>
                                                    <div className="form-group">
                                                        <Multiselect
                                                            options={payrollData} // Options to display in the dropdown
                                                            selectedValues={payrollDataForEdit} // Preselected value to persist in dropdown
                                                            showCheckbox={true}
                                                            placeholder="Select Payroll Type"
                                                            onSelect={onPayrollTypeSelect} // Function will trigger on select event
                                                            onRemove={onPayrollTypeSelect} // Function will trigger on remove event
                                                            displayValue="name" // Property name to display in the dropdown options
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </>



                                            <>
                                                <Form.Group controlId="formBasicVenue">
                                                    <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Shift Type</span></Form.Label>
                                                    <div className="form-group">
                                                        <Multiselect
                                                            options={shiftData} // Options to display in the dropdown
                                                            selectedValues={shiftDataForEdit} // Preselected value to persist in dropdown
                                                            showCheckbox={true}
                                                            placeholder="Select Shift Type"
                                                            onSelect={onShiftTypeSelect} // Function will trigger on select event
                                                            onRemove={onShiftTypeSelect} // Function will trigger on remove event
                                                            displayValue="name" // Property name to display in the dropdown options
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </>



                                            <>
                                                <Form.Group controlId="formBasicVenue">
                                                    <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Payment Type</span></Form.Label>
                                                    <div className="form-group">
                                                        <Multiselect
                                                            options={paymentData} // Options to display in the dropdown
                                                            selectedValues={paymentDataForEdit} // Preselected value to persist in dropdown
                                                            showCheckbox={true}
                                                            placeholder="Select Payment Type"
                                                            onSelect={onPaymentTypeSelect} // Function will trigger on select event
                                                            onRemove={onPaymentTypeSelect} // Function will trigger on remove event
                                                            displayValue="name" // Property name to display in the dropdown options
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </>


                                            <Form.Group controlId="formBasicVenue">
                                                <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>About</span></Form.Label>
                                                <div className="form-group">
                                                    <textarea className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'about') }} value={about}>
                                                    </textarea>
                                                </div>
                                            </Form.Group>

                                        </Form>
                                    </>)}

                            </Modal.Body>
                            <div className='text-center m-5'>
                                <Button className="mx-3" variant="danger" onClick={(e) => { closeEditModal(e) }}>Close</Button>
                                <Button className="mx-3" variant="primary" onClick={updateTalentProfile} disabled={updateDisabled}>
                                    {loadingUpdate && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Update</Button>
                            </div>
                        </Modal>

                        <Modal show={shiftModal} size="md"
                            centered
                            style={{ background: "rgb(95 95 95 / 77%)" }}>
                            <div className='text-center m-5'>
                                <Modal.Title><b>Weekly Shift</b></Modal.Title>
                            </div>
                            <Modal.Body>
                                {loadingShiftBody ?
                                    (<>
                                        <div className="row" style={{ width: '100%', height: '30vh' }}>
                                            <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '30vh' }}>
                                                    <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>) :
                                    (<>
                                        <div className="row mt-3 mb-3">
                                            <div className="col-12">
                                                <div className="talent_details_shift">
                                                    <a className={
                                                        filterShow
                                                            ? "panel-heading heading_links_ accordion_title_info_ d-flex justify-content-between"
                                                            : "panel-heading heading_links_ accordion_title_info_ collapsed d-flex justify-content-between"
                                                    }
                                                        onClick={() => setFilterShow(!filterShow)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <span style={{ fontWeight: '600', color: '#3abd00' }}>Talent Profile Id : <span style={{ color: 'black' }}>{userProfileId}</span></span>
                                                        <img src={downArrow} />
                                                    </a>
                                                    <div
                                                        className={
                                                            filterShow ? "collapse show" : "collapse"
                                                        }
                                                        id="acceptance"
                                                    >
                                                        <div
                                                            className="card border-0 pt-2"
                                                            style={{
                                                                "max-height": "200px",
                                                                overflow: "auto",
                                                            }}
                                                        >
                                                            <div className='card-body' style={{ paddingTop: '0px' }}>
                                                                <table className='table' style={{ width: '100%' }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none' }}>Business Name</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{businessName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1'>Location</td>
                                                                            <td className='table-shift-td-2'>{address}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1'>Employment Type</td>
                                                                            <td className='table-shift-td-2'>{employmentDataForShift.length > 0 ? employmentDataForShift.join(', ') : '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1'>Facility Type</td>
                                                                            <td className='table-shift-td-2'>{facilityDataForShift.length > 0 ? facilityDataForShift.join(', ') : '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1'>Payment Type</td>
                                                                            <td className='table-shift-td-2'>{paymentDataForShift.length > 0 ? paymentDataForShift.join(', ') : '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1'>Payroll Type</td>
                                                                            <td className='table-shift-td-2'>{payrollDataForShift.length > 0 ? payrollDataForShift.join(', ') : '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1'>Schedule Type</td>
                                                                            <td className='table-shift-td-2'>{scheduleDataForShift.length > 0 ? scheduleDataForShift.join(', ') : '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1'>Shift Type</td>
                                                                            <td className='table-shift-td-2'>{shiftDataForShift.length > 0 ? shiftDataForShift.join(', ') : '-'}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Form>
                                            <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                                                <div className='row my-3'>
                                                    <div className='col-6'>
                                                        <h5 style={{ color: '#006838' }}><b>Sunday</b></h5>
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <Switch onChange={() => { toggleSwitch('sunday') }} checked={isSundayChecked} />
                                                    </div>
                                                </div>
                                                <div className='row my-3'>
                                                    <div className='col-6'>
                                                        <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'sunday', value)} value={sundayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />

                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'sunday', value)} value={sundayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <hr className='my-5'></hr>
                                            <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                                                <div className='row mb-3'>
                                                    <div className='col-6'>
                                                        <h5 style={{ color: '#006838' }}><b>Monday</b></h5>
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <Switch onChange={() => { toggleSwitch('monday') }} checked={isMondayChecked} />
                                                    </div>
                                                </div>
                                                <div className='row my-3'>
                                                    <div className='col-6'>
                                                        <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'monday', value)} value={mondayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'monday', value)} value={mondayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <hr className='my-5'></hr>
                                            <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                                                <div className='row mb-3'>
                                                    <div className='col-6'>
                                                        <h5 style={{ color: '#006838' }}><b>Tuesday</b></h5>
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <Switch onChange={() => { toggleSwitch('tuesday') }} checked={isTuesdayChecked} />
                                                    </div>
                                                </div>
                                                <div className='row my-3'>
                                                    <div className='col-6'>
                                                        <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'tuesday', value)} value={tuesdayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'tuesday', value)} value={tuesdayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <hr className='my-5'></hr>
                                            <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                                                <div className='row mb-3'>
                                                    <div className='col-6'>
                                                        <h5 style={{ color: '#006838' }}><b>Wednesday</b></h5>
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <Switch onChange={() => { toggleSwitch('wednesday') }} checked={isWednesdayChecked} />
                                                    </div>
                                                </div>
                                                <div className='row my-3'>
                                                    <div className='col-6'>
                                                        <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'wednesday', value)} value={wednesdayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'wednesday', value)} value={wednesdayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <hr className='my-5'></hr>
                                            <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                                                <div className='row mb-3'>
                                                    <div className='col-6'>
                                                        <h5 style={{ color: '#006838' }}><b>Thursday</b></h5>
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <Switch onChange={() => { toggleSwitch('thursday') }} checked={isThursdayChecked} />
                                                    </div>
                                                </div>
                                                <div className='row my-3'>
                                                    <div className='col-6'>
                                                        <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'thursday', value)} value={thursdayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'thursday', value)} value={thursdayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <hr className='my-5'></hr>
                                            <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                                                <div className='row mb-3'>
                                                    <div className='col-6'>
                                                        <h5 style={{ color: '#006838' }}><b>Friday</b></h5>
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <Switch onChange={() => { toggleSwitch('friday') }} checked={isFridayChecked} />
                                                    </div>
                                                </div>
                                                <div className='row my-3'>
                                                    <div className='col-6'>
                                                        <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'friday', value)} value={fridayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'friday', value)} value={fridayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <hr className='my-5'></hr>
                                            <Form.Group controlId="formBasicVenue" style={{ marginTop: '30px', marginBottom: '30px' }}>
                                                <div className='row mb-3'>
                                                    <div className='col-6'>
                                                        <h5 style={{ color: '#006838' }}><b>Saturday</b></h5>
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <Switch onChange={() => { toggleSwitch('saturday') }} checked={isSaturdayChecked} />
                                                    </div>
                                                </div>
                                                <div className='row my-3'>
                                                    <div className='col-6'>
                                                        <label className='mr-3 start-end-text'>Start Time :</label><TimePicker onChange={(value) => changeTime('start', 'saturday', value)} value={saturdayStartTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                    <div className='col-6' style={{ textAlign: 'end' }}>
                                                        <label className='mr-3 start-end-text'>End Time :</label><TimePicker onChange={(value) => changeTime('end', 'saturday', value)} value={saturdayEndTime} disableClock={true} minutePlaceholder="mm" hourPlaceholder='hh' />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Form>

                                    </>)}

                            </Modal.Body>
                            <div className='text-center m-5'>
                                <Button className="mx-3" variant="danger" onClick={(e) => { closeShiftModal(e) }}>Close</Button>
                                <Button className="mx-3" variant="primary" onClick={updateWeeklyShiftData} disabled={updateWeeklyShiftDataDisabled}>
                                    {loadingUpdateWeeklyShiftData && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    Update</Button>
                            </div>
                        </Modal>
                    </div >

                </div >

            </div >
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getTalents: bindActionCreators(getTalents, dispatch),
    getTalentProfile: bindActionCreators(getTalentProfile, dispatch),
    updateTalent: bindActionCreators(updateTalent, dispatch),
    getProfession: bindActionCreators(getProfession, dispatch),
    getSkill: bindActionCreators(getSkill, dispatch),
    getEmployment: bindActionCreators(getEmployment, dispatch),
    getSchedule: bindActionCreators(getSchedule, dispatch),
    getFacility: bindActionCreators(getFacility, dispatch),
    getPayroll: bindActionCreators(getPayroll, dispatch),
    getShift: bindActionCreators(getShift, dispatch),
    getPayment: bindActionCreators(getPayment, dispatch),
    createTalent: bindActionCreators(createTalent, dispatch),
    getDaysList: bindActionCreators(getDaysList, dispatch),
    getActiveDaysList: bindActionCreators(getActiveDaysList, dispatch),
    updateWeeklyShiftDataForEmptyActiveDaysList: bindActionCreators(updateWeeklyShiftDataForEmptyActiveDaysList, dispatch),
    updateWeeklyShiftDataForActiveDaysList: bindActionCreators(updateWeeklyShiftDataForActiveDaysList, dispatch),
    getUserInformation: bindActionCreators(getUserInformation, dispatch),
    addUserInformation: bindActionCreators(addUserInformation, dispatch),
    updateUserInformation: bindActionCreators(updateUserInformation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Talent);