import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Quote from "../../public/img/quote.svg";
import One from "../../public/img/1.png";
import Two from "../../public/img/2.png";
import Three from "../../public/img/3.png";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";

const WhyEasy = (props) => {
  const history = useHistory();

  return (
    <>
      <section className="services_section p-60  aos-item" data-aos="fade-up">
        <div className="container">
          <div className="home_main_title button_padding ">
            <h2>Why Easy</h2>
            <p>Work Your Way </p>
          </div>

          <div className="row job_post_now_inner">
            <div className="col-md-4">
              <div className="client_say">
                <img src={Quote} alt="" />
                "Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et."
              </div>
              <div className="client_detail_inner">
                <div className="client_img">
                  <img src={One} alt="" />{" "}
                </div>
                <div className="client_detail">
                  <h4>Darren Adams</h4>
                  <p>Student</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="client_say">
                <img src={Quote} alt="" />
                "Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et."
              </div>
              <div className="client_detail_inner">
                <div className="client_img">
                  <img src={Two} alt="" />{" "}
                </div>
                <div className="client_detail">
                  <h4>Darren Adams</h4>
                  <p>Student</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="client_say">
                <img src={Quote} alt="" />
                "Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et."
              </div>
              <div className="client_detail_inner">
                <div className="client_img">
                  <img src={Three} alt="" />{" "}
                </div>
                <div className="client_detail">
                  <h4>Darren Adams</h4>
                  <p>Student</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WhyEasy);
