import React from 'react';
import { Link ,useParams} from "react-router-dom";
import company from "../../public/images/CompanyLogo1.svg";
import jobloaction from "../../public/images/joblocation.svg";
import Upload from "../../public/images/CloudArrowUp.svg";


const Details = () => {



    return (
        <>
            <section className="interested_Nav">
                <div className="interested_main">
                    <div className="container">
                        <div className="interested_nav">
                            <div className="interested_icon">
                                <img src={company} alt="logo" className="img-fluid" />
                            </div>
                            <div className="interested_content">
                                <h5 className="job_heading">HR- IT Recruitment | Work from Home | 1 to 6 Years | Female only<span className="job_Subheading">(Orcapod Consulting Services Private Limited)</span></h5>
                                <div className="job_location">
                                    <a href="javascript:void();">
                                        <img src={jobloaction} alt="logo" />
                                        <span>US, California</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="personal_info">
                <div className="container">
                    <div className="go_back">
                        <a href="/apply-job">
                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                            <span>go back</span>
                        </a>
                    </div>
                    <div className="personal_details">
                        <form className="form-group form_details" role="form">
                            <div className="message_boxmain">
                            <h4>Message to Hiring Manager</h4>
                            <p className="message_subheading">Tell us a bit about why you're applying to work here. What excited you about this role?</p>
                            <textarea className="message_box" rows="6" cols="10" placeholder="Type here"></textarea>
                            </div>
                            <div className="row">
                                <div className='form-group mb-4 col-lg-6'>
                                    <label>We want to make sure your experience is both positive and inclusive! Please feel free to share how to best pronounce.</label>
                                    <input type="text" className="form-control"  required />
                                </div>
                                <div className='form-group mb-4 col-lg-6'>
                                    <label>Pronouns (to be shared with the recruiter, hiring manager,and/or interviewers)</label>
                                    <input type="text" className="form-control"  required />
                                </div>
                                <div className='form-group mb-4 col-lg-6'>
                                    <label>Option to write in pronouns (if needed)</label>
                                    <input type="text" className="form-control"  required />
                                </div>
                            </div>
                            <div className='option_menu'>
                                <p>Are you authorized to work in the job's location?</p>
                                <label className='checkbox_'>Yes
                                    <input type="radio" className='check_main d-none' id="yes" name="option" value="Yes" checked/>
                                    <span className="checkmarks"></span>
                                </label>
                                <label className='checkbox_no'>No
                                    <input type="radio" className='check_main d-none' id="no" name="option" value="No" />
                                    <span className="checkmarks_no"></span>
                                </label>
                            </div>
                            <div className='option_menu'>
                                <p>Will you now or in the future require sponsorship for work authorization? (Responding to this request is voluntary, and this information will not be used in assessing your qualifications for any position.)</p>
                                <label className='checkbox_'>Yes
                                    <input type="radio" className='check_main d-none' id="yesone" name="optionOne" value="Yes" checked/>
                                    <span className="checkmarks"></span>
                                </label>
                                <label className='checkbox_no'>No
                                    <input type="radio" className='check_main d-none' id="noone" name="optionOne" value="No" />
                                    <span className="checkmarks_no"></span>
                                </label>
                            </div>
                            <div className='option_menu'>
                                <p>Have you ever been employed by Block, Inc. or any of its subsidiaries or affiliates (whether in the U.S. or internationally)?</p>
                                <label className='checkbox_'>Yes
                                    <input type="radio" className='check_main d-none' id="yestwo" name="optionTwo" value="Yes" checked/>
                                    <span className="checkmarks"></span>
                                </label>
                                <label className='checkbox_no'>No
                                    <input type="radio" className='check_main d-none' id="notwo" name="optionTwo" value="No" />
                                    <span className="checkmarks_no"></span>
                                </label>
                            </div>
                            <div className='form-group mb-4'>
                                    <label>We want to make sure your experience is both positive and inclusive! Please feel free to share with our teams how to best pronounce.</label>
                                    <input type="text" className="form-control"  required />
                            </div>
                            <div className='option_menu'>
                                <p>Have you ever been employed by Block, Inc. or any of its subsidiaries or affiliates (whether in the U.S. or internationally)?</p>
                                <label className='checkbox_'>Yes
                                    <input type="radio" className='check_main d-none' id="yesthree" name="optionthree" value="Yes" checked/>
                                    <span className="checkmarks"></span>
                                </label>
                                <label className='checkbox_no'>No
                                    <input type="radio" className='check_main d-none' id="nothree" name="optionthree" value="No" />
                                    <span className="checkmarks_no"></span>
                                </label>
                            </div>
                            <div className='form-group mb-4'>
                                    <label>We want to make sure your experience is both positive and inclusive! Please feel free to share with our teams how to best pronounce.</label>
                                    <input type="text" className="form-control"  required />
                            </div>
                            <div className='option_menu'>
                                <p>Have you ever been employed by Block, Inc. or any of its subsidiaries or affiliates (whether in the U.S. or internationally)?</p>
                                <label className='checkbox_'>Yes
                                    <input type="radio" className='check_main d-none' id="yesfour" name="optionfour" value="Yes" checked/>
                                    <span className="checkmarks"></span>
                                </label>
                                <label className='checkbox_no'>No
                                    <input type="radio" className='check_main d-none' id="nofour" name="optionfour" value="No" />
                                    <span className="checkmarks_no"></span>
                                </label>
                            </div>
                            <div className='details_content'>
                                <p>I certify that all of the information I have provided is correct and complete and realize that falsification or misrepresentation, including omission, on this or any other personnel record, or in the hiring process, may be grounds for refusal of employment, or if hired, may lead to disciplinary action, up to and including termination.</p>
                                <p>By signing this Electronic Signature Acknowledgment Online Form, I agree that my electronic signature is the legally binding equivalent to my handwritten signature. Whenever I execute an electronic signature, it has the same validity and meaning as my handwritten signature. I will not, at any time in the future, repudiate the meaning of my electronic signature or claim that my electronic signature is not legally binding.</p>
                            </div>
                            <div className="message_boxmain">
                                <h4>Please sign by typing your Full Legal First, Middle Initial, and Last Names</h4>
                                <p className="message_subheading">Tell us a bit about why you're applying to work here. What excited you about this role?</p>
                                <textarea className="message_box" rows="6" cols="10" placeholder="Type here"></textarea>
                            </div>
                            <div className='details_content'>
                                <p>By clicking “accept,” you agree that any disputes related to your application, including those related to Block’s selection process, hiring decision, and your background check, shall be submitted to binding arbitration with the Judicial Arbitration and Mediation Services, Inc. (JAMS) on an individual basis only in San Francisco, before a single arbitrator according to the applicable JAMS Rules then in effect, with Block bearing all costs unique to arbitration. To the extent permitted by law, you and Block each waive the right to initiate or participate in any class, collective, or representative action against the other in any forum (including court and arbitration). If any provision of this agreement is deemed unenforceable, the remainder shall remain in effect, except that, notwithstanding the above, under no circumstances shall class or collective, or representative proceedings be permitted in arbitration.</p>
                            </div>
                            <div className='accept'>
                                <label className='check_main'>
                                    <input type="checkbox"  id='check' className='d-none'/>
                                    <span className='checkboxmain_'></span>
                                    Accept
                                </label>
                            </div>
                            <div className="upload_box">
                                <h4>Additional attachments</h4>
                                <input type="file" id="file" className="d-none" />
                                <label for="file">
                                    <div className="file-button">
                                        <span className="uploadIcon"><img src={Upload} /><small>Browse</small> or just drop it here</span>
                                    </div>
                                </label>
                            </div>
                            <div className="buttonBox">
                            <div className="btnBack">
                                <button className="sliderFormBtn">
                                    <Link to="/interested">Go Back</Link>
                                </button>
                            </div>
                            <div className="btnNext">
                                <button className="sliderFormBtn">
                                    <Link to="/details">Submit</Link>
                                </button>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Details;