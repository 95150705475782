import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { getUserProfileList } from "../../actions/common";
import { getFormFieldsPrespective } from "../../actions/employee/skill";
import { getFormFieldsForPostJob, postJob, getSkillBio, getFormFieldsEntity, getDailyCalendarDetails, postJobDateAndTime, postJobSettings, updateJobSettings } from "../../actions/employer/post";
import location from '../../public/images/location.png';
import downArrow from "../../public/images/downArrow.svg";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import inputFieldGenerator from "./fieldGenerator";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from 'sweetalert';
import DatePicker from 'react-date-picker';
import moment from 'moment-timezone';
import TimePicker from 'react-time-picker';
import Calendar from 'react-calendar';
import { showBadge } from '../../../utilities/helpers';


const Post = (props) => {
  const history = useHistory();

  const [userIdHash, setUserIdHash] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(true);
  const [closeDisabled, setCloseDisabled] = useState(false);

  const [gettingField, setGettingField] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [fieldMetaData, setFieldMetaData] = useState(null);
  const [gettingFieldJobSettings, setGettingFieldJobSettings] = useState(false);
  const [fieldValuesJobSettings, setFieldValuesJobSettings] = useState({});
  const [fieldMetaDataJobSettings, setFieldMetaDataJobSettings] = useState(null);

  const [talentData, setTalentData] = useState([]);

  const [businessName, setBusinessName] = useState()
  const [address, setAddress] = useState()
  const [userProfileId, setUserProfileId] = useState("");
  const [entityTypeId, setEntityTypeId] = useState("");

  const [perspectiveId, setPerspectiveId] = useState("");

  const [entityId, setEntityId] = useState();

  const [employmentDataForShift, setEmploymentDataForShift] = useState([]);
  const [scheduleDataForShift, setScheduleDataForShift] = useState([]);
  const [facilityDataForShift, setFacilityDataForShift] = useState([]);
  const [payrollDataForShift, setPayrollDataForShift] = useState([]);
  const [shiftDataForShift, setShiftDataForShift] = useState([]);
  const [paymentDataForShift, setPaymentDataForShift] = useState([]);

  const [employmentTypeData, setEmploymentTypeData] = useState([]);

  const [calendarFlag, setCalendarFlag] = useState(false);

  const [contractStartDate, setContractStartDate] = useState()
  const [showContractStartDate, setShowContractStartDate] = useState()
  const [contractEndDate, setContractEndDate] = useState()
  const [showContractEndDate, setShowContractEndDate] = useState()
  const [contractStartTime, setContractStartTime] = useState()
  const [contractEndTime, setContractEndTime] = useState()
  const [finalContractStartDate, setFinalContractStartDate] = useState()
  const [finalContractEndDate, setFinalContractEndDate] = useState()
  const [contractProviderCount, setContractProviderCount] = useState()
  const [contractStartTimeFlag, setContractStartTimeFlag] = useState(false);
  const [contractEndTimeFlag, setContractEndTimeFlag] = useState(false);

  const [fullTimeStartDate, setFullTimeStartDate] = useState()
  const [showFullTimeStartDate, setShowFullTimeStartDate] = useState()
  const [fullTimeEndDate, setFullTimeEndDate] = useState()
  const [showfullTimeEndDate, setShowFullTimeEndDate] = useState()
  const [fullTimeStartTime, setFullTimeStartTime] = useState()
  const [fullTimeEndTime, setFullTimeEndTime] = useState()
  const [finalfullTimeStartDate, setFinalFullTimeStartDate] = useState()
  const [finalfullTimeEndDate, setFinalFullTimeEndDate] = useState()
  const [fullTimeProviderCount, setFullTimeProviderCount] = useState()
  const [fullTimeStartTimeFlag, setFullTimeStartTimeFlag] = useState(false);
  const [fullTimeEndTimeFlag, setFullTimeEndTimeFlag] = useState(false);

  const [partTimeStartDate, setPartTimeStartDate] = useState()
  const [finalPartTimeStartDate, setFinalPartTimeStartDate] = useState()
  const [partTimeInputDateFlag, setPartTimeInputDateFlag] = useState(false)

  const [partTimeStartTime1, setPartTimeStartTime1] = useState()
  const [finalPartTimeStartDate1, setFinalPartTimeStartDate1] = useState()
  const [partTimeEndTime1, setPartTimeEndTime1] = useState()
  const [finalPartTimeEndDate1, setFinalPartTimeEndDate1] = useState()
  const [partTimeStartTimeFlag1, setPartTimeStartTimeFlag1] = useState(false)
  const [partTimeStartTime1Modal, setPartTimeStartTime1Modal] = useState(false)
  const [partTimeEndTimeFlag1, setPartTimeEndTimeFlag1] = useState(false)
  const [partTimeEndTime1Modal, setPartTimeEndTime1Modal] = useState(false)
  const [partTimeProviderCount1, setPartTimeProviderCount1] = useState()

  const [partTimeStartTime2, setPartTimeStartTime2] = useState()
  const [finalPartTimeStartDate2, setFinalPartTimeStartDate2] = useState()
  const [partTimeEndTime2, setPartTimeEndTime2] = useState()
  const [finalPartTimeEndDate2, setFinalPartTimeEndDate2] = useState()
  const [partTimeStartTimeFlag2, setPartTimeStartTimeFlag2] = useState(false)
  const [partTimeStartTime2Modal, setPartTimeStartTime2Modal] = useState(false)
  const [partTimeEndTimeFlag2, setPartTimeEndTimeFlag2] = useState(false)
  const [partTimeEndTime2Modal, setPartTimeEndTime2Modal] = useState(false)
  const [partTimeProviderCount2, setPartTimeProviderCount2] = useState()

  const [partTimeStartTime3, setPartTimeStartTime3] = useState()
  const [finalPartTimeStartDate3, setFinalPartTimeStartDate3] = useState()
  const [partTimeEndTime3, setPartTimeEndTime3] = useState()
  const [finalPartTimeEndDate3, setFinalPartTimeEndDate3] = useState()
  const [partTimeStartTimeFlag3, setPartTimeStartTimeFlag3] = useState(false)
  const [partTimeStartTime3Modal, setPartTimeStartTime3Modal] = useState(false)
  const [partTimeEndTimeFlag3, setPartTimeEndTimeFlag3] = useState(false)
  const [partTimeEndTime3Modal, setPartTimeEndTime3Modal] = useState(false)
  const [partTimeProviderCount3, setPartTimeProviderCount3] = useState()

  const [partTimeStartTime4, setPartTimeStartTime4] = useState()
  const [finalPartTimeStartDate4, setFinalPartTimeStartDate4] = useState()
  const [partTimeEndTime4, setPartTimeEndTime4] = useState()
  const [finalPartTimeEndDate4, setFinalPartTimeEndDate4] = useState()
  const [partTimeStartTimeFlag4, setPartTimeStartTimeFlag4] = useState(false)
  const [partTimeStartTime4Modal, setPartTimeStartTime4Modal] = useState(false)
  const [partTimeEndTimeFlag4, setPartTimeEndTimeFlag4] = useState(false)
  const [partTimeEndTime4Modal, setPartTimeEndTime4Modal] = useState(false)
  const [partTimeProviderCount4, setPartTimeProviderCount4] = useState()

  const [partTimeStartTime5, setPartTimeStartTime5] = useState()
  const [finalPartTimeStartDate5, setFinalPartTimeStartDate5] = useState()
  const [partTimeEndTime5, setPartTimeEndTime5] = useState()
  const [finalPartTimeEndDate5, setFinalPartTimeEndDate5] = useState()
  const [partTimeStartTimeFlag5, setPartTimeStartTimeFlag5] = useState(false)
  const [partTimeStartTime5Modal, setPartTimeStartTime5Modal] = useState(false)
  const [partTimeEndTimeFlag5, setPartTimeEndTimeFlag5] = useState(false)
  const [partTimeEndTime5Modal, setPartTimeEndTime5Modal] = useState(false)
  const [partTimeProviderCount5, setPartTimeProviderCount5] = useState()

  const [shift2Flag, setShift2Flag] = useState(false)
  const [shift3Flag, setShift3Flag] = useState(false)
  const [shift4Flag, setShift4Flag] = useState(false)
  const [shift5Flag, setShift5Flag] = useState(false)

  const [shiftCount, setShiftCount] = useState(0)

  const [payloadArray, setPayloadArray] = useState([])


  const [fullTimeData, setFullTimeData] = useState({});
  const [fullTimeDataFlag, setFullTimeDataFlag] = useState(false);
  const [partTimeData, setPartTimeData] = useState({});
  const [partTimeDataFlag, setPartTimeDataFlag] = useState(false);
  const [contractData, setContractData] = useState({});
  const [contractDataFlag, setContractDataFlag] = useState(false);

  const [filterShow, setFilterShow] = useState(false);

  const [errorFlag, setErrorFlag] = useState(false);
  const [contractErrorFlag, setContractErrorFlag] = useState(false);
  const [fullTimeErrorFlag, setFullTimeErrorFlag] = useState(false);

  const [jobSettingsPostFlag, setJobSettingsPostFlag] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [jobSettingsModal, setJobSettingsModal] = useState(false);

  useEffect(() => {
    let params = {}
    if (props.auth.isLoggedIn) {
      if (props.auth.userProfile) {
        setUserIdHash(props.auth.userProfile.userIdHash)
        params = {
          userIdHash: props.auth.userProfile.userIdHash
        }
      }
    }

    props.getUserProfileList(params, res => {
      if (res.status) {
        if (res.data.length > 0) {
          setTalentData(res.data)
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
    })
  }, [])

  const getDailyCalendarData = (params) => {
    props.getDailyCalendarDetails(params, res => {
      if (res.status) {
        console.log('RES', res.data)
        if (res.data) {
          if (res.data['1']) {
            setFullTimeData(res.data['1'])
            setFullTimeDataFlag(true)
          }
          if (res.data['2']) {
            setPartTimeData(res.data['2'])
            setPartTimeDataFlag(true)
          }
          if (res.data['3']) {
            setContractData(res.data['3'])
            setContractDataFlag(true)
          }
        }
      }
    })
  }

  const getFormatedDate = (date, format = 'YYYY-MM-DD, h:mm:ss A', timeZone = '') => {
    if (date && moment(date).isValid()) {
      if (timeZone) {
        return moment(date).format(format).tz(timeZone);
      }
      return moment(date).format(format)
    }
    return false;
  }

  let contractObject = {}
  let fullTimeObject = {}
  let partTimeObject1 = {}
  let partTimeObject2 = {}
  let partTimeObject3 = {}
  let partTimeObject4 = {}
  let partTimeObject5 = {}

  //CONTRACT SHIFT START DATE AND TIME
  const changeStartDateForContract = (value) => {
    setContractStartDate(value)
    setContractStartTimeFlag(true)
    setContractStartTime()
  }
  const changeStartTimeForContract = (value) => {
    setContractStartTime(value)
  }
  const selectStartTimeForContract = () => {
    if (contractStartTime == null) {
      toast.error('Please select Contract Start Time')
      return
    }
    setFinalContractStartDate(contractStartDate + ', ' + contractStartTime)
    setShowContractStartDate(contractStartDate.toString().substring(4, 15) + ', ' + contractStartTime)
    setContractStartTimeFlag(false)
    setErrorFlag(false)
  }

  //CONTRACT SHIFT END DATE AND TIME
  const changeEndDateForContract = (value) => {
    setContractEndDate(value)
    setContractEndTimeFlag(true)
    setContractEndTime()
  }
  const changeEndTimeForContract = (value) => {
    setContractEndTime(value)
  }
  const selectEndTimeForContract = () => {
    if (contractEndTime == null) {
      toast.error('Please select Contract End Time')
      return
    }
    setFinalContractEndDate(contractEndDate + ', ' + contractEndTime)
    setShowContractEndDate(contractEndDate.toString().substring(4, 15) + ', ' + contractEndTime)
    setContractEndTimeFlag(false)
    setErrorFlag(false)
  }

  //CONTRACT PROVIDER COUNT
  const changeContractProviderCount = (e) => {
    e.preventDefault()
    setContractProviderCount(parseInt(e.target.value))
    setErrorFlag(false)
  }

  //FULL TIME SHIFT START DATE AND TIME
  const changeStartDateForFullTime = (value) => {
    setFullTimeStartDate(value)
    setFullTimeStartTimeFlag(true)
    setFullTimeStartTime()
  }
  const changeStartTimeForFullTime = (value) => {
    setFullTimeStartTime(value)
  }
  const selectStartTimeForFullTime = () => {
    if (fullTimeStartTime == null) {
      toast.error('Please select Full Time Start Time')
      return
    }
    setFinalFullTimeStartDate(fullTimeStartDate + ', ' + fullTimeStartTime)
    setShowFullTimeStartDate(fullTimeStartDate.toString().substring(4, 15) + ', ' + fullTimeStartTime)
    setFullTimeStartTimeFlag(false)
    setErrorFlag(false)
  }

  //FULL TIME PROVIDER COUNT
  const changeFullTimeProviderCount = (e) => {
    e.preventDefault()
    setFullTimeProviderCount(parseInt(e.target.value))
    setErrorFlag(false)
  }

  //PART TIME START TIME SHIFT 1
  const changeDateForPartTime = (value) => {
    console.log('PART TIME', value)
    setFinalPartTimeStartDate(value)
    setPartTimeStartDate(value.toString().substring(4, 15))
    setPartTimeInputDateFlag(true)
  }
  const selectStartTimeForPartTime1 = () => {
    if (partTimeStartTime1 == null) {
      toast.error('Please select Part Time Start Time for Shift 1')
      return
    }
    setFinalPartTimeStartDate1(finalPartTimeStartDate + ', ' + partTimeStartTime1)
    setPartTimeStartTimeFlag1(true)
    setPartTimeStartTime1Modal(false)
    setErrorFlag(false)
  }
  const openPartTimeStartTime1Modal = () => {
    setPartTimeStartTime1Modal(true)
  }
  const changeStartTime1ForPartTime = (value) => {
    setPartTimeStartTime1(value)
  }
  //PART TIME END TIME SHIFT 1
  const selectEndTimeForPartTime1 = () => {
    if (partTimeEndTime1 == null) {
      toast.error('Please select Part Time End Time for Shift 1')
      return
    }
    setFinalPartTimeEndDate1(finalPartTimeStartDate + ', ' + partTimeEndTime1)
    setPartTimeEndTimeFlag1(true)
    setPartTimeEndTime1Modal(false)
    setErrorFlag(false)
  }
  const openPartTimeEndTime1Modal = () => {
    setPartTimeEndTime1Modal(true)
  }
  const changeEndTime1ForPartTime = (value) => {
    setPartTimeEndTime1(value)
  }
  //PART TIME PROVIDER COUNT 1
  const changePartTimeProviderCount1 = (e) => {
    e.preventDefault()
    setPartTimeProviderCount1(parseInt(e.target.value))
    setErrorFlag(false)
  }






  //PART TIME START TIME SHIFT 2
  const selectStartTimeForPartTime2 = () => {
    if (partTimeStartTime2 == null) {
      toast.error('Please select Part Time Start Time for Shift 2')
      return
    }
    setFinalPartTimeStartDate2(finalPartTimeStartDate + ', ' + partTimeStartTime2)
    setPartTimeStartTimeFlag2(true)
    setPartTimeStartTime2Modal(false)
    setErrorFlag(false)
  }
  const openPartTimeStartTime2Modal = () => {
    setPartTimeStartTime2Modal(true)
  }
  const changeStartTime2ForPartTime = (value) => {
    setPartTimeStartTime2(value)
  }
  //PART TIME END TIME SHIFT 1
  const selectEndTimeForPartTime2 = () => {
    if (partTimeEndTime2 == null) {
      toast.error('Please select Part Time End Time for Shift 2')
      return
    }
    setFinalPartTimeEndDate2(finalPartTimeStartDate + ', ' + partTimeEndTime2)
    setPartTimeEndTimeFlag2(true)
    setPartTimeEndTime2Modal(false)
    setErrorFlag(false)
  }
  const openPartTimeEndTime2Modal = () => {
    setPartTimeEndTime2Modal(true)
  }
  const changeEndTime2ForPartTime = (value) => {
    setPartTimeEndTime2(value)
  }
  //PART TIME PROVIDER COUNT 1
  const changePartTimeProviderCount2 = (e) => {
    e.preventDefault()
    setPartTimeProviderCount2(parseInt(e.target.value))
    setErrorFlag(false)
  }





  //PART TIME START TIME SHIFT 3
  const selectStartTimeForPartTime3 = () => {
    if (partTimeStartTime3 == null) {
      toast.error('Please select Part Time Start Time for Shift 3')
      return
    }
    setFinalPartTimeStartDate3(finalPartTimeStartDate + ', ' + partTimeStartTime3)
    setPartTimeStartTimeFlag3(true)
    setPartTimeStartTime3Modal(false)
    setErrorFlag(false)
  }
  const openPartTimeStartTime3Modal = () => {
    setPartTimeStartTime3Modal(true)
  }
  const changeStartTime3ForPartTime = (value) => {
    setPartTimeStartTime3(value)
  }
  //PART TIME END TIME SHIFT 1
  const selectEndTimeForPartTime3 = () => {
    if (partTimeEndTime3 == null) {
      toast.error('Please select Part Time End Time for Shift 3')
      return
    }
    setFinalPartTimeEndDate3(finalPartTimeStartDate + ', ' + partTimeEndTime3)
    setPartTimeEndTimeFlag3(true)
    setPartTimeEndTime3Modal(false)
    setErrorFlag(false)
  }
  const openPartTimeEndTime3Modal = () => {
    setPartTimeEndTime3Modal(true)
  }
  const changeEndTime3ForPartTime = (value) => {
    setPartTimeEndTime3(value)
  }
  //PART TIME PROVIDER COUNT 1
  const changePartTimeProviderCount3 = (e) => {
    e.preventDefault()
    setPartTimeProviderCount3(parseInt(e.target.value))
    setErrorFlag(false)
  }




  //PART TIME START TIME SHIFT 4
  const selectStartTimeForPartTime4 = () => {
    if (partTimeStartTime4 == null) {
      toast.error('Please select Part Time Start Time for Shift 4')
      return
    }
    setFinalPartTimeStartDate4(finalPartTimeStartDate + ', ' + partTimeStartTime4)
    setPartTimeStartTimeFlag4(true)
    setPartTimeStartTime4Modal(false)
    setErrorFlag(false)
  }
  const openPartTimeStartTime4Modal = () => {
    setPartTimeStartTime4Modal(true)
  }
  const changeStartTime4ForPartTime = (value) => {
    setPartTimeStartTime4(value)
  }
  //PART TIME END TIME SHIFT 4
  const selectEndTimeForPartTime4 = () => {
    if (partTimeEndTime4 == null) {
      toast.error('Please select Part Time End Time for Shift 4')
      return
    }
    setFinalPartTimeEndDate4(finalPartTimeStartDate + ', ' + partTimeEndTime4)
    setPartTimeEndTimeFlag4(true)
    setPartTimeEndTime4Modal(false)
    setErrorFlag(false)
  }
  const openPartTimeEndTime4Modal = () => {
    setPartTimeEndTime4Modal(true)
  }
  const changeEndTime4ForPartTime = (value) => {
    setPartTimeEndTime4(value)
  }
  //PART TIME PROVIDER COUNT 4
  const changePartTimeProviderCount4 = (e) => {
    e.preventDefault()
    setPartTimeProviderCount4(parseInt(e.target.value))
    setErrorFlag(false)
  }





  //PART TIME START TIME SHIFT 5
  const selectStartTimeForPartTime5 = () => {
    if (partTimeStartTime5 == null) {
      toast.error('Please select Part Time Start Time for Shift 5')
      return
    }
    setFinalPartTimeStartDate5(finalPartTimeStartDate + ', ' + partTimeStartTime5)
    setPartTimeStartTimeFlag5(true)
    setPartTimeStartTime5Modal(false)
    setErrorFlag(false)
  }
  const openPartTimeStartTime5Modal = () => {
    setPartTimeStartTime5Modal(true)
  }
  const changeStartTime5ForPartTime = (value) => {
    setPartTimeStartTime5(value)
  }
  //PART TIME END TIME SHIFT 5
  const selectEndTimeForPartTime5 = () => {
    if (partTimeEndTime5 == null) {
      toast.error('Please select Part Time End Time for Shift 5')
      return
    }
    setFinalPartTimeEndDate5(finalPartTimeStartDate + ', ' + partTimeEndTime5)
    setPartTimeEndTimeFlag5(true)
    setPartTimeEndTime5Modal(false)
    setErrorFlag(false)
  }
  const openPartTimeEndTime5Modal = () => {
    setPartTimeEndTime5Modal(true)
  }
  const changeEndTime5ForPartTime = (value) => {
    setPartTimeEndTime5(value)
  }
  //PART TIME PROVIDER COUNT 5
  const changePartTimeProviderCount5 = (e) => {
    e.preventDefault()
    setPartTimeProviderCount5(parseInt(e.target.value))
    setErrorFlag(false)
  }






  const addAnotherShift = (e) => {
    if (shiftCount == 0) {
      setShift2Flag(true)
      setShiftCount(1)
    } else if (shiftCount == 1) {
      setShift3Flag(true)
      setShiftCount(2)
    } else if (shiftCount == 2) {
      setShift4Flag(true)
      setShiftCount(3)
    } else if (shiftCount == 3) {
      setShift5Flag(true)
      setShiftCount(4)
    }
  }

  const onInputChange = (fieldName, value, e) => {
    setCreateDisabled(false)
    setFieldValues(() => ({
      ...fieldValues,
      [fieldName]: value,
    }));
  };

  const fields = [];
  const renderedFields = {};

  const renderField = (fieldMetaData, parentFieldValue) => {
    if (!Array.isArray(fieldMetaData)) return null;
    fieldMetaData?.map(({ field, childrenFields }, index) => {
      if (
        parentFieldValue !== undefined &&
        renderedFields[field?.entityAttributeFieldKey] !== undefined
      ) {
        fields[renderedFields[field?.entityAttributeFieldKey]] = (
          <div className="col-md-12 mb-3">
            {inputFieldGenerator(
              field,
              fieldValues[field?.entityAttributeFieldKey],
              onInputChange,
              parentFieldValue,
              props
            )}
          </div>
        );
      }

      if (renderedFields[field?.entityAttributeFieldKey] === undefined) {
        renderedFields[field?.entityAttributeFieldKey] = fields.length;
        fields.push(
          <div className="col-md-12 mb-3">
            {inputFieldGenerator(
              field,
              fieldValues[field?.entityAttributeFieldKey],
              onInputChange,
              parentFieldValue,
              props
            )}
          </div>
        );
      }

      if (childrenFields) {
        renderField(
          childrenFields[0],
          fieldValues[field?.entityAttributeFieldKey]
        );
      }
    });
  };

  const fieldElements = useMemo(() => {
    if (fieldMetaData) {
      renderField(fieldMetaData);
      return fields;
    } else return [];
  }, [fieldMetaData, fieldValues]);

  const onInputChange2 = (fieldName, value, e) => {
    setCreateDisabled(false)
    setFieldValuesJobSettings(() => ({
      ...fieldValuesJobSettings,
      [fieldName]: value,
    }));
  };

  const fields2 = [];
  const renderedFields2 = {};

  const renderField2 = (fieldMetaDataJobSettings, parentFieldValue) => {
    if (!Array.isArray(fieldMetaDataJobSettings)) return null;
    fieldMetaDataJobSettings?.map(({ field, childrenFields }, index) => {
      if (
        parentFieldValue !== undefined &&
        renderedFields2[field?.entityAttributeFieldKey] !== undefined
      ) {
        fields2[renderedFields2[field?.entityAttributeFieldKey]] = (
          <div className="col-md-12 mb-3">
            {inputFieldGenerator(
              field,
              fieldValuesJobSettings[field?.entityAttributeFieldKey],
              onInputChange2,
              parentFieldValue,
              props
            )}
          </div>
        );
      }

      if (renderedFields2[field?.entityAttributeFieldKey] === undefined) {
        renderedFields2[field?.entityAttributeFieldKey] = fields2.length;
        fields2.push(
          <div className="col-md-12 mb-3">
            {inputFieldGenerator(
              field,
              fieldValuesJobSettings[field?.entityAttributeFieldKey],
              onInputChange2,
              parentFieldValue,
              props
            )}
          </div>
        );
      }

      if (childrenFields) {
        renderField2(
          childrenFields[0],
          fieldValuesJobSettings[field?.entityAttributeFieldKey]
        );
      }
    });
  };

  const fieldElements2 = useMemo(() => {
    if (fieldMetaDataJobSettings) {
      renderField2(fieldMetaDataJobSettings);
      return fields2;
    } else return [];
  }, [fieldMetaDataJobSettings, fieldValuesJobSettings]);

  const getSkillBioFn = (userProfileId) => {
    setGettingFieldJobSettings(true);
    props.getSkillBio(
      { userIdHash: props.auth.userProfile.userIdHash, userProfileId: userProfileId },
      (res) => {
        if (res.status) {
          if (res.data.entityInfo) {
            getFormFieldsEntity(res.data.entityInfo.id);
          } else {
            getFormFieldsPrespective({
              perspectiveId: res.data.perspectiveInfo.id,
              userProfileId: userProfileId,
            });
          }
        }
      }
    );
  };

  const getFormFieldsPrespective = (data) => {
    setGettingFieldJobSettings(true);
    setPerspectiveId(data.perspectiveId)
    props.getFormFieldsPrespective(
      { perspectiveId: data.perspectiveId, userProfileId: data.userProfileId },
      (res) => {
        setGettingFieldJobSettings(false);
        if (res.status) {
          setJobSettingsPostFlag(false)
          setFieldMetaDataJobSettings(res.data);
        }
      }
    );
  };

  const getFormFieldsEntity = (id) => {
    setGettingFieldJobSettings(true);
    props.getFormFieldsEntity({ entityId: id }, (res) => {
      setGettingFieldJobSettings(false);
      if (res.status) {
        setFieldMetaDataJobSettings(res.data);
        if (res.data.length) {
          setJobSettingsPostFlag(true)
          setEntityId(id)
          let obj = {}
          for (let i = 0; i < res.data.length; i++) {
            obj[res.data[i].field.entityAttributeFieldKey] = res.data[i].field.fieldValue
          }
          setFieldValuesJobSettings(obj)
        }
      }
    });
  };

  const onClickPostJob = (e, value) => {
    e.preventDefault();
    console.log(value)
    setUserProfileId(value.id)
    setEntityTypeId(value.selectedEntityTypeId)
    setBusinessName(value.entityName)
    setAddress(value.address1 ? value.address1 : value.address)
    setEmploymentTypeData(value.userProfileDataTypes ? value.userProfileDataTypes.employmentType : [])

    if (value.userProfileDataTypes.employmentType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.employmentType.length; i++) {
        arr.push(value.userProfileDataTypes.employmentType[i].name)
      }
      setEmploymentDataForShift(arr)
    }
    if (value.userProfileDataTypes.facilityType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.facilityType.length; i++) {
        arr.push(value.userProfileDataTypes.facilityType[i].name)
      }
      setFacilityDataForShift(arr)
    }
    if (value.userProfileDataTypes.paymentType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.paymentType.length; i++) {
        arr.push(value.userProfileDataTypes.paymentType[i].name)
      }
      setPaymentDataForShift(arr)
    }
    if (value.userProfileDataTypes.scheduleType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.scheduleType.length; i++) {
        arr.push(value.userProfileDataTypes.scheduleType[i].name)
      }
      setScheduleDataForShift(arr)
    }
    if (value.userProfileDataTypes.shiftType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.shiftType.length; i++) {
        arr.push(value.userProfileDataTypes.shiftType[i].name)
      }
      setShiftDataForShift(arr)
    }
    if (value.userProfileDataTypes.payrollType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.payrollType.length; i++) {
        arr.push(value.userProfileDataTypes.payrollType[i].name)
      }
      setPayrollDataForShift(arr)
    }

    setGettingField(true);
    props.getFormFieldsForPostJob(
      { entityTypeId: value.selectedEntityTypeId, userProfileId: value.id },
      (res) => {
        if (res.status) {
          setGettingField(false)
          if (res.data.length > 0) {
            setFieldMetaData(res.data)
          }
        }
      }
    );
    setPostModal(!postModal);
    document.getElementById('kt_content').setAttribute('style', 'position:fixed; width:85%')
  }

  const onClickClosePostModal = () => {
    setFieldMetaData(null);
    setFieldValues({});
    setUserProfileId()
    setEntityTypeId()
    setBusinessName()
    setAddress()
    setEmploymentDataForShift([])
    setFacilityDataForShift([])
    setPaymentDataForShift([])
    setPayrollDataForShift([])
    setScheduleDataForShift([])
    setShiftDataForShift([])
    setFilterShow(false)
    setCreateDisabled(true)
    setCalendarFlag(false)
    setContractStartDate()
    setContractStartTime()
    setShowContractStartDate()
    setFinalContractStartDate()
    setContractEndDate()
    setContractEndTime()
    setShowContractEndDate()
    setFinalContractEndDate()
    setFullTimeStartDate()
    setFullTimeStartTime()
    setShowFullTimeStartDate()
    setFinalFullTimeStartDate()

    setPartTimeStartDate()
    setPartTimeInputDateFlag(false)

    setShiftCount(0)

    setPartTimeStartTime1()
    setPartTimeEndTime1()
    setPartTimeStartTime2()
    setPartTimeEndTime2()
    setPartTimeStartTime3()
    setPartTimeEndTime3()
    setPartTimeStartTime4()
    setPartTimeEndTime4()
    setPartTimeStartTime5()
    setPartTimeEndTime5()

    setFinalPartTimeStartDate1()
    setFinalPartTimeEndDate1()
    setFinalPartTimeStartDate2()
    setFinalPartTimeEndDate2()
    setFinalPartTimeStartDate3()
    setFinalPartTimeEndDate3()
    setFinalPartTimeStartDate4()
    setFinalPartTimeEndDate4()
    setFinalPartTimeStartDate5()
    setFinalPartTimeEndDate5()

    setPartTimeProviderCount1()
    setPartTimeProviderCount2()
    setPartTimeProviderCount3()
    setPartTimeProviderCount4()
    setPartTimeProviderCount5()

    setPartTimeStartTimeFlag1(false)
    setPartTimeEndTimeFlag1(false)
    setPartTimeStartTimeFlag2(false)
    setPartTimeEndTimeFlag2(false)
    setPartTimeStartTimeFlag3(false)
    setPartTimeEndTimeFlag3(false)
    setPartTimeStartTimeFlag4(false)
    setPartTimeEndTimeFlag4(false)
    setPartTimeStartTimeFlag5(false)
    setPartTimeEndTimeFlag5(false)

    setShift2Flag(false)
    setShift3Flag(false)
    setShift4Flag(false)
    setShift5Flag(false)
    setPostModal(!postModal);
    document.getElementById('kt_content').setAttribute('style', '')
  };

  const postJobData = (data) => {
    setGettingField(true);
    setCreateDisabled(true)
    setCloseDisabled(true)
    props.postJob(
      data,
      (res) => {
        setGettingField(false);
        if (res.status) {
          console.log('POST RES', res)
          //setCalendarFlag(true)
          setFieldMetaData(null);
          setFieldValues(null);
          //setPostModal(!postModal);
          // swal({
          //   title: "Job posted successfully",
          //   text: "",
          //   icon: "success"
          // }).then(() => {
          //   setFieldValues({})
          //   setFieldMetaData(null)
          //   setCreateDisabled(true)
          //   setCloseDisabled(false)
          // })
          setFieldValues({})
          setFieldMetaData(null)
          setEntityId(res.data.dataEntityID)
          setCalendarFlag(true)
          setCreateDisabled(false)
        } else {
          if (res.statusCode == 400) {
            toast.error("Required fields cannot be empty");
            setCreateDisabled(false)
            setCloseDisabled(false)
          } else {
            toast.error("Something went wrong!");
            setCreateDisabled(false)
            setCloseDisabled(false)
          }
        }
      },
      (err) => {
        toast.error("Something went wrong!");
        setGettingField(false);
        setCreateDisabled(false)
        setCloseDisabled(false)
      }
    );
  };

  const onPostJob = () => {
    postJobData({
      entityTypeId: entityTypeId,
      data: { data: JSON.stringify(fieldValues) },
      userIdHash: userIdHash,
      userProfileId: userProfileId,
    });
  };

  const onPostDateAndTime = () => {
    if (employmentTypeData.length > 0) {
      let arr = []
      for (let i = 0; i < employmentTypeData.length; i++) {
        if (employmentTypeData[i].employmentTypeId == 3) {
          if (finalContractStartDate == null || finalContractStartDate == undefined) {
            toast.error('Please select start date and time for Contract')
            break
          } else if (finalContractEndDate == null || finalContractEndDate == undefined) {
            toast.error('Please select end date and time for Contract')
            break
          } else if (contractProviderCount == null || contractProviderCount == undefined) {
            toast.error('Please enter number of providers for Contract')
            break
          } else {
            contractObject.employmentTypeId = 3
            contractObject.startTime = finalContractStartDate.toString().substring(0, 3) + ', ' + finalContractStartDate.toString().substring(8, 10) + ' ' + finalContractStartDate.toString().substring(4, 7) + ' ' + finalContractStartDate.toString().substring(11, 15) + ' ' + contractStartTime + ':00 IST'
            contractObject.endTime = finalContractEndDate.toString().substring(0, 3) + ', ' + finalContractEndDate.toString().substring(8, 10) + ' ' + finalContractEndDate.toString().substring(4, 7) + ' ' + finalContractEndDate.toString().substring(11, 15) + ' ' + contractEndTime + ':00 IST'
            contractObject.providerCount = contractProviderCount
            arr.push(contractObject)
          }


        }
        if (employmentTypeData[i].employmentTypeId == 1) {
          if (finalfullTimeStartDate == null || finalfullTimeStartDate == undefined) {
            toast.error('Please select start date and time for Full Time')
            break
          } else if (fullTimeProviderCount == null || fullTimeProviderCount == undefined) {
            toast.error('Please enter number of providers for Full Time')
            break
          } else {
            fullTimeObject.employmentTypeId = 1
            fullTimeObject.startTime = finalfullTimeStartDate.toString().substring(0, 3) + ', ' + finalfullTimeStartDate.toString().substring(8, 10) + ' ' + finalfullTimeStartDate.toString().substring(4, 7) + ' ' + finalfullTimeStartDate.toString().substring(11, 15) + ' ' + fullTimeStartTime + ':00 IST'
            fullTimeObject.endTime = finalfullTimeStartDate.toString().substring(0, 3) + ', ' + finalfullTimeStartDate.toString().substring(8, 10) + ' ' + finalfullTimeStartDate.toString().substring(4, 7) + ' ' + finalfullTimeStartDate.toString().substring(11, 15) + ' ' + fullTimeStartTime + ':00 IST'
            fullTimeObject.providerCount = fullTimeProviderCount
            arr.push(fullTimeObject)
          }


        }
        if (employmentTypeData[i].employmentTypeId == 2) {
          if (finalPartTimeStartDate1 == null || finalPartTimeStartDate1 == undefined) {
            toast.error('Please select start date and time for Part Time Shift 1')
            break
          } else if (finalPartTimeEndDate1 == null || finalPartTimeEndDate1 == undefined) {
            toast.error('Please select end date and time for Part Time Shift 1')
            break
          } else if (partTimeProviderCount1 == null || partTimeProviderCount1 == undefined) {
            toast.error('Please enter number of providers for Part Time Shift 1')
            break
          } else {
            console.log(finalPartTimeStartDate1)
            partTimeObject1.employmentTypeId = 2
            partTimeObject1.startTime = finalPartTimeStartDate1.toString().substring(0, 3) + ', ' + finalPartTimeStartDate1.toString().substring(8, 10) + ' ' + finalPartTimeStartDate1.toString().substring(4, 7) + ' ' + finalPartTimeStartDate1.toString().substring(11, 15) + ' ' + partTimeStartTime1 + ':00 IST'
            partTimeObject1.endTime = finalPartTimeEndDate1.toString().substring(0, 3) + ', ' + finalPartTimeEndDate1.toString().substring(8, 10) + ' ' + finalPartTimeEndDate1.toString().substring(4, 7) + ' ' + finalPartTimeEndDate1.toString().substring(11, 15) + ' ' + partTimeEndTime1 + ':00 IST'
            partTimeObject1.providerCount = partTimeProviderCount1
            arr.push(partTimeObject1)
          }

          if (finalPartTimeStartDate2 != undefined || finalPartTimeEndDate2 != undefined) {
            if (finalPartTimeStartDate2 == null || finalPartTimeStartDate2 == undefined) {
              toast.error('Please select start date and time for Part Time Shift 2')
              break
            } else if (finalPartTimeEndDate2 == null || finalPartTimeEndDate2 == undefined) {
              toast.error('Please select end date and time for Part Time Shift 2')
              break
            } else if (partTimeProviderCount2 == null || partTimeProviderCount2 == undefined) {
              toast.error('Please enter number of providers for Part Time Shift 2')
              break
            } else {
              partTimeObject2.employmentTypeId = 2
              partTimeObject2.startTime = finalPartTimeStartDate2.toString().substring(0, 3) + ', ' + finalPartTimeStartDate2.toString().substring(8, 10) + ' ' + finalPartTimeStartDate2.toString().substring(4, 7) + ' ' + finalPartTimeStartDate2.toString().substring(11, 15) + ' ' + partTimeStartTime2 + ':00 IST'
              partTimeObject2.endTime = finalPartTimeEndDate2.toString().substring(0, 3) + ', ' + finalPartTimeEndDate2.toString().substring(8, 10) + ' ' + finalPartTimeEndDate2.toString().substring(4, 7) + ' ' + finalPartTimeEndDate2.toString().substring(11, 15) + ' ' + partTimeEndTime2 + ':00 IST'
              partTimeObject2.providerCount = partTimeProviderCount2
              arr.push(partTimeObject2)
            }


          }
          if (finalPartTimeStartDate3 != undefined || finalPartTimeEndDate3 != undefined) {
            if (finalPartTimeStartDate3 == null || finalPartTimeStartDate3 == undefined) {
              toast.error('Please select start date and time for Part Time Shift 3')
              break
            } else if (finalPartTimeEndDate3 == null || finalPartTimeEndDate3 == undefined) {
              toast.error('Please select end date and time for Part Time Shift 3')
              break
            } else if (partTimeProviderCount3 == null || partTimeProviderCount3 == undefined) {
              toast.error('Please enter number of providers for Part Time Shift 3')
              break
            } else {
              partTimeObject3.employmentTypeId = 2
              partTimeObject3.startTime = finalPartTimeStartDate3.toString().substring(0, 3) + ', ' + finalPartTimeStartDate3.toString().substring(8, 10) + ' ' + finalPartTimeStartDate3.toString().substring(4, 7) + ' ' + finalPartTimeStartDate3.toString().substring(11, 15) + ' ' + partTimeStartTime3 + ':00 IST'
              partTimeObject3.endTime = finalPartTimeEndDate3.toString().substring(0, 3) + ', ' + finalPartTimeEndDate3.toString().substring(8, 10) + ' ' + finalPartTimeEndDate3.toString().substring(4, 7) + ' ' + finalPartTimeEndDate3.toString().substring(11, 15) + ' ' + partTimeEndTime3 + ':00 IST'
              partTimeObject3.providerCount = partTimeProviderCount3
              arr.push(partTimeObject3)
            }


          }
          if (finalPartTimeStartDate4 != undefined || finalPartTimeEndDate4 != undefined) {
            if (finalPartTimeStartDate4 == null || finalPartTimeStartDate4 == undefined) {
              toast.error('Please select start date and time for Part Time Shift 4')
              break
            } else if (finalPartTimeEndDate4 == null || finalPartTimeEndDate4 == undefined) {
              toast.error('Please select end date and time for Part Time Shift 4')
              break
            } else if (partTimeProviderCount4 == null || partTimeProviderCount4 == undefined) {
              toast.error('Please enter number of providers for Part Time Shift 4')
              break
            } else {
              partTimeObject4.employmentTypeId = 2
              partTimeObject4.startTime = finalPartTimeStartDate4.toString().substring(0, 3) + ', ' + finalPartTimeStartDate4.toString().substring(8, 10) + ' ' + finalPartTimeStartDate4.toString().substring(4, 7) + ' ' + finalPartTimeStartDate4.toString().substring(11, 15) + ' ' + partTimeStartTime4 + ':00 IST'
              partTimeObject4.endTime = finalPartTimeEndDate4.toString().substring(0, 3) + ', ' + finalPartTimeEndDate4.toString().substring(8, 10) + ' ' + finalPartTimeEndDate4.toString().substring(4, 7) + ' ' + finalPartTimeEndDate4.toString().substring(11, 15) + ' ' + partTimeEndTime4 + ':00 IST'
              partTimeObject4.providerCount = partTimeProviderCount4
              arr.push(partTimeObject4)
            }


          }
          if (finalPartTimeStartDate5 != undefined || finalPartTimeEndDate5 != undefined) {
            if (finalPartTimeStartDate5 == null || finalPartTimeStartDate5 == undefined) {
              toast.error('Please select start date and time for Part Time Shift 5')
              break
            } else if (finalPartTimeEndDate5 == null || finalPartTimeEndDate5 == undefined) {
              toast.error('Please select end date and time for Part Time Shift 5')
              break
            } else if (partTimeProviderCount5 == null || partTimeProviderCount5 == undefined) {
              toast.error('Please enter number of providers for Part Time Shift 5')
              break
            } else {
              partTimeObject5.employmentTypeId = 2
              partTimeObject5.startTime = finalPartTimeStartDate5.toString().substring(0, 3) + ', ' + finalPartTimeStartDate5.toString().substring(8, 10) + ' ' + finalPartTimeStartDate5.toString().substring(4, 7) + ' ' + finalPartTimeStartDate5.toString().substring(11, 15) + ' ' + partTimeStartTime5 + ':00 IST'
              partTimeObject5.endTime = finalPartTimeEndDate5.toString().substring(0, 3) + ', ' + finalPartTimeEndDate5.toString().substring(8, 10) + ' ' + finalPartTimeEndDate5.toString().substring(4, 7) + ' ' + finalPartTimeEndDate5.toString().substring(11, 15) + ' ' + partTimeEndTime5 + ':00 IST'
              partTimeObject5.providerCount = partTimeProviderCount5
              arr.push(partTimeObject5)
            }


          }
        }
      }

      let params = {
        userProfileId: userProfileId,
        entityId: entityId,
        data: arr
      }

      if (arr.length >= employmentTypeData.length) {
        postDateAndTime(params)
      }

    }

  }

  const postDateAndTime = (params) => {
    setGettingField(true)
    setCreateDisabled(true)
    props.postJobDateAndTime(params, res => {
      if (res.status) {
        if (res.data.length) {
          setPostModal(!postModal);
          swal({
            title: "Job posted successfully",
            text: "",
            icon: "success"
          }).then(() => {
            setCreateDisabled(true)
            setCloseDisabled(false)
            setCalendarFlag(false)
            setContractStartDate()
            setContractStartTime()
            setShowContractStartDate()
            setFinalContractStartDate()
            setContractEndDate()
            setContractEndTime()
            setShowContractEndDate()
            setFinalContractEndDate()
            setFullTimeStartDate()
            setFullTimeStartTime()
            setShowFullTimeStartDate()
            setFinalFullTimeStartDate()

            setPartTimeStartDate()
            setPartTimeInputDateFlag(false)

            setShiftCount(0)

            setPartTimeStartTime1()
            setPartTimeEndTime1()
            setPartTimeStartTime2()
            setPartTimeEndTime2()
            setPartTimeStartTime3()
            setPartTimeEndTime3()
            setPartTimeStartTime4()
            setPartTimeEndTime4()
            setPartTimeStartTime5()
            setPartTimeEndTime5()

            setFinalPartTimeStartDate1()
            setFinalPartTimeEndDate1()
            setFinalPartTimeStartDate2()
            setFinalPartTimeEndDate2()
            setFinalPartTimeStartDate3()
            setFinalPartTimeEndDate3()
            setFinalPartTimeStartDate4()
            setFinalPartTimeEndDate4()
            setFinalPartTimeStartDate5()
            setFinalPartTimeEndDate5()

            setPartTimeProviderCount1()
            setPartTimeProviderCount2()
            setPartTimeProviderCount3()
            setPartTimeProviderCount4()
            setPartTimeProviderCount5()

            setPartTimeStartTimeFlag1(false)
            setPartTimeEndTimeFlag1(false)
            setPartTimeStartTimeFlag2(false)
            setPartTimeEndTimeFlag2(false)
            setPartTimeStartTimeFlag3(false)
            setPartTimeEndTimeFlag3(false)
            setPartTimeStartTimeFlag4(false)
            setPartTimeEndTimeFlag4(false)
            setPartTimeStartTimeFlag5(false)
            setPartTimeEndTimeFlag5(false)

            setShift2Flag(false)
            setShift3Flag(false)
            setShift4Flag(false)
            setShift5Flag(false)
            document.getElementById('kt_content').setAttribute('style', '')
          })
        }
      }
    })
  }

  const openJobSettingsModal = (e, value) => {
    e.preventDefault();
    console.log(value)
    setUserProfileId(value.id)
    setEntityTypeId(value.selectedEntityTypeId)
    setBusinessName(value.entityName)
    setAddress(value.address1 ? value.address1 : value.address)

    if (value.userProfileDataTypes.employmentType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.employmentType.length; i++) {
        arr.push(value.userProfileDataTypes.employmentType[i].name)
      }
      setEmploymentDataForShift(arr)
    }
    if (value.userProfileDataTypes.facilityType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.facilityType.length; i++) {
        arr.push(value.userProfileDataTypes.facilityType[i].name)
      }
      setFacilityDataForShift(arr)
    }
    if (value.userProfileDataTypes.paymentType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.paymentType.length; i++) {
        arr.push(value.userProfileDataTypes.paymentType[i].name)
      }
      setPaymentDataForShift(arr)
    }
    if (value.userProfileDataTypes.scheduleType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.scheduleType.length; i++) {
        arr.push(value.userProfileDataTypes.scheduleType[i].name)
      }
      setScheduleDataForShift(arr)
    }
    if (value.userProfileDataTypes.shiftType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.shiftType.length; i++) {
        arr.push(value.userProfileDataTypes.shiftType[i].name)
      }
      setShiftDataForShift(arr)
    }
    if (value.userProfileDataTypes.payrollType.length > 0) {
      let arr = []
      for (let i = 0; i < value.userProfileDataTypes.payrollType.length; i++) {
        arr.push(value.userProfileDataTypes.payrollType[i].name)
      }
      setPayrollDataForShift(arr)
    }
    getSkillBioFn(value.id)
    setJobSettingsModal(true)
  }

  const closeJobSettingsModal = (e) => {
    e.preventDefault()
    setJobSettingsModal(false);
    setUserProfileId()
    setEntityTypeId()
    setPerspectiveId()
    setBusinessName()
    setAddress()
    setEmploymentDataForShift([])
    setFacilityDataForShift([])
    setPaymentDataForShift([])
    setPayrollDataForShift([])
    setScheduleDataForShift([])
    setShiftDataForShift([])
    setFilterShow(false)
    setCreateDisabled(true)
    setFieldMetaDataJobSettings(null);
    setFieldValuesJobSettings({});
  };

  const submitJobSetting = () => {
    submitJobSettingsData({
      perspectiveId: perspectiveId,
      data: { data: JSON.stringify(fieldValuesJobSettings) },
      userIdHash: userIdHash,
      userProfileId: userProfileId,
    });
  }

  const submitJobSettingsData = (params) => {
    setGettingFieldJobSettings(true)
    setCreateDisabled(true)
    props.postJobSettings(params, res => {
      if (res.status) {
        setJobSettingsModal(false)
        swal({
          title: "Job settings posted successfully",
          text: "",
          icon: "success"
        }).then(() => {
          setCreateDisabled(false)
        })
      }
    })
  }

  const updateJobSetting = () => {
    updateJobSettingsData({
      entityId: entityId,
      data: { data: JSON.stringify(fieldValuesJobSettings) },
      userIdHash: userIdHash,
      userProfileId: userProfileId,
    });
  }

  const updateJobSettingsData = (params) => {
    setGettingFieldJobSettings(true)
    setCreateDisabled(true)
    props.updateJobSettings(params, res => {
      if (res.status) {
        setJobSettingsModal(false)
        swal({
          title: "Job settings updated successfully",
          text: "",
          icon: "success"
        }).then(() => {
          setEntityId()
        })
      }
    })
  }

  const openJobListPage = (e, value) => {
    e.preventDefault()
    console.log(value)
    history.push('/jobList/' + value.id + '/' + value.selectedEntityTypeId)
  }

  const openCandidatesPage = (e, value) => {
    history.push(`/candidates/${value.id}`)
  }

  return (
    <>
      <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">

        <div className="d-flex flex-column-fluid">

          <div className="custom-container">

            <div className="row  pb-5">
              <div className="col-lg-6 col-md-6">
                <div className="inner_txecy">
                  <h4 className='mr-3'>Job Post Management </h4>
                  <p>In order to post your job, select the appropriate talent carefully.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                {/* <div className="talen_outre">
                  <button className="btn" data-toggle="modal" data-target="#myModal12">+ CREATE TALENT</button>
                </div> */}
              </div>
            </div>

            <div className="Profile_form">
              {loading ?
                (
                  <div className="row" style={{ width: '100%', height: '30vh' }}>
                    <div className="col-12" style={{ width: '100%', height: '100%' }}>
                      <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                        <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                      </div>
                    </div>
                  </div>
                ) :
                (<>
                  {talentData.length == 0 ? (
                    <div className='row h-100 w-100'><div className='col-12 text-center m-auto'>No Data Found</div></div>
                  ) :
                    (
                      <div className='row'>
                        {talentData.map((value, index) => {
                          return (
                            <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                              <div className="blush_outer">
                                <div className="geoge_outer">
                                  <div className='custom789 circleName'>{value.userProfileDataTypes.UserProfileEntityTypeIsSelected ? value.userProfileDataTypes.UserProfileEntityTypeIsSelected.entityTypeNameAbbreviation : ''}</div>
                                  <img src={value.isVerified ? verified : unVerified} alt="verify" style={{ position: "absolute", top: '55px', left: '55px' }} />
                                  <div className="google_inner">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h5>{value.entityName}</h5>
                                      <img src={showBadge(value.userProfileDataTypes.selectedUserDataRating)} alt="badge" className=" badgeClass" />
                                    </div>
                                    <p style={{fontSize:'14px', color:'grey'}}>{value.userProfileDataTypes.Profession}</p>
                                    <p className="custom_png"><img className="custom78" src={location} alt="" />{value.city}, {value.country}</p>
                                  </div>
                                </div>
                                <div className="open_job">
                                  <h5>
                                    <a href="javascript:void(0)" onClick={(e) => openCandidatesPage(e, value)}>
                                      <svg width="24" height="15" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5168 14.9256C17.5168 14.9256 12.998 13.765 12.3602 12.5013C12.0692 11.9226 12.3551 11.1838 12.6461 10.6743C12.7794 10.4954 12.9085 10.3064 13.0333 10.1074C13.7875 8.90105 14.3628 7.31351 14.4218 5.35313C14.5104 2.40244 12.2251 0.00842222 9.51328 0C9.51166 0 9.50912 0 9.50745 0C9.50578 0 9.50405 0 9.5007 0C6.78961 0.00842222 4.50449 2.40244 4.59303 5.35313C4.65209 7.31351 5.22826 8.90105 5.98145 10.1074H5.98059H5.98145C6.10633 10.3065 6.23703 10.4954 6.36952 10.6743C6.65971 11.1838 6.94564 11.9226 6.65545 12.5013C6.0186 13.765 1.49813 14.9256 1.49813 14.9256C0.628482 15.2158 0 16.0392 0 17.0076V19H19.0149V17.0076C19.0149 16.0392 18.3881 15.2158 17.5168 14.9256Z" fill="#006838"></path>
                                        <path d="M26.5513 16.0375C26.5016 16.0206 23.2599 15.1939 22.7977 14.2745C22.5843 13.8546 22.7943 13.3162 23.006 12.9451C23.1021 12.8153 23.1958 12.6785 23.2869 12.5334C23.8352 11.6562 24.2544 10.5022 24.2974 9.07668C24.3624 6.93241 22.6998 5.18961 20.7251 5.18459C20.7234 5.18459 20.7226 5.18459 20.7209 5.18459C20.72 5.18459 20.7175 5.18459 20.7167 5.18459C18.7427 5.18966 17.0785 6.93241 17.1434 9.07668C17.1865 10.5022 17.6057 11.6562 18.1547 12.5334C18.2458 12.6785 18.3403 12.8153 18.4356 12.9451C18.4508 12.9721 18.466 12.9991 18.4803 13.0261C20.0805 13.6891 21.1365 15.2531 21.1365 17.0076V19H27.6402V17.5507C27.6403 16.8456 27.1832 16.2484 26.5513 16.0375Z" fill="#006838"></path>
                                      </svg>
                                      <span className="ml-2" style={{fontSize:'14px'}}>Candidates</span>
                                    </a>
                                  </h5>
                                </div>
                                <div className="button_list">
                                  <a className="about_btn shift_outer4" href="javascript:void(0)" onClick={(e) => onClickPostJob(e, value)}>Post Your job</a>
                                  <a className="about_btn" href="javascript:void(0)" onClick={(e) => openJobSettingsModal(e, value)}>Job Settings</a>
                                  <a className="about_btn shift_outer5" href="javascript:void(0)" onClick={(e) => { openJobListPage(e, value) }}>My Job List</a>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                </>)}
            </div >
            <Modal
              show={postModal}
              size="md"
              centered
              style={{ background: "rgb(95 95 95 / 77%)" }}
            >
              <div className="text-center m-5">
                <div className='text-center m-5'>
                  <Modal.Title>
                    {!calendarFlag ?
                      (<>
                        <b>Post Your Job</b>
                      </>) :
                      (<>
                        <b>Select Date and Shift</b>
                      </>)}

                  </Modal.Title>
                </div>
              </div>

              {gettingField ? (
                <div className="row" style={{ width: '100%', height: '30vh' }}>
                  <div className="col-12" style={{ width: '100%', height: '100%' }}>
                    <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '25vh' }}>
                      <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="row mt-3 mb-3" style={{ padding: '15px' }}>
                    <div className="col-12">
                      <div className="talent_details_shift">
                        <a className={
                          filterShow
                            ? "panel-heading heading_links_ accordion_title_info_ d-flex justify-content-between"
                            : "panel-heading heading_links_ accordion_title_info_ collapsed d-flex justify-content-between"
                        }
                          onClick={() => setFilterShow(!filterShow)}
                          style={{ cursor: 'pointer' }}
                        >
                          <span style={{ fontWeight: '600', color: '#3abd00' }}>Talent Profile Id : <span style={{ color: 'black' }}>{userProfileId}</span></span>
                          <img src={downArrow} />
                        </a>
                        <div
                          className={
                            filterShow ? "collapse show" : "collapse"
                          }
                          id="acceptance"
                        >
                          <div
                            className="card border-0 pt-2"
                            style={{
                              "max-height": "200px",
                              overflow: "auto",
                            }}
                          >
                            <div className='card-body' style={{ paddingTop: '0px' }}>
                              <table className='table' style={{ width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <td className='table-shift-td-1' style={{ border: 'none' }}>Business Name</td>
                                    <td className='table-shift-td-2' style={{ border: 'none' }}>{businessName}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Location</td>
                                    <td className='table-shift-td-2'>{address}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Employment Type</td>
                                    <td className='table-shift-td-2'>{employmentDataForShift.length > 0 ? employmentDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Facility Type</td>
                                    <td className='table-shift-td-2'>{facilityDataForShift.length > 0 ? facilityDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Payment Type</td>
                                    <td className='table-shift-td-2'>{paymentDataForShift.length > 0 ? paymentDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Payroll Type</td>
                                    <td className='table-shift-td-2'>{payrollDataForShift.length > 0 ? payrollDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Schedule Type</td>
                                    <td className='table-shift-td-2'>{scheduleDataForShift.length > 0 ? scheduleDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Shift Type</td>
                                    <td className='table-shift-td-2'>{shiftDataForShift.length > 0 ? shiftDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!calendarFlag ?
                    (<>
                      <div className="row mx-2">{fieldElements}</div>
                    </>) :
                    (<>
                      <div className='card'>
                        <div className='card-body'>
                          {employmentTypeData.length > 0 && (
                            employmentTypeData.map((ed, index) => {
                              return (
                                <>
                                  {ed.employmentTypeId == 3 && (
                                    <>
                                      <div className='row pt-5 pb-5'>
                                        <div className='col-12 p-0'>
                                          <label style={{ fontSize: '15px' }}><b>Contract Start and End Date : </b></label>
                                          <div>
                                            <div className='d-flex'>
                                              {/* Contract Start Date and Time */}
                                              <label className='mr-3' style={{ paddingTop: '5px' }}>Start Date : </label>
                                              <input type='text' placeholder='Select Start Date' style={{ border: 'none', width: '30%' }} value={showContractStartDate} disabled />
                                              <DatePicker onChange={changeStartDateForContract} value={contractStartDate} className='start-date-calendar' />
                                              {contractStartTimeFlag ?
                                                (<>
                                                  <Modal show={contractStartTimeFlag}
                                                    size="sm"
                                                    centered
                                                    style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                    <Modal.Body style={{ padding: '20px' }}>
                                                      <TimePicker onChange={changeStartTimeForContract} value={contractStartTime} disableClock={true} />
                                                      <br></br>
                                                      <Button
                                                        className="mx-3"
                                                        variant="danger"
                                                        onClick={() => { setContractStartTimeFlag(false) }}
                                                      >Cancel</Button>
                                                      <Button className="mx-3" variant="primary" onClick={selectStartTimeForContract}>
                                                        Ok
                                                      </Button>
                                                    </Modal.Body>

                                                  </Modal>

                                                </>) : (<></>)}

                                              {/* Contract End Date and Time */}
                                              <label className='mr-3 ml-5' style={{ paddingTop: '5px' }}>End Date : </label>
                                              <input type='text' placeholder='Select End Date' style={{ border: 'none', width: '30%' }} value={showContractEndDate} disabled />
                                              <DatePicker onChange={changeEndDateForContract} value={contractEndDate} className='end-date-calendar' />
                                              {contractEndTimeFlag ?
                                                (<>
                                                  <Modal show={contractEndTimeFlag}
                                                    size="sm"
                                                    centered
                                                    style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                    <Modal.Body style={{ padding: '20px' }}>
                                                      <TimePicker onChange={changeEndTimeForContract} value={contractEndTime} disableClock={true} />
                                                      <br></br>
                                                      <Button
                                                        className="mx-3"
                                                        variant="danger"
                                                        onClick={() => { setContractEndTimeFlag(false) }}
                                                      >Cancel</Button>
                                                      <Button className="mx-3" variant="primary" onClick={selectEndTimeForContract}>
                                                        Ok
                                                      </Button>
                                                    </Modal.Body>

                                                  </Modal>

                                                </>) : (<></>)}
                                            </div>
                                            <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changeContractProviderCount(e) }} value={contractProviderCount} />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {ed.employmentTypeId == 1 && (
                                    <>
                                      <div className='row pt-5 pb-5'>
                                        <div className='col-12 p-0'>
                                          <label style={{ fontSize: '15px' }}><b>Full Time Date : </b></label>
                                          <div>
                                            <div className='d-flex'>
                                              {/* Full-Time Start Date and Time */}
                                              <label className='mr-3' style={{ paddingTop: '5px' }}>Start Date : </label>
                                              <input type='text' placeholder='Select Start Date' style={{ border: 'none', width: '30%' }} value={showFullTimeStartDate} disabled />
                                              <DatePicker onChange={changeStartDateForFullTime} value={fullTimeStartDate} className='start-date-calendar' />
                                              {fullTimeStartTimeFlag ?
                                                (<>
                                                  <Modal show={fullTimeStartTimeFlag}
                                                    size="sm"
                                                    centered
                                                    style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                    <Modal.Body style={{ padding: '20px' }}>
                                                      <TimePicker onChange={changeStartTimeForFullTime} value={fullTimeStartTime} disableClock={true} />
                                                      <br></br>
                                                      <Button
                                                        className="mx-3"
                                                        variant="danger"
                                                        onClick={() => { setFullTimeStartTimeFlag(false) }}
                                                      >Cancel</Button>
                                                      <Button className="mx-3" variant="primary" onClick={selectStartTimeForFullTime}>
                                                        Ok
                                                      </Button>
                                                    </Modal.Body>

                                                  </Modal>

                                                </>) : (<></>)}
                                            </div>
                                            <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changeFullTimeProviderCount(e) }} value={fullTimeProviderCount} />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {ed.employmentTypeId == 2 && (
                                    <>
                                      <div className='row pt-5 pb-5'>
                                        <div className='col-12 p-0'>
                                          <label style={{ fontSize: '15px' }}><b>Daily Date Picker : </b></label>
                                          <div className='mb-3'>
                                            <Calendar className='part-time-calendar' onChange={changeDateForPartTime} />
                                          </div>
                                          {partTimeInputDateFlag ?
                                            (<>
                                              <Modal show={partTimeStartTime1Modal}
                                                size="sm"
                                                centered
                                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                <Modal.Body style={{ padding: '20px' }}>
                                                  <TimePicker onChange={changeStartTime1ForPartTime} value={partTimeStartTime1} disableClock={true} />
                                                  <br></br>
                                                  <Button
                                                    className="mx-3"
                                                    variant="danger"
                                                    onClick={() => { setPartTimeStartTime1Modal(false) }}
                                                  >Cancel</Button>
                                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime1}>
                                                    Ok
                                                  </Button>
                                                </Modal.Body>
                                              </Modal>
                                              <Modal show={partTimeEndTime1Modal}
                                                size="sm"
                                                centered
                                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                <Modal.Body style={{ padding: '20px' }}>
                                                  <TimePicker onChange={changeEndTime1ForPartTime} value={partTimeEndTime1} disableClock={true} />
                                                  <br></br>
                                                  <Button
                                                    className="mx-3"
                                                    variant="danger"
                                                    onClick={() => { setPartTimeEndTime1Modal(false) }}
                                                  >Cancel</Button>
                                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime1}>
                                                    Ok
                                                  </Button>
                                                </Modal.Body>
                                              </Modal>

                                              <Modal show={partTimeStartTime2Modal}
                                                size="sm"
                                                centered
                                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                <Modal.Body style={{ padding: '20px' }}>
                                                  <TimePicker onChange={changeStartTime2ForPartTime} value={partTimeStartTime2} disableClock={true} />
                                                  <br></br>
                                                  <Button
                                                    className="mx-3"
                                                    variant="danger"
                                                    onClick={() => { setPartTimeStartTime2Modal(false) }}
                                                  >Cancel</Button>
                                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime2}>
                                                    Ok
                                                  </Button>
                                                </Modal.Body>
                                              </Modal>
                                              <Modal show={partTimeEndTime2Modal}
                                                size="sm"
                                                centered
                                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                <Modal.Body style={{ padding: '20px' }}>
                                                  <TimePicker onChange={changeEndTime2ForPartTime} value={partTimeEndTime2} disableClock={true} />
                                                  <br></br>
                                                  <Button
                                                    className="mx-3"
                                                    variant="danger"
                                                    onClick={() => { setPartTimeEndTime2Modal(false) }}
                                                  >Cancel</Button>
                                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime2}>
                                                    Ok
                                                  </Button>
                                                </Modal.Body>
                                              </Modal>

                                              <Modal show={partTimeStartTime3Modal}
                                                size="sm"
                                                centered
                                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                <Modal.Body style={{ padding: '20px' }}>
                                                  <TimePicker onChange={changeStartTime3ForPartTime} value={partTimeStartTime3} disableClock={true} />
                                                  <br></br>
                                                  <Button
                                                    className="mx-3"
                                                    variant="danger"
                                                    onClick={() => { setPartTimeStartTime3Modal(false) }}
                                                  >Cancel</Button>
                                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime3}>
                                                    Ok
                                                  </Button>
                                                </Modal.Body>
                                              </Modal>
                                              <Modal show={partTimeEndTime3Modal}
                                                size="sm"
                                                centered
                                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                <Modal.Body style={{ padding: '20px' }}>
                                                  <TimePicker onChange={changeEndTime3ForPartTime} value={partTimeEndTime3} disableClock={true} />
                                                  <br></br>
                                                  <Button
                                                    className="mx-3"
                                                    variant="danger"
                                                    onClick={() => { setPartTimeEndTime3Modal(false) }}
                                                  >Cancel</Button>
                                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime3}>
                                                    Ok
                                                  </Button>
                                                </Modal.Body>
                                              </Modal>

                                              <Modal show={partTimeStartTime4Modal}
                                                size="sm"
                                                centered
                                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                <Modal.Body style={{ padding: '20px' }}>
                                                  <TimePicker onChange={changeStartTime4ForPartTime} value={partTimeStartTime4} disableClock={true} />
                                                  <br></br>
                                                  <Button
                                                    className="mx-3"
                                                    variant="danger"
                                                    onClick={() => { setPartTimeStartTime4Modal(false) }}
                                                  >Cancel</Button>
                                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime4}>
                                                    Ok
                                                  </Button>
                                                </Modal.Body>
                                              </Modal>
                                              <Modal show={partTimeEndTime4Modal}
                                                size="sm"
                                                centered
                                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                <Modal.Body style={{ padding: '20px' }}>
                                                  <TimePicker onChange={changeEndTime4ForPartTime} value={partTimeEndTime4} disableClock={true} />
                                                  <br></br>
                                                  <Button
                                                    className="mx-3"
                                                    variant="danger"
                                                    onClick={() => { setPartTimeEndTime4Modal(false) }}
                                                  >Cancel</Button>
                                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime4}>
                                                    Ok
                                                  </Button>
                                                </Modal.Body>
                                              </Modal>

                                              <Modal show={partTimeStartTime5Modal}
                                                size="sm"
                                                centered
                                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                <Modal.Body style={{ padding: '20px' }}>
                                                  <TimePicker onChange={changeStartTime5ForPartTime} value={partTimeStartTime5} disableClock={true} />
                                                  <br></br>
                                                  <Button
                                                    className="mx-3"
                                                    variant="danger"
                                                    onClick={() => { setPartTimeStartTime5Modal(false) }}
                                                  >Cancel</Button>
                                                  <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime5}>
                                                    Ok
                                                  </Button>
                                                </Modal.Body>
                                              </Modal>
                                              <Modal show={partTimeEndTime5Modal}
                                                size="sm"
                                                centered
                                                style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                <Modal.Body style={{ padding: '20px' }}>
                                                  <TimePicker onChange={changeEndTime5ForPartTime} value={partTimeEndTime5} disableClock={true} />
                                                  <br></br>
                                                  <Button
                                                    className="mx-3"
                                                    variant="danger"
                                                    onClick={() => { setPartTimeEndTime5Modal(false) }}
                                                  >Cancel</Button>
                                                  <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime5}>
                                                    Ok
                                                  </Button>
                                                </Modal.Body>
                                              </Modal>
                                              <div className='card'>
                                                <div className='card-body' style={{ padding: '10px' }}>
                                                  <label><b>{partTimeStartDate}</b></label>
                                                  <div>
                                                    <label>Add Shift 1 : </label>
                                                    {partTimeStartTimeFlag1 ?
                                                      (<>
                                                        <span className='ml-3'>Start Time : {partTimeStartTime1} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime1Modal() }}></i>
                                                      </>) :
                                                      (<>
                                                        <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime1Modal() }}></i>
                                                      </>)}
                                                    {partTimeEndTimeFlag1 ?
                                                      (<>
                                                        <span className='ml-3'>End Time : {partTimeEndTime1} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime1Modal() }}></i>
                                                      </>) :
                                                      (<>
                                                        <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime1Modal() }}></i>
                                                      </>)}
                                                    <div>
                                                      <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount1(e) }} value={partTimeProviderCount1} />
                                                    </div>
                                                  </div>

                                                  {shift2Flag ?
                                                    (<>
                                                      <div className='pt-3'>
                                                        <label>Add Shift 2 : </label>
                                                        {partTimeStartTimeFlag2 ?
                                                          (<>
                                                            <span className='ml-3'>Start Time : {partTimeStartTime2} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime2Modal() }}></i>
                                                          </>) :
                                                          (<>
                                                            <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime2Modal() }}></i>
                                                          </>)}
                                                        {partTimeEndTimeFlag2 ?
                                                          (<>
                                                            <span className='ml-3'>End Time : {partTimeEndTime2} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime2Modal() }}></i>
                                                          </>) :
                                                          (<>
                                                            <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime2Modal() }}></i>
                                                          </>)}
                                                        <div>
                                                          <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount2(e) }} value={partTimeProviderCount2} />
                                                        </div>
                                                      </div>
                                                    </>) : (<></>)}

                                                  {shift3Flag ?
                                                    (<>
                                                      <div className='pt-3'>
                                                        <label>Add Shift 3 : </label>
                                                        {partTimeStartTimeFlag3 ?
                                                          (<>
                                                            <span className='ml-3'>Start Time : {partTimeStartTime3} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime3Modal() }}></i>
                                                          </>) :
                                                          (<>
                                                            <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime3Modal() }}></i>
                                                          </>)}
                                                        {partTimeEndTimeFlag3 ?
                                                          (<>
                                                            <span className='ml-3'>End Time : {partTimeEndTime3} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime3Modal() }}></i>
                                                          </>) :
                                                          (<>
                                                            <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime3Modal() }}></i>
                                                          </>)}
                                                        <div>
                                                          <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount3(e) }} value={partTimeProviderCount3} />
                                                        </div>
                                                      </div>
                                                    </>) : (<></>)}

                                                  {shift4Flag ?
                                                    (<>
                                                      <div className='pt-3'>
                                                        <label>Add Shift 4 : </label>
                                                        {partTimeStartTimeFlag4 ?
                                                          (<>
                                                            <span className='ml-3'>Start Time : {partTimeStartTime4} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime4Modal() }}></i>
                                                          </>) :
                                                          (<>
                                                            <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime4Modal() }}></i>
                                                          </>)}
                                                        {partTimeEndTimeFlag4 ?
                                                          (<>
                                                            <span className='ml-3'>End Time : {partTimeEndTime4} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime4Modal() }}></i>
                                                          </>) :
                                                          (<>
                                                            <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime4Modal() }}></i>
                                                          </>)}
                                                        <div>
                                                          <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount4(e) }} value={partTimeProviderCount4} />
                                                        </div>
                                                      </div>
                                                    </>) : (<></>)}

                                                  {shift5Flag ?
                                                    (<>
                                                      <div className='pt-3'>
                                                        <label>Add Shift 5 : </label>
                                                        {partTimeStartTimeFlag5 ?
                                                          (<>
                                                            <span className='ml-3'>Start Time : {partTimeStartTime5} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime5Modal() }}></i>
                                                          </>) :
                                                          (<>
                                                            <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime5Modal() }}></i>
                                                          </>)}
                                                        {partTimeEndTimeFlag5 ?
                                                          (<>
                                                            <span className='ml-3'>End Time : {partTimeEndTime5} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime5Modal() }}></i>
                                                          </>) :
                                                          (<>
                                                            <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime5Modal() }}></i>
                                                          </>)}
                                                        <div>
                                                          <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount5(e) }} value={partTimeProviderCount5} />
                                                        </div>
                                                      </div>
                                                    </>) : (<></>)}

                                                  {shiftCount == 4 ? (<></>) :
                                                    (<>
                                                      <div>
                                                        <label className='mt-3' style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => { addAnotherShift(e) }}>Add Another Shift</label>
                                                      </div>
                                                    </>)}

                                                </div>
                                              </div>
                                            </>) :
                                            (<></>)}
                                          <div></div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })
                          )}
                        </div>
                      </div>

                    </>)}
                </>

              )}
              <div className="text-center m-5">
                {calendarFlag ?
                  (<>
                    <Button className="mx-3" variant="primary" onClick={onPostDateAndTime} disabled={createDisabled}>
                      Post
                    </Button>
                  </>) :
                  (<>
                    <Button
                      className="mx-3"
                      variant="danger"
                      onClick={onClickClosePostModal}
                      disabled={closeDisabled}
                    >
                      Close
                    </Button>
                    <Button className="mx-3" variant="primary" onClick={onPostJob} disabled={createDisabled}>
                      Post
                    </Button>
                  </>)}

              </div>
            </Modal>

            <Modal
              show={jobSettingsModal}
              size="md"
              centered
              style={{ background: "rgb(95 95 95 / 77%)" }}
            >
              <div className="text-center m-5">
                <div className='text-center m-5'>
                  <Modal.Title><b>Job Setting</b></Modal.Title>
                </div>
              </div>

              {gettingFieldJobSettings ? (
                <div className="row" style={{ width: '100%', height: '30vh' }}>
                  <div className="col-12" style={{ width: '100%', height: '100%' }}>
                    <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '25vh' }}>
                      <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="row mt-3 mb-3" style={{ padding: '15px' }}>
                    <div className="col-12">
                      <div className="talent_details_shift">
                        <a className={
                          filterShow
                            ? "panel-heading heading_links_ accordion_title_info_ d-flex justify-content-between"
                            : "panel-heading heading_links_ accordion_title_info_ collapsed d-flex justify-content-between"
                        }
                          onClick={() => setFilterShow(!filterShow)}
                          style={{ cursor: 'pointer' }}
                        >
                          <span style={{ fontWeight: '600', color: '#3abd00' }}>Talent Profile Id : <span style={{ color: 'black' }}>{userProfileId}</span></span>
                          <img src={downArrow} />
                        </a>
                        <div
                          className={
                            filterShow ? "collapse show" : "collapse"
                          }
                          id="acceptance"
                        >
                          <div
                            className="card border-0 pt-2"
                            style={{
                              "max-height": "200px",
                              overflow: "auto",
                            }}
                          >
                            <div className='card-body' style={{ paddingTop: '0px' }}>
                              <table className='table' style={{ width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <td className='table-shift-td-1' style={{ border: 'none' }}>Business Name</td>
                                    <td className='table-shift-td-2' style={{ border: 'none' }}>{businessName}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Location</td>
                                    <td className='table-shift-td-2'>{address}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Employment Type</td>
                                    <td className='table-shift-td-2'>{employmentDataForShift.length > 0 ? employmentDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Facility Type</td>
                                    <td className='table-shift-td-2'>{facilityDataForShift.length > 0 ? facilityDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Payment Type</td>
                                    <td className='table-shift-td-2'>{paymentDataForShift.length > 0 ? paymentDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Payroll Type</td>
                                    <td className='table-shift-td-2'>{payrollDataForShift.length > 0 ? payrollDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Schedule Type</td>
                                    <td className='table-shift-td-2'>{scheduleDataForShift.length > 0 ? scheduleDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                  <tr>
                                    <td className='table-shift-td-1'>Shift Type</td>
                                    <td className='table-shift-td-2'>{shiftDataForShift.length > 0 ? shiftDataForShift.join(', ') : '-'}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mx-2">{fieldElements2}</div>

                </>

              )}
              <div className="text-center m-5">
                <Button
                  className="mx-3"
                  variant="danger"
                  onClick={(e) => closeJobSettingsModal(e)}
                  disabled={closeDisabled}
                >
                  Close
                </Button>
                {jobSettingsPostFlag ?
                  (<>
                    <Button className="mx-3" variant="primary" onClick={updateJobSetting} disabled={createDisabled}>
                      Update
                    </Button>
                  </>) :
                  (<>
                    <Button className="mx-3" variant="primary" onClick={submitJobSetting} disabled={createDisabled}>
                      Submit
                    </Button>
                  </>)}

              </div>
            </Modal>

          </div >

        </div >
      </div >
    </>
  )

}

const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  getUserProfileList: bindActionCreators(getUserProfileList, dispatch),
  getFormFieldsEntity: bindActionCreators(getFormFieldsEntity, dispatch),
  getSkillBio: bindActionCreators(getSkillBio, dispatch),
  getFormFieldsPrespective: bindActionCreators(getFormFieldsPrespective, dispatch),
  getFormFieldsForPostJob: bindActionCreators(getFormFieldsForPostJob, dispatch),
  postJob: bindActionCreators(postJob, dispatch),
  getDailyCalendarDetails: bindActionCreators(getDailyCalendarDetails, dispatch),
  postJobDateAndTime: bindActionCreators(postJobDateAndTime, dispatch),
  postJobSettings: bindActionCreators(postJobSettings, dispatch),
  updateJobSettings: bindActionCreators(updateJobSettings, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Post);