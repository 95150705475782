import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import "../../public/css/login-1.css";
import logo_home from "../../public/images/logo_home.png";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import { useHistory } from "react-router-dom";

const AuthLayout = (props) => {
  const history = useHistory();
  useEffect(() => {}, []);
  return (
    <>
      <LandingHeader {...props}/>
      {props.children}
      <LandingFooter />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
