import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

export const getHomeScreenTabs = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/employer/${params.userIdHash}/userProfile/tabs`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getHomeScreenList = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/employer/${params.userIdHash}/find?${
        params.entityTypeId ? `&entityTypeId=${params.entityTypeId}` : ""
      }${
        params.userProfileIds ? `&userProfileIds=${params.userProfileIds}` : ""
      }${params.searchText ? `&searchText=${params.searchText}` : ""}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const invite = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/invite/${params.jobUserProfileId}/${params.entityId}?providerUserProfileId=${params.providerUserProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status > 200 && result.status < 205 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};
