import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";

const Numbers = (props) => {
  const history = useHistory();

  return (
    <>
      <section className="services_section  aos-item" data-aos="fade-up">
        <div className="container choose_us__jersey">
          <div className="row">
            <div className="col-lg-6 jersey_section_inner ">
              <div className="jersey_section">
                <h3>Lorem Ipsum is simply dummy </h3>
                <p className=" pt-4">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6 pr-0">
              <div className="row">
                <div className="col-md-6 service_million_inner1">
                  <div className="service_million">
                    <h3>$250 million</h3>
                    <p>Paid to Freelancers</p>
                  </div>
                  <div className="service_million2">
                    <h3>$1 million</h3>
                    <p>Paid Invoices</p>
                  </div>
                </div>
                <div className="col-md-6 service_million_inner">
                  <div className="service_million1">
                    <h3>800,000</h3>
                    <p>Employers Worldwide</p>
                  </div>
                  <div className="service_million4">
                    <h3>99%</h3>
                    <p>Customer satisfaction Rate</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Numbers);
