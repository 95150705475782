import React from 'react';
import { connect } from 'react-redux';
import Footer from './Footer';
import Header from './Header';
import "../../public/css/style.css";

const HomeLayout = (props) => {

    return (
        <>
            <Header/>
                {props.children}
            <Footer/>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
 });
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeLayout);