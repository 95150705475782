import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import left_small from '../../public/images/left_small.png';
import master_card from '../../public/images/master_card.png';
import card5 from '../../public/images/card5.png';
import setting5 from '../../public/images/setting5.png';


const ManageBankAcc = (props) => {
    const history = useHistory();

    return (
        <>
          <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
                  
                  <div className="d-flex flex-column-fluid">
                     
                     <div className="custom-container">
                        
						 <div className="col-lg-12 col-md-12">
                              <div className="custom_skki8">
                                 <h3>Settings<img src={left_small} /><span>Manage Bank Account Details</span></h3>
                              </div>
                           </div>
                        <div className="Profile_form mange_ban5">
					
                          
                       
                        <div className="row">
						 <div className="col-lg-3 col-md-0"></div>
						<div className="col-lg-6 col-md-12">
							<div className="email_outer custom_pu56">
								<label>Account Number</label>
								<input type="text" placeholder="Enter Account Number" />
							</div>
							<div className="email_outer custom_pu56">
								<label>Account Holder Name</label>
								<input type="text" placeholder="Antony Will" />
							</div>
							<div className="row">
							<div className="col-lg-8 col-md-12">
							<div className="email_outer custom_pu56">
								<label>Bank Name</label>
								<input type="text" placeholder="Lorem Ipsum" />
							</div>
							</div>
							<div className="col-lg-4 col-md-12">
							<div className="email_outer custom_pu56">
								<label>IFSC Code</label>
								<input type="text" placeholder="AB123" />
							</div>
							</div>
							</div>
						</div>
					
						</div>
						
					<div className="forgott6 custom_detalis7">
                        
                        <div className="list_btn">
                           <ul>
                              <li><button className="btn btn_cancek">Cancel</button></li>
                              <li><button className="btn btn_cancek dark_btn">Update</button></li>
                           </ul>
                        </div>
                     </div>

                        </div>
                        
                     </div>
                     
                  </div>
               </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageBankAcc);