import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import insta from "../../public/images/insta.svg";
import Vector from "../../public/images/Vector.svg";
import reliability1 from "../../public/images/reliability(1).svg";
import flexibility from "../../public/images/flexibility.svg";
import shield_1 from "../../public/images/shield_1.svg";
import diagram from "../../public/images/diagram.svg";
import driver from "../../public/images/driver.svg";
import payment from "../../public/images/payment.svg";
import engineering1 from "../../public/images/engineering_(1).svg";
import Hire_Freelancers from "../../public/images/Hire_Freelancers.svg";
import Post_a_Job from "../../public/images/Post_a_Job.svg";
import Image_video from "../../public/images/Image_video.png";
import quote from "../../public/images/quote.svg";
import one from "../../public/images/1.png";
import two from "../../public/images/2.png";
import three from "../../public/images/3.png";
import logo_home from "../../public/images/logo_home.png";
import CarouselComp from "./carousel";
import FindTalent from "./findTalent";
import WhyUs from "./whyUs";
import Numbers from "./numbers";
import WhyChooseUs from "./whyChooseUs";
import WorkYourWay from "./workYourWay";
import WhyEasy from "./whyEasy";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = (props) => {
  const history = useHistory();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <CarouselComp />
      <FindTalent />
      <WhyUs />
      <Numbers />
      <WhyChooseUs />
      <WorkYourWay />
      <WhyEasy />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
