import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { EXPRESS_MIDDLEWARE_API_URL, API_URL } from "../../config";
import { logout } from '../../../redux/actions/auth';

/****** action creator for login ********/
export const userLogin = (params, cb) => {
  return dispatch => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/user/login?password=${params.password}&userNameOrEmailAddress=${params.userNameOrEmailAddress}&userTypeId=${params.userTypeId}`)
      .then(result => {
        if (result.status === 200 && result.data) {

          window.localStorage.setItem('loginType',params.userTypeId);

          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Invalid credentials!",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getUserTypeList = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/userType/actives`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } 
        // else {
        //   let res = {
        //     status: false,
        //     message: result.message ? result.message : message.commonError,
        //     type: message.error,
        //   };
        //   cb(res);
        // }
      })
      // .catch((error) => {
      //   let res = {
      //     status: false,
      //     message: message.commonError,
      //     type: message.error,
      //   };
      //   cb(res);
      // });
  };
};

export const userChangePassword = (params, cb) => {
  return dispatch => {
    RestClient.put(`${EXPRESS_MIDDLEWARE_API_URL}/user/updatepassword?oldPassword=${params.oldPassword}&newPassword=${params.newPassword}`, {}, true)
      .then(result => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Invalid credentials!",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getAllCategories = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/userTypeCategory/actives/${params.userTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const createEmployer = (params, cb) => {
  var payload = {
    countryAbbr: params.countryAbbr,
    firstName: params.firstName,
    lastName: params.lastName,
    userEmailAddress: params.userEmailAddress,
    password: params.password,
    phoneNumber: params.phoneNumber,
    entityName: params.entityName
  }
  return dispatch => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/1?userTypeCategoryId=${parseInt(params.userTypeCategoryId)}`, payload)
      .then(result => {
        console.log('RESULT', result)
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Invalid credentials!",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        console.log('ERROR', error)
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });

  };
};

export const createEmployee = (params, cb) => {
  var payload = {
    countryAbbr: params.countryAbbr,
    firstName: params.firstName,
    lastName: params.lastName,
    userEmailAddress: params.userEmailAddress,
    password: params.password,
    phoneNumber: params.phoneNumber
  }
  return dispatch => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/2`, payload)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Invalid credentials!",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });

  };
};

export const forgotPassword = (params, cb) => {
  return dispatch => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/user/resendForgotPasswordCode?userNameOrEmailAddress=${params.userNameOrEmailAddress}`)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Invalid credentials!",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const resetPassword = (params, cb) => {
  return dispatch => {
    RestClient.put(`${EXPRESS_MIDDLEWARE_API_URL}/user/updatepassword?userTypeId=1&userNameOrEmailAddress=${params.userNameOrEmailAddress}&verifyEmailAddressCode=${params.otp}&newPassword=${params.newPassword}`)
      .then(result => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Invalid OTP!",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const userLogOut = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/user/logout`, {}, true)
      .then(result => {
        window.localStorage.removeItem('loginType');
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
          dispatch(logout());
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Unable to log out",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const verifyEmail = (params, cb) => {
  return dispatch => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/verifyEmailAddressCode?userIdHash=${params.userIdHash}&verifyEmailAddressCode=${params.code}`, {}, true)
      .then(result => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
          dispatch(logout());
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Invalid OTP",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const verifyPhone = (params, cb) => {
  return dispatch => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/verifyPhoneNumberCode?userIdHash=${params.userIdHash}&verifyPhoneNumberCode=${params.code}`, {}, true)
      .then(result => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
          dispatch(logout());
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Invalid OTP",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getUserInformation = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/userInformation/default`, {}, true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getUserInformationWithUserProfileId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/userInformation/default?userProfileId=${params.userProfileId}`, {}, true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const updateUserInformation = (params, cb) => {
  return (dispatch) => {
    RestClient.put(
      `${EXPRESS_MIDDLEWARE_API_URL}/userInformation/${params.userInformationId}?userIdHash=${params.userIdHash}`,
      params.data
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const addUserInformation = (params, cb) => {
  return (dispatch) => {
    console.log('INSIDE ADD INFO')
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/userInformation/add?userIdHash=${params.userIdHash}`, params, true)
      .then((result) => {
        console.log('INSIDE ADD INFO', result)
        // if (result.status == 200) {
        //   let res = {
        //     status: true,
        //     message: result.message ? result.message : message.success,
        //     statusCode: result.status,
        //     type: message.success,
        //     data: result.data,
        //   };
        //   cb(res);
        // } else {
        //   let res = {
        //     status: false,
        //     message: result.message ? result.message : message.commonError,
        //     type: message.error,
        //   };
        //   cb(res);
        // }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getUserProfile = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/userProfileSetting?userIdHash=${params.userIdHash}`, {}, true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const updateUserProfile = (params, cb) => {
  return (dispatch) => {
    RestClient.put(
      `${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/updateUserProfile?userIdHash=${params.userIdHash}`,
      params.data
    )
      .then((result) => {
        if (result === 1 || result === 2) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getUserProfileImage = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/file/${params.userIdHash}/fileType?fileTypeTag=profile_image`
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const uploadUserProfileImage = (params, cb) => {
  return (dispatch) => {
    RestClient.postFormData(
      `${EXPRESS_MIDDLEWARE_API_URL}/file/${params.userIdHash}?fileTypeTag=profile_image`,
      params.data,
      true
    )
    .then((result) => {
      if (result && result.status !== 400) {
        let res = {
          status: true,
          message: result.message ? result.message : message.success,
          statusCode: result.status,
          type: message.success,
          data: result.data,
        };
        cb(res);
      } else {
        let res = {
          status: false,
          message: result.message ? result.message : message.commonError,
          type: message.error,
        };
        cb(res);
      }
    })
    .catch((error) => {
      let res = {
        status: false,
        message: message.commonError,
        type: message.error,
      };
      cb(res);
    });
  };
};

export const getAllNotification = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/notification/actives?userIdHash=${params.userIdHash}`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getViewNotification = (params, cb) => {
  let id = params.notificationId
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/notification/${id}`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getNotificationCount = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/notification/countUnread?userIdHash=${params.userIdHash}`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};