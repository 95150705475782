import React from "react";
import { connect } from "react-redux";
import logo from "../../public/images/logo.png";
import profile from "../../public/images/profile.png";

const MobileHeader = (props) => {
    let userName = "User Name";
    if(props.auth.isLoggedIn && props.auth.userProfile){
      if(props.auth.userProfile.userName){
        userName = props.auth.userProfile.userName;
      }
      else if(props.auth.userProfile.userType && props.auth.userProfile.userType.userType == "Admin"){
        userName = "Admin";
      }
    }
    let nameFirstLetter = userName.charAt(0);

  return (
    <div id="kt_header_mobile" className="header-mobile align-items-center custom_header_bg">
        <a><img alt="Logo" src={logo} className="logo" /></a>
        <div className="d-flex align-items-center">
            <button className="btn btn-hover-text-primary p-0 ml-2" id="kt_header_mobile_topbar_toggle">
                <span className="svg-icon svg-icon-xl">
                    <img src={profile} style={{width: "37px"}}/>
                </span>
            </button>
            <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle"><span/></button>
        </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
