import React from "react";
import { connect } from "react-redux";
import Skill from "../../public/img/skill.svg";
import JobLocation from "../../public/img/joblocation.svg";
import RightArrow from "../../public/img/rightArrow.png";
import { Link } from "react-router-dom";

const RightMenuJob = (props) => {

    const value = props.job
    return (
        <>   
            {
            <div className="tab-pane2 fade show" onclick="closeTabs()" id={"jobdetails_"}
                    role="tabpanel" aria-labelledby="jobdescription1">
                <div className="jobs_details">
                    <div>
                        <h4>
                        {value.userProfileData.selectedEntityData.title} | {value.userProfileData.selectedEntityTypeData.profession}
                        </h4>
                        <p>
                        {value.userProfileData.selectedUserData.entityName}{" "}
                        </p>
                    </div>
                    <p className="job_location d-flex justify-content-between">
                    <a href="javascript:void();">
                    <img src={JobLocation} alt="logo" />
                    <span className="ml-2">{" "}{value.userProfileData.selectedUserData.state}{", "}{value.userProfileData.selectedUserData.country}</span>
                    </a>
                    <span>
                        <a href="javascript:void();">
                        {/* <img src={JobLocation} alt="logo" /> */}
                        {/* <span className="salery">$700 - $1500 p.a.</span> */}
                        </a>
                    </span>
                    </p>
                    <div className="btn_">
                        {props.auth && props.auth.isLoggedIn ? (
                            <Link to={`/apply-job/${value.userProfileData?.selectedEntityData?.id}/${value.userProfileData?.selectedEntityData?.jobUserProfileId}`}><button className="sliderFormBtn  ml-0" type="submit">Apply for Job</button></Link>
                            ):(
                            <button className="sliderFormBtn  ml-0"
                            data-bs-toggle="modal"
                            data-bs-target="#mySignin"
                            >Login to apply</button>
                            )
                            }
                        {/* <button className="sliderFormBtn ml-0" type="submit">Apply for Job</button> */}
                    </div>
                    <div className="JobDescriptionDetails">
                        <h5 className="d-inline-flex align-items-center">
                            <img src={Skill} alt="JobDescription_" className="img-fluid" />
                            <span className="textHead_inner ml-2">Job Description</span>
                            {/* <p> {value.userProfileData.selectedEntityData.description}</p> */}
                        </h5>
                        <div className="">
                            <h3 className="JobDescriptionListingHead mb-3">Responsibilities</h3>
                            <ul className="JobDescriptionListing">
                            <li>
                                <img src={RightArrow} alt="arrow" className="img-fluid" />
                                <span>
                                {value.userProfileData.selectedEntityData.description}
                                </span> 
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  const mapDispatchToProps = (dispatch) => ({
   
 });
  
  export default connect(mapStateToProps, mapDispatchToProps)(RightMenuJob);
