import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getTalentProfile } from "../../actions/employer/talent";
import {
  getJobDetails,
  getFieldValuesForEditing,
  editJob,
  getSkillBio,
  getFormFieldsEntity,
  getDailyCalendarDetails,
  getShortlisted,
  getFollowed,
  getInvited,
  getAllApplicants,
  removeShortlist,
  removeFollow,
  removeInvite,
} from "../../actions/employer/post";
import { accept, reject } from "../../actions/common";
import { getFormFieldsPrespective } from "../../actions/employee/skill";
import { getDaysList, getActiveDaysList } from "../../actions/employer/talent";
import talent_edit from "../../public/images/talent_edit.png";
import downArrow from "../../public/images/downArrow.svg";
import goldBadge from "../../public/images/goldBadge.svg";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import locationImg from "../../public/images/location.png";
import { Button, Modal, Form } from "react-bootstrap";
import swal from "sweetalert";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { showBadge, getFormatedDate } from "../../../utilities/helpers";
import newCalender from "../../public/images/newCalender.svg";

const Candidates = (props) => {
  const [userIdHash, setUserIdHash] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState();
  const [editDisabled, setEditDisabled] = useState(true);
  const [closeEditDisabled, setCloseEditDisabled] = useState(false);
  const [talentProfileData, setTalentProfileData] = useState("");
  const [applicantsData, setApplicantsData] = useState([]);
  const [applicantsDataColA, setApplicantsDataColA] = useState([]);
  const [applicantsDataColB, setApplicantsDataColB] = useState([]);
  const [shortlistedData, setShortlistedData] = useState([]);
  const [shortlistedDataColA, setShortlistedDataColA] = useState([]);
  const [shortlistedDataColB, setShortlistedDataColB] = useState([]);
  const [followedData, setFollowedData] = useState([]);
  const [followedDataColA, setFollowedDataColA] = useState([]);
  const [followedDataColB, setFollowedDataColB] = useState([]);
  const [invitedData, setInvitedData] = useState([]);
  const [invitedDataColA, setInvitedDataColA] = useState([]);
  const [invitedDataColB, setInvitedDataColB] = useState([]);
  const [employmentDataForShift, setEmploymentDataForShift] = useState([]);
  const [scheduleDataForShift, setScheduleDataForShift] = useState([]);
  const [facilityDataForShift, setFacilityDataForShift] = useState([]);
  const [payrollDataForShift, setPayrollDataForShift] = useState([]);
  const [shiftDataForShift, setShiftDataForShift] = useState([]);
  const [paymentDataForShift, setPaymentDataForShift] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [gettingField, setGettingField] = useState(false);
  const [gettingFieldJobSetting, setGettingFieldJobSetting] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [fieldMetaData, setFieldMetaData] = useState(null);
  const [fieldValuesJobSetting, setFieldValuesJobSetting] = useState({});
  const [fieldMetaDataJobSetting, setFieldMetaDataJobSetting] = useState(null);
  const [fieldValuesDays, setFieldValuesDays] = useState({});
  const [fieldMetaDataDays, setFieldMetaDataDays] = useState(null);
  const [activeDays, setActiveDays] = useState([]);
  const [jobInformationFlag, setJobInformationFlag] = useState(true);
  const [jobSettingFlag, setJobSettingFlag] = useState(false);
  const [weeklyCalendarFlag, setWeeklyCalendarFlag] = useState(false);
  const [dailyCalendarFlag, setDailyCalendarFlag] = useState(false);
  const [aboutEmployerFlag, setAboutEmployerFlag] = useState(false);
  const [fullTimeData, setFullTimeData] = useState({});
  const [fullTimeDataFlag, setFullTimeDataFlag] = useState(false);
  const [partTimeData, setPartTimeData] = useState({});
  const [partTimeDataFlag, setPartTimeDataFlag] = useState(false);
  const [contractData, setContractData] = useState({});
  const [contractDataFlag, setContractDataFlag] = useState(false);

  const initModal = { modal: false, type: "", data: {} };
  const [modal, setModal] = useState(initModal);
  const [modalLoading, setModalLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    getTalentProfileData({ userProfileId: props.match.params.talentId });
    getApplicantsData({ userProfileId: props.match.params.talentId });
    getShortlistedData({ userProfileId: props.match.params.talentId });
    getFollowedData({ userProfileId: props.match.params.talentId });
    getInvitedData({ userProfileId: props.match.params.talentId });
  }, []);

  const getTalentProfileData = (params) => {
    props.getTalentProfile(params, (res) => {
      if (res.status) {
        setTalentProfileData(res.data);
        setLoading(false);
        if (res.data.userProfileDataTypes.employmentType.length > 0) {
          let arr = [];
          for (
            let i = 0;
            i < res.data.userProfileDataTypes.employmentType.length;
            i++
          ) {
            arr.push(res.data.userProfileDataTypes.employmentType[i].name);
          }
          setEmploymentDataForShift(arr);
        }
        if (res.data.userProfileDataTypes.facilityType.length > 0) {
          let arr = [];
          for (
            let i = 0;
            i < res.data.userProfileDataTypes.facilityType.length;
            i++
          ) {
            arr.push(res.data.userProfileDataTypes.facilityType[i].name);
          }
          setFacilityDataForShift(arr);
        }
        if (res.data.userProfileDataTypes.paymentType.length > 0) {
          let arr = [];
          for (
            let i = 0;
            i < res.data.userProfileDataTypes.paymentType.length;
            i++
          ) {
            arr.push(res.data.userProfileDataTypes.paymentType[i].name);
          }
          setPaymentDataForShift(arr);
        }
        if (res.data.userProfileDataTypes.scheduleType.length > 0) {
          let arr = [];
          for (
            let i = 0;
            i < res.data.userProfileDataTypes.scheduleType.length;
            i++
          ) {
            arr.push(res.data.userProfileDataTypes.scheduleType[i].name);
          }
          setScheduleDataForShift(arr);
        }
        if (res.data.userProfileDataTypes.shiftType.length > 0) {
          let arr = [];
          for (
            let i = 0;
            i < res.data.userProfileDataTypes.shiftType.length;
            i++
          ) {
            arr.push(res.data.userProfileDataTypes.shiftType[i].name);
          }
          setShiftDataForShift(arr);
        }
        if (res.data.userProfileDataTypes.payrollType.length > 0) {
          let arr = [];
          for (
            let i = 0;
            i < res.data.userProfileDataTypes.payrollType.length;
            i++
          ) {
            arr.push(res.data.userProfileDataTypes.payrollType[i].name);
          }
          setPayrollDataForShift(arr);
        }
      } else {
      }
    });
  };

  const getApplicantsData = (params) => {
    props.getAllApplicants(params, (res) => {
      if (res.status) {
        setDataLoading(false)
        console.log("APPLICANTS", res.data);
        if (res.data.status == 500) {
          toast.error("Internal Server Error");
          return;
        }
        setApplicantsData(res.data);
        if (res.data.length > 0) {
          let division = res.data.length / 2;
          let colA_Array = [];
          let colB_Array = [];
          for (let i = 0; i < Math.ceil(division); i++) {
            colA_Array.push(res.data[i]);
          }
          setApplicantsDataColA(colA_Array);
          for (let i = Math.ceil(division); i < res.data.length; i++) {
            colB_Array.push(res.data[i]);
          }
          setApplicantsDataColB(colB_Array);
        }
      } else {
        setDataLoading(false)
        toast.error("Something went wrong");
      }
    });
  };

  const getShortlistedData = (params) => {
    props.getShortlisted(params, (res) => {
      if (res.status) {
        console.log("SHORTLIST", res.data);
        setShortlistedData(res.data);
        setModalLoading(false);
        setModal(initModal);
        if (res.data.length > 0) {
          let division = res.data.length / 2;
          let colA_Array = [];
          let colB_Array = [];
          for (let i = 0; i < Math.ceil(division); i++) {
            colA_Array.push(res.data[i]);
          }
          setShortlistedDataColA(colA_Array);
          for (let i = Math.ceil(division); i < res.data.length; i++) {
            colB_Array.push(res.data[i]);
          }
          setShortlistedDataColB(colB_Array);
        }
      }
    });
  };

  const getFollowedData = (params) => {
    props.getFollowed(params, (res) => {
      if (res.status) {
        console.log("FOLLOW", res.data);
        setFollowedData(res.data);
        setModalLoading(false);
        setModal(initModal);
        if (res.data.length > 0) {
          let division = res.data.length / 2;
          let colA_Array = [];
          let colB_Array = [];
          for (let i = 0; i < Math.ceil(division); i++) {
            colA_Array.push(res.data[i]);
          }
          setFollowedDataColA(colA_Array);
          for (let i = Math.ceil(division); i < res.data.length; i++) {
            colB_Array.push(res.data[i]);
          }
          setFollowedDataColB(colB_Array);
        }
      }
    });
  };

  const getInvitedData = (params) => {
    props.getInvited(params, (res) => {
      if (res.status) {
        console.log("INVITE", res.data);
        setInvitedData(res.data);
        setModalLoading(false);
        setModal(initModal);
        if (res.data.length > 0) {
          let division = res.data.length / 2;
          let colA_Array = [];
          let colB_Array = [];
          for (let i = 0; i < Math.ceil(division); i++) {
            colA_Array.push(res.data[i]);
          }
          setInvitedDataColA(colA_Array);
          for (let i = Math.ceil(division); i < res.data.length; i++) {
            colB_Array.push(res.data[i]);
          }
          setInvitedDataColB(colB_Array);
        }
      }
    });
  };

  const columnClicked = (flag) => {
    if (flag == "jobInformation") {
      setJobInformationFlag(true);
      setJobSettingFlag(false);
      setWeeklyCalendarFlag(false);
      setDailyCalendarFlag(false);
      setAboutEmployerFlag(false);
    } else if (flag == "jobSetting") {
      setJobInformationFlag(false);
      setJobSettingFlag(true);
      setWeeklyCalendarFlag(false);
      setDailyCalendarFlag(false);
      setAboutEmployerFlag(false);
    } else if (flag == "weeklyCalendar") {
      setJobInformationFlag(false);
      setJobSettingFlag(false);
      setWeeklyCalendarFlag(true);
      setDailyCalendarFlag(false);
      setAboutEmployerFlag(false);
    } else if (flag == "dailyCalendar") {
      setJobInformationFlag(false);
      setJobSettingFlag(false);
      setWeeklyCalendarFlag(false);
      setDailyCalendarFlag(true);
      setAboutEmployerFlag(false);
    } else if (flag == "aboutEmployer") {
      setJobInformationFlag(false);
      setJobSettingFlag(false);
      setWeeklyCalendarFlag(false);
      setDailyCalendarFlag(false);
      setAboutEmployerFlag(true);
    }
  };

  const jobInformationStyleActive = () => {
    return {
      border: "1px solid #3abd00",
      borderRadius: "5px",
      color: "#3abd00",
    };
  };
  const jobInformationStyleInActive = () => {
    return {
      border: "0px",
      borderRadius: "0px",
      color: "black",
    };
  };
  const jobSettingStyleActive = () => {
    return {
      border: "1px solid #3abd00",
      borderRadius: "5px",
      color: "#3abd00",
    };
  };
  const jobSettingStyleInActive = () => {
    return {
      border: "0px",
      borderRadius: "0px",
      color: "black",
    };
  };
  const weeklyCalendarStyleActive = () => {
    return {
      border: "1px solid #3abd00",
      borderRadius: "5px",
      color: "#3abd00",
    };
  };
  const weeklyCalendarStyleInActive = () => {
    return {
      border: "0px",
      borderRadius: "0px",
      color: "black",
    };
  };
  const dailyCalendarStyleActive = () => {
    return {
      border: "1px solid #3abd00",
      borderRadius: "5px",
      color: "#3abd00",
    };
  };
  const dailyCalendarStyleInActive = () => {
    return {
      border: "0px",
      borderRadius: "0px",
      color: "black",
    };
  };
  const aboutEmployerStyleActive = () => {
    return {
      border: "1px solid #3abd00",
      borderRadius: "5px",
      color: "#3abd00",
    };
  };
  const aboutEmployerStyleInActive = () => {
    return {
      border: "0px",
      borderRadius: "0px",
      color: "black",
    };
  };

  const deactivateShortlist = () => {
    setModalLoading(true);
    props.removeShortlist({ shortlistId: modal.data.id }, (res) => {
      if (res.status) {
        toast.success("Removed shortlisted data successfully");
        getShortlistedData({ userProfileId: props.match.params.talentId });
      } else {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong");
      }
    });
  };

  const unfollow = () => {
    setModalLoading(true);
    props.removeFollow({ followId: modal.data.id }, (res) => {
      if (res.status) {
        toast.success("Removed followed data successfully");
        getFollowedData({ userProfileId: props.match.params.talentId });
      } else {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong");
      }
    });
  };

  const uninvite = () => {
    setModalLoading(true);
    props.removeInvite({ inviteId: modal.data.id }, (res) => {
      if (res.status) {
        toast.success("Removed invited data successfully");
        getInvitedData({ userProfileId: props.match.params.talentId });
      } else {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong");
      }
    });
  };

  const onClickAction = () => {
    if (modal.type === "shortlist") {
      deactivateShortlist();
    } else if (modal.type === "followed list") {
      unfollow();
    } else if (modal.type === "invited list") {
      uninvite();
    }
  };

  const acceptCandidateProfile = (e, value) => {
    e.preventDefault()
    swal({
        text: `Are you sure, you want to accept ${value.userProfileData?.selectedUserData?.firstName} ${value.userProfileData?.selectedUserData?.lastName}'s profile?`,
        icon: "warning",
        buttons: true
    }).then((ok) => {
        if(ok) {
            setDataLoading(true)
            props.accept({userIdHash: props.auth.userProfile.userIdHash, applyIdHash: value.userProfileData?.applyData?.applyIdHash}, res => {
                if(res.status) {
                    swal({
                        text: "Profile accepted successfully!",
                        icon: "success"
                    }).then(() => {
                        setDataLoading(true)
                        getApplicantsData({ userProfileId: props.match.params.talentId });
                    })
                } else {
                    setDataLoading(false)
                    toast.error('Something went wrong!')
                }
            })
        }
    })
  }
  
  const rejectCandidateProfile = (e, value) => {
    e.preventDefault()
    swal({
        text: `Are you sure, you want to reject ${value.userProfileData?.selectedUserData?.firstName} ${value.userProfileData?.selectedUserData?.lastName}'s profile?`,
        icon: "warning",
        buttons: true
    }).then((ok) => {
        if(ok) {
            setDataLoading(true)
            props.accept({userIdHash: props.auth.userProfile.userIdHash, applyIdHash: value.userProfileData?.applyData?.applyIdHash}, res => {
                if(res.status) {
                    swal({
                        text: "Profile rejected successfully!",
                        icon: "success"
                    }).then(() => {
                        setDataLoading(true)
                        getApplicantsData({ userProfileId: props.match.params.talentId });
                    })
                } else {
                    setDataLoading(false)
                    toast.error('Something went wrong!')
                }
            })
        }
    })
  }

  return (
    <>
      <div
        className="content d-flex flex-column flex-column-fluid pt-5 pb-0"
        id="kt_content"
      >
        <div className="d-flex flex-column-fluid">
          <div className="custom-container" style={{ height: "auto" }}>
            <div className="row  pb-5">
              <div className="col-lg-6 col-md-6">
                <div className="inner_txecy">
                  <h4>Candidates</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                {/* <div className="talen_outre">
                                    <button className="btn" data-toggle="modal" data-target="#myModal12">+ CREATE TALENT</button>
                                </div> */}
              </div>
            </div>
            <div className="row my-5">
              <div className="col-12 m-auto">
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    state?.fromDashboard
                      ? history.goBack()
                      : history.push(`/post`)
                  }
                >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <div className="Profile_form">
              {loading ? (
                <>
                  <div
                    className="row"
                    style={{ width: "100%", height: "30vh" }}
                  >
                    <div
                      className="col-12"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "center",
                          verticalAlign: "middle",
                          lineHeight: "50vh",
                        }}
                      >
                        <div
                          className="spinner-grow"
                          role="status"
                          style={{ background: "#006838" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row mt-3 mb-3" style={{ padding: "0px" }}>
                    <div className="col-12">
                      <div className="talent_details_shift">
                        <a
                          className={
                            filterShow
                              ? "panel-heading heading_links_ accordion_title_info_ d-flex justify-content-between"
                              : "panel-heading heading_links_ accordion_title_info_ collapsed d-flex justify-content-between"
                          }
                          onClick={() => setFilterShow(!filterShow)}
                          style={{ cursor: "pointer" }}
                        >
                          <span style={{ fontWeight: "600", color: "#3abd00" }}>
                            Talent Profile Id :{" "}
                            <span style={{ color: "black" }}>
                              {talentProfileData.id}
                            </span>
                          </span>
                          <img src={downArrow} />
                        </a>
                        <div
                          className={filterShow ? "collapse show" : "collapse"}
                          id="acceptance"
                        >
                          <div
                            className="card border-0 pt-2"
                            style={{
                              "max-height": "200px",
                              overflow: "auto",
                            }}
                          >
                            <div
                              className="card-body"
                              style={{ paddingTop: "0px" }}
                            >
                              <table
                                className="table"
                                style={{ width: "100%" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      className="table-shift-td-1"
                                      style={{ border: "none" }}
                                    >
                                      Business Name
                                    </td>
                                    <td
                                      className="table-shift-td-2"
                                      style={{ border: "none" }}
                                    >
                                      {talentProfileData.entityName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-shift-td-1">
                                      Location
                                    </td>
                                    <td className="table-shift-td-2">
                                      {talentProfileData.address1
                                        ? talentProfileData.address1
                                        : talentProfileData.address}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-shift-td-1">
                                      Employment Type
                                    </td>
                                    <td className="table-shift-td-2">
                                      {employmentDataForShift.length > 0
                                        ? employmentDataForShift.join(", ")
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-shift-td-1">
                                      Facility Type
                                    </td>
                                    <td className="table-shift-td-2">
                                      {facilityDataForShift.length > 0
                                        ? facilityDataForShift.join(", ")
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-shift-td-1">
                                      Payment Type
                                    </td>
                                    <td className="table-shift-td-2">
                                      {paymentDataForShift.length > 0
                                        ? paymentDataForShift.join(", ")
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-shift-td-1">
                                      Payroll Type
                                    </td>
                                    <td className="table-shift-td-2">
                                      {payrollDataForShift.length > 0
                                        ? payrollDataForShift.join(", ")
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-shift-td-1">
                                      Schedule Type
                                    </td>
                                    <td className="table-shift-td-2">
                                      {scheduleDataForShift.length > 0
                                        ? scheduleDataForShift.join(", ")
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-shift-td-1">
                                      Shift Type
                                    </td>
                                    <td className="table-shift-td-2">
                                      {shiftDataForShift.length > 0
                                        ? shiftDataForShift.join(", ")
                                        : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row no-gutters text-center"
                    style={{ marginTop: "40px", marginBottom: "40px" }}
                  >
                    <div
                      className="col jobInformation"
                      style={
                        jobInformationFlag
                          ? jobInformationStyleActive()
                          : jobInformationStyleInActive()
                      }
                      onClick={() => columnClicked("jobInformation")}
                    >
                      Applicants
                    </div>
                    <div
                      className="col jobSetting"
                      style={
                        jobSettingFlag
                          ? jobSettingStyleActive()
                          : jobSettingStyleInActive()
                      }
                      onClick={() => columnClicked("jobSetting")}
                    >
                      Shortlisted
                    </div>
                    <div
                      className="col weeklyCalendar"
                      style={
                        weeklyCalendarFlag
                          ? weeklyCalendarStyleActive()
                          : weeklyCalendarStyleInActive()
                      }
                      onClick={() => columnClicked("weeklyCalendar")}
                    >
                      Following
                    </div>
                    <div
                      className="col dailyCalendar"
                      style={
                        dailyCalendarFlag
                          ? dailyCalendarStyleActive()
                          : dailyCalendarStyleInActive()
                      }
                      onClick={() => columnClicked("dailyCalendar")}
                    >
                      Invited
                    </div>
                    {/* <div className="col aboutEmp" style={aboutEmployerFlag ? aboutEmployerStyleActive() : aboutEmployerStyleInActive()} onClick={() => columnClicked('aboutEmployer')}>
                                            ABOUT EMPLOYER
                                        </div> */}
                  </div>

                  <div className="card" style={{border:'0px'}}>
                    <div className="card-body" style={{padding:'0px'}}>
                      {jobInformationFlag ? (
                        <>
                          {applicantsData.length > 0 ? (
                            <>
                              {dataLoading ? (
                                <>
                                  <div
                                    className="row"
                                    style={{ width: "100%", height: "30vh" }}
                                  >
                                    <div
                                      className="col-12"
                                      style={{ width: "100%", height: "100%" }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          lineHeight: "30vh",
                                        }}
                                      >
                                        <div
                                          className="spinner-grow"
                                          role="status"
                                          style={{ background: "#006838" }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="Profile_form"
                                    style={{ background: "#f7f7f7" }}
                                  >
                                    <div className="row">
                                      <div
                                        className="col-md-6"
                                        style={{ paddingLeft: "22px" }}
                                      >
                                        <div className="row">
                                          {applicantsDataColA.map(
                                            (value, index) => {
                                              return (
                                                <>
                                                  <div className="col-12 my-3">
                                                    <div className="blush_outer">
                                                      <div className="edit_outer">
                                                        <div>
                                                          <div
                                                            className=""
                                                            style={{
                                                              height: "35px",
                                                              width: "60px",
                                                              background:
                                                                "#3abd00",
                                                              textAlign:
                                                                "center",
                                                              fontSize: "14px",
                                                              paddingTop: "8px",
                                                              fontWeight: "600",
                                                              color: "white",
                                                            }}
                                                          >
                                                            {value.minHourlyPayment ==
                                                            null
                                                              ? "$ -- / hr"
                                                              : `$ ${value.minHourlyPayment} / hr`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="geoge_outer">
                                                        <div className="custom789 circleName">
                                                          {value.userProfileData
                                                            ? value
                                                                .userProfileData
                                                                .selectedEntityTypeData
                                                                .entityTypeNameAbbreviation
                                                            : ""}
                                                        </div>
                                                        <img
                                                          src={
                                                            value
                                                              .userProfileData
                                                              .selectedUserData
                                                              .isVerified
                                                              ? verified
                                                              : unVerified
                                                          }
                                                          alt="verify"
                                                          style={{
                                                            position:
                                                              "absolute",
                                                            top: "55px",
                                                            left: "55px",
                                                          }}
                                                        />
                                                        <div className="google_inner">
                                                          <div className="d-flex justify-content-between align-items-center">
                                                            <h3>
                                                              {value
                                                                .userProfileData
                                                                .selectedUserData
                                                                .firstName ==
                                                                "" &&
                                                              value
                                                                .userProfileData
                                                                .selectedUserData
                                                                .lastName == ""
                                                                ? "--"
                                                                : value
                                                                    .userProfileData
                                                                    .selectedUserData
                                                                    .firstName +
                                                                  " " +
                                                                  value
                                                                    .userProfileData
                                                                    .selectedUserData
                                                                    .lastName}
                                                            </h3>
                                                            <img
                                                              src={showBadge(
                                                                value
                                                                  .userProfileData
                                                                  .selectedUserDataRating
                                                              )}
                                                              alt="badge"
                                                              className="mr-6 badgeClass"
                                                              style={{
                                                                position:
                                                                  "relative",
                                                                top: "9px",
                                                                right: "30px",
                                                              }}
                                                            />
                                                          </div>
                                                          {/* <h5>{value.userProfileDataTypes.Profession}</h5> */}
                                                          <p
                                                            className="custom_png"
                                                            style={{
                                                              color:
                                                                "deepskyblue",
                                                            }}
                                                          >
                                                            #
                                                            {
                                                              value
                                                                .userProfileData
                                                                .selectedUserData
                                                                .userNameHash
                                                            }{" "}
                                                            {value
                                                              .userProfileData
                                                              .selectedUserData
                                                              .userName ? (
                                                              <>
                                                                / @
                                                                {
                                                                  value
                                                                    .userProfileData
                                                                    .selectedUserData
                                                                    .userName
                                                                }
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </p>
                                                          <p className="custom_png">
                                                            <img
                                                              className="custom78"
                                                              src={locationImg}
                                                              alt=""
                                                            />
                                                            {
                                                              value
                                                                .userProfileData
                                                                .selectedUserData
                                                                .city
                                                            }
                                                            ,{" "}
                                                            {
                                                              value
                                                                .userProfileData
                                                                .selectedUserData
                                                                .country
                                                            }
                                                          </p>
                                                        </div>

                                                        <div className="row cust_spc pt-0">
                                                          <div className="col-sm-12 col-lg-6">
                                                            <div className="date_container d-flex">
                                                              <span className="job_infoHeading_ me-25">
                                                                Job Id :
                                                              </span>
                                                              <p className="details_value_ ml-3">
                                                                {" "}
                                                                {
                                                                  value
                                                                    .userProfileData
                                                                    .selectedEntityData
                                                                    .id
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="row cust_spc pt-0">
                                                          <div className="col-sm-12 col-lg-6">
                                                            <div className="date_container">
                                                              <img
                                                                src={
                                                                  newCalender
                                                                }
                                                                alt="calender"
                                                              />
                                                              <span className="date_ talent_id ml-2">
                                                                {getFormatedDate(
                                                                  value
                                                                    .userProfileData
                                                                    .selectedEntityData
                                                                    .createdDate,
                                                                  "MMM DD, YYYY"
                                                                )}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="row cust_spc pt-0">
                                                          <div className="col-sm-12 col-lg-6">
                                                            <div className="date_container d-flex">
                                                              <span className="job_infoHeading_ me-25">
                                                                Profession :
                                                              </span>
                                                              <p className="details_value_ ml-3">
                                                                {" "}
                                                                {
                                                                  value
                                                                    .userProfileData
                                                                    .selectedEntityTypeData
                                                                    .profession
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="row cust_spc pt-0">
                                                          <div className="col-sm-12 col-lg-12">
                                                            <div className="info_container_">
                                                              <ul className="job_info_list_">
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Employment
                                                                    Type :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .employmentType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .employmentType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Payment Type
                                                                    :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .paymentType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .paymentType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Payroll Type
                                                                    :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .payrollType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .payrollType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Schedule
                                                                    Type :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .scheduleType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .scheduleType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Facility
                                                                    Type :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .facilityType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .facilityType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Shift Type :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .shiftType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .shiftType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Location :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value.city}
                                                                    ,{" "}
                                                                    {
                                                                      value.state
                                                                    }
                                                                    ,{" "}
                                                                    {
                                                                      value.country
                                                                    }
                                                                  </p>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="button_list">
                                                        <a
                                                          className="about_btn"
                                                          style={{
                                                            color: "white",
                                                            cursor: "pointer",
                                                            // borderLeft:
                                                            //   "1px solid gainsboro",
                                                            // borderBottom:
                                                            //   "1px solid gainsboro",
                                                            borderRight:
                                                              "1px solid gainsboro",
                                                            color: "black",
                                                          }}
                                                          onClick={(e) =>
                                                            rejectCandidateProfile(
                                                              e,
                                                              value
                                                            )
                                                          }
                                                        >
                                                          Reject
                                                        </a>
                                                        <a
                                                          className="about_btn"
                                                          style={{
                                                            color: "white",
                                                            cursor: "pointer",
                                                            // borderBottom:
                                                            //   "1px solid gainsboro",
                                                            borderRight:
                                                              "1px solid gainsboro",
                                                            color:
                                                              "rgb(58, 189, 0)",
                                                          }}
                                                          onClick={(e) =>
                                                            acceptCandidateProfile(
                                                              e,
                                                              value
                                                            )
                                                          }
                                                        >
                                                          Accept
                                                        </a>
                                                        <a
                                                          className="about_btn shift_outer4"
                                                          style={{
                                                            cursor: "pointer",
                                                            borderRadius: "0px",
                                                            borderRight:
                                                              "0px solid gainsboro",
                                                          }}
                                                        >
                                                          Candidate Detail
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        className="col-md-6"
                                        style={{ paddingRight: "22px" }}
                                      >
                                        <div className="row">
                                          {applicantsDataColB.map(
                                            (value, index) => {
                                              return (
                                                <>
                                                  <div className="col-12 my-3">
                                                    <div className="blush_outer">
                                                      <div className="edit_outer">
                                                        <div>
                                                          <div
                                                            className=""
                                                            style={{
                                                              height: "35px",
                                                              width: "60px",
                                                              background:
                                                                "#3abd00",
                                                              textAlign:
                                                                "center",
                                                              fontSize: "14px",
                                                              paddingTop: "8px",
                                                              fontWeight: "600",
                                                              color: "white",
                                                            }}
                                                          >
                                                            {value.minHourlyPayment ==
                                                            null
                                                              ? "$ -- / hr"
                                                              : `$ ${value.minHourlyPayment} / hr`}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="geoge_outer">
                                                        <div className="custom789 circleName">
                                                          {value.userProfileData
                                                            ? value
                                                                .userProfileData
                                                                .selectedEntityTypeData
                                                                .entityTypeNameAbbreviation
                                                            : ""}
                                                        </div>
                                                        <img
                                                          src={
                                                            value
                                                              .userProfileData
                                                              .selectedUserData
                                                              .isVerified
                                                              ? verified
                                                              : unVerified
                                                          }
                                                          alt="verify"
                                                          style={{
                                                            position:
                                                              "absolute",
                                                            top: "55px",
                                                            left: "55px",
                                                          }}
                                                        />
                                                        <div className="google_inner">
                                                          <div className="d-flex justify-content-between align-items-center">
                                                            <h3>
                                                              {value
                                                                .userProfileData
                                                                .selectedUserData
                                                                .firstName ==
                                                                "" &&
                                                              value
                                                                .userProfileData
                                                                .selectedUserData
                                                                .lastName == ""
                                                                ? "--"
                                                                : value
                                                                    .userProfileData
                                                                    .selectedUserData
                                                                    .firstName +
                                                                  " " +
                                                                  value
                                                                    .userProfileData
                                                                    .selectedUserData
                                                                    .lastName}
                                                            </h3>
                                                            <img
                                                              src={showBadge(
                                                                value
                                                                  .userProfileData
                                                                  .selectedUserDataRating
                                                              )}
                                                              alt="badge"
                                                              className="mr-6 badgeClass"
                                                              style={{
                                                                position:
                                                                  "relative",
                                                                top: "9px",
                                                                right: "30px",
                                                              }}
                                                            />
                                                          </div>
                                                          {/* <h5>{value.userProfileDataTypes.Profession}</h5> */}
                                                          <p
                                                            className="custom_png"
                                                            style={{
                                                              color:
                                                                "deepskyblue",
                                                            }}
                                                          >
                                                            #
                                                            {
                                                              value
                                                                .userProfileData
                                                                .selectedUserData
                                                                .userNameHash
                                                            }{" "}
                                                            {value
                                                              .userProfileData
                                                              .selectedUserData
                                                              .userName ? (
                                                              <>
                                                                / @
                                                                {
                                                                  value
                                                                    .userProfileData
                                                                    .selectedUserData
                                                                    .userName
                                                                }
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </p>
                                                          <p className="custom_png">
                                                            <img
                                                              className="custom78"
                                                              src={locationImg}
                                                              alt=""
                                                            />
                                                            {
                                                              value
                                                                .userProfileData
                                                                .selectedUserData
                                                                .city
                                                            }
                                                            ,{" "}
                                                            {
                                                              value
                                                                .userProfileData
                                                                .selectedUserData
                                                                .country
                                                            }
                                                          </p>
                                                        </div>

                                                        <div className="row cust_spc pt-0">
                                                          <div className="col-sm-12 col-lg-6">
                                                            <div className="date_container d-flex">
                                                              <span className="job_infoHeading_ me-25">
                                                                Job Id :
                                                              </span>
                                                              <p className="details_value_ ml-3">
                                                                {" "}
                                                                {
                                                                  value
                                                                    .userProfileData
                                                                    .selectedEntityData
                                                                    .id
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="row cust_spc pt-0">
                                                          <div className="col-sm-12 col-lg-6">
                                                            <div className="date_container">
                                                              <img
                                                                src={
                                                                  newCalender
                                                                }
                                                                alt="calender"
                                                              />
                                                              <span className="date_ talent_id ml-2">
                                                                {getFormatedDate(
                                                                  value
                                                                    .userProfileData
                                                                    .selectedEntityData
                                                                    .createdDate,
                                                                  "MMM DD, YYYY"
                                                                )}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="row cust_spc pt-0">
                                                          <div className="col-sm-12 col-lg-6">
                                                            <div className="date_container d-flex">
                                                              <span className="job_infoHeading_ me-25">
                                                                Profession :
                                                              </span>
                                                              <p className="details_value_ ml-3">
                                                                {" "}
                                                                {
                                                                  value
                                                                    .userProfileData
                                                                    .selectedEntityTypeData
                                                                    .profession
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="row cust_spc pt-0">
                                                          <div className="col-sm-12 col-lg-12">
                                                            <div className="info_container_">
                                                              <ul className="job_info_list_">
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Employment
                                                                    Type :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .employmentType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .employmentType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Payment Type
                                                                    :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .paymentType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .paymentType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Payroll Type
                                                                    :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .payrollType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .payrollType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Schedule
                                                                    Type :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .scheduleType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .scheduleType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Facility
                                                                    Type :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .facilityType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .facilityType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Shift Type :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value
                                                                      .userProfileData
                                                                      .shiftType
                                                                      ?.length
                                                                      ? Array.from(
                                                                          value
                                                                            .userProfileData
                                                                            .shiftType,
                                                                          (x) =>
                                                                            x.name
                                                                        ).join(
                                                                          ", "
                                                                        )
                                                                      : "N/A"}
                                                                  </p>
                                                                </li>
                                                                <li className="mb-3">
                                                                  <span className="job_infoHeading_ me-25">
                                                                    Location :
                                                                  </span>
                                                                  <p className="details_value_">
                                                                    {" "}
                                                                    {value.city}
                                                                    ,{" "}
                                                                    {
                                                                      value.state
                                                                    }
                                                                    ,{" "}
                                                                    {
                                                                      value.country
                                                                    }
                                                                  </p>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="button_list">
                                                        <a
                                                          className="about_btn"
                                                          style={{
                                                            color: "white",
                                                            cursor: "pointer",
                                                            // borderLeft:
                                                            //   "1px solid gainsboro",
                                                            // borderBottom:
                                                            //   "1px solid gainsboro",
                                                            borderRight:
                                                              "1px solid gainsboro",
                                                            color: "black",
                                                          }}
                                                          onClick={(e) =>
                                                            rejectCandidateProfile(
                                                              e,
                                                              value
                                                            )
                                                          }
                                                        >
                                                          Reject
                                                        </a>
                                                        <a
                                                          className="about_btn"
                                                          style={{
                                                            color: "white",
                                                            cursor: "pointer",
                                                            // borderBottom:
                                                            //   "1px solid gainsboro",
                                                            borderRight:
                                                              "1px solid gainsboro",
                                                            color:
                                                              "rgb(58, 189, 0)",
                                                          }}
                                                          onClick={(e) =>
                                                            acceptCandidateProfile(
                                                              e,
                                                              value
                                                            )
                                                          }
                                                        >
                                                          Accept
                                                        </a>
                                                        <a
                                                          className="about_btn shift_outer4"
                                                          style={{
                                                            cursor: "pointer",
                                                            borderRadius: "0px",
                                                            borderRight:
                                                              "0px solid gainsboro",
                                                          }}
                                                        >
                                                          Candidate Detail
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="p-5 text-center">No Data</div>
                            </>
                          )}
                        </>
                      ) : jobSettingFlag ? (
                        <>
                          {shortlistedData.length > 0 ? (
                            <>
                              <div
                                className="Profile_form"
                                style={{ background: "#f7f7f7" }}
                              >
                                <div className="row">
                                  <div
                                    className="col-md-6"
                                    style={{ paddingLeft: "22px" }}
                                  >
                                    <div className="row">
                                      {shortlistedDataColA.map(
                                        (value, index) => {
                                          return (
                                            <>
                                              <div className="col-12 my-3">
                                                <div className="blush_outer">
                                                  <div className="edit_outer">
                                                    <div>
                                                      <div
                                                        className=""
                                                        style={{
                                                          height: "35px",
                                                          width: "60px",
                                                          background: "#3abd00",
                                                          textAlign: "center",
                                                          fontSize: "14px",
                                                          paddingTop: "8px",
                                                          fontWeight: "600",
                                                          color: "white",
                                                        }}
                                                      >
                                                        {value.userProfileData
                                                          .minHourlyPayment ==
                                                        null
                                                          ? "$ -- / hr"
                                                          : `$ ${value.userProfileData.minHourlyPayment} / hr`}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="geoge_outer">
                                                    <div className="custom789 circleName">
                                                      {value.userProfileData
                                                        ? value.userProfileData
                                                            .entityTypeNameAbbreviation
                                                        : ""}
                                                    </div>
                                                    <img
                                                      src={
                                                        value.userProfileRating.verified === 1
                                                          ? verified
                                                          : unVerified
                                                      }
                                                      alt="verify"
                                                      style={{
                                                        position: "absolute",
                                                        top: "55px",
                                                        left: "55px",
                                                      }}
                                                    />
                                                    <div className="google_inner">
                                                      <div className="d-flex justify-content-between align-items-center">
                                                        <h3>
                                                          {value.userProfileData
                                                            .firstName == "" &&
                                                          value.userProfileData
                                                            .lastName == ""
                                                            ? "--"
                                                            : value
                                                                .userProfileData
                                                                .firstName +
                                                              " " +
                                                              value
                                                                .userProfileData
                                                                .lastName}
                                                        </h3>
                                                        <img
                                                          src={showBadge(
                                                            value.userProfileRating
                                                          )}
                                                          alt="badge"
                                                          className="mr-6 badgeClass"
                                                          style={{
                                                            position:
                                                              "relative",
                                                            top: "9px",
                                                            right: "30px",
                                                          }}
                                                        />
                                                      </div>
                                                      {/* <h5>{value.userProfileDataTypes.Profession}</h5> */}
                                                      <p
                                                        className="custom_png"
                                                        style={{
                                                          color: "deepskyblue",
                                                        }}
                                                      >
                                                        #
                                                        {
                                                          value.userProfileData
                                                            .userNameHash
                                                        }{" "}
                                                        {value.userProfileData
                                                          .userName ? (
                                                          <>
                                                            / @
                                                            {
                                                              value
                                                                .userProfileData
                                                                .userName
                                                            }
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </p>
                                                      <p className="custom_png">
                                                        <img
                                                          className="custom78"
                                                          src={locationImg}
                                                          alt=""
                                                        />
                                                        {
                                                          value.userProfileData
                                                            .city
                                                        }
                                                        ,{" "}
                                                        {
                                                          value.userProfileData
                                                            .country
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="button_list">
                                                    <a
                                                      className="about_btn"
                                                      style={{
                                                        color: "white",
                                                        cursor: "pointer",
                                                        // borderLeft:
                                                        //   "1px solid gainsboro",
                                                        // borderBottom:
                                                        //   "1px solid gainsboro",
                                                        borderRight:
                                                          "1px solid gainsboro",
                                                        color:
                                                          "rgb(58, 189, 0)",
                                                      }}
                                                      onClick={() => {
                                                        setModal({
                                                          modal: !modal.modal,
                                                          type: "shortlist",
                                                          data: value,
                                                        });
                                                      }}
                                                    >
                                                      Remove
                                                    </a>
                                                    <a
                                                      className="about_btn shift_outer4"
                                                      style={{
                                                        cursor: "pointer",
                                                        borderRadius: "0px",
                                                        borderRight:
                                                          "0px solid gainsboro",
                                                      }}
                                                    >
                                                      Candidate Detail
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-6"
                                    style={{ paddingRight: "22px" }}
                                  >
                                    <div className="row">
                                      {shortlistedDataColB.map(
                                        (value, index) => {
                                          return (
                                            <>
                                              <div className="col-12 my-3">
                                                <div className="blush_outer">
                                                  <div className="edit_outer">
                                                    <div>
                                                      <div
                                                        className=""
                                                        style={{
                                                          height: "35px",
                                                          width: "60px",
                                                          background: "#3abd00",
                                                          textAlign: "center",
                                                          fontSize: "14px",
                                                          paddingTop: "8px",
                                                          fontWeight: "600",
                                                          color: "white",
                                                        }}
                                                      >
                                                        {value.userProfileData
                                                          .minHourlyPayment ==
                                                        null
                                                          ? "$ -- / hr"
                                                          : `$ ${value.userProfileData.minHourlyPayment} / hr`}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="geoge_outer">
                                                    <div className="custom789 circleName">
                                                      {value.userProfileData
                                                        ? value.userProfileData
                                                            .entityTypeNameAbbreviation
                                                        : ""}
                                                    </div>
                                                    <img
                                                      src={
                                                        value.userProfileRating.verified === 1
                                                          ? verified
                                                          : unVerified
                                                      }
                                                      alt="verify"
                                                      style={{
                                                        position: "absolute",
                                                        top: "55px",
                                                        left: "55px",
                                                      }}
                                                    />
                                                    <div className="google_inner">
                                                      <div className="d-flex justify-content-between align-items-center">
                                                        <h3>
                                                          {value.userProfileData
                                                            .firstName == "" &&
                                                          value.userProfileData
                                                            .lastName == ""
                                                            ? "--"
                                                            : value
                                                                .userProfileData
                                                                .firstName +
                                                              " " +
                                                              value
                                                                .userProfileData
                                                                .lastName}
                                                        </h3>
                                                        <img
                                                          src={showBadge(
                                                            value.userProfileRating
                                                          )}
                                                          alt="badge"
                                                          className="mr-6 badgeClass"
                                                          style={{
                                                            position:
                                                              "relative",
                                                            top: "9px",
                                                            right: "30px",
                                                          }}
                                                        />
                                                      </div>
                                                      {/* <h5>{value.userProfileDataTypes.Profession}</h5> */}
                                                      <p
                                                        className="custom_png"
                                                        style={{
                                                          color: "deepskyblue",
                                                        }}
                                                      >
                                                        #
                                                        {
                                                          value.userProfileData
                                                            .userNameHash
                                                        }{" "}
                                                        {value.userProfileData
                                                          .userName ? (
                                                          <>
                                                            / @
                                                            {
                                                              value
                                                                .userProfileData
                                                                .userName
                                                            }
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </p>
                                                      <p className="custom_png">
                                                        <img
                                                          className="custom78"
                                                          src={locationImg}
                                                          alt=""
                                                        />
                                                        {
                                                          value.userProfileData
                                                            .city
                                                        }
                                                        ,{" "}
                                                        {
                                                          value.userProfileData
                                                            .country
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>

                                                  <div className="button_list">
                                                    <a
                                                      className="about_btn"
                                                      style={{
                                                        color: "white",
                                                        cursor: "pointer",
                                                        // borderLeft:
                                                        //   "1px solid gainsboro",
                                                        // borderBottom:
                                                        //   "1px solid gainsboro",
                                                        borderRight:
                                                          "1px solid gainsboro",
                                                        color:
                                                          "rgb(58, 189, 0)",
                                                      }}
                                                      onClick={() => {
                                                        setModal({
                                                          modal: !modal.modal,
                                                          type: "shortlist",
                                                          data: value,
                                                        });
                                                      }}
                                                    >
                                                      Remove
                                                    </a>
                                                    <a
                                                      className="about_btn shift_outer4"
                                                      style={{
                                                        cursor: "pointer",
                                                        borderRadius: "0px",
                                                        borderRight:
                                                          "0px solid gainsboro",
                                                      }}
                                                    >
                                                      Candidate Detail
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="p-5 text-center">No Data</div>
                            </>
                          )}
                        </>
                      ) : weeklyCalendarFlag ? (
                        <>
                          {followedData.length > 0 ? (
                            <>
                              <div
                                className="Profile_form"
                                style={{ background: "#f7f7f7" }}
                              >
                                <div className="row">
                                  <div
                                    className="col-md-6"
                                    style={{ paddingLeft: "22px" }}
                                  >
                                    <div className="row">
                                      {followedDataColA.map((value, index) => {
                                        return (
                                          <>
                                            <div className="col-12 my-3">
                                              <div className="blush_outer">
                                                <div className="edit_outer">
                                                  <div>
                                                    <div
                                                      className=""
                                                      style={{
                                                        height: "35px",
                                                        width: "60px",
                                                        background: "#3abd00",
                                                        textAlign: "center",
                                                        fontSize: "14px",
                                                        paddingTop: "8px",
                                                        fontWeight: "600",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {value.userProfileData
                                                        .minHourlyPayment ==
                                                      null
                                                        ? "$ -- / hr"
                                                        : `$ ${value.userProfileData.minHourlyPayment} / hr`}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="geoge_outer">
                                                  <div className="custom789 circleName">
                                                    {value.userProfileData
                                                      ? value.userProfileData
                                                          .entityTypeNameAbbreviation
                                                      : ""}
                                                  </div>
                                                  <img
                                                    src={
                                                      value.userProfileRating?.verified === 1
                                                        ? verified
                                                        : unVerified
                                                    }
                                                    alt="verify"
                                                    style={{
                                                      position: "absolute",
                                                      top: "55px",
                                                      left: "55px",
                                                    }}
                                                  />
                                                  <div className="google_inner">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                      <h3>
                                                        {value.userProfileData
                                                          .firstName == "" &&
                                                        value.userProfileData
                                                          .lastName == ""
                                                          ? "--"
                                                          : value
                                                              .userProfileData
                                                              .firstName +
                                                            " " +
                                                            value
                                                              .userProfileData
                                                              .lastName}
                                                      </h3>
                                                      <img
                                                        src={showBadge(
                                                          value.userProfileRating
                                                        )}
                                                        alt="badge"
                                                        className="mr-6 badgeClass"
                                                        style={{
                                                          position: "relative",
                                                          top: "9px",
                                                          right: "30px",
                                                        }}
                                                      />
                                                    </div>
                                                    {/* <h5>{value.userProfileDataTypes.Profession}</h5> */}
                                                    <p
                                                      className="custom_png"
                                                      style={{
                                                        color: "deepskyblue",
                                                      }}
                                                    >
                                                      #
                                                      {
                                                        value.userProfileData
                                                          .userNameHash
                                                      }{" "}
                                                      {value.userProfileData
                                                        .userName ? (
                                                        <>
                                                          / @
                                                          {
                                                            value
                                                              .userProfileData
                                                              .userName
                                                          }
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </p>
                                                    <p className="custom_png">
                                                      <img
                                                        className="custom78"
                                                        src={locationImg}
                                                        alt=""
                                                      />
                                                      {
                                                        value.userProfileData
                                                          .city
                                                      }
                                                      ,{" "}
                                                      {
                                                        value.userProfileData
                                                          .country
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="button_list">
                                                  <a
                                                    className="about_btn"
                                                    style={{
                                                      color: "white",
                                                      cursor: "pointer",
                                                    //   borderLeft:
                                                    //     "1px solid gainsboro",
                                                    //   borderBottom:
                                                    //     "1px solid gainsboro",
                                                      borderRight:
                                                        "1px solid gainsboro",
                                                      color: "rgb(58, 189, 0)",
                                                    }}
                                                    onClick={() => {
                                                      setModal({
                                                        modal: !modal.modal,
                                                        type: "followed list",
                                                        data: value,
                                                      });
                                                    }}
                                                  >
                                                    Un-Follow
                                                  </a>
                                                  <a
                                                    className="about_btn shift_outer4"
                                                    style={{
                                                      cursor: "pointer",
                                                      borderRadius: "0px",
                                                      borderRight:
                                                        "0px solid gainsboro",
                                                    }}
                                                  >
                                                    Candidate Detail
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-6"
                                    style={{ paddingRight: "22px" }}
                                  >
                                    <div className="row">
                                      {followedDataColB.map((value, index) => {
                                        return (
                                          <>
                                            <div className="col-12 my-3">
                                              <div className="blush_outer">
                                                <div className="edit_outer">
                                                  <div>
                                                    <div
                                                      className=""
                                                      style={{
                                                        height: "35px",
                                                        width: "60px",
                                                        background: "#3abd00",
                                                        textAlign: "center",
                                                        fontSize: "14px",
                                                        paddingTop: "8px",
                                                        fontWeight: "600",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {value.userProfileData
                                                        .minHourlyPayment ==
                                                      null
                                                        ? "$ -- / hr"
                                                        : `$ ${value.userProfileData.minHourlyPayment} / hr`}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="geoge_outer">
                                                  <div className="custom789 circleName">
                                                    {value.userProfileData
                                                      ? value.userProfileData
                                                          .entityTypeNameAbbreviation
                                                      : ""}
                                                  </div>
                                                  <img
                                                    src={
                                                        value.userProfileRating?.verified === 1
                                                        ? verified
                                                        : unVerified
                                                    }
                                                    alt="verify"
                                                    style={{
                                                      position: "absolute",
                                                      top: "55px",
                                                      left: "55px",
                                                    }}
                                                  />
                                                  <div className="google_inner">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                      <h3>
                                                        {value.userProfileData
                                                          .firstName == "" &&
                                                        value.userProfileData
                                                          .lastName == ""
                                                          ? "--"
                                                          : value
                                                              .userProfileData
                                                              .firstName +
                                                            " " +
                                                            value
                                                              .userProfileData
                                                              .lastName}
                                                      </h3>
                                                      <img
                                                        src={showBadge(
                                                          value.userProfileRating
                                                        )}
                                                        alt="badge"
                                                        className="mr-6 badgeClass"
                                                        style={{
                                                          position: "relative",
                                                          top: "9px",
                                                          right: "30px",
                                                        }}
                                                      />
                                                    </div>
                                                    {/* <h5>{value.userProfileDataTypes.Profession}</h5> */}
                                                    <p
                                                      className="custom_png"
                                                      style={{
                                                        color: "deepskyblue",
                                                      }}
                                                    >
                                                      #
                                                      {
                                                        value.userProfileData
                                                          .userNameHash
                                                      }{" "}
                                                      {value.userProfileData
                                                        .userName ? (
                                                        <>
                                                          / @
                                                          {
                                                            value
                                                              .userProfileData
                                                              .userName
                                                          }
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </p>
                                                    <p className="custom_png">
                                                      <img
                                                        className="custom78"
                                                        src={locationImg}
                                                        alt=""
                                                      />
                                                      {
                                                        value.userProfileData
                                                          .city
                                                      }
                                                      ,{" "}
                                                      {
                                                        value.userProfileData
                                                          .country
                                                      }
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="button_list">
                                                  <a
                                                    className="about_btn"
                                                    style={{
                                                      color: "white",
                                                      cursor: "pointer",
                                                    //   borderLeft:
                                                    //     "1px solid gainsboro",
                                                    //   borderBottom:
                                                    //     "1px solid gainsboro",
                                                      borderRight:
                                                        "1px solid gainsboro",
                                                      color: "rgb(58, 189, 0)",
                                                    }}
                                                    onClick={() => {
                                                      setModal({
                                                        modal: !modal.modal,
                                                        type: "followed list",
                                                        data: value,
                                                      });
                                                    }}
                                                  >
                                                    Un-Follow
                                                  </a>
                                                  <a
                                                    className="about_btn shift_outer4"
                                                    style={{
                                                      cursor: "pointer",
                                                      borderRadius: "0px",
                                                      borderRight:
                                                        "0px solid gainsboro",
                                                    }}
                                                  >
                                                    Candidate Detail
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="p-5 text-center">No Data</div>
                            </>
                          )}
                        </>
                      ) : dailyCalendarFlag ? (
                        <>
                          {invitedData.length > 0 ? (
                            <>
                              <div
                                className="Profile_form"
                                style={{ background: "#f7f7f7" }}
                              >
                                <div className="row">
                                  <div
                                    className="col-md-6"
                                    style={{ paddingLeft: "22px" }}
                                  >
                                    <div className="row">
                                      {invitedDataColA.map((value, index) => {
                                        return (
                                          <>
                                            <div className="col-12 my-3">
                                              <div className="blush_outer">
                                                <div className="edit_outer">
                                                  <div>
                                                    <div
                                                      className=""
                                                      style={{
                                                        height: "35px",
                                                        width: "60px",
                                                        background: "#3abd00",
                                                        textAlign: "center",
                                                        fontSize: "14px",
                                                        paddingTop: "8px",
                                                        fontWeight: "600",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {value.userProfileData
                                                        .minHourlyPayment ==
                                                      null
                                                        ? "$ -- / hr"
                                                        : `$ ${value.userProfileData.minHourlyPayment} / hr`}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="geoge_outer">
                                                  <div className="custom789 circleName">
                                                    {value.userProfileData
                                                      ? value.userProfileData
                                                          .entityTypeNameAbbreviation
                                                      : ""}
                                                  </div>
                                                  <img
                                                    src={
                                                        value.userProfileRating?.verified === 1
                                                        ? verified
                                                        : unVerified
                                                    }
                                                    alt="verify"
                                                    style={{
                                                      position: "absolute",
                                                      top: "55px",
                                                      left: "55px",
                                                    }}
                                                  />
                                                  <div className="google_inner">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                      <h3>
                                                        {value.userProfileData
                                                          .firstName == "" &&
                                                        value.userProfileData
                                                          .lastName == ""
                                                          ? "--"
                                                          : value
                                                              .userProfileData
                                                              .firstName +
                                                            " " +
                                                            value
                                                              .userProfileData
                                                              .lastName}
                                                      </h3>
                                                      <img
                                                        src={showBadge(
                                                          value.userProfileRating
                                                        )}
                                                        alt="badge"
                                                        className="mr-6 badgeClass"
                                                        style={{
                                                          position: "relative",
                                                          top: "9px",
                                                          right: "30px",
                                                        }}
                                                      />
                                                    </div>
                                                    {/* <h5>{value.userProfileDataTypes.Profession}</h5> */}
                                                    <p
                                                      className="custom_png"
                                                      style={{
                                                        color: "deepskyblue",
                                                      }}
                                                    >
                                                      #
                                                      {
                                                        value.userProfileData
                                                          .userNameHash
                                                      }{" "}
                                                      {value.userProfileData
                                                        .userName ? (
                                                        <>
                                                          / @
                                                          {
                                                            value
                                                              .userProfileData
                                                              .userName
                                                          }
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </p>
                                                    <p className="custom_png">
                                                      <img
                                                        className="custom78"
                                                        src={locationImg}
                                                        alt=""
                                                      />
                                                      {
                                                        value.userProfileData
                                                          .city
                                                      }
                                                      ,{" "}
                                                      {
                                                        value.userProfileData
                                                          .country
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="button_list">
                                                  <a
                                                    className="about_btn"
                                                    style={{
                                                      color: "white",
                                                      cursor: "pointer",
                                                    //   borderLeft:
                                                    //     "1px solid gainsboro",
                                                    //   borderBottom:
                                                    //     "1px solid gainsboro",
                                                      borderRight:
                                                        "1px solid gainsboro",
                                                      color: "rgb(58, 189, 0)",
                                                    }}
                                                    onClick={() => {
                                                      setModal({
                                                        modal: !modal.modal,
                                                        type: "invited list",
                                                        data: value,
                                                      });
                                                    }}
                                                  >
                                                    Un-Invite
                                                  </a>
                                                  <a
                                                    className="about_btn shift_outer4"
                                                    style={{
                                                      cursor: "pointer",
                                                      borderRadius: "0px",
                                                      borderRight:
                                                        "0px solid gainsboro",
                                                    }}
                                                  >
                                                    Candidate Detail
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-6"
                                    style={{ paddingRight: "22px" }}
                                  >
                                    <div className="row">
                                      {invitedDataColB.map((value, index) => {
                                        return (
                                          <>
                                            <div className="col-12 my-3">
                                              <div className="blush_outer">
                                                <div className="edit_outer">
                                                  <div>
                                                    <div
                                                      className=""
                                                      style={{
                                                        height: "35px",
                                                        width: "60px",
                                                        background: "#3abd00",
                                                        textAlign: "center",
                                                        fontSize: "14px",
                                                        paddingTop: "8px",
                                                        fontWeight: "600",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {value.userProfileData
                                                        .minHourlyPayment ==
                                                      null
                                                        ? "$ -- / hr"
                                                        : `$ ${value.userProfileData.minHourlyPayment} / hr`}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="geoge_outer">
                                                  <div className="custom789 circleName">
                                                    {value.userProfileData
                                                      ? value.userProfileData
                                                          .entityTypeNameAbbreviation
                                                      : ""}
                                                  </div>
                                                  <img
                                                    src={
                                                        value.userProfileRating?.verified === 1
                                                        ? verified
                                                        : unVerified
                                                    }
                                                    alt="verify"
                                                    style={{
                                                      position: "absolute",
                                                      top: "55px",
                                                      left: "55px",
                                                    }}
                                                  />
                                                  <div className="google_inner">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                      <h3>
                                                        {value.userProfileData
                                                          .firstName == "" &&
                                                        value.userProfileData
                                                          .lastName == ""
                                                          ? "--"
                                                          : value
                                                              .userProfileData
                                                              .firstName +
                                                            " " +
                                                            value
                                                              .userProfileData
                                                              .lastName}
                                                      </h3>
                                                      <img
                                                        src={showBadge(
                                                          value.userProfileRating
                                                        )}
                                                        alt="badge"
                                                        className="mr-6 badgeClass"
                                                        style={{
                                                          position: "relative",
                                                          top: "9px",
                                                          right: "30px",
                                                        }}
                                                      />
                                                    </div>
                                                    {/* <h5>{value.userProfileDataTypes.Profession}</h5> */}
                                                    <p
                                                      className="custom_png"
                                                      style={{
                                                        color: "deepskyblue",
                                                      }}
                                                    >
                                                      #
                                                      {
                                                        value.userProfileData
                                                          .userNameHash
                                                      }{" "}
                                                      {value.userProfileData
                                                        .userName ? (
                                                        <>
                                                          / @
                                                          {
                                                            value
                                                              .userProfileData
                                                              .userName
                                                          }
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </p>
                                                    <p className="custom_png">
                                                      <img
                                                        className="custom78"
                                                        src={locationImg}
                                                        alt=""
                                                      />
                                                      {
                                                        value.userProfileData
                                                          .city
                                                      }
                                                      ,{" "}
                                                      {
                                                        value.userProfileData
                                                          .country
                                                      }
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="button_list">
                                                  <a
                                                    className="about_btn"
                                                    style={{
                                                      color: "white",
                                                      cursor: "pointer",
                                                    //   borderLeft:
                                                    //     "1px solid gainsboro",
                                                    //   borderBottom:
                                                    //     "1px solid gainsboro",
                                                      borderRight:
                                                        "1px solid gainsboro",
                                                      color: "rgb(58, 189, 0)",
                                                    }}
                                                    onClick={() => {
                                                      setModal({
                                                        modal: !modal.modal,
                                                        type: "invited list",
                                                        data: value,
                                                      });
                                                    }}
                                                  >
                                                    Un-Invite
                                                  </a>
                                                  <a
                                                    className="about_btn shift_outer4"
                                                    style={{
                                                      cursor: "pointer",
                                                      borderRadius: "0px",
                                                      borderRight:
                                                        "0px solid gainsboro",
                                                    }}
                                                  >
                                                    Candidate Detail
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="p-5 text-center">No Data</div>
                            </>
                          )}
                        </>
                      ) : aboutEmployerFlag ? (
                        <></>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="row my-5">
                    <div className="col-12 text-center m-auto">
                      {!state?.fromDashboard && (
                        <button
                          className="btn btn-primary"
                          onClick={() => history.push(`/post`)}
                        >
                          Back To Post
                        </button>
                      )}
                    </div>
                  </div>

                  <Modal
                    show={modal.modal}
                    size="md"
                    centered
                    style={{ background: "rgb(95 95 95 / 77%)" }}
                  >
                    <div className="text-center m-5">
                      {modal.data?.userProfileData?.firstName &&
                      modal.data?.userProfileData?.lastName ? (
                        <>
                          <Modal.Title>
                            Are you sure, you want to remove {" "}
                            {modal.data?.userProfileData?.firstName}{" "}
                            {modal.data?.userProfileData?.lastName} {" "}
                            from {modal.type} ?
                          </Modal.Title>
                        </>
                      ) : (
                        <>
                          <Modal.Title>
                            Are you sure, you want to remove this from{" "}
                            {modal.type} ?
                          </Modal.Title>
                        </>
                      )}
                    </div>

                    {modalLoading ? (
                      <div className="text-center m-5">
                        <span className="spinner-border spinner-border-sm"></span>
                      </div>
                    ) : (
                      <div className="text-center m-5">
                        <Button
                          className="mx-3"
                          variant="danger"
                          onClick={() => setModal(!modal)}
                        >
                          No
                        </Button>
                        <Button
                          className="mx-3"
                          variant="primary"
                          onClick={onClickAction}
                        >
                          Yes
                        </Button>
                      </div>
                    )}
                  </Modal>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getTalentProfile: bindActionCreators(getTalentProfile, dispatch),
  getJobDetails: bindActionCreators(getJobDetails, dispatch),
  getFieldValuesForEditing: bindActionCreators(
    getFieldValuesForEditing,
    dispatch
  ),
  editJob: bindActionCreators(editJob, dispatch),
  getSkillBio: bindActionCreators(getSkillBio, dispatch),
  getFormFieldsEntity: bindActionCreators(getFormFieldsEntity, dispatch),
  getFormFieldsPrespective: bindActionCreators(
    getFormFieldsPrespective,
    dispatch
  ),
  getDaysList: bindActionCreators(getDaysList, dispatch),
  getActiveDaysList: bindActionCreators(getActiveDaysList, dispatch),
  getDailyCalendarDetails: bindActionCreators(
    getDailyCalendarDetails,
    dispatch
  ),
  getShortlisted: bindActionCreators(getShortlisted, dispatch),
  getFollowed: bindActionCreators(getFollowed, dispatch),
  getInvited: bindActionCreators(getInvited, dispatch),
  getAllApplicants: bindActionCreators(getAllApplicants, dispatch),
  removeShortlist: bindActionCreators(removeShortlist, dispatch),
  removeFollow: bindActionCreators(removeFollow, dispatch),
  removeInvite: bindActionCreators(removeInvite, dispatch),
  accept: bindActionCreators(accept, dispatch),
  reject: bindActionCreators(reject, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
