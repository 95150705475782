import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import company from "../../public/images/CompanyLogo1.svg";
import jobloaction from "../../public/images/joblocation.svg";
import Mail from "../../public/images/mailIcon.svg"
import Admin from "../../public/images/adminIcon.svg"
import Phone from "../../public/images/phone.svg"
import Location from "../../public/images/locationGrey.svg"
import Globe from "../../public/images/Globe.svg"
import Upload from "../../public/images/CloudArrowUp.svg";
import { getJobDetailsLanding } from "../../actions/common";
import { bindActionCreators } from "redux";
import { deleteResumeCred, getResume } from "../../actions/employee/skill";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
    geocodeByAddress
} from "react-places-autocomplete";

const ApplyJob = (props) => {

    const { entityId , userProfileId } = useParams();
    const [ loading,setLoading ] = useState(false);
    const [ credLoading,setCredLoading ] = useState(false);
    const [ job,setJob ] = useState({});
    const [ uploadResume,setUploadResume ] = useState([]);
    const [ fileIdHash,setFileIdHash ] = useState('');
    
    const [ formData,setFormData ] = useState({
        firstName: props.auth.userProfile.userName,
        lastName:'',
        emailAddress:props.auth.userProfile.emailAddress,
        confirmEmailAddress:'',
        phoneCountryCode:'',
        phoneNumber:'',
        address:'',
        city:'',
        state:'',
        country:'',
        twitter:'',
        facebook:'',
        linkedIn:'',
        website:'',
        message:'',
        education:'',
        experience:''
    })

    useEffect(()=>{
        getJobDetails()
        getResume()
    },[])

    const getJobDetails = () => {
        setLoading(true);
        const params = {
            entityId : entityId,
            userProfileId : userProfileId
        }

        props.getJobDetailsLanding(params, (res) => {
          setLoading(false);
          if (res.status) {
            console.log("Jobs Details", res.data);
            setJob(res.data)
          } else {
            setJob({});
          }
        });
    }

    const getResume = () => {
        setCredLoading(true);
        props.getResume(
        { userIdHash: props.auth.token},
        (res) => {
            setCredLoading(false)
            if (res.status) {
                setFileIdHash(res.data[0].fileHashId)
                setUploadResume(res.data)
            }
            else{
                setUploadResume([])
            }
        }
        );
    }

    const deleteResume = (fileHashId) => {
        setCredLoading(true)
        props.deleteResumeCred(
          {  fileHashId: fileHashId, userIdHash: props.auth.token , value:1},
          (res) => {
            if (res.status) {
              setCredLoading(false)
              setUploadResume([])
            }
            else{
              toast.warn('Something went wrong');
            }
          }
        );
    }

    const onChangeInput = (e,flag) => {

        setFormData({
            ...formData,
            [flag]:e.target.value
        })

    }   

    const autoCompleteHandleChange = (address) => {
        if (address === "") {
            setFormData({
                ...formData,
                city:'',
                state:'',
                country:'',
                address:''
            })
        }
        setFormData({
            ...formData,
            address:address
        })
    };
    
    const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
        .then((results) => {
        document.getElementById("searchTextField").blur();
        fillAddressDetails(results);
        })
        .catch((error) => {});
    };
    
    const google = (window.google = window.google ? window.google : {});

    const searchOptions = {
        // location: new this.google.maps.LatLng(51.509865, -0.118092),
        // radius: 424000,
        //types: ["address", "uk"],
        componentRestrictions: { country: "" },
    };
    
    const fillAddressDetails = (results) => {
                
        setFormData({
            ...formData,
            address:results[0].formatted_address
        })
    
        for (let j = 0; j < results[0].address_components.length; j++) {
          if (results[0].address_components[j].types[0] == "postal_code") {
            // setZipCode(results[0].address_components[j].short_name);
          } else if (results[0].address_components[j].types[0] == "locality") {
            setFormData({
                ...formData,
                city:results[0].address_components[j].long_name
            })
          } else if (
            results[0].address_components[j].types[0] ==
            "administrative_area_level_1"
          ) {
            setFormData({
                ...formData,
                state:results[0].address_components[j].long_name
            })
          } else if (results[0].address_components[j].types[0] == "country") {
            setFormData({
                ...formData,
                country:results[0].address_components[j].long_name
            })
          }
        }
      };


    console.log(formData)

    return (
        <>
            {loading?
            <>
                <div className="row" style={{ width: "100%", height: "80vh" }}>
                    <div
                        className="col-12"
                        style={{ width: "100%", height: "100%" }}
                    >
                        <div
                        style={{
                            width: "100%",
                            height: "100%",
                            textAlign: "center",
                            verticalAlign: "middle",
                            lineHeight: "40vh",
                        }}
                        >
                        <div
                            className="spinner-grow"
                            role="status"
                            style={{ background: "#006838" }}
                        ></div>
                        </div>
                    </div>
                </div>
            </>
            :
            <>
                <section className="interested_Nav_">
                    <div className="interested_main">
                        <div className="container">
                            <div className="interested_nav">
                                <div className="interested_icon">
                                    <img src={company} alt="logo" className="img-fluid" />
                                </div>
                                <div className="interested_content">
                                    <h5 className="job_heading">{job.jobDetail?.title} || {job.jobDetail?.name}
                                        <span className="job_Subheading"> ( {job.jobProfile?.entityName} )</span>
                                    </h5>
                                    <div className="job_location">
                                        <a href="javascript:void();">
                                            <img src={jobloaction} alt="logo" />
                                            <span>{job.jobProfile?.address}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="personal_info">
                    <div className="container">
                        <div className="go_back">
                            <a href="/apply-job">
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                <span><Link to={`/apply-job/${entityId}/${userProfileId}`}>go back</Link></span>
                            </a>
                        </div>
                        <div className="personal_details">
                            <h4>Personal information</h4>
                            <form className="form-group form_Loginhorizontal" role="form">
                                <div className="row">
                                    <div className="form-group mb-4 col-md-6">
                                        <label for="Fname">First Name</label>
                                        <input type="text" className="form-control" name="firstName" placeholder="Enter First Name" required
                                            onChange={(e) => {
                                                onChangeInput(e, "firstName");
                                              }}
                                            value={formData.firstName}
                                             />
                                        <span className="personal_detailsform">
                                            <img src={Admin} alt="Icon" className="img-fluid" />
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <label for="Lname">Last Name</label>
                                        <input type="text" className="form-control" name="lastName" placeholder="Enter Last Name"  required
                                           onChange={(e) => {
                                            onChangeInput(e, "lastName");
                                          }}
                                          value={formData.lastName}

                                        />
                                        <span className="personal_detailsform">
                                            <img src={Admin} alt="Icon" className="img-fluid" />
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <label for="Email">Email</label>
                                        <input type="email" className="form-control" name="emailAddress" placeholder="Email Address" required
                                          onChange={(e) => {
                                            onChangeInput(e, "emailAddress");
                                          }}
                                          value={formData.emailAddress}
                                          />
                                        <span className="personal_detailsform">
                                            <img src={Mail} alt="Icon" className="img-fluid" />
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <label for="Email">Confirm Your Email</label>
                                        <input type="email" className="form-control" name="confirmEmailAddress" placeholder="Confirm Email Address" required 
                                            onChange={(e) => {
                                            onChangeInput(e, "confirmEmailAddress");
                                          }}
                                          />
                                        <span className="personal_detailsform">
                                            <img src={Mail} alt="Icon" className="img-fluid" />
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <label for="Place">Place of Residence</label>
                                        {/* <input type="text" className="form-control" name="Place" placeholder="Address " required /> */}
                                        <PlacesAutocomplete
                                        onChange={autoCompleteHandleChange}
                                        onSelect={autoCompleteHandleSelect}
                                        searchOptions={searchOptions}
                                        value={formData.address}
                                        >
                                        {({
                                            getInputProps,
                                            suggestions,
                                            getSuggestionItemProps,
                                            loading,
                                        }) => (
                                            <div>
                                            <input
                                                id="searchTextField"
                                                {...getInputProps({
                                                placeholder: "Search Places...",
                                                className:
                                                    "location-search-input form-control inputStyle",
                                                })}
                                                // style={{ height: "45px" }}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                const className = suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? {
                                                        backgroundColor: "#41b6e6",
                                                        cursor: "pointer",
                                                    }
                                                    : {
                                                        backgroundColor: "#ffffff",
                                                        cursor: "pointer",
                                                    };
                                                return (
                                                    <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                    >
                                                    <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                                })}
                                            </div>
                                            </div>
                                        )}
                                        </PlacesAutocomplete>
                                        {/* <span className="personal_detailsform">
                                            <img src={Location} alt="Icon" className="img-fluid" />
                                        </span> */}
                                    </div>
                                    <div className="form-group mb-4 col-md-6">
                                        <label for="Number">Country Code</label>
                                        <select
                                            name="countryCode"
                                            id=""
                                            className="form-control form-control-solid login-signup-screen-phonecode"
                                            onChange={(e) => {
                                            onChangeInput(e, "phoneCountryCode");
                                            }}
                                            value="US-1"
                                        >
                                            <option data-countryCode="GB" value="GB-44">
                                            UK (+44)
                                            </option>
                                            <option
                                            data-countryCode="US"
                                            value="US-1"
                                            >
                                            USA (+1)
                                            </option>
                                            <optgroup label="Other countries">
                                            <option
                                                data-countryCode="DZ"
                                                value="DZ-213"
                                            >
                                                Algeria (+213)
                                            </option>
                                            <option
                                                data-countryCode="AD"
                                                value="AD-376"
                                            >
                                                Andorra (+376)
                                            </option>
                                            <option
                                                data-countryCode="AO"
                                                value="AO-244"
                                            >
                                                Angola (+244)
                                            </option>
                                            <option
                                                data-countryCode="AI"
                                                value="AI-1264"
                                            >
                                                Anguilla (+1264)
                                            </option>
                                            <option
                                                data-countryCode="AG"
                                                value="AG-1268"
                                            >
                                                Antigua &amp; Barbuda (+1268)
                                            </option>
                                            <option
                                                data-countryCode="AR"
                                                value="AR-54"
                                            >
                                                Argentina (+54)
                                            </option>
                                            <option
                                                data-countryCode="AM"
                                                value="AM-374"
                                            >
                                                Armenia (+374)
                                            </option>
                                            <option
                                                data-countryCode="AW"
                                                value="AW-297"
                                            >
                                                Aruba (+297)
                                            </option>
                                            <option
                                                data-countryCode="AU"
                                                value="AU-61"
                                            >
                                                Australia (+61)
                                            </option>
                                            <option
                                                data-countryCode="AT"
                                                value="AT-43"
                                            >
                                                Austria (+43)
                                            </option>
                                            <option
                                                data-countryCode="AZ"
                                                value="AZ-994"
                                            >
                                                Azerbaijan (+994)
                                            </option>
                                            <option
                                                data-countryCode="BS"
                                                value="BS-1242"
                                            >
                                                Bahamas (+1242)
                                            </option>
                                            <option
                                                data-countryCode="BH"
                                                value="BH-973"
                                            >
                                                Bahrain (+973)
                                            </option>
                                            <option
                                                data-countryCode="BD"
                                                value="BD-880"
                                            >
                                                Bangladesh (+880)
                                            </option>
                                            <option
                                                data-countryCode="BB"
                                                value="BB-1246"
                                            >
                                                Barbados (+1246)
                                            </option>
                                            <option
                                                data-countryCode="BY"
                                                value="BY-375"
                                            >
                                                Belarus (+375)
                                            </option>
                                            <option
                                                data-countryCode="BE"
                                                value="BE-32"
                                            >
                                                Belgium (+32)
                                            </option>
                                            <option
                                                data-countryCode="BZ"
                                                value="BZ-501"
                                            >
                                                Belize (+501)
                                            </option>
                                            <option
                                                data-countryCode="BJ"
                                                value="BJ-229"
                                            >
                                                Benin (+229)
                                            </option>
                                            <option
                                                data-countryCode="BM"
                                                value="BM-1441"
                                            >
                                                Bermuda (+1441)
                                            </option>
                                            <option
                                                data-countryCode="BT"
                                                value="BT-975"
                                            >
                                                Bhutan (+975)
                                            </option>
                                            <option
                                                data-countryCode="BO"
                                                value="BO-591"
                                            >
                                                Bolivia (+591)
                                            </option>
                                            <option
                                                data-countryCode="BA"
                                                value="BA-387"
                                            >
                                                Bosnia Herzegovina (+387)
                                            </option>
                                            <option
                                                data-countryCode="BW"
                                                value="BW-267"
                                            >
                                                Botswana (+267)
                                            </option>
                                            <option
                                                data-countryCode="BR"
                                                value="BR-55"
                                            >
                                                Brazil (+55)
                                            </option>
                                            <option
                                                data-countryCode="BN"
                                                value="BN-673"
                                            >
                                                Brunei (+673)
                                            </option>
                                            <option
                                                data-countryCode="BG"
                                                value="BG-359"
                                            >
                                                Bulgaria (+359)
                                            </option>
                                            <option
                                                data-countryCode="BF"
                                                value="BF-226"
                                            >
                                                Burkina Faso (+226)
                                            </option>
                                            <option
                                                data-countryCode="BI"
                                                value="BI-257"
                                            >
                                                Burundi (+257)
                                            </option>
                                            <option
                                                data-countryCode="KH"
                                                value="KH-855"
                                            >
                                                Cambodia (+855)
                                            </option>
                                            <option
                                                data-countryCode="CM"
                                                value="CM-237"
                                            >
                                                Cameroon (+237)
                                            </option>
                                            <option
                                                data-countryCode="CA"
                                                value="CA-1"
                                            >
                                                Canada (+1)
                                            </option>
                                            <option
                                                data-countryCode="CV"
                                                value="CV-238"
                                            >
                                                Cape Verde Islands (+238)
                                            </option>
                                            <option
                                                data-countryCode="KY"
                                                value="KY-1345"
                                            >
                                                Cayman Islands (+1345)
                                            </option>
                                            <option
                                                data-countryCode="CF"
                                                value="CF-236"
                                            >
                                                Central African Republic (+236)
                                            </option>
                                            <option
                                                data-countryCode="CL"
                                                value="CL-56"
                                            >
                                                Chile (+56)
                                            </option>
                                            <option
                                                data-countryCode="CN"
                                                value="CN-86"
                                            >
                                                China (+86)
                                            </option>
                                            <option
                                                data-countryCode="CO"
                                                value="CO-57"
                                            >
                                                Colombia (+57)
                                            </option>
                                            <option
                                                data-countryCode="KM"
                                                value="KM-269"
                                            >
                                                Comoros (+269)
                                            </option>
                                            <option
                                                data-countryCode="CG"
                                                value="CG-242"
                                            >
                                                Congo (+242)
                                            </option>
                                            <option
                                                data-countryCode="CK"
                                                value="CK-682"
                                            >
                                                Cook Islands (+682)
                                            </option>
                                            <option
                                                data-countryCode="CR"
                                                value="CR-506"
                                            >
                                                Costa Rica (+506)
                                            </option>
                                            <option
                                                data-countryCode="HR"
                                                value="HR-385"
                                            >
                                                Croatia (+385)
                                            </option>
                                            <option
                                                data-countryCode="CU"
                                                value="CU-53"
                                            >
                                                Cuba (+53)
                                            </option>
                                            <option
                                                data-countryCode="CY"
                                                value="CY-90392"
                                            >
                                                Cyprus North (+90392)
                                            </option>
                                            <option
                                                data-countryCode="CY"
                                                value="CY-357"
                                            >
                                                Cyprus South (+357)
                                            </option>
                                            <option
                                                data-countryCode="CZ"
                                                value="CZ-42"
                                            >
                                                Czech Republic (+42)
                                            </option>
                                            <option
                                                data-countryCode="DK"
                                                value="DK-45"
                                            >
                                                Denmark (+45)
                                            </option>
                                            <option
                                                data-countryCode="DJ"
                                                value="DJ-253"
                                            >
                                                Djibouti (+253)
                                            </option>
                                            <option
                                                data-countryCode="DM"
                                                value="DM-1809"
                                            >
                                                Dominica (+1809)
                                            </option>
                                            <option
                                                data-countryCode="DO"
                                                value="DO-1809"
                                            >
                                                Dominican Republic (+1809)
                                            </option>
                                            <option
                                                data-countryCode="EC"
                                                value="EC-593"
                                            >
                                                Ecuador (+593)
                                            </option>
                                            <option
                                                data-countryCode="EG"
                                                value="EG-20"
                                            >
                                                Egypt (+20)
                                            </option>
                                            <option
                                                data-countryCode="SV"
                                                value="SV-503"
                                            >
                                                El Salvador (+503)
                                            </option>
                                            <option
                                                data-countryCode="GQ"
                                                value="GQ-240"
                                            >
                                                Equatorial Guinea (+240)
                                            </option>
                                            <option
                                                data-countryCode="ER"
                                                value="ER-291"
                                            >
                                                Eritrea (+291)
                                            </option>
                                            <option
                                                data-countryCode="EE"
                                                value="EE-372"
                                            >
                                                Estonia (+372)
                                            </option>
                                            <option
                                                data-countryCode="ET"
                                                value="ET-251"
                                            >
                                                Ethiopia (+251)
                                            </option>
                                            <option
                                                data-countryCode="FK"
                                                value="FK-500"
                                            >
                                                Falkland Islands (+500)
                                            </option>
                                            <option
                                                data-countryCode="FO"
                                                value="FO-298"
                                            >
                                                Faroe Islands (+298)
                                            </option>
                                            <option
                                                data-countryCode="FJ"
                                                value="FJ-679"
                                            >
                                                Fiji (+679)
                                            </option>
                                            <option
                                                data-countryCode="FI"
                                                value="FI-358"
                                            >
                                                Finland (+358)
                                            </option>
                                            <option
                                                data-countryCode="FR"
                                                value="FR-33"
                                            >
                                                France (+33)
                                            </option>
                                            <option
                                                data-countryCode="GF"
                                                value="GF-594"
                                            >
                                                French Guiana (+594)
                                            </option>
                                            <option
                                                data-countryCode="PF"
                                                value="PF-689"
                                            >
                                                French Polynesia (+689)
                                            </option>
                                            <option
                                                data-countryCode="GA"
                                                value="GA-241"
                                            >
                                                Gabon (+241)
                                            </option>
                                            <option
                                                data-countryCode="GM"
                                                value="GM-220"
                                            >
                                                Gambia (+220)
                                            </option>
                                            <option
                                                data-countryCode="GE"
                                                value="GE-7880"
                                            >
                                                Georgia (+7880)
                                            </option>
                                            <option
                                                data-countryCode="DE"
                                                value="DE-49"
                                            >
                                                Germany (+49)
                                            </option>
                                            <option
                                                data-countryCode="GH"
                                                value="GH-233"
                                            >
                                                Ghana (+233)
                                            </option>
                                            <option
                                                data-countryCode="GI"
                                                value="GI-350"
                                            >
                                                Gibraltar (+350)
                                            </option>
                                            <option
                                                data-countryCode="GR"
                                                value="GR-30"
                                            >
                                                Greece (+30)
                                            </option>
                                            <option
                                                data-countryCode="GL"
                                                value="GL-299"
                                            >
                                                Greenland (+299)
                                            </option>
                                            <option
                                                data-countryCode="GD"
                                                value="GD-1473"
                                            >
                                                Grenada (+1473)
                                            </option>
                                            <option
                                                data-countryCode="GP"
                                                value="GP-590"
                                            >
                                                Guadeloupe (+590)
                                            </option>
                                            <option
                                                data-countryCode="GU"
                                                value="GU-671"
                                            >
                                                Guam (+671)
                                            </option>
                                            <option
                                                data-countryCode="GT"
                                                value="GT-502"
                                            >
                                                Guatemala (+502)
                                            </option>
                                            <option
                                                data-countryCode="GN"
                                                value="GN-224"
                                            >
                                                Guinea (+224)
                                            </option>
                                            <option
                                                data-countryCode="GW"
                                                value="GW-245"
                                            >
                                                Guinea - Bissau (+245)
                                            </option>
                                            <option
                                                data-countryCode="GY"
                                                value="GY-592"
                                            >
                                                Guyana (+592)
                                            </option>
                                            <option
                                                data-countryCode="HT"
                                                value="HT-509"
                                            >
                                                Haiti (+509)
                                            </option>
                                            <option
                                                data-countryCode="HN"
                                                value="HN-504"
                                            >
                                                Honduras (+504)
                                            </option>
                                            <option
                                                data-countryCode="HK"
                                                value="HK-852"
                                            >
                                                Hong Kong (+852)
                                            </option>
                                            <option
                                                data-countryCode="HU"
                                                value="HU-36"
                                            >
                                                Hungary (+36)
                                            </option>
                                            <option
                                                data-countryCode="IS"
                                                value="IS-354"
                                            >
                                                Iceland (+354)
                                            </option>
                                            <option
                                                data-countryCode="IN"
                                                value="IN-91"
                                            >
                                                India (+91)
                                            </option>
                                            <option
                                                data-countryCode="ID"
                                                value="ID-62"
                                            >
                                                Indonesia (+62)
                                            </option>
                                            <option
                                                data-countryCode="IR"
                                                value="IR-98"
                                            >
                                                Iran (+98)
                                            </option>
                                            <option
                                                data-countryCode="IQ"
                                                value="IQ-964"
                                            >
                                                Iraq (+964)
                                            </option>
                                            <option
                                                data-countryCode="IE"
                                                value="IE-353"
                                            >
                                                Ireland (+353)
                                            </option>
                                            <option
                                                data-countryCode="IL"
                                                value="IL-972"
                                            >
                                                Israel (+972)
                                            </option>
                                            <option
                                                data-countryCode="IT"
                                                value="IT-39"
                                            >
                                                Italy (+39)
                                            </option>
                                            <option
                                                data-countryCode="JM"
                                                value="JM-1876"
                                            >
                                                Jamaica (+1876)
                                            </option>
                                            <option
                                                data-countryCode="JP"
                                                value="JP-81"
                                            >
                                                Japan (+81)
                                            </option>
                                            <option
                                                data-countryCode="JO"
                                                value="JO-962"
                                            >
                                                Jordan (+962)
                                            </option>
                                            <option
                                                data-countryCode="KZ"
                                                value="KZ-7"
                                            >
                                                Kazakhstan (+7)
                                            </option>
                                            <option
                                                data-countryCode="KE"
                                                value="KE-254"
                                            >
                                                Kenya (+254)
                                            </option>
                                            <option
                                                data-countryCode="KI"
                                                value="KI-686"
                                            >
                                                Kiribati (+686)
                                            </option>
                                            <option
                                                data-countryCode="KP"
                                                value="KP-850"
                                            >
                                                Korea North (+850)
                                            </option>
                                            <option
                                                data-countryCode="KR"
                                                value="KR-82"
                                            >
                                                Korea South (+82)
                                            </option>
                                            <option
                                                data-countryCode="KW"
                                                value="KW-965"
                                            >
                                                Kuwait (+965)
                                            </option>
                                            <option
                                                data-countryCode="KG"
                                                value="KG-996"
                                            >
                                                Kyrgyzstan (+996)
                                            </option>
                                            <option
                                                data-countryCode="LA"
                                                value="LA-856"
                                            >
                                                Laos (+856)
                                            </option>
                                            <option
                                                data-countryCode="LV"
                                                value="LV-371"
                                            >
                                                Latvia (+371)
                                            </option>
                                            <option
                                                data-countryCode="LB"
                                                value="LB-961"
                                            >
                                                Lebanon (+961)
                                            </option>
                                            <option
                                                data-countryCode="LS"
                                                value="LS-266"
                                            >
                                                Lesotho (+266)
                                            </option>
                                            <option
                                                data-countryCode="LR"
                                                value="LR-231"
                                            >
                                                Liberia (+231)
                                            </option>
                                            <option
                                                data-countryCode="LY"
                                                value="LY-218"
                                            >
                                                Libya (+218)
                                            </option>
                                            <option
                                                data-countryCode="LI"
                                                value="LI-417"
                                            >
                                                Liechtenstein (+417)
                                            </option>
                                            <option
                                                data-countryCode="LT"
                                                value="LT-370"
                                            >
                                                Lithuania (+370)
                                            </option>
                                            <option
                                                data-countryCode="LU"
                                                value="LU-352"
                                            >
                                                Luxembourg (+352)
                                            </option>
                                            <option
                                                data-countryCode="MO"
                                                value="MO-853"
                                            >
                                                Macao (+853)
                                            </option>
                                            <option
                                                data-countryCode="MK"
                                                value="MK-389"
                                            >
                                                Macedonia (+389)
                                            </option>
                                            <option
                                                data-countryCode="MG"
                                                value="MG-261"
                                            >
                                                Madagascar (+261)
                                            </option>
                                            <option
                                                data-countryCode="MW"
                                                value="MW-265"
                                            >
                                                Malawi (+265)
                                            </option>
                                            <option
                                                data-countryCode="MY"
                                                value="MY-60"
                                            >
                                                Malaysia (+60)
                                            </option>
                                            <option
                                                data-countryCode="MV"
                                                value="MV-960"
                                            >
                                                Maldives (+960)
                                            </option>
                                            <option
                                                data-countryCode="ML"
                                                value="ML-223"
                                            >
                                                Mali (+223)
                                            </option>
                                            <option
                                                data-countryCode="MT"
                                                value="MT-356"
                                            >
                                                Malta (+356)
                                            </option>
                                            <option
                                                data-countryCode="MH"
                                                value="MH-692"
                                            >
                                                Marshall Islands (+692)
                                            </option>
                                            <option
                                                data-countryCode="MQ"
                                                value="MQ-596"
                                            >
                                                Martinique (+596)
                                            </option>
                                            <option
                                                data-countryCode="MR"
                                                value="MR-222"
                                            >
                                                Mauritania (+222)
                                            </option>
                                            <option
                                                data-countryCode="YT"
                                                value="YT-269"
                                            >
                                                Mayotte (+269)
                                            </option>
                                            <option
                                                data-countryCode="MX"
                                                value="MX-52"
                                            >
                                                Mexico (+52)
                                            </option>
                                            <option
                                                data-countryCode="FM"
                                                value="FM-691"
                                            >
                                                Micronesia (+691)
                                            </option>
                                            <option
                                                data-countryCode="MD"
                                                value="MD-373"
                                            >
                                                Moldova (+373)
                                            </option>
                                            <option
                                                data-countryCode="MC"
                                                value="MC-377"
                                            >
                                                Monaco (+377)
                                            </option>
                                            <option
                                                data-countryCode="MN"
                                                value="MN-976"
                                            >
                                                Mongolia (+976)
                                            </option>
                                            <option
                                                data-countryCode="MS"
                                                value="MS-1664"
                                            >
                                                Montserrat (+1664)
                                            </option>
                                            <option
                                                data-countryCode="MA"
                                                value="MA-212"
                                            >
                                                Morocco (+212)
                                            </option>
                                            <option
                                                data-countryCode="MZ"
                                                value="MZ-258"
                                            >
                                                Mozambique (+258)
                                            </option>
                                            <option
                                                data-countryCode="MN"
                                                value="MN-95"
                                            >
                                                Myanmar (+95)
                                            </option>
                                            <option
                                                data-countryCode="NA"
                                                value="NA-264"
                                            >
                                                Namibia (+264)
                                            </option>
                                            <option
                                                data-countryCode="NR"
                                                value="NR-674"
                                            >
                                                Nauru (+674)
                                            </option>
                                            <option
                                                data-countryCode="NP"
                                                value="NP-977"
                                            >
                                                Nepal (+977)
                                            </option>
                                            <option
                                                data-countryCode="NL"
                                                value="NL-31"
                                            >
                                                Netherlands (+31)
                                            </option>
                                            <option
                                                data-countryCode="NC"
                                                value="NC-687"
                                            >
                                                New Caledonia (+687)
                                            </option>
                                            <option
                                                data-countryCode="NZ"
                                                value="NZ-64"
                                            >
                                                New Zealand (+64)
                                            </option>
                                            <option
                                                data-countryCode="NI"
                                                value="NI-505"
                                            >
                                                Nicaragua (+505)
                                            </option>
                                            <option
                                                data-countryCode="NE"
                                                value="NE-227"
                                            >
                                                Niger (+227)
                                            </option>
                                            <option
                                                data-countryCode="NG"
                                                value="NG-234"
                                            >
                                                Nigeria (+234)
                                            </option>
                                            <option
                                                data-countryCode="NU"
                                                value="NU-683"
                                            >
                                                Niue (+683)
                                            </option>
                                            <option
                                                data-countryCode="NF"
                                                value="NF-672"
                                            >
                                                Norfolk Islands (+672)
                                            </option>
                                            <option
                                                data-countryCode="NP"
                                                value="NP-670"
                                            >
                                                Northern Marianas (+670)
                                            </option>
                                            <option
                                                data-countryCode="NO"
                                                value="NO-47"
                                            >
                                                Norway (+47)
                                            </option>
                                            <option
                                                data-countryCode="OM"
                                                value="OM-968"
                                            >
                                                Oman (+968)
                                            </option>
                                            <option
                                                data-countryCode="PW"
                                                value="PW-680"
                                            >
                                                Palau (+680)
                                            </option>
                                            <option
                                                data-countryCode="PA"
                                                value="PA-507"
                                            >
                                                Panama (+507)
                                            </option>
                                            <option
                                                data-countryCode="PG"
                                                value="PG-675"
                                            >
                                                Papua New Guinea (+675)
                                            </option>
                                            <option
                                                data-countryCode="PY"
                                                value="PY-595"
                                            >
                                                Paraguay (+595)
                                            </option>
                                            <option
                                                data-countryCode="PE"
                                                value="PE-51"
                                            >
                                                Peru (+51)
                                            </option>
                                            <option
                                                data-countryCode="PH"
                                                value="PH-63"
                                            >
                                                Philippines (+63)
                                            </option>
                                            <option
                                                data-countryCode="PL"
                                                value="PL-48"
                                            >
                                                Poland (+48)
                                            </option>
                                            <option
                                                data-countryCode="PT"
                                                value="PT-351"
                                            >
                                                Portugal (+351)
                                            </option>
                                            <option
                                                data-countryCode="PR"
                                                value="PR-1787"
                                            >
                                                Puerto Rico (+1787)
                                            </option>
                                            <option
                                                data-countryCode="QA"
                                                value="QA-974"
                                            >
                                                Qatar (+974)
                                            </option>
                                            <option
                                                data-countryCode="RE"
                                                value="RE-262"
                                            >
                                                Reunion (+262)
                                            </option>
                                            <option
                                                data-countryCode="RO"
                                                value="RO-40"
                                            >
                                                Romania (+40)
                                            </option>
                                            <option
                                                data-countryCode="RU"
                                                value="RU-7"
                                            >
                                                Russia (+7)
                                            </option>
                                            <option
                                                data-countryCode="RW"
                                                value="RW-250"
                                            >
                                                Rwanda (+250)
                                            </option>
                                            <option
                                                data-countryCode="SM"
                                                value="SM-378"
                                            >
                                                San Marino (+378)
                                            </option>
                                            <option
                                                data-countryCode="ST"
                                                value="ST-239"
                                            >
                                                Sao Tome &amp; Principe (+239)
                                            </option>
                                            <option
                                                data-countryCode="SA"
                                                value="SA-966"
                                            >
                                                Saudi Arabia (+966)
                                            </option>
                                            <option
                                                data-countryCode="SN"
                                                value="SN-221"
                                            >
                                                Senegal (+221)
                                            </option>
                                            <option
                                                data-countryCode="CS"
                                                value="CS-381"
                                            >
                                                Serbia (+381)
                                            </option>
                                            <option
                                                data-countryCode="SC"
                                                value="SC-248"
                                            >
                                                Seychelles (+248)
                                            </option>
                                            <option
                                                data-countryCode="SL"
                                                value="SL-232"
                                            >
                                                Sierra Leone (+232)
                                            </option>
                                            <option
                                                data-countryCode="SG"
                                                value="SG-65"
                                            >
                                                Singapore (+65)
                                            </option>
                                            <option
                                                data-countryCode="SK"
                                                value="SK-421"
                                            >
                                                Slovak Republic (+421)
                                            </option>
                                            <option
                                                data-countryCode="SI"
                                                value="SI-386"
                                            >
                                                Slovenia (+386)
                                            </option>
                                            <option
                                                data-countryCode="SB"
                                                value="SB-677"
                                            >
                                                Solomon Islands (+677)
                                            </option>
                                            <option
                                                data-countryCode="SO"
                                                value="SO-252"
                                            >
                                                Somalia (+252)
                                            </option>
                                            <option
                                                data-countryCode="ZA"
                                                value="ZA-27"
                                            >
                                                South Africa (+27)
                                            </option>
                                            <option
                                                data-countryCode="ES"
                                                value="ES-34"
                                            >
                                                Spain (+34)
                                            </option>
                                            <option
                                                data-countryCode="LK"
                                                value="LK-94"
                                            >
                                                Sri Lanka (+94)
                                            </option>
                                            <option
                                                data-countryCode="SH"
                                                value="SH-290"
                                            >
                                                St. Helena (+290)
                                            </option>
                                            <option
                                                data-countryCode="KN"
                                                value="KN-1869"
                                            >
                                                St. Kitts (+1869)
                                            </option>
                                            <option
                                                data-countryCode="SC"
                                                value="SC-1758"
                                            >
                                                St. Lucia (+1758)
                                            </option>
                                            <option
                                                data-countryCode="SD"
                                                value="SD-249"
                                            >
                                                Sudan (+249)
                                            </option>
                                            <option
                                                data-countryCode="SR"
                                                value="SR-597"
                                            >
                                                Suriname (+597)
                                            </option>
                                            <option
                                                data-countryCode="SZ"
                                                value="SZ-268"
                                            >
                                                Swaziland (+268)
                                            </option>
                                            <option
                                                data-countryCode="SE"
                                                value="SE-46"
                                            >
                                                Sweden (+46)
                                            </option>
                                            <option
                                                data-countryCode="CH"
                                                value="CH-41"
                                            >
                                                Switzerland (+41)
                                            </option>
                                            <option
                                                data-countryCode="SI"
                                                value="SI-963"
                                            >
                                                Syria (+963)
                                            </option>
                                            <option
                                                data-countryCode="TW"
                                                value="TW-886"
                                            >
                                                Taiwan (+886)
                                            </option>
                                            <option
                                                data-countryCode="TJ"
                                                value="TJ-7"
                                            >
                                                Tajikstan (+7)
                                            </option>
                                            <option
                                                data-countryCode="TH"
                                                value="TH-66"
                                            >
                                                Thailand (+66)
                                            </option>
                                            <option
                                                data-countryCode="TG"
                                                value="TG-228"
                                            >
                                                Togo (+228)
                                            </option>
                                            <option
                                                data-countryCode="TO"
                                                value="TO-676"
                                            >
                                                Tonga (+676)
                                            </option>
                                            <option
                                                data-countryCode="TT"
                                                value="TT-1868"
                                            >
                                                Trinidad &amp; Tobago (+1868)
                                            </option>
                                            <option
                                                data-countryCode="TN"
                                                value="TN-216"
                                            >
                                                Tunisia (+216)
                                            </option>
                                            <option
                                                data-countryCode="TR"
                                                value="TR-90"
                                            >
                                                Turkey (+90)
                                            </option>
                                            <option
                                                data-countryCode="TM"
                                                value="TM-7"
                                            >
                                                Turkmenistan (+7)
                                            </option>
                                            <option
                                                data-countryCode="TM"
                                                value="TM-993"
                                            >
                                                Turkmenistan (+993)
                                            </option>
                                            <option
                                                data-countryCode="TC"
                                                value="TC-1649"
                                            >
                                                Turks &amp; Caicos Islands (+1649)
                                            </option>
                                            <option
                                                data-countryCode="TV"
                                                value="TV-688"
                                            >
                                                Tuvalu (+688)
                                            </option>
                                            <option
                                                data-countryCode="UG"
                                                value="UG-256"
                                            >
                                                Uganda (+256)
                                            </option>
                                            {/* <option data-countryCode="GB" value="44">UK (+44)</option> */}
                                            <option
                                                data-countryCode="UA"
                                                value="UA-380"
                                            >
                                                Ukraine (+380)
                                            </option>
                                            <option
                                                data-countryCode="AE"
                                                value="AE-971"
                                            >
                                                United Arab Emirates (+971)
                                            </option>
                                            <option
                                                data-countryCode="UY"
                                                value="UY-598"
                                            >
                                                Uruguay (+598)
                                            </option>
                                            {/* <option data-countryCode="US" value="1">USA (+1)</option> */}
                                            <option
                                                data-countryCode="UZ"
                                                value="UZ-7"
                                            >
                                                Uzbekistan (+7)
                                            </option>
                                            <option
                                                data-countryCode="VU"
                                                value="VU-678"
                                            >
                                                Vanuatu (+678)
                                            </option>
                                            <option
                                                data-countryCode="VA"
                                                value="VA-379"
                                            >
                                                Vatican City (+379)
                                            </option>
                                            <option
                                                data-countryCode="VE"
                                                value="VE-58"
                                            >
                                                Venezuela (+58)
                                            </option>
                                            <option
                                                data-countryCode="VN"
                                                value="VN-84"
                                            >
                                                Vietnam (+84)
                                            </option>
                                            <option
                                                data-countryCode="VG"
                                                value="VG-84"
                                            >
                                                Virgin Islands - British (+1284)
                                            </option>
                                            <option
                                                data-countryCode="VI"
                                                value="VI-84"
                                            >
                                                Virgin Islands - US (+1340)
                                            </option>
                                            <option
                                                data-countryCode="WF"
                                                value="WF-681"
                                            >
                                                Wallis &amp; Futuna (+681)
                                            </option>
                                            <option
                                                data-countryCode="YE"
                                                value="YE-969"
                                            >
                                                Yemen (North)(+969)
                                            </option>
                                            <option
                                                data-countryCode="YE"
                                                value="YE-967"
                                            >
                                                Yemen (South)(+967)
                                            </option>
                                            <option
                                                data-countryCode="ZM"
                                                value="ZM-260"
                                            >
                                                Zambia (+260)
                                            </option>
                                            <option
                                                data-countryCode="ZW"
                                                value="ZW-263"
                                            >
                                                Zimbabwe (+263)
                                            </option>
                                            </optgroup>
                                        </select>
                                        {/* <span className="personal_detailsform">
                                            <img src={Phone} alt="Icon" className="img-fluid" />
                                        </span> */}
                                    </div>
                                    
                                    <div className="form-group mb-4 col-md-6">
                                        <label for="Number">Phone Number</label>
                                        <input type="Number" className="form-control" name="phoneNumber" placeholder="phone" required 
                                        onChange={(e) => {
                                            onChangeInput(e, "phoneNumber");
                                        }}
                                        value={formData.phoneNumber}
                                        />
                                        <span className="personal_detailsform">
                                            <img src={Phone} alt="Icon" className="img-fluid" />
                                        </span>
                                    </div>
                                </div>
                                <div className="experience">
                                    <div className="details">
                                        <h4>Experience</h4>
                                        <input type="text" className="form-detail" name="experience" placeholder="" 
                                        onChange={(e) => {
                                            onChangeInput(e, "experience");
                                        }}
                                        value={formData.experience}   />
                                    </div>
                                    <div className="details">
                                        <h4>Education</h4>
                                        <input type="text" className="form-detail" name="education" placeholder=""  
                                            onChange={(e) => {
                                                onChangeInput(e, "education");
                                            }}
                                            value={formData.education}
                                        />
                                    </div>
                                </div>
                                <div className="socialMedia_links">
                                    <h4>On the web</h4>
                                    <div className="row">
                                        <div className="form-group mb-4 col-md-6">
                                            <label for="LinkedIn">LinkedIn</label>
                                            <input type="text" className="form-control" name="linkedIn" placeholder="URL"
                                            onChange={(e) => {
                                                onChangeInput(e, "linkedIn");
                                            }}
                                            value={formData.linkedIn}  
                                            />
                                            <span className="personal_detailsform">
                                                <img src={Globe} alt="Icon" className="img-fluid" />
                                            </span>
                                        </div>
                                        <div className="form-group mb-4 col-md-6">
                                            <label for="Facebook">Facebook</label>
                                            <input type="text" className="form-control" name="facebook" placeholder="URL" 
                                            onChange={(e) => {
                                                onChangeInput(e, "facebook");
                                            }}
                                            value={formData.facebook}   />
                                            <span className="personal_detailsform">
                                                <img src={Globe} alt="Icon" className="img-fluid" />
                                            </span>
                                        </div>
                                        <div className="form-group mb-4 col-md-6">
                                            <label for="Twitter">Twitter</label>
                                            <input type="email" className="form-control" name="twitter" placeholder="URL"
                                            onChange={(e) => {
                                                onChangeInput(e, "twitter");
                                            }}
                                            value={formData.twitter} />
                                            <span className="personal_detailsform">
                                                <img src={Globe} alt="Icon" className="img-fluid" />
                                            </span>
                                        </div>
                                        <div className="form-group mb-4 col-md-6">
                                            <label for="Website">Website</label>
                                            <input type="email" className="form-control" name="website" placeholder="URL" 
                                            onChange={(e) => {
                                                onChangeInput(e, "website");
                                            }}
                                            value={formData.website}
                                            />
                                            <span className="personal_detailsform">
                                                <img src={Globe} alt="Icon" className="img-fluid" />
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className="upload_box">
                                <h4>Resume</h4>
                                {credLoading ? 
                                    <>
                                    <div className="row" style={{ width: "100%", height: "80vh" }}>
                                        <div
                                            className="col-12"
                                            style={{ width: "100%", height: "100%" }}
                                        >
                                            <div
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                                lineHeight: "40vh",
                                            }}
                                            >
                                            <div
                                                className="spinner-grow"
                                                role="status"
                                                style={{ background: "#006838" }}
                                            ></div>
                                            </div>
                                        </div>
                                    </div>
                                    </> 
                                :
                                <>
                                    {uploadResume.length > 0 ?
                                        <>
                                            <div className="row"> 
                                                <div className="col-md-4 text-center">
                                                    <i className='fa fa-file-text' style={{ color: 'green', fontSize: '25px'}}></i>
                                                </div>
                                                <div className="col-md-4 text-center">
                                                    <h4>{uploadResume[0]?.name}</h4>
                                                </div>
                                                <div className="col-md-4 text-center">
                                                    <i className='fa fa-times' style={{ color: 'grey', fontSize: '25px'}} onClick={() => {deleteResume(uploadResume[0]?.fileHashId)}}></i>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <input type="file" id="file" className="d-none" />
                                            <label for="file">
                                                <div className="file-button">
                                                    <span className="uploadIcon"><img src={Upload} /><small>Browse resume</small> or just drop it here</span>
                                                </div>
                                            </label>
                                        </>
                                    }
                                </>
                                }
                                </div>
                                <div className="message_boxmain">
                                    <h4>Message to Hiring Manager</h4>
                                    <p className="message_subheading">Let the company know about your interest working there</p>
                                    <textarea className="message_box" rows="6" cols="10" placeholder="Type here"
                                    onChange={(e) => {
                                        onChangeInput(e, "message");
                                    }}
                                    value={formData.message}
                                    >
                                    </textarea>
                                </div>
                                <div className="btnNext">
                                    <button className="sliderFormBtn">
                                        <Link to="/details">Next</Link>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </>
            }   
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
    getJobDetailsLanding: bindActionCreators(getJobDetailsLanding,dispatch),
    getResume: bindActionCreators(getResume, dispatch),
    deleteResumeCred: bindActionCreators(deleteResumeCred,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyJob);
