import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { getTalentProfile } from "../../actions/employer/talent";
import { getJobDetails, getFieldValuesForEditing, editJob, getSkillBio, getFormFieldsEntity, getDailyCalendarDetails, postJobDateAndTime } from "../../actions/employer/post";
import { getFormFieldsPrespective } from "../../actions/employee/skill";
import { getDaysList, getActiveDaysList } from "../../actions/employer/talent";
import { getUserInformation } from "../../actions/user";
import { getHomeScreenList } from "../../actions/employee/dashboard";
import talent_edit from '../../public/images/talent_edit.png';
import downArrow from "../../public/images/downArrow.svg";
import inputFieldGenerator from "./fieldGenerator";
import inputFieldGenerator2 from "./fieldGenerator2";
import daysFieldGenerator from "./daysFieldGenerator";
import goldBadge from "../../public/images/goldBadge.svg";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import { Button, Modal, Form } from "react-bootstrap";
import swal from 'sweetalert';
import { toast } from "react-toastify";
import moment from 'moment-timezone';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import Calendar from 'react-calendar';
import { showBadge } from '../../../utilities/helpers';
import locationImg from '../../public/images/location.png';


const JobDetails = (props) => {
    const [userIdHash, setUserIdHash] = useState("");
    const [loading, setLoading] = useState(true);
    const [editDisabled, setEditDisabled] = useState(true);
    const [closeEditDisabled, setCloseEditDisabled] = useState(false);
    const [talentProfileData, setTalentProfileData] = useState("");
    const [employmentDataForShift, setEmploymentDataForShift] = useState([]);
    const [scheduleDataForShift, setScheduleDataForShift] = useState([]);
    const [facilityDataForShift, setFacilityDataForShift] = useState([]);
    const [payrollDataForShift, setPayrollDataForShift] = useState([]);
    const [shiftDataForShift, setShiftDataForShift] = useState([]);
    const [paymentDataForShift, setPaymentDataForShift] = useState([]);
    const [filterShow, setFilterShow] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [gettingField, setGettingField] = useState(false);
    const [gettingFieldJobSetting, setGettingFieldJobSetting] = useState(false);
    const [fieldValues, setFieldValues] = useState({});
    const [fieldMetaData, setFieldMetaData] = useState(null);
    const [fieldValuesJobSetting, setFieldValuesJobSetting] = useState({});
    const [fieldMetaDataJobSetting, setFieldMetaDataJobSetting] = useState(null);
    const [fieldValuesDays, setFieldValuesDays] = useState({});
    const [fieldMetaDataDays, setFieldMetaDataDays] = useState(null);
    const [activeDays, setActiveDays] = useState([]);
    const [jobInformationFlag, setJobInformationFlag] = useState(true);
    const [jobSettingFlag, setJobSettingFlag] = useState(false);
    const [weeklyCalendarFlag, setWeeklyCalendarFlag] = useState(false);
    const [dailyCalendarFlag, setDailyCalendarFlag] = useState(false);
    const [aboutEmployerFlag, setAboutEmployerFlag] = useState(false);
    const [fullTimeData, setFullTimeData] = useState({});
    const [fullTimeDataFlag, setFullTimeDataFlag] = useState(false);
    const [partTimeData, setPartTimeData] = useState({});
    const [partTimeDataFlag, setPartTimeDataFlag] = useState(false);
    const [contractData, setContractData] = useState({});
    const [contractDataFlag, setContractDataFlag] = useState(false);

    const [entityId, setEntityId] = useState();

    const [employmentTypeData, setEmploymentTypeData] = useState([]);

    const [calendarFlag, setCalendarFlag] = useState(false);

    const [contractStartDate, setContractStartDate] = useState()
    const [showContractStartDate, setShowContractStartDate] = useState()
    const [contractEndDate, setContractEndDate] = useState()
    const [showContractEndDate, setShowContractEndDate] = useState()
    const [contractStartTime, setContractStartTime] = useState()
    const [contractEndTime, setContractEndTime] = useState()
    const [finalContractStartDate, setFinalContractStartDate] = useState()
    const [finalContractEndDate, setFinalContractEndDate] = useState()
    const [contractProviderCount, setContractProviderCount] = useState()
    const [contractStartTimeFlag, setContractStartTimeFlag] = useState(false);
    const [contractEndTimeFlag, setContractEndTimeFlag] = useState(false);

    const [fullTimeStartDate, setFullTimeStartDate] = useState()
    const [showFullTimeStartDate, setShowFullTimeStartDate] = useState()
    const [fullTimeEndDate, setFullTimeEndDate] = useState()
    const [showfullTimeEndDate, setShowFullTimeEndDate] = useState()
    const [fullTimeStartTime, setFullTimeStartTime] = useState()
    const [fullTimeEndTime, setFullTimeEndTime] = useState()
    const [finalfullTimeStartDate, setFinalFullTimeStartDate] = useState()
    const [finalfullTimeEndDate, setFinalFullTimeEndDate] = useState()
    const [fullTimeProviderCount, setFullTimeProviderCount] = useState()
    const [fullTimeStartTimeFlag, setFullTimeStartTimeFlag] = useState(false);
    const [fullTimeEndTimeFlag, setFullTimeEndTimeFlag] = useState(false);

    const [partTimeStartDate, setPartTimeStartDate] = useState()
    const [finalPartTimeStartDate, setFinalPartTimeStartDate] = useState()
    const [partTimeInputDateFlag, setPartTimeInputDateFlag] = useState(false)

    const [partTimeStartTime1, setPartTimeStartTime1] = useState()
    const [finalPartTimeStartDate1, setFinalPartTimeStartDate1] = useState()
    const [partTimeEndTime1, setPartTimeEndTime1] = useState()
    const [finalPartTimeEndDate1, setFinalPartTimeEndDate1] = useState()
    const [partTimeStartTimeFlag1, setPartTimeStartTimeFlag1] = useState(false)
    const [partTimeStartTime1Modal, setPartTimeStartTime1Modal] = useState(false)
    const [partTimeEndTimeFlag1, setPartTimeEndTimeFlag1] = useState(false)
    const [partTimeEndTime1Modal, setPartTimeEndTime1Modal] = useState(false)
    const [partTimeProviderCount1, setPartTimeProviderCount1] = useState()

    const [partTimeStartTime2, setPartTimeStartTime2] = useState()
    const [finalPartTimeStartDate2, setFinalPartTimeStartDate2] = useState()
    const [partTimeEndTime2, setPartTimeEndTime2] = useState()
    const [finalPartTimeEndDate2, setFinalPartTimeEndDate2] = useState()
    const [partTimeStartTimeFlag2, setPartTimeStartTimeFlag2] = useState(false)
    const [partTimeStartTime2Modal, setPartTimeStartTime2Modal] = useState(false)
    const [partTimeEndTimeFlag2, setPartTimeEndTimeFlag2] = useState(false)
    const [partTimeEndTime2Modal, setPartTimeEndTime2Modal] = useState(false)
    const [partTimeProviderCount2, setPartTimeProviderCount2] = useState()

    const [partTimeStartTime3, setPartTimeStartTime3] = useState()
    const [finalPartTimeStartDate3, setFinalPartTimeStartDate3] = useState()
    const [partTimeEndTime3, setPartTimeEndTime3] = useState()
    const [finalPartTimeEndDate3, setFinalPartTimeEndDate3] = useState()
    const [partTimeStartTimeFlag3, setPartTimeStartTimeFlag3] = useState(false)
    const [partTimeStartTime3Modal, setPartTimeStartTime3Modal] = useState(false)
    const [partTimeEndTimeFlag3, setPartTimeEndTimeFlag3] = useState(false)
    const [partTimeEndTime3Modal, setPartTimeEndTime3Modal] = useState(false)
    const [partTimeProviderCount3, setPartTimeProviderCount3] = useState()

    const [partTimeStartTime4, setPartTimeStartTime4] = useState()
    const [finalPartTimeStartDate4, setFinalPartTimeStartDate4] = useState()
    const [partTimeEndTime4, setPartTimeEndTime4] = useState()
    const [finalPartTimeEndDate4, setFinalPartTimeEndDate4] = useState()
    const [partTimeStartTimeFlag4, setPartTimeStartTimeFlag4] = useState(false)
    const [partTimeStartTime4Modal, setPartTimeStartTime4Modal] = useState(false)
    const [partTimeEndTimeFlag4, setPartTimeEndTimeFlag4] = useState(false)
    const [partTimeEndTime4Modal, setPartTimeEndTime4Modal] = useState(false)
    const [partTimeProviderCount4, setPartTimeProviderCount4] = useState()

    const [partTimeStartTime5, setPartTimeStartTime5] = useState()
    const [finalPartTimeStartDate5, setFinalPartTimeStartDate5] = useState()
    const [partTimeEndTime5, setPartTimeEndTime5] = useState()
    const [finalPartTimeEndDate5, setFinalPartTimeEndDate5] = useState()
    const [partTimeStartTimeFlag5, setPartTimeStartTimeFlag5] = useState(false)
    const [partTimeStartTime5Modal, setPartTimeStartTime5Modal] = useState(false)
    const [partTimeEndTimeFlag5, setPartTimeEndTimeFlag5] = useState(false)
    const [partTimeEndTime5Modal, setPartTimeEndTime5Modal] = useState(false)
    const [partTimeProviderCount5, setPartTimeProviderCount5] = useState()

    const [shift2Flag, setShift2Flag] = useState(false)
    const [shift3Flag, setShift3Flag] = useState(false)
    const [shift4Flag, setShift4Flag] = useState(false)
    const [shift5Flag, setShift5Flag] = useState(false)

    const [shiftCount, setShiftCount] = useState(0)

    const [userProfileData, setUserProfileData] = useState({})

    const history = useHistory();
    const location = useLocation();
    const { state } = location

    useEffect(() => {
        getTalentProfileData({ userProfileId: props.match.params.talentId })
        getJobDetailsData({ userProfileId: props.match.params.talentId, entityId: props.match.params.entityId })
        getSkillBio()
        getDaysListFn({ userProfileId: props.match.params.talentId })
        getDailyCalendarData({ userProfileId: props.match.params.talentId, entityId: props.match.params.entityId })
        getUserInformationData()
        getHomeScreenList("")
    }, [])

    const getTalentProfileData = (params) => {
        props.getTalentProfile(params, res => {
            if (res.status) {
                console.log('TALENT DATA', res.data)
                setTalentProfileData(res.data)
                if (res.data.userProfileDataTypes.employmentType.length > 0) {
                    let arr = []
                    setEmploymentTypeData(res.data.userProfileDataTypes.employmentType)
                    for (let i = 0; i < res.data.userProfileDataTypes.employmentType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.employmentType[i].name)
                    }
                    setEmploymentDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.facilityType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.facilityType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.facilityType[i].name)
                    }
                    setFacilityDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.paymentType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.paymentType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.paymentType[i].name)
                    }
                    setPaymentDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.scheduleType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.scheduleType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.scheduleType[i].name)
                    }
                    setScheduleDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.shiftType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.shiftType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.shiftType[i].name)
                    }
                    setShiftDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.payrollType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.payrollType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.payrollType[i].name)
                    }
                    setPayrollDataForShift(arr)
                }
            } else {

            }
        })
    }

    const getJobDetailsData = (params) => {
        props.getJobDetails(params, res => {
            setLoading(false);
            if (res.status) {
                setFieldMetaData(res.data.fields);
                if (res.data.fields.length) {
                    let obj = {}
                    res.data.fields.map(r => {
                        obj[r.field.entityAttributeFieldKey] = r.field.fieldValue
                    })
                    setFieldValues(obj)
                }
            }
        })
    }

    const getDaysListFn = (params) => {
        props.getDaysList(params, resDay => {
            if (resDay.status) {
                if (resDay.data.length > 0) {
                    let daysArray = []
                    for (let i = 0; i < resDay.data.length; i++) {
                        let obj = {}
                        obj.day = resDay.data[i].day
                        obj.startTime = '--'
                        obj.endTime = '--'
                        daysArray.push(obj)
                    }
                    if (daysArray.length) {
                        props.getActiveDaysList(params, resAct => {
                            if (resAct.status) {
                                if (resAct.data.length) {
                                    for (let i = 0; i < daysArray.length; i++) {
                                        for (let j = 0; j < resAct.data.length; j++) {
                                            if ((daysArray[i].day).toLowerCase() == resAct.data[j].day) {
                                                daysArray[i].startTime = resAct.data[j].startHour + ' : ' + (resAct.data[j].startMinute).toString().padStart(2, '0') + ' ' + (resAct.data[j].shiftStartMeridian).toUpperCase()
                                                daysArray[i].endTime = resAct.data[j].endHour + ' : ' + (resAct.data[j].endMinute).toString().padStart(2, '0') + ' ' + (resAct.data[j].shiftEndMeridian).toUpperCase()
                                            }
                                        }
                                    }
                                    setFieldMetaDataDays(daysArray)
                                } else {
                                    setFieldMetaDataDays(daysArray)
                                }
                            }
                        })
                    }
                }
            }
        })
    }

    const getDailyCalendarData = (params) => {
        props.getDailyCalendarDetails(params, res => {
            if (res.status) {
                if (res.data) {

                    if (res.data['1']) {
                        setFullTimeData(res.data['1'])
                        setFullTimeDataFlag(true)
                        let newDate = new Date((res.data['1'].shifts[0].startTime).toString().substring(0, 10))
                        let newDate2 = newDate.toString().substring(4, 15)
                        let time = (res.data['1'].shifts[0].startTime).toString().substring(11, 16)
                        setShowFullTimeStartDate(newDate2 + ', ' + time)
                        setFullTimeStartDate(newDate)
                        setFullTimeStartTime(time)
                        setFullTimeProviderCount(res.data['1'].shifts[0].providerCount)
                        setFinalFullTimeStartDate(newDate + ', ' + time)
                    }
                    if (res.data['2']) {
                        setPartTimeData(res.data['2'])
                        setPartTimeDataFlag(true)
                        setPartTimeInputDateFlag(true)
                        let newDate = new Date((res.data['2'].shifts[0].startTime).toString().substring(0, 10))
                        setPartTimeStartDate(newDate.toString().substring(4, 15))
                        setFinalPartTimeStartDate(newDate)

                        setPartTimeStartTimeFlag1(true)
                        setPartTimeStartTime1((res.data['2'].shifts[0].startTime).toString().substring(11, 16))
                        setPartTimeEndTimeFlag1(true)
                        setPartTimeEndTime1((res.data['2'].shifts[0].endTime).toString().substring(11, 16))

                        setPartTimeProviderCount1(res.data['2'].shifts[0].providerCount)
                        setFinalPartTimeStartDate1(newDate + ', ' + (res.data['2'].shifts[0].startTime).toString().substring(11, 16))
                        setFinalPartTimeEndDate1(newDate + ', ' + (res.data['2'].shifts[0].endTime).toString().substring(11, 16))

                        let shiftLength = res.data['2'].shifts.length

                        if (shiftLength == 2) {
                            setShift2Flag(true)
                            setPartTimeStartTimeFlag2(true)
                            setPartTimeStartTime2((res.data['2'].shifts[1].startTime).toString().substring(11, 16))
                            setPartTimeEndTimeFlag2(true)
                            setPartTimeEndTime2((res.data['2'].shifts[1].endTime).toString().substring(11, 16))
                            setPartTimeProviderCount2(res.data['2'].shifts[1].providerCount)
                            setFinalPartTimeStartDate2(newDate + ', ' + (res.data['2'].shifts[1].startTime).toString().substring(11, 16))
                            setFinalPartTimeEndDate2(newDate + ', ' + (res.data['2'].shifts[1].endTime).toString().substring(11, 16))
                        } else if (shiftLength == 3) {
                            setShift2Flag(true)
                            setPartTimeStartTimeFlag2(true)
                            setPartTimeStartTime2((res.data['2'].shifts[1].startTime).toString().substring(11, 16))
                            setPartTimeEndTimeFlag2(true)
                            setPartTimeEndTime2((res.data['2'].shifts[1].endTime).toString().substring(11, 16))
                            setPartTimeProviderCount2(res.data['2'].shifts[1].providerCount)
                            setFinalPartTimeStartDate2(newDate + ', ' + (res.data['2'].shifts[1].startTime).toString().substring(11, 16))
                            setFinalPartTimeEndDate2(newDate + ', ' + (res.data['2'].shifts[1].endTime).toString().substring(11, 16))

                            setShift3Flag(true)
                            setPartTimeStartTimeFlag3(true)
                            setPartTimeStartTime3((res.data['2'].shifts[2].startTime).toString().substring(11, 16))
                            setPartTimeEndTimeFlag3(true)
                            setPartTimeEndTime3((res.data['2'].shifts[2].endTime).toString().substring(11, 16))
                            setPartTimeProviderCount3(res.data['2'].shifts[2].providerCount)
                            setFinalPartTimeStartDate3(newDate + ', ' + (res.data['2'].shifts[2].startTime).toString().substring(11, 16))
                            setFinalPartTimeEndDate3(newDate + ', ' + (res.data['2'].shifts[2].endTime).toString().substring(11, 16))
                        } else if (shiftLength == 4) {
                            setShift2Flag(true)
                            setPartTimeStartTimeFlag2(true)
                            setPartTimeStartTime2((res.data['2'].shifts[1].startTime).toString().substring(11, 16))
                            setPartTimeEndTimeFlag2(true)
                            setPartTimeEndTime2((res.data['2'].shifts[1].endTime).toString().substring(11, 16))
                            setPartTimeProviderCount2(res.data['2'].shifts[1].providerCount)
                            setFinalPartTimeStartDate2(newDate + ', ' + (res.data['2'].shifts[1].startTime).toString().substring(11, 16))
                            setFinalPartTimeEndDate2(newDate + ', ' + (res.data['2'].shifts[1].endTime).toString().substring(11, 16))

                            setShift3Flag(true)
                            setPartTimeStartTimeFlag3(true)
                            setPartTimeStartTime3((res.data['2'].shifts[2].startTime).toString().substring(11, 16))
                            setPartTimeEndTimeFlag3(true)
                            setPartTimeEndTime3((res.data['2'].shifts[2].endTime).toString().substring(11, 16))
                            setPartTimeProviderCount3(res.data['2'].shifts[2].providerCount)
                            setFinalPartTimeStartDate3(newDate + ', ' + (res.data['2'].shifts[2].startTime).toString().substring(11, 16))
                            setFinalPartTimeEndDate3(newDate + ', ' + (res.data['2'].shifts[2].endTime).toString().substring(11, 16))

                            setShift4Flag(true)
                            setPartTimeStartTimeFlag4(true)
                            setPartTimeStartTime4((res.data['2'].shifts[3].startTime).toString().substring(11, 16))
                            setPartTimeEndTimeFlag4(true)
                            setPartTimeEndTime4((res.data['2'].shifts[3].endTime).toString().substring(11, 16))
                            setPartTimeProviderCount4(res.data['2'].shifts[3].providerCount)
                            setFinalPartTimeStartDate4(newDate + ', ' + (res.data['2'].shifts[3].startTime).toString().substring(11, 16))
                            setFinalPartTimeEndDate4(newDate + ', ' + (res.data['2'].shifts[3].endTime).toString().substring(11, 16))
                        } else if (shiftLength == 5) {
                            setShift2Flag(true)
                            setPartTimeStartTimeFlag2(true)
                            setPartTimeStartTime2((res.data['2'].shifts[1].startTime).toString().substring(11, 16))
                            setPartTimeEndTimeFlag2(true)
                            setPartTimeEndTime2((res.data['2'].shifts[1].endTime).toString().substring(11, 16))
                            setPartTimeProviderCount2(res.data['2'].shifts[1].providerCount)
                            setFinalPartTimeStartDate2(newDate + ', ' + (res.data['2'].shifts[1].startTime).toString().substring(11, 16))
                            setFinalPartTimeEndDate2(newDate + ', ' + (res.data['2'].shifts[1].endTime).toString().substring(11, 16))

                            setShift3Flag(true)
                            setPartTimeStartTimeFlag3(true)
                            setPartTimeStartTime3((res.data['2'].shifts[2].startTime).toString().substring(11, 16))
                            setPartTimeEndTimeFlag3(true)
                            setPartTimeEndTime3((res.data['2'].shifts[2].endTime).toString().substring(11, 16))
                            setPartTimeProviderCount3(res.data['2'].shifts[2].providerCount)
                            setFinalPartTimeStartDate3(newDate + ', ' + (res.data['2'].shifts[2].startTime).toString().substring(11, 16))
                            setFinalPartTimeEndDate3(newDate + ', ' + (res.data['2'].shifts[2].endTime).toString().substring(11, 16))

                            setShift4Flag(true)
                            setPartTimeStartTimeFlag4(true)
                            setPartTimeStartTime4((res.data['2'].shifts[3].startTime).toString().substring(11, 16))
                            setPartTimeEndTimeFlag4(true)
                            setPartTimeEndTime4((res.data['2'].shifts[3].endTime).toString().substring(11, 16))
                            setPartTimeProviderCount4(res.data['2'].shifts[3].providerCount)
                            setFinalPartTimeStartDate4(newDate + ', ' + (res.data['2'].shifts[3].startTime).toString().substring(11, 16))
                            setFinalPartTimeEndDate4(newDate + ', ' + (res.data['2'].shifts[3].endTime).toString().substring(11, 16))

                            setShift5Flag(true)
                            setPartTimeStartTimeFlag5(true)
                            setPartTimeStartTime5((res.data['2'].shifts[4].startTime).toString().substring(11, 16))
                            setPartTimeEndTimeFlag5(true)
                            setPartTimeEndTime5((res.data['2'].shifts[4].endTime).toString().substring(11, 16))
                            setPartTimeProviderCount5(res.data['2'].shifts[4].providerCount)
                            setFinalPartTimeStartDate5(newDate + ', ' + (res.data['2'].shifts[4].startTime).toString().substring(11, 16))
                            setFinalPartTimeEndDate5(newDate + ', ' + (res.data['2'].shifts[4].endTime).toString().substring(11, 16))
                        }
                    }
                    if (res.data['3']) {
                        setContractData(res.data['3'])
                        setContractDataFlag(true)
                        let newDate = new Date((res.data['3'].shifts[0].startTime).toString().substring(0, 10))
                        let newDate2 = newDate.toString().substring(4, 15)
                        let time = (res.data['3'].shifts[0].startTime).toString().substring(11, 16)

                        setContractStartDate(newDate)
                        setContractStartTime(time)
                        setContractProviderCount(res.data['3'].shifts[0].providerCount)
                        setFinalContractStartDate(newDate + ', ' + time)

                        setShowContractStartDate(newDate2 + ', ' + time)

                        let newEndDate = new Date((res.data['3'].shifts[0].endTime).toString().substring(0, 10))
                        let newEndDate2 = newEndDate.toString().substring(4, 15)
                        let endtime = (res.data['3'].shifts[0].endTime).toString().substring(11, 16)

                        setContractEndDate(newEndDate)
                        setContractEndTime(endtime)
                        setContractProviderCount(res.data['3'].shifts[0].providerCount)
                        setFinalContractEndDate(newEndDate + ', ' + endtime)

                        setShowContractEndDate(newEndDate2 + ', ' + endtime)

                    }
                }
            }
        })
    }

    const getUserInformationData = () => {
        props.getUserInformation({}, res => {
            console.log('User Info', res);
            if(res.status) {
                setUserProfileData(res.data)
            } else {
                setUserProfileData({})
            }
        })
    }

    const getHomeScreenList = (entityTypeId, userProfileIds, searchText) => {
        props.getHomeScreenList(
          {
            userIdHash: props.auth.token, entityTypeId: entityTypeId, userProfileIds: userProfileIds,
            searchText: searchText,
          },
          (res) => {
            if(res.status) {
                if(res.data.length > 0) {
                    res.data.map(list => {
                        if(list.id == props.match.params.talentId) {
                            console.log('User Profile Data', list.userProfileData)
                            setUserProfileData(list.userProfileData)
                        }
                    })
                }
            }
          }
        );
      };

    const onInputChange = (fieldName, value, e) => {
        setEditDisabled(false)
        setFieldValues(() => ({
            ...fieldValues,
            [fieldName]: value,
        }));
    };

    const fields = [];
    const renderedFields = {};

    const renderField = (fieldMetaData, parentFieldValue) => {
        if (!Array.isArray(fieldMetaData)) return null;
        fieldMetaData?.map(({ field, childrenFields }, index) => {
            if (
                parentFieldValue !== undefined &&
                renderedFields[field?.entityAttributeFieldKey] !== undefined
            ) {
                fields[renderedFields[field?.entityAttributeFieldKey]] = (
                    <div className="col-md-12 mb-3">
                        {inputFieldGenerator(
                            field,
                            fieldValues[field?.entityAttributeFieldKey],
                            onInputChange,
                            parentFieldValue,
                            props
                        )}
                    </div>
                );
            }

            if (renderedFields[field?.entityAttributeFieldKey] === undefined) {
                renderedFields[field?.entityAttributeFieldKey] = fields.length;
                fields.push(
                    <div className="col-md-12 mb-3">
                        {inputFieldGenerator(
                            field,
                            fieldValues[field?.entityAttributeFieldKey],
                            onInputChange,
                            parentFieldValue,
                            props
                        )}
                    </div>
                );
            }

            if (childrenFields) {
                renderField(
                    childrenFields[0],
                    fieldValues[field?.entityAttributeFieldKey]
                );
            }
        });
    };

    const fieldElements = useMemo(() => {
        if (fieldMetaData) {
            renderField(fieldMetaData);
            return fields;
        } else return [];
    }, [fieldMetaData, fieldValues]);

    const fields2 = [];
    const renderedFields2 = {};

    const renderField2 = (fieldMetaData, parentFieldValue) => {
        if (!Array.isArray(fieldMetaData)) return null;
        fieldMetaData?.map(({ field, childrenFields }, index) => {
            if (
                parentFieldValue !== undefined &&
                renderedFields2[field?.entityAttributeFieldKey] !== undefined
            ) {
                fields2[renderedFields2[field?.entityAttributeFieldKey]] = (
                    <div className="col-md-12 mb-3">
                        {inputFieldGenerator2(
                            field,
                            fieldValues[field?.entityAttributeFieldKey],
                            onInputChange,
                            parentFieldValue,
                            props
                        )}
                    </div>
                );
            }

            if (renderedFields2[field?.entityAttributeFieldKey] === undefined) {
                renderedFields2[field?.entityAttributeFieldKey] = fields2.length;
                fields2.push(
                    <div className="col-md-12 mb-3">
                        {inputFieldGenerator2(
                            field,
                            fieldValues[field?.entityAttributeFieldKey],
                            onInputChange,
                            parentFieldValue,
                            props
                        )}
                    </div>
                );
            }

            if (childrenFields) {
                renderField2(
                    childrenFields[0],
                    fieldValues[field?.entityAttributeFieldKey]
                );
            }
        });
    };

    const fieldElements2 = useMemo(() => {
        if (fieldMetaData) {
            renderField2(fieldMetaData);
            return fields2;
        } else return [];
    }, [fieldMetaData, fieldValues]);

    const fields3 = [];
    const renderedFields3 = {};

    const renderField3 = (fieldMetaDataJobSetting, parentFieldValue) => {
        if (!Array.isArray(fieldMetaDataJobSetting)) return null;
        fieldMetaDataJobSetting?.map(({ field, childrenFields }, index) => {
            if (
                parentFieldValue !== undefined &&
                renderedFields3[field?.entityAttributeFieldKey] !== undefined
            ) {
                fields3[renderedFields3[field?.entityAttributeFieldKey]] = (
                    <div className="col-md-12 mb-3">
                        {inputFieldGenerator(
                            field,
                            fieldValuesJobSetting[field?.entityAttributeFieldKey],
                            onInputChange,
                            parentFieldValue,
                            props
                        )}
                    </div>
                );
            }

            if (renderedFields3[field?.entityAttributeFieldKey] === undefined) {
                renderedFields3[field?.entityAttributeFieldKey] = fields3.length;
                fields3.push(
                    <div className="col-md-12 mb-3">
                        {inputFieldGenerator(
                            field,
                            fieldValuesJobSetting[field?.entityAttributeFieldKey],
                            onInputChange,
                            parentFieldValue,
                            props
                        )}
                    </div>
                );
            }

            if (childrenFields) {
                renderField3(
                    childrenFields[0],
                    fieldValuesJobSetting[field?.entityAttributeFieldKey]
                );
            }
        });
    };

    const fieldElements3 = useMemo(() => {
        if (fieldMetaDataJobSetting) {
            renderField3(fieldMetaDataJobSetting);
            return fields3;
        } else return [];
    }, [fieldMetaDataJobSetting, fieldValuesJobSetting]);

    const onInputChangeDays = (fieldName, value, e) => {
        setFieldValuesDays(() => ({
            ...fieldValuesDays,
            [fieldName]: value,
        }));
    };

    const fields4 = [];
    const renderedFields4 = {};

    const renderField4 = (fieldMetaDataDays, parentFieldValue) => {
        if (!Array.isArray(fieldMetaDataDays)) return null;
        fieldMetaDataDays?.map((day, index) => {
            fields4.push(
                <div className="col-md-12 mb-3">
                    {daysFieldGenerator(
                        day,
                        fieldValuesDays,
                        onInputChange,
                        parentFieldValue,
                        props
                    )}
                </div>
            );
        });
    };

    const fieldElements4 = useMemo(() => {
        if (fieldMetaDataDays) {
            renderField4(fieldMetaDataDays);
            return fields4;
        } else return [];
    }, [fieldMetaDataDays, fieldValuesDays]);

    const getSkillBio = () => {
        setGettingFieldJobSetting(true);
        props.getSkillBio(
            { userIdHash: props.auth.userProfile.userIdHash, userProfileId: props.match.params.talentId },
            (res) => {
                if (res.status) {
                    if (res.data.entityInfo) {
                        getFormFieldsEntity(res.data.entityInfo.id);
                    } else {
                        getFormFieldsPrespective({
                            perspectiveId: res.data.perspectiveInfo.id,
                            userProfileId: props.match.params.talentId,
                        });
                    }
                }
            }
        );
    };

    const getFormFieldsPrespective = (data) => {
        setGettingFieldJobSetting(true);
        props.getFormFieldsPrespective(
            { perspectiveId: data.perspectiveId, userProfileId: data.userProfileId },
            (res) => {
                setGettingFieldJobSetting(false);
                if (res.status) {
                    setFieldMetaDataJobSetting(res.data);
                }
            }
        );
    };

    const getFormFieldsEntity = (id) => {
        setGettingFieldJobSetting(true);
        props.getFormFieldsEntity({ entityId: id }, (res) => {
            setGettingFieldJobSetting(false);
            if (res.status) {
                setFieldMetaDataJobSetting(res.data);
            }
        });
    };

    const columnClicked = (flag) => {
        if (flag == 'jobInformation') {
            setJobInformationFlag(true)
            setJobSettingFlag(false)
            setWeeklyCalendarFlag(false)
            setDailyCalendarFlag(false)
            setAboutEmployerFlag(false)
        } else if (flag == 'jobSetting') {
            setJobInformationFlag(false)
            setJobSettingFlag(true)
            setWeeklyCalendarFlag(false)
            setDailyCalendarFlag(false)
            setAboutEmployerFlag(false)
        } else if (flag == 'weeklyCalendar') {
            setJobInformationFlag(false)
            setJobSettingFlag(false)
            setWeeklyCalendarFlag(true)
            setDailyCalendarFlag(false)
            setAboutEmployerFlag(false)
        } else if (flag == 'dailyCalendar') {
            getDailyCalendarData({ userProfileId: props.match.params.talentId, entityId: props.match.params.entityId })
            setJobInformationFlag(false)
            setJobSettingFlag(false)
            setWeeklyCalendarFlag(false)
            setDailyCalendarFlag(true)
            setAboutEmployerFlag(false)
        } else if (flag == 'aboutEmployer') {
            setJobInformationFlag(false)
            setJobSettingFlag(false)
            setWeeklyCalendarFlag(false)
            setDailyCalendarFlag(false)
            setAboutEmployerFlag(true)
        }
    }

    const jobInformationStyleActive = () => {
        return {
            border: '1px solid #3abd00',
            borderRadius: '5px',
            color: '#3abd00'
        }
    }
    const jobInformationStyleInActive = () => {
        return {
            border: '0px',
            borderRadius: '0px',
            color: 'black'
        }
    }
    const jobSettingStyleActive = () => {
        return {
            border: '1px solid #3abd00',
            borderRadius: '5px',
            color: '#3abd00'
        }
    }
    const jobSettingStyleInActive = () => {
        return {
            border: '0px',
            borderRadius: '0px',
            color: 'black'
        }
    }
    const weeklyCalendarStyleActive = () => {
        return {
            border: '1px solid #3abd00',
            borderRadius: '5px',
            color: '#3abd00'
        }
    }
    const weeklyCalendarStyleInActive = () => {
        return {
            border: '0px',
            borderRadius: '0px',
            color: 'black'
        }
    }
    const dailyCalendarStyleActive = () => {
        return {
            border: '1px solid #3abd00',
            borderRadius: '5px',
            color: '#3abd00'
        }
    }
    const dailyCalendarStyleInActive = () => {
        return {
            border: '0px',
            borderRadius: '0px',
            color: 'black'
        }
    }
    const aboutEmployerStyleActive = () => {
        return {
            border: '1px solid #3abd00',
            borderRadius: '5px',
            color: '#3abd00'
        }
    }
    const aboutEmployerStyleInActive = () => {
        return {
            border: '0px',
            borderRadius: '0px',
            color: 'black'
        }
    }

    let contractObject = {}
    let fullTimeObject = {}
    let partTimeObject1 = {}
    let partTimeObject2 = {}
    let partTimeObject3 = {}
    let partTimeObject4 = {}
    let partTimeObject5 = {}

    //CONTRACT SHIFT START DATE AND TIME
    const changeStartDateForContract = (value) => {
        setContractStartDate(value)
        setContractStartTimeFlag(true)
        setContractStartTime()
    }
    const changeStartTimeForContract = (value) => {
        setContractStartTime(value)
    }
    const selectStartTimeForContract = () => {
        if (contractStartTime == null) {
            toast.error('Please select Contract Start Time')
            return
        }
        setFinalContractStartDate(contractStartDate + ', ' + contractStartTime)
        setShowContractStartDate(contractStartDate.toString().substring(4, 15) + ', ' + contractStartTime)
        setContractStartTimeFlag(false)
    }

    //CONTRACT SHIFT END DATE AND TIME
    const changeEndDateForContract = (value) => {
        setContractEndDate(value)
        setContractEndTimeFlag(true)
        setContractEndTime()
    }
    const changeEndTimeForContract = (value) => {
        setContractEndTime(value)
    }
    const selectEndTimeForContract = () => {
        if (contractEndTime == null) {
            toast.error('Please select Contract End Time')
            return
        }
        setFinalContractEndDate(contractEndDate + ', ' + contractEndTime)
        setShowContractEndDate(contractEndDate.toString().substring(4, 15) + ', ' + contractEndTime)
        setContractEndTimeFlag(false)
    }

    //CONTRACT PROVIDER COUNT
    const changeContractProviderCount = (e) => {
        e.preventDefault()
        setContractProviderCount(parseInt(e.target.value))
    }

    //FULL TIME SHIFT START DATE AND TIME
    const changeStartDateForFullTime = (value) => {
        setFullTimeStartDate(value)
        setFullTimeStartTimeFlag(true)
        setFullTimeStartTime()
    }
    const changeStartTimeForFullTime = (value) => {
        setFullTimeStartTime(value)
    }
    const selectStartTimeForFullTime = () => {
        if (fullTimeStartTime == null) {
            toast.error('Please select Full Time Start Time')
            return
        }
        setFinalFullTimeStartDate(fullTimeStartDate + ', ' + fullTimeStartTime)
        setShowFullTimeStartDate(fullTimeStartDate.toString().substring(4, 15) + ', ' + fullTimeStartTime)
        setFullTimeStartTimeFlag(false)
    }

    //FULL TIME PROVIDER COUNT
    const changeFullTimeProviderCount = (e) => {
        e.preventDefault()
        setFullTimeProviderCount(parseInt(e.target.value))
    }

    //PART TIME START TIME SHIFT 1
    const changeDateForPartTime = (value) => {
        setFinalPartTimeStartDate(value)
        setPartTimeStartDate(value.toString().substring(4, 15))
        setPartTimeInputDateFlag(true)
    }
    const selectStartTimeForPartTime1 = () => {
        if (partTimeStartTime1 == null) {
            toast.error('Please select Part Time Start Time for Shift 1')
            return
        }
        setFinalPartTimeStartDate1(finalPartTimeStartDate + ', ' + partTimeStartTime1)
        setPartTimeStartTimeFlag1(true)
        setPartTimeStartTime1Modal(false)
    }
    const openPartTimeStartTime1Modal = () => {
        setPartTimeStartTime1Modal(true)
    }
    const changeStartTime1ForPartTime = (value) => {
        setPartTimeStartTime1(value)
    }
    //PART TIME END TIME SHIFT 1
    const selectEndTimeForPartTime1 = () => {
        if (partTimeEndTime1 == null) {
            toast.error('Please select Part Time End Time for Shift 1')
            return
        }
        setFinalPartTimeEndDate1(finalPartTimeStartDate + ', ' + partTimeEndTime1)
        setPartTimeEndTimeFlag1(true)
        setPartTimeEndTime1Modal(false)
    }
    const openPartTimeEndTime1Modal = () => {
        setPartTimeEndTime1Modal(true)
    }
    const changeEndTime1ForPartTime = (value) => {
        setPartTimeEndTime1(value)
    }
    //PART TIME PROVIDER COUNT 1
    const changePartTimeProviderCount1 = (e) => {
        e.preventDefault()
        setPartTimeProviderCount1(parseInt(e.target.value))
    }






    //PART TIME START TIME SHIFT 2
    const selectStartTimeForPartTime2 = () => {
        if (partTimeStartTime2 == null) {
            toast.error('Please select Part Time Start Time for Shift 2')
            return
        }
        setFinalPartTimeStartDate2(finalPartTimeStartDate + ', ' + partTimeStartTime2)
        setPartTimeStartTimeFlag2(true)
        setPartTimeStartTime2Modal(false)
    }
    const openPartTimeStartTime2Modal = () => {
        setPartTimeStartTime2Modal(true)
    }
    const changeStartTime2ForPartTime = (value) => {
        setPartTimeStartTime2(value)
    }
    //PART TIME END TIME SHIFT 1
    const selectEndTimeForPartTime2 = () => {
        if (partTimeEndTime2 == null) {
            toast.error('Please select Part Time End Time for Shift 2')
            return
        }
        setFinalPartTimeEndDate2(finalPartTimeStartDate + ', ' + partTimeEndTime2)
        setPartTimeEndTimeFlag2(true)
        setPartTimeEndTime2Modal(false)
    }
    const openPartTimeEndTime2Modal = () => {
        setPartTimeEndTime2Modal(true)
    }
    const changeEndTime2ForPartTime = (value) => {
        setPartTimeEndTime2(value)
    }
    //PART TIME PROVIDER COUNT 1
    const changePartTimeProviderCount2 = (e) => {
        e.preventDefault()
        setPartTimeProviderCount2(parseInt(e.target.value))
    }





    //PART TIME START TIME SHIFT 3
    const selectStartTimeForPartTime3 = () => {
        if (partTimeStartTime3 == null) {
            toast.error('Please select Part Time Start Time for Shift 3')
            return
        }
        setFinalPartTimeStartDate3(finalPartTimeStartDate + ', ' + partTimeStartTime3)
        setPartTimeStartTimeFlag3(true)
        setPartTimeStartTime3Modal(false)
    }
    const openPartTimeStartTime3Modal = () => {
        setPartTimeStartTime3Modal(true)
    }
    const changeStartTime3ForPartTime = (value) => {
        setPartTimeStartTime3(value)
    }
    //PART TIME END TIME SHIFT 1
    const selectEndTimeForPartTime3 = () => {
        if (partTimeEndTime3 == null) {
            toast.error('Please select Part Time End Time for Shift 3')
            return
        }
        setFinalPartTimeEndDate3(finalPartTimeStartDate + ', ' + partTimeEndTime3)
        setPartTimeEndTimeFlag3(true)
        setPartTimeEndTime3Modal(false)
    }
    const openPartTimeEndTime3Modal = () => {
        setPartTimeEndTime3Modal(true)
    }
    const changeEndTime3ForPartTime = (value) => {
        setPartTimeEndTime3(value)
    }
    //PART TIME PROVIDER COUNT 1
    const changePartTimeProviderCount3 = (e) => {
        e.preventDefault()
        setPartTimeProviderCount3(parseInt(e.target.value))
    }




    //PART TIME START TIME SHIFT 4
    const selectStartTimeForPartTime4 = () => {
        if (partTimeStartTime4 == null) {
            toast.error('Please select Part Time Start Time for Shift 4')
            return
        }
        setFinalPartTimeStartDate4(finalPartTimeStartDate + ', ' + partTimeStartTime4)
        setPartTimeStartTimeFlag4(true)
        setPartTimeStartTime4Modal(false)
    }
    const openPartTimeStartTime4Modal = () => {
        setPartTimeStartTime4Modal(true)
    }
    const changeStartTime4ForPartTime = (value) => {
        setPartTimeStartTime4(value)
    }
    //PART TIME END TIME SHIFT 4
    const selectEndTimeForPartTime4 = () => {
        if (partTimeEndTime4 == null) {
            toast.error('Please select Part Time End Time for Shift 4')
            return
        }
        setFinalPartTimeEndDate4(finalPartTimeStartDate + ', ' + partTimeEndTime4)
        setPartTimeEndTimeFlag4(true)
        setPartTimeEndTime4Modal(false)
    }
    const openPartTimeEndTime4Modal = () => {
        setPartTimeEndTime4Modal(true)
    }
    const changeEndTime4ForPartTime = (value) => {
        setPartTimeEndTime4(value)
    }
    //PART TIME PROVIDER COUNT 4
    const changePartTimeProviderCount4 = (e) => {
        e.preventDefault()
        setPartTimeProviderCount4(parseInt(e.target.value))
    }





    //PART TIME START TIME SHIFT 5
    const selectStartTimeForPartTime5 = () => {
        if (partTimeStartTime5 == null) {
            toast.error('Please select Part Time Start Time for Shift 5')
            return
        }
        setFinalPartTimeStartDate5(finalPartTimeStartDate + ', ' + partTimeStartTime5)
        setPartTimeStartTimeFlag5(true)
        setPartTimeStartTime5Modal(false)
    }
    const openPartTimeStartTime5Modal = () => {
        setPartTimeStartTime5Modal(true)
    }
    const changeStartTime5ForPartTime = (value) => {
        setPartTimeStartTime5(value)
    }
    //PART TIME END TIME SHIFT 5
    const selectEndTimeForPartTime5 = () => {
        if (partTimeEndTime5 == null) {
            toast.error('Please select Part Time End Time for Shift 5')
            return
        }
        setFinalPartTimeEndDate5(finalPartTimeStartDate + ', ' + partTimeEndTime5)
        setPartTimeEndTimeFlag5(true)
        setPartTimeEndTime5Modal(false)
    }
    const openPartTimeEndTime5Modal = () => {
        setPartTimeEndTime5Modal(true)
    }
    const changeEndTime5ForPartTime = (value) => {
        setPartTimeEndTime5(value)
    }
    //PART TIME PROVIDER COUNT 5
    const changePartTimeProviderCount5 = (e) => {
        e.preventDefault()
        setPartTimeProviderCount5(parseInt(e.target.value))
    }






    const addAnotherShift = (e) => {
        if (shiftCount == 0) {
            setShift2Flag(true)
            setShiftCount(1)
        } else if (shiftCount == 1) {
            setShift3Flag(true)
            setShiftCount(2)
        } else if (shiftCount == 2) {
            setShift4Flag(true)
            setShiftCount(3)
        } else if (shiftCount == 3) {
            setShift5Flag(true)
            setShiftCount(4)
        }
    }

    const openEditModal = (e) => {
        e.preventDefault()
        setCloseEditDisabled(false)
        setEditModal(true)
    }
    const closeEditModal = () => {
        setEditModal(false)
        setCalendarFlag(false)
        // setContractStartDate()
        // setContractStartTime()
        // setShowContractStartDate()
        // setFinalContractStartDate()
        // setContractEndDate()
        // setContractEndTime()
        // setShowContractEndDate()
        // setFinalContractEndDate()
        // setFullTimeStartDate()
        // setFullTimeStartTime()
        // setShowFullTimeStartDate()
        // setFinalFullTimeStartDate()

        // setPartTimeStartDate()
        // setPartTimeInputDateFlag(false)

        // setShiftCount(0)

        // setPartTimeStartTime1()
        // setPartTimeEndTime1()
        // setPartTimeStartTime2()
        // setPartTimeEndTime2()
        // setPartTimeStartTime3()
        // setPartTimeEndTime3()
        // setPartTimeStartTime4()
        // setPartTimeEndTime4()
        // setPartTimeStartTime5()
        // setPartTimeEndTime5()

        // setFinalPartTimeStartDate1()
        // setFinalPartTimeEndDate1()
        // setFinalPartTimeStartDate2()
        // setFinalPartTimeEndDate2()
        // setFinalPartTimeStartDate3()
        // setFinalPartTimeEndDate3()
        // setFinalPartTimeStartDate4()
        // setFinalPartTimeEndDate4()
        // setFinalPartTimeStartDate5()
        // setFinalPartTimeEndDate5()

        // setPartTimeProviderCount1()
        // setPartTimeProviderCount2()
        // setPartTimeProviderCount3()
        // setPartTimeProviderCount4()
        // setPartTimeProviderCount5()

        // setPartTimeStartTimeFlag1(false)
        // setPartTimeEndTimeFlag1(false)
        // setPartTimeStartTimeFlag2(false)
        // setPartTimeEndTimeFlag2(false)
        // setPartTimeStartTimeFlag3(false)
        // setPartTimeEndTimeFlag3(false)
        // setPartTimeStartTimeFlag4(false)
        // setPartTimeEndTimeFlag4(false)
        // setPartTimeStartTimeFlag5(false)
        // setPartTimeEndTimeFlag5(false)

        // setShift2Flag(false)
        // setShift3Flag(false)
        // setShift4Flag(false)
        // setShift5Flag(false)

        document.getElementById('kt_content').setAttribute('style', '')
    }
    const editJobFn = () => {
        setGettingField(true);
        setEditDisabled(true)
        setCloseEditDisabled(true)
        props.editJob(
            {
                entityId: props.match.params.entityId,
                userIdHash: props.auth.userProfile.userIdHash,
                userProfileId: props.match.params.talentId,
                data: { data: JSON.stringify(fieldValues) },
            },
            (res) => {
                setGettingField(false);
                if (res.status) {
                    // document.getElementById('kt_content').setAttribute('style', '')
                    document.getElementById('kt_content').setAttribute('style', 'position:fixed; width:82%')
                    // setEditModal(!editModal);
                    // swal({
                    //     title: "Job updated successfully",
                    //     text: "",
                    //     icon: "success"
                    // }).then(() => {
                    //     setEditDisabled(true)
                    //     setCloseEditDisabled(false)
                    // })
                    setEntityId(props.match.params.entityId)
                    setCalendarFlag(true)
                    setEditDisabled(false)
                } else {
                    if (res.statusCode == 400) {
                        toast.error("Required fields cannot be empty");
                        setEditDisabled(false)
                        setCloseEditDisabled(false)
                    } else {
                        toast.error("Something went wrong!");
                        setEditDisabled(false)
                        setCloseEditDisabled(false)
                    }
                }
            },
            (err) => {
                toast.error("Something went wrong!");
                setGettingField(false);
                setEditDisabled(false)
                setCloseEditDisabled(false)
            }
        );
    }

    const onPostDateAndTime = () => {
        if (employmentTypeData.length > 0) {
            let arr = []
            for (let i = 0; i < employmentTypeData.length; i++) {
                if (employmentTypeData[i].employmentTypeId == 3) {
                    if (finalContractStartDate == null || finalContractStartDate == undefined) {
                        toast.error('Please select start date and time for Contract')
                        break
                    } else if (finalContractEndDate == null || finalContractEndDate == undefined) {
                        toast.error('Please select end date and time for Contract')
                        break
                    } else if (contractProviderCount == null || contractProviderCount == undefined) {
                        toast.error('Please enter number of providers for Contract')
                        break
                    } else {
                        contractObject.employmentTypeId = 3
                        contractObject.startTime = finalContractStartDate.toString().substring(0, 3) + ', ' + finalContractStartDate.toString().substring(8, 10) + ' ' + finalContractStartDate.toString().substring(4, 7) + ' ' + finalContractStartDate.toString().substring(11, 15) + ' ' + contractStartTime + ':00 IST'
                        contractObject.endTime = finalContractEndDate.toString().substring(0, 3) + ', ' + finalContractEndDate.toString().substring(8, 10) + ' ' + finalContractEndDate.toString().substring(4, 7) + ' ' + finalContractEndDate.toString().substring(11, 15) + ' ' + contractEndTime + ':00 IST'
                        contractObject.providerCount = contractProviderCount
                        arr.push(contractObject)
                    }


                }
                if (employmentTypeData[i].employmentTypeId == 1) {
                    if (finalfullTimeStartDate == null || finalfullTimeStartDate == undefined) {
                        toast.error('Please select start date and time for Full Time')
                        break
                    } else if (fullTimeProviderCount == null || fullTimeProviderCount == undefined) {
                        toast.error('Please enter number of providers for Full Time')
                        break
                    } else {
                        fullTimeObject.employmentTypeId = 1
                        fullTimeObject.startTime = finalfullTimeStartDate.toString().substring(0, 3) + ', ' + finalfullTimeStartDate.toString().substring(8, 10) + ' ' + finalfullTimeStartDate.toString().substring(4, 7) + ' ' + finalfullTimeStartDate.toString().substring(11, 15) + ' ' + fullTimeStartTime + ':00 IST'
                        fullTimeObject.endTime = finalfullTimeStartDate.toString().substring(0, 3) + ', ' + finalfullTimeStartDate.toString().substring(8, 10) + ' ' + finalfullTimeStartDate.toString().substring(4, 7) + ' ' + finalfullTimeStartDate.toString().substring(11, 15) + ' ' + fullTimeStartTime + ':00 IST'
                        fullTimeObject.providerCount = fullTimeProviderCount
                        arr.push(fullTimeObject)
                    }


                }
                if (employmentTypeData[i].employmentTypeId == 2) {
                    if (finalPartTimeStartDate1 == null || finalPartTimeStartDate1 == undefined) {
                        toast.error('Please select start date and time for Part Time Shift 1')
                        break
                    } else if (finalPartTimeEndDate1 == null || finalPartTimeEndDate1 == undefined) {
                        toast.error('Please select end date and time for Part Time Shift 1')
                        break
                    } else if (partTimeProviderCount1 == null || partTimeProviderCount1 == undefined) {
                        toast.error('Please enter number of providers for Part Time Shift 1')
                        break
                    } else {
                        partTimeObject1.employmentTypeId = 2
                        partTimeObject1.startTime = finalPartTimeStartDate1.toString().substring(0, 3) + ', ' + finalPartTimeStartDate1.toString().substring(8, 10) + ' ' + finalPartTimeStartDate1.toString().substring(4, 7) + ' ' + finalPartTimeStartDate1.toString().substring(11, 15) + ' ' + partTimeStartTime1 + ':00 IST'
                        partTimeObject1.endTime = finalPartTimeEndDate1.toString().substring(0, 3) + ', ' + finalPartTimeEndDate1.toString().substring(8, 10) + ' ' + finalPartTimeEndDate1.toString().substring(4, 7) + ' ' + finalPartTimeEndDate1.toString().substring(11, 15) + ' ' + partTimeEndTime1 + ':00 IST'
                        partTimeObject1.providerCount = partTimeProviderCount1
                        arr.push(partTimeObject1)
                    }

                    if (finalPartTimeStartDate2 != undefined || finalPartTimeEndDate2 != undefined) {
                        if (finalPartTimeStartDate2 == null || finalPartTimeStartDate2 == undefined) {
                            toast.error('Please select start date and time for Part Time Shift 2')
                            break
                        } else if (finalPartTimeEndDate2 == null || finalPartTimeEndDate2 == undefined) {
                            toast.error('Please select end date and time for Part Time Shift 2')
                            break
                        } else if (partTimeProviderCount2 == null || partTimeProviderCount2 == undefined) {
                            toast.error('Please enter number of providers for Part Time Shift 2')
                            break
                        } else {
                            partTimeObject2.employmentTypeId = 2
                            partTimeObject2.startTime = finalPartTimeStartDate2.toString().substring(0, 3) + ', ' + finalPartTimeStartDate2.toString().substring(8, 10) + ' ' + finalPartTimeStartDate2.toString().substring(4, 7) + ' ' + finalPartTimeStartDate2.toString().substring(11, 15) + ' ' + partTimeStartTime2 + ':00 IST'
                            partTimeObject2.endTime = finalPartTimeEndDate2.toString().substring(0, 3) + ', ' + finalPartTimeEndDate2.toString().substring(8, 10) + ' ' + finalPartTimeEndDate2.toString().substring(4, 7) + ' ' + finalPartTimeEndDate2.toString().substring(11, 15) + ' ' + partTimeEndTime2 + ':00 IST'
                            partTimeObject2.providerCount = partTimeProviderCount2
                            arr.push(partTimeObject2)
                        }


                    }
                    if (finalPartTimeStartDate3 != undefined || finalPartTimeEndDate3 != undefined) {
                        if (finalPartTimeStartDate3 == null || finalPartTimeStartDate3 == undefined) {
                            toast.error('Please select start date and time for Part Time Shift 3')
                            break
                        } else if (finalPartTimeEndDate3 == null || finalPartTimeEndDate3 == undefined) {
                            toast.error('Please select end date and time for Part Time Shift 3')
                            break
                        } else if (partTimeProviderCount3 == null || partTimeProviderCount3 == undefined) {
                            toast.error('Please enter number of providers for Part Time Shift 3')
                            break
                        } else {
                            partTimeObject3.employmentTypeId = 2
                            partTimeObject3.startTime = finalPartTimeStartDate3.toString().substring(0, 3) + ', ' + finalPartTimeStartDate3.toString().substring(8, 10) + ' ' + finalPartTimeStartDate3.toString().substring(4, 7) + ' ' + finalPartTimeStartDate3.toString().substring(11, 15) + ' ' + partTimeStartTime3 + ':00 IST'
                            partTimeObject3.endTime = finalPartTimeEndDate3.toString().substring(0, 3) + ', ' + finalPartTimeEndDate3.toString().substring(8, 10) + ' ' + finalPartTimeEndDate3.toString().substring(4, 7) + ' ' + finalPartTimeEndDate3.toString().substring(11, 15) + ' ' + partTimeEndTime3 + ':00 IST'
                            partTimeObject3.providerCount = partTimeProviderCount3
                            arr.push(partTimeObject3)
                        }


                    }
                    if (finalPartTimeStartDate4 != undefined || finalPartTimeEndDate4 != undefined) {
                        if (finalPartTimeStartDate4 == null || finalPartTimeStartDate4 == undefined) {
                            toast.error('Please select start date and time for Part Time Shift 4')
                            break
                        } else if (finalPartTimeEndDate4 == null || finalPartTimeEndDate4 == undefined) {
                            toast.error('Please select end date and time for Part Time Shift 4')
                            break
                        } else if (partTimeProviderCount4 == null || partTimeProviderCount4 == undefined) {
                            toast.error('Please enter number of providers for Part Time Shift 4')
                            break
                        } else {
                            partTimeObject4.employmentTypeId = 2
                            partTimeObject4.startTime = finalPartTimeStartDate4.toString().substring(0, 3) + ', ' + finalPartTimeStartDate4.toString().substring(8, 10) + ' ' + finalPartTimeStartDate4.toString().substring(4, 7) + ' ' + finalPartTimeStartDate4.toString().substring(11, 15) + ' ' + partTimeStartTime4 + ':00 IST'
                            partTimeObject4.endTime = finalPartTimeEndDate4.toString().substring(0, 3) + ', ' + finalPartTimeEndDate4.toString().substring(8, 10) + ' ' + finalPartTimeEndDate4.toString().substring(4, 7) + ' ' + finalPartTimeEndDate4.toString().substring(11, 15) + ' ' + partTimeEndTime4 + ':00 IST'
                            partTimeObject4.providerCount = partTimeProviderCount4
                            arr.push(partTimeObject4)
                        }


                    }
                    if (finalPartTimeStartDate5 != undefined || finalPartTimeEndDate5 != undefined) {
                        if (finalPartTimeStartDate5 == null || finalPartTimeStartDate5 == undefined) {
                            toast.error('Please select start date and time for Part Time Shift 5')
                            break
                        } else if (finalPartTimeEndDate5 == null || finalPartTimeEndDate5 == undefined) {
                            toast.error('Please select end date and time for Part Time Shift 5')
                            break
                        } else if (partTimeProviderCount5 == null || partTimeProviderCount5 == undefined) {
                            toast.error('Please enter number of providers for Part Time Shift 5')
                            break
                        } else {
                            partTimeObject5.employmentTypeId = 2
                            partTimeObject5.startTime = finalPartTimeStartDate5.toString().substring(0, 3) + ', ' + finalPartTimeStartDate5.toString().substring(8, 10) + ' ' + finalPartTimeStartDate5.toString().substring(4, 7) + ' ' + finalPartTimeStartDate5.toString().substring(11, 15) + ' ' + partTimeStartTime5 + ':00 IST'
                            partTimeObject5.endTime = finalPartTimeEndDate5.toString().substring(0, 3) + ', ' + finalPartTimeEndDate5.toString().substring(8, 10) + ' ' + finalPartTimeEndDate5.toString().substring(4, 7) + ' ' + finalPartTimeEndDate5.toString().substring(11, 15) + ' ' + partTimeEndTime5 + ':00 IST'
                            partTimeObject5.providerCount = partTimeProviderCount5
                            arr.push(partTimeObject5)
                        }


                    }
                }
            }

            let params = {
                userProfileId: props.match.params.talentId,
                entityId: entityId,
                data: arr
            }

            if (arr.length >= employmentTypeData.length) {
                postDateAndTime(params)
            }

        }

    }

    const postDateAndTime = (params) => {
        setGettingField(true)
        setEditDisabled(true)
        document.getElementById('kt_content').setAttribute('style', '')
        props.postJobDateAndTime(params, res => {
            if (res.status) {
                if (res.data.length) {
                    setEditModal(!editModal);
                    swal({
                        title: "Job posted successfully",
                        text: "",
                        icon: "success"
                    }).then(() => {
                        setEditDisabled(true)
                        setGettingField(false)
                        setCloseEditDisabled(false)
                        setCalendarFlag(false)
                        // setContractStartDate()
                        // setContractStartTime()
                        // setShowContractStartDate()
                        // setFinalContractStartDate()
                        // setContractEndDate()
                        // setContractEndTime()
                        // setShowContractEndDate()
                        // setFinalContractEndDate()
                        // setFullTimeStartDate()
                        // setFullTimeStartTime()
                        // setShowFullTimeStartDate()
                        // setFinalFullTimeStartDate()

                        // setPartTimeStartDate()
                        // setPartTimeInputDateFlag(false)

                        // setShiftCount(0)

                        // setPartTimeStartTime1()
                        // setPartTimeEndTime1()
                        // setPartTimeStartTime2()
                        // setPartTimeEndTime2()
                        // setPartTimeStartTime3()
                        // setPartTimeEndTime3()
                        // setPartTimeStartTime4()
                        // setPartTimeEndTime4()
                        // setPartTimeStartTime5()
                        // setPartTimeEndTime5()

                        // setFinalPartTimeStartDate1()
                        // setFinalPartTimeEndDate1()
                        // setFinalPartTimeStartDate2()
                        // setFinalPartTimeEndDate2()
                        // setFinalPartTimeStartDate3()
                        // setFinalPartTimeEndDate3()
                        // setFinalPartTimeStartDate4()
                        // setFinalPartTimeEndDate4()
                        // setFinalPartTimeStartDate5()
                        // setFinalPartTimeEndDate5()

                        // setPartTimeProviderCount1()
                        // setPartTimeProviderCount2()
                        // setPartTimeProviderCount3()
                        // setPartTimeProviderCount4()
                        // setPartTimeProviderCount5()

                        // setPartTimeStartTimeFlag1(false)
                        // setPartTimeEndTimeFlag1(false)
                        // setPartTimeStartTimeFlag2(false)
                        // setPartTimeEndTimeFlag2(false)
                        // setPartTimeStartTimeFlag3(false)
                        // setPartTimeEndTimeFlag3(false)
                        // setPartTimeStartTimeFlag4(false)
                        // setPartTimeEndTimeFlag4(false)
                        // setPartTimeStartTimeFlag5(false)
                        // setPartTimeEndTimeFlag5(false)

                        // setShift2Flag(false)
                        // setShift3Flag(false)
                        // setShift4Flag(false)
                        // setShift5Flag(false)
                    })
                }
            }
        })
    }

    // MMMM Do YYYY, h:mm:ss a
    const getFormatedDate = (date, format = 'YYYY-MM-DD, h:mm:ss A', timeZone = '') => {
        if (date && moment(date).isValid()) {
            if (timeZone) {
                return moment(date).format(format).tz(timeZone);
            }
            let dateFetched = moment(date).format(format)
            let newDate = new Date((dateFetched).toString().substring(0, 10))
            let newDate2 = newDate.toString().substring(4, 15)
            let time = dateFetched.toString().substring(12, 23)
            // return moment(date).format(format)
            return newDate2 + ', ' + time
        }
        return false;
    }
    // const getFormatedDate = (date) => {
    //     let newDate = new Date(date.toString().substring(0, 10))
    //     let newDate2 = newDate.toString().substring(4,15)
    //     let time = date.toString().substring(11, 16)
    //     return newDate2 + ', ' + time;
    // }

    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
                <div className="d-flex flex-column-fluid">
                    <div className="custom-container" style={{ height: 'auto' }}>
                        <div className="row  pb-5">
                            <div className="col-lg-6 col-md-6">
                                <div className="inner_txecy">
                                    <h4>Job Details</h4>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                {/* <div className="talen_outre">
                                    <button className="btn" data-toggle="modal" data-target="#myModal12">+ CREATE TALENT</button>
                                </div> */}

                            </div>
                        </div>
                        <div className='row my-5'>
                            <div className='col-12 m-auto'>
                                <button className='btn btn-primary' onClick={() => state?.fromDashboard ? history.goBack() : history.push(`/jobList/${props.match.params.talentId}/${props.match.params.entityTypeId}`)}><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                            </div>
                        </div>

                        <div className="Profile_form">
                            {loading ?
                                (<>
                                    <div className="row" style={{ width: '100%', height: '30vh' }}>
                                        <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </>) :
                                (<>
                                    <div className="row mt-3 mb-3" style={{ padding: '0px' }}>
                                        <div className="col-12">
                                            <div className="talent_details_shift">
                                                <a className={
                                                    filterShow
                                                        ? "panel-heading heading_links_ accordion_title_info_ d-flex justify-content-between"
                                                        : "panel-heading heading_links_ accordion_title_info_ collapsed d-flex justify-content-between"
                                                }
                                                    onClick={() => setFilterShow(!filterShow)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <span style={{ fontWeight: '600', color: '#3abd00' }}>Talent Profile Id : <span style={{ color: 'black' }}>{talentProfileData.id}</span></span>
                                                    <img src={downArrow} />
                                                </a>
                                                <div
                                                    className={
                                                        filterShow ? "collapse show" : "collapse"
                                                    }
                                                    id="acceptance"
                                                >
                                                    <div
                                                        className="card border-0 pt-2"
                                                        style={{
                                                            "max-height": "200px",
                                                            overflow: "auto",
                                                        }}
                                                    >
                                                        <div className='card-body' style={{ paddingTop: '0px' }}>
                                                            <table className='table' style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Business Name</td>
                                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{talentProfileData.entityName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Location</td>
                                                                        <td className='table-shift-td-2'>{talentProfileData.address1 ? talentProfileData.address1 : talentProfileData.address}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Employment Type</td>
                                                                        <td className='table-shift-td-2'>{employmentDataForShift.length > 0 ? employmentDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Facility Type</td>
                                                                        <td className='table-shift-td-2'>{facilityDataForShift.length > 0 ? facilityDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Payment Type</td>
                                                                        <td className='table-shift-td-2'>{paymentDataForShift.length > 0 ? paymentDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Payroll Type</td>
                                                                        <td className='table-shift-td-2'>{payrollDataForShift.length > 0 ? payrollDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Schedule Type</td>
                                                                        <td className='table-shift-td-2'>{scheduleDataForShift.length > 0 ? scheduleDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Shift Type</td>
                                                                        <td className='table-shift-td-2'>{shiftDataForShift.length > 0 ? shiftDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row no-gutters text-center" style={{ marginTop: '40px', marginBottom: '40px' }}>
                                        <div className="col jobInformation" style={jobInformationFlag ? jobInformationStyleActive() : jobInformationStyleInActive()} onClick={() => columnClicked('jobInformation')}>
                                            JOB INFORMATION
                                        </div>
                                        <div className="col jobSetting" style={jobSettingFlag ? jobSettingStyleActive() : jobSettingStyleInActive()} onClick={() => columnClicked('jobSetting')}>
                                            JOB SETTING
                                        </div>
                                        <div className="col aboutEmp" style={aboutEmployerFlag ? aboutEmployerStyleActive() : aboutEmployerStyleInActive()} onClick={() => columnClicked('aboutEmployer')}>
                                            ABOUT EMPLOYER
                                        </div>
                                        <div className="col weeklyCalendar" style={weeklyCalendarFlag ? weeklyCalendarStyleActive() : weeklyCalendarStyleInActive()} onClick={() => columnClicked('weeklyCalendar')}>
                                            WEEKLY CALENDAR
                                        </div>
                                        <div className="col dailyCalendar" style={dailyCalendarFlag ? dailyCalendarStyleActive() : dailyCalendarStyleInActive()} onClick={() => columnClicked('dailyCalendar')}>
                                            DAILY CALENDAR
                                        </div>

                                    </div>

                                    <div className='card'>
                                        <div className='card-body' style={{paddingTop:'35px', paddingBottom:'35px'}}>
                                            {jobInformationFlag ?
                                                (<>
                                                    <div className="edit_outer" style={{ top: '0' }}>
                                                        {!state?.fromDashboard && <a style={{ cursor: 'pointer' }} onClick={(e) => { openEditModal(e) }}><img src={talent_edit} alt="" /></a>}
                                                    </div>
                                                    <div className="row mx-2">{fieldElements}</div>
                                                    <p className="talent_id mt-5 job_infoHeading_ me-25" style={{marginLeft:'20px'}}>
                                                        <span className='my-3' style={{ color:'green' }}>Job Posted By :{" "}</span>
                                                        <p className="talent_id mt-5 job_infoHeading_ me-25">
                                                        {userProfileData.selectedUserData?.firstName ? (
                                                        <>
                                                        {userProfileData?.selectedUserData?.firstName ? userProfileData?.selectedUserData?.firstName : '--'} {userProfileData?.selectedUserData?.lastName ? userProfileData?.selectedUserData?.lastName : '--'}
                                                        </>) : (
                                                        <>
                                                        {userProfileData?.firstName ? userProfileData?.firstName : '--'} {userProfileData?.lastName ? userProfileData?.lastName : '--'}
                                                        </>)}    
                                                        </p>
                                                        <p className="talent_id mt-2 job_infoHeading_ me-25">
                                                        {userProfileData.selectedUserData?.entityName ? (
                                                        <>
                                                        {userProfileData?.selectedUserData?.entityName ? userProfileData?.selectedUserData?.entityName : '--' }
                                                        </>) : (
                                                        <>
                                                        {userProfileData?.entityName ? userProfileData?.entityName : '--' }
                                                        </>)}
                                                        </p>
                                                        <p className="talent_id mt-2 job_infoHeading_ me-25">
                                                        <img className="custom78" src={locationImg} alt="" />{userProfileData.selectedUserData?.state ? (
                                                        <>
                                                        {userProfileData?.selectedUserData?.state}{', '}{userProfileData?.selectedUserData?.country}
                                                        </>) : (
                                                        <>
                                                        {userProfileData?.state}{', '}{userProfileData?.country}
                                                        </>)}
                                                        </p>
                                                        <p className="talent_id mt-2 job_infoHeading_ me-25">
                                                        <i className="fas fa-briefcase mr-2" style={{ color: 'green' }}></i>{userProfileData?.userTypeCategoryJobInfo?.name ? (
                                                        <>
                                                        {userProfileData?.userTypeCategoryJobInfo?.name ? userProfileData?.userTypeCategoryJobInfo?.name : '--'}
                                                        </>) : (
                                                        <>
                                                        {props.auth.userProfile?.userCategoryInfo?.name ? props.auth.userProfile?.userCategoryInfo?.name : '--'}
                                                        </>)}
                                                        </p>
                                                        
                                                    </p>
                                                </>) :
                                                (jobSettingFlag ?
                                                    (<>
                                                        <div className="row mx-2">{fieldElements3}</div>
                                                    </>) :
                                                    (aboutEmployerFlag ?
                                                        (<>
                                                            <div className="geoge_outer">
                                                                <div className='custom789 circleName'>{talentProfileData.userProfileDataTypes?.UserProfileEntityTypeIsSelected ? talentProfileData.userProfileDataTypes?.UserProfileEntityTypeIsSelected?.entityTypeNameAbbreviation : ''}</div>
                                                                <img src={talentProfileData.isVerified ? verified : unVerified} alt="verify" style={{ position: "absolute", left: '85px', top: '85px' }} />
                                                                <div className="google_inner">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        {/* <h3>{talentProfileData.userProfileDataTypes.Profession ? talentProfileData.userProfileDataTypes.Profession : '--'}</h3> */}
                                                                        <h3>
                                                                            {/* {userProfileData?.selectedUserData?.entityName ? userProfileData?.selectedUserData?.entityName : (userProfileData?.selectedUserData?.firstName ? userProfileData?.selectedUserData?.firstName : '--')(" ")(userProfileData?.selectedUserData?.lastName ? userProfileData?.selectedUserData?.lastName : '--')} */}
                                                                            {userProfileData.selectedUserData?.entityName ? (
                                                                            <>
                                                                            {userProfileData?.selectedUserData?.entityName ? userProfileData?.selectedUserData?.entityName : ('--')}
                                                                            </>) : (
                                                                            <>
                                                                            {talentProfileData?.entityName ? talentProfileData?.entityName : ('--')}
                                                                            </>)}
                                                                            
                                                                        </h3>
                                                                        <img src={showBadge(talentProfileData.userProfileDataTypes?.selectedUserDataRating)} alt="badge" className="mr-6" />
                                                                    </div>
                                                                    <p className="custom_png" style={{ color: "deepskyblue" }}>#{userProfileData?.selectedUserData?.userNameHash ? userProfileData?.selectedUserData?.userNameHash : talentProfileData.userProfileDataTypes?.userDataInfo?.userNameHash} {userProfileData?.selectedUserData?.userName ? (<>/ @{userProfileData?.selectedUserData?.userName}</>) : (<></>)}</p>
                                                                </div>
                                                                <table className='table mt-5' style={{ width: '100%' }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none', paddingLeft: '80px' }}>Profession</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{talentProfileData.userProfileDataTypes?.Profession ? talentProfileData.userProfileDataTypes?.Profession : '- - -'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none', paddingLeft: '80px' }}>Company</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{userProfileData?.selectedUserData?.entityName ? userProfileData?.selectedUserData?.entityName : (talentProfileData?.entityName ? talentProfileData?.entityName : ('--')) }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none', paddingLeft: '80px' }}>About Employer</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{talentProfileData.about ? talentProfileData.about : '- - -'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none', paddingLeft: '80px' }}>Employment Type</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{employmentDataForShift.length > 0 ? employmentDataForShift.join(', ') : '- - -'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none', paddingLeft: '80px' }}>Facility Type</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{facilityDataForShift.length > 0 ? facilityDataForShift.join(', ') : '- - -'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none', paddingLeft: '80px' }}>Payment Type</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{paymentDataForShift.length > 0 ? paymentDataForShift.join(', ') : '- - -'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none', paddingLeft: '80px' }}>Payroll Type</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{payrollDataForShift.length > 0 ? payrollDataForShift.join(', ') : '- - -'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none', paddingLeft: '80px' }}>Schedule Type</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{scheduleDataForShift.length > 0 ? scheduleDataForShift.join(', ') : '- - -'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none', paddingLeft: '80px' }}>Shift Type</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{shiftDataForShift.length > 0 ? shiftDataForShift.join(', ') : '- - -'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='table-shift-td-1' style={{ border: 'none', paddingLeft: '80px' }}>Location</td>
                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{talentProfileData.address1 ? talentProfileData.address1 : talentProfileData.address}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <p className="talent_id mt-5 job_infoHeading_ me-25" style={{marginLeft:'20px'}}>
                                                        <span className='my-3' style={{ color:'green' }}>Job Posted By :{" "}</span>
                                                        <p className="talent_id mt-5 job_infoHeading_ me-25">
                                                        {userProfileData.selectedUserData?.firstName ? (
                                                        <>
                                                        {userProfileData?.selectedUserData?.firstName ? userProfileData?.selectedUserData?.firstName : '--'} {userProfileData?.selectedUserData?.lastName ? userProfileData?.selectedUserData?.lastName : '--'}
                                                        </>) : (
                                                        <>
                                                        {userProfileData?.firstName ? userProfileData?.firstName : '--'} {userProfileData?.lastName ? userProfileData?.lastName : '--'}
                                                        </>)}    
                                                        </p>
                                                        <p className="talent_id mt-2 job_infoHeading_ me-25">
                                                        {userProfileData.selectedUserData?.entityName ? (
                                                        <>
                                                        {userProfileData?.selectedUserData?.entityName ? userProfileData?.selectedUserData?.entityName : '--' }
                                                        </>) : (
                                                        <>
                                                        {userProfileData?.entityName ? userProfileData?.entityName : '--' }
                                                        </>)}
                                                        </p>
                                                        <p className="talent_id mt-2 job_infoHeading_ me-25">
                                                        <img className="custom78" src={locationImg} alt="" />{userProfileData.selectedUserData?.state ? (
                                                        <>
                                                        {userProfileData?.selectedUserData?.state}{', '}{userProfileData?.selectedUserData?.country}
                                                        </>) : (
                                                        <>
                                                        {userProfileData?.state}{', '}{userProfileData?.country}
                                                        </>)}
                                                        </p>
                                                        <p className="talent_id mt-2 job_infoHeading_ me-25">
                                                        <i className="fas fa-briefcase mr-2" style={{ color: 'green' }}></i>{userProfileData?.userTypeCategoryJobInfo?.name ? (
                                                        <>
                                                        {userProfileData?.userTypeCategoryJobInfo?.name ? userProfileData?.userTypeCategoryJobInfo?.name : '--'}
                                                        </>) : (
                                                        <>
                                                        {props.auth.userProfile?.userCategoryInfo?.name ? props.auth.userProfile?.userCategoryInfo?.name : '--'}
                                                        </>)}
                                                        </p>
                                                        
                                                    </p>
                                                            </div>
                                                        </>) :
                                                        (weeklyCalendarFlag ?
                                                            (<>
                                                                <div className="row mx-2">{fieldElements4}</div>
                                                            </>) :
                                                            (dailyCalendarFlag ?
                                                                (<>
                                                                    {contractDataFlag &&
                                                                        (<>
                                                                            <label className=''><b>Contract Start and End Date :</b></label>
                                                                            {contractData.shifts.length && (
                                                                                contractData.shifts.map((cd, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className='row mt-5 mb-2'>
                                                                                                <div className='col-6'>
                                                                                                    <label>Start Date : </label><span> &nbsp;{getFormatedDate(cd.startTime)}</span>
                                                                                                </div>
                                                                                                <div className='col-6'>
                                                                                                    <label>End Date : </label><span> &nbsp;{getFormatedDate(cd.endTime)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='row mb-5'>
                                                                                                <div className='col-6'>
                                                                                                    <label className='mr-3'>No. of Providers : </label>
                                                                                                    <input type='text' value={cd.providerCount} disabled style={{ padding: '5px', borderRadius: '5px' }} />
                                                                                                </div>
                                                                                                <div className='col-6'>

                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            )}
                                                                            <hr></hr>
                                                                        </>)}
                                                                    {fullTimeDataFlag &&
                                                                        (<>
                                                                            <label className='mt-5'><b>Full Time Date :</b></label>
                                                                            {fullTimeData.shifts.length && (
                                                                                fullTimeData.shifts.map((ft, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className='row mt-5 mb-2'>
                                                                                                <div className='col-6'>
                                                                                                    <label>Start Date : </label><span> &nbsp;{getFormatedDate(ft.startTime)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='row mb-5'>
                                                                                                <div className='col-6'>
                                                                                                    <label className='mr-3'>No. of Providers : </label>
                                                                                                    <input type='text' value={ft.providerCount} disabled style={{ padding: '5px', borderRadius: '5px' }} />
                                                                                                </div>
                                                                                                <div className='col-6'>

                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            )}
                                                                            <hr></hr>
                                                                        </>)}
                                                                    {partTimeDataFlag &&
                                                                        (<>
                                                                            <label className='mt-5'><b>Daily Date Picker :</b></label>
                                                                            {partTimeData.shifts.length && (
                                                                                partTimeData.shifts.map((pt, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className='row mt-5'>
                                                                                                <div className='col-12'>
                                                                                                    <label>Shift {index + 1} :</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='row mt-2 mb-2'>
                                                                                                <div className='col-6'>
                                                                                                    <label>Start Date : </label><span> &nbsp;{getFormatedDate(pt.startTime)}</span>
                                                                                                </div>
                                                                                                <div className='col-6'>
                                                                                                    <label>End Date : </label><span> &nbsp;{getFormatedDate(pt.endTime)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='row mb-5'>
                                                                                                <div className='col-6'>
                                                                                                    <label className='mr-3'>No. of Providers : </label>
                                                                                                    <input type='text' value={pt.providerCount} disabled style={{ padding: '5px', borderRadius: '5px' }} />
                                                                                                </div>
                                                                                                <div className='col-6'>

                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            )}
                                                                            <hr></hr>
                                                                        </>)}
                                                                    {contractDataFlag == false && fullTimeDataFlag == false && partTimeDataFlag == false && (<><div className='m-auto text-center'>No Data</div></>)}
                                                                </>) :
                                                                (<></>)))))}
                                        </div>
                                    </div>

                                    <Modal
                                        show={editModal}
                                        size="md"
                                        centered
                                        style={{ background: "rgb(95 95 95 / 77%)" }}
                                    >
                                        <div className="text-center m-5">
                                            <div className='text-center m-5'>
                                                <Modal.Title><b>Update Your Job</b></Modal.Title>
                                            </div>
                                        </div>

                                        {gettingField ? (
                                            <div className="row" style={{ width: '100%', height: '30vh' }}>
                                                <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                    <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '25vh' }}>
                                                        <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {!calendarFlag ?
                                                    (<>
                                                        <div className="row mx-2">{fieldElements2}</div>
                                                    </>) :
                                                    (<>
                                                        <div className='card'>
                                                            <div className='card-body'>
                                                                {employmentTypeData.length > 0 && (
                                                                    employmentTypeData.map((ed, index) => {
                                                                        return (
                                                                            <>
                                                                                {ed.employmentTypeId == 3 && (
                                                                                    <>
                                                                                        <div className='row pt-5 pb-5'>
                                                                                            <div className='col-12 p-0'>
                                                                                                <label style={{ fontSize: '15px' }}><b>Contract Start and End Date : </b></label>
                                                                                                <div>
                                                                                                    <div className='d-flex'>
                                                                                                        {/* Contract Start Date and Time */}
                                                                                                        <label className='mr-3' style={{ paddingTop: '5px' }}>Start Date : </label>
                                                                                                        <input type='text' placeholder='Select Start Date' style={{ border: 'none', width: '30%' }} value={showContractStartDate} disabled />
                                                                                                        <DatePicker onChange={changeStartDateForContract} value={contractStartDate} className='start-date-calendar' />
                                                                                                        {contractStartTimeFlag ?
                                                                                                            (<>
                                                                                                                <Modal show={contractStartTimeFlag}
                                                                                                                    size="sm"
                                                                                                                    centered
                                                                                                                    style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                                    <Modal.Body style={{ padding: '20px' }}>
                                                                                                                        <TimePicker onChange={changeStartTimeForContract} value={contractStartTime} disableClock={true} />
                                                                                                                        <br></br>
                                                                                                                        <Button
                                                                                                                            className="mx-3"
                                                                                                                            variant="danger"
                                                                                                                            onClick={() => { setContractStartTimeFlag(false) }}
                                                                                                                        >Cancel</Button>
                                                                                                                        <Button className="mx-3" variant="primary" onClick={selectStartTimeForContract}>
                                                                                                                            Ok
                                                                                                                        </Button>
                                                                                                                    </Modal.Body>

                                                                                                                </Modal>

                                                                                                            </>) : (<></>)}

                                                                                                        {/* Contract End Date and Time */}
                                                                                                        <label className='mr-3 ml-5' style={{ paddingTop: '5px' }}>End Date : </label>
                                                                                                        <input type='text' placeholder='Select End Date' style={{ border: 'none', width: '30%' }} value={showContractEndDate} disabled />
                                                                                                        <DatePicker onChange={changeEndDateForContract} value={contractEndDate} className='end-date-calendar' />
                                                                                                        {contractEndTimeFlag ?
                                                                                                            (<>
                                                                                                                <Modal show={contractEndTimeFlag}
                                                                                                                    size="sm"
                                                                                                                    centered
                                                                                                                    style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                                    <Modal.Body style={{ padding: '20px' }}>
                                                                                                                        <TimePicker onChange={changeEndTimeForContract} value={contractEndTime} disableClock={true} />
                                                                                                                        <br></br>
                                                                                                                        <Button
                                                                                                                            className="mx-3"
                                                                                                                            variant="danger"
                                                                                                                            onClick={() => { setContractEndTimeFlag(false) }}
                                                                                                                        >Cancel</Button>
                                                                                                                        <Button className="mx-3" variant="primary" onClick={selectEndTimeForContract}>
                                                                                                                            Ok
                                                                                                                        </Button>
                                                                                                                    </Modal.Body>

                                                                                                                </Modal>

                                                                                                            </>) : (<></>)}
                                                                                                    </div>
                                                                                                    <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changeContractProviderCount(e) }} value={contractProviderCount} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {ed.employmentTypeId == 1 && (
                                                                                    <>
                                                                                        <div className='row pt-5 pb-5'>
                                                                                            <div className='col-12 p-0'>
                                                                                                <label style={{ fontSize: '15px' }}><b>Full Time Date : </b></label>
                                                                                                <div>
                                                                                                    <div className='d-flex'>
                                                                                                        {/* Full-Time Start Date and Time */}
                                                                                                        <label className='mr-3' style={{ paddingTop: '5px' }}>Start Date : </label>
                                                                                                        <input type='text' placeholder='Select Start Date' style={{ border: 'none', width: '30%' }} value={showFullTimeStartDate} disabled />
                                                                                                        <DatePicker onChange={changeStartDateForFullTime} value={fullTimeStartDate} className='start-date-calendar' />
                                                                                                        {fullTimeStartTimeFlag ?
                                                                                                            (<>
                                                                                                                <Modal show={fullTimeStartTimeFlag}
                                                                                                                    size="sm"
                                                                                                                    centered
                                                                                                                    style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                                    <Modal.Body style={{ padding: '20px' }}>
                                                                                                                        <TimePicker onChange={changeStartTimeForFullTime} value={fullTimeStartTime} disableClock={true} />
                                                                                                                        <br></br>
                                                                                                                        <Button
                                                                                                                            className="mx-3"
                                                                                                                            variant="danger"
                                                                                                                            onClick={() => { setFullTimeStartTimeFlag(false) }}
                                                                                                                        >Cancel</Button>
                                                                                                                        <Button className="mx-3" variant="primary" onClick={selectStartTimeForFullTime}>
                                                                                                                            Ok
                                                                                                                        </Button>
                                                                                                                    </Modal.Body>

                                                                                                                </Modal>

                                                                                                            </>) : (<></>)}
                                                                                                    </div>
                                                                                                    <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changeFullTimeProviderCount(e) }} value={fullTimeProviderCount} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {ed.employmentTypeId == 2 && (
                                                                                    <>
                                                                                        <div className='row pt-5 pb-5'>
                                                                                            <div className='col-12 p-0'>
                                                                                                <label style={{ fontSize: '15px' }}><b>Daily Date Picker : </b></label>
                                                                                                <div className='mb-3'>
                                                                                                    <Calendar className='part-time-calendar' onChange={changeDateForPartTime} value={finalPartTimeStartDate} />
                                                                                                </div>
                                                                                                {partTimeInputDateFlag ?
                                                                                                    (<>
                                                                                                        <Modal show={partTimeStartTime1Modal}
                                                                                                            size="sm"
                                                                                                            centered
                                                                                                            style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                            <Modal.Body style={{ padding: '20px' }}>
                                                                                                                <TimePicker onChange={changeStartTime1ForPartTime} value={partTimeStartTime1} disableClock={true} />
                                                                                                                <br></br>
                                                                                                                <Button
                                                                                                                    className="mx-3"
                                                                                                                    variant="danger"
                                                                                                                    onClick={() => { setPartTimeStartTime1Modal(false) }}
                                                                                                                >Cancel</Button>
                                                                                                                <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime1}>
                                                                                                                    Ok
                                                                                                                </Button>
                                                                                                            </Modal.Body>
                                                                                                        </Modal>
                                                                                                        <Modal show={partTimeEndTime1Modal}
                                                                                                            size="sm"
                                                                                                            centered
                                                                                                            style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                            <Modal.Body style={{ padding: '20px' }}>
                                                                                                                <TimePicker onChange={changeEndTime1ForPartTime} value={partTimeEndTime1} disableClock={true} />
                                                                                                                <br></br>
                                                                                                                <Button
                                                                                                                    className="mx-3"
                                                                                                                    variant="danger"
                                                                                                                    onClick={() => { setPartTimeEndTime1Modal(false) }}
                                                                                                                >Cancel</Button>
                                                                                                                <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime1}>
                                                                                                                    Ok
                                                                                                                </Button>
                                                                                                            </Modal.Body>
                                                                                                        </Modal>

                                                                                                        <Modal show={partTimeStartTime2Modal}
                                                                                                            size="sm"
                                                                                                            centered
                                                                                                            style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                            <Modal.Body style={{ padding: '20px' }}>
                                                                                                                <TimePicker onChange={changeStartTime2ForPartTime} value={partTimeStartTime2} disableClock={true} />
                                                                                                                <br></br>
                                                                                                                <Button
                                                                                                                    className="mx-3"
                                                                                                                    variant="danger"
                                                                                                                    onClick={() => { setPartTimeStartTime2Modal(false) }}
                                                                                                                >Cancel</Button>
                                                                                                                <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime2}>
                                                                                                                    Ok
                                                                                                                </Button>
                                                                                                            </Modal.Body>
                                                                                                        </Modal>
                                                                                                        <Modal show={partTimeEndTime2Modal}
                                                                                                            size="sm"
                                                                                                            centered
                                                                                                            style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                            <Modal.Body style={{ padding: '20px' }}>
                                                                                                                <TimePicker onChange={changeEndTime2ForPartTime} value={partTimeEndTime2} disableClock={true} />
                                                                                                                <br></br>
                                                                                                                <Button
                                                                                                                    className="mx-3"
                                                                                                                    variant="danger"
                                                                                                                    onClick={() => { setPartTimeEndTime2Modal(false) }}
                                                                                                                >Cancel</Button>
                                                                                                                <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime2}>
                                                                                                                    Ok
                                                                                                                </Button>
                                                                                                            </Modal.Body>
                                                                                                        </Modal>

                                                                                                        <Modal show={partTimeStartTime3Modal}
                                                                                                            size="sm"
                                                                                                            centered
                                                                                                            style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                            <Modal.Body style={{ padding: '20px' }}>
                                                                                                                <TimePicker onChange={changeStartTime3ForPartTime} value={partTimeStartTime3} disableClock={true} />
                                                                                                                <br></br>
                                                                                                                <Button
                                                                                                                    className="mx-3"
                                                                                                                    variant="danger"
                                                                                                                    onClick={() => { setPartTimeStartTime3Modal(false) }}
                                                                                                                >Cancel</Button>
                                                                                                                <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime3}>
                                                                                                                    Ok
                                                                                                                </Button>
                                                                                                            </Modal.Body>
                                                                                                        </Modal>
                                                                                                        <Modal show={partTimeEndTime3Modal}
                                                                                                            size="sm"
                                                                                                            centered
                                                                                                            style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                            <Modal.Body style={{ padding: '20px' }}>
                                                                                                                <TimePicker onChange={changeEndTime3ForPartTime} value={partTimeEndTime3} disableClock={true} />
                                                                                                                <br></br>
                                                                                                                <Button
                                                                                                                    className="mx-3"
                                                                                                                    variant="danger"
                                                                                                                    onClick={() => { setPartTimeEndTime3Modal(false) }}
                                                                                                                >Cancel</Button>
                                                                                                                <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime3}>
                                                                                                                    Ok
                                                                                                                </Button>
                                                                                                            </Modal.Body>
                                                                                                        </Modal>

                                                                                                        <Modal show={partTimeStartTime4Modal}
                                                                                                            size="sm"
                                                                                                            centered
                                                                                                            style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                            <Modal.Body style={{ padding: '20px' }}>
                                                                                                                <TimePicker onChange={changeStartTime4ForPartTime} value={partTimeStartTime4} disableClock={true} />
                                                                                                                <br></br>
                                                                                                                <Button
                                                                                                                    className="mx-3"
                                                                                                                    variant="danger"
                                                                                                                    onClick={() => { setPartTimeStartTime4Modal(false) }}
                                                                                                                >Cancel</Button>
                                                                                                                <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime4}>
                                                                                                                    Ok
                                                                                                                </Button>
                                                                                                            </Modal.Body>
                                                                                                        </Modal>
                                                                                                        <Modal show={partTimeEndTime4Modal}
                                                                                                            size="sm"
                                                                                                            centered
                                                                                                            style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                            <Modal.Body style={{ padding: '20px' }}>
                                                                                                                <TimePicker onChange={changeEndTime4ForPartTime} value={partTimeEndTime4} disableClock={true} />
                                                                                                                <br></br>
                                                                                                                <Button
                                                                                                                    className="mx-3"
                                                                                                                    variant="danger"
                                                                                                                    onClick={() => { setPartTimeEndTime4Modal(false) }}
                                                                                                                >Cancel</Button>
                                                                                                                <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime4}>
                                                                                                                    Ok
                                                                                                                </Button>
                                                                                                            </Modal.Body>
                                                                                                        </Modal>

                                                                                                        <Modal show={partTimeStartTime5Modal}
                                                                                                            size="sm"
                                                                                                            centered
                                                                                                            style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                            <Modal.Body style={{ padding: '20px' }}>
                                                                                                                <TimePicker onChange={changeStartTime5ForPartTime} value={partTimeStartTime5} disableClock={true} />
                                                                                                                <br></br>
                                                                                                                <Button
                                                                                                                    className="mx-3"
                                                                                                                    variant="danger"
                                                                                                                    onClick={() => { setPartTimeStartTime5Modal(false) }}
                                                                                                                >Cancel</Button>
                                                                                                                <Button className="mx-3" variant="primary" onClick={selectStartTimeForPartTime5}>
                                                                                                                    Ok
                                                                                                                </Button>
                                                                                                            </Modal.Body>
                                                                                                        </Modal>
                                                                                                        <Modal show={partTimeEndTime5Modal}
                                                                                                            size="sm"
                                                                                                            centered
                                                                                                            style={{ background: "rgb(95 95 95 / 77%)", textAlign: 'center' }}>
                                                                                                            <Modal.Body style={{ padding: '20px' }}>
                                                                                                                <TimePicker onChange={changeEndTime5ForPartTime} value={partTimeEndTime5} disableClock={true} />
                                                                                                                <br></br>
                                                                                                                <Button
                                                                                                                    className="mx-3"
                                                                                                                    variant="danger"
                                                                                                                    onClick={() => { setPartTimeEndTime5Modal(false) }}
                                                                                                                >Cancel</Button>
                                                                                                                <Button className="mx-3" variant="primary" onClick={selectEndTimeForPartTime5}>
                                                                                                                    Ok
                                                                                                                </Button>
                                                                                                            </Modal.Body>
                                                                                                        </Modal>
                                                                                                        <div className='card'>
                                                                                                            <div className='card-body' style={{ padding: '10px' }}>
                                                                                                                <label><b>{partTimeStartDate}</b></label>
                                                                                                                <div>
                                                                                                                    <label>Add Shift 1 : </label>
                                                                                                                    {partTimeStartTimeFlag1 ?
                                                                                                                        (<>
                                                                                                                            <span className='ml-3'> {partTimeStartTime1} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime1Modal() }}></i>
                                                                                                                        </>) :
                                                                                                                        (<>
                                                                                                                            <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime1Modal() }}></i>
                                                                                                                        </>)}
                                                                                                                    {partTimeEndTimeFlag1 ?
                                                                                                                        (<>
                                                                                                                            <span className='ml-3'> {partTimeEndTime1} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime1Modal() }}></i>
                                                                                                                        </>) :
                                                                                                                        (<>
                                                                                                                            <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime1Modal() }}></i>
                                                                                                                        </>)}
                                                                                                                    <div>
                                                                                                                        <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount1(e) }} value={partTimeProviderCount1} />
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                {shift2Flag ?
                                                                                                                    (<>
                                                                                                                        <div className='pt-3'>
                                                                                                                            <label>Add Shift 2 : </label>
                                                                                                                            {partTimeStartTimeFlag2 ?
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> {partTimeStartTime2} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime2Modal() }}></i>
                                                                                                                                </>) :
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime2Modal() }}></i>
                                                                                                                                </>)}
                                                                                                                            {partTimeEndTimeFlag2 ?
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> {partTimeEndTime2} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime2Modal() }}></i>
                                                                                                                                </>) :
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime2Modal() }}></i>
                                                                                                                                </>)}
                                                                                                                            <div>
                                                                                                                                <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount2(e) }} value={partTimeProviderCount2} />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>) : (<></>)}

                                                                                                                {shift3Flag ?
                                                                                                                    (<>
                                                                                                                        <div className='pt-3'>
                                                                                                                            <label>Add Shift 3 : </label>
                                                                                                                            {partTimeStartTimeFlag3 ?
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> {partTimeStartTime3} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime3Modal() }}></i>
                                                                                                                                </>) :
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime3Modal() }}></i>
                                                                                                                                </>)}
                                                                                                                            {partTimeEndTimeFlag3 ?
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> {partTimeEndTime3} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime3Modal() }}></i>
                                                                                                                                </>) :
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime3Modal() }}></i>
                                                                                                                                </>)}
                                                                                                                            <div>
                                                                                                                                <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount3(e) }} value={partTimeProviderCount3} />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>) : (<></>)}

                                                                                                                {shift4Flag ?
                                                                                                                    (<>
                                                                                                                        <div className='pt-3'>
                                                                                                                            <label>Add Shift 4 : </label>
                                                                                                                            {partTimeStartTimeFlag4 ?
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> {partTimeStartTime4} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime4Modal() }}></i>
                                                                                                                                </>) :
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime4Modal() }}></i>
                                                                                                                                </>)}
                                                                                                                            {partTimeEndTimeFlag4 ?
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> {partTimeEndTime4} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime4Modal() }}></i>
                                                                                                                                </>) :
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime4Modal() }}></i>
                                                                                                                                </>)}
                                                                                                                            <div>
                                                                                                                                <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount4(e) }} value={partTimeProviderCount4} />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>) : (<></>)}

                                                                                                                {shift5Flag ?
                                                                                                                    (<>
                                                                                                                        <div className='pt-3'>
                                                                                                                            <label>Add Shift 5 : </label>
                                                                                                                            {partTimeStartTimeFlag5 ?
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> {partTimeStartTime5} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime5Modal() }}></i>
                                                                                                                                </>) :
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> From </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeStartTime5Modal() }}></i>
                                                                                                                                </>)}
                                                                                                                            {partTimeEndTimeFlag5 ?
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> {partTimeEndTime5} </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime5Modal() }}></i>
                                                                                                                                </>) :
                                                                                                                                (<>
                                                                                                                                    <span className='ml-3'> To </span><i className="fa fa-clock-o ml-3" style={{ color: '#3abd00', fontSize: '15px' }} onClick={() => { openPartTimeEndTime5Modal() }}></i>
                                                                                                                                </>)}
                                                                                                                            <div>
                                                                                                                                <input type='number' className='input-placeholder' placeholder='How many providers needed?' onChange={(e) => { changePartTimeProviderCount5(e) }} value={partTimeProviderCount5} />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>) : (<></>)}

                                                                                                                {shiftCount == 4 ? (<></>) :
                                                                                                                    (<>
                                                                                                                        <div>
                                                                                                                            <label className='mt-3' style={{ color: 'blue' }} onClick={(e) => { addAnotherShift(e) }}>Add Another Shift</label>
                                                                                                                        </div>
                                                                                                                    </>)}

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>) :
                                                                                                    (<></>)}
                                                                                                <div></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    })
                                                                )}
                                                            </div>
                                                        </div>

                                                    </>)}
                                            </>

                                        )}
                                        <div className="text-center m-5">
                                            {/* <Button
                                                className="mx-3"
                                                variant="danger"
                                                onClick={closeEditModal}
                                                disabled={closeEditDisabled}
                                            >
                                                Close
                                            </Button>
                                            <Button className="mx-3" variant="primary" onClick={editJobFn} disabled={editDisabled}>
                                                Update
                                            </Button> */}
                                            {calendarFlag ?
                                                (<>
                                                    <Button className="mx-3" variant="primary" onClick={onPostDateAndTime} disabled={editDisabled}>
                                                        Update
                                                    </Button>
                                                </>) :
                                                (<>
                                                    <Button
                                                        className="mx-3"
                                                        variant="danger"
                                                        onClick={closeEditModal}
                                                        disabled={closeEditDisabled}
                                                    >
                                                        Close
                                                    </Button>
                                                    <Button className="mx-3" variant="primary" onClick={editJobFn} disabled={editDisabled}>
                                                        Update
                                                    </Button>
                                                </>)}
                                        </div>
                                    </Modal>

                                    <div className='row my-5'>
                                        <div className='col-12 text-center m-auto'>
                                            {!state?.fromDashboard && <button className='btn btn-primary' onClick={() => history.push(`/jobList/${props.match.params.talentId}/${props.match.params.entityTypeId}`)}>Back To Job List</button>}
                                        </div>
                                    </div>
                                </>)}

                        </div>


                    </div>

                </div>

            </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getTalentProfile: bindActionCreators(getTalentProfile, dispatch),
    getJobDetails: bindActionCreators(getJobDetails, dispatch),
    getFieldValuesForEditing: bindActionCreators(getFieldValuesForEditing, dispatch),
    editJob: bindActionCreators(editJob, dispatch),
    getSkillBio: bindActionCreators(getSkillBio, dispatch),
    getFormFieldsEntity: bindActionCreators(getFormFieldsEntity, dispatch),
    getFormFieldsPrespective: bindActionCreators(getFormFieldsPrespective, dispatch),
    getDaysList: bindActionCreators(getDaysList, dispatch),
    getActiveDaysList: bindActionCreators(getActiveDaysList, dispatch),
    getDailyCalendarDetails: bindActionCreators(getDailyCalendarDetails, dispatch),
    postJobDateAndTime: bindActionCreators(postJobDateAndTime, dispatch),
    getUserInformation: bindActionCreators(getUserInformation, dispatch),
    getHomeScreenList: bindActionCreators(getHomeScreenList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);