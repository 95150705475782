import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {Modal,Button}from 'react-bootstrap';
import { bindActionCreators } from "redux";
import { getViewNotification } from "../../../actions/user";
import { toast } from "react-toastify";
import Cancel from  "../../../public/images/cancel.svg"; 



const ViewNotificationModal = (props) => {
    console.log("props",props)
    const {show, toggleviewShow, notificationId} = props;
    const [viewNotification, setviewNotification] = useState({});
    const [loading, setLoading] = useState(false);

    const getViewNotification = () => {
      let params = {
          notificationId: notificationId,
      }
        setLoading(true);
        props.getViewNotification(params, res => {
          setLoading(false);
          if (res.status) {
             let notifcation = res.data
             setviewNotification(notifcation);
          }
          else {
            toast.error(res.message);
          }
        },
        err => {
          setLoading(false);
          toast.error('Something went wrong! Please try again later.');
        });
    }
  

  useEffect(() => {
     getViewNotification();
  }, []);


    return(
          <Modal show={show} 
        className="view_notification"
        backdrop="static"
        onHide={toggleviewShow}
        size='md'
        >
        <Modal.Header closeButton  >
          <Modal.Title>Notifications</Modal.Title>
          <button type="button" className="btn-closebox" aria-label="Close" onClick={toggleviewShow}>
            <img src={Cancel} alt="cancel"/>
          </button>
        </Modal.Header>
        <Modal.Body>  
              {loading ? (
                <div className="col-md-12 text-center">
                  <span className="spinner-grow spinner-border-sm"></span>
                </div>
              ) :
                <div>
                    <p><b>{viewNotification && viewNotification.subject }</b> </p>
                    <p>{viewNotification && viewNotification.message } </p>
                    
                   
                </div>  
                }    
        
        </Modal.Body>
      </Modal>
    )
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
   getViewNotification: bindActionCreators(getViewNotification, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(ViewNotificationModal);