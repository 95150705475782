import React, { useEffect, useState } from "react";
import { searchEmployerJobs } from "../../actions/employer/talent";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Skill from "../../public/img/skill.svg";
import JobLocation from "../../public/img/joblocation.svg";


const RightMenu = (props) => {
  
    var empl = props.empl
    const [jobsEmployer, setJobsEmployer] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if(empl.userProfileData?.selectedUserData?.userNameHash){
            searchEmployerJobs(empl.userProfileData?.selectedUserData?.userNameHash)
        }
    }, [empl]); 

    const searchEmployerJobs = (employerHashName) => {
        setLoading(true)
        if(employerHashName == null){
          setJobsEmployer([])
          return false;
        }
        var params={
          'employerHashName' :employerHashName,
        }
        props.searchEmployerJobs(params, (res) => {
          if(res.status == false){
            setJobsEmployer([])
            setLoading(false)
          }
          else{
            setJobsEmployer(res.data)
            setLoading(false)
          }
        });
    };

    // console.log("jobs", jobsEmployer.length);


    return (
        <div>
            {loading?
            <>
                <div className="row" style={{ width: "100%", height: "80vh" }}>
                    <div
                    className="col-12"
                    style={{ width: "100%", height: "100%" }}
                    >
                    <div
                        style={{
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                        verticalAlign: "middle",
                        lineHeight: "40vh",
                        }}
                    >
                        <div
                        className="spinner-grow"
                        role="status"
                        style={{ background: "#006838" }}
                        ></div>
                    </div>
                    </div>
                </div>
            </>
            :
            <>
                <div className="tab-pane fade show"  id={"jobdetails_"}
                    role="tabpanel" aria-labelledby="jobdescription1">
                    <button type="button" className="btn-close d-none" >
                    </button>
                    <div className="employer_details">
                        <a>
                            <h4>{empl?.userProfileData?.selectedEntityData?.title} || {empl.firstName} {empl.lastName}</h4>
                            <img src={JobLocation} alt="location" className="img-fluid" />
                            <span className="ml-2">{empl.address1}</span>
                        </a>
                        <a>
                            <div className="rating text-right">
                                {[1,2,3,4,5].map((x, i) =>
                                    empl.userProfileData?.selectedUserDataRating?.level >= x 
                                    ?
                                    <span key={i} className="fa fa-star checked"></span>
                                    :
                                    <span key={i} className="fa fa-star"></span>
                                )}
                            
                            </div>
                            <span>({empl?.userProfileData?.selectedUserDataRating?.level}) Rating</span>
                        </a>
                    </div>
                    <div className="about_Job">
                        <a  className="d-inline-flex align-items-center ">
                            <img src={Skill} alt="about" className="img-fluid" />
                            <span className="textHead_inner ml-2">About</span>
                        </a>
                        <p>{empl.userProfileData.selectedEntityData.description}</p>

                        
                        <div className="job_opportunities">
                            <h4>Career Opportunities At {empl?.userProfileData?.selectedEntityData?.title}</h4>
                            <div className="apply_jobs_Container">
                                {jobsEmployer.length > 0 ? 
                                <>
                                    {jobsEmployer.map((job,index)=>{
                                        return (
                                            <div key={index} className="apply_jobs_area">
                                            <span>{job.userProfileData?.selectedEntityData?.title} || {job.userProfileData?.selectedEntityData?.name}</span>
                                            {props.auth && props.auth.isLoggedIn ? (
                                                <button className="sliderFormBtn" type="submit">Apply for Job</button>
                                                ):(
                                                <button className="sliderFormBtn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#mySignin"
                                                >Login to apply</button>
                                                )
                                            }
                                            </div>
                                        ) 
                                    })}
                                </>
                                : <>
                                    <div className="apply_jobs_area">
                                    <span>No Jobs Found</span>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  const mapDispatchToProps = (dispatch) => ({
    searchEmployerJobs: bindActionCreators(
      searchEmployerJobs,
      dispatch
    ),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);