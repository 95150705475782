import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import logoutImg from "../../public/images/logout.png";
import settings from "../../public/images/settings.png";
import settings_hover from "../../public/images/settings_hover.png";
import inbox from "../../public/images/inbox.png";
import booking from "../../public/images/booking.png";
import booking_hover from "../../public/images/booking_hover.png";
import post from "../../public/images/post.png";
import postHover from "../../public/images/post_hover.png";
import talent from "../../public/images/talent.png";
import talentHover from "../../public/images/talehover.png";
import Dashboard_normal from "../../public/images/Dashboard_normal.png";
import Dashboard_green from "../../public/images/Dashboard_green.png";
import menu from "../../public/images/menu.png";
import basket from "../../public/images/basket.svg";
import basket_hover from "../../public/images/basket_white.svg";
import dashboard_logo from "../../public/images/dashboard_logo.png";
import { userLogOut } from "../../actions/user";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

const SidebarCustomer = (props) => {
  const history = useHistory();
  const isLogginCheck = props.auth.isLoggedIn;
  const userType = props.auth.userProfile.userType.userType;
  const [openSetting, setOpenSetting] = useState(false);
  const [logOutLoading, setlogOutLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const userLogout = () => {
    setlogOutLoading(true);
    props.onLogout(
      {},
      (res) => {
        setlogOutLoading(false);
        if (res.status) {
          // history.push("/");
          window.location.pathname = '/'
          setModal(false);
          // toast.success(
          //   res.data
          //     ? "Logged out successfully"
          //     : "Session timed out. Please login again."
          // );
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
        setlogOutLoading(false);
      }
    );
  };

  const onClickAnchorSettings = (event) => {
    event.preventDefault();
    if (!openSetting) {
      setOpenSetting(true);
    } else {
      setOpenSetting(false);
    }
  };

  const onClickOtherMenu = (event) => {
    event.preventDefault();
    setOpenSetting(false);
  };

  const isActiveMenu = (menuName) => {
    let pathName = window.location.pathname;
    if (pathName === menuName) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // let pathName = window.location.pathname;
    // if(pathName && pathName != '/'){
    //     let pathNameArr = pathName.split("/");
    //     if(pathNameArr[1] == 'perspective' || pathNameArr[1] == 'entityAttributes'){
    //         setSelectedTab('entityTypes')
    //     }
    //     else {
    //         setSelectedTab(pathNameArr[1])
    //     }
    // }
    // entityTypes();
  }, []);

  return (
    <>
      {isLogginCheck ? (
        <div
          className="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
          id="kt_aside"
        >
          <div className="brand flex-column-auto" id="kt_brand">
            <a className="custom-logo" style={{cursor:'pointer'}} onClick={(e) => {e.preventDefault(); history.push('/')}}>
              <img alt="Logo" src={dashboard_logo} />
            </a>
            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
              style={{ position: "relative", left: "54px" }}
            >
              <span className="svg-icon svg-icon svg-icon-xl active">
                <div className="custom-icon pt-5">
                  <img alt="Logo" src={menu} width="15px" height="15px" />
                </div>
              </span>
            </button>
          </div>
          <div
            className="aside-menu-wrapper flex-column-fluid"
            id="kt_aside_menu_wrapper"
          >
            <div
              id="kt_aside_menu"
              className="aside-menu my-4"
              data-menu-vertical="1"
              data-menu-scroll="1"
              data-menu-dropdown-timeout="500"
            >
              <ul className="menu-nav">
                <li
                  className={
                    !isActiveMenu("/home") ? "menu-item" : "menu-item my_hiring"
                  }
                  onClick={onClickOtherMenu}
                  aria-haspopup="true"
                >
                  <Link to="/home" className="menu-link">
                    <span className="svg-icon menu-icon">
                      <i className="left-side-icon">
                        <img
                          src={
                            !isActiveMenu("/home")
                              ? Dashboard_normal
                              : Dashboard_green
                          }
                        />{" "}
                      </i>
                    </span>
                    <span className="menu-text">Dashboard</span>
                  </Link>
                </li>
                <li
                  className={
                    !isActiveMenu("/talent")
                      ? "menu-item"
                      : "menu-item my_hiring"
                  }
                  onClick={onClickOtherMenu}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <Link to="/talent" className="menu-link menu-toggle">
                    <span className="svg-icon menu-icon">
                      <i className="left-side-icon">
                        <img
                          src={!isActiveMenu("/talent") ? talent : talentHover}
                        />
                      </i>
                    </span>
                    <span className="menu-text">Talent</span>
                  </Link>
                </li>
                <li
                  className={
                    !isActiveMenu("/post") ? "menu-item" : "menu-item my_hiring"
                  }
                  onClick={onClickOtherMenu}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <Link to="/post" className="menu-link menu-toggle">
                    <span className="svg-icon menu-icon">
                      <i className="left-side-icon">
                        <img src={!isActiveMenu("/post") ? post : postHover} />
                      </i>
                    </span>
                    <span className="menu-text">Post</span>
                  </Link>
                </li>
                <li
                  className={
                    !isActiveMenu("/bookingEmployer")
                      ? "menu-item"
                      : "menu-item my_hiring"
                  }
                  onClick={onClickOtherMenu}
                  aria-haspopup="true"
                >
                  <Link to="/bookingEmployer" className="menu-link">
                    <span className="svg-icon menu-icon">
                      <i className="left-side-icon">
                        <img
                          src={
                            !isActiveMenu("/bookingEmployer")
                              ? booking
                              : booking_hover
                          }
                        />
                      </i>
                    </span>
                    <span className="menu-text">Booking</span>
                  </Link>
                </li>

                <li
                  className={
                    !isActiveMenu("/networkEmployer")
                      ? "menu-item"
                      : "menu-item my_hiring"
                  }
                  onClick={onClickOtherMenu}
                  aria-haspopup="true"
                >
                  <Link to="/networkEmployer" className="menu-link">
                    <span className="svg-icon menu-icon">
                      <i className="left-side-icon">
                        <img
                          src={
                            !isActiveMenu("/networkEmployer")
                              ? basket_hover
                              : basket
                          }
                        />
                      </i>
                    </span>
                    <span className="menu-text">Network</span>
                  </Link>
                </li>

                <li
                  className={
                    openSetting
                      ? "menu-item menu-item-submenu menu-item-open"
                      : "menu-item menu-item-submenu"
                  }
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <a
                    onClick={onClickAnchorSettings}
                    className="menu-link menu-toggle"
                  >
                    <span className="svg-icon menu-icon">
                      <i className="left-side-icon">
                        <img src={!openSetting ? settings : settings_hover} />
                      </i>
                    </span>
                    <span className="menu-text">Settings</span>
                    <i className="menu-arrow fa fa-angle-down"></i>
                  </a>
                  <div className="menu-submenu" kt-hidden-height="180">
                    <i className="menu-arrow"></i>
                    <ul className="menu-subnav">
                      <li
                        className={
                          isActiveMenu("/change-password")
                            ? "menu-item my_hiring"
                            : "menu-item"
                        }
                        aria-haspopup="true"
                      >
                        {/* <a className="menu-link my_hiring" onClick={()=> {history.push('/change-password')}}>
                                                <i className="fas fa-caret-right"></i>
                                                <span className="menu-text">Change Password</span>
                                            </a> */}
                        <Link
                          to="/change-password"
                          className="menu-link menu-toggle"
                        >
                          <i className="fas fa-caret-right"></i>
                          <span className="menu-text">Change Password</span>
                        </Link>
                      </li>
                      <li
                        className={
                          isActiveMenu("/manage-payment")
                            ? "menu-item my_hiring"
                            : "menu-item"
                        }
                        aria-haspopup="true"
                      >
                        {/* <a href="#" className="menu-link">
                                                <i className="fas fa-caret-right"></i>
                                                <span className="menu-text">Manage Payment</span>
                                            </a> */}
                        <Link
                          to="/manage-payment"
                          className="menu-link menu-toggle"
                        >
                          <i className="fas fa-caret-right"></i>
                          <span className="menu-text">Manage Payment</span>
                        </Link>
                      </li>
                      <li
                        className={
                          isActiveMenu("/contact")
                            ? "menu-item my_hiring"
                            : "menu-item"
                        }
                        aria-haspopup="true"
                      >
                        {/* <a href="#" className="menu-link">
                                                <i className="fas fa-caret-right"></i>
                                                <span className="menu-text">Contact Us</span>
                                            </a> */}
                        <Link to="/contact" className="menu-link menu-toggle">
                          <i className="fas fa-caret-right"></i>
                          <span className="menu-text">Contact Us</span>
                        </Link>
                      </li>
                      <li
                        className={
                          isActiveMenu("/profileEmployer")
                            ? "menu-item my_hiring"
                            : "menu-item"
                        }
                        aria-haspopup="true"
                      >
                        {/* <a href="#" className="menu-link">
                                                <i className="fas fa-caret-right"></i>
                                                <span className="menu-text">Contact Us</span>
                                            </a> */}
                        <Link
                          to="/profileEmployer"
                          className="menu-link menu-toggle"
                        >
                          <i className="fas fa-caret-right"></i>
                          <span className="menu-text">User Profile</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  className="menu-item"
                  aria-haspopup="true"
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  <a className="menu-link">
                    <span className="svg-icon menu-icon">
                      <i className="left-side-icon">
                        <img src={logoutImg} />
                      </i>
                    </span>
                    <span className="menu-text">Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        false
      )}
      <Modal
        show={modal}
        size="md"
        centered
        style={{ background: "rgb(95 95 95 / 77%)" }}
      >
        <div className="text-center m-5">
          <Modal.Title>Are you sure, you want to logout ?</Modal.Title>
        </div>

        {logOutLoading ? (
          <div className="text-center m-5">
            <span className="spinner-border spinner-border-sm"></span>
          </div>
        ) : (
          <div className="text-center m-5">
            <Button
              className="mx-3"
              variant="danger"
              onClick={() => setModal(!modal)}
            >
              No
            </Button>
            <Button className="mx-3" variant="primary" onClick={userLogout}>
              Yes
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  onLogout: bindActionCreators(userLogOut, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCustomer);
