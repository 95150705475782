import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../redux/actions/auth';
import footer_logo from '../../public/images/footer-logo.svg';
import white_insta_icon_footer from '../../public/images/white_insta_icon_footer.svg';
// import { setAuthorizationToken } from "../../../utilities/RestClient";

const Footer = (props) => {
    const history = useHistory();
    
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="footer_logo"><a href="#" onClick={(e) => {history.push('/')}}><img src={footer_logo} alt=""/></a></div>
                    </div>
                    <div className="col-md-5 moblie_width">
                        <h4> MENU</h4>
                        <ul className="footer_menu"> 
                            <li><a href="#" onClick={(e) => {history.push('/aboutUs')}}>About </a></li>
                            <li><a href="#" onClick={(e) => {history.push('/contactUs')}}>Contact </a></li>
                        </ul>
                    </div>
                    <div className="col-md-2 moblie_text_c">
                    <h4> Social </h4>
                        <ul className="footer_socail"> 
                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i className="fab fa-twitter"></i> </a></li>
                            <li><a href="#"><img src={white_insta_icon_footer} alt=""/> </a></li>
                        </ul>
                    </div>
                </div>
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="copyright_text">Copyright © 2021 Timelyhire. All Rights Reserved.</div>
                        </div>
                        <div className="col-md-6">
                            <ul className="copyright_link"> 
                                <li><a href="#" onClick={(e) => {history.push('/terms_n_conditions')}}> Terms of Use</a></li>
                                <li><a href="#" onClick={(e) => {history.push('/privacy_policy')}}>Privacy Policy </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
		</div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    logout: () =>  dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);