import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { getTalentProfile } from "../../actions/employer/talent";
import insta from '../../public/images/insta.svg';
import talent_edit from '../../public/images/talent_edit.png';
import list1 from '../../public/images/list1.png';
import location from '../../public/images/location.png';


const TalentDetails = (props) => {
    const [userIdHash, setUserIdHash] = useState("");
    const [loading, setLoading] = useState(true);
    const [talentProfileData, setTalentProfileData] = useState("");
    const [employmentDataForShift, setEmploymentDataForShift] = useState([]);
    const [scheduleDataForShift, setScheduleDataForShift] = useState([]);
    const [facilityDataForShift, setFacilityDataForShift] = useState([]);
    const [payrollDataForShift, setPayrollDataForShift] = useState([]);
    const [shiftDataForShift, setShiftDataForShift] = useState([]);
    const [paymentDataForShift, setPaymentDataForShift] = useState([]);

    const history = useHistory();

    useEffect(() => {
        let params = {
            userProfileId: props.match.params.id
        }
        props.getTalentProfile(params, res => {
            if (res.status) {
                setTalentProfileData(res.data)

                if (res.data.userProfileDataTypes.employmentType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.employmentType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.employmentType[i].name)
                    }
                    setEmploymentDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.facilityType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.facilityType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.facilityType[i].name)
                    }
                    setFacilityDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.paymentType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.paymentType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.paymentType[i].name)
                    }
                    setPaymentDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.scheduleType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.scheduleType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.scheduleType[i].name)
                    }
                    setScheduleDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.shiftType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.shiftType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.shiftType[i].name)
                    }
                    setShiftDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.payrollType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.payrollType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.payrollType[i].name)
                    }
                    setPayrollDataForShift(arr)
                }

                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }, [])

    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
                <div className="d-flex flex-column-fluid">
                    <div className="custom-container" style={{ height: 'auto' }}>
                        <div className="row  pb-5">
                            <div className="col-lg-6 col-md-6">
                                <div className="inner_txecy">
                                    <h4>My Talents - About {talentProfileData.entityName}</h4>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                {/* <div className="talen_outre">
                                    <button className="btn" data-toggle="modal" data-target="#myModal12">+ CREATE TALENT</button>
                                </div> */}

                            </div>
                        </div>
                        <div className='row my-5'>
                            <div className='col-12 m-auto'>
                                <button className='btn btn-primary' onClick={() => history.push('/talent')}><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                            </div>
                        </div>

                        <div className="Profile_form">
                            {loading ?
                                (<>
                                    <div className="row" style={{ width: '100%', height: '30vh' }}>
                                        <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </>) :
                                (<>
                                    <div className='card'>
                                        <div className='card-body py-5'>
                                            {/* <h5>Business Name : <span style={{fontSize:'15px'}}>{talentProfileData.entityName}</span></h5>
                                          <h5>Location : <span style={{fontSize:'15px'}}>{talentProfileData.address1 ? talentProfileData.address1 : talentProfileData.address}</span></h5> */}
                                            <table className='table' style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Business Name</td>
                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{talentProfileData.entityName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Location</td>
                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{talentProfileData.address1 ? talentProfileData.address1 : talentProfileData.address}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Profession</td>
                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{talentProfileData.userProfileDataTypes.Profession}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Employment Type</td>
                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{employmentDataForShift.length > 0 ? employmentDataForShift.join(', ') : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Facility Type</td>
                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{facilityDataForShift.length > 0 ? facilityDataForShift.join(', ') : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Payment Type</td>
                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{paymentDataForShift.length > 0 ? paymentDataForShift.join(', ') : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Payroll Type</td>
                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{payrollDataForShift.length > 0 ? payrollDataForShift.join(', ') : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Schedule Type</td>
                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{scheduleDataForShift.length > 0 ? scheduleDataForShift.join(', ') : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Shift Type</td>
                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{shiftDataForShift.length > 0 ? shiftDataForShift.join(', ') : '-'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='row my-5'>
                                            <div className='col-12 text-center'>
                                                <button className='btn btn-primary' onClick={() => history.push('/talent')}>Back To Talents</button>
                                            </div>
                                        </div>
                                    </div>
                                </>)}

                        </div>


                    </div>

                </div>

            </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getTalentProfile: bindActionCreators(getTalentProfile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TalentDetails);