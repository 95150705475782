import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  getHomeScreenList,
  getHomeScreenTabs,
  invite,
} from "../../actions/employer/dashboard";
import small_dot from "../../public/images/small_dot.png";
import downArrow from "../../public/images/downArrow.svg";
import locationImg from "../../public/images/location.png";
import showImg from "../../public/images/Show.png";
import {
  debounce,
  getFormatedDate,
  showBadge,
} from "../../../utilities/helpers";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import newCalender from "../../public/images/newCalender.svg";
import filter from "../../public/images/filter.svg";
import searchIcon from "../../public/images/searchIcon.svg";
import { Button, Modal } from "react-bootstrap";
import { follow, shortlist, reportUser, blockUser, reportPost } from "../../actions/common";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

const EmployerDashboard = (props) => {
  const history = useHistory();
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [tabsLoading, setTabsLoading] = useState(false);

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const [filterShow, setFilterShow] = useState(false);
  const [filterVisible, setFilterVisible] = useState(true);
  const [selectedUserProfileIds, setSelectedUserProfileIds] = useState([]);
  const initModal = { modal: false, type: "", data: {} };
  const [modal, setModal] = useState(initModal);
  const [modalLoading, setModalLoading] = useState(false);

  const [reportComment, setReportComment] = useState('');

  const getHomeScreenTabs = () => {
    setTabsLoading(true);
    props.getHomeScreenTabs(
      { userIdHash: props.auth.userProfile.userIdHash },
      (res) => {
        setTabsLoading(false);
        if (res.status) {
          setTabs(res.data);
        }
      }
    );
  };

  const getHomeScreenList = (entityTypeId, userProfileIds, searchText) => {
    setListLoading(true);
    props.getHomeScreenList(
      {
        userIdHash: props.auth.userProfile.userIdHash,
        entityTypeId: entityTypeId,
        userProfileIds: userProfileIds,
        searchText: searchText,
      },
      (res) => {
        setListLoading(false);
        if (res.status) {
          console.log('LIST', res.data)
          setList(res.data);
        }
      }
    );
  };

  const shortlist = () => {
    setModalLoading(true);
    props.shortlist(
      {
        jobUserProfileId: modal.data.selectedEntityData.jobUserProfileId,
        providerUserProfileId: modal.data.selectedUserData.userProfileId,
        entityId: modal.data.selectedEntityData.id,
      },
      (res) => {
        setModalLoading(false);
        if (res.status) {
          setModal(initModal);
          toast.success("Shortlisted Successfully");
        }
      },
      (err) => {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong!");
      }
    );
  };

  const follow = () => {
    setModalLoading(true);
    props.follow(
      {
        jobUserProfileId: modal.data.selectedEntityData.jobUserProfileId,
        providerUserProfileId: modal.data.selectedUserData.userProfileId,
      },
      (res) => {
        setModalLoading(false);
        if (res.status) {
          setModal(initModal);
          toast.success("Followed Successfully");
        }
      },
      (err) => {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong!");
      }
    );
  };

  const invite = () => {
    setModalLoading(true);
    props.invite(
      {
        jobUserProfileId: modal.data.selectedEntityData.jobUserProfileId,
        entityId: modal.data.selectedEntityData.id,
        providerUserProfileId: modal.data.selectedUserData.userProfileId,
      },
      (res) => {
        setModalLoading(false);
        if (res.status) {
          setModal(initModal);
          toast.success("Invited Successfully");
        }
      },
      (err) => {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong!");
      }
    );
  };

  const onClickAction = () => {
    if (modal.type === "shortlist") {
      shortlist();
    } else if (modal.type === "follow") {
      follow();
    } else if (modal.type === "invite") {
      invite();
    } else if (modal.type === "Report") {
      reportUserFn(modal.data)
    } else if (modal.type === "Block") {
      blockUserFn(modal.data)
    } else if (modal.type === "Report this Post of") {
      reportPostFn(modal.data)
    }
  };

  useEffect(() => {
    getHomeScreenTabs();
    getHomeScreenList("");
  }, []);

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value) {
      getHomeScreenList("", "", value);
    } else {
      getHomeScreenList("");
    }
  };

  const optimizedSearch = useCallback(debounce(handleSearch), []);

  const openPopup = (e, value) => {
    e.preventDefault();
    let elem = document.getElementById(value.id + "popup");
    elem.style.display = "block";
  };

  const closePopup = (e, value) => {
    e.preventDefault();
    let elem = document.getElementById(value.id + "popup");
    elem.style.display = "none";
  };

  const reportCommentChanged = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setReportComment(value)
  }

  const reportUserBtnClicked = (e, value) => {
    e.preventDefault();
    console.log(value)
    setModal({modal:true, type:"Report", data: value.userProfileData})
    
    closePopup(e, value)
  }
  
  const blockUserBtnClicked = (e, value) => {
    e.preventDefault();
    console.log(value)
    setModal({modal:true, type:"Block", data: value.userProfileData})
    
    closePopup(e, value)
  }
  
  const reportPostBtnClicked = (e, value) => {
    e.preventDefault();
    console.log(value)
    setModal({modal:true, type:"Report this Post of", data: value.userProfileData})
    
    closePopup(e, value)
  }

  const reportUserFn = (value) => {
    setModalLoading(true)
    let params = {
      userIdHash: props.auth.userProfile.userIdHash,
      userNameHash: value.selectedUserData?.userNameHash,
      comment: reportComment
    };
  
    props.reportUser(params, res => {
      setModalLoading(false)
      if(res.status) {
        setModal(!modal)
        swal({
          text: 'User reported successfully',
          icon: 'success'
        }).then(() => {
          setReportComment('')
        })
      } else {
        toast.error('Something went wrong!')
      }
    })
  }
  
  const blockUserFn = (value) => {
    setModalLoading(true)
    let params = {
      userIdHash: props.auth.userProfile.userIdHash,
      userNameHash: value.selectedUserData?.userNameHash,
      comment: reportComment
    };
  
    props.blockUser(params, res => {
      setModalLoading(false)
      if(res.status) {
        setModal(!modal)
        swal({
          text: 'User blocked successfully',
          icon: 'success'
        }).then(() => {
          setReportComment('')
        })
      } else {
        toast.error('Something went wrong!')
      }
    })
  }
  
  const reportPostFn = (value) => {
    setModalLoading(true)
    let params = {
      userIdHash: props.auth.userProfile.userIdHash,
      entityId: value.selectedEntityData?.id,
      comment: reportComment
    };
  
    props.reportPost(params, res => {
      setModalLoading(false)
      if(res.status) {
        setModal(!modal)
        swal({
          text: 'Post reported successfully',
          icon: 'success'
        }).then(() => {
          setReportComment('')
        })
      } else {
        toast.error('Something went wrong!')
      }
    })
  }

  return (
    <div
      className="content d-flex flex-column flex-column-fluid pt-5 pb-0"
      id="kt_content"
    >
      <div className="d-flex flex-column-fluid">
        <div className="custom-container custom_contr5">
          <div className="Profile_form custom_profile4">
            <div className="white_bf6">
              <div className="new_whitr" style={{ background: "#F6F6F6" }}>
                <h4 className="mb-6">
                  Dashboard
                  <img src={small_dot} />
                  <span>Your Job</span>
                </h4>
                <div className="custpom_tabs">
                  {tabsLoading ? (
                    // <div className="nav nav-tabs">
                    //   <span className="spinner-border spinner-border-sm"></span>
                    // </div>
                    <div
                      className="row"
                      style={{ width: "100%", height: "5vh" }}
                    >
                      <div
                        className="col-12"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            textAlign: "center",
                            verticalAlign: "middle",
                            lineHeight: "5vh",
                          }}
                        >
                          {/* <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div> */}
                          <div
                            className="spinner-border"
                            role="status"
                            style={{ color: "#006838" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="custpom_tabs"
                      style={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        marginTop: "",
                        paddingTop: "12px",
                        paddingBottom: "2px",
                        borderRadius: "10px",
                        boxShadow: "0px 5px 6px #0000000d",
                        opacity: "1",
                      }}
                    >
                      <ul
                        className="nav nav-tabs"
                        style={{ justifyContent: "start" }}
                      >
                        <li
                          className="mb-2"
                          onClick={() => {
                            getHomeScreenList("");
                            setFilterVisible(true);
                            setActiveTab(0);
                          }}
                        >
                          <a
                            href="#"
                            className={activeTab === 0 ? "active" : ""}
                          >
                            All
                          </a>
                        </li>
                        {tabs.length > 0
                          ? tabs.map((item) => (
                              <li
                                className="mb-2"
                                onClick={() => {
                                  getHomeScreenList(
                                    item.userProfileData.selectedEntityTypeData
                                      .entityTypeId
                                  );
                                  setFilterVisible(false);
                                  setActiveTab(item.id);
                                }}
                              >
                                <a
                                  style={{ fontSize: "14px" }}
                                  href="#"
                                  className={
                                    activeTab === item.id ? "active" : ""
                                  }
                                >
                                  {item.userProfileData.selectedEntityTypeData.profession.toUpperCase()}
                                </a>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="custpom_tabs" style={{ marginTop: "-70px" }}>
                <div className="tab-content">
                  <div id="home" className="tab-pane fade in active show">
                    <div
                      className="row custom_spece5 mt-3 mb-5 justify-content-between"
                      style={{ poasition: "relative" }}
                    >
                      <div className="col-md-6">
                        {filterVisible && (
                          <div className="talent_details">
                            <a
                              href="javascript:void(0)"
                              className={
                                filterShow
                                  ? "panel-heading heading_links_ accordion_title_info_ d-flex justify-content-between"
                                  : "panel-heading heading_links_ accordion_title_info_ collapsed d-flex justify-content-between"
                              }
                              onClick={() => setFilterShow(!filterShow)}
                            >
                              <span>My Talent List:</span>
                              <img src={filter} />
                            </a>
                            <div
                              className={
                                filterShow ? "collapse show" : "collapse"
                              }
                              id="acceptance"
                            >
                              <div
                                className="card border-0 pt-2"
                                style={{
                                  "max-height": "200px",
                                  overflow: "auto",
                                }}
                              >
                                {listLoading ? (
                                  <div className="col-md-12 text-center">
                                    <span className="spinner-grow spinner-border-sm"></span>
                                  </div>
                                ) : list.length ? (
                                  list.map((item) => (
                                    <div className="form-group">
                                      <input
                                        type="checkbox"
                                        className="form-check-input mr-3"
                                        id="checkbox"
                                        onClick={() =>
                                          setSelectedUserProfileIds([
                                            ...selectedUserProfileIds,
                                            item.userProfileData
                                              .selectedUserData.userProfileId,
                                          ])
                                        }
                                      />
                                      <label className="checbox_text_">
                                        <span className="idDetail_">
                                          ID :{" "}
                                          {
                                            item.userProfileData
                                              .selectedEntityData.id
                                          }{" "}
                                          {
                                            item.userProfileData
                                              .selectedEntityTypeData
                                              .entityTypeName
                                          }
                                        </span>
                                        <p className="add_">
                                          <img
                                            src={locationImg}
                                            alt="map"
                                            className=""
                                          />
                                          {item.city}, {item.country}
                                        </p>
                                      </label>
                                    </div>
                                  ))
                                ) : (
                                  "No Records"
                                )}
                                <button
                                  className="apply_btn"
                                  onClick={() => {
                                    getHomeScreenList(
                                      "",
                                      selectedUserProfileIds.toString()
                                    );
                                    setFilterShow(!filterShow);
                                  }}
                                >
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <img
                        src={searchIcon}
                        alt="Search"
                        style={{
                          position: "absolute",
                          zIndex: "99999",
                          paddingTop: "9px",
                          paddingLeft: "0px",
                          right: "350px",
                          zIndex:"5",
                        }}
                      />
                      <input
                        onChange={optimizedSearch}
                        type="text"
                        name="Search"
                        className="form-control col-md-6"
                        placeholder="Search"
                        style={{
                          maxWidth: "350px",
                          float: "right",
                          marginRight: "12px",
                          paddingLeft: "40px",
                          zIndex:"1",
                        }}
                      />
                    </div>
                    <div className="row custom_spece5">
                      {listLoading ? (
                        <div
                          className="row"
                          style={{ width: "100%", height: "30vh" }}
                        >
                          <div
                            className="col-12"
                            style={{ width: "100%", height: "100%" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                textAlign: "center",
                                verticalAlign: "middle",
                                lineHeight: "50vh",
                              }}
                            >
                              <div
                                className="spinner-grow"
                                role="status"
                                style={{ background: "#006838" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      ) : list.length > 0 ? (
                        list.map((item) => (
                          <div className="col-md-4 mb-5 mt-5">
                            <div
                              className="inner_docter custom_dt"
                              style={{
                                boxShadow: "rgb(0 0 0 / 5%) 0px 5px 6px",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-12 col-md-6">
                                  <div className="job_detailsContainer">
                                    <div className="d-flex">
                                      <h5
                                        className="talent_user_name"
                                        style={{
                                          color: "green",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {item.userProfileData.selectedEntityData
                                          .name
                                          ? item.userProfileData
                                              .selectedEntityData.name
                                          : "---"}{" "}
                                        /{" "}
                                        {item.userProfileData.selectedEntityData
                                          .title
                                          ? item.userProfileData
                                              .selectedEntityData.title
                                          : "---"}
                                      </h5>
                                      <div>
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                          style={{
                                            color: "black",
                                            fontSize: "18px",
                                            cursor: "pointer",
                                            fontWeight: "500",
                                            position: "absolute",
                                            right: "25px",
                                          }}
                                          onClick={(e) => {
                                            openPopup(e, item);
                                          }}
                                        ></i>
                                      </div>
                                    </div>

                                    <div
                                      className="blush_outer"
                                      id={item.id + "popup"}
                                      style={{
                                        position: "absolute",
                                        height: "135px",
                                        width: "135px",
                                        top: "18px",
                                        right: "24px",
                                        border: "1px solid gainsboro",
                                        display: "none",
                                        zIndex: "9",
                                      }}
                                    >
                                      <div className="d-flex">
                                        <p
                                          className="p-2 mb-0"
                                          style={{
                                            color: "grey",
                                          }}
                                        >
                                          More Options
                                        </p>
                                        <i
                                          className="fa fa-times"
                                          aria-hidden="true"
                                          style={{
                                            color: "grey",
                                            fontSize: "14px",
                                            paddingTop: "10px",
                                            marginLeft: "15px",
                                          }}
                                          onClick={(e) => {
                                            closePopup(e, item);
                                          }}
                                        ></i>
                                      </div>
                                      <p
                                        className="p-2 mt-0 mb-0"
                                        style={{
                                          borderBottom: "1px solid gainsboro",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          reportUserBtnClicked(e, item);
                                        }}
                                      >
                                        Report User
                                      </p>
                                      <p
                                        className="p-2 mt-0 mb-0"
                                        style={{
                                          borderBottom: "1px solid gainsboro",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          reportPostBtnClicked(e, item);
                                        }}
                                      >
                                        Report Post
                                      </p>
                                      <p
                                        className="p-2 mt-0 mb-0"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          blockUserBtnClicked(e, item);
                                        }}
                                      >
                                        Block User
                                      </p>
                                    </div>

                                    <p className="talent_id">
                                      Job ID :{" "}
                                      {
                                        item.userProfileData.selectedEntityData
                                          .id
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center cust_spc">
                                <div className="col-md-9">
                                  <div className="user_details">
                                    <div className="circleName">
                                      {
                                        item.userProfileData
                                          .selectedEntityTypeData
                                          .entityTypeNameAbbreviation
                                      }
                                    </div>
                                    <img
                                      src={
                                        item.isVerified ? verified : unVerified
                                      }
                                      alt="verify"
                                      style={{
                                        position: "absolute",
                                        top: "35px",
                                        left: "48px",
                                      }}
                                    />
                                    <h5
                                      className="talent_user_name"
                                      style={{
                                        color: "green",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {item.userProfileData.selectedUserData
                                        .firstName
                                        ? item.userProfileData.selectedUserData
                                            .firstName
                                        : "---"}{" "}
                                      {
                                        item.userProfileData.selectedUserData
                                          .lastName
                                      }
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-md-3 text-right">
                                  <img
                                    src={showBadge(
                                      item.userProfileData
                                        .selectedUserDataRating
                                    )}
                                    alt="badge"
                                  />
                                </div>
                              </div>
                              <div className="row cust_spc pt-0">
                                <div className="col-sm-12 col-lg-6">
                                  <div className="date_container">
                                    <img src={newCalender} alt="calender" />
                                    <span className="date_ talent_id ml-2">
                                      {getFormatedDate(
                                        item.userProfileData.selectedEntityData
                                          .createdDate,
                                        "MMM DD, YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="row cust_spc pt-0">
                                <div className="col-sm-12 col-lg-12">
                                  <div className="info_container_">
                                    <ul className="job_info_list_">
                                      <li className="mb-3">
                                        <span className="job_infoHeading_ me-25">
                                          Employment Type :
                                        </span>
                                        <p className="details_value_">
                                          {" "}
                                          {item.userProfileData.employmentType
                                            ?.length
                                            ? Array.from(
                                                item.userProfileData
                                                  .employmentType,
                                                (x) => x.name
                                              ).join(", ")
                                            : "N/A"}
                                        </p>
                                      </li>
                                      <li className="mb-3">
                                        <span className="job_infoHeading_ me-25">
                                          Payment Type :
                                        </span>
                                        <p className="details_value_">
                                          {" "}
                                          {item.userProfileData.paymentType
                                            ?.length
                                            ? Array.from(
                                                item.userProfileData
                                                  .paymentType,
                                                (x) => x.name
                                              ).join(", ")
                                            : "N/A"}
                                        </p>
                                      </li>
                                      <li className="mb-3">
                                        <span className="job_infoHeading_ me-25">
                                          Payroll Type :
                                        </span>
                                        <p className="details_value_">
                                          {" "}
                                          {item.userProfileData.payrollType
                                            ?.length
                                            ? Array.from(
                                                item.userProfileData
                                                  .payrollType,
                                                (x) => x.name
                                              ).join(", ")
                                            : "N/A"}
                                        </p>
                                      </li>
                                      <li className="mb-3">
                                        <span className="job_infoHeading_ me-25">
                                          Schedule Type :
                                        </span>
                                        <p className="details_value_">
                                          {" "}
                                          {item.userProfileData.scheduleType
                                            ?.length
                                            ? Array.from(
                                                item.userProfileData
                                                  .scheduleType,
                                                (x) => x.name
                                              ).join(", ")
                                            : "N/A"}
                                        </p>
                                      </li>
                                      <li className="mb-3">
                                        <span className="job_infoHeading_ me-25">
                                          Facility Type :
                                        </span>
                                        <p className="details_value_">
                                          {" "}
                                          {item.userProfileData.facilityType
                                            ?.length
                                            ? Array.from(
                                                item.userProfileData
                                                  .facilityType,
                                                (x) => x.name
                                              ).join(", ")
                                            : "N/A"}
                                        </p>
                                      </li>
                                      <li className="mb-3">
                                        <span className="job_infoHeading_ me-25">
                                          Shift Type :
                                        </span>
                                        <p className="details_value_">
                                          {" "}
                                          {item.userProfileData.shiftType
                                            ?.length
                                            ? Array.from(
                                                item.userProfileData.shiftType,
                                                (x) => x.name
                                              ).join(", ")
                                            : "N/A"}
                                        </p>
                                      </li>
                                      <li className="mb-3">
                                        <span className="job_infoHeading_ me-25">
                                          Location :
                                        </span>
                                        <p className="details_value_">
                                          {" "}
                                          {item.city}, {item.state},{" "}
                                          {item.country}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="open_job">
                                <h5>
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      history.push({
                                        pathname: "/candidate",
                                        state: item,
                                      })
                                    }
                                  >
                                    <img src={showImg} alt="show" />
                                    <span
                                      className="ml-2"
                                      style={{ fontSize: "14px" }}
                                    >
                                      View Candidate Details
                                    </span>
                                  </a>
                                </h5>
                              </div>
                              <div className="button_list">
                                <a
                                  className="about_btn shift_outer4"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    setModal({
                                      modal: !modal.modal,
                                      type: "shortlist",
                                      data: item.userProfileData,
                                    })
                                  }
                                >
                                  ShortList
                                </a>
                                <a
                                  className="about_btn"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    setModal({
                                      modal: !modal.modal,
                                      type: "follow",
                                      data: item.userProfileData,
                                    })
                                  }
                                >
                                  Follow
                                </a>
                                <a
                                  className="about_btn shift_outer5"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    setModal({
                                      modal: !modal.modal,
                                      type: "invite",
                                      data: item.userProfileData,
                                    })
                                  }
                                >
                                  Invite
                                </a>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="text-center m-5 w-100">
                          <h3>No Result(s) Found</h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modal.modal}
        size="md"
        centered
        style={{ background: "rgb(95 95 95 / 77%)" }}
      >
        <div className="text-center m-5">
          <Modal.Title>
            Are you sure, you want to {modal.type}{" "}
            {modal.data?.selectedUserData?.firstName
              ? modal.data?.selectedUserData?.firstName
              : "--"}{" "}
            {modal.data?.selectedUserData?.lastName
              ? modal.data?.selectedUserData?.lastName
              : "--"}{" "}
            ?
          </Modal.Title>
        </div>

        {modal.type === "Report" ? (
          <>
            <div className="text-center m-5">
              <Modal.Body>
                <p style={{ textAlign: "center" }}>
                  Comment your reason (optional):
                </p>
                <textarea
                  rows="4"
                  value={reportComment}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    reportCommentChanged(e);
                  }}
                ></textarea>
              </Modal.Body>
            </div>
          </>
        ) : modal.type === "Block" ? (
          <>
            <div className="text-center m-5">
              <Modal.Body>
                <p style={{ textAlign: "center" }}>
                  Comment your reason (optional):
                </p>
                <textarea
                  rows="4"
                  value={reportComment}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    reportCommentChanged(e);
                  }}
                ></textarea>
              </Modal.Body>
            </div>
          </>
        ) : modal.type === "Report this Post of" ? (
          <>
            <div className="text-center m-5">
              <Modal.Body>
                <p style={{ textAlign: "center" }}>
                  Comment your reason (optional):
                </p>
                <textarea
                  rows="4"
                  value={reportComment}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    reportCommentChanged(e);
                  }}
                ></textarea>
              </Modal.Body>
            </div>
          </>
        ) : (
          ""
        )}

        {modalLoading ? (
          <div className="text-center m-5">
            <span className="spinner-border spinner-border-sm"></span>
          </div>
        ) : (
          <div className="text-center m-5">
            <Button
              className="mx-3"
              variant="danger"
              onClick={() => {setModal(!modal); setReportComment('');}}
            >
              No
            </Button>
            <Button className="mx-3" variant="primary" onClick={onClickAction}>
              Yes
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getHomeScreenTabs: bindActionCreators(getHomeScreenTabs, dispatch),
  getHomeScreenList: bindActionCreators(getHomeScreenList, dispatch),
  shortlist: bindActionCreators(shortlist, dispatch),
  follow: bindActionCreators(follow, dispatch),
  invite: bindActionCreators(invite, dispatch),
  reportUser: bindActionCreators(reportUser, dispatch),
  blockUser: bindActionCreators(blockUser, dispatch),
  reportPost: bindActionCreators(reportPost, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployerDashboard);
