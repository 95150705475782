import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { getHomeScreenTabs, getHomeScreenList } from "../../actions/employee/dashboard";
import { getOpenStateData, getOpenStateDataWithEntityTypeId, getInterestedStateData, getInterestedStateDataWithEntityTypeId, getProspectStateData, getProspectStateDataWithEntityTypeId, getInterviewingStateData, getInterviewingStateDataWithEntityTypeId, getHiredStateData, getHiredStateDataWithEntityTypeId, getBacklogStateData, getBacklogStateDataWithEntityTypeId, moveForwardToInterested, moveForwardToProspect, moveForwardToInterviewing, moveForwardToHired, reapplyBacklogProfile } from "../../actions/employer/booking";
import white_arrow from '../../public/images/white_arrow.png';
import tham from '../../public/images/tham.png';
import showImg from "../../public/images/Show.png";
import { debounce, getFormatedDate, showBadge, showBadgeForNullValueEmployee } from "../../../utilities/helpers";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import newCalender from "../../public/images/newCalender.svg";
import { toast } from 'react-toastify';
import swal from 'sweetalert';


const BookingEmployee = (props) => {
   const history = useHistory();
   const [tabs, setTabs] = useState([]);
   const [activeTab, setActiveTab] = useState(0);
   const [tabsLoading, setTabsLoading] = useState(false);

   const [filterVisible, setFilterVisible] = useState(true);

   const [entityTypeId, setEntityTypeId] = useState();

   const [openFlag, setOpenFlag] = useState(true);
   const [interestedFlag, setInterestedFlag] = useState(false);
   const [prospectFlag, setProspectFlag] = useState(false);
   const [interviewingFlag, setInterviewingFlag] = useState(false);
   const [hiredFlag, setHiredFlag] = useState(false);
   const [backlogFlag, setBacklogFlag] = useState(false);

   const [openDataLoading, setOpenDataLoading] = useState(true);
   const [interestedDataLoading, setInterestedDataLoading] = useState(true);
   const [prospectDataLoading, setProspectDataLoading] = useState(true);
   const [interviewingDataLoading, setInterviewingDataLoading] = useState(true);
   const [hiredDataLoading, setHiredDataLoading] = useState(true);
   const [backlogDataLoading, setBacklogDataLoading] = useState(true);

   const [openData, setOpenData] = useState([]);
   const [interestedData, setInterestedData] = useState([]);
   const [prospectData, setProspectData] = useState([]);
   const [interviewingData, setInterviewingData] = useState([]);
   const [hiredData, setHiredData] = useState([]);
   const [backlogData, setBacklogData] = useState([]);

   useEffect(() => {
      getHomeScreenTabs();
      setActiveTab(0)
      getOpenData()
   }, []);

   const getHomeScreenTabs = () => {
      setTabsLoading(true);
      props.getHomeScreenTabs(
         { userIdHash: props.auth.userProfile.userIdHash },
         (res) => {
            setTabsLoading(false);
            if (res.status) {
               setTabs(res.data);
            }
         }
      );
   };

   const getHomeScreenList = (entityTypeId, userProfileIds, searchText) => {
      // setListLoading(true);
      props.getHomeScreenList(
         {
            userIdHash: props.auth.userProfile.userIdHash,
            entityTypeId: entityTypeId,
            userProfileIds: userProfileIds,
            searchText: searchText,
         },
         (res) => {
            //  setListLoading(false);
            if (res.status) {
               // setList(res.data);
            }
         }
      );
   };

   const setEntityTypeIdFun = (id) => {
      console.log(id)
      setEntityTypeId(id)
   }

   const getOpenData = () => {
      setOpenDataLoading(true)
      props.getOpenStateData({}, res => {
         if (res.status) {
            console.log('Open Data for All', res.data)
            setOpenData(res.data)
            setOpenDataLoading(false)
         } else {
            toast.error(res.message ? res.message : 'Something went wrong')
         }
      })
   }

   const getOpenDataWithEntityTypeId = (id) => {
      props.getOpenStateDataWithEntityTypeId({ entityTypeId: id }, res => {
         setOpenDataLoading(true)
         props.getOpenStateDataWithEntityTypeId({ entityTypeId: id }, res => {
            if (res.status) {
               console.log('Open Data for Entity Id', res.data)
               setOpenData(res.data)
               setOpenDataLoading(false)
            } else {
               toast.error(res.message ? res.message : 'Something went wrong')
            }
         })
      })
   }

   const getInterestedData = () => {
      setInterestedDataLoading(true)
      props.getInterestedStateData({}, res => {
         if (res.status) {
            console.log('Interested Data for All', res.data)
            setInterestedData(res.data)
            setInterestedDataLoading(false)
         } else {
            toast.error(res.message ? res.message : 'Something went wrong')
         }
      })
   }

   const getInterestedDataWithEntityTypeId = (id) => {
      props.getInterestedStateDataWithEntityTypeId({ entityTypeId: id }, res => {
         setInterestedDataLoading(true)
         props.getInterestedStateDataWithEntityTypeId({ entityTypeId: id }, res => {
            if (res.status) {
               console.log('Interested Data for Entity Id', res.data)
               setInterestedData(res.data)
               setInterestedDataLoading(false)
            } else {
               toast.error(res.message ? res.message : 'Something went wrong')
            }
         })
      })
   }

   const getProspectData = () => {
      setProspectDataLoading(true)
      props.getProspectStateData({}, res => {
         if (res.status) {
            console.log('Prospect Data for All', res.data)
            setProspectData(res.data)
            setProspectDataLoading(false)
         } else {
            toast.error(res.message ? res.message : 'Something went wrong')
         }
      })
   }

   const getProspectDataWithEntityTypeId = (id) => {
      props.getProspectStateDataWithEntityTypeId({ entityTypeId: id }, res => {
         setProspectDataLoading(true)
         props.getProspectStateDataWithEntityTypeId({ entityTypeId: id }, res => {
            if (res.status) {
               console.log('Prospect Data for Entity Id', res.data)
               setProspectData(res.data)
               setProspectDataLoading(false)
            } else {
               toast.error(res.message ? res.message : 'Something went wrong')
            }
         })
      })
   }

   const getInterviewingData = () => {
      setInterviewingDataLoading(true)
      props.getInterviewingStateData({}, res => {
         if (res.status) {
            console.log('Interviewing Data for All', res.data)
            setInterviewingData(res.data)
            setInterviewingDataLoading(false)
         } else {
            toast.error(res.message ? res.message : 'Something went wrong')
         }
      })
   }

   const getInterviewingDataWithEntityTypeId = (id) => {
      props.getInterviewingStateDataWithEntityTypeId({ entityTypeId: id }, res => {
         setInterviewingDataLoading(true)
         props.getInterviewingStateDataWithEntityTypeId({ entityTypeId: id }, res => {
            if (res.status) {
               console.log('Interviewing Data for Entity Id', res.data)
               setInterviewingData(res.data)
               setInterviewingDataLoading(false)
            } else {
               toast.error(res.message ? res.message : 'Something went wrong')
            }
         })
      })
   }

   const getHiredData = () => {
      setHiredDataLoading(true)
      props.getHiredStateData({}, res => {
         if (res.status) {
            console.log('Hired Data for All', res.data)
            setHiredData(res.data)
            setHiredDataLoading(false)
         } else {
            toast.error(res.message ? res.message : 'Something went wrong')
         }
      })
   }

   const getHiredDataWithEntityTypeId = (id) => {
      props.getHiredStateDataWithEntityTypeId({ entityTypeId: id }, res => {
         setHiredDataLoading(true)
         props.getHiredStateDataWithEntityTypeId({ entityTypeId: id }, res => {
            if (res.status) {
               console.log('Hired Data for Entity Id', res.data)
               setHiredData(res.data)
               setHiredDataLoading(false)
            } else {
               toast.error(res.message ? res.message : 'Something went wrong')
            }
         })
      })
   }

   const getBacklogData = () => {
      setBacklogDataLoading(true)
      props.getBacklogStateData({}, res => {
         if (res.status) {
            console.log('Backlog Data for All', res.data)
            setBacklogData(res.data)
            setBacklogDataLoading(false)
         } else {
            toast.error(res.message ? res.message : 'Something went wrong')
         }
      })
   }

   const getBacklogDataWithEntityTypeId = (id) => {
      props.getBacklogStateDataWithEntityTypeId({ entityTypeId: id }, res => {
         setBacklogDataLoading(true)
         props.getBacklogStateDataWithEntityTypeId({ entityTypeId: id }, res => {
            if (res.status) {
               console.log('Backlog Data for Entity Id', res.data)
               setBacklogData(res.data)
               setBacklogDataLoading(false)
            } else {
               toast.error(res.message ? res.message : 'Something went wrong')
            }
         })
      })
   }

   const acceptInterestedFn = (item) => {
      console.log('ITEM', item)
      swal({
         title: "Are you sure you want to move forward?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      }).then((willDelete) => {
         if (willDelete) {
            setInterestedDataLoading(true)
            props.moveForwardToInterested({ applyIdHash: item.userProfileData.applyData.applyIdHash, userIdHash: props.auth.userProfile.userIdHash, value: 1 }, res => {
               console.log('RES', res)
               setInterestedDataLoading(false)
               if (res.status) {
                  columnClicked('interested')
                  swal({
                     title: "Congratulations!! You have moved one step closer to get hired for the job",
                     text: "",
                     icon: "success"
                  }).then(onUpdate => {
                     columnClicked('interested')
                  })
               } else {
                  toast.error('Something went wrong')
                  setInterestedDataLoading(false)
               }
            })
         }

      })

   }

   const rejectInterestedFn = (item) => {
      console.log('ITEM', item)
      swal({
         title: "Are you sure you do not want to move ahead with the job?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      }).then((willDelete) => {
         if (willDelete) {
            setInterestedDataLoading(true)
            props.moveForwardToInterested({ applyIdHash: item.userProfileData.applyData.applyIdHash, userIdHash: props.auth.userProfile.userIdHash, value: 0 }, res => {
               console.log('RES', res)
               setInterestedDataLoading(false)
               if (res.status) {
                  columnClicked('interested')
                  swal({
                     title: "You have cancelled / rejected the job successfully",
                     text: "",
                     icon: "success"
                  }).then(onUpdate => {
                     columnClicked('interested')
                  })
               } else {
                  toast.error('Something went wrong')
                  setInterestedDataLoading(false)
               }
            })
         }

      })

   }

   const acceptProspectFn = (item) => {
      console.log('ITEM', item)
      swal({
         title: "Are you sure you want to move forward?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      }).then((willDelete) => {
         if (willDelete) {
            setProspectDataLoading(true)
            props.moveForwardToProspect({ applyIdHash: item.userProfileData.applyData.applyIdHash, userIdHash: props.auth.userProfile.userIdHash, value: 1 }, res => {
               console.log('RES', res)
               setProspectDataLoading(false)
               if (res.status) {
                  columnClicked('prospect')
                  swal({
                     title: "Congratulations!! You have moved one step closer to get hired for the job",
                     text: "",
                     icon: "success"
                  }).then(onUpdate => {
                     columnClicked('prospect')
                  })
               } else {
                  toast.error('Something went wrong')
                  setProspectDataLoading(false)
               }
            })
         }

      })

   }

   const rejectProspectFn = (item) => {
      console.log('ITEM', item)
      swal({
         title: "Are you sure you do not want to move ahead with the job?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      }).then((willDelete) => {
         if (willDelete) {
            setProspectDataLoading(true)
            props.moveForwardToProspect({ applyIdHash: item.userProfileData.applyData.applyIdHash, userIdHash: props.auth.userProfile.userIdHash, value: 0 }, res => {
               console.log('RES', res)
               setProspectDataLoading(false)
               if (res.status) {
                  columnClicked('prospect')
                  swal({
                     title: "You have cancelled / rejected the job successfully",
                     text: "",
                     icon: "success"
                  }).then(onUpdate => {
                     columnClicked('prospect')
                  })
               } else {
                  toast.error('Something went wrong')
                  setProspectDataLoading(false)
               }
            })
         }

      })

   }

   const acceptInterviewingFn = (item) => {
      console.log('ITEM', item)
      swal({
         title: "Are you sure you want to move forward?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      }).then((willDelete) => {
         if (willDelete) {
            setInterviewingDataLoading(true)
            props.moveForwardToInterviewing({ applyIdHash: item.userProfileData.applyData.applyIdHash, userIdHash: props.auth.userProfile.userIdHash, value: 1 }, res => {
               console.log('RES', res)
               setInterviewingDataLoading(false)
               if (res.status) {
                  columnClicked('interviewing')
                  swal({
                     title: "Congratulations!! You have moved one step closer to get hired for the job",
                     text: "",
                     icon: "success"
                  }).then(onUpdate => {
                     columnClicked('interviewing')
                  })
               } else {
                  toast.error('Something went wrong')
                  setInterviewingDataLoading(false)
               }
            })
         }

      })

   }

   const rejectInterviewingFn = (item) => {
      console.log('ITEM', item)
      swal({
         title: "Are you sure you do not want to move ahead with the job?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      }).then((willDelete) => {
         if (willDelete) {
            setInterviewingDataLoading(true)
            props.moveForwardToInterviewing({ applyIdHash: item.userProfileData.applyData.applyIdHash, userIdHash: props.auth.userProfile.userIdHash, value: 0 }, res => {
               console.log('RES', res)
               setInterviewingDataLoading(false)
               if (res.status) {
                  columnClicked('interviewing')
                  swal({
                     title: "You have cancelled / rejected the job successfully",
                     text: "",
                     icon: "success"
                  }).then(onUpdate => {
                     columnClicked('interviewing')
                  })
               } else {
                  toast.error('Something went wrong')
                  setInterviewingDataLoading(false)
               }
            })
         }

      })

   }

   const acceptHiredFn = (item) => {
      console.log('ITEM', item)
      swal({
         title: "Are you sure you want to accept this job?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      }).then((willDelete) => {
         if (willDelete) {
            setHiredDataLoading(true)
            props.moveForwardToHired({ applyIdHash: item.userProfileData.applyData.applyIdHash, userIdHash: props.auth.userProfile.userIdHash, value: 1 }, res => {
               console.log('RES', res)
               setHiredDataLoading(false)
               if (res.status) {
                  columnClicked('hired')
                  swal({
                     title: "Congratulations!! You have accepted the job",
                     text: "",
                     icon: "success"
                  }).then(onUpdate => {
                     columnClicked('hired')
                  })
               } else {
                  toast.error('Something went wrong')
                  setHiredDataLoading(false)
               }
            })
         }

      })

   }

   const rejectHiredFn = (item) => {
      console.log('ITEM', item)
      swal({
         title: "Are you sure you want to reject this job?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      }).then((willDelete) => {
         if (willDelete) {
            setHiredDataLoading(true)
            props.moveForwardToHired({ applyIdHash: item.userProfileData.applyData.applyIdHash, userIdHash: props.auth.userProfile.userIdHash, value: 0 }, res => {
               console.log('RES', res)
               setHiredDataLoading(false)
               if (res.status) {
                  columnClicked('hired')
                  swal({
                     title: "You have rejected the job successfully",
                     text: "",
                     icon: "success"
                  }).then(onUpdate => {
                     columnClicked('hired')
                  })
               } else {
                  toast.error('Something went wrong')
                  setHiredDataLoading(false)
               }
            })
         }

      })

   }

   const reapplyBacklogFn = (item) => {
      console.log('ITEM', item)
      swal({
         title: "Are you sure you want to re-apply?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      }).then((willDelete) => {
         if (willDelete) {
            setBacklogDataLoading(true)
            props.reapplyBacklogProfile({ applyIdHash: item.userProfileData.applyData.applyIdHash, userIdHash: props.auth.userProfile.userIdHash, value: 1 }, res => {
               console.log('RES', res)
               setBacklogDataLoading(false)
               if (res.status) {
                  columnClicked('backlog')
                  swal({
                     title: "Re-applied and moved to Open section",
                     text: "",
                     icon: "success"
                  }).then(onUpdate => {
                     columnClicked('open')
                  })
               } else {
                  toast.error('Something went wrong')
                  setBacklogDataLoading(false)
               }
            })
         }

      })

   }

   const columnClicked = (flag) => {
      if (flag == 'open') {
         setOpenFlag(true)
         setInterestedFlag(false)
         setProspectFlag(false)
         setInterviewingFlag(false)
         setHiredFlag(false)
         setBacklogFlag(false)
         setOpenDataLoading(true)
         if (entityTypeId == undefined || entityTypeId == '' || entityTypeId == null) {
            getOpenData()
         } else {
            getOpenDataWithEntityTypeId(entityTypeId)
         }
      } else if (flag == 'interested') {
         setOpenFlag(false)
         setInterestedFlag(true)
         setProspectFlag(false)
         setInterviewingFlag(false)
         setHiredFlag(false)
         setBacklogFlag(false)
         setInterestedDataLoading(true)
         if (entityTypeId == undefined || entityTypeId == '' || entityTypeId == null) {
            getInterestedData()
         } else {
            getInterestedDataWithEntityTypeId(entityTypeId)
         }
      } else if (flag == 'prospect') {
         setOpenFlag(false)
         setInterestedFlag(false)
         setProspectFlag(true)
         setInterviewingFlag(false)
         setHiredFlag(false)
         setBacklogFlag(false)
         setProspectDataLoading(true)
         if (entityTypeId == undefined || entityTypeId == '' || entityTypeId == null) {
            getProspectData()
         } else {
            getProspectDataWithEntityTypeId(entityTypeId)
         }
      } else if (flag == 'interviewing') {
         setOpenFlag(false)
         setInterestedFlag(false)
         setProspectFlag(false)
         setInterviewingFlag(true)
         setHiredFlag(false)
         setBacklogFlag(false)
         setInterviewingDataLoading(true)
         if (entityTypeId == undefined || entityTypeId == '' || entityTypeId == null) {
            getInterviewingData()
         } else {
            getInterviewingDataWithEntityTypeId(entityTypeId)
         }
      } else if (flag == 'hired') {
         setOpenFlag(false)
         setInterestedFlag(false)
         setProspectFlag(false)
         setInterviewingFlag(false)
         setHiredFlag(true)
         setBacklogFlag(false)
         setHiredDataLoading(true)
         if (entityTypeId == undefined || entityTypeId == '' || entityTypeId == null) {
            getHiredData()
         } else {
            getHiredDataWithEntityTypeId(entityTypeId)
         }
      } else if (flag == 'backlog') {
         setOpenFlag(false)
         setInterestedFlag(false)
         setProspectFlag(false)
         setInterviewingFlag(false)
         setHiredFlag(false)
         setBacklogFlag(true)
         setBacklogDataLoading(true)
         if (entityTypeId == undefined || entityTypeId == '' || entityTypeId == null) {
            getBacklogData()
         } else {
            getBacklogDataWithEntityTypeId(entityTypeId)
         }
      }
   }

   const openStyleActive = () => {
      return {
         border: '1px solid red',
         borderRadius: '5px',
         color: 'red'
      }
   }
   const openStyleInActive = () => {
      return {
         border: '1px solid gainsboro',
         borderRadius: '0px',
         color: 'black'
      }
   }
   const interestedStyleActive = () => {
      return {
         border: '1px solid orange',
         borderRadius: '5px',
         color: 'orange'
      }
   }
   const interestedStyleInActive = () => {
      return {
         border: '1px solid gainsboro',
         borderRadius: '0px',
         color: 'black'
      }
   }
   const prospectStyleActive = () => {
      return {
         border: '1px solid cornflowerblue',
         borderRadius: '5px',
         color: 'cornflowerblue'
      }
   }
   const prospectStyleInActive = () => {
      return {
         border: '1px solid gainsboro',
         borderRadius: '0px',
         color: 'black'
      }
   }
   const interviewingStyleActive = () => {
      return {
         border: '1px solid blue',
         borderRadius: '5px',
         color: 'blue'
      }
   }
   const interviewingStyleInActive = () => {
      return {
         border: '1px solid gainsboro',
         borderRadius: '0px',
         color: 'black'
      }
   }
   const hiredStyleActive = () => {
      return {
         border: '1px solid green',
         borderRadius: '5px',
         color: 'green'
      }
   }
   const hiredStyleInActive = () => {
      return {
         border: '1px solid gainsboro',
         borderRadius: '0px',
         color: 'black'
      }
   }
   const backlogStyleActive = () => {
      return {
         border: '1px solid black',
         borderRadius: '5px',
         color: 'black'
      }
   }
   const backlogStyleInActive = () => {
      return {
         border: '1px solid gainsboro',
         borderRadius: '0px',
         color: 'black'
      }
   }

   return (
      <>
         <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
            <div className="d-flex flex-column-fluid">
               <div className="custom-container custom_contr5">
                  <div className="Profile_form custom_profile4">
                     <div className="white_bf6">
                        <div className="new_whitr" style={{ background: 'none' }}>
                           <h4>Bookings</h4>
                           <div className="custpom_tabs my-3">
                              {tabsLoading ? (
                                 // <div className="nav nav-tabs">
                                 //    <span className="spinner-border spinner-border-sm"></span>
                                 // </div>
                                 <div className="row" style={{ width: '100%', height: '30vh' }}>
                                    <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                       <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '5vh' }}>
                                          <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                                       </div>
                                    </div>
                                 </div>
                              ) : (
                                 <>
                                    <div className="custpom_tabs" style={{ background: '#FFFFFF 0% 0% no-repeat padding-box', marginTop: '', paddingTop: '12px', paddingBottom: '2px', borderRadius: '10px', boxShadow: '0px 5px 6px #0000000d', opacity: '1' }}>
                                       <ul className="nav nav-tabs" style={{justifyContent:'start'}}>
                                          <li className="mb-2" onClick={() => {
                                             getHomeScreenList("");
                                             setFilterVisible(true);
                                             setActiveTab(0);
                                             setEntityTypeId();
                                             setOpenDataLoading(true)
                                             setInterestedDataLoading(true)
                                             setProspectDataLoading(true)
                                             setInterviewingDataLoading(true)
                                             setHiredDataLoading(true)
                                             setBacklogDataLoading(true)
                                             getOpenData()
                                             getInterestedData()
                                             getProspectData()
                                             getInterviewingData()
                                             getHiredData()
                                             getBacklogData()
                                          }}>
                                             <a href="#" className={activeTab === 0 ? "active" : ""}>All</a>
                                          </li>
                                          {tabs.length > 0
                                             ? tabs.map((item) => (
                                                <li className="mb-2" onClick={() => {
                                                   getHomeScreenList(item.userProfileData.selectedEntityTypeData.entityTypeId);
                                                   setFilterVisible(false);
                                                   setActiveTab(item.id);
                                                   setEntityTypeIdFun(item.userProfileData.selectedEntityTypeData.entityTypeId);
                                                   setOpenDataLoading(true)
                                                   setInterestedDataLoading(true)
                                                   setProspectDataLoading(true)
                                                   setInterviewingDataLoading(true)
                                                   setHiredDataLoading(true)
                                                   setBacklogDataLoading(true)
                                                   getOpenDataWithEntityTypeId(item.userProfileData.selectedEntityTypeData.entityTypeId)
                                                   getInterestedDataWithEntityTypeId(item.userProfileData.selectedEntityTypeData.entityTypeId)
                                                   getProspectDataWithEntityTypeId(item.userProfileData.selectedEntityTypeData.entityTypeId)
                                                   getInterviewingDataWithEntityTypeId(item.userProfileData.selectedEntityTypeData.entityTypeId)
                                                   getHiredDataWithEntityTypeId(item.userProfileData.selectedEntityTypeData.entityTypeId)
                                                   getBacklogDataWithEntityTypeId(item.userProfileData.selectedEntityTypeData.entityTypeId)
                                                }}>
                                                   <a style={{ fontSize: '14px' }} href="#" className={activeTab === item.id ? "active" : ""}>
                                                      {
                                                         item.userProfileData.selectedEntityTypeData
                                                            .profession.toUpperCase()
                                                      }
                                                   </a>
                                                </li>
                                             ))
                                             : null}
                                       </ul>
                                    </div>


                                    <div className="row no-gutters text-center" style={{ marginTop: '30px', marginBottom: '40px' }}>
                                       <div className="col jobInformation mr-2" style={openFlag ? openStyleActive() : openStyleInActive()} onClick={() => columnClicked('open')}>
                                          Open
                                       </div>
                                       <div className="col jobSetting mr-2" style={interestedFlag ? interestedStyleActive() : interestedStyleInActive()} onClick={() => columnClicked('interested')}>
                                          Interested
                                       </div>
                                       <div className="col weeklyCalendar mr-2" style={prospectFlag ? prospectStyleActive() : prospectStyleInActive()} onClick={() => columnClicked('prospect')}>
                                          Prospect
                                       </div>
                                       <div className="col dailyCalendar mr-2" style={interviewingFlag ? interviewingStyleActive() : interviewingStyleInActive()} onClick={() => columnClicked('interviewing')}>
                                          Interviewing
                                       </div>
                                       <div className="col aboutEmp mr-2" style={hiredFlag ? hiredStyleActive() : hiredStyleInActive()} onClick={() => columnClicked('hired')}>
                                          Hired
                                       </div>
                                       <div className="col aboutEmp" style={backlogFlag ? backlogStyleActive() : backlogStyleInActive()} onClick={() => columnClicked('backlog')}>
                                          Backlog
                                       </div>
                                    </div>

                                    <div className='card' style={{ height: '' }}>
                                       <div className='card-body row'>
                                          {openFlag &&
                                             (<>
                                                {openDataLoading ?
                                                   (<>
                                                      <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                         <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                               <div className="spinner-grow" role="status" style={{ background: 'red' }}></div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </>) :
                                                   (<>
                                                      {openData.length ?
                                                         (<>
                                                            {openData.map((item => {
                                                               return (
                                                                  <>
                                                                     <div className="col-md-4 col-lg-4 mb-5" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                                                        <div className="inner_docter custom_dt" style={{ border: '1px solid red' }}>
                                                                           <div className="row">
                                                                              <div className="col-lg-12 col-md-6">
                                                                                 <div className="job_detailsContainer">
                                                                                    {/* <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedEntityData.name ? item.userProfileData.selectedEntityData.name : "---"}{" "}
                                                                                       /{" "}
                                                                                       {item.userProfileData.selectedEntityData.title ? item.userProfileData.selectedEntityData.title : "---"}
                                                                                    </h3>
                                                                                    <p className="talent_id">
                                                                                       Job ID :{" "}
                                                                                       {item.userProfileData.selectedEntityData.id}
                                                                                    </p> */}
                                                                                    <p className='talent_id' style={{ color: 'green' }}>Candidate Profile : </p>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row align-items-center cust_spc" style={{ paddingTop: '0px' }}>
                                                                              <div className="col-md-9">
                                                                                 <div className="user_details">
                                                                                    <div className="circleName">
                                                                                       {item.userProfileData.selectedEntityTypeData.entityTypeNameAbbreviation}
                                                                                    </div>
                                                                                    <img
                                                                                       src={
                                                                                          item.isVerified ? verified : unVerified
                                                                                       }
                                                                                       alt="verify"
                                                                                       style={{
                                                                                          position: "absolute",
                                                                                          top: '35px', left: '48px'
                                                                                       }}
                                                                                    />
                                                                                    <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedUserData.firstName ? item.userProfileData.selectedUserData.firstName : "---"}
                                                                                       {" "}
                                                                                       {item.userProfileData.selectedUserData.lastName}
                                                                                    </h3>
                                                                                    <p className="custom_png" style={{ color: "deepskyblue", position: 'absolute', left: '72px', top: '35px' }}>#{item.userProfileData.selectedUserData.userNameHash} {item.userProfileData.selectedUserData.userName ? (<>/ @{item.userProfileData.selectedUserData.userName}</>) : (<></>)}</p>
                                                                                 </div>
                                                                              </div>
                                                                              <div className="col-md-3 text-right">
                                                                                 <img src={showBadgeForNullValueEmployee(item.userProfileData.selectedUserDataRating)} alt="badge" />
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-6">
                                                                                 <div className="date_container">
                                                                                    <img src={newCalender} alt="calender" />
                                                                                    <span className="date_ talent_id ml-2">
                                                                                       {getFormatedDate(
                                                                                          item.userProfileData.selectedEntityData
                                                                                             .createdDate,
                                                                                          "DD MMM YYYY, hh:mm A"
                                                                                       )}
                                                                                    </span>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-12">
                                                                                 <div className="info_container_">
                                                                                    <ul className="job_info_list_">
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Employment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.employmentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.employmentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.paymentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.paymentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payroll Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.payrollType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.payrollType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Schedule Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.scheduleType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.scheduleType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Facility Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.facilityType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.facilityType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Shift Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.shiftType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.shiftType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Location :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.city}, {item.state},{" "}
                                                                                             {item.country}
                                                                                          </p>
                                                                                       </li>
                                                                                    </ul>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           {/* <div className="open_job" >
                                                                              <h5>
                                                                                 <a href="javascript:void(0)" onClick={() => history.push({ pathname: "/candidate", state: item })}>
                                                                                    <img src={showImg} alt="show" />
                                                                                    <span className="ml-2">
                                                                                       View Candidate Details
                                                                                    </span>
                                                                                 </a>
                                                                              </h5>
                                                                           </div> */}
                                                                           <div className="button_list">
                                                                              <a
                                                                                 className="about_btn booking_module_btn_open"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px 0px 0px 0px", paddingTop: '22px' }}
                                                                                 onClick={() => history.push(`/jobDetails/${item.id}/${item.userProfileData.selectedEntityData.id}/${item.userProfileData.selectedEntityTypeData.entityTypeId}`, { fromDashboard: true })}
                                                                              >
                                                                                 Job Detail
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn_open"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px" }}
                                                                              // onClick={() =>
                                                                              //    setModal({
                                                                              //       modal: !modal.modal,
                                                                              //       type: "follow",
                                                                              //       data: item.userProfileData,
                                                                              //    })
                                                                              // }
                                                                              >
                                                                                 Accept
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn booking_module_btn_open"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ paddingTop: '22px' }}
                                                                              // onClick={() =>
                                                                              //    setModal({
                                                                              //       modal: !modal.modal,
                                                                              //       type: "invite",
                                                                              //       data: item.userProfileData,
                                                                              //    })
                                                                              // }
                                                                              >
                                                                                 Cancel
                                                                              </a>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </>
                                                               )
                                                            }))}
                                                         </>) :
                                                         (<>
                                                            <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                               <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                                  <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                                     <div className="" style={{ color: 'red' }}><b>No Data Found</b></div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </>)}
                                                   </>)}
                                             </>)}
                                          {interestedFlag &&
                                             (<>
                                                {interestedDataLoading ?
                                                   (<>
                                                      <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                         <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                               <div className="spinner-grow" role="status" style={{ background: 'orange' }}></div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </>) :
                                                   (<>
                                                      {interestedData.length ?
                                                         (<>
                                                            {interestedData.map((item => {
                                                               return (
                                                                  <>
                                                                     <div className="col-md-4 col-lg-4 mb-5" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                                                        <div className="inner_docter custom_dt" style={{ border: '1px solid orange' }}>
                                                                           <div className="row">
                                                                              <div className="col-lg-12 col-md-6">
                                                                                 <div className="job_detailsContainer">
                                                                                    {/* <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedEntityData.name ? item.userProfileData.selectedEntityData.name : "---"}{" "}
                                                                                       /{" "}
                                                                                       {item.userProfileData.selectedEntityData.title ? item.userProfileData.selectedEntityData.title : "---"}
                                                                                    </h3>
                                                                                    <p className="talent_id">
                                                                                       Job ID :{" "}
                                                                                       {item.userProfileData.selectedEntityData.id}
                                                                                    </p> */}
                                                                                    <p className='talent_id' style={{ color: 'green' }}>Candidate Profile : </p>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row align-items-center cust_spc" style={{ paddingTop: '0px' }}>
                                                                              <div className="col-md-9">
                                                                                 <div className="user_details">
                                                                                    <div className="circleName">
                                                                                       {item.userProfileData.selectedEntityTypeData.entityTypeNameAbbreviation}
                                                                                    </div>
                                                                                    <img
                                                                                       src={
                                                                                          item.isVerified ? verified : unVerified
                                                                                       }
                                                                                       alt="verify"
                                                                                       style={{
                                                                                          position: "absolute",
                                                                                          top: '35px', left: '48px'
                                                                                       }}
                                                                                    />
                                                                                    <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedUserData.firstName ? item.userProfileData.selectedUserData.firstName : "---"}
                                                                                       {" "}
                                                                                       {item.userProfileData.selectedUserData.lastName}
                                                                                    </h3>
                                                                                    <p className="custom_png" style={{ color: "deepskyblue", position: 'absolute', left: '72px', top: '35px' }}>#{item.userProfileData.selectedUserData.userNameHash} {item.userProfileData.selectedUserData.userName ? (<>/ @{item.userProfileData.selectedUserData.userName}</>) : (<></>)}</p>
                                                                                 </div>
                                                                              </div>
                                                                              <div className="col-md-3 text-right">
                                                                                 <img src={showBadgeForNullValueEmployee(item.userProfileData.selectedUserDataRating)} alt="badge" />
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-6">
                                                                                 <div className="date_container">
                                                                                    <img src={newCalender} alt="calender" />
                                                                                    <span className="date_ talent_id ml-2">
                                                                                       {getFormatedDate(
                                                                                          item.userProfileData.selectedEntityData
                                                                                             .createdDate,
                                                                                          "DD MMM YYYY, hh:mm A"
                                                                                       )}
                                                                                    </span>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-12">
                                                                                 <div className="info_container_">
                                                                                    <ul className="job_info_list_">
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Employment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.employmentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.employmentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.paymentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.paymentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payroll Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.payrollType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.payrollType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Schedule Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.scheduleType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.scheduleType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Facility Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.facilityType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.facilityType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Shift Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.shiftType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.shiftType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Location :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.city}, {item.state},{" "}
                                                                                             {item.country}
                                                                                          </p>
                                                                                       </li>
                                                                                    </ul>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           {/* <div className="open_job" >
                                                                              <h5>
                                                                                 <a href="javascript:void(0)" onClick={() => history.push({ pathname: "/candidate", state: item })}>
                                                                                    <img src={showImg} alt="show" />
                                                                                    <span className="ml-2">
                                                                                       View Candidate Details
                                                                                    </span>
                                                                                 </a>
                                                                              </h5>
                                                                           </div> */}
                                                                           <div className="button_list">
                                                                              <a
                                                                                 className="about_btn booking_module_btn_interested"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px 0px 0px 0px", paddingTop: '22px' }}
                                                                                 onClick={() => history.push(`/jobDetails/${item.id}/${item.userProfileData.selectedEntityData.id}/${item.userProfileData.selectedEntityTypeData.entityTypeId}`, { fromDashboard: true })}
                                                                              >
                                                                                 Job Detail
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn_interested"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px" }}
                                                                                 onClick={() =>
                                                                                    acceptInterestedFn(item)
                                                                                 }
                                                                              >
                                                                                 Accept
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn booking_module_btn_interested"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ paddingTop: '22px' }}
                                                                                 onClick={() =>
                                                                                    rejectInterestedFn(item)
                                                                                 }
                                                                              >
                                                                                 Cancel
                                                                              </a>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </>
                                                               )
                                                            }))}
                                                         </>) :
                                                         (<>
                                                            <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                               <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                                  <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                                     <div className="" style={{ color: 'orange' }}><b>No Data Found</b></div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </>)}
                                                   </>)}
                                             </>)}
                                          {prospectFlag &&
                                             (<>
                                                {prospectDataLoading ?
                                                   (<>
                                                      <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                         <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                               <div className="spinner-grow" role="status" style={{ background: 'cornflowerblue' }}></div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </>) :
                                                   (<>
                                                      {prospectData.length ?
                                                         (<>
                                                            {prospectData.map((item => {
                                                               return (
                                                                  <>
                                                                     <div className="col-md-4 col-lg-4 mb-5" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                                                        <div className="inner_docter custom_dt" style={{ border: '1px solid cornflowerblue' }}>
                                                                           <div className="row">
                                                                              <div className="col-lg-12 col-md-6">
                                                                                 <div className="job_detailsContainer">
                                                                                    {/* <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedEntityData.name ? item.userProfileData.selectedEntityData.name : "---"}{" "}
                                                                                       /{" "}
                                                                                       {item.userProfileData.selectedEntityData.title ? item.userProfileData.selectedEntityData.title : "---"}
                                                                                    </h3>
                                                                                    <p className="talent_id">
                                                                                       Job ID :{" "}
                                                                                       {item.userProfileData.selectedEntityData.id}
                                                                                    </p> */}
                                                                                    <p className='talent_id' style={{ color: 'green' }}>Candidate Profile : </p>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row align-items-center cust_spc" style={{ paddingTop: '0px' }}>
                                                                              <div className="col-md-9">
                                                                                 <div className="user_details">
                                                                                    <div className="circleName">
                                                                                       {item.userProfileData.selectedEntityTypeData.entityTypeNameAbbreviation}
                                                                                    </div>
                                                                                    <img
                                                                                       src={
                                                                                          item.isVerified ? verified : unVerified
                                                                                       }
                                                                                       alt="verify"
                                                                                       style={{
                                                                                          position: "absolute",
                                                                                          top: '35px', left: '48px'
                                                                                       }}
                                                                                    />
                                                                                    <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedUserData.firstName ? item.userProfileData.selectedUserData.firstName : "---"}
                                                                                       {" "}
                                                                                       {item.userProfileData.selectedUserData.lastName}
                                                                                    </h3>
                                                                                    <p className="custom_png" style={{ color: "deepskyblue", position: 'absolute', left: '72px', top: '35px' }}>#{item.userProfileData.selectedUserData.userNameHash} {item.userProfileData.selectedUserData.userName ? (<>/ @{item.userProfileData.selectedUserData.userName}</>) : (<></>)}</p>
                                                                                 </div>
                                                                              </div>
                                                                              <div className="col-md-3 text-right">
                                                                                 <img src={showBadgeForNullValueEmployee(item.userProfileData.selectedUserDataRating)} alt="badge" />
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-6">
                                                                                 <div className="date_container">
                                                                                    <img src={newCalender} alt="calender" />
                                                                                    <span className="date_ talent_id ml-2">
                                                                                       {getFormatedDate(
                                                                                          item.userProfileData.selectedEntityData
                                                                                             .createdDate,
                                                                                          "DD MMM YYYY, hh:mm A"
                                                                                       )}
                                                                                    </span>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-12">
                                                                                 <div className="info_container_">
                                                                                    <ul className="job_info_list_">
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Employment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.employmentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.employmentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.paymentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.paymentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payroll Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.payrollType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.payrollType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Schedule Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.scheduleType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.scheduleType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Facility Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.facilityType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.facilityType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Shift Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.shiftType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.shiftType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Location :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.city}, {item.state},{" "}
                                                                                             {item.country}
                                                                                          </p>
                                                                                       </li>
                                                                                    </ul>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           {/* <div className="open_job" >
                                                                              <h5>
                                                                                 <a href="javascript:void(0)" onClick={() => history.push({ pathname: "/candidate", state: item })}>
                                                                                    <img src={showImg} alt="show" />
                                                                                    <span className="ml-2">
                                                                                       View Candidate Details
                                                                                    </span>
                                                                                 </a>
                                                                              </h5>
                                                                           </div> */}
                                                                           <div className="button_list">
                                                                              <a
                                                                                 className="about_btn booking_module_btn_prospect"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px 0px 0px 0px", paddingTop: '22px' }}
                                                                                 onClick={() => history.push(`/jobDetails/${item.id}/${item.userProfileData.selectedEntityData.id}/${item.userProfileData.selectedEntityTypeData.entityTypeId}`, { fromDashboard: true })}
                                                                              >
                                                                                 Job Detail
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn_prospect"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px" }}
                                                                                 onClick={() =>
                                                                                    acceptProspectFn(item)
                                                                                 }
                                                                              >
                                                                                 Accept
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn booking_module_btn_prospect"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ paddingTop: '22px' }}
                                                                                 onClick={() =>
                                                                                    rejectProspectFn(item)
                                                                                 }
                                                                              >
                                                                                 Cancel
                                                                              </a>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </>
                                                               )
                                                            }))}
                                                         </>) :
                                                         (<>
                                                            <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                               <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                                  <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                                     <div className="" style={{ color: 'cornflowerblue' }}><b>No Data Found</b></div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </>)}
                                                   </>)}
                                             </>)}
                                          {interviewingFlag &&
                                             (<>
                                                {interviewingDataLoading ?
                                                   (<>
                                                      <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                         <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                               <div className="spinner-grow" role="status" style={{ background: 'blue' }}></div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </>) :
                                                   (<>
                                                      {interviewingData.length ?
                                                         (<>
                                                            {interviewingData.map((item => {
                                                               return (
                                                                  <>
                                                                     <div className="col-md-4 col-lg-4 mb-5" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                                                        <div className="inner_docter custom_dt" style={{ border: '1px solid blue' }}>
                                                                           <div className="row">
                                                                              <div className="col-lg-12 col-md-6">
                                                                                 <div className="job_detailsContainer">
                                                                                    {/* <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedEntityData.name ? item.userProfileData.selectedEntityData.name : "---"}{" "}
                                                                                       /{" "}
                                                                                       {item.userProfileData.selectedEntityData.title ? item.userProfileData.selectedEntityData.title : "---"}
                                                                                    </h3>
                                                                                    <p className="talent_id">
                                                                                       Job ID :{" "}
                                                                                       {item.userProfileData.selectedEntityData.id}
                                                                                    </p> */}
                                                                                    <p className='talent_id' style={{ color: 'green' }}>Candidate Profile : </p>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row align-items-center cust_spc" style={{ paddingTop: '0px' }}>
                                                                              <div className="col-md-9">
                                                                                 <div className="user_details">
                                                                                    <div className="circleName">
                                                                                       {item.userProfileData.selectedEntityTypeData.entityTypeNameAbbreviation}
                                                                                    </div>
                                                                                    <img
                                                                                       src={
                                                                                          item.isVerified ? verified : unVerified
                                                                                       }
                                                                                       alt="verify"
                                                                                       style={{
                                                                                          position: "absolute",
                                                                                          top: '35px', left: '48px'
                                                                                       }}
                                                                                    />
                                                                                    <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedUserData.firstName ? item.userProfileData.selectedUserData.firstName : "---"}
                                                                                       {" "}
                                                                                       {item.userProfileData.selectedUserData.lastName}
                                                                                    </h3>
                                                                                    <p className="custom_png" style={{ color: "deepskyblue", position: 'absolute', left: '72px', top: '35px' }}>#{item.userProfileData.selectedUserData.userNameHash} {item.userProfileData.selectedUserData.userName ? (<>/ @{item.userProfileData.selectedUserData.userName}</>) : (<></>)}</p>
                                                                                 </div>
                                                                              </div>
                                                                              <div className="col-md-3 text-right">
                                                                                 <img src={showBadgeForNullValueEmployee(item.userProfileData.selectedUserDataRating)} alt="badge" />
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-6">
                                                                                 <div className="date_container">
                                                                                    <img src={newCalender} alt="calender" />
                                                                                    <span className="date_ talent_id ml-2">
                                                                                       {getFormatedDate(
                                                                                          item.userProfileData.selectedEntityData
                                                                                             .createdDate,
                                                                                          "DD MMM YYYY, hh:mm A"
                                                                                       )}
                                                                                    </span>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-12">
                                                                                 <div className="info_container_">
                                                                                    <ul className="job_info_list_">
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Employment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.employmentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.employmentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.paymentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.paymentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payroll Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.payrollType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.payrollType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Schedule Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.scheduleType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.scheduleType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Facility Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.facilityType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.facilityType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Shift Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.shiftType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.shiftType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Location :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.city}, {item.state},{" "}
                                                                                             {item.country}
                                                                                          </p>
                                                                                       </li>
                                                                                    </ul>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           {/* <div className="open_job" >
                                                                              <h5>
                                                                                 <a href="javascript:void(0)" onClick={() => history.push({ pathname: "/candidate", state: item })}>
                                                                                    <img src={showImg} alt="show" />
                                                                                    <span className="ml-2">
                                                                                       View Candidate Details
                                                                                    </span>
                                                                                 </a>
                                                                              </h5>
                                                                           </div> */}
                                                                           <div className="button_list">
                                                                              <a
                                                                                 className="about_btn booking_module_btn_interviewing"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px 0px 0px 0px", paddingTop: '22px' }}
                                                                                 onClick={() => history.push(`/jobDetails/${item.id}/${item.userProfileData.selectedEntityData.id}/${item.userProfileData.selectedEntityTypeData.entityTypeId}`, { fromDashboard: true })}
                                                                              >
                                                                                 Job Detail
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn_interviewing"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px" }}
                                                                                 onClick={() =>
                                                                                    acceptInterviewingFn(item)
                                                                                 }
                                                                              >
                                                                                 Accept
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn booking_module_btn_interviewing"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ paddingTop: '22px' }}
                                                                                 onClick={() =>
                                                                                    rejectInterviewingFn(item)
                                                                                 }
                                                                              >
                                                                                 Cancel
                                                                              </a>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </>
                                                               )
                                                            }))}
                                                         </>) :
                                                         (<>
                                                            <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                               <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                                  <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                                     <div className="" style={{ color: 'blue' }}><b>No Data Found</b></div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </>)}
                                                   </>)}
                                             </>)}
                                          {hiredFlag &&
                                             (<>
                                                {hiredDataLoading ?
                                                   (<>
                                                      <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                         <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                               <div className="spinner-grow" role="status" style={{ background: 'green' }}></div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </>) :
                                                   (<>
                                                      {hiredData.length ?
                                                         (<>
                                                            {hiredData.map((item => {
                                                               return (
                                                                  <>
                                                                     <div className="col-md-4 col-lg-4 mb-5" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                                                        <div className="inner_docter custom_dt" style={{ border: '1px solid green' }}>
                                                                           <div className="row">
                                                                              <div className="col-lg-12 col-md-6">
                                                                                 <div className="job_detailsContainer">
                                                                                    {/* <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedEntityData.name ? item.userProfileData.selectedEntityData.name : "---"}{" "}
                                                                                       /{" "}
                                                                                       {item.userProfileData.selectedEntityData.title ? item.userProfileData.selectedEntityData.title : "---"}
                                                                                    </h3>
                                                                                    <p className="talent_id">
                                                                                       Job ID :{" "}
                                                                                       {item.userProfileData.selectedEntityData.id}
                                                                                    </p> */}
                                                                                    <p className='talent_id' style={{ color: 'green' }}>Candidate Profile : </p>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row align-items-center cust_spc" style={{ paddingTop: '0px' }}>
                                                                              <div className="col-md-9">
                                                                                 <div className="user_details">
                                                                                    <div className="circleName">
                                                                                       {item.userProfileData.selectedEntityTypeData.entityTypeNameAbbreviation}
                                                                                    </div>
                                                                                    <img
                                                                                       src={
                                                                                          item.isVerified ? verified : unVerified
                                                                                       }
                                                                                       alt="verify"
                                                                                       style={{
                                                                                          position: "absolute",
                                                                                          top: '35px', left: '48px'
                                                                                       }}
                                                                                    />
                                                                                    <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedUserData.firstName ? item.userProfileData.selectedUserData.firstName : "---"}
                                                                                       {" "}
                                                                                       {item.userProfileData.selectedUserData.lastName}
                                                                                    </h3>
                                                                                    <p className="custom_png" style={{ color: "deepskyblue", position: 'absolute', left: '72px', top: '35px' }}>#{item.userProfileData.selectedUserData.userNameHash} {item.userProfileData.selectedUserData.userName ? (<>/ @{item.userProfileData.selectedUserData.userName}</>) : (<></>)}</p>
                                                                                 </div>
                                                                              </div>
                                                                              <div className="col-md-3 text-right">
                                                                                 <img src={showBadgeForNullValueEmployee(item.userProfileData.selectedUserDataRating)} alt="badge" />
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-6">
                                                                                 <div className="date_container">
                                                                                    <img src={newCalender} alt="calender" />
                                                                                    <span className="date_ talent_id ml-2">
                                                                                       {getFormatedDate(
                                                                                          item.userProfileData.selectedEntityData
                                                                                             .createdDate,
                                                                                          "DD MMM YYYY, hh:mm A"
                                                                                       )}
                                                                                    </span>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-12">
                                                                                 <div className="info_container_">
                                                                                    <ul className="job_info_list_">
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Employment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.employmentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.employmentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.paymentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.paymentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payroll Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.payrollType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.payrollType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Schedule Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.scheduleType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.scheduleType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Facility Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.facilityType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.facilityType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Shift Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.shiftType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.shiftType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Location :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.city}, {item.state},{" "}
                                                                                             {item.country}
                                                                                          </p>
                                                                                       </li>
                                                                                    </ul>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           {/* <div className="open_job" >
                                                                              <h5>
                                                                                 <a href="javascript:void(0)" onClick={() => history.push({ pathname: "/candidate", state: item })}>
                                                                                    <img src={showImg} alt="show" />
                                                                                    <span className="ml-2">
                                                                                       View Candidate Details
                                                                                    </span>
                                                                                 </a>
                                                                              </h5>
                                                                           </div> */}
                                                                           <div className="button_list">
                                                                              <a
                                                                                 className="about_btn booking_module_btn_hired"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px 0px 0px 0px", paddingTop: '22px' }}
                                                                                 onClick={() => history.push(`/jobDetails/${item.id}/${item.userProfileData.selectedEntityData.id}/${item.userProfileData.selectedEntityTypeData.entityTypeId}`, { fromDashboard: true })}
                                                                              >
                                                                                 Job Detail
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn_hired"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px" }}
                                                                                 onClick={() =>
                                                                                    acceptHiredFn(item)
                                                                                 }
                                                                              >
                                                                                 Accept
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn booking_module_btn_hired"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ paddingTop: '22px' }}
                                                                                 onClick={() =>
                                                                                    rejectHiredFn(item)
                                                                                 }
                                                                              >
                                                                                 Cancel
                                                                              </a>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </>
                                                               )
                                                            }))}
                                                         </>) :
                                                         (<>
                                                            <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                               <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                                  <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                                     <div className="" style={{ color: 'green' }}><b>No Data Found</b></div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </>)}
                                                   </>)}
                                             </>)}
                                          {backlogFlag &&
                                             (<>
                                                {backlogDataLoading ?
                                                   (<>
                                                      <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                         <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                               <div className="spinner-grow" role="status" style={{ background: 'black' }}></div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </>) :
                                                   (<>
                                                      {backlogData.length ?
                                                         (<>
                                                            {backlogData.map((item => {
                                                               return (
                                                                  <>
                                                                     <div className="col-md-4 col-lg-4 mb-5" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                                                        <div className="inner_docter custom_dt" style={{ border: '1px solid black' }}>
                                                                           <div className="row">
                                                                              <div className="col-lg-12 col-md-6">
                                                                                 <div className="job_detailsContainer">
                                                                                    {/* <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedEntityData.name ? item.userProfileData.selectedEntityData.name : "---"}{" "}
                                                                                       /{" "}
                                                                                       {item.userProfileData.selectedEntityData.title ? item.userProfileData.selectedEntityData.title : "---"}
                                                                                    </h3>
                                                                                    <p className="talent_id">
                                                                                       Job ID :{" "}
                                                                                       {item.userProfileData.selectedEntityData.id}
                                                                                    </p> */}
                                                                                    <p className='talent_id' style={{ color: 'green' }}>Candidate Profile : </p>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row align-items-center cust_spc" style={{ paddingTop: '0px' }}>
                                                                              <div className="col-md-9">
                                                                                 <div className="user_details">
                                                                                    <div className="circleName">
                                                                                       {item.userProfileData.selectedEntityTypeData.entityTypeNameAbbreviation}
                                                                                    </div>
                                                                                    <img
                                                                                       src={
                                                                                          item.isVerified ? verified : unVerified
                                                                                       }
                                                                                       alt="verify"
                                                                                       style={{
                                                                                          position: "absolute",
                                                                                          top: '35px', left: '48px'
                                                                                       }}
                                                                                    />
                                                                                    <h3 className="talent_user_name">
                                                                                       {item.userProfileData.selectedUserData.firstName ? item.userProfileData.selectedUserData.firstName : "---"}
                                                                                       {" "}
                                                                                       {item.userProfileData.selectedUserData.lastName}
                                                                                    </h3>
                                                                                    <p className="custom_png" style={{ color: "deepskyblue", position: 'absolute', left: '72px', top: '35px' }}>#{item.userProfileData.selectedUserData.userNameHash} {item.userProfileData.selectedUserData.userName ? (<>/ @{item.userProfileData.selectedUserData.userName}</>) : (<></>)}</p>
                                                                                 </div>
                                                                              </div>
                                                                              <div className="col-md-3 text-right">
                                                                                 <img src={showBadgeForNullValueEmployee(item.userProfileData.selectedUserDataRating)} alt="badge" />
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-6">
                                                                                 <div className="date_container">
                                                                                    <img src={newCalender} alt="calender" />
                                                                                    <span className="date_ talent_id ml-2">
                                                                                       {getFormatedDate(
                                                                                          item.userProfileData.selectedEntityData
                                                                                             .createdDate,
                                                                                          "DD MMM YYYY, hh:mm A"
                                                                                       )}
                                                                                    </span>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           <div className="row cust_spc pt-0">
                                                                              <div className="col-sm-12 col-lg-12">
                                                                                 <div className="info_container_">
                                                                                    <ul className="job_info_list_">
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Employment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.employmentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.employmentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payment Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.paymentType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.paymentType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Payroll Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.payrollType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.payrollType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Schedule Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.scheduleType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.scheduleType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Facility Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.facilityType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.facilityType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Shift Type :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.userProfileData.shiftType
                                                                                                ?.length
                                                                                                ? Array.from(item.userProfileData.shiftType, x => x.name).join(", ")
                                                                                                : "N/A"}
                                                                                          </p>
                                                                                       </li>
                                                                                       <li className="mb-3">
                                                                                          <span className="job_infoHeading_ me-25">
                                                                                             Location :
                                                                                          </span>
                                                                                          <p className="details_value_">
                                                                                             {" "}
                                                                                             {item.city}, {item.state},{" "}
                                                                                             {item.country}
                                                                                          </p>
                                                                                       </li>
                                                                                    </ul>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                           {/* <div className="open_job" >
                                                                              <h5>
                                                                                 <a href="javascript:void(0)" onClick={() => history.push({ pathname: "/candidate", state: item })}>
                                                                                    <img src={showImg} alt="show" />
                                                                                    <span className="ml-2">
                                                                                       View Candidate Details
                                                                                    </span>
                                                                                 </a>
                                                                              </h5>
                                                                           </div> */}
                                                                           <div className="button_list">
                                                                              <a
                                                                                 className="about_btn booking_module_btn_backlog"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px 0px 0px 0px", paddingTop: '22px' }}
                                                                                 onClick={() => history.push(`/jobDetails/${item.id}/${item.userProfileData.selectedEntityData.id}/${item.userProfileData.selectedEntityTypeData.entityTypeId}`, { fromDashboard: true })}
                                                                              >
                                                                                 Job Detail
                                                                              </a>
                                                                              <a
                                                                                 className="about_btn_backlog"
                                                                                 href="javascript:void(0)"
                                                                                 style={{ borderRadius: "0px" }}
                                                                                 onClick={() =>
                                                                                    reapplyBacklogFn(item)
                                                                                 }
                                                                              >
                                                                                 Re-Apply
                                                                              </a>
                                                                              {/* <a
                                                                                 className="about_btn booking_module_btn_backlog"
                                                                                 href="javascript:void(0)"
                                                               
                                                                              >
                                                                                 Cancel
                                                                              </a> */}
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </>
                                                               )
                                                            }))}
                                                         </>) :
                                                         (<>
                                                            <div className="row" style={{ width: '100%', height: '50vh' }}>
                                                               <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                                  <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                                     <div className="" style={{ color: 'black' }}><b>No Data Found</b></div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </>)}
                                                   </>)}
                                             </>)}
                                       </div>
                                    </div>

                                 </>

                              )}
                           </div>


                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )

}

const mapStateToProps = state => ({
   auth: state.auth
});
const mapDispatchToProps = dispatch => ({
   getHomeScreenTabs: bindActionCreators(getHomeScreenTabs, dispatch),
   getHomeScreenList: bindActionCreators(getHomeScreenList, dispatch),
   getOpenStateData: bindActionCreators(getOpenStateData, dispatch),
   getOpenStateDataWithEntityTypeId: bindActionCreators(getOpenStateDataWithEntityTypeId, dispatch),
   getInterestedStateData: bindActionCreators(getInterestedStateData, dispatch),
   getInterestedStateDataWithEntityTypeId: bindActionCreators(getInterestedStateDataWithEntityTypeId, dispatch),
   getProspectStateData: bindActionCreators(getProspectStateData, dispatch),
   getProspectStateDataWithEntityTypeId: bindActionCreators(getProspectStateDataWithEntityTypeId, dispatch),
   getInterviewingStateData: bindActionCreators(getInterviewingStateData, dispatch),
   getInterviewingStateDataWithEntityTypeId: bindActionCreators(getInterviewingStateDataWithEntityTypeId, dispatch),
   getHiredStateData: bindActionCreators(getHiredStateData, dispatch),
   getHiredStateDataWithEntityTypeId: bindActionCreators(getHiredStateDataWithEntityTypeId, dispatch),
   getBacklogStateData: bindActionCreators(getBacklogStateData, dispatch),
   getBacklogStateDataWithEntityTypeId: bindActionCreators(getBacklogStateDataWithEntityTypeId, dispatch),
   moveForwardToInterested: bindActionCreators(moveForwardToInterested, dispatch),
   moveForwardToProspect: bindActionCreators(moveForwardToProspect, dispatch),
   moveForwardToInterviewing: bindActionCreators(moveForwardToInterviewing, dispatch),
   moveForwardToHired: bindActionCreators(moveForwardToHired, dispatch),
   reapplyBacklogProfile: bindActionCreators(reapplyBacklogProfile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingEmployee);