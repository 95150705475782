import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";

const FindTalent = (props) => {
  const history = useHistory();

  const [employerFlag, setEmployerFlag] = useState(true);
  const [employeeFlag, setEmployeeFlag] = useState(false);

  useEffect(() => {
    setEmployerFlag(true);
    setEmployeeFlag(false);
  }, []);

  const employerActiveStyle = () => {
    return {
      padding: "20px",
      textAlign: "center",
      borderRadius: "35px",
      fontSize: "20px",
      fontWeight: "600",
      background: "#006838",
      color: "white",
      cursor: "pointer",
    };
  };
  const employerInActiveStyle = () => {
    return {
      padding: "20px",
      textAlign: "center",
      borderRadius: "35px",
      fontSize: "20px",
      fontWeight: "600",
      background: "#EBEBEB",
      color: "black",
      cursor: "pointer",
    };
  };
  const employeeActiveStyle = () => {
    return {
      padding: "20px",
      textAlign: "center",
      borderRadius: "35px",
      fontSize: "20px",
      fontWeight: "600",
      background: "#006838",
      color: "white",
      cursor: "pointer",
    };
  };
  const employeeInActiveStyle = () => {
    return {
      padding: "20px",
      textAlign: "center",
      borderRadius: "35px",
      fontSize: "20px",
      fontWeight: "600",
      background: "#EBEBEB",
      color: "black",
      cursor: "pointer",
    };
  };

  const changeSelection = (flag) => {
    if (flag === "employer") {
      setEmployerFlag(true);
      setEmployeeFlag(false);
    } else if (flag === "employee") {
      setEmployerFlag(false);
      setEmployeeFlag(true);
    }
  };

  return (
    <>
      <section className="find_talent p-60 aos-item" data-aos={"fade-up"}>
        <div className="container">
          <div className="find_talent_inner">
            <div className="home_tab" id="tab-content">
              {/* <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#home">
                  For Employers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#menu1">
                  For Employees
                </a>
              </li>
            </ul> */}

              <div
                className="row"
                style={{
                  borderRadius: "35px",
                  background: "#EBEBEB",
                  marginBottom: "50px",
                }}
              >
                <div className="col-6 p-0">
                  <div
                    style={
                      employerFlag
                        ? employerActiveStyle()
                        : employerInActiveStyle()
                    }
                    onClick={() => {
                      changeSelection("employer");
                    }}
                  >
                    For Employers
                  </div>
                </div>
                <div className="col-6 p-0">
                  <div
                    style={
                      employeeFlag
                        ? employeeActiveStyle()
                        : employeeInActiveStyle()
                    }
                    onClick={() => {
                      changeSelection("employee");
                    }}
                  >
                    For Employees
                  </div>
                </div>
              </div>

              <div className="tab-content">
                {employerFlag ? (
                  <>
                    <div
                      id=""
                      className="container active"
                    >
                      <div className="home_main_title">
                        <p>Find or Hire Employees your way Ondemand </p>
                        <div className="telent_heading">
                          <p>
                            {" "}
                            Develop Trusted relationship and build your own
                            virtual bend for quick project turnaround or big
                            transformation.
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 ">
                          <div className="service" style={{ background: 'white' }}>
                            <h3 style={{ paddingBottom: "35px" }}>
                              Post A job and Hire Local Professional
                            </h3>
                            <p>Project catalog</p>
                            <span className="find_talentlink">
                              <a href="#">
                                <i
                                  className="fa fa-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="service" style={{ background: 'white' }}>
                            <h3>
                              Hire Prescreened Local Service Providers Instantly
                            </h3>
                            <p>Talent Scout</p>
                            <span className="find_talentlink">
                              <a href="#">
                                <i
                                  className="fa fa-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="service" style={{ background: 'white' }}>
                            <h3>Ondemand Timely Shift Hire Scout</h3>
                            <p>Talent Marketplace</p>
                            <span className="find_talentlink">
                              <a href="#">
                                <i
                                  className="fa fa-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div id="" className="container">
                      <div className="home_main_title">
                        <h2>For Service Providers</h2>
                        <p>Apply for Local Jobs or Get Hired Ondemand</p>
                        <div className="telent_heading">
                          <p>
                            {" "}
                            Develop Trusted relationship and build your own
                            virtual bend for quick project turnaround or big
                            transformation.
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="service" style={{ background: 'white' }}>
                            <h3 style={{ paddingBottom: "35px" }}>
                              Apply for Local Jobs
                            </h3>
                            <p>Talent Marketplace</p>
                            <span className="find_talentlink">
                              <a href="#">
                                <i
                                  className="fa fa-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="service" style={{ background: 'white' }}>
                            <h3>
                              Get Hired and start working Instantly onDemand
                            </h3>
                            <p>Talent catalog</p>
                            <span className="find_talentlink">
                              <a href="#">
                                <i
                                  className="fa fa-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="service" style={{ background: 'white' }}>
                            <h3>Work the Way you want</h3>
                            <p>Talent catalog</p>
                            <span className="find_talentlink">
                              <a href="#">
                                <i
                                  className="fa fa-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FindTalent);
