import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import left_small from '../../public/images/left_small.png';
import master_card from '../../public/images/master_card.png';
import card5 from '../../public/images/card5.png';
import setting5 from '../../public/images/setting5.png';


const ManagePayment = (props) => {
    const history = useHistory();

    return (
        <>
          <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
                  
                  <div className="d-flex flex-column-fluid">
                     
                     <div className="custom-container">
                        
                        <div className="row  pb-5">
                           <div className="col-lg-6 col-md-6">
                              <div className="inner_txecy job_list">
                                 <h4>Settings </h4>
                                 <p><span><img src={left_small} /></span>Change Payment Method</p>
                              </div>
                           </div>
                           <div className="col-lg-6 col-md-6">
                              <div className="talen_outre">
                                 <button className="btn" data-toggle="modal" data-target="#myModal3">+ Add New Card</button>
                                 
                                 <div className="modal fade" id="myModal3">
                                    <div className="modal-dialog modal-dialog-centered">
                                       <div className="modal-content">
                                          
                                          <div className="modal-header custom_heder4">
                                             <button type="button" className="close custom_close" data-dismiss="modal">&times;</button>
                                             <h4 className="modal-title">Add New Card</h4>
                                          </div>
                                          
                                          <div className="modal-body new_crd5">
                                             <div className="email_outer">
                                                <input type="text" placeholder="Name On Card" />
                                             </div>
                                             <div className="email_outer">
                                                <input type="text" placeholder="Card Name" />
                                             </div>
                                             <div className="email_outer">
                                                <input type="text" placeholder="Card Number" />
                                             </div>
                                             <div className="row">
                                                <div className="col-lg-6 col-md-12">
                                                   <div className="email_outer">
                                                      <input type="text" placeholder="Enter First Name" />
                                                   </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                   <div className="email_outer">
                                                      <input type="text" placeholder="Enter Last Name" />
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="list_btn save_card3">
                                                <ul>
                                                   <li><button className="btn btn_cancek dark_btn">Save Card</button></li>
                                                </ul>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                        <div className="Profile_form">
                           
                           <div className="row">
                              <div className="col-md-5">
                                 <div className="card_outer4">
                                    <img src={card5} />
                                 </div>
                              </div>
                              <div className="col-md-5">
                                 <div className="img_inner587">
                                    <img className="seetinf6" src={setting5} data-toggle="collapse" />
                                    <div className="custom_collapse">
                                       <ul>
                                          <li><a href="">Set as deafult card</a></li>
                                          <li className="custom_red"><a href="">Delete card</a></li>
                                       </ul>
                                    </div>
                                    <div className="smal_list5"><img src={master_card} /></div>
                                 </div>
                              </div>
                              
                           </div>
                           
                        </div>
                        
                     </div>
                  </div>
                  
               </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagePayment);