import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import talent_edit from "../../public/images/talent_edit.png";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import locationImg from "../../public/images/location.png";
import showImg from "../../public/images/Show.png";
import { bindActionCreators } from "redux";
import { getUserProfileList } from "../../actions/common";
import { Button, Form, Modal } from "react-bootstrap";
import "../../public/css/providerStyles.css";
import {
  addSkillBio,
  editSkillBio,
  getFormFieldsEntity,
  getFormFieldsPrespective,
  getSkillBio,
  getFileMimeType,
  getResumeCred,
  uploadResumeCred,
  deleteResumeCred
} from "../../actions/employee/skill";
import inputFieldGenerator from "./fieldGenerator";
import { toast } from "react-toastify";
import { createTalent, getEmployment, getFacility, getPayment, getPayroll, getProfession, getSchedule, getShift, getSkill, getTalentProfile, updateTalent } from "../../actions/employer/talent";
import { getUserInformation, updateUserInformation, addUserInformation } from "../../actions/user";
import Multiselect from "multiselect-react-dropdown";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import swal from "sweetalert";
import { showBadge } from "../../../utilities/helpers";

const Skills = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [credLoading, setCredLoading] = useState(false);
  
  const [gettingField, setGettingField] = useState(false);
  const [modal, setModal] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [fieldMetaData, setFieldMetaData] = useState(null);
  const [resumeAction, setResumeAction] = useState({ type: null, name: null });

  const [professionData, setProfessionData] = useState([]);
  const [professionId, setProfessionId] = useState([]);

  const [skillData1, setSkillData1] = useState([]);
  const [skillData2, setSkillData2] = useState([]);
  const [skillData3, setSkillData3] = useState([]);
  const [skillData4, setSkillData4] = useState([]);
  const [skillData5, setSkillData5] = useState([]);

  const [skillFlag1, setSkillFlag1] = useState(false);
  const [skillFlag2, setSkillFlag2] = useState(false);
  const [skillFlag3, setSkillFlag3] = useState(false);
  const [skillFlag4, setSkillFlag4] = useState(false);
  const [skillFlag5, setSkillFlag5] = useState(false);

  const [employmentData, setEmploymentData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [facilityData, setFacilityData] = useState([]);
  const [payrollData, setPayrollData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);

  const [userProfileData, setUserProfileData] = useState({});

  const [skillModal, setSkillModal] = useState({ type: '', modal: false });

  const [verifyModal, setVerifyModal] = useState(false);
  const [editVerifyModal, setEditVerifyModal] = useState(false);
  const [credentialModal, setCredentialModal] = useState(false);
  const [mimeTypes, setMimeTypes] = useState([]);
  const [uploadedCred, setUploadedCred] = useState([]);  
  const [userProfileIdUse, setUserProfileIdUse] = useState(0);
  const [resumeCredFile, setResumeCredFile] = useState(null);
  

  const [userInformation, setUserInformation] = useState();

  const [loadingUpdateUserInfo, setLoadingUpdateUserInfo] = useState(false)

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [address, setAddress] = useState();
  const [address1, setAddress1] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipCode, setZipCode] = useState();
  const [country, setCountry] = useState();
  const [unitId, setUnitId] = useState();

  const initField = {
    "address": "",
    "address1": "",
    "city": "",
    "country": "",
    "countryAbbr": "",
    "employmentTypeIds": "",
    "facilityTypeIds": "",
    "latitude": 0,
    "longitude": 0,
    "minExperience": "",
    "minHourlyPayment": "",
    "minSalaryPayment": "",
    "paymentTypeIds": "",
    "payrollTypeIds": "",
    "scheduleTypeIds": "",
    "selectedEntityTypeIds": "",
    "shiftTypeIds": "",
    "state": "",
    "stateAbbr": "",
    "unitId": "",
    "userDepartmentIds": "",
    "withinMile": "",
    "zipCode": ""
  };

  const [form, setForm] = useState(initField);

  const onChangeField = (event) => {
    const {
      target: { value, name },
    } = event;
    //setForm({ ...form, [name]: value.trimStart() });
    setForm({ ...form, [name]: value });
  };

  const getUserProfileList = () => {
    setListLoading(true);
    props.getUserProfileList({ userIdHash: props.auth.token }, (res) => {
      setListLoading(false);
      if (res.status) {
        console.log('LIST', res.data)
        setList(res.data);
      }
    });
  };

  const getSkillBio = (id) => {
    setGettingField(true);
    props.getSkillBio(
      { userIdHash: props.auth.token, userProfileId: id },
      (res) => {
        setGettingField(false);
        if (res.status) {
          // setList(res.data);
          if (res.data.entityInfo) {
            setResumeAction({
              type: "Edit",
              name: res.data.perspectiveInfo.name,
              id: res.data.entityInfo.id,
              userProfileId: id,
            });
            getFormFieldsEntity(res.data.entityInfo.id);
          } else {
            setResumeAction({
              type: "Add",
              name: res.data.perspectiveInfo.name,
              id: res.data.perspectiveInfo.id,
              userProfileId: id,
            });
            getFormFieldsPrespective({
              perspectiveId: res.data.perspectiveInfo.id,
              userProfileId: id,
            });
          }
        }
      }
    );
  };

  const getFormFieldsPrespective = (data) => {
    setGettingField(true);
    props.getFormFieldsPrespective(
      { perspectiveId: data.perspectiveId, userProfileId: data.userProfileId },
      (res) => {
        setGettingField(false);
        if (res.status) {
          setFieldMetaData(res.data);
          setFieldValues(
            res.data.reduce((acc, { field }) => {
              acc[field.entityAttributeFieldKey] = field.fieldValue || null;
              return acc;
            }, {})
          );
        }
      }
    );
  };

  const getFormFieldsEntity = (id) => {
    setGettingField(true);
    props.getFormFieldsEntity({ entityId: id }, (res) => {
      setGettingField(false);
      if (res.status) {
        setFieldMetaData(res.data);
        setFieldValues(
          res.data.reduce((acc, { field }) => {
            acc[field.entityAttributeFieldKey] = field.fieldValue || null;
            return acc;
          }, {})
        );
      }
    });
  };

  const addSkillBio = (data) => {
    setGettingField(true);
    props.addSkillBio(data, (res) => {
      setGettingField(false);
      if (res.status) {
        setFieldMetaData(null);
        setFieldValues({});
        setModal(!modal);
        swal({
          title: "Resume Added Successfully",
          text: "",
          icon: "success"
        })
      } else {
        toast.error("Something went wrong!");
        setGettingField(false);
      }
    });
  };

  const editSkillBio = (data) => {
    setGettingField(true);
    props.editSkillBio(
      data,
      (res) => {
        setGettingField(false);
        if (res.status) {
          setFieldMetaData(null);
          setFieldValues({});
          setModal(!modal);
          swal({
            title: "Resume Updated Successfully",
            text: "",
            icon: "success"
          })
        } else {
          toast.error("Something went wrong!");
          setGettingField(false);
        }
      }
    );
  };

  const createTalent = (data) => {
    setListLoading(true);
    props.createTalent({ data: data, userTypeId: props.auth.userProfile.userType.userTypeId }, res => {
      if (res.status) {
        setListLoading(false);
        removeAllSkillField();
        setUserProfileData({})
        setSkillModal({ modal: false, type: "" })
        swal({
          title: "Skill Profile created successfully!",
          text: "",
          icon: "success"
        }).then(onUpdate => {
          getUserProfileList()
        })
      } else {
        setListLoading(false)
        toast.error(res.message)
      }
    })
  }

  const updateTalent = (data) => {
    setListLoading(true);
    if (data.address1 == null) {
      data.address1 = ""
    }
    props.updateTalent({ data: data, userProfileId: data.id }, res => {
      if (res.status) {
        setListLoading(true);
        removeAllSkillField();
        setUserProfileData({})
        setSkillModal({ modal: false, type: "" })
        swal({
          title: "Skill Profile updated successfully!",
          text: "",
          icon: "success"
        }).then(onUpdate => {
          getUserProfileList()
        })
      } else {
        setListLoading(false)
        toast.error(res.message)
      }
    })
  }



  const getProfession = () => {
    props.getProfession({}, res => {
      if (res.status) {
        setProfessionData(res.data)
        //setProfessionId(res.data[0].id)
      }
    })
  }

  const getSkill = (id) => {
    props.getSkill({ professionId: id }, res => {
      if (res.status) {
        if (!skillFlag1) {
          setSkillFlag1(true);
          setSkillData1(res.data);
        }
        if (skillFlag1 && !skillFlag2) {
          setSkillFlag2(true);
          setSkillData2(res.data);
        }
        if (skillFlag2 && !skillFlag3) {
          setSkillFlag3(true);
          setSkillData3(res.data);
        }
        if (skillFlag3 && !skillFlag4) {
          setSkillFlag4(true);
          setSkillData4(res.data);
        }
        if (skillFlag4 && !skillFlag5) {
          setSkillFlag5(true);
          setSkillData5(res.data);
        }
      }
    })
  }

  const getTalentProfile = (id) => {
    props.getTalentProfile({ userProfileId: id }, res => {
      if (res.status) {
        const { data } = res;
        setUserProfileData(data.userProfileDataTypes);
        setForm({
          id: data.id, address: data.address, address1: data.address1, city: data.city, state: data.state, country: data.country, zipCode: data.zipCode, employmentTypeIds: Array.from(data.userProfileDataTypes?.employmentType, x => x.employmentTypeId).join(','), facilityTypeIds: Array.from(data.userProfileDataTypes?.facilityType, x => x.facilityTypeId).join(','), latitude: data.latitude, longitude: data.longitude, minExperience: data.minExperience, minHourlyPayment: data.minHourlyPayment, minSalaryPayment: data.minSalaryPayment, paymentTypeIds: Array.from(data.userProfileDataTypes?.paymentType, x => x.paymentTypeId).join(','), payrollTypeIds: Array.from(data.userProfileDataTypes?.payrollType, x => x.payrollTypeId).join(','), scheduleTypeIds: Array.from(data.userProfileDataTypes?.scheduleType, x => x.scheduleTypeId).join(','), shiftTypeIds: Array.from(data.userProfileDataTypes?.shiftType, x => x.shiftTypeId).join(','), unitId: data.unitId, userDepartmentIds: "", withinMile: data.withinMile,
        });
      }
    })
  }

  const getEmployment = () => {
    props.getEmployment({}, res => {
      if (res.status) {
        setEmploymentData(res.data)
      }
    })
  }

  const getSchedule = () => {
    props.getSchedule({}, res => {
      if (res.status) {
        if (res.data.length > 0) {
          setScheduleData(res.data)
        }
      }
    })
  }

  const getFacility = () => {
    props.getFacility({}, res => {
      if (res.status) {
        setFacilityData(res.data)
      }
    })
  }


  const getPayroll = () => {
    props.getPayroll({}, res => {
      if (res.status) {
        setPayrollData(res.data)
      }
    })
  }


  const getShift = () => {
    props.getShift({}, res => {
      if (res.status) {
        setShiftData(res.data)
      }
    })
  }


  const getPayment = () => {
    props.getPayment({}, res => {
      if (res.status) {
        setPaymentData(res.data)
      }
    })
  }

  const getUserInformationData = () => {
    props.getUserInformation({}, res => {
      if (res.status) {
        console.log('USER INFO', res.data)
        setUserInformation(res.data)
        // if (res.data.entityName !== null) {
        //     setFirstName(res.data?.entityName)
        // }
      }
    })
  }


  useEffect(() => {
    getUserProfileList();
    getUserInformationData();
    if (location.state?.fromLogin) {
      openCreateTalentModal()
    }
  }, []);

  const removeAllSkillField = () => {
    setSkillData1([])
    setSkillFlag1(false)
    setSkillData2([])
    setSkillFlag2(false)
    setSkillData3([])
    setSkillFlag3(false)
    setSkillData4([])
    setSkillFlag4(false)
    setSkillData5([])
    setSkillFlag5(false)
  };

  const onClickSkillResume = (id) => {
    getSkillBio(id);
    setModal(!modal);
  };

  const onClickCredentialsResume = (userProfileId) => {
    // console.log(userProfileId)
    getCredentialData();
    setUserProfileIdUse(userProfileId)
    getResumeCred(userProfileId);
    setCredentialModal(!credentialModal);
  }

  const getCredentialData = () => {
    setCredLoading(true)
    props.getFileMimeType(
      { fileTag: 'profile_resume'},
      (res) => {
      setCredLoading(false)

        if (res.status) {
          console.log(res)

          var filtered = res.data.filter((elem) => {
            return elem.allowedFileExtension != null 
          })

          setMimeTypes(filtered)
        }
        else{
          setMimeTypes([])
          toast.warn('Something went wrong');
        }
      }
    );

  }

  const getResumeCred = (userProfileId) => {
    setCredLoading(true)

    props.getResumeCred(
      { userIdHash: props.auth.token, userProfileId: userProfileId},
      (res) => {
        setCredLoading(false)
        if (res.status) {
          console.log("CRED=========",res)
          setUploadedCred(res.data)
        }
        else{
          setUploadedCred([])
          toast.warn('Something went wrong');
        }
      }
    );
  }
  
  const setFile = (e) => {
    setResumeCredFile(e.target.files[0])
  }

  const updateResumeCred = (userProfileId) => {
    setCredLoading(true)

    if(resumeCredFile == null){
      toast.warn('Please select a file first');
      return false;
    }
    var formData = new FormData();
    formData.append('file', resumeCredFile)

    props.uploadResumeCred(
      { userIdHash: props.auth.token, userProfileId: userProfileId , data:formData},
      (res) => {
        setCredLoading(false)

        if (res.status) {
          getResumeCred(userProfileId)
          setResumeCredFile(null)
        }
        else{
          toast.warn('Something went wrong');
        }
      }
    );

  }

  const removeResumeCred = (fileHashId) => {
    setCredLoading(true)

    props.deleteResumeCred(
      {  fileHashId: fileHashId, userIdHash: props.auth.token , value:1},
      (res) => {
        if (res.status) {
          setCredLoading(false)

          setResumeCredFile(null)
          setUploadedCred([])
        }
        else{
          toast.warn('Something went wrong');
        }
      }
    );

  }
  

  // console.log([mimeTypes , userProfileIdUse]);



  const closeCredentialModal = (e) => {
    setCredentialModal(!credentialModal);
  }
  


  const onClickCloseResume = () => {
    setFieldMetaData(null);
    setFieldValues({});
    setModal(!modal);
  };

  const onSubmitResume = (e) => {
    e.preventDefault()
    if (resumeAction.type === "Add") {
      addSkillBio({
        perspectiveId: resumeAction.id,
        data: { data: JSON.stringify(fieldValues) },
        userIdHash: props.auth.token,
        userProfileId: resumeAction.userProfileId,
      });
    } else if (resumeAction.type === "Edit") {
      editSkillBio({
        entityId: resumeAction.id,
        data: { data: JSON.stringify(fieldValues) },
        userIdHash: props.auth.token,
        userProfileId: resumeAction.userProfileId,
      });
    }
  };

  const onSubmitSkillProfile = () => {
    // console.log('FORM', form)
    if (skillModal.type === "Create") {
      if (professionId == undefined) {
        toast.error('Profession is required')
        return
      }
      if (form.address == null || form.address == '') {
        toast.error('Address Line 1 is required')
        return
      }
      if (form.city == null || form.city == '') {
        toast.error('City is required')
        return
      }
      if (form.state == null || form.state == '') {
        toast.error('State is required')
        return
      }
      if (form.country == null || form.country == '') {
        toast.error('Country is required')
        return
      }
      if (form.minHourlyPayment == null || form.minHourlyPayment == '') {
        toast.error('Hourly Rate is required')
        return
      }
      if (form.minSalaryPayment == null || form.minSalaryPayment == '') {
        toast.error('Min Salary is required')
        return
      }
      if (form.minExperience == null || form.minExperience == '') {
        toast.error('Min Experience is required')
        return
      }
      if (form.withinMile == null || form.withinMile == '') {
        toast.error('Miles is required')
        return
      }
      createTalent({ ...form, selectedEntityTypeIds: professionId.toString() });
    } else if (skillModal.type === "Update") {
      updateTalent(form);
    }
  };



  const onInputChange = (fieldName, value, e) => {
    setFieldValues(() => ({
      ...fieldValues,
      [fieldName]: value,
    }));
  };


  const fields = [];
  const renderedFields = {};

  const renderField = (fieldMetaData, parentFieldValue) => {
    if (!Array.isArray(fieldMetaData)) return null;

    fieldMetaData?.map(({ field, childrenFields }, index) => {
      if (
        parentFieldValue !== undefined &&
        renderedFields[field?.entityAttributeFieldKey] !== undefined
      ) {
        fields[renderedFields[field?.entityAttributeFieldKey]] = (
          <div className="col-md-12 mb-3">
            {inputFieldGenerator(
              field,
              fieldValues[field?.entityAttributeFieldKey],
              onInputChange,
              parentFieldValue,
              props
            )}
          </div>
        );
      }

      if (renderedFields[field?.entityAttributeFieldKey] === undefined) {
        renderedFields[field?.entityAttributeFieldKey] = fields.length;
        fields.push(
          <div className="col-md-12 mb-3">
            {inputFieldGenerator(
              field,
              fieldValues[field?.entityAttributeFieldKey],
              onInputChange,
              parentFieldValue,
              props
            )}
          </div>
        );
      }

      if (childrenFields) {
        renderField(
          childrenFields[0],
          fieldValues[field?.entityAttributeFieldKey]
        );
      }
    });
  };

  const fieldElements = useMemo(() => {
    if (fieldMetaData) {
      renderField(fieldMetaData);
      return fields;
    } else return [];
  }, [fieldMetaData, fieldValues]);

  function openVerifyModal(e = null) {
    if (e) {
      e.preventDefault();
    }

    getUserInformationData()
    setFirstName(userInformation?.firstName)
    setLastName(userInformation?.lastName)
    setAddress()
    setAddress1()
    setCity()
    setState()
    setCountry()
    setZipCode()
    setUnitId()
    setVerifyModal(true)
  }

  function closeVerifyModal(e = null) {
    if (e) {
      e.preventDefault();
    }
    setVerifyModal(false)
  }

  function openEditVerifyModal(e = null) {
    if (e) {
      e.preventDefault();
    }
    setVerifyModal(false)
    setEditVerifyModal(true)
  }

  function backToVerifyModal(e = null) {
    if (e) {
      e.preventDefault();
    }
    setEditVerifyModal(false)
    setVerifyModal(true)
  }

  function openCreateTalentModal(e = null) {
    if (e) e.preventDefault();
    setSkillModal({ modal: true, type: 'Create' })
    setProfessionId()
    getProfession();
    getEmployment();
    getFacility();
    getPayment()
    getPayroll()
    getSchedule()
    getShift()
    setForm(initField);
  }

  function openUpdateTalentModal(id) {
    setSkillModal({ modal: true, type: 'Update' })
    setProfessionId();
    getEmployment();
    getFacility();
    getPayment()
    getPayroll()
    getSchedule()
    getShift()
    getTalentProfile(id)
  }

  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        document.getElementById("searchTextField").blur();
        setTimeout(() => {
          document.getElementById("searchTextField").value = "";
        }, 10);
        fillAddressDetails(results);
      })
      .catch((error) => { });
  };

  const fillAddressDetails = (results) => {
    // console.log(results)
    let newForm = { ...form }
    // setForm({ ...form, address: results[0].formatted_address })
    newForm.address = results[0].formatted_address
    //setForm(newForm)
    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        // setForm({ ...form, zipCode: results[0].address_components[j].short_name })
        newForm.zipCode = results[0].address_components[j].short_name
        //setForm(newForm)
      }
      if (results[0].address_components[j].types[0] == "locality") {
        // setForm({ ...form, city: results[0].address_components[j].long_name })
        newForm.city = results[0].address_components[j].long_name
        //setForm(newForm)
      }
      if (results[0].address_components[j].types[0] == "administrative_area_level_1") {
        // setForm({ ...form, state: results[0].address_components[j].long_name })
        newForm.state = results[0].address_components[j].long_name
        //setForm(newForm)
      }
      if (results[0].address_components[j].types[0] == "country") {
        // setForm({ ...form, country: results[0].address_components[j].long_name })
        newForm.country = results[0].address_components[j].long_name
        //setForm(newForm)
      }
    }
    setForm(newForm)
  };

  const autoCompleteHandleChange = (address) => {
    setAddress(address);
  };

  const autoCompleteHandleSelectForUserInfo = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        document.getElementById("searchTextField").blur();
        setTimeout(() => {
          document.getElementById("searchTextField").value = "";
        }, 10);
        fillAddressDetailsForUserInfo(results);
      })
      .catch((error) => { });
  };

  const fillAddressDetailsForUserInfo = (results) => {
    setAddress(results[0].formatted_address)
    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        setZipCode(results[0].address_components[j].short_name);
      } else if (results[0].address_components[j].types[0] == "locality") {
        setCity(results[0].address_components[j].long_name);
      } else if (results[0].address_components[j].types[0] == "administrative_area_level_1") {
        setState(results[0].address_components[j].long_name)
      } else if (results[0].address_components[j].types[0] == "country") {
        setCountry(results[0].address_components[j].long_name)
      }
    }
    // setUpdateDisabled(false)
  };

  function changeInputvalue(e, flag) {
    const value = e.target.value
    // setCreateDisabled(false)
    // setUpdateDisabled(false)
    if (flag == 'firstName') { setFirstName(value) }
    else if (flag == 'lastName') { setLastName(value) }
    else if (flag == 'address') { setAddress(value) }
    else if (flag == 'address1') { setAddress1(value) }
    else if (flag == 'city') { setCity(value) }
    else if (flag == 'state') { setState(value) }
    else if (flag == 'zip') { setZipCode(value) }
    else if (flag == 'country') { setCountry(value) }
    else if (flag == 'unit') { setUnitId(value) }
  }

  function updatePrimaryInformation(e) {
    e.preventDefault();
    if (firstName == '' || firstName == null || firstName == undefined || lastName == '' || lastName == null || lastName == undefined) {
      toast.error('First Name / Last Name must not be empty')
      return
    }
    let params = {
      data: {
        address: address ? address : userInformation.address,
        address1: address1 ? address1 : '',
        city: city ? city : userInformation.city,
        country: country ? country : userInformation.country,
        countryAbbr: "IN",
        entityName: '',
        firstName: firstName ? firstName : userInformation.firstName,
        lastName: lastName ? lastName : userInformation.lastName,
        id: 0,
        isDefault: 0,
        isValidated: 0,
        isVendorManaged: 0,
        isVerified: 0,
        middleName: "MN",
        phoneNumber: "6666666666",
        state: state ? state : userInformation.state,
        stateAbbr: "SA",
        unitId: unitId ? unitId : userInformation.unitId,
        uploadDirectory: "UD",
        zipCode: zipCode ? zipCode : userInformation.zipCode
      },
      userInformationId: userInformation.id,
      userIdHash: props.auth.token,
      token: props.auth.token
    }

    // console.log('PARAMS', params)

    setLoadingUpdateUserInfo(true)

    props.updateUserInformation(params, res => {
      if (res.status) {
        getUserInformationData()
        setLoadingUpdateUserInfo(false)
        setEditVerifyModal(false)
        setVerifyModal(true)
      }
    })
  }

  return (
    <>
      <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
        <div className="d-flex flex-column-fluid">
          <div className="custom-container" style={{ height: 'auto' }}>
            <div className="row pb-5">
              <div className="col-md-6">
                <div className="inner_txecy">
                  <h4>My Professional Skills </h4>
                </div>
              </div>
              <div className="col-md-6">
                <div className="talen_outre">
                  <button className="btn" onClick={(e) => { openVerifyModal(e) }}>+ CREATE SKILL</button>
                </div>
              </div>
            </div>

            <div className="Profile_form">
              <div className="row pb-5">
                {listLoading ? (
                  <div className="row" style={{ width: '100%', height: '30vh' }}>
                    <div className="col-12" style={{ width: '100%', height: '100%' }}>
                      <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                        <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                      </div>
                    </div>
                  </div>
                ) : list.length > 0 ? (
                  list.map((item) => (
                    <div className="col-md-4" key={item.id}>
                      <div className="blush_outer">
                        <div className="edit_outer">
                          <a onClick={() => openUpdateTalentModal(item.id)} style={{ cursor: 'pointer' }}><img src={talent_edit} alt="" /></a>
                        </div>
                        <div className="geoge_outer">
                          <div className="circleName custom789">{item.userProfileDataTypes.UserProfileEntityTypeIsSelected?.entityTypeNameAbbreviation ? item.userProfileDataTypes.UserProfileEntityTypeIsSelected?.entityTypeNameAbbreviation : "---"}</div>
                          <img src={item.isVerified ? verified : unVerified} alt="verify" style={{ position: "absolute", top: '55px', left: '55px' }} />
                          <div className="google_inner">
                            <div className="d-flex justify-content-between align-items-center">
                              <h5>{item.userProfileDataTypes.employmentType?.length ? item.userProfileDataTypes.employmentType.map((item, index, array) => index + 1 === array.length ? `${item.name}` : `${item.name}, `) : "---"}</h5>
                              <img src={showBadge(item.userProfileDataTypes.selectedUserDataRating)} alt="badge" className="badgeClass mr-6" />
                            </div>
                            <p style={{fontSize:'14px', color:'grey'}}>{item.userProfileDataTypes.Profession ? item.userProfileDataTypes.Profession : '---'}</p>
                            <p className="custom_png"><img className="custom78" src={locationImg} alt="" />{item.city ? item.city + ', ' : '---'} {item.country ? item.country : '---'}</p>
                          </div>
                        </div>
                        <div className="open_job">
                          <h5>
                            <a href="javascript:void(0)" onClick={() => history.push(`jobApplications/${item.id}`)}>
                              <img src={showImg} alt="show" />
                              <span className="ml-2" style={{fontSize:'14px'}}>My Job Applications</span>
                            </a>
                          </h5>
                        </div>
                        <div className="button_list">
                          <a onClick={() => onClickSkillResume(item.id)} className="about_btn" href="javascript:void(0)">Skill Resume</a>
                          <a onClick={() => onClickCredentialsResume(item?.userProfileDataTypes?.UserProfileEntityTypeIsSelected?.userProfileId)} className="about_btn shift_outer5" href="javascript:void(0)">Credentials</a>
                        </div>
                      </div>
                    </div>
                  ))
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modal} size="md" centered style={{ background: "rgb(95 95 95 / 77%)" }}>
        <div className="text-center m-5">
          <Modal.Title>
            {resumeAction.type} {resumeAction.name}
          </Modal.Title>
        </div>
        <form onSubmit={onSubmitResume}>
          <div className="row mx-2">{fieldElements}</div>
          <div className="text-center m-5">
            <Button className="mx-3" variant="danger" onClick={onClickCloseResume}>
              Close
            </Button>
            {fieldMetaData !== null && <Button className="mx-3" variant="primary" type="submit">
              {gettingField ?
                <span className="spinner-border spinner-border-sm"></span>
                : resumeAction.type == "Add" ? "Submit" : "Update"}
            </Button>}
          </div>
        </form>

      </Modal>

      <Modal show={skillModal.modal} size="md" centered style={{ background: "rgb(95 95 95 / 77%)" }}>
        <div className='text-center m-5'>
          <Modal.Title><b>{skillModal.type} Professional Skill Profile</b></Modal.Title>
        </div>
        <Modal.Body>
          {/* <div className="col-md-12 mb-3">
            <label>Business Name<span className="required">*</span></label>
            <input value={form.entityName} className="form-control col-12" name="entityName" onChange={onChangeField} required />
          </div> */}


          {skillModal.type !== 'Update' && professionData.length > 0 ? (
            <div className="col-md-12 mb-3">
              <label>Profession<span className="required">*</span></label>

              <select className="form-control col-12" onChange={(e) => { setProfessionId([e.target.value]); removeAllSkillField(); getSkill(e.target.value) }}>
                <option defaultValue='' disabled selected>Select Profession</option>
                {professionData.map((value) => {
                  return (
                    <option value={value.id}>{value.name}</option>
                  )
                })}
              </select>
            </div>
          ) : (
            <>
            </>
          )}

          {skillFlag1 ? (
            <div className="col-md-12 mb-3">
              <label>Skill<span className="required">*</span></label>
              <select className="form-control col-12" onChange={(e) => { setProfessionId([...professionId, e.target.value]); getSkill(e.target.value) }}>
                <option defaultValue='' disabled selected>Select Skill</option>
                {skillData1.map((value) => {
                  return (
                    <option value={value.id}>{value.name}</option>
                  )
                })}
              </select>
            </div>) : null}

          {skillFlag2 ? (
            <div className="col-md-12 mb-3">
              <label>Skill<span className="required">*</span></label>
              <select className="form-control col-12" onChange={(e) => setProfessionId([...professionId, e.target.value])}>
                <option defaultValue='' disabled selected>Select Skill</option>
                {skillData2.map((value) => {
                  return (
                    <option value={value.id}>{value.name}</option>
                  )
                })}
              </select>
            </div>) : null}

          {skillFlag3 ? (
            <div className="col-md-12 mb-3">
              <label>Skill<span className="required">*</span></label>
              <select className="form-control col-12" onChange={(e) => setProfessionId([...professionId, e.target.value])}>
                <option defaultValue='' disabled selected>Select Skill</option>
                {skillData3.map((value) => {
                  return (
                    <option value={value.id}>{value.name}</option>
                  )
                })}
              </select>
            </div>) : null}

          {skillFlag4 ? (
            <div className="col-md-12 mb-3">
              <label>Skill<span className="required">*</span></label>
              <select className="form-control col-12" onChange={(e) => setProfessionId([...professionId, e.target.value])}>
                <option defaultValue='' disabled selected>Select Skill</option>
                {skillData4.map((value, index) => {
                  return (
                    <option value={value.id}>{value.name}</option>
                  )
                })}
              </select>
            </div>) : null}

          {skillFlag5 ? (
            <div className="col-md-12 mb-3">
              <label>Skill<span className="required">*</span></label>
              <select className="form-control col-12" onChange={(e) => setProfessionId([...professionId, e.target.value])}>
                <option defaultValue='' disabled selected>Select Skill</option>
                {skillData5.map((value) => {
                  return (
                    <option value={value.id}>{value.name}</option>
                  )
                })}
              </select>
            </div>) : null}

          <div className="col-md-12 mb-3">
            <label>Pick Address</label>
            <PlacesAutocomplete
              value={form.address}
              onChange={(val) => { setForm({ ...form, address: val }) }}
              onSelect={autoCompleteHandleSelect}
              searchOptions={{ componentRestrictions: { country: "" } }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    id="searchTextField"
                    {...getInputProps({
                      placeholder: "Search Places ...",
                      className:
                        "location-search-input form-control inputStyle",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#41b6e6", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>

          <div className="col-md-12 mb-3">
            <label>Address Line 1<span className="required">*</span></label>
            <input value={form.address} className="form-control col-12" name="address" onChange={onChangeField} required />
          </div>

          <div className="col-md-12 mb-3">
            <label>Address Line 2</label>
            <input value={form.address1} className="form-control col-12" name="address1" onChange={onChangeField} />
          </div>

          <div className="col-md-12 mb-3">
            <label>City<span className="required">*</span></label>
            <input value={form.city} className="form-control col-12" name="city" onChange={onChangeField} required />
          </div>

          <div className="col-md-12 mb-3">
            <label>State<span className="required">*</span></label>
            <input value={form.state} className="form-control col-12" name="state" onChange={onChangeField} required />
          </div>

          <div className="col-md-12 mb-3">
            <label>Zip Code<span className="required">*</span></label>
            <input type='text' value={form.zipCode} className="form-control col-12" name="zipCode" onChange={onChangeField} required />
          </div>

          <div className="col-md-12 mb-3">
            <label>Country<span className="required">*</span></label>
            <input value={form.country} className="form-control col-12" name="country" onChange={onChangeField} required />
          </div>

          <div className="col-md-12 mb-3">
            <label>Unit Id</label>
            <input value={form.unitId} className="form-control col-12" name="unitId" onChange={onChangeField} />
          </div>

          <div className="col-md-12 mb-3">
            <label>Hourly Rate</label>
            <input type='number' value={form.minHourlyPayment} className="form-control col-12" name="minHourlyPayment" onChange={onChangeField} />
          </div>

          <div className="col-md-12 mb-3">
            <label>Min. Years of Experience</label>
            <input type='number' value={form.minExperience} className="form-control col-12" name="minExperience" onChange={onChangeField} />
          </div>

          <div className="col-md-12 mb-3">
            <label>Min. Salary</label>
            <input type='number' value={form.minSalaryPayment} className="form-control col-12" name="minSalaryPayment" onChange={onChangeField} />
          </div>

          <div className="col-md-12 mb-3">
            <label>Miles</label>
            <input type='number' value={form.withinMile} className="form-control col-12" name="withinMile" onChange={onChangeField} />
          </div>

          {employmentData.length > 0 ? (
            <div className="col-md-12 mb-3">
              <label>Employment Type<span className="required">*</span></label>
              <Multiselect
                options={employmentData} // Options to display in the dropdown
                selectedValues={userProfileData?.employmentType?.length ? userProfileData.employmentType.map((x) => ({ id: x.employmentTypeId, name: x.name })) : []} // Preselected value to persist in dropdown
                showCheckbox={true}
                placeholder="Select Employment Type"
                onSelect={(list) => { setForm({ ...form, employmentTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on select event
                onRemove={(list) => { setForm({ ...form, employmentTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
          ) : null}

          {scheduleData.length > 0 ? (
            <div className="col-md-12 mb-3">
              <label>Schedule Type<span className="required">*</span></label>
              <Multiselect
                options={scheduleData} // Options to display in the dropdown
                selectedValues={userProfileData?.scheduleType?.length ? userProfileData.scheduleType.map((x) => ({ id: x.scheduleTypeId, name: x.name })) : []} // Preselected value to persist in dropdown
                showCheckbox={true}
                placeholder="Select Schedule Type"
                onSelect={(list) => { setForm({ ...form, scheduleTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on select event
                onRemove={(list) => { setForm({ ...form, scheduleTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
          ) : null}

          {facilityData.length > 0 ? (
            <div className="col-md-12 mb-3">
              <label>Facility Type<span className="required">*</span></label>
              <Multiselect
                options={facilityData} // Options to display in the dropdown
                selectedValues={userProfileData?.facilityType?.length ? userProfileData.facilityType.map((x) => ({ id: x.facilityTypeId, name: x.name })) : []} // Preselected value to persist in dropdown
                showCheckbox={true}
                placeholder="Select Facility Type"
                onSelect={(list) => { setForm({ ...form, facilityTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on select event
                onRemove={(list) => { setForm({ ...form, facilityTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
          ) : null}

          {payrollData.length > 0 ? (
            <div className="col-md-12 mb-3">
              <label>Payroll Type<span className="required">*</span></label>
              <Multiselect
                options={payrollData} // Options to display in the dropdown
                selectedValues={userProfileData?.payrollType?.length ? userProfileData.payrollType.map((x) => ({ id: x.payrollTypeId, name: x.name })) : []} // Preselected value to persist in dropdown
                showCheckbox={true}
                placeholder="Select Payroll Type"
                onSelect={(list) => { setForm({ ...form, payrollTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on select event
                onRemove={(list) => { setForm({ ...form, payrollTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
          ) : null}

          {shiftData.length > 0 ? (
            <div className="col-md-12 mb-3">
              <label>Shift Type<span className="required">*</span></label>
              <Multiselect
                options={shiftData} // Options to display in the dropdown
                selectedValues={userProfileData?.shiftType?.length ? userProfileData.shiftType.map((x) => ({ id: x.shiftTypeId, name: x.name })) : []} // Preselected value to persist in dropdown
                showCheckbox={true}
                placeholder="Select Shift Type"
                onSelect={(list) => { setForm({ ...form, shiftTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on select event
                onRemove={(list) => { setForm({ ...form, shiftTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
          ) : null}

          {paymentData.length > 0 ? (
            <div className="col-md-12 mb-3">
              <label>Payment Type<span className="required">*</span></label>
              <Multiselect
                options={paymentData} // Options to display in the dropdown
                selectedValues={userProfileData?.paymentType?.length ? userProfileData.paymentType.map((x) => ({ id: x.paymentTypeId, name: x.name })) : []} // Preselected value to persist in dropdown
                showCheckbox={true}
                placeholder="Select Payment Type"
                onSelect={(list) => { setForm({ ...form, paymentTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on select event
                onRemove={(list) => { setForm({ ...form, paymentTypeIds: Array.from(list, x => x.id).join(",") }) }} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
          ) : null}

        </Modal.Body>

        <div className='text-center m-5'>
          <Button className="mx-3" variant="danger" onClick={() => { setSkillModal({ modal: false, type: "" }); removeAllSkillField(); setUserProfileData({}); setForm(initField); closeVerifyModal() }}>Close</Button>
          <Button className="mx-3" variant="primary" onClick={onSubmitSkillProfile}
          // disabled={createDisabled}
          >
            {listLoading ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : skillModal.type}
          </Button>
        </div>
      </Modal>

      <Modal show={verifyModal} size="md"
        centered
        style={{ background: "rgb(95 95 95 / 77%)" }}>
        <div className='text-center m-5'>
          <Modal.Title><b>Verify Primary Information</b></Modal.Title>
          <img src={talent_edit} alt="Edit" style={{ width: '5%', position: 'absolute', top: '15px', right: '22px', cursor: 'pointer' }} onClick={openEditVerifyModal} />
        </div>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>First Name</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.firstName : ''} disabled />
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Last Name</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.lastName : ''} disabled />
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.address : ''} disabled />
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address 1</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.address1 : ''} disabled />
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>City</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.city : ''} disabled />
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>State</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.state : ''} disabled />
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Zip Code</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.zipCode : ''} disabled />
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Country</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.country : ''} disabled />
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Unit Id</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.unitId : ''} disabled />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <div className='text-center m-5'>
          <Button className="mx-3" variant="danger" onClick={(e) => { closeVerifyModal(e) }}>Close</Button>
          <Button className="mx-3" variant="primary" onClick={(e) => { openCreateTalentModal(e) }}>Continue to Create Skill</Button>
        </div>
      </Modal>

      <Modal show={editVerifyModal} size="md"
        centered
        style={{ background: "rgb(95 95 95 / 77%)" }}>
        <div className='text-center m-5'>
          <Modal.Title><b>Edit Primary Information</b></Modal.Title>
          <i className='fa fa-arrow-left' style={{ color: 'green', fontSize: '20px', position: 'absolute', top: '20px', left: '22px', cursor: 'pointer' }} onClick={backToVerifyModal}></i>
        </div>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>First Name</span></Form.Label>
              <div className="form-group">
                {/* {isBusinessNameFieldDisabled ?
                  (<>
                    <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.firstName : ''} disabled />
                  </>) :
                  (<>
                    <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.firstName : ''} />
                  </>)} */}
                <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'firstName') }} value={firstName} />
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Last Name</span></Form.Label>
              <div className="form-group">
                {/* {isBusinessNameFieldDisabled ?
                  (<>
                    <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.lastName : ''} disabled />
                  </>) :
                  (<>
                    <input className="form-control col-12" name="report.granularity" id="report.granularity" value={userInformation ? userInformation.lastName : ''} />
                  </>)} */}
                <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'lastName') }} value={lastName} />
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Pick Address</span></Form.Label>
              <div className="form-group">
                <PlacesAutocomplete
                  value={address}
                  onChange={autoCompleteHandleChange}
                  onSelect={autoCompleteHandleSelectForUserInfo}
                  searchOptions={{ componentRestrictions: { country: "" } }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        id="searchTextField"
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className:
                            "location-search-input form-control inputStyle",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#41b6e6", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'address') }} value={address ? address : userInformation ? userInformation.address : ''} />
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Address 1</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'address1') }} value={address1} />
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>City</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'city') }} value={city ? city : userInformation ? userInformation.city : ''} />
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>State</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'state') }} value={state ? state : userInformation ? userInformation.state : ''} />
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Zip Code</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'zip') }} value={zipCode ? zipCode : userInformation ? userInformation.zipCode : ''} />
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Country</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'country') }} value={country ? country : userInformation ? userInformation.country : ''} />
              </div>
            </Form.Group>

            <Form.Group controlId="formBasicVenue">
              <Form.Label><span style={{ fontSize: '20px', color: '#006838', fontWeight: '300' }}>Unit Id</span></Form.Label>
              <div className="form-group">
                <input className="form-control col-12" name="report.granularity" id="report.granularity" onChange={(e) => { changeInputvalue(e, 'unit') }} value={unitId ? unitId : userInformation ? userInformation.unitId : ''} />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <div className='text-center m-5'>
          <Button className="mx-3" variant="danger" onClick={(e) => { backToVerifyModal(e) }}>Close</Button>
          <Button className="mx-3" variant="primary" onClick={updatePrimaryInformation}>
            {loadingUpdateUserInfo && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Update</Button>
        </div>
      </Modal>

      <Modal show={credentialModal} size="md"
        centered
        style={{ background: "rgb(95 95 95 / 77%)" }}>
        <div className='text-center m-5'>
          <Modal.Title><b>Credential</b></Modal.Title>
        </div>
        <Modal.Body>
          {credLoading?
          <div className="row" style={{ width: '100%', height: '20vh' }}>
            <div className="col-12" style={{ width: '100%', height: '100%' }}>
              <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '20vh' }}>
                <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
              </div>
            </div>
          </div>
          :(
          <>
            {uploadedCred.length > 0 ? 
              <>
                <div className="row"> 
                  <div className="col-md-4 text-center">
                    <i className='fa fa-file-text' style={{ color: 'green', fontSize: '25px'}}></i>
                  </div>
                  <div className="col-md-4 text-center">
                    <h4>{uploadedCred[0]?.name}</h4>
                  </div>
                  <div className="col-md-4 text-center">
                    <i className='fa fa-times' style={{ color: 'grey', fontSize: '25px'}}  onClick={(e) => { removeResumeCred(uploadedCred[0]?.fileHashId) }}></i>
                  </div>
                </div>
              </>
            :
              <>
                <Form>
                  <Form.Group controlId="formBasicVenue">
                    <div className="form-group">
                      <input className="form-control col-12" name="credential" id="uploadCredResume" type="file" accept=".pdf,.docx,.doc" onChange={(e)=>setFile(e)}/>
                    </div>
                  <Form.Label><span style={{ fontSize: '15px', color: '#F64E60', fontWeight: '300' }}>
                    Only
                    {mimeTypes.map((mime)=>{
                      return(` ${mime.allowedFileExtension}, `)
                    })
                    }are allowed </span>
                    </Form.Label>
                  </Form.Group>
                </Form>
              </>
            }
          </>
          )}
        </Modal.Body>
        <div className='text-center m-5'>
          <Button className="mx-3" variant="danger" onClick={(e) => { closeCredentialModal(e) }}>Close</Button>
          {uploadedCred.length > 0 || credLoading ? null :
          <Button className="mx-3" variant="primary" onClick={(e) => updateResumeCred(userProfileIdUse)}>
            {loadingUpdateUserInfo && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Update</Button>}
        </div>
      </Modal>

      
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getUserProfileList: bindActionCreators(getUserProfileList, dispatch),
  getSkillBio: bindActionCreators(getSkillBio, dispatch),
  getFormFieldsPrespective: bindActionCreators(getFormFieldsPrespective, dispatch),
  getFormFieldsEntity: bindActionCreators(getFormFieldsEntity, dispatch),
  editSkillBio: bindActionCreators(editSkillBio, dispatch),
  addSkillBio: bindActionCreators(addSkillBio, dispatch),
  createTalent: bindActionCreators(createTalent, dispatch),
  updateTalent: bindActionCreators(updateTalent, dispatch),
  getTalentProfile: bindActionCreators(getTalentProfile, dispatch),
  getFileMimeType: bindActionCreators(getFileMimeType, dispatch),
  getResumeCred: bindActionCreators(getResumeCred, dispatch),
  uploadResumeCred: bindActionCreators(uploadResumeCred, dispatch),
  deleteResumeCred: bindActionCreators(deleteResumeCred, dispatch),
  getProfession: bindActionCreators(getProfession, dispatch),
  getSkill: bindActionCreators(getSkill, dispatch),
  getEmployment: bindActionCreators(getEmployment, dispatch),
  getSchedule: bindActionCreators(getSchedule, dispatch),
  getFacility: bindActionCreators(getFacility, dispatch),
  getPayroll: bindActionCreators(getPayroll, dispatch),
  getShift: bindActionCreators(getShift, dispatch),
  getPayment: bindActionCreators(getPayment, dispatch),
  getUserInformation: bindActionCreators(getUserInformation, dispatch),
  addUserInformation: bindActionCreators(addUserInformation, dispatch),
  updateUserInformation: bindActionCreators(updateUserInformation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Skills);
