import React, { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import newCalender from "../../public/images/newCalender.svg";
import goldBadge from "../../public/images/goldBadge.svg";
import { getFormatedDate } from "../../../utilities/helpers";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { follow, shortlist } from "../../actions/common";
import { invite } from "../../actions/employer/dashboard";
import { getFormFieldsEntity } from "../../actions/employee/skill";
import { getActiveDaysList, } from "../../actions/employer/talent";
import { getDailyCalendar } from "../../actions/employee/dashboard";

const CandidateDetail = (props) => {
  const location = useLocation();
  const profileInfo = location.state;
  console.log(profileInfo);

  const initModal = { modal: false, type: "", data: {} };
  const [modal, setModal] = useState(initModal);
  const [modalLoading, setModalLoading] = useState(false);

  const [fieldMetaData, setFieldMetaData] = useState([]);
  const [activeDaysListData, setActiveDaysListData] = useState([]);
  const [dailyCalendarData, setDailyCalendarData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [tab, setTab] = useState(1);

  const getActiveDaysList = () => {
    setLoading(true);
    props.getActiveDaysList({ userProfileId: profileInfo.userProfileData.selectedUserData.userProfileId }, res => {
      setLoading(false);
      if (res.status) {
        setActiveDaysListData(res.data)
      }
    })
  };

  const getDailyCalendar = () => {
    setLoading(true);
    props.getDailyCalendar({ userProfileId: profileInfo.userProfileData.selectedUserData.userProfileId, entityId: profileInfo.userProfileData.selectedEntityData.id }, res => {
      setLoading(false);
      if (res.status) {
        setDailyCalendarData(res.data)
      }
    })
  };

  const getFormFieldsEntity = () => {
    setLoading(true);
    props.getFormFieldsEntity({ entityId: profileInfo.userProfileData.selectedEntityData.id }, (res) => {
      setLoading(false);
      if (res.status) {
        setFieldMetaData(res.data);
      }
    });
  };

  const shortlist = () => {
    setModalLoading(true);
    props.shortlist(
      {
        jobUserProfileId: modal.data.selectedEntityData.jobUserProfileId,
        providerUserProfileId: modal.data.selectedUserData.userProfileId,
      },
      (res) => {
        setModalLoading(false);
        if (res.status) {
          setModal(initModal);
          toast.success("Shortlisted Successfully");
        }
      },
      (err) => {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong!");
      }
    );
  };

  const follow = () => {
    setModalLoading(true);
    props.follow(
      {
        jobUserProfileId: modal.data.selectedEntityData.jobUserProfileId,
        providerUserProfileId: modal.data.selectedUserData.userProfileId,
      },
      (res) => {
        setModalLoading(false);
        if (res.status) {
          setModal(initModal);
          toast.success("Followed Successfully");
        }
      },
      (err) => {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong!");
      }
    );
  };

  const invite = () => {
    setModalLoading(true);
    props.invite(
      {
        jobUserProfileId: modal.data.selectedEntityData.jobUserProfileId,
        entityId: modal.data.selectedEntityData.id,
      },
      (res) => {
        setModalLoading(false);
        if (res.status) {
          setModal(initModal);
          toast.success("Invited Successfully");
        }
      },
      (err) => {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong!");
      }
    );
  };

  const onClickAction = () => {
    if (modal.type === "shortlist") {
      shortlist();
    } else if (modal.type === "follow") {
      follow();
    } else if (modal.type === "invite") {
      invite();
    }
  };

  return <>
    <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
      <div className="d-flex flex-column-fluid">
        <div className="custom-container" style={{ height: 'auto' }}>
          <div className="inner_txecy">
            <h4>Candidate Details</h4>
          </div>
          <div className="d-flex justify-content-center mb-5">
            <Button className="mx-3" variant={tab === 1 ? "primary" : 'secondary'} onClick={() => setTab(1)}>
              Profile Information
            </Button>
            <Button className="mx-3" variant={tab === 2 ? "primary" : 'secondary'} onClick={() => { setTab(2); getFormFieldsEntity() }}>
              Skill Resume
            </Button>
            <Button className="mx-3" variant={tab === 3 ? "primary" : 'secondary'} onClick={() => { setTab(3); getActiveDaysList() }}>
              Weekly Calendar
            </Button>
            <Button className="mx-3" variant={tab === 4 ? "primary" : 'secondary'} onClick={() => { setTab(4); getDailyCalendar() }}>
              Daily Calendar
            </Button>
          </div>
          <div className="inner_docter custom_dt">
            {loading ?
              <div className="col-md-12 text-center m-2">
                <span className="spinner-grow spinner-border-sm"></span>
              </div>
              : tab === 1 ?
                <>
                  <div className="row">
                    <div className="col-lg-12 col-md-6">
                      <div className="job_detailsContainer">
                        <h3 className="talent_user_name">
                          {
                            profileInfo.userProfileData.selectedEntityData
                              .name
                          }{" "}
                          /{" "}
                          {
                            profileInfo.userProfileData.selectedEntityData
                              .title
                          }
                        </h3>
                        <p className="talent_id">
                          Job ID :{" "}
                          {
                            profileInfo.userProfileData.selectedEntityData
                              .id
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center cust_spc">
                    <div className="col-md-9">
                      <div className="user_details">
                        <div className="circleName">
                          {
                            profileInfo.userProfileData
                              .selectedEntityTypeData
                              .entityTypeNameAbbreviation
                          }
                        </div>
                        <img
                          src={
                            profileInfo.isVerified ? verified : unVerified
                          }
                          alt="verify"
                          style={{
                            position: "absolute",
                            alignSelf: "baseline",
                          }}
                        />
                        <h3 className="talent_user_name">
                          {
                            profileInfo.userProfileData.selectedUserData
                              .firstName
                          }{" "}
                          {
                            profileInfo.userProfileData.selectedUserData
                              .lastName
                          }
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-3 text-right">
                      <img src={goldBadge} alt="badge" />
                    </div>
                  </div>
                  <div className="row cust_spc pt-0">
                    <div className="col-sm-12 col-lg-6">
                      <div className="date_container">
                        <img src={newCalender} alt="calender" />
                        <span className="date_ talent_id ml-2">
                          {getFormatedDate(
                            profileInfo.userProfileData.selectedEntityData
                              .createdDate,
                            "DD MMM YYYY, hh:mm A"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row cust_spc pt-0">
                    <div className="col-sm-12 col-lg-12">
                      <div className="info_container_">
                        <ul className="job_info_list_">
                          <li className="mb-3">
                            <span className="job_infoHeading_ me-25">
                              Employment Type :
                            </span>
                            <p className="details_value_">
                              {" "}
                              {profileInfo.userProfileData.employmentType
                                ?.length
                                ? Array.from(profileInfo.userProfileData.employmentType, x => x.name).join(", ")
                                : "N/A"}
                            </p>
                          </li>
                          <li className="mb-3">
                            <span className="job_infoHeading_ me-25">
                              Payment Type :
                            </span>
                            <p className="details_value_">
                              {" "}
                              {profileInfo.userProfileData.paymentType
                                ?.length
                                ? Array.from(profileInfo.userProfileData.paymentType, x => x.name).join(", ")
                                : "N/A"}
                            </p>
                          </li>
                          <li className="mb-3">
                            <span className="job_infoHeading_ me-25">
                              Payroll Type :
                            </span>
                            <p className="details_value_">
                              {" "}
                              {profileInfo.userProfileData.payrollType
                                ?.length
                                ? Array.from(profileInfo.userProfileData.payrollType, x => x.name).join(", ")
                                : "N/A"}
                            </p>
                          </li>
                          <li className="mb-3">
                            <span className="job_infoHeading_ me-25">
                              Schedule Type :
                            </span>
                            <p className="details_value_">
                              {" "}
                              {profileInfo.userProfileData.scheduleType
                                ?.length
                                ? Array.from(profileInfo.userProfileData.scheduleType, x => x.name).join(", ")
                                : "N/A"}
                            </p>
                          </li>
                          <li className="mb-3">
                            <span className="job_infoHeading_ me-25">
                              Facility Type :
                            </span>
                            <p className="details_value_">
                              {" "}
                              {profileInfo.userProfileData.facilityType
                                ?.length
                                ? Array.from(profileInfo.userProfileData.facilityType, x => x.name).join(", ")
                                : "N/A"}
                            </p>
                          </li>
                          <li className="mb-3">
                            <span className="job_infoHeading_ me-25">
                              Shift Type :
                            </span>
                            <p className="details_value_">
                              {" "}
                              {profileInfo.userProfileData.shiftType
                                ?.length
                                ? Array.from(profileInfo.userProfileData.shiftType, x => x.name).join(", ")
                                : "N/A"}
                            </p>
                          </li>
                          <li className="mb-3">
                            <span className="job_infoHeading_ me-25">
                              Location :
                            </span>
                            <p className="details_value_">
                              {" "}
                              {profileInfo.city}, {profileInfo.state},{" "}
                              {profileInfo.country}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
                : tab === 2 ?
                  <div className="row cust_spc">
                    <div className="col-sm-12">
                      <div className="info_container_">
                        {fieldMetaData.length ? fieldMetaData.map((item) =>
                          <div className="row mb-3">
                            <span className="job_infoHeading_ me-25">
                              {item.field.fieldName} :
                            </span>
                            <p className="details_value_ m-0 ml-2">
                              {" "}
                              {item.field.fieldValue}
                            </p>
                          </div>) : <div className="text-center m-2"><h4>No Data</h4></div>}
                      </div>
                    </div>
                  </div>
                  : tab === 3 ?
                    <div className="row cust_spc">
                      <div className="col-sm-12">
                        <div className="info_container_">
                          {activeDaysListData.length ? activeDaysListData.map((item) =>
                            <div className="row mb-3">
                              <span className="job_infoHeading_ me-25">
                                {item.day.toUpperCase()} :
                              </span>
                              <p className="details_value_ m-0 ml-2">
                                {" "}
                                {item.startHour}:{item.startMinute} {item.shiftStartMeridian.toUpperCase()} - {item.endHour}:{item.endMinute} {item.shiftEndMeridian.toUpperCase()}
                              </p>
                            </div>) : <div className="text-center m-2"><h4>No Data</h4></div>}
                        </div>
                      </div>
                    </div>
                    :
                    <div className="row cust_spc">
                      <div className="col-sm-12">
                        <div className="info_container_">
                          {dailyCalendarData.length ? dailyCalendarData.map((item) =>
                            <div className="mb-3">
                              <span className="job_infoHeading_ me-25">
                                {item.day} :
                              </span>
                              <p className="details_value_">
                                {" "}
                                {item.startHour}:{item.startMinute} {item.shiftStartMeridian} - {item.endHour}:{item.endMinute} {item.shiftEndMeridian}
                              </p>
                            </div>) : <div className="text-center m-2"><h4>No Data</h4></div>}
                        </div>
                      </div>
                    </div>
            }
            <div className="button_list">
              <a
                className="about_btn shift_outer4"
                href="javascript:void(0)"
                style={{ borderRadius: "0px 0px 0px 5px" }}
                onClick={() =>
                  setModal({
                    modal: !modal.modal,
                    type: "shortlist",
                    data: profileInfo.userProfileData,
                  })
                }
              >
                ShortList
              </a>
              <a
                className="about_btn"
                href="javascript:void(0)"
                style={{ borderRadius: "0px" }}
                onClick={() =>
                  setModal({
                    modal: !modal.modal,
                    type: "follow",
                    data: profileInfo.userProfileData,
                  })
                }
              >
                Follow
              </a>
              <a
                className="about_btn shift_outer4"
                href="javascript:void(0)"
                onClick={() =>
                  setModal({
                    modal: !modal.modal,
                    type: "invite",
                    data: profileInfo.userProfileData,
                  })
                }
              >
                Invite
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      show={modal.modal}
      size="md"
      centered
      style={{ background: "rgb(95 95 95 / 77%)" }}
    >
      <div className="text-center m-5">
        <Modal.Title>
          Are you sure, you want to {modal.type}{" "}
          {modal.data?.selectedUserData?.firstName}{" "}
          {modal.data?.selectedUserData?.lastName} ?
        </Modal.Title>
      </div>

      {modalLoading ? (
        <div className="text-center m-5">
          <span className="spinner-border spinner-border-sm"></span>
        </div>
      ) : (
        <div className="text-center m-5">
          <Button
            className="mx-3"
            variant="danger"
            onClick={() => setModal(!modal)}
          >
            No
          </Button>
          <Button className="mx-3" variant="primary" onClick={onClickAction}>
            Yes
          </Button>
        </div>
      )}
    </Modal>
  </>;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  shortlist: bindActionCreators(shortlist, dispatch),
  follow: bindActionCreators(follow, dispatch),
  invite: bindActionCreators(invite, dispatch),
  getFormFieldsEntity: bindActionCreators(getFormFieldsEntity, dispatch),
  getActiveDaysList: bindActionCreators(getActiveDaysList, dispatch),
  getDailyCalendar: bindActionCreators(getDailyCalendar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetail);
