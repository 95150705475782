import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { getAppliedJobs, getFollowedJobs, getInvitedJobs, getShortlistedJobs, unFollow, unInvite, unShortlist } from "../../actions/employee/skill";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import moment from 'moment-timezone';
import { showBadge } from '../../../utilities/helpers';
import newCalender from "../../public/images/newCalender.svg";
import locationImg from "../../public/images/location.png";
import { toast } from 'react-toastify';
import { Button, Modal } from "react-bootstrap";

const JobApplications = (props) => {
    const [loading, setLoading] = useState(true);
    const [filterShow, setFilterShow] = useState(false);
    const initModal = { modal: false, type: "", data: {} };
    const [modal, setModal] = useState(initModal);
    const [modalLoading, setModalLoading] = useState(false);
    const [list, setList] = useState([]);
    const [shortlistData, setShortListData] = useState([]);
    const [inviteData, setInviteData] = useState([]);
    const [followData, setFollowData] = useState([]);
    const [selectedColumn, columnClicked] = useState('myJobApplications');

    const history = useHistory();
    const { userProfileId } = useParams()

    useEffect(() => {
        getAppliedJobs();
    }, [])

    const getAppliedJobs = () => {
        setLoading(true);
        props.getAppliedJobs({ id: userProfileId }, (res) => {
            setLoading(false);
            if (res.status) {
                setList(res.data);
            } else {
                setList([])
            }
        });
    };

    const getShortlistedJobs = () => {
        setLoading(true);
        props.getShortlistedJobs({ id: userProfileId }, (res) => {
            setLoading(false);
            if (res.status) {
                setShortListData(res.data);
            } else {
                setShortListData([])
            }
        });
    };

    const getFollowedJobs = () => {
        setLoading(true);
        props.getFollowedJobs({ id: userProfileId }, (res) => {
            setLoading(false);
            if (res.status) {
                setFollowData(res.data);
            } else {
                setFollowData([])
            }
        });
    };

    const getInvitedJobs = () => {
        setLoading(true);
        props.getInvitedJobs({ id: userProfileId }, (res) => {
            setLoading(false);
            if (res.status) {
                setInviteData(res.data);
            } else {
                setInviteData([])
            }
        });
    };

    const unInvite = () => {
        setModalLoading(true);
        props.unInvite({ id: modal.data.id }, (res) => {
            setModalLoading(false);
            if (res.status) {
                setModal(initModal)
                getInvitedJobs()
            } else {
                toast.error('Something went wrong.')
            }
        });
    };

    const unFollow = () => {
        setModalLoading(true);
        props.unFollow({ id: modal.data.id }, (res) => {
            setModalLoading(false);
            if (res.status) {
                setModal(initModal)
                getFollowedJobs()
            } else {
                toast.error('Something went wrong.')
            }
        });
    };

    const unShortlist = () => {
        setModalLoading(true);
        props.unShortlist({ id: modal.data.id }, (res) => {
            setModalLoading(false);
            if (res.status) {
                setModal(initModal)
                getFollowedJobs()
            } else {
                toast.error('Something went wrong.')
            }
        });
    };

    const onClickAction = () => {
        if (modal.type === "un-shortlist") {
            unShortlist();
        } else if (modal.type === "un-follow") {
            unFollow();
        } else if (modal.type === "un-invite") {
            unInvite();
        }
    };

    // MMMM Do YYYY, h:mm:ss a
    const getFormatedDate = (date, format = 'YYYY-MM-DD, h:mm:ss A', timeZone = '') => {
        if (date && moment(date).isValid()) {
            if (timeZone) {
                return moment(date).format(format).tz(timeZone);
            }
            return moment(date).format(format)
        }
        return false;
    }

    const tabStyleActive = () => {
        return {
            border: '1px solid #3abd00',
            borderRadius: '5px',
            color: '#3abd00'
        }
    }
    const tabStyleInActive = () => {
        return {
            border: '0px',
            borderRadius: '0px',
            color: 'black'
        }
    }

    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
                <div className="d-flex flex-column-fluid">
                    <div className="custom-container" style={{ height: 'auto' }}>
                        <div className="row  pb-5">
                            <div className="col-lg-6 col-md-6">
                                <div className="inner_txecy">
                                    <h4>Job Applications</h4>
                                </div>
                            </div>
                        </div>
                        <div className='row my-5'>
                            <div className='col-12 m-auto'>
                                <button className='btn btn-primary' onClick={() => history.goBack()}><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                            </div>
                        </div>

                        <div className="Profile_form">
                            {/* <div className="row mt-3 mb-3" style={{ padding: '0px' }}>
                                        <div className="col-12">
                                            <div className="talent_details_shift">
                                                <a className={
                                                    filterShow
                                                        ? "panel-heading heading_links_ accordion_title_info_ d-flex justify-content-between"
                                                        : "panel-heading heading_links_ accordion_title_info_ collapsed d-flex justify-content-between"
                                                }
                                                    onClick={() => setFilterShow(!filterShow)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <span style={{ fontWeight: '600', color: '#3abd00' }}>Talent Profile Id : <span style={{ color: 'black' }}>{talentProfileData.id}</span></span>
                                                    <img src={downArrow} />
                                                </a>
                                                <div
                                                    className={
                                                        filterShow ? "collapse show" : "collapse"
                                                    }
                                                    id="acceptance"
                                                >
                                                    <div
                                                        className="card border-0 pt-2"
                                                        style={{
                                                            "max-height": "200px",
                                                            overflow: "auto",
                                                        }}
                                                    >
                                                        <div className='card-body' style={{ paddingTop: '0px' }}>
                                                            <table className='table' style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Business Name</td>
                                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{talentProfileData.entityName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Location</td>
                                                                        <td className='table-shift-td-2'>{talentProfileData.address1 ? talentProfileData.address1 : talentProfileData.address}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Employment Type</td>
                                                                        <td className='table-shift-td-2'>{employmentDataForShift.length > 0 ? employmentDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Facility Type</td>
                                                                        <td className='table-shift-td-2'>{facilityDataForShift.length > 0 ? facilityDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Payment Type</td>
                                                                        <td className='table-shift-td-2'>{paymentDataForShift.length > 0 ? paymentDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Payroll Type</td>
                                                                        <td className='table-shift-td-2'>{payrollDataForShift.length > 0 ? payrollDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Schedule Type</td>
                                                                        <td className='table-shift-td-2'>{scheduleDataForShift.length > 0 ? scheduleDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Shift Type</td>
                                                                        <td className='table-shift-td-2'>{shiftDataForShift.length > 0 ? shiftDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                            <div className="row no-gutters text-center" style={{ marginTop: '40px', marginBottom: '40px' }}>
                                <div className="col" style={selectedColumn == 'myJobApplications' ? tabStyleActive() : tabStyleInActive()} onClick={() => { columnClicked('myJobApplications'); getAppliedJobs() }}>
                                    My Job Applications
                                </div>
                                <div className="col" style={selectedColumn == 'shortlistedJobs' ? tabStyleActive() : tabStyleInActive()} onClick={() => { columnClicked('shortlistedJobs'); getShortlistedJobs() }}>
                                    Shortlisted Jobs
                                </div>
                                <div className="col" style={selectedColumn == 'followingJobs' ? tabStyleActive() : tabStyleInActive()} onClick={() => { columnClicked('followingJobs'); getFollowedJobs() }}>
                                    Following Jobs
                                </div>
                                <div className="col" style={selectedColumn == 'invitedJobs' ? tabStyleActive() : tabStyleInActive()} onClick={() => { columnClicked('invitedJobs'); getInvitedJobs() }}>
                                    Invited Jobs
                                </div>
                            </div>

                            <div className='row'>
                                {loading ?
                                    <div className="row" style={{ width: '100%' }}>
                                        <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', }}>
                                                <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    : selectedColumn == 'myJobApplications' ?
                                        list.length ? list.map((item) => (
                                            <div className="col-md-3 mb-5">
                                                <div className="inner_docter custom_dt">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-6">
                                                            <div className="job_detailsContainer">
                                                                <h3 className="talent_user_name">
                                                                    {item.userProfileData.selectedEntityData.name ? item.userProfileData.selectedEntityData.name : "---"}{" "}
                                                                    /{" "}
                                                                    {item.userProfileData.selectedEntityData.title ? item.userProfileData.selectedEntityData.title : "---"}
                                                                </h3>
                                                                <p className="talent_id">
                                                                    Job ID :{" "}
                                                                    {item.userProfileData.selectedEntityData.id}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center cust_spc">
                                                        <div className="col-md-9">
                                                            <div className="user_details">
                                                                <div className="circleName">
                                                                    {item.userProfileData.selectedEntityTypeData.entityTypeNameAbbreviation}
                                                                </div>
                                                                <img
                                                                    src={
                                                                        item.isVerified ? verified : unVerified
                                                                    }
                                                                    alt="verify"
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: '35px', left: '48px'
                                                                    }}
                                                                />
                                                                <h3 className="talent_user_name">
                                                                    {item.userProfileData.selectedUserData.firstName ? item.userProfileData.selectedUserData.firstName : "---"}
                                                                    {" "}
                                                                    {item.userProfileData.selectedUserData.lastName}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 text-right">
                                                            <img src={showBadge(item.userProfileData.selectedUserDataRating)} alt="badge" />
                                                        </div>
                                                    </div>
                                                    <div className="row cust_spc pt-0">
                                                        <div className="col-sm-12 col-lg-6">
                                                            <div className="date_container">
                                                                <img src={newCalender} alt="calender" />
                                                                <span className="date_ talent_id ml-2">
                                                                    {getFormatedDate(
                                                                        item.userProfileData.selectedEntityData
                                                                            .createdDate,
                                                                        "MMM DD, YYYY"
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row cust_spc pt-0">
                                                        <div className="col-sm-12 col-lg-12">
                                                            <div className="info_container_">
                                                                <ul className="job_info_list_">
                                                                    <li className="mb-3">
                                                                        <span className="job_infoHeading_ me-25">
                                                                            Employment Type :
                                                                        </span>
                                                                        <p className="details_value_">
                                                                            {" "}
                                                                            {item.userProfileData.employmentType
                                                                                ?.length
                                                                                ? Array.from(item.userProfileData.employmentType, x => x.name).join(", ")
                                                                                : "N/A"}
                                                                        </p>
                                                                    </li>
                                                                    <li className="mb-3">
                                                                        <span className="job_infoHeading_ me-25">
                                                                            Payment Type :
                                                                        </span>
                                                                        <p className="details_value_">
                                                                            {" "}
                                                                            {item.userProfileData.paymentType
                                                                                ?.length
                                                                                ? Array.from(item.userProfileData.paymentType, x => x.name).join(", ")
                                                                                : "N/A"}
                                                                        </p>
                                                                    </li>
                                                                    <li className="mb-3">
                                                                        <span className="job_infoHeading_ me-25">
                                                                            Payroll Type :
                                                                        </span>
                                                                        <p className="details_value_">
                                                                            {" "}
                                                                            {item.userProfileData.payrollType
                                                                                ?.length
                                                                                ? Array.from(item.userProfileData.payrollType, x => x.name).join(", ")
                                                                                : "N/A"}
                                                                        </p>
                                                                    </li>
                                                                    <li className="mb-3">
                                                                        <span className="job_infoHeading_ me-25">
                                                                            Schedule Type :
                                                                        </span>
                                                                        <p className="details_value_">
                                                                            {" "}
                                                                            {item.userProfileData.scheduleType
                                                                                ?.length
                                                                                ? Array.from(item.userProfileData.scheduleType, x => x.name).join(", ")
                                                                                : "N/A"}
                                                                        </p>
                                                                    </li>
                                                                    <li className="mb-3">
                                                                        <span className="job_infoHeading_ me-25">
                                                                            Facility Type :
                                                                        </span>
                                                                        <p className="details_value_">
                                                                            {" "}
                                                                            {item.userProfileData.facilityType
                                                                                ?.length
                                                                                ? Array.from(item.userProfileData.facilityType, x => x.name).join(", ")
                                                                                : "N/A"}
                                                                        </p>
                                                                    </li>
                                                                    <li className="mb-3">
                                                                        <span className="job_infoHeading_ me-25">
                                                                            Shift Type :
                                                                        </span>
                                                                        <p className="details_value_">
                                                                            {" "}
                                                                            {item.userProfileData.shiftType
                                                                                ?.length
                                                                                ? Array.from(item.userProfileData.shiftType, x => x.name).join(", ")
                                                                                : "N/A"}
                                                                        </p>
                                                                    </li>
                                                                    <li className="mb-3">
                                                                        <span className="job_infoHeading_ me-25">
                                                                            Location :
                                                                        </span>
                                                                        <p className="details_value_">
                                                                            {" "}
                                                                            {item.city}, {item.state},{" "}
                                                                            {item.country}
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="button_list">
                                                        <a className="about_btn shift_outer4" href="javascript:void(0)"
                                                            onClick={() => { }
                                                                /* setModal({
                                                                    modal: !modal.modal,
                                                                    type: "shortlist",
                                                                    data: item.userProfileData,
                                                                }) */
                                                            }
                                                        >
                                                            Cancel
                                                        </a>
                                                        <a className="about_btn" href="javascript:void(0)"
                                                            onClick={() => { }
                                                                /* setModal({
                                                                    modal: !modal.modal,
                                                                    type: "follow",
                                                                    data: item.userProfileData,
                                                                }) */
                                                            }
                                                        >
                                                            Job Detail
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : <div className="text-center m-5 w-100"><h3>No Result(s) Found</h3></div>
                                        : selectedColumn == 'shortlistedJobs' ?
                                            shortlistData.length ? shortlistData.map((item) => (
                                                <div className="col-md-4 mb-5">
                                                    <div className="blush_outer">
                                                        <div className="geoge_outer">
                                                            <div className="circleName custom789">{item.userProfileData.entityTypeNameAbbreviation ? item.userProfileData.entityTypeNameAbbreviation : "---"}</div>
                                                            <img src={item.userProfileData.isVerified ? verified : unVerified} alt="verify" style={{ position: "absolute", top: '55px', left: '55px' }} />
                                                            <div className="google_inner">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h3 className="talent_user_name">
                                                                        {item.userProfileData.firstName ? item.userProfileData.firstName : "---"}
                                                                        {" "}
                                                                        {item.userProfileData.lastName}
                                                                    </h3>
                                                                    {item.userProfileRating && <img src={showBadge(item.userProfileRating)} alt="badge" className="badgeClass mr-6" />}
                                                                </div>
                                                                <p className="custom_png mt-3"><img className="custom78" src={locationImg} alt="" />{item.userProfileData.city ? item.userProfileData.city + ', ' : '---'} {item.userProfileData.country ? item.userProfileData.country : '---'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="button_list">
                                                            <a className="about_btn shift_outer4" href="javascript:void(0)" onClick={() => setModal({ modal: !modal.modal, type: "un-shortlist", data: item })}>Remove</a>
                                                            <a className="about_btn" href="javascript:void(0)" onClick={() => { }}>Job Detail</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <div className="text-center m-5 w-100"><h3>No Result(s) Found</h3></div>
                                            : selectedColumn == 'followingJobs' ?
                                                followData.length ? followData.map((item) => (
                                                    <div className="col-md-4 mb-5">
                                                        <div className="blush_outer">
                                                            <div className="geoge_outer">
                                                                <div className="circleName custom789">{item.userProfileData.entityTypeNameAbbreviation ? item.userProfileData.entityTypeNameAbbreviation : "---"}</div>
                                                                <img src={item.userProfileData.isVerified ? verified : unVerified} alt="verify" style={{ position: "absolute", top: '55px', left: '55px' }} />
                                                                <div className="google_inner">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h3 className="talent_user_name">
                                                                            {item.userProfileData.firstName ? item.userProfileData.firstName : "---"}
                                                                            {" "}
                                                                            {item.userProfileData.lastName}
                                                                        </h3>
                                                                        <img src={showBadge(item.userProfileRating)} alt="badge" className="badgeClass mr-6" />
                                                                    </div>
                                                                    <p className="custom_png mt-3"><img className="custom78" src={locationImg} alt="" />{item.userProfileData.city ? item.userProfileData.city + ', ' : '---'} {item.userProfileData.country ? item.userProfileData.country : '---'}</p>
                                                                </div>
                                                            </div>
                                                            <div className="button_list">
                                                                <a className="about_btn shift_outer4" href="javascript:void(0)" onClick={() => setModal({ modal: !modal.modal, type: "un-follow", data: item })}>Un-Follow</a>
                                                                <a className="about_btn" href="javascript:void(0)" onClick={() => { }}>Job Detail</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : <div className="text-center m-5 w-100"><h3>No Result(s) Found</h3></div>
                                                : selectedColumn == 'invitedJobs' ?
                                                    inviteData.length ? inviteData.map((item) => (
                                                        <div className="col-md-4 mb-5">
                                                            <div className="blush_outer">
                                                                <div className="geoge_outer">
                                                                    <div className="circleName custom789">{item.userProfileData.entityTypeNameAbbreviation ? item.userProfileData.entityTypeNameAbbreviation : "---"}</div>
                                                                    <img src={item.userProfileData.isVerified ? verified : unVerified} alt="verify" style={{ position: "absolute", top: '55px', left: '55px' }} />
                                                                    <div className="google_inner">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <h3 className="talent_user_name">
                                                                                {item.userProfileData.firstName ? item.userProfileData.firstName : "---"}
                                                                                {" "}
                                                                                {item.userProfileData.lastName}
                                                                            </h3>
                                                                            <img src={showBadge(item.userProfileRating)} alt="badge" className="badgeClass mr-6" />
                                                                        </div>
                                                                        <p className="custom_png mt-3"><img className="custom78" src={locationImg} alt="" />{item.userProfileData.city ? item.userProfileData.city + ', ' : '---'} {item.userProfileData.country ? item.userProfileData.country : '---'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="button_list">
                                                                    <a className="about_btn shift_outer4" href="javascript:void(0)" onClick={() => setModal({ modal: !modal.modal, type: "un-invite", data: item })}>un-invite</a>
                                                                    <a className="about_btn" href="javascript:void(0)" onClick={() => { }}>Job Detail</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : <div className="text-center m-5 w-100"><h3>No Result(s) Found</h3></div>
                                                    : <div className="text-center m-5 w-100"><h3>No Result(s) Found</h3></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={modal.modal}
                size="md"
                centered
                style={{ background: "rgb(95 95 95 / 77%)" }}
            >
                <div className="text-center m-5">
                    <Modal.Title>
                        Are you sure, you want to {modal.type}{" "}
                        {modal.data?.userProfileData?.firstName}{" "}
                        {modal.data?.userProfileData?.lastName} ?
                    </Modal.Title>
                </div>

                {modalLoading ? (
                    <div className="text-center m-5">
                        <span className="spinner-border spinner-border-sm"></span>
                    </div>
                ) : (
                    <div className="text-center m-5">
                        <Button
                            className="mx-3"
                            variant="danger"
                            onClick={() => setModal(initModal)}
                        >
                            No
                        </Button>
                        <Button className="mx-3" variant="primary" onClick={onClickAction}>
                            Yes
                        </Button>
                    </div>
                )}
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    unInvite: bindActionCreators(unInvite, dispatch),
    getInvitedJobs: bindActionCreators(getInvitedJobs, dispatch),
    unFollow: bindActionCreators(unFollow, dispatch),
    getFollowedJobs: bindActionCreators(getFollowedJobs, dispatch),
    unShortlist: bindActionCreators(unShortlist, dispatch),
    getShortlistedJobs: bindActionCreators(getShortlistedJobs, dispatch),
    getAppliedJobs: bindActionCreators(getAppliedJobs, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobApplications);