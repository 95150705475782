import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { getUserTypeList } from "../../actions/user";
import notification from "../../public/images/notification.png";
import profile from "../../public/images/profile.png";
import logo_home from "../../public/images/logo_home.png";
import SignInIcon from "../../public/images/sign_in_icon4.png";
import SignInIcon2 from "../../public/images/sign_in_icon1.png";
import SignInIcon3 from "../../public/images/sign_in_icon.png";
import SignInIcon4 from "../../public/images/sign_in_icon2.png";
import favorite1 from "../../public/images/favroite.svg";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";
import { toast } from "react-toastify";
import { useRef } from "react";

const LandingHeader = (props) => {
  const history = useHistory();
  const divRef = useRef(null);

  const [loginFlag, setLoginFlag] = useState(false);
  const [searchJobFlag, setSearchJobFlag] = useState(false);
  const [searchEmployerFlag, setSearchEmployerFlag] = useState(false);
  const [favJobFlag, setFavJobFlag] = useState(false);

  const [contactUsFlag, setContactUsFlag] = useState(false);
  const [employerFlag, setEmployerFlag] = useState();
  const [employeeFlag, setEmployeeFlag] = useState();
  const [userTypeData, setUserTypeData] = useState([]);
  const [userType, setUserType] = useState();
  const [loading, setLoading] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]);

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: "smooth" });
    if (window.location.pathname.includes("/login") || window.location.pathname.includes("/signup")) {
      setLoginFlag(true);
      setContactUsFlag(false)
    } else if (window.location.pathname === "/search-job") {
      setLoginFlag(false);
      setSearchJobFlag(true);
      setContactUsFlag(false);
      setSearchEmployerFlag(false);
      setFavJobFlag(false);
    } else if (window.location.pathname.includes("/search-job/")) {
      setLoginFlag(false);
      setSearchJobFlag(true);
      setSearchEmployerFlag(false);
      setContactUsFlag(false); 
      setFavJobFlag(false);
    } else if (window.location.pathname.includes("/search-employer/") || window.location.pathname.includes("/search-employer")) {
      setLoginFlag(false);
      setSearchJobFlag(false);
      setSearchEmployerFlag(true);
      setContactUsFlag(false) 
      setFavJobFlag(false);
    } else if (window.location.pathname.includes("/fav-jobs/") || window.location.pathname.includes("/fav-jobs")){
      setLoginFlag(false);
      setSearchJobFlag(false);
      setContactUsFlag(false);
      setSearchEmployerFlag(false);
      setFavJobFlag(true);
    } else if (window.location.pathname.includes("/contactUs") ||window.location.pathname.includes("/privacy_policy") ||window.location.pathname.includes("/terms_n_conditions")||window.location.pathname.includes("/apply-job")) {
      setLoginFlag(false);
      setSearchJobFlag(false);
      setContactUsFlag(true);
      setSearchEmployerFlag(false);
      setFavJobFlag(false);
    } else {
      setLoginFlag(false);
      setSearchJobFlag(false);
      setContactUsFlag(false);
      setSearchEmployerFlag(false);
      setFavJobFlag(false);
    }
    getListOfUserTypes();
    setEmployeeFlag(false);
    setEmployerFlag(false);
    setLoading(false);
  }, [
    window.location.pathname.includes("/search-job"),
    window.location.pathname.includes("/login/"),
    window.location.pathname.includes("/signup/"),
    window.location.pathname.includes("/contactUs"),
    window.location.pathname.includes("/privacy_policy"),
    window.location.pathname.includes("/terms_n_conditions"),
    window.location.pathname.includes("/search-employer"),
    window.location.pathname.includes("/fav-jobs"),
    window.location.pathname === undefined,
    window.location.pathname === '',
  ]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event
          setLoginFlag(false);
          window.location.pathname.includes("/search-job")
            ? setSearchJobFlag(true)
            : setSearchJobFlag(false);
          getListOfUserTypes();
          setEmployeeFlag(false);
          setEmployerFlag(false);
          setLoading(false);
        }
      }
    });
  }, [locationKeys]);

  const getListOfUserTypes = () => {
    props.getUserTypeList({}, (res) => {
      if (res.status) {
        // console.log("User Types", res);
        setUserTypeData(res.data);
      } else {
        setUserTypeData([]);
      }
    });
  };

  const employerActiveStyle = () => {
    return {
      width: "100%",
      height: "170px",
      padding: "27px 20px",
      textAlign: "center",
      borderRadius: "6px",
      border: "1px solid green",
      cursor: "pointer",
    };
  };

  const employerInActiveStyle = () => {
    return {
      width: "100%",
      height: "170px",
      padding: "27px 20px",
      textAlign: "center",
      borderRadius: "6px",
      border: "1px solid gainsboro",
      cursor: "pointer",
    };
  };

  const selectedUser = (e, flag) => {
    e.preventDefault();
    if (flag === "employer") {
      setEmployerFlag(true);
      setEmployeeFlag(false);
      userTypeData.map((user) => {
        if (user.name.toLowerCase() === "employer") {
          setUserType("employer");
        }
      });
    } else if (flag === "employee") {
      setEmployeeFlag(true);
      setEmployerFlag(false);
      userTypeData.map((user) => {
        if (user.name.toLowerCase() === "employee") {
          setUserType("employee");
        }
      });
    }
  };

  const continueToLoginPage = (e) => {
    e.preventDefault();
    if (!employerFlag && !employeeFlag) {
      toast.error("Please select an user type first");
      return;
    }
    setLoginFlag(true);
    setTimeout(() => {
      history.push("/login/" + userType);
      selectedUser(e, userType);
    }, 0);
  };

  const continueToSignupPage = (e) => {
    e.preventDefault();
    if (!employerFlag && !employeeFlag) {
      toast.error("Please select an user type first");
      return;
    }
    setLoginFlag(true);
    setTimeout(() => {
      history.push("/signup/" + userType);
      selectedUser(e, userType);
    }, 0);
  };

  return (
    <>
    <div ref={divRef} />
      <div className="header_landing" id="header_id">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light ">
            <a
              className="navbar-brand"
              href=""
              onClick={(e) => {
                e.preventDefault();
                setLoginFlag(false);
                setEmployerFlag(false);
                setEmployeeFlag(false);
                history.push("/");
              }}
            >
              <img
                src={logo_home}
                alt="logo"
                className="logo_landing"
                width="200px"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </button>
            {!loginFlag && (
              <>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-0 ml-auto">
                    <li
                      className={
                        !loginFlag && !searchJobFlag && !contactUsFlag && !searchEmployerFlag &&!favJobFlag
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <a
                        className="nav-link"
                        href=""
                        style={{ fontSize: "22px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setLoginFlag(false);
                          setSearchJobFlag(false);
                          setEmployerFlag(false);
                          setEmployeeFlag(false);
                          setSearchEmployerFlag(false);
                          history.push("/");
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li
                      className={searchJobFlag ? "nav-item active" : "nav-item"}
                    >
                      <a
                        className="nav-link"
                        href="/"
                        style={{ color: "#F5B800", fontSize: "22px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setLoginFlag(false);
                          setSearchJobFlag(true);
                          setEmployerFlag(false);
                          setEmployeeFlag(false);
                          history.push("/search-job");
                        }}
                      >
                        Search Job
                      </a>
                    </li>
                    <li className={searchEmployerFlag ? "nav-item active mr-5" : "nav-item mr-5"}>
                      <a
                        className="nav-link"
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setLoginFlag(false);
                          setSearchJobFlag(false);
                          setEmployerFlag(false);
                          setEmployeeFlag(false);
                          setSearchEmployerFlag(true);
                          history.push("/search-employer");
                        }}
                        style={{
                          color: "#18AC67",
                          fontSize: "22px",
                          cursor: "pointer",
                        }}
                      >
                        Search Employer
                      </a>
                    </li>
                    {props.auth && props.auth.isLoggedIn ? (
                    <>
                      <li className={favJobFlag ? "favorite_jobs active " : "favorite_jobs"} >
                        <a
                          className="nav-link favorite"
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/fav-jobs");
                          }}
                          
                        >
                          <img src={favorite1} alt="favroite" className="img-fluid"/>
                              <span>My Favourite Jobs</span>
                        </a>
                      </li>
                    </>
                    ):null}
                  </ul>
                  <div className="header_links my-2 my-lg-0">
                    {props.auth && props.auth.isLoggedIn ? (
                      <>
                        <button
                          type="button"
                          className="login-register-landing"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/home");
                          }}
                        >
                          My Account
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="login-register-landing mr-3"
                          data-bs-toggle="modal"
                          data-bs-target="#mySignin"
                        >
                          Login
                        </button>
                        <button
                          type="button"
                          className="login-register-landing"
                          data-bs-toggle="modal"
                          data-bs-target="#mySignup"
                        >
                          Sign Up
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </nav>
        </div>
      </div>

      <div
        className="modal fade modal_apply"
        id="mySignin"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-sm"
          role="document"
          style={{ position: "absolute", right: "0" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times"></i>
              </button>
            </div>

            <div className="modal-body" id="popup_sign_in_inner">
              <div className="hourlyrate">
                <h3>Login</h3>
                {/* <h4> Your Hourly Rate For This Job:</h4> */}
                <h4> Please select the user type for login: </h4>
              </div>
              <div className="row my-5">
                <div
                  className="col-md-6"
                  onClick={(e) => selectedUser(e, "employer")}
                >
                  <div
                    style={
                      employerFlag
                        ? employerActiveStyle()
                        : employerInActiveStyle()
                    }
                  >
                    <div className="popup_sign_in_img">
                      <img
                        src={employerFlag ? SignInIcon2 : SignInIcon}
                        alt=""
                        style={{ width: "74px" }}
                      />
                    </div>
                    <div className="popup_sign_in1">As An Employer </div>
                  </div>
                </div>
                <div
                  className="col-md-6"
                  onClick={(e) => selectedUser(e, "employee")}
                >
                  <div
                    style={
                      employeeFlag
                        ? employerActiveStyle()
                        : employerInActiveStyle()
                    }
                  >
                    <div className="popup_sign_in_img">
                      <img
                        src={employeeFlag ? SignInIcon4 : SignInIcon3}
                        alt=""
                        style={{ width: "80px" }}
                      />
                    </div>
                    <div className="popup_sign_in1">As An Employee </div>
                  </div>
                </div>
              </div>
              <a
                id="myLink"
                className="post_job btn  my-5 my-sm-0 button-5"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ color: "white", position: "relative", top: "25px" }}
                onClick={(e) => {
                  continueToLoginPage(e);
                }}
                disabled={!employerFlag && !employeeFlag ? true : false}
              >
                <div className="eff-5"></div>Continue
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal_apply"
        id="mySignup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-sm"
          role="document"
          style={{ position: "absolute", right: "0" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times"></i>
              </button>
            </div>

            <div className="modal-body" id="popup_sign_in_inner">
              <div className="hourlyrate">
                <h3>Signup</h3>
                {/* <h4> Your Hourly Rate For This Job:</h4> */}
                <h4> Please select the user type for signup: </h4>
              </div>
              <div className="row my-5">
                <div
                  className="col-md-6"
                  onClick={(e) => selectedUser(e, "employer")}
                >
                  <div
                    style={
                      employerFlag
                        ? employerActiveStyle()
                        : employerInActiveStyle()
                    }
                  >
                    <div className="popup_sign_in_img">
                      <img
                        src={employerFlag ? SignInIcon2 : SignInIcon}
                        alt=""
                        style={{ width: "74px" }}
                      />
                    </div>
                    <div className="popup_sign_in1">As An Employer </div>
                  </div>
                </div>
                <div
                  className="col-md-6"
                  onClick={(e) => selectedUser(e, "employee")}
                >
                  <div
                    style={
                      employeeFlag
                        ? employerActiveStyle()
                        : employerInActiveStyle()
                    }
                  >
                    <div className="popup_sign_in_img">
                      <img
                        src={employeeFlag ? SignInIcon4 : SignInIcon3}
                        alt=""
                        style={{ width: "80px" }}
                      />
                    </div>
                    <div className="popup_sign_in1">As An Employee </div>
                  </div>
                </div>
              </div>
              <a
                id="myLink"
                className="post_job btn  my-5 my-sm-0 button-5"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ color: "white", position: "relative", top: "25px" }}
                onClick={(e) => {
                  continueToSignupPage(e);
                }}
                disabled={!employerFlag && !employeeFlag ? true : false}
              >
                <div className="eff-5"></div>Continue
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getUserTypeList: bindActionCreators(getUserTypeList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingHeader);
