import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from "redux";
import moment from 'moment';
import { getTalentProfile } from "../../actions/employer/talent";
import { getJobList, getSkillBio, getFieldValuesForEditing, editJob, getJobDetails, getFormFieldsEntity, getFormFieldsForPostJob } from "../../actions/employer/post";
import { getFormFieldsPrespective } from "../../actions/employee/skill";
import insta from '../../public/images/insta.svg';
import talent_edit from '../../public/images/talent_edit.png';
import list1 from '../../public/images/list1.png';
import location from '../../public/images/location.png';
import downArrow from "../../public/images/downArrow.svg";
import { Button, Modal } from "react-bootstrap";
import inputFieldGenerator from "../post/fieldGenerator";
import swal from 'sweetalert';
import { toast } from "react-toastify";


const JobList = (props) => {

    const [loading, setLoading] = useState(true);
    const [closeEditDisabled, setCloseEditDisabled] = useState(false)
    const [editDisabled, setEditDisabled] = useState(false)
    const [talentProfileData, setTalentProfileData] = useState("");
    const [entityId, setEntityId] = useState();
    const [employmentDataForShift, setEmploymentDataForShift] = useState([]);
    const [scheduleDataForShift, setScheduleDataForShift] = useState([]);
    const [facilityDataForShift, setFacilityDataForShift] = useState([]);
    const [payrollDataForShift, setPayrollDataForShift] = useState([]);
    const [shiftDataForShift, setShiftDataForShift] = useState([]);
    const [paymentDataForShift, setPaymentDataForShift] = useState([]);
    const [jobListData, setJobListData] = useState([]);
    const [talentDataColA, setTalentDataColA] = useState([]);
    const [talentDataColB, setTalentDataColB] = useState([]);
    const [filterShow, setFilterShow] = useState(false);
    const [filterShowEdit, setFilterShowEdit] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [gettingField, setGettingField] = useState(false);
    const [fieldValues, setFieldValues] = useState({});
    const [fieldMetaData, setFieldMetaData] = useState(null);

    const history = useHistory();

    useEffect(() => {
        getTalentProfileData({ userProfileId: props.match.params.talentId })
        getJobListData({ userProfileId: props.match.params.talentId, entityTypeId: props.match.params.entityTypeId, userIdHash: props.auth.userProfile.userIdHash })
    }, [])

    const getTalentProfileData = (params) => {
        props.getTalentProfile(params, res => {
            if (res.status) {
                setTalentProfileData(res.data)

                if (res.data.userProfileDataTypes.employmentType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.employmentType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.employmentType[i].name)
                    }
                    setEmploymentDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.facilityType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.facilityType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.facilityType[i].name)
                    }
                    setFacilityDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.paymentType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.paymentType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.paymentType[i].name)
                    }
                    setPaymentDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.scheduleType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.scheduleType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.scheduleType[i].name)
                    }
                    setScheduleDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.shiftType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.shiftType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.shiftType[i].name)
                    }
                    setShiftDataForShift(arr)
                }
                if (res.data.userProfileDataTypes.payrollType.length > 0) {
                    let arr = []
                    for (let i = 0; i < res.data.userProfileDataTypes.payrollType.length; i++) {
                        arr.push(res.data.userProfileDataTypes.payrollType[i].name)
                    }
                    setPayrollDataForShift(arr)
                }
            } else {

            }
        })
    }

    const getJobListData = (params) => {
        props.getJobList(params, res => {
            if (res.status) {
                if (res.data.result) {
                    let result = []
                    let dates = []

                    for (let i in res.data.result) {
                        result.push([i, res.data.result[i]]);
                    }

                    for (let i in res.data.resultDates) {
                        dates.push([i, res.data.resultDates[i]]);
                    }

                    if (result.length > 0) {
                        let division = result.length / 2
                        let colA = []
                        let colB = []
                        let colA_Array = []
                        let colB_Array = []
                        let colA_DateArray = []
                        let colB_DateArray = []

                        for (let i = 0; i < Math.ceil(division); i++) {
                            let obj = {}
                            obj.id = result[i][0]
                            obj.data = result[i][1]
                            // colA_Array.push(result[i][1])
                            colA_Array.push(obj)
                            colA_DateArray.push(dates[i])
                        }

                        for (let i = 0; i < colA_Array.length; i++) {
                            let a = i
                            let obj = {}
                            obj.date = {
                                createdDate: (colA_DateArray[i][1].createdDate).substring(0, (colA_DateArray[i][1].createdDate).length - 10).toString(),
                                updatedDate: (colA_DateArray[i][1].updatedDate).substring(0, (colA_DateArray[i][1].updatedDate).length - 10).toString()
                            }

                            for (let key in colA_Array[i].data) {
                                for (let key1 in colA_Array[i].data[key]) {
                                    obj[key1] = colA_Array[i].data[key][key1]
                                    obj.id = colA_Array[i].id
                                }
                            }

                            colA.push(obj)
                            if (a == colA_Array.length - 1) {
                                setTalentDataColA(colA)
                            }
                        }

                        for (let i = Math.ceil(division); i < result.length; i++) {
                            let obj = {}
                            obj.id = result[i][0]
                            obj.data = result[i][1]
                            // colB_Array.push(result[i][1])
                            colB_Array.push(obj)
                            colB_DateArray.push(dates[i])
                        }

                        for (let i = 0; i < colB_Array.length; i++) {
                            let a = i
                            let obj = {}
                            obj.date = {
                                createdDate: (colB_DateArray[i][1].createdDate).substring(0, (colB_DateArray[i][1].createdDate).length - 10).toString(),
                                updatedDate: (colB_DateArray[i][1].updatedDate).substring(0, (colB_DateArray[i][1].updatedDate).length - 10).toString()
                            }
                            for (let key in colB_Array[i].data) {
                                for (let key1 in colB_Array[i].data[key]) {
                                    obj[key1] = colB_Array[i].data[key][key1]
                                    obj.id = colB_Array[i].id
                                }
                            }

                            colB.push(obj)
                            if (a == colB_Array.length - 1) {
                                setTalentDataColB(colB)
                            }
                        }

                        setJobListData(result)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                } else {
                    setLoading(false)
                }

            }
        })
    }

    const changeDateFormat = (str) => {
        let a = str
        let date = str.substring(0, str.length - 9)
        let time = a.substring(11, a.length - 3)
        let result = date + ', ' + time
        return result
    }

    const onInputChange = (fieldName, value, e) => {
        setEditDisabled(false)
        setFieldValues(() => ({
            ...fieldValues,
            [fieldName]: value,
        }));
    };

    const fields = [];
    const renderedFields = {};

    const renderField = (fieldMetaData, parentFieldValue) => {
        if (!Array.isArray(fieldMetaData)) return null;
        fieldMetaData?.map(({ field, childrenFields }, index) => {
            if (
                parentFieldValue !== undefined &&
                renderedFields[field?.entityAttributeFieldKey] !== undefined
            ) {
                fields[renderedFields[field?.entityAttributeFieldKey]] = (
                    <div className="col-md-12 mb-3">
                        {inputFieldGenerator(
                            field,
                            fieldValues[field?.entityAttributeFieldKey],
                            onInputChange,
                            parentFieldValue,
                            props
                        )}
                    </div>
                );
            }

            if (renderedFields[field?.entityAttributeFieldKey] === undefined) {
                renderedFields[field?.entityAttributeFieldKey] = fields.length;
                fields.push(
                    <div className="col-md-12 mb-3">
                        {inputFieldGenerator(
                            field,
                            fieldValues[field?.entityAttributeFieldKey],
                            onInputChange,
                            parentFieldValue,
                            props
                        )}
                    </div>
                );
            }

            if (childrenFields) {
                renderField(
                    childrenFields[0],
                    fieldValues[field?.entityAttributeFieldKey]
                );
            }
        });
    };

    const fieldElements = useMemo(() => {
        if (fieldMetaData) {
            renderField(fieldMetaData);
            return fields;
        } else return [];
    }, [fieldMetaData, fieldValues]);

    const getSkillBio = () => {
        setGettingField(true);
        props.getSkillBio(
            { userIdHash: props.auth.userProfile.userIdHash, userProfileId: props.match.params.talentId },
            (res) => {
                if (res.status) {
                    if (res.data.entityInfo) {
                        getFormFieldsEntity(res.data.entityInfo.id);
                    } else {
                        getFormFieldsPrespective({
                            perspectiveId: res.data.perspectiveInfo.id,
                            userProfileId: props.match.params.talentId,
                        });
                    }
                }
            }
        );
    };

    const getFormFieldsPrespective = (data) => {
        setGettingField(true);
        props.getFormFieldsPrespective(
            { perspectiveId: data.perspectiveId, userProfileId: data.userProfileId },
            (res) => {
                setGettingField(false);
                if (res.status) {
                    setFieldMetaData(res.data);
                }
            }
        );
    };

    const getFormFieldsEntity = (id) => {
        setGettingField(true);
        props.getFormFieldsEntity({ entityId: id }, (res) => {
            setGettingField(false);
            if (res.status) {
                setFieldMetaData(res.data);
            }
        });
    };

    const openEditModal = (e, value) => {
        e.preventDefault()
        setEditModal(true)
        setEntityId(value.id)
        getFieldValuesForEditingFunction(value.id)
    }

    const getFieldValuesForEditingFunction = (entityId) => {
        setGettingField(true);
        props.getFieldValuesForEditing({ entityId: entityId }, res => {
            setGettingField(false);
            if (res.status) {
                setFieldMetaData(res.data);
                if (res.data.length) {
                    let obj = {}
                    res.data.map(r => {
                        obj[r.field.entityAttributeFieldKey] = r.field.fieldValue
                    })
                    setFieldValues(obj)
                }
            }
        })
    }

    const closeEditModal = (e) => {
        e.preventDefault()
        setEditModal(false)
        setFieldMetaData();
        setEntityId()
    }

    const editJobFn = (value) => {
        setGettingField(true);
        setEditDisabled(true)
        setCloseEditDisabled(true)
        props.editJob(
            {
                entityId: entityId,
                userIdHash: props.auth.userProfile.userIdHash,
                userProfileId: props.match.params.talentId,
                data: { data: JSON.stringify(fieldValues) },
            },
            (res) => {
                setGettingField(false);
                if (res.status) {
                    setFieldMetaData(null);
                    setFieldValues(null);
                    setEditModal(!editModal);
                    swal({
                        title: "Job updated successfully",
                        text: "",
                        icon: "success"
                    }).then(() => {
                        setLoading(true)
                        setFieldValues({})
                        setFieldMetaData(null)
                        setEditDisabled(true)
                        setCloseEditDisabled(false)
                        getJobListData({ userProfileId: props.match.params.talentId, entityTypeId: props.match.params.entityTypeId, userIdHash: props.auth.userProfile.userIdHash })
                    })
                } else {
                    if (res.statusCode == 400) {
                        toast.error("Required fields cannot be empty");
                        setEditDisabled(false)
                        setCloseEditDisabled(false)
                    } else {
                        toast.error("Something went wrong!");
                        setEditDisabled(false)
                        setCloseEditDisabled(false)
                    }
                }
            },
            (err) => {
                toast.error("Something went wrong!");
                setGettingField(false);
                setEditDisabled(false)
                setCloseEditDisabled(false)
            }
        );
    };

    const openJobDetailsPage = (e, value) => {
        e.preventDefault()
        history.push(`/jobDetails/${props.match.params.talentId}/${value.id}/${props.match.params.entityTypeId}`)
    }

    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
                <div className="d-flex flex-column-fluid">
                    <div className="custom-container" style={{ height: 'auto' }}>
                        <div className="row  pb-5">
                            <div className="col-lg-6 col-md-6">
                                <div className="inner_txecy">
                                    <h4>List of Posted Jobs</h4>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                {/* <div className="talen_outre">
                                    <button className="btn" data-toggle="modal" data-target="#myModal12">+ CREATE TALENT</button>
                                </div> */}

                            </div>
                        </div>

                        <div className='row my-5'>
                            <div className='col-12 m-auto'>
                                <button className='btn btn-primary' onClick={() => history.push('/post')}><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                            </div>
                        </div>

                        <div className="Profile_form">
                            {loading ?
                                (<>
                                    <div className="row" style={{ width: '100%', height: '30vh' }}>
                                        <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
                                                <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </>) :
                                (<>
                                    <div className="row mt-3 mb-3" style={{ padding: '0px' }}>
                                        <div className="col-12">
                                            <div className="talent_details_shift">
                                                <a className={
                                                    filterShow
                                                        ? "panel-heading heading_links_ accordion_title_info_ d-flex justify-content-between"
                                                        : "panel-heading heading_links_ accordion_title_info_ collapsed d-flex justify-content-between"
                                                }
                                                    onClick={() => setFilterShow(!filterShow)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <span style={{ fontWeight: '600', color: '#3abd00' }}>Talent Profile Id : <span style={{ color: 'black' }}>{talentProfileData.id}</span></span>
                                                    <img src={downArrow} />
                                                </a>
                                                <div
                                                    className={
                                                        filterShow ? "collapse show" : "collapse"
                                                    }
                                                    id="acceptance"
                                                >
                                                    <div
                                                        className="card border-0 pt-2"
                                                        style={{
                                                            "max-height": "200px",
                                                            overflow: "auto",
                                                        }}
                                                    >
                                                        <div className='card-body' style={{ paddingTop: '0px' }}>
                                                            <table className='table' style={{ width: '100%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='table-shift-td-1' style={{ border: 'none' }}>Business Name</td>
                                                                        <td className='table-shift-td-2' style={{ border: 'none' }}>{talentProfileData.entityName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Location</td>
                                                                        <td className='table-shift-td-2'>{talentProfileData.address1 ? talentProfileData.address1 : talentProfileData.address}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Employment Type</td>
                                                                        <td className='table-shift-td-2'>{employmentDataForShift.length > 0 ? employmentDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Facility Type</td>
                                                                        <td className='table-shift-td-2'>{facilityDataForShift.length > 0 ? facilityDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Payment Type</td>
                                                                        <td className='table-shift-td-2'>{paymentDataForShift.length > 0 ? paymentDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Payroll Type</td>
                                                                        <td className='table-shift-td-2'>{payrollDataForShift.length > 0 ? payrollDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Schedule Type</td>
                                                                        <td className='table-shift-td-2'>{scheduleDataForShift.length > 0 ? scheduleDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='table-shift-td-1'>Shift Type</td>
                                                                        <td className='table-shift-td-2'>{shiftDataForShift.length > 0 ? shiftDataForShift.join(', ') : '-'}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {jobListData.length == 0 ? (<><div className='row h-100 w-100'><div className='col-12 text-center m-auto' style={{ position: 'relative', left: '8px' }}><b>No Jobs Found For the Talent</b></div></div></>) :
                                        (<>
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        {talentDataColA.map((value, index) => {
                                                            return (
                                                                <>
                                                                    <div className='col-12 my-5'>
                                                                        <div className="blush_outer">
                                                                            <div className="edit_outer">
                                                                                <a style={{ cursor: 'pointer' }} onClick={(e) => { openEditModal(e, value) }}><img src={talent_edit} alt="" /></a>
                                                                            </div>
                                                                            <div className="geoge_outer">
                                                                                <div className="google_inner" style={{ paddingLeft: '10px' }}>
                                                                                    <table className='table' style={{ width: '100%' }}>
                                                                                        <tbody>
                                                                                            {value.title && (
                                                                                                <tr>
                                                                                                    <td className='table-shift-td-1' style={{ border: 'none', width: '20%' }}>Title</td>
                                                                                                    <td className='table-shift-td-2' style={{ border: 'none' }}>{value.title}</td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {value.pay_salary && (
                                                                                                <tr>
                                                                                                    <td className='table-shift-td-1' style={{ border: 'none', width: '20%' }}>Pay / Salary<span>($)</span></td>
                                                                                                    <td className='table-shift-td-2' style={{ border: 'none' }}>{value.pay_salary}</td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {value.date && (
                                                                                                <tr>
                                                                                                    {value.date.createdDate && (
                                                                                                        <>
                                                                                                            <td className='table-shift-td-1' style={{ border: 'none', width: '20%' }}>Created At</td>
                                                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{changeDateFormat(value.date.createdDate)}</td>
                                                                                                        </>
                                                                                                    )}

                                                                                                </tr>
                                                                                            )}
                                                                                            {value.date && (
                                                                                                <tr>
                                                                                                    {value.date.updatedDate && (
                                                                                                        <>
                                                                                                            <td className='table-shift-td-1' style={{ border: 'none', width: '20%' }}>Last Updated At</td>
                                                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{changeDateFormat(value.date.updatedDate)}</td>
                                                                                                        </>
                                                                                                    )}

                                                                                                </tr>
                                                                                            )}
                                                                                        </tbody>
                                                                                    </table>

                                                                                </div>
                                                                            </div>
                                                                            <div className="button_list">
                                                                                <a className="about_btn" style={{ color: 'white', cursor: 'pointer', width: '100%', background: '#01884a' }} onClick={(e)=>openJobDetailsPage(e, value)}><i className="fa fa-eye" aria-hidden="true"></i> View</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        {talentDataColB.map((value, index) => {
                                                            return (
                                                                <>
                                                                    <div className='col-12 my-5'>
                                                                        <div className="blush_outer">
                                                                            <div className="edit_outer">
                                                                                <a style={{ cursor: 'pointer' }} onClick={(e) => { openEditModal(e, value) }}><img src={talent_edit} alt="" /></a>
                                                                            </div>
                                                                            <div className="geoge_outer">
                                                                                <div className="google_inner" style={{ paddingLeft: '10px' }}>
                                                                                    <table className='table' style={{ width: '100%' }}>
                                                                                        <tbody>
                                                                                            {value.title && (
                                                                                                <tr>
                                                                                                    <td className='table-shift-td-1' style={{ border: 'none', width: '20%' }}>Title</td>
                                                                                                    <td className='table-shift-td-2' style={{ border: 'none' }}>{value.title}</td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {value.pay_salary && (
                                                                                                <tr>
                                                                                                    <td className='table-shift-td-1' style={{ border: 'none', width: '20%' }}>Pay / Salary<span>($)</span></td>
                                                                                                    <td className='table-shift-td-2' style={{ border: 'none' }}>{value.pay_salary}</td>
                                                                                                </tr>
                                                                                            )}
                                                                                            {value.date && (
                                                                                                <tr>
                                                                                                    {value.date.createdDate && (
                                                                                                        <>
                                                                                                            <td className='table-shift-td-1' style={{ border: 'none', width: '20%' }}>Created At</td>
                                                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{changeDateFormat(value.date.createdDate)}</td>
                                                                                                        </>
                                                                                                    )}

                                                                                                </tr>
                                                                                            )}
                                                                                            {value.date && (
                                                                                                <tr>
                                                                                                    {value.date.updatedDate && (
                                                                                                        <>
                                                                                                            <td className='table-shift-td-1' style={{ border: 'none', width: '20%' }}>Last Updated At</td>
                                                                                                            <td className='table-shift-td-2' style={{ border: 'none' }}>{changeDateFormat(value.date.updatedDate)}</td>
                                                                                                        </>
                                                                                                    )}

                                                                                                </tr>
                                                                                            )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                            <div className="button_list">
                                                                                <a className="about_btn" style={{ color: 'white', cursor: 'pointer', width: '100%', background: '#01884a' }} onClick={(e)=>openJobDetailsPage(e, value)}><i className="fa fa-eye" aria-hidden="true"></i> View</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </>)}
                                    <Modal
                                        show={editModal}
                                        size="md"
                                        centered
                                        style={{ background: "rgb(95 95 95 / 77%)" }}
                                    >
                                        <div className="text-center m-5">
                                            <div className='text-center m-5'>
                                                <Modal.Title><b>Update Your Job</b></Modal.Title>
                                            </div>
                                        </div>

                                        {gettingField ? (
                                            <div className="row" style={{ width: '100%', height: '30vh' }}>
                                                <div className="col-12" style={{ width: '100%', height: '100%' }}>
                                                    <div style={{ width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', lineHeight: '25vh' }}>
                                                        <div className="spinner-grow" role="status" style={{ background: '#006838' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="row mt-3 mb-3" style={{ padding: '15px' }}>
                                                    <div className="col-12">
                                                        <div className="talent_details_shift">
                                                            <a className={
                                                                filterShowEdit
                                                                    ? "panel-heading heading_links_ accordion_title_info_ d-flex justify-content-between"
                                                                    : "panel-heading heading_links_ accordion_title_info_ collapsed d-flex justify-content-between"
                                                            }
                                                                onClick={() => setFilterShowEdit(!filterShowEdit)}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <span style={{ fontWeight: '600', color: '#3abd00' }}>Talent Profile Id : <span style={{ color: 'black' }}>{props.match.params.talentId}</span></span>
                                                                <img src={downArrow} />
                                                            </a>
                                                            <div
                                                                className={
                                                                    filterShowEdit ? "collapse show" : "collapse"
                                                                }
                                                                id="acceptance"
                                                            >
                                                                <div
                                                                    className="card border-0 pt-2"
                                                                    style={{
                                                                        "max-height": "200px",
                                                                        overflow: "auto",
                                                                    }}
                                                                >
                                                                    <div className='card-body' style={{ paddingTop: '0px' }}>
                                                                        <table className='table' style={{ width: '100%' }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className='table-shift-td-1' style={{ border: 'none' }}>Business Name</td>
                                                                                    <td className='table-shift-td-2' style={{ border: 'none' }}>{talentProfileData.entityName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='table-shift-td-1'>Location</td>
                                                                                    <td className='table-shift-td-2'>{talentProfileData.address1 ? talentProfileData.address1 : talentProfileData.address}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='table-shift-td-1'>Employment Type</td>
                                                                                    <td className='table-shift-td-2'>{employmentDataForShift.length > 0 ? employmentDataForShift.join(', ') : '-'}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='table-shift-td-1'>Facility Type</td>
                                                                                    <td className='table-shift-td-2'>{facilityDataForShift.length > 0 ? facilityDataForShift.join(', ') : '-'}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='table-shift-td-1'>Payment Type</td>
                                                                                    <td className='table-shift-td-2'>{paymentDataForShift.length > 0 ? paymentDataForShift.join(', ') : '-'}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='table-shift-td-1'>Payroll Type</td>
                                                                                    <td className='table-shift-td-2'>{payrollDataForShift.length > 0 ? payrollDataForShift.join(', ') : '-'}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='table-shift-td-1'>Schedule Type</td>
                                                                                    <td className='table-shift-td-2'>{scheduleDataForShift.length > 0 ? scheduleDataForShift.join(', ') : '-'}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className='table-shift-td-1'>Shift Type</td>
                                                                                    <td className='table-shift-td-2'>{shiftDataForShift.length > 0 ? shiftDataForShift.join(', ') : '-'}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mx-2">{fieldElements}</div>

                                            </>

                                        )}
                                        <div className="text-center m-5">
                                            <Button
                                                className="mx-3"
                                                variant="danger"
                                                onClick={closeEditModal}
                                                disabled={closeEditDisabled}
                                            >
                                                Close
                                            </Button>
                                            <Button className="mx-3" variant="primary" onClick={editJobFn} disabled={editDisabled}>
                                                Update
                                            </Button>
                                        </div>
                                    </Modal>

                                    <div className='row my-5'>
                                        <div className='col-12 text-center m-auto'>
                                            <button className='btn btn-primary' onClick={() => history.push('/post')}>Back To Post</button>
                                        </div>
                                    </div>
                                </>)}

                        </div>


                    </div>

                </div>

            </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getTalentProfile: bindActionCreators(getTalentProfile, dispatch),
    getJobList: bindActionCreators(getJobList, dispatch),
    getFormFieldsForPostJob: bindActionCreators(getFormFieldsForPostJob, dispatch),
    getSkillBio: bindActionCreators(getSkillBio, dispatch),
    getFormFieldsEntity: bindActionCreators(getFormFieldsEntity, dispatch),
    getFormFieldsPrespective: bindActionCreators(getFormFieldsPrespective, dispatch),
    getFieldValuesForEditing: bindActionCreators(getFieldValuesForEditing, dispatch),
    editJob: bindActionCreators(editJob, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobList);