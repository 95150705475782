import RestClient from "../../utilities/RestClient";
import message from "../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../config";


export const getJobDetailsLanding = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/main/jobDetail?entityId=${params.entityId}&userProfileId=${params.userProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};



export const addToFavourite = (params, cb) => {
  return dispatch => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/main/addFavorite/${params.userIdHash}?entityId=${params.entityId}`,
     params.data, 
     true)
    .then((result) => {
      if (result && result.status !== 400) {
        let res = {
          status: true,
          message: result.message ? result.message : message.success,
          statusCode: result.status,
          type: message.success,
          data: result.data,
        };
        cb(res);
      } else {
        let res = {
          status: false,
          message: result.message ? result.message : message.commonError,
          type: message.error,
        };
        cb(res);
      }
    })
    .catch((error) => {
      let res = {
        status: false,
        message: message.commonError,
        type: message.error,
      };
      cb(res);
    });
  };
}

export const removeFromFavouriteList = (params, cb) => {
  return dispatch => {
    RestClient.delete(`${EXPRESS_MIDDLEWARE_API_URL}/main/removeFavorite/${params.userIdHash}?entityId=${params.entityId}`,
     params.data, 
     true)
    .then((result) => {
      if (result && result.status !== 400) {
        let res = {
          status: true,
          message: result.message ? result.message : message.success,
          statusCode: result.status,
          type: message.success,
          data: result.data,
        };
        cb(res);
      } else {
        let res = {
          status: false,
          message: result.message ? result.message : message.commonError,
          type: message.error,
        };
        cb(res);
      }
    })
    .catch((error) => {
      let res = {
        status: false,
        message: message.commonError,
        type: message.error,
      };
      cb(res);
    });
  };
}

export const getUserFavouriteJobList = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/main/jobsFavorite?userIdHash=${params.userIdHash}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getUserProfileList = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/userProfile/list?userIdHash=${params.userIdHash}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const shortlist = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/shortlist/${params.jobUserProfileId}/${params.providerUserProfileId}?entityId=${params.entityId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status > 200 && result.status < 205 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const follow = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/follow/${params.jobUserProfileId}/${params.providerUserProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status > 200 && result.status < 205 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const accept = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/apply/open/${params.applyIdHash}?userIdHash=${params.userIdHash}&value=1`,
      {},
      true
    )
      .then((result) => {
        if (result === true) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const reject = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/apply/open/${params.applyIdHash}?userIdHash=${params.userIdHash}&value=0`,
      {},
      true
    )
      .then((result) => {
        if (result === true) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getUserTypeInfo = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/userType/${params.userType}/info`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getFollowingListForAll = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/followNetwork/${params.userIdHash}/following?userTypeId=${params.userTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getFollowingListForEntity = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/followNetwork/${params.userIdHash}/following?entityTypeId=${params.entityTypeId}&userTypeId=${params.userTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getFollowersListForAll = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/followNetwork/${params.userIdHash}/follower?userTypeId=${params.userTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getFollowersListForEntity = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/followNetwork/${params.userIdHash}/follower?entityTypeId=${params.entityTypeId}&userTypeId=${params.userTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const unfollow = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/followNetwork/${params.userIdHash}/unfollow?followIdHash=${params.followIdHash}`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSpaceDataForProfessionalsForAll = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/list?userTypeId=${params.userTypeId}&limit=10`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSpaceDataForProfessionalsForEntityId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/list?entityTypeId=${params.entityTypeId}&userTypeId=${params.userTypeId}&limit=10`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSpaceDataForCompaniesForAll = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/list?userTypeId=${params.userTypeId}&limit=10`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSpaceDataForCompaniesForEntityId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/list?entityTypeId=${params.entityTypeId}&userTypeId=${params.userTypeId}&limit=10`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const followFromSpaces = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/followNetwork/${params.userIdHash}?entityTypeId=${params.entityTypeId}&userNameHash=${params.userNameHash}`,
      {},
      true
    )
      .then((result) => {
        if (result.status > 200 && result.status < 205 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSpaceDataForMeForAll = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/me`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSpaceDataForMeForEntityId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/me?entityTypeId=${params.entityTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSpaceCategories = (params, cb) => {
  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/spaceType/actives`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const createSpacePost = (params, cb) => {
  return (dispatch) => {
    let payload = {
      title: params.title,
      description: params.description,
      spaceTypeId: params.spaceTypeId,
    };
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}?entityTypeId=${params.entityId}`,
      payload,
      true
    )
      .then((result) => {
        if (result.status == 202) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const deleteSpacePost = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(
      `${EXPRESS_MIDDLEWARE_API_URL}/space?spaceIdHash=${params.spaceIdHash}`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const updateSpacePost = (params, cb) => {
  return (dispatch) => {
    let payload = {
      title: params.title,
      description: params.description,
      spaceTypeId: params.spaceTypeId,
    };
    RestClient.put(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/${params.spaceIdHash}`,
      payload,
      true
    )
      .then((result) => {
        if (result.spaceIdHash) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSpacesDataFromFollowingNetwork = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/followNetwork?followIdHash=${params.followIdHash}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getStatusUpdateDataForUsers = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/onMyMind/users?limit=${params.limit}&userTypeId=${params.userTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getStatusUpdateDataForUsersForEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/onMyMind/users?limit=${params.limit}&userTypeId=${params.userTypeId}&entityTypeId=${params.entityTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getStatusUpdateDataForMe = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/onMyMind/me?limit=${params.limit}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getStatusUpdateDataForMeForEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}/onMyMind/me?limit=${params.limit}&entityTypeId=${params.entityTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const createWhatsOnMyMindStatus = (params, cb) => {
  return (dispatch) => {
    let payload = {
      description: params.description,
    };
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/space/${params.userIdHash}?entityTypeId=${params.entityId}&isOnMyMind=1`,
      payload,
      true
    )
      .then((result) => {
        if (result.status == 202) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getReadInboxMessagesForAll = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/inboxMessages?userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}&sortOldest=${params.sortOldest}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getReadInboxMessagesForEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/inboxMessages?entityTypeId=${params.entityTypeId}&userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}&sortOldest=${params.sortOldest}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getNewInboxMessagesForAll = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/newMessages?userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}&sortOldest=${params.sortOldest}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getNewInboxMessagesForEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/newMessages?entityTypeId=${params.entityTypeId}&userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}&sortOldest=${params.sortOldest}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getDeletedInboxMessagesForAll = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/deletedMessage?userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}&sortOldest=${params.sortOldest}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getDeletedInboxMessagesForEntityTypeId = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/deletedMessage?entityTypeId=${params.entityTypeId}&userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}&sortOldest=${params.sortOldest}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const deleteMessageFromInbox = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/delete/action?messageIdHash=${params.messageIdHash}&userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        if (result == true) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const deleteMessageThreadFromInbox = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/delete/thread?messageInstanceId=${params.messageInstanceId}&userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        if (result == true) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const restoreMessageThreadFromArchived = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/delete/thread?messageInstanceId=${params.messageInstanceId}&userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        if (result == true) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const restoreMessageToInboxFromArchived = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/delete/action?messageIdHash=${params.messageIdHash}&userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
      .then((result) => {
        if (result == true) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const sendNewMessage = (params, cb) => {
  return (dispatch) => {
    let payload = {
      subject: params.subject,
      message: params.message,
    };
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/message?followIdHash=${params.followIdHash}&userIdHash=${params.userIdHash}`,
      payload,
      true
    )
      .then((result) => {
        if (result.status == 202 || result.status == 201) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getListOfMessagesForMessageThread = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/messageThread/?messageInstanceId=${params.messageInstanceId}&userIdHash=${params.userIdHash}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const replyToMessage = (params, cb) => {
  return (dispatch) => {
    let payload = {
      message: params.message,
      subject: params.subject,
    };
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/message?replyMessageIdHash=${params.replyMessageIdHash}&userIdHash=${params.userIdHash}`,
      payload,
      true
    )
      .then((result) => {
        if (result.status == 202 || result.status == 201) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const searchMessages = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      params.entityTypeId
        ? `${EXPRESS_MIDDLEWARE_API_URL}/message/searchMessage?search=${params.search}&userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}&entityTypeId=${params.entityTypeId}`
        : `${EXPRESS_MIDDLEWARE_API_URL}/message/searchMessage?search=${params.search}&userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const searchSpaces = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/message/searchSpace?search=${params.search}&userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const searchFollowing = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/followNetwork/search?search=${params.search}&userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const sortMessages = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      params.entityTypeId
        ? `${EXPRESS_MIDDLEWARE_API_URL}/message/searchMessage?search=${params.search}&userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}&sortOldest=${params.sortOldest}&entityTypeId=${params.entityTypeId}`
        : `${EXPRESS_MIDDLEWARE_API_URL}/message/searchMessage?search=${params.search}&userIdHash=${params.userIdHash}&userTypeId=${params.userTypeId}&sortOldest=${params.sortOldest}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getConnections = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/followNetwork/userNetwork?userIdHash=${params.userIdHash}&userNameHash=${params.userNameHash}&userTypeId=${params.userTypeId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const followFromConnections = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/followNetwork/connect/${params.userIdHash}?entityTypeId=${params.entityTypeId}&entityTypeIdNetwork=${params.entityTypeIdNetwork}&myFollowUserNameHash=${params.myFollowUserNameHash}&userNameHash=${params.userNameHash}`,
      {},
      true
    )
      .then((result) => {
        console.log("RESULT", result);
        if (result.status > 200 && result.status < 205 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const reportUser = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      params.comment
        ? `${EXPRESS_MIDDLEWARE_API_URL}/flagUser/reportUser/${params.userIdHash}?comment=${params.comment}&userNameHash=${params.userNameHash}`
        : `${EXPRESS_MIDDLEWARE_API_URL}/flagUser/reportUser/${params.userIdHash}?userNameHash=${params.userNameHash}`,
      {},
      true
    )
      .then((result) => {
        if (result.data === true) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const blockUser = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      params.comment
        ? `${EXPRESS_MIDDLEWARE_API_URL}/flagUser/blockUser/${params.userIdHash}?comment=${params.comment}&userNameHash=${params.userNameHash}`
        : `${EXPRESS_MIDDLEWARE_API_URL}/flagUser/blockUser/${params.userIdHash}?userNameHash=${params.userNameHash}`,
      {},
      true
    )
      .then((result) => {
        if (result.data === true) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const reportPost = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      params.comment
        ? `${EXPRESS_MIDDLEWARE_API_URL}/flagUser/reportPost/${params.userIdHash}?comment=${params.comment}&entityId=${params.entityId}`
        : `${EXPRESS_MIDDLEWARE_API_URL}/flagUser/reportPost/${params.userIdHash}?entityId=${params.entityId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status > 200 && result.status < 205 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getProfessionsList = (params, cb) => {
  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/main/categoryTop`, {}, false)
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getProfessionsChildrenList = (params, cb) => {
  return (dispatch) => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/main/category/children?entityTypeName=${params.entityTypeName}`, {}, false)
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSearchJobListForLandingPage = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      params.professionTitle && params.profession ?
      `${EXPRESS_MIDDLEWARE_API_URL}/main/search?entityTypeNames=${params.profession}&search=${params.professionTitle}&city=${params.city}&state=${params.state}&country=${params.country}` : params.professionTitle ? `${EXPRESS_MIDDLEWARE_API_URL}/main/search?search=${params.professionTitle}&city=${params.city}&state=${params.state}&country=${params.country}`
      : params.profession ? `${EXPRESS_MIDDLEWARE_API_URL}/main/search?entityTypeNames=${params.profession}&city=${params.city}&state=${params.state}&country=${params.country}` :
      `${EXPRESS_MIDDLEWARE_API_URL}/main/search?city=${params.city}&state=${params.state}&country=${params.country}`,
      {},
      false
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getServiceTermList = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/serviceTermType/list`,
      {},
      false
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getServiceTermData = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/serviceTerm/get`,
      {},
      false
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getContactUsTypeActives = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/contactUsType/actives`,
      {},
      false
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const contactUs = (params, cb) => {
  return (dispatch) => {
    let hashAccessKeyId = params.hashAccessKeyId;
    delete params.hashAccessKeyId;
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/contactUs?hashAccessKeyId=${hashAccessKeyId}`,params,true)
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};


export const getTermsNconditions = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/serviceTerm/get?hashAccessKeyId=${params.hashAccessKeyId}&serviceTag=${params.serviceTag}`,
      {},
      false
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};


export const getPrivacyPolicy = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/serviceTerm/get?hashAccessKeyId=${params.hashAccessKeyId}&serviceTag=${params.serviceTag}`,
      {},
      false
    )
      .then((result) => {
        if (result.status === 200) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};