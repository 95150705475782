import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PostJob from "../../public/img/Post_a_Job.svg";
import HireFreelancers from "../../public/img/Hire_Freelancers.svg";
import Engineering from "../../public/img/engineering_(1).svg";
import Payment from "../../public/img/payment.svg";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";
import AOS from "aos";
import "aos/dist/aos.css";

const WhyChooseUs = (props) => {
  const history = useHistory();

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <section className="services_section p-60 aos-item" data-aos={"fade-up"}>
        <div className="container">
          <div className="home_main_title button_padding ">
            <h2>Why Choose us</h2>
            <p>It was popularised in the 1960s with the release </p>
          </div>

          <div className="row get_work_jersey">
            <div className="col-md-3">
              <div className="get_work_section">
                <div className="get_work_img">
                  <img src={PostJob} alt="" />
                </div>
                <h3>Lorem Ipsum is simply </h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="get_work_section">
                <div className="get_work_img  get_work_img_pstn">
                  <img src={HireFreelancers} alt="" />
                </div>
                <h3>Lorem Ipsum is simply </h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="get_work_section">
                <div className="get_work_img">
                  <img src={Engineering} alt="" />
                </div>
                <h3>Lorem Ipsum is simply </h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="get_work_section">
                <div className="get_work_img get_work_img_pstn2">
                  <img src={Payment} alt="" />
                </div>
                <h3>Lorem Ipsum is simply </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="pay_form text-center button_padding">
                <button
                  type="button"
                  name="next"
                  className=" post_job btn  button-5"
                  value="Next"
                >
                  <div className="eff-5"></div>See How Timelyhire Work
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WhyChooseUs);
