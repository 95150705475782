import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import {
  getHomeScreenList,
  getHomeScreenTabs
} from "../../actions/employee/dashboard";
import {
  getUserTypeInfo,
  getFollowingListForAll,
  getFollowingListForEntity,
  getFollowersListForAll,
  getFollowersListForEntity,
  unfollow,
  getSpacesDataFromFollowingNetwork,
  getSpaceDataForProfessionalsForAll,
  followFromSpaces,
  getSpaceDataForProfessionalsForEntityId,
  getSpaceDataForCompaniesForAll,
  getSpaceDataForCompaniesForEntityId,
  getSpaceDataForMeForAll,
  getSpaceDataForMeForEntityId,
  getSpaceCategories,
  createSpacePost,
  deleteSpacePost,
  updateSpacePost,
  getStatusUpdateDataForUsers,
  getStatusUpdateDataForMe,
  getStatusUpdateDataForUsersForEntityTypeId,
  getStatusUpdateDataForMeForEntityTypeId,
  createWhatsOnMyMindStatus,
  getReadInboxMessagesForAll,
  getReadInboxMessagesForEntityTypeId,
  getNewInboxMessagesForAll,
  getNewInboxMessagesForEntityTypeId,
  getDeletedInboxMessagesForAll,
  getDeletedInboxMessagesForEntityTypeId,
  deleteMessageFromInbox,
  deleteMessageThreadFromInbox,
  restoreMessageThreadFromArchived,
  restoreMessageToInboxFromArchived,
  sendNewMessage,
  getListOfMessagesForMessageThread,
  replyToMessage,
  searchMessages,
  searchSpaces,
  searchFollowing,
  getConnections,
  followFromConnections,
  reportUser,
  blockUser,
  reportPost
} from "../../actions/common";
import small_dot from "../../public/images/small_dot.png";
import downArrow from "../../public/images/downArrow.svg";
import locationImg from "../../public/images/location.png";
import locationGrey from "../../public/images/locationGrey.svg";
import showImg from "../../public/images/Show.png";
import {
  debounce,
  getFormatedDate,
  showBadge,
} from "../../../utilities/helpers";
import verified from "../../public/images/verified.svg";
import unVerified from "../../public/images/unVerified.svg";
import newCalender from "../../public/images/newCalender.svg";
import searchIcon from "../../public/images/searchIcon.svg";
import zeroFollowingImage from "../../public/images/zeroFollowingImageSmall.png";
import spacesIcon from "../../public/images/spacesIcon.svg";
import messageIcon from "../../public/images/messageIcon.svg";
import privateIcon from "../../public/images/privateIcon.svg";
import publicIcon from "../../public/images/publicIcon.svg";
import paperPlane from "../../public/images/paperPlane.svg";
import greenCircle from "../../public/images/greenCircle.svg";
import greyCircle from "../../public/images/greyCircle.svg";
import plusIcon from "../../public/images/plusIcon.svg";
import sortIcon from "../../public/images/sortIcon.svg";
import statusIcon from "../../public/images/statusIcon.svg";
import senderIcon from "../../public/images/senderIcon.svg";
import receiverIcon from "../../public/images/receiverIcon.svg";
import meFollowingGrey from "../../public/images/Vector-2/Vector-3.png";
import meFollowingGreen from "../../public/images/Vector-2/Vector-1.png";
import myFollowersGrey from "../../public/images/Vector-2/Vector.png";
import myFollowersGreen from "../../public/images/Vector-2/Vector-2.png";
import account from "../../public/images/account.svg";
import { Button, Modal } from "react-bootstrap";
import { follow, shortlist } from "../../actions/common";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment-timezone";

const NetworkEmployee = (props) => {
  const history = useHistory();
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [tabsLoading, setTabsLoading] = useState(false);

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const [filterShow, setFilterShow] = useState(false);
  const [filterVisible, setFilterVisible] = useState(true);
  const [selectedUserProfileIds, setSelectedUserProfileIds] = useState([]);
  const initModal = { modal: false, type: "", data: {} };
  const [modal, setModal] = useState(initModal);
  const [modalLoading, setModalLoading] = useState(false);

  const [followingFlag, setFollowingFlag] = useState(true);
  const [spacesFlag, setSpacesFlag] = useState(false);
  const [spacesFlagForIndividual, setSpacesFlagForIndividual] = useState(false);
  const [connectionFlag, setConnectionFlag] = useState(false);
  const [followingConnectionFlag, setFollowingConnectionFlag] = useState(false);
  const [followersConnectionFlag, setFollowersConnectionFlag] = useState(false);
  const [messagesFlag, setMessagesFlag] = useState(false);
  const [professionalsFlag, setProfessionalsFlag] = useState(true);
  const [companiesFlag, setCompaniesFlag] = useState(false);
  const [meFollowingFlag, setMeFollowingFlag] = useState(true);
  const [myFollowersFlag, setMyFollowersFlag] = useState(false);
  const [meFlag, setMeFlag] = useState(false);
  const [statusUpdateFlag, setStatusUpdateFlag] = useState(false);
  const [createPostFlag, setCreatePostFlag] = useState(false);
  const [whatInYourMindFlag, setWhatInYourMindFlag] = useState(false);

  const [userType, setUserType] = useState("");
  const [userTypeId, setUserTypeId] = useState();
  const [entityTypeId, setEntityTypeId] = useState();

  const [followingData, setFollowingData] = useState([]);
  const [spacesData, setSpacesData] = useState([]);
  const [spacesDataForIndividual, setSpacesDataForIndividual] = useState([]);
  const [statusUpdateData, setStatusUpdateData] = useState([]);

  const [viewMoreFlag, setViewMoreFlag] = useState(false);
  const [spaceIdHash, setSpaceIdHash] = useState();

  const [name, setName] = useState("");

  const [spaceUserInfo, setSpaceUserInfo] = useState({});
  const [connectionData, setConnectionData] = useState({});

  const [travelFlag, setTravelFlag] = useState(false);
  const [qnaFlag, setQnAFlag] = useState(false);

  const [spacePostBtnDisabled, setSpacePostBtnDisabled] = useState(true);
  const [updateStatusBtnDisabled, setUpdateStatusBtnDisabled] = useState(true);

  const [spaceCategoriesLoading, setSpaceCategoriesLoading] = useState(false);
  const [spaceCategoriesData, setSpaceCategoriesData] = useState([]);
  const [spaceCategoryCircleIcon, setSpaceCategoryCircleIcon] = useState();
  const [spaceCategoryStyle, setSpaceCategoryStyle] = useState({});
  const [spaceCategoryId, setSpaceCategoryId] = useState();
  const [spaceTitle, setSpaceTitle] = useState();
  const [spaceDesc, setSpaceDesc] = useState();

  const [whatInYourMindStatus, setWhatInYourMindStatus] = useState();
  const [whatInYourMindStatusLoading, setWhatInYourMindStatusLoading] =
    useState(false);

  const [editPostFlag, setEditPostFlag] = useState(false);

  const [postDetailsFlag, setPostDetailsFlag] = useState(false);
  const [postDetailsData, setPostDetailsData] = useState();

  const [messageDetailsFlag, setMessageDetailsFlag] = useState(false);
  const [messageDetailsData, setMessageDetailsData] = useState({
    user: {},
    message: {},
  });

  const [inboxNewFlag, setInboxNewFlag] = useState(false);
  const [inboxReadFlag, setInboxReadFlag] = useState(false);
  const [inboxArchivedFlag, setInboxArchivedFlag] = useState(false);

  const [messageListLoading, setMessageListLoading] = useState(false);
  const [messageData, setMessageData] = useState([]);

  const [startNewConversationFlag, setStartNewConversationFlag] =
    useState(false);
  const [sendNewMessageLoading, setSendNewMessageLoading] = useState(false);
  const [msgSendBtnDisabled, setMessageSendBtnDisabled] = useState(false);
  const [followIdHash, setFollowIdHash] = useState();
  const [msgReceiverName, setMessageReceiverName] = useState();
  const [msgSubject, setMsgSubject] = useState();
  const [msg, setMsg] = useState();

  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
  const [chatHistoryData, setChatHistoryData] = useState([]);
  const [msgIdHash, setMsgIdHash] = useState();
  const [msgReplySubject, setMsgReplySubject] = useState();
  const [msgReply, setMsgReply] = useState();

  const [reportComment, setReportComment] = useState('');

  const divRef = useRef(null);

  const getHomeScreenTabs = () => {
    setTabsLoading(true);
    props.getHomeScreenTabs(
      { userIdHash: props.auth.userProfile.userIdHash },
      (res) => {
        setTabsLoading(false);
        if (res.status) {
          setTabs(res.data);
        }
      }
    );
  };

  const getHomeScreenList = (
    entityTypeId,
    userProfileIds,
    searchText,
    flag = null
  ) => {
    console.log("Entity Type Id", entityTypeId);
    setListLoading(true);
    setEntityTypeId(entityTypeId);
    if (followingFlag) {
      if (professionalsFlag) {
        if (flag) {
          if (flag == "myFollowers") {
            getFollowersListForMyFollowersOrMeFollowingClicked(
              "employee",
              entityTypeId
            );
          } else if (flag == "meFollowing") {
            getFollowersListForMyFollowersOrMeFollowingClicked(
              "employee",
              entityTypeId
            );
          }
        } else {
          getUserTypeInfoData("employee", entityTypeId);
        }
      } else if (companiesFlag) {
        if (flag) {
          if (flag == "myFollowers") {
            getFollowersListForMyFollowersOrMeFollowingClicked(
              "employer",
              entityTypeId
            );
          } else if (flag == "meFollowing") {
            getFollowersListForMyFollowersOrMeFollowingClicked(
              "employer",
              entityTypeId
            );
          }
        } else {
          getUserTypeInfoData("employer", entityTypeId);
        }
      }
    } else if (spacesFlag) {
      if (flag) {
        if (flag == "companiesClicked") {
          getCompaniesListOfSpacesForCompaniesClicked("employer", entityTypeId);
        } else if (flag == "professionalsClicked") {
          getCompaniesListOfSpacesForProfessionalsClicked(
            "employee",
            entityTypeId
          );
        } else if (flag == "meClicked") {
          getMeListOfSpacesForMeClicked(entityTypeId);
        }
      } else {
        getSpacesData(entityTypeId);
      }
    } else if (messagesFlag) {
      if (flag) {
        if (flag == "companiesClicked") {
          if (inboxNewFlag) {
            getListOfNewMessagesForCompanies("employer", entityTypeId);
          } else if (inboxReadFlag) {
            getListOfReadMessagesForCompanies("employer", entityTypeId);
          } else if (inboxArchivedFlag) {
            getListOfArchivedMessagesForCompanies("employer", entityTypeId);
          }
        } else if (flag == "professionalsClicked") {
          if (inboxNewFlag) {
            getListOfNewMessagesForProfessionals("employee", entityTypeId);
          } else if (inboxReadFlag) {
            getListOfReadMessagesForProfessionals("employee", entityTypeId);
          } else if (inboxArchivedFlag) {
            getListOfArchivedMessagesForProfessionals("employee", entityTypeId);
          }
        }
      } else {
        if (inboxNewFlag) {
          getListOfNewMessages(entityTypeId, userTypeId);
        } else if (inboxReadFlag) {
          getListOfReadMessages(entityTypeId, userTypeId);
        } else if (inboxArchivedFlag) {
          getListOfArchivedMessages(entityTypeId, userTypeId);
        }
      }
    } else if (statusUpdateFlag) {
      if (flag) {
        if (flag == "companiesClicked") {
          getCompaniesListOfStatusUpdateForCompaniesClicked(
            "employer",
            entityTypeId
          );
        } else if (flag == "professionalsClicked") {
          getProfessionalsListOfStatusUpdateForProfessionalsClicked(
            "employee",
            entityTypeId
          );
        } else if (flag == "meClicked") {
          getMeListOfStatusUpdateForMeClicked(entityTypeId);
        }
      } else {
        getStatusUpdateData(entityTypeId);
      }
    }
  };

  const shortlist = () => {
    setModalLoading(true);
    props.shortlist(
      {
        jobUserProfileId: modal.data.selectedEntityData.jobUserProfileId,
        providerUserProfileId: modal.data.selectedUserData.userProfileId,
        entityId: modal.data.selectedEntityData.id,
      },
      (res) => {
        setModalLoading(false);
        if (res.status) {
          setModal(initModal);
          toast.success("Shortlisted Successfully");
        }
      },
      (err) => {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong!");
      }
    );
  };

  const follow = () => {
    setModalLoading(true);
    props.follow(
      {
        jobUserProfileId: modal.data.selectedEntityData.jobUserProfileId,
        providerUserProfileId: modal.data.selectedUserData.userProfileId,
      },
      (res) => {
        setModalLoading(false);
        if (res.status) {
          setModal(initModal);
          toast.success("Followed Successfully");
        }
      },
      (err) => {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong!");
      }
    );
  };

  const invite = () => {
    setModalLoading(true);
    props.invite(
      {
        jobUserProfileId: modal.data.selectedEntityData.jobUserProfileId,
        entityId: modal.data.selectedEntityData.id,
        providerUserProfileId: modal.data.selectedUserData.userProfileId,
      },
      (res) => {
        setModalLoading(false);
        if (res.status) {
          setModal(initModal);
          toast.success("Invited Successfully");
        }
      },
      (err) => {
        setModalLoading(false);
        setModal(initModal);
        toast.error("Something went wrong!");
      }
    );
  };

  const onClickAction = () => {
    if (modal.type === "shortlist") {
      shortlist();
    } else if (modal.type === "follow") {
      follow();
    } else if (modal.type === "invite") {
      invite();
    } else if (modal.type === 'Report') {
      reportUserFn(modal.data)
    } else if (modal.type === 'Block') {
      blockUserFn(modal.data)
    }
  };

  useEffect(() => {
    getHomeScreenTabs();
    getHomeScreenList("");
    setFollowingFlag(true);
    setProfessionalsFlag(true);
    setMeFollowingFlag(true);
    setMyFollowersFlag(false);
    setUserType("employee");
    getUserTypeInfoData("employee", entityTypeId);
    console.log("VIEWPORT", window.innerWidth);
  }, []);

  const getUserTypeInfoData = (userType, entityId = null) => {
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        console.log("User Type Info", res);
        if (res.data) {
          setUserTypeId(res.data.id);
          console.log("ENTITY ID", entityId);
          if (entityId) {
            if (meFollowingFlag) {
              getListOfFollowingForEntity(res.data.id, entityId);
            } else if (myFollowersFlag) {
              getListOfFollowersForEntity(res.data.id, entityId);
            }
          } else {
            if (meFollowingFlag) {
              getListOfFollowingForAll(res.data.id);
            } else if (myFollowersFlag) {
              getListOfFollowersForAll(res.data.id);
            }
          }
        }
      }
    });
  };

  const getListOfFollowingForAll = (id) => {
    setListLoading(true);
    props.getFollowingListForAll(
      { userTypeId: id, userIdHash: props.auth.userProfile.userIdHash },
      (res) => {
        setListLoading(false);
        if (res.status) {
          if (res.data.length) {
            console.log("List of Following Users For All", res);
            setFollowingData(res.data);
          } else {
            setFollowingData([]);
          }
        } else {
          toast.error("Something went wrong");
          setFollowingData([]);
        }
      }
    );
  };

  const getListOfFollowingForEntity = (id, entityId) => {
    setListLoading(true);
    props.getFollowingListForEntity(
      {
        userTypeId: id,
        entityTypeId: entityId,
        userIdHash: props.auth.userProfile.userIdHash,
      },
      (res) => {
        setListLoading(false);
        if (res.status) {
          if (res.data.length) {
            console.log("List of Following Users For Entity", res);
            setFollowingData(res.data);
          } else {
            setFollowingData([]);
          }
        } else {
          toast.error("Something went wrong");
          setFollowingData([]);
        }
      }
    );
  };

  const getListOfFollowersForAll = (id) => {
    setListLoading(true);
    props.getFollowersListForAll(
      { userTypeId: id, userIdHash: props.auth.userProfile.userIdHash },
      (res) => {
        setListLoading(false);
        if (res.status) {
          if (res.data.length) {
            console.log("List of Followers For All", res);
            setFollowingData(res.data);
          } else {
            setFollowingData([]);
          }
        } else {
          toast.error("Something went wrong");
          setFollowingData([]);
        }
      }
    );
  };

  const getListOfFollowersForEntity = (id, entityId) => {
    setListLoading(true);
    props.getFollowersListForEntity(
      {
        userTypeId: id,
        entityTypeId: entityId,
        userIdHash: props.auth.userProfile.userIdHash,
      },
      (res) => {
        setListLoading(false);
        if (res.status) {
          if (res.data.length) {
            console.log("List of Followers For Entity", res);
            setFollowingData(res.data);
          } else {
            setFollowingData([]);
          }
        } else {
          toast.error("Something went wrong");
          setFollowingData([]);
        }
      }
    );
  };

  const getFollowersListForMyFollowersOrMeFollowingClicked = (
    userType,
    entityId = null
  ) => {
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        console.log("User Type Info", res);
        if (res.data) {
          setUserTypeId(res.data.id);
          console.log("ENTITY ID", entityId);
          if (entityId) {
            if (!meFollowingFlag) {
              getListOfFollowingForEntity(res.data.id, entityId);
            } else if (!myFollowersFlag) {
              getListOfFollowersForEntity(res.data.id, entityId);
            }
          } else {
            if (!meFollowingFlag) {
              getListOfFollowingForAll(res.data.id);
            } else if (!myFollowersFlag) {
              getListOfFollowersForAll(res.data.id);
            }
          }
        }
      }
    });
  };

  const unFollowFunction = (e, value) => {
    e.preventDefault();

    let params = {
      userIdHash: props.auth.userProfile.userIdHash,
      followIdHash: value.followIdHash,
    };

    let title = "";
    if (professionalsFlag) {
      title =
        value.followUserInfoData?.userInformation?.firstName +
        " " +
        value.followUserInfoData?.userInformation?.lastName;
    } else if (companiesFlag) {
      title = value.followUserInfoData?.userInformation?.entityName;
    }

    swal({
      text: `Are you sure you want to unfollow ${title}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setListLoading(true);
        props.unfollow(params, (res) => {
          console.log("UNFOLLOW RES", res);
          if (res.status) {
            swal({
              text: "Profile unfollowed successfully",
              icon: "success",
            }).then((onUpdate) => {
              getUserTypeInfoData(userType, entityTypeId);
            });
          } else {
            toast.error("Something went wrong");
            setListLoading(false);
          }
        });
      }
    });
  };

  const getSpacesData = (entityId = null) => {
    setListLoading(true);
    if (professionalsFlag) {
      if (entityId) {
        props.getSpaceDataForProfessionalsForEntityId(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId,
            entityTypeId: entityId,
          },
          (res) => {
            console.log("SPACES LIST For ENTITY ID", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setSpacesData(res.data);
              } else {
                setSpacesData([]);
              }
            } else {
              toast.error("Something went wrong");
              setSpacesData([]);
            }
          }
        );
      } else {
        props.getSpaceDataForProfessionalsForAll(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId,
          },
          (res) => {
            console.log("SPACES LIST", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setSpacesData(res.data);
              } else {
                setSpacesData([]);
              }
            } else {
              toast.error("Something went wrong");
              setSpacesData([]);
            }
          }
        );
      }
    } else if (companiesFlag) {
      if (entityId) {
        props.getSpaceDataForCompaniesForEntityId(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId,
            entityTypeId: entityId,
          },
          (res) => {
            console.log("SPACES LIST Of Companies For ENTITY ID", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setSpacesData(res.data);
              } else {
                setSpacesData([]);
              }
            } else {
              toast.error("Something went wrong");
              setSpacesData([]);
            }
          }
        );
      } else {
        props.getSpaceDataForCompaniesForAll(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId,
          },
          (res) => {
            console.log("SPACES LIST of Companies", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setSpacesData(res.data);
              } else {
                setSpacesData([]);
              }
            } else {
              toast.error("Something went wrong");
              setSpacesData([]);
            }
          }
        );
      }
    } else if (meFlag) {
      if (entityId) {
        props.getSpaceDataForMeForEntityId(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            entityTypeId: entityId,
          },
          (res) => {
            console.log("SPACES LIST Of Me For ENTITY ID", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setSpacesData(res.data);
              } else {
                setSpacesData([]);
              }
            } else {
              toast.error("Something went wrong");
              setSpacesData([]);
            }
          }
        );
      } else {
        props.getSpaceDataForMeForAll(
          { userIdHash: props.auth.userProfile.userIdHash },
          (res) => {
            console.log("SPACES LIST of Me", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setSpacesData(res.data);
              } else {
                setSpacesData([]);
              }
            } else {
              toast.error("Something went wrong");
              setSpacesData([]);
            }
          }
        );
      }
    }
  };

  const getCompaniesListOfSpacesForCompaniesClicked = (
    userType,
    entityId = null
  ) => {
    setListLoading(true);
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        setUserTypeId(res.data.id);
        if (entityId) {
          props.getSpaceDataForCompaniesForEntityId(
            {
              userIdHash: props.auth.userProfile.userIdHash,
              userTypeId: res.data.id,
              entityTypeId: entityId,
            },
            (res) => {
              console.log("SPACES LIST Of Companies For ENTITY ID", res);
              setListLoading(false);
              if (res.status) {
                if (res.data.length) {
                  setSpacesData(res.data);
                } else {
                  setSpacesData([]);
                }
              } else {
                toast.error("Something went wrong");
                setSpacesData([]);
              }
            }
          );
        } else {
          props.getSpaceDataForCompaniesForAll(
            {
              userIdHash: props.auth.userProfile.userIdHash,
              userTypeId: res.data.id,
            },
            (res) => {
              console.log("SPACES LIST of Companies", res);
              setListLoading(false);
              if (res.status) {
                if (res.data.length) {
                  setSpacesData(res.data);
                } else {
                  setSpacesData([]);
                }
              } else {
                toast.error("Something went wrong");
                setSpacesData([]);
              }
            }
          );
        }
      }
    });
  };

  const getCompaniesListOfSpacesForProfessionalsClicked = (
    userType,
    entityId = null
  ) => {
    setListLoading(true);
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        if (entityId) {
          props.getSpaceDataForCompaniesForEntityId(
            {
              userIdHash: props.auth.userProfile.userIdHash,
              userTypeId: res.data.id,
              entityTypeId: entityId,
            },
            (res) => {
              console.log("SPACES LIST Of Companies For ENTITY ID", res);
              setListLoading(false);
              if (res.status) {
                if (res.data.length) {
                  setSpacesData(res.data);
                } else {
                  setSpacesData([]);
                }
              } else {
                toast.error("Something went wrong");
                setSpacesData([]);
              }
            }
          );
        } else {
          props.getSpaceDataForCompaniesForAll(
            {
              userIdHash: props.auth.userProfile.userIdHash,
              userTypeId: res.data.id,
            },
            (res) => {
              console.log("SPACES LIST of Companies", res);
              setListLoading(false);
              if (res.status) {
                if (res.data.length) {
                  setSpacesData(res.data);
                } else {
                  setSpacesData([]);
                }
              } else {
                toast.error("Something went wrong");
                setSpacesData([]);
              }
            }
          );
        }
      }
    });
  };

  const getMeListOfSpacesForMeClicked = (entityId = null) => {
    setListLoading(true);
    if (entityId) {
      props.getSpaceDataForMeForEntityId(
        {
          userIdHash: props.auth.userProfile.userIdHash,
          entityTypeId: entityId,
        },
        (res) => {
          console.log("SPACES LIST Of Me For ENTITY ID", res);
          setListLoading(false);
          if (res.status) {
            if (res.data.length) {
              setSpacesData(res.data);
            } else {
              setSpacesData([]);
            }
          } else {
            toast.error("Something went wrong");
            setSpacesData([]);
          }
        }
      );
    } else {
      props.getSpaceDataForMeForAll(
        { userIdHash: props.auth.userProfile.userIdHash },
        (res) => {
          console.log("SPACES LIST of Me", res);
          setListLoading(false);
          if (res.status) {
            if (res.data.length) {
              setSpacesData(res.data);
            } else {
              setSpacesData([]);
            }
          } else {
            toast.error("Something went wrong");
            setSpacesData([]);
          }
        }
      );
    }
  };

  const getStatusUpdateData = (entityId = null) => {
    setListLoading(true);

    if (professionalsFlag || companiesFlag) {
      if (entityId) {
        props.getStatusUpdateDataForUsersForEntityTypeId(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId,
            entityTypeId: entityId,
            limit: 20,
          },
          (res) => {
            console.log(
              "STATUS UPDATE FOR PROFESSIONALS/COMPANIES For ENTITY ID",
              res
            );
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setStatusUpdateData(res.data);
              } else {
                setStatusUpdateData([]);
              }
            } else {
              toast.error("Something went wrong");
              setStatusUpdateData([]);
            }
          }
        );
      } else {
        props.getStatusUpdateDataForUsers(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId,
            limit: 20,
          },
          (res) => {
            console.log("STATUS UPDATE DATA FOR PROFESSIONALS/COMPANIES", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setStatusUpdateData(res.data);
              } else {
                setStatusUpdateData([]);
              }
            } else {
              toast.error("Something went wrong");
              setStatusUpdateData([]);
            }
          }
        );
      }
    } else if (meFlag) {
      if (entityId) {
        props.getStatusUpdateDataForMeForEntityTypeId(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            entityTypeId: entityId,
            limit: 20,
          },
          (res) => {
            console.log("STATUS UPDATE DATA Of Me For ENTITY ID", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setStatusUpdateData(res.data);
              } else {
                setStatusUpdateData([]);
              }
            } else {
              toast.error("Something went wrong");
              setStatusUpdateData([]);
            }
          }
        );
      } else {
        props.getStatusUpdateDataForMe(
          { userIdHash: props.auth.userProfile.userIdHash, limit: 20 },
          (res) => {
            console.log("STATUS UPDATE DATA of Me", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setStatusUpdateData(res.data);
              } else {
                setStatusUpdateData([]);
              }
            } else {
              toast.error("Something went wrong");
              setStatusUpdateData([]);
            }
          }
        );
      }
    }
  };

  const getCompaniesListOfStatusUpdateForCompaniesClicked = (
    userType,
    entityId = null
  ) => {
    setListLoading(true);
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        setUserTypeId(res.data.id);
        if (entityId) {
          props.getStatusUpdateDataForUsersForEntityTypeId(
            {
              userIdHash: props.auth.userProfile.userIdHash,
              userTypeId: res.data.id,
              entityTypeId: entityId,
              limit: 20,
            },
            (res) => {
              console.log("STATUS UPDATE FOR COMPANIES For ENTITY ID", res);
              setListLoading(false);
              if (res.status) {
                if (res.data.length) {
                  setStatusUpdateData(res.data);
                } else {
                  setStatusUpdateData([]);
                }
              } else {
                toast.error("Something went wrong");
                setStatusUpdateData([]);
              }
            }
          );
        } else {
          props.getStatusUpdateDataForUsers(
            {
              userIdHash: props.auth.userProfile.userIdHash,
              userTypeId: res.data.id,
              limit: 20,
            },
            (res) => {
              console.log("STATUS UPDATE DATA FOR COMPANIES", res);
              setListLoading(false);
              if (res.status) {
                if (res.data.length) {
                  setStatusUpdateData(res.data);
                } else {
                  setStatusUpdateData([]);
                }
              } else {
                toast.error("Something went wrong");
                setStatusUpdateData([]);
              }
            }
          );
        }
      }
    });
  };

  const getProfessionalsListOfStatusUpdateForProfessionalsClicked = (
    userType,
    entityId = null
  ) => {
    setListLoading(true);
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        setUserTypeId(res.data.id);
        if (entityId) {
          props.getStatusUpdateDataForUsersForEntityTypeId(
            {
              userIdHash: props.auth.userProfile.userIdHash,
              userTypeId: res.data.id,
              entityTypeId: entityId,
              limit: 20,
            },
            (res) => {
              console.log("STATUS UPDATE FOR PROFESSIONALS For ENTITY ID", res);
              setListLoading(false);
              if (res.status) {
                if (res.data.length) {
                  setStatusUpdateData(res.data);
                } else {
                  setStatusUpdateData([]);
                }
              } else {
                toast.error("Something went wrong");
                setStatusUpdateData([]);
              }
            }
          );
        } else {
          props.getStatusUpdateDataForUsers(
            {
              userIdHash: props.auth.userProfile.userIdHash,
              userTypeId: res.data.id,
              limit: 20,
            },
            (res) => {
              console.log("STATUS UPDATE DATA FOR PROFESSIONALS", res);
              setListLoading(false);
              if (res.status) {
                if (res.data.length) {
                  setStatusUpdateData(res.data);
                } else {
                  setStatusUpdateData([]);
                }
              } else {
                toast.error("Something went wrong");
                setStatusUpdateData([]);
              }
            }
          );
        }
      }
    });
  };

  const getMeListOfStatusUpdateForMeClicked = (entityId = null) => {
    setListLoading(true);
    if (entityId) {
      props.getStatusUpdateDataForMeForEntityTypeId(
        {
          userIdHash: props.auth.userProfile.userIdHash,
          entityTypeId: entityId,
          limit: 20,
        },
        (res) => {
          console.log("STATUS UPDATE FOR ME For ENTITY ID", res);
          setListLoading(false);
          if (res.status) {
            if (res.data.length) {
              setStatusUpdateData(res.data);
            } else {
              setStatusUpdateData([]);
            }
          } else {
            toast.error("Something went wrong");
            setStatusUpdateData([]);
          }
        }
      );
    } else {
      props.getStatusUpdateDataForMe(
        { userIdHash: props.auth.userProfile.userIdHash, limit: 20 },
        (res) => {
          console.log("STATUS UPDATE DATA FOR ME", res);
          setListLoading(false);
          if (res.status) {
            if (res.data.length) {
              setStatusUpdateData(res.data);
            } else {
              setStatusUpdateData([]);
            }
          } else {
            toast.error("Something went wrong");
            setStatusUpdateData([]);
          }
        }
      );
    }
  };

  const getListOfNewMessages = (entityId = null, userTypeId) => {
    setMessageListLoading(true);
    setMessageDetailsFlag(true);
    if (entityId) {
      props.getNewInboxMessagesForEntityTypeId(
        {
          userIdHash: props.auth.userProfile.userIdHash,
          entityTypeId: entityId,
          userTypeId: userTypeId,
          sortOldest: 0
        },
        (res) => {
          console.log("NEW INBOX MESSAGES For ENTITY ID", res);
          setMessageListLoading(false);
          setMessageDetailsFlag(false);
          if (res.status) {
            if (res.data.length) {
              setMessageData(res.data);
              setMessageDetailsData({ user: {}, message: {} });
              // getListOfMessagesForMessageThreadFn(
              //   res.data[0].messageInstanceId
              // );
            } else {
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          } else {
            toast.error("Something went wrong");
            setMessageData([]);
            setMessageDetailsData({ user: {}, message: {} });
          }
        }
      );
    } else {
      props.getNewInboxMessagesForAll(
        {
          userIdHash: props.auth.userProfile.userIdHash,
          userTypeId: userTypeId,
          sortOldest: 0
        },
        (res) => {
          console.log("NEW INBOX MESSAGES For ALL", res);
          setMessageListLoading(false);
          setMessageDetailsFlag(false);
          if (res.status) {
            if (res.data.length) {
              setMessageData(res.data);
              setMessageDetailsData({ user: {}, message: {} });
              // getListOfMessagesForMessageThreadFn(
              //   res.data[0].messageInstanceId
              // );
            } else {
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          } else {
            toast.error("Something went wrong");
            setMessageData([]);
            setMessageDetailsData({});
          }
        }
      );
    }
  };

  const getListOfReadMessages = (entityId = null, userTypeId) => {
    setMessageListLoading(true);
    setMessageDetailsFlag(true);
    if (entityId) {
      props.getReadInboxMessagesForEntityTypeId(
        {
          userIdHash: props.auth.userProfile.userIdHash,
          entityTypeId: entityId,
          userTypeId: userTypeId,
          sortOldest: 0
        },
        (res) => {
          console.log("READ INBOX MESSAGES For ENTITY ID", res);
          setMessageListLoading(false);
          setMessageDetailsFlag(false);
          if (res.status) {
            if (res.data.length) {
              setMessageData(res.data);
              setMessageDetailsData({ user: {}, message: {} });
              // getListOfMessagesForMessageThreadFn(
              //   res.data[0].messageInstanceId
              // );
            } else {
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          } else {
            toast.error("Something went wrong");
            setMessageData([]);
            setMessageDetailsData({ user: {}, message: {} });
          }
        }
      );
    } else {
      props.getReadInboxMessagesForAll(
        {
          userIdHash: props.auth.userProfile.userIdHash,
          userTypeId: userTypeId,
          sortOldest: 0
        },
        (res) => {
          console.log("READ INBOX MESSAGES For ALL", res);
          setMessageListLoading(false);
          setMessageDetailsFlag(false);
          if (res.status) {
            if (res.data.length) {
              setMessageData(res.data);
              setMessageDetailsData({ user: {}, message: {} });
              // getListOfMessagesForMessageThreadFn(
              //   res.data[0].messageInstanceId
              // );
            } else {
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          } else {
            toast.error("Something went wrong");
            setMessageData([]);
            setMessageDetailsData({ user: {}, message: {} });
          }
        }
      );
    }
  };

  const getListOfArchivedMessages = (entityId = null, userTypeId) => {
    setMessageListLoading(true);
    setMessageDetailsFlag(true);
    if (entityId) {
      props.getDeletedInboxMessagesForEntityTypeId(
        {
          userIdHash: props.auth.userProfile.userIdHash,
          entityTypeId: entityId,
          userTypeId: userTypeId,
          sortOldest: 0
        },
        (res) => {
          console.log("ARCHIVED INBOX MESSAGES For ENTITY ID", res);
          setMessageListLoading(false);
          setMessageDetailsFlag(false);
          if (res.status) {
            if (res.data.length) {
              setMessageData(res.data);
              setMessageDetailsData({ user: {}, message: {} });
              // getListOfMessagesForMessageThreadFn(
              //   res.data[0].messageInstanceId
              // );
            } else {
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          } else {
            toast.error("Something went wrong");
            setMessageData([]);
            setMessageDetailsData({ user: {}, message: {} });
          }
        }
      );
    } else {
      props.getDeletedInboxMessagesForAll(
        {
          userIdHash: props.auth.userProfile.userIdHash,
          userTypeId: userTypeId,
          sortOldest: 0
        },
        (res) => {
          console.log("ARCHIVED INBOX MESSAGES For ALL", res);
          setMessageListLoading(false);
          setMessageDetailsFlag(false);
          if (res.status) {
            if (res.data.length) {
              setMessageData(res.data);
              setMessageDetailsData({ user: {}, message: {} });
              // getListOfMessagesForMessageThreadFn(
              //   res.data[0].messageInstanceId
              // );
            } else {
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          } else {
            toast.error("Something went wrong");
            setMessageData([]);
            setMessageDetailsData({ user: {}, message: {} });
          }
        }
      );
    }
  };

  const getListOfNewMessagesForCompanies = (userType, entityId = null) => {
    setMessageListLoading(true);
    setMessageDetailsFlag(true);
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        setUserTypeId(res.data.id);
        getListOfNewMessages(entityId, res.data.id);
      }
    });
  };

  const getListOfNewMessagesForProfessionals = (userType, entityId = null) => {
    setMessageListLoading(true);
    setMessageDetailsFlag(true);
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        setUserTypeId(res.data.id);
        getListOfNewMessages(entityId, res.data.id);
      }
    });
  };

  const getListOfReadMessagesForCompanies = (userType, entityId = null) => {
    setMessageListLoading(true);
    setMessageDetailsFlag(true);
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        setUserTypeId(res.data.id);
        getListOfReadMessages(entityId, res.data.id);
      }
    });
  };

  const getListOfReadMessagesForProfessionals = (userType, entityId = null) => {
    setMessageListLoading(true);
    setMessageDetailsFlag(true);
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        setUserTypeId(res.data.id);
        getListOfReadMessages(entityId, res.data.id);
      }
    });
  };

  const getListOfArchivedMessagesForCompanies = (userType, entityId = null) => {
    setMessageListLoading(true);
    setMessageDetailsFlag(true);
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        setUserTypeId(res.data.id);
        getListOfArchivedMessages(entityId, res.data.id);
      }
    });
  };

  const getListOfArchivedMessagesForProfessionals = (
    userType,
    entityId = null
  ) => {
    setMessageListLoading(true);
    setMessageDetailsFlag(true);
    props.getUserTypeInfo({ userType: userType }, (res) => {
      if (res.status) {
        setUserTypeId(res.data.id);
        getListOfArchivedMessages(entityId, res.data.id);
      }
    });
  };

  const getListOfMessagesForMessageThreadFn = (messageInstanceId) => {
    setChatHistoryLoading(true);
    props.getListOfMessagesForMessageThread(
      {
        messageInstanceId: messageInstanceId,
        userIdHash: props.auth.userProfile.userIdHash,
      },
      (res) => {
        setChatHistoryLoading(false);
        if (res.status) {
          console.log("CHAT HISTORY", res);
          if (res.data.length) {
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].createdDate = getChatHistoryFormatedDate(
                res.data[i].createdDate
              );
              if (i == res.data.length - 1) {
                sortChatHistoryByDate(res.data);
              }
            }
          }
        } else {
          // console.log('COMING TO THE ELSE PART')
          // toast.error('Something went wrong')
          // setChatHistoryData([])
        }
      }
    );
  };

  const getChatHistoryFormatedDate = (date) => {
    let timestamp = Date.parse(date);
    let todayFullDate =
      new Date().getDate() +
      "-" +
      new Date().getMonth() +
      "-" +
      new Date().getFullYear();
    let newDate =
      new Date(timestamp).getDate() +
      "-" +
      new Date(timestamp).getMonth() +
      "-" +
      new Date(timestamp).getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let msgDate = new Date(timestamp).getDate();
    let msgMonth = new Date(timestamp).getMonth();
    let msgYear = new Date(timestamp).getFullYear();

    let dateToShow = monthNames[msgMonth] + " " + msgDate + " " + msgYear;

    let hours = new Date(timestamp).getHours();
    let minutes = new Date(timestamp).getMinutes();
    let seconds = new Date(timestamp).getSeconds();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let time =
      hours +
      ":" +
      minutes.toString().padStart(2, 0) +
      ":" +
      seconds.toString().padStart(2, 0) +
      " " +
      ampm;

    return dateToShow + " " + time;
  };

  const sortChatHistoryByDate = (arr) => {
    const sorter = (a, b) => {
      return (
        new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
      );
    };
    arr.sort(sorter);
    setChatHistoryData(arr);
    setMsgReplySubject(arr[arr.length - 1].subject);
    setMsgIdHash(arr[arr.length - 1].messageIdHash);
    divRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const searchInMessages = (value, userTypeId) => {
    props.searchMessages(
      {
        search: value,
        userIdHash: props.auth.userProfile.userIdHash,
        userTypeId: userTypeId,
        entityTypeId: entityTypeId ? entityTypeId : ''
      },
      (res) => {
        if (res.status) {
          setMessageData(res.data);
          setMessageDetailsData({ user: {}, message: {} });
        }
      }
    );
  };
  
  const searchInSpaces = (value, userTypeId) => {
    props.searchSpaces(
      {
        search: value,
        userIdHash: props.auth.userProfile.userIdHash,
        userTypeId: userTypeId,
      },
      (res) => {
        setListLoading(false)
        if (res.status) {
          setSpacesData(res.data);
        }
      }
    );
  };

  const searchInFollowing = (value, userTypeId) => {
    props.searchFollowing(
      {
        search: value,
        userIdHash: props.auth.userProfile.userIdHash,
        userTypeId: userTypeId,
      },
      (res) => {
        setListLoading(false)
        if (res.status) {
          setFollowingData(res.data);
        }
      }
    );
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value) {
      getHomeScreenList("", "", value);
    } else {
      getHomeScreenList("");
    }
  };

  const handleSearchMessagesProfessionals = (e) => {
    const { value } = e.target;
    if (value) {
      console.log(value);
      props.getUserTypeInfo({ userType: "employee" }, (res) => {
        if (res.status) {
          searchInMessages(value, res.data.id);
        }
      });
    } else {
      if(inboxNewFlag) {
        getListOfNewMessagesForProfessionals('employee', entityTypeId)
      }
      if(inboxReadFlag) {
        getListOfReadMessagesForProfessionals('employee', entityTypeId)
      }
      if(inboxArchivedFlag) {
        getListOfArchivedMessagesForProfessionals('employee', entityTypeId)
      }
    }
  };

  const handleSearchMessagesCompanies = (e) => {
    const { value } = e.target;
    if (value) {
      console.log(value);
      props.getUserTypeInfo({ userType: "employer" }, (res) => {
        if (res.status) {
          searchInMessages(value, res.data.id);
        }
      });
    } else {
      if(inboxNewFlag) {
        getListOfNewMessagesForCompanies('employer', entityTypeId)
      }
      if(inboxReadFlag) {
        getListOfReadMessagesForCompanies('employer', entityTypeId)
      }
      if(inboxArchivedFlag) {
        getListOfArchivedMessagesForCompanies('employer', entityTypeId)
      }
    }
  };
  
  const handleSearchSpacesProfessionals = (e) => {
    const { value } = e.target;
    if (value) {
      console.log(value);
      setListLoading(true)
      props.getUserTypeInfo({ userType: "employee" }, (res) => {
        if (res.status) {
          setUserTypeId(res.data.id)
          searchInSpaces(value, res.data.id);
        }
      });
    } else {
      if (entityTypeId) {
        props.getSpaceDataForProfessionalsForEntityId(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId ? userTypeId : 2,
            entityTypeId: entityTypeId,
          },
          (res) => {
            console.log("SPACES LIST For ENTITY ID", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setSpacesData(res.data);
              } else {
                setSpacesData([]);
              }
            } else {
              toast.error("Something went wrong");
              setSpacesData([]);
            }
          }
        );
      } else {
        props.getSpaceDataForProfessionalsForAll(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId ? userTypeId : 2,
          },
          (res) => {
            console.log("SPACES LIST", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setSpacesData(res.data);
              } else {
                setSpacesData([]);
              }
            } else {
              toast.error("Something went wrong");
              setSpacesData([]);
            }
          }
        );
      }
    }
  };

  const handleSearchSpacesCompanies = (e) => {
    const { value } = e.target;
    if (value) {
      console.log(value);
      setListLoading(true)
      props.getUserTypeInfo({ userType: "employer" }, (res) => {
        if (res.status) {
          setUserTypeId(res.data.id)
          searchInSpaces(value, res.data.id);
        }
      });
    } else {
      if (entityTypeId) {
        props.getSpaceDataForCompaniesForEntityId(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId ? userTypeId : 1,
            entityTypeId: entityTypeId,
          },
          (res) => {
            console.log("SPACES LIST Of Companies For ENTITY ID", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setSpacesData(res.data);
              } else {
                setSpacesData([]);
              }
            } else {
              toast.error("Something went wrong");
              setSpacesData([]);
            }
          }
        );
      } else {
        props.getSpaceDataForCompaniesForAll(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId ? userTypeId : 1,
          },
          (res) => {
            console.log("SPACES LIST of Companies", res);
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                setSpacesData(res.data);
              } else {
                setSpacesData([]);
              }
            } else {
              toast.error("Something went wrong");
              setSpacesData([]);
            }
          }
        );
      }
    }
  };

  const handleSearchFollowingProfessionals = (e) => {
    const { value } = e.target;
    if (value) {
      console.log(value);
      setListLoading(true)
      props.getUserTypeInfo({ userType: "employee" }, (res) => {
        if (res.status) {
          setUserTypeId(res.data.id)
          searchInFollowing(value, res.data.id);
        }
      });
    } else {
      if(myFollowersFlag) {
        props.getFollowersListForAll(
          { userTypeId: userTypeId ? userTypeId : 2, userIdHash: props.auth.userProfile.userIdHash },
          (res) => {
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                console.log("List of Followers For All", res);
                setFollowingData(res.data);
              } else {
                setFollowingData([]);
              }
            } else {
              toast.error("Something went wrong");
              setFollowingData([]);
            }
          }
        );
      } else {
        props.getFollowingListForAll(
          { userTypeId: userTypeId ? userTypeId : 2, userIdHash: props.auth.userProfile.userIdHash },
          (res) => {
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                console.log("List of Following Users For All", res);
                setFollowingData(res.data);
              } else {
                setFollowingData([]);
              }
            } else {
              toast.error("Something went wrong");
              setFollowingData([]);
            }
          }
        );
      }
    }
  };

  const handleSearchFollowingCompanies = (e) => {
    const { value } = e.target;
    if (value) {
      console.log(value);
      setListLoading(true)
      props.getUserTypeInfo({ userType: "employer" }, (res) => {
        if (res.status) {
          setUserTypeId(res.data.id)
          searchInFollowing(value, res.data.id);
        }
      });
    } else {
      if(myFollowersFlag) {
        props.getFollowersListForAll(
          { userTypeId: userTypeId ? userTypeId : 1, userIdHash: props.auth.userProfile.userIdHash },
          (res) => {
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                console.log("List of Followers For All", res);
                setFollowingData(res.data);
              } else {
                setFollowingData([]);
              }
            } else {
              toast.error("Something went wrong");
              setFollowingData([]);
            }
          }
        );
      } else {
        props.getFollowingListForAll(
          { userTypeId: userTypeId ? userTypeId : 1, userIdHash: props.auth.userProfile.userIdHash },
          (res) => {
            setListLoading(false);
            if (res.status) {
              if (res.data.length) {
                console.log("List of Following Users For All", res);
                setFollowingData(res.data);
              } else {
                setFollowingData([]);
              }
            } else {
              toast.error("Something went wrong");
              setFollowingData([]);
            }
          }
        );
      }
      
    }
  };

  const optimizedSearch = useCallback(debounce(handleSearch), []);
  const optimizedSearchMessagesProfessionals = useCallback(
    debounce(handleSearchMessagesProfessionals),
    [inboxNewFlag, inboxReadFlag, inboxArchivedFlag, entityTypeId, userTypeId]
  );
  const optimizedSearchMessagesCompanies = useCallback(
    debounce(handleSearchMessagesCompanies),
    [inboxNewFlag, inboxReadFlag, inboxArchivedFlag, entityTypeId, userTypeId]
  );
  const optimizedSearchSpacesProfessionals = useCallback(
    debounce(handleSearchSpacesProfessionals),
    [professionalsFlag, companiesFlag, entityTypeId, userTypeId]
  );
  const optimizedSearchSpacesCompanies = useCallback(
    debounce(handleSearchSpacesCompanies),
    [professionalsFlag, companiesFlag, entityTypeId, userTypeId]
  );
  const optimizedSearchFollowingProfessionals = useCallback(
    debounce(handleSearchFollowingProfessionals),
    [professionalsFlag, companiesFlag, meFollowingFlag, myFollowersFlag, entityTypeId, userTypeId]
  );
  const optimizedSearchFollowingCompanies = useCallback(
    debounce(handleSearchFollowingCompanies),
    [professionalsFlag, companiesFlag, meFollowingFlag, myFollowersFlag, entityTypeId, userTypeId]
  );

  const followingStyleActive = () => {
    return {
      border: "0px",
      borderRadius: "22px",
      color: "white",
      paddingLeft: "20px",
      paddingRight: "22px",
      background: "#3F4254 0% 0% no-repeat padding-box",
      cursor: "pointer",
      paddingTop: "12px",
      paddingBottom: "12px",
    };
  };
  const followingStyleInActive = () => {
    return {
      border: "0px",
      borderRadius: "22px",
      color: "black",
      paddingLeft: "20px",
      paddingRight: "22px",
      background: "#E3E3E5",
      cursor: "pointer",
      paddingTop: "12px",
      paddingBottom: "12px",
    };
  };
  const spacesStyleActive = () => {
    return {
      border: "0px",
      borderRadius: "22px",
      color: "white",
      paddingLeft: "20px",
      paddingRight: "20px",
      background: "#3F4254 0% 0% no-repeat padding-box",
      cursor: "pointer",
      paddingTop: "12px",
      paddingBottom: "12px",
    };
  };
  const spacesStyleInActive = () => {
    return {
      border: "0px",
      borderRadius: "22px",
      color: "black",
      paddingLeft: "20px",
      paddingRight: "20px",
      background: "#E3E3E5",
      cursor: "pointer",
      paddingTop: "12px",
      paddingBottom: "12px",
    };
  };
  const messagesStyleActive = () => {
    return {
      border: "0px",
      borderRadius: "22px",
      color: "white",
      paddingLeft: "20px",
      paddingRight: "24px",
      background: "#3F4254 0% 0% no-repeat padding-box",
      cursor: "pointer",
      paddingTop: "12px",
      paddingBottom: "12px",
    };
  };
  const messagesStyleInActive = () => {
    return {
      border: "0px",
      borderRadius: "22px",
      color: "black",
      paddingLeft: "20px",
      paddingRight: "24px",
      background: "#E3E3E5",
      cursor: "pointer",
      paddingTop: "12px",
      paddingBottom: "12px",
    };
  };

  const professionalsStyleActive = () => {
    return {
      border: "1px solid #3DBF04",
      borderRadius: "0px",
      color: "#3DBF04",
      paddingLeft: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingRight: "20px",
      cursor: "pointer",
      background: "#F6F6F6 0% 0% no-repeat padding-box",
    };
  };
  const professionalsStyleInActive = () => {
    return {
      border: "1px solid #EBEBEB",
      borderRadius: "0px",
      color: "black",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      cursor: "pointer",
      background: "#F6F6F6 0% 0% no-repeat padding-box",
    };
  };
  const companiesStyleActive = () => {
    return {
      border: "1px solid #3DBF04",
      borderRadius: "0px",
      color: "#3DBF04",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      cursor: "pointer",
      background: "#F6F6F6 0% 0% no-repeat padding-box",
    };
  };
  const companiesStyleInActive = () => {
    return {
      border: "1px solid #EBEBEB",
      borderRadius: "0px",
      color: "black",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      cursor: "pointer",
      background: "#F6F6F6 0% 0% no-repeat padding-box",
    };
  };
  const meStyleActive = () => {
    return {
      border: "1px solid #3DBF04",
      borderRadius: "0px",
      color: "#3DBF04",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      cursor: "pointer",
      background: "#F6F6F6 0% 0% no-repeat padding-box",
    };
  };
  const meStyleInActive = () => {
    return {
      border: "1px solid #EBEBEB",
      borderRadius: "0px",
      color: "black",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "10px",
      paddingBottom: "10px",
      cursor: "pointer",
      background: "#F6F6F6 0% 0% no-repeat padding-box",
    };
  };
  const statusUpdateStyleActive = () => {
    return {
      border: "0px",
      borderRadius: "22px",
      color: "white",
      paddingLeft: "20px",
      paddingRight: "24px",
      background: "#3F4254 0% 0% no-repeat padding-box",
      cursor: "pointer",
      paddingTop: "12px",
      paddingBottom: "12px",
    };
  };
  const statusUpdateStyleInActive = () => {
    return {
      border: "0px",
      borderRadius: "22px",
      color: "black",
      paddingLeft: "20px",
      paddingRight: "24px",
      background: "#E3E3E5",
      cursor: "pointer",
      paddingTop: "12px",
      paddingBottom: "12px",
    };
  };

  const meFollowingStyleActive = () => {
    return {
      color: "#3DBF04",
      cursor: "pointer",
    };
  };
  const meFollowingStyleInActive = () => {
    return {
      color: "black",
      cursor: "pointer",
    };
  };
  const myFollowersStyleActive = () => {
    return {
      color: "#3DBF04",
      cursor: "pointer",
    };
  };
  const myFollowersStyleInActive = () => {
    return {
      color: "black",
      cursor: "pointer",
    };
  };
  const createPostStyle = () => {
    return {
      border: "1px solid #EBEBEB",
      borderRadius: "8px",
      color: "black",
      paddingLeft: "20px",
      paddingRight: "35px",
      paddingTop: "10px",
      background: "#FFF 0% 0% no-repeat padding-box",
      position: "relative",
      cursor: "pointer",
      height: "42px",
      maxWidth: "350px",
      marginLeft: "15px"
    };
  };
  const whatInMindStyle = () => {
    return {
      border: "1px solid #EBEBEB",
      borderRadius: "8px",
      color: "black",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "10px",
      background: "#FFF 0% 0% no-repeat padding-box",
      position: "relative",
      cursor: "pointer",
      height: "42px",
      maxWidth: "350px",
      marginLeft: "15px"
    };
  };
  const inboxStyleLoading = () => {
    return {
      background: "white",
      borderRadius: "6px",
      maxHeight: "550px",
      minHeight: "550px",
    };
  };
  const inboxStyle = () => {
    return {
      background: "white",
      borderRadius: "6px",
      maxHeight: "550px",
      minHeight: "550px",
      overflowY: "scroll",
    };
  };
  const chatHistoryStyleLoading = () => {
    return {
      maxHeight: "372px",
      minHeight: "372px",
    };
  };
  const chatHistoryStyle = () => {
    return {
      maxHeight: "372px",
      minHeight: "372px",
      overflowY: "scroll",
    };
  };

  const fieldClicked = (flag) => {
    if (flag) {
      if (flag == "following") {
        setListLoading(true);
        setFollowingFlag(true);
        setSpacesFlag(false);
        setMessagesFlag(false);
        setStatusUpdateFlag(false);
        if (meFlag) {
          setMeFlag(false);
          setProfessionalsFlag(true);
          setUserType("employee", entityTypeId);
          getUserTypeInfoData("employee", entityTypeId);
        } else {
          getUserTypeInfoData(userType, entityTypeId);
        }
      } else if (flag == "spaces") {
        setListLoading(true);
        setFollowingFlag(false);
        setSpacesFlag(true);
        setMessagesFlag(false);
        setStatusUpdateFlag(false);
        getSpacesData(entityTypeId);
      } else if (flag == "messages") {
        setMessageListLoading(true);
        setFollowingFlag(false);
        setSpacesFlag(false);
        setMessagesFlag(true);
        setStatusUpdateFlag(false);
        setInboxNewFlag(true);
        setInboxReadFlag(false);
        setInboxArchivedFlag(false);
        if (meFlag) {
          setMeFlag(false);
          setProfessionalsFlag(true);
          setUserType("employee");
          getUserTypeInfoData("employee", entityTypeId);
          getListOfNewMessagesForProfessionals("employee", entityTypeId);
        } else {
          getUserTypeInfoData(userType, entityTypeId);
          getListOfNewMessages(entityTypeId, userTypeId);
        }
      } else if (flag == "statusUpdate") {
        setListLoading(true);
        setFollowingFlag(false);
        setSpacesFlag(false);
        setMessagesFlag(false);
        setStatusUpdateFlag(true);
        // getHomeScreenList(entityTypeId, '', '', 'statusUpdateClicked')
        getStatusUpdateData(entityTypeId);
      } else if (flag == "meFollowing") {
        setListLoading(true);
        setMeFollowingFlag(true);
        setMyFollowersFlag(false);
        getHomeScreenList(entityTypeId, "", "", "meFollowing");
      } else if (flag == "myFollowers") {
        setName("myFollowers");
        setListLoading(true);
        setMyFollowersFlag(true);
        setMeFollowingFlag(false);
        getHomeScreenList(entityTypeId, "", "", "myFollowers");
      } else if (flag == "professionals") {
        setListLoading(true);
        setProfessionalsFlag(true);
        setCompaniesFlag(false);
        setMeFlag(false);
        setMessageListLoading(true);
        setMessageDetailsFlag(true);
        props.getUserTypeInfo({ userType: "employee" }, (res) => {
          if (res.status) {
            setUserTypeId(res.data.id);
            setUserType("employee");
            if (spacesFlag) {
              getHomeScreenList(entityTypeId, "", "", "professionalsClicked");
            } else if (statusUpdateFlag) {
              getHomeScreenList(entityTypeId, "", "", "professionalsClicked");
            } else if (messagesFlag) {
              getHomeScreenList(entityTypeId, "", "", "professionalsClicked");
            } else {
              getUserTypeInfoData("employee", entityTypeId);
            }
          } else {
            toast.error("Something went wrong");
          }
        });
      } else if (flag == "companies") {
        setListLoading(true);
        setProfessionalsFlag(false);
        setCompaniesFlag(true);
        setMeFlag(false);
        setMessageListLoading(true);
        setMessageDetailsFlag(true);
        props.getUserTypeInfo({ userType: "employer" }, (res) => {
          if (res.status) {
            setUserTypeId(res.data.id);
            setUserType("employer");
            if (spacesFlag) {
              getHomeScreenList(entityTypeId, "", "", "companiesClicked");
            } else if (statusUpdateFlag) {
              getHomeScreenList(entityTypeId, "", "", "companiesClicked");
            } else if (messagesFlag) {
              getHomeScreenList(entityTypeId, "", "", "companiesClicked");
            } else {
              getUserTypeInfoData("employer", entityTypeId);
            }
          }
        });
      } else if (flag == "me") {
        setListLoading(true);
        setProfessionalsFlag(false);
        setCompaniesFlag(false);
        setMeFlag(true);
        setUserType("");
        getHomeScreenList(entityTypeId, "", "", "meClicked");
      } else if (flag == "followingConnection") {
        setFollowersConnectionFlag(false);
        setFollowingConnectionFlag(true);
      } else if (flag == "followersConnection") {
        setFollowersConnectionFlag(true);
        setFollowingConnectionFlag(false);
      }
    }
  };

  const createPostBtnClicked = () => {
    if (entityTypeId) {
      setSpaceTitle();
      setSpaceDesc();
      setCreatePostFlag(true);
      setSpaceCategoriesLoading(true);
      props.getSpaceCategories({}, (res) => {
        if (res.status) {
          setSpaceCategoriesLoading(false);
          if (res.data.length) {
            setSpaceCategoriesData(res.data);
            setSpaceCategoryId(res.data[0].id);
            let elem = document.getElementById(res.data[0].name);
            res.data.map((sc) => {
              if (sc.name == elem.id) {
                document.getElementById(sc.name + "img").src = greenCircle;
                elem.style.border = "0px solid #EBEBEB";
                elem.style.borderRadius = "8px";
                elem.style.color = "#3DBF04";
                elem.style.paddingLeft = "20px";
                elem.style.paddingRight = "20px";
                elem.style.paddingTop = "10px";
                elem.style.paddingBottom = "10px";
                elem.style.background = "lightcyan 0% 0% no-repeat padding-box";
                elem.style.position = "relative";
                elem.style.cursor = "pointer";
              } else {
                document.getElementById(sc.name + "img").src = greyCircle;
                document.getElementById(sc.name).style.border =
                  "0px solid #EBEBEB";
                document.getElementById(sc.name).style.borderRadius = "8px";
                document.getElementById(sc.name).style.color = "black";
                document.getElementById(sc.name).style.paddingLeft = "20px";
                document.getElementById(sc.name).style.paddingRight = "20px";
                document.getElementById(sc.name).style.paddingTop = "10px";
                document.getElementById(sc.name).style.paddingBottom = "10px";
                document.getElementById(sc.name).style.background =
                  "gainsboro 0% 0% no-repeat padding-box";
                document.getElementById(sc.name).style.position = "relative";
                document.getElementById(sc.name).style.cursor = "pointer";
              }
            });
          } else {
            setSpaceCategoriesData([]);
          }
        } else {
          setSpaceCategoriesLoading(false);
          toast.error("Something went wrong");
        }
      });
    } else {
      toast.error("Please select a specific profession");
    }
  };

  const whatInYourMindBtnClicked = () => {
    if (entityTypeId) {
      setWhatInYourMindStatus();
      setWhatInYourMindFlag(true);
    } else {
      toast.error("Please select a specific profession");
    }
  };

  const backToMainPage = () => {
    setCreatePostFlag(false);
    setEditPostFlag(false);
    setWhatInYourMindFlag(false);
    setPostDetailsFlag(false);
    setStartNewConversationFlag(false);
    setSpacesFlagForIndividual(false);
    setConnectionFlag(false);
    setSpaceIdHash();
    setFollowIdHash();
    setSpaceUserInfo({});
    setMessageReceiverName();
    setMsgSubject();
    setMsg();
  };

  const spaceCategoryChanged = (value) => {
    spaceCategoriesData.map((sc) => {
      if (sc.name == value.name) {
        document.getElementById(value.name + "img").src = greenCircle;
        document.getElementById(value.name).style.border = "0px solid #EBEBEB";
        document.getElementById(value.name).style.borderRadius = "8px";
        document.getElementById(value.name).style.color = "#3DBF04";
        document.getElementById(value.name).style.paddingLeft = "20px";
        document.getElementById(value.name).style.paddingRight = "20px";
        document.getElementById(value.name).style.paddingTop = "10px";
        document.getElementById(value.name).style.paddingBottom = "10px";
        document.getElementById(value.name).style.background =
          "lightcyan 0% 0% no-repeat padding-box";
        document.getElementById(value.name).style.position = "relative";
        document.getElementById(value.name).style.cursor = "pointer";
      } else {
        document.getElementById(sc.name + "img").src = greyCircle;
        document.getElementById(sc.name).style.border = "0px solid #EBEBEB";
        document.getElementById(sc.name).style.borderRadius = "8px";
        document.getElementById(sc.name).style.color = "black";
        document.getElementById(sc.name).style.paddingLeft = "20px";
        document.getElementById(sc.name).style.paddingRight = "20px";
        document.getElementById(sc.name).style.paddingTop = "10px";
        document.getElementById(sc.name).style.paddingBottom = "10px";
        document.getElementById(sc.name).style.background =
          "gainsboro 0% 0% no-repeat padding-box";
        document.getElementById(sc.name).style.position = "relative";
        document.getElementById(sc.name).style.cursor = "pointer";
      }
    });
    setSpaceCategoryId(value.id);
  };

  const postDataChanged = (e, flag) => {
    e.preventDefault();
    setSpacePostBtnDisabled(false);
    let value = e.target.value;
    if (flag) {
      if (flag == "title") {
        setSpaceTitle(value);
      } else if (flag == "desc") {
        setSpaceDesc(value);
      }
    }
  };

  const createNewSpacePost = (e) => {
    e.preventDefault();
    if (spaceTitle == "" || spaceTitle == undefined || spaceTitle == null) {
      toast.error("Please enter Title of the Post");
      return;
    }
    if (spaceDesc == "" || spaceDesc == undefined || spaceDesc == null) {
      toast.error("Please enter Description of the Post");
      return;
    }
    let params = {
      title: spaceTitle,
      description: spaceDesc,
      spaceTypeId: spaceCategoryId,
      userIdHash: props.auth.userProfile.userIdHash,
      entityId: entityTypeId,
    };

    setSpacePostBtnDisabled(true);
    setSpaceCategoriesLoading(true);

    if (editPostFlag) {
      params = { ...params, spaceIdHash: spaceIdHash };
      props.updateSpacePost(params, (res) => {
        setSpacePostBtnDisabled(false);
        if (res.status) {
          swal({
            text: `Post updated successfully`,
            icon: "success",
          }).then((val) => {
            setCreatePostFlag(false);
            setEditPostFlag(false);
            setPostDetailsFlag(false);
            setSpaceIdHash();
            getSpacesData(entityTypeId);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    } else {
      props.createSpacePost(params, (res) => {
        setSpaceCategoriesLoading(false);
        setSpacePostBtnDisabled(false);
        if (res.status) {
          setSpaceTitle();
          setSpaceDesc();
          createPostBtnClicked();
          swal({
            text: `New post created successfully`,
            icon: "success",
          }).then((val) => {
            setCreatePostFlag(false);
            setSpaceIdHash();
            getSpacesData(entityTypeId);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    }
  };

  const whatInYourMindStatusChanged = (e, flag) => {
    e.preventDefault();
    setUpdateStatusBtnDisabled(false);
    let value = e.target.value;
    if (flag) {
      if (flag == "status") {
        setWhatInYourMindStatus(value);
      }
    }
  };

  const updateWhatInYourMindStatus = (e) => {
    e.preventDefault();
    if (
      whatInYourMindStatus == "" ||
      whatInYourMindStatus == undefined ||
      whatInYourMindStatus == null
    ) {
      toast.error("Please enter Status");
      return;
    }

    let params = {
      description: whatInYourMindStatus,
      userIdHash: props.auth.userProfile.userIdHash,
      entityId: entityTypeId,
    };

    setUpdateStatusBtnDisabled(true);
    setWhatInYourMindStatusLoading(true);
    props.createWhatsOnMyMindStatus(params, (res) => {
      setUpdateStatusBtnDisabled(false);
      setWhatInYourMindStatusLoading(false);
      if (res.status) {
        swal({
          text: `Status updated successfully`,
          icon: "success",
        }).then((val) => {
          setWhatInYourMindFlag(false);
          getHomeScreenList(entityTypeId, "", "", "meClicked");
        });
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const viewLessDescStyle = () => {
    return {
      lineHeight: "1.2em",
      overflow: "hidden",
      webkitLineClamp: "3",
      display: "-webkit-box",
      webkitBoxOrient: "vertical",
      color: "#868686",
      height: "48px",
    };
  };

  const viewMoreBtnClicked = (e, value) => {
    e.preventDefault();

    if (value.spaceTypeInfoData.isPrivate) {
      if (professionalsFlag) {
        swal({
          text: `As you are not following ${value.userInfoData?.userInformation?.firstName} ${value.userInfoData?.userInformation?.lastName} and this is a private post, so you have to firstly follow ${value.userInfoData?.userInformation?.firstName} ${value.userInfoData?.userInformation?.lastName} to see more details about this post`,
          buttons: {
            follow: {
              text: "Follow",
              className: "swalBtnFollow",
              value: "follow",
            },
            cancel: "No, Thanks",
          },
        }).then((val) => {
          switch (val) {
            case "cancel":
              break;
            case "follow":
              setListLoading(true);
              props.followFromSpaces(
                {
                  userIdHash: props.auth.userProfile.userIdHash,
                  entityTypeId: value.userSelectedEntityTypeId,
                  userNameHash: value.userInfoData?.userInfo?.userNameHash,
                },
                (res) => {
                  setListLoading(false);
                  if (res.status) {
                    swal({
                      text: `You have followed ${value.userInfoData?.userInformation?.firstName} ${value.userInfoData?.userInformation?.lastName}`,
                      buttons: {
                        ok: "Ok",
                      },
                    }).then((val) => {
                      switch (val) {
                        case "ok":
                          getSpacesData(entityTypeId);
                          break;
                      }
                    });
                  }
                }
              );
              break;
          }
        });
      } else if (companiesFlag) {
        swal({
          text: `As you are not following ${value.userInfoData?.userInformation?.entityName} and this is a private post, so you have to firstly follow ${value.userInfoData?.userInformation?.entityName} to see more details about this post`,
          buttons: {
            follow: {
              text: "Follow",
              className: "swalBtnFollow",
              value: "follow",
            },
            cancel: "No, Thanks",
          },
        }).then((val) => {
          switch (val) {
            case "cancel":
              break;
            case "follow":
              setListLoading(true);
              props.followFromSpaces(
                {
                  userIdHash: props.auth.userProfile.userIdHash,
                  entityTypeId: value.userSelectedEntityTypeId,
                  userNameHash: value.userInfoData?.userInfo?.userNameHash,
                },
                (res) => {
                  setListLoading(false);
                  if (res.status) {
                    swal({
                      text: `You have followed ${value.userInfoData?.userInformation?.entityName}`,
                      buttons: {
                        ok: "Ok",
                      },
                    }).then((val) => {
                      switch (val) {
                        case "ok":
                          getSpacesData(entityTypeId);
                          break;
                      }
                    });
                  } else {
                    toast.error("Something went wrong");
                  }
                }
              );
              break;
          }
        });
      } else if (meFlag) {
        swal({
          text: `As you are not following ${value.userInfoData?.userInformation?.firstName} ${value.userInfoData?.userInformation?.lastName} and this is a private post, so you have to firstly follow ${value.userInfoData?.userInformation?.firstName} ${value.userInfoData?.userInformation?.lastName} to see more details about this post`,
          buttons: {
            follow: {
              text: "Follow",
              className: "swalBtnFollow",
              value: "follow",
            },
            cancel: "No, Thanks",
          },
        }).then((val) => {
          switch (val) {
            case "cancel":
              break;
            case "follow":
              setListLoading(true);
              props.followFromSpaces(
                {
                  userIdHash: props.auth.userProfile.userIdHash,
                  entityTypeId: value.userSelectedEntityTypeId,
                  userNameHash: value.userInfoData?.userInfo?.userNameHash,
                },
                (res) => {
                  setListLoading(false);
                  if (res.status) {
                    swal({
                      text: `You have followed ${value.userInfoData?.userInformation?.firstName} ${value.userInfoData?.userInformation?.lastName}`,
                      buttons: {
                        ok: "Ok",
                      },
                    }).then((val) => {
                      switch (val) {
                        case "ok":
                          getSpacesData(entityTypeId);
                          break;
                      }
                    });
                  }
                }
              );
              break;
          }
        });
      }
    } else {
      // let elem = document.getElementById(value.spaceIdHash)
      // let innerHtml = document.getElementById(`viewMore${value.spaceIdHash}`).innerHTML
      // if (innerHtml == 'View Less') {
      //     elem.style.lineHeight = '1.2em'
      //     elem.style.overflow = 'hidden'
      //     elem.style.webkitLineClamp = '1'
      //     elem.style.display = '-webkit-box'
      //     elem.style.webkitBoxOrient = 'vertical'
      //     document.getElementById(`viewMore${value.spaceIdHash}`).innerHTML = "View More"
      // } else {
      //     elem.style.overflow = null
      //     elem.style.webkitLineClamp = null
      //     elem.style.display = null
      //     elem.style.webkitBoxOrient = null
      //     document.getElementById(`viewMore${value.spaceIdHash}`).innerHTML = "View Less"
      // }
      setPostDetailsData(value);
      setPostDetailsFlag(true);
    }
  };

  const viewMoreBtnClickedForMeSpaces = (e, value) => {
    setPostDetailsData(value);
    setPostDetailsFlag(true);
  };

  const viewMoreBtnClickedForMeStatusUpdate = (e, value) => {
    if (!entityTypeId) {
      toast.error("Please select a specific profession first");
      return;
    }
    setWhatInYourMindStatus(value.description);
    setWhatInYourMindFlag(true);
  };

  const followProfileFromSpaces = (value) => {
    let params = {
      userIdHash: props.auth.userProfile.userIdHash,
      entityTypeId: value.userSelectedEntityTypeId,
      userNameHash: value.userInfoData?.userInfo?.userNameHash,
    };
    if (professionalsFlag) {
      swal({
        text: `Are you sure you want to follow ${value.userInfoData?.userInformation?.firstName} ${value.userInfoData?.userInformation?.lastName}?`,
        icon: "warning",
        buttons: true,
        // dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setListLoading(true);
          props.followFromSpaces(params, (res) => {
            setListLoading(false);
            if (res.status) {
              swal({
                text: `You followed ${value.userInfoData?.userInformation?.firstName} ${value.userInfoData?.userInformation?.lastName} successfully`,
                icon: "success",
              }).then((onUpdate) => {
                getSpacesData(entityTypeId);
              });
            } else {
              toast.error("Something went wrong");
              setListLoading(false);
            }
          });
        }
      });
    } else if (companiesFlag) {
      swal({
        text: `Are you sure you want to follow ${value.userInfoData?.userInformation?.entityName}?`,
        icon: "warning",
        buttons: true,
        // dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setListLoading(true);
          props.followFromSpaces(params, (res) => {
            setListLoading(false);
            if (res.status) {
              swal({
                text: `You followed ${value.userInfoData?.userInformation?.entityName} successfully`,
                icon: "success",
              }).then((onUpdate) => {
                getSpacesData(entityTypeId);
              });
            } else {
              toast.error("Something went wrong");
              setListLoading(false);
            }
          });
        }
      });
    } else if (meFlag) {
      swal({
        text: `Are you sure you want to follow ${value.userInfoData?.userInformation?.firstName} ${value.userInfoData?.userInformation?.lastName}?`,
        icon: "warning",
        buttons: true,
        // dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setListLoading(true);
          props.followFromSpaces(params, (res) => {
            setListLoading(false);
            if (res.status) {
              swal({
                text: `You followed ${value.userInfoData?.userInformation?.firstName} ${value.userInfoData?.userInformation?.lastName} successfully`,
                icon: "success",
              }).then((onUpdate) => {
                getSpacesData(entityTypeId);
              });
            } else {
              toast.error("Something went wrong");
              setListLoading(false);
            }
          });
        }
      });
    }
  };

  const openPopup = (e, value) => {
    e.preventDefault();
    let elem = document.getElementById(value.spaceIdHash + "popup");
    elem.style.display = "block";
  };

  const closePopup = (e, value) => {
    e.preventDefault();
    let elem = document.getElementById(value.spaceIdHash + "popup");
    elem.style.display = "none";
  };

  const openSortPopUp = (e) => {
    e.preventDefault();
    let elem = document.getElementById("sortBtnId");
    elem.style.display = "block";
  }
  
  const closeSortPopUp = (e) => {
    e.preventDefault();
    let elem = document.getElementById("sortBtnId");
    elem.style.display = "none";
  }

  const openMsgPopup = (e, value) => {
    e.preventDefault();
    let elem = document.getElementById(value.messageIdHash + "popup");
    elem.style.display = "block";
  };

  const closeMsgPopup = (e, value) => {
    e.preventDefault();
    let elem = document.getElementById(value.messageIdHash + "popup");
    elem.style.display = "none";
  };

  const openMsgDetailsPopup = (e, value) => {
    e.preventDefault();
    let elem = document.getElementById(value.messageIdHash + "popupMsgDetails");
    elem.style.display = "block";
  };

  const closeMsgDetailsPopup = (e, value) => {
    e.preventDefault();
    let elem = document.getElementById(value.messageIdHash + "popupMsgDetails");
    elem.style.display = "none";
  };

  const openMsgDetailsPopupSingle = (e, value) => {
    e.preventDefault();
    let elem = document.getElementById(
      value.messageIdHash + "popupMsgDetailsSingle"
    );
    elem.style.display = "block";
  };

  const closeMsgDetailsPopupSingle = (e, value) => {
    e.preventDefault();
    let elem = document.getElementById(
      value.messageIdHash + "popupMsgDetailsSingle"
    );
    elem.style.display = "none";
  };

  const deleteSpacePostData = (e, value) => {
    e.preventDefault();

    swal({
      text: `Are you sure you want to delete this post?`,
      icon: "warning",
      buttons: true,
    }).then((val) => {
      if (val) {
        setListLoading(true);
        setPostDetailsFlag(false);
        props.deleteSpacePost({ spaceIdHash: value.spaceIdHash }, (res) => {
          if (res.status) {
            swal({
              text: `Post deleted successfully`,
              icon: "success",
            }).then((val) => {
              if (val) {
                getSpacesData(entityTypeId);
                setPostDetailsFlag(false);
              } else {
                getSpacesData(entityTypeId);
                setPostDetailsFlag(false);
              }
            });
          } else {
            toast.error("Something went wrong");
            setListLoading(false);
          }
        });
      }
    });
  };

  const editPostBtnClicked = (e, value) => {
    e.preventDefault();
    if (entityTypeId || postDetailsFlag) {
      setEditPostFlag(true);
      setCreatePostFlag(true);
      setSpaceCategoriesLoading(true);
      setSpacePostBtnDisabled(true);
      setSpaceTitle(value.title);
      setSpaceDesc(value.description);
      setSpaceCategoryId(value.spaceTypeId);
      setSpaceIdHash(value.spaceIdHash);
      props.getSpaceCategories({}, (res) => {
        if (res.status) {
          setSpaceCategoriesLoading(false);
          if (res.data.length) {
            setSpaceCategoriesData(res.data);
            setSpaceCategoryId(res.data[0].id);
            let elem = document.getElementById(value.spaceTypeInfoData.name);
            res.data.map((sc) => {
              if (sc.name == elem.id) {
                document.getElementById(sc.name + "img").src = greenCircle;
                elem.style.border = "0px solid #EBEBEB";
                elem.style.borderRadius = "8px";
                elem.style.color = "#3DBF04";
                elem.style.paddingLeft = "20px";
                elem.style.paddingRight = "20px";
                elem.style.paddingTop = "10px";
                elem.style.paddingBottom = "10px";
                elem.style.background = "lightcyan 0% 0% no-repeat padding-box";
                elem.style.position = "relative";
                elem.style.cursor = "pointer";
              } else {
                document.getElementById(sc.name + "img").src = greyCircle;
                document.getElementById(sc.name).style.border =
                  "0px solid #EBEBEB";
                document.getElementById(sc.name).style.borderRadius = "8px";
                document.getElementById(sc.name).style.color = "black";
                document.getElementById(sc.name).style.paddingLeft = "20px";
                document.getElementById(sc.name).style.paddingRight = "20px";
                document.getElementById(sc.name).style.paddingTop = "10px";
                document.getElementById(sc.name).style.paddingBottom = "10px";
                document.getElementById(sc.name).style.background =
                  "gainsboro 0% 0% no-repeat padding-box";
                document.getElementById(sc.name).style.position = "relative";
                document.getElementById(sc.name).style.cursor = "pointer";
              }
            });
          } else {
            setSpaceCategoriesData([]);
          }
        } else {
          setSpaceCategoriesLoading(false);
          toast.error("Something went wrong");
        }
      });
    } else {
      toast.error("Please select a specific profession");
    }
  };

  const changeMessageCategory = (e, flag) => {
    e.preventDefault();
    if (flag) {
      if (flag === "new") {
        setInboxNewFlag(true);
        setInboxReadFlag(false);
        setInboxArchivedFlag(false);
        getListOfNewMessages(entityTypeId, userTypeId);
      } else if (flag === "read") {
        setInboxNewFlag(false);
        setInboxReadFlag(true);
        setInboxArchivedFlag(false);
        getListOfReadMessages(entityTypeId, userTypeId);
      } else if (flag === "archived") {
        setInboxNewFlag(false);
        setInboxReadFlag(false);
        setInboxArchivedFlag(true);
        getListOfArchivedMessages(entityTypeId, userTypeId);
      }
    }
  };

  const showMessageDetails = (e, value) => {
    e.preventDefault();
    console.log("MESSAGE DETAILS", value);
    setMessageDetailsData({ user: value, message: value });
    getListOfMessagesForMessageThreadFn(value.messageInstanceId);
  };

  const openNewConversationWindow = (e, value) => {
    e.preventDefault();
    console.log("VALUE", value);
    setFollowIdHash(value.followIdHash);
    if (professionalsFlag) {
      setMessageReceiverName(
        value.followUserInfoData?.userInformation?.firstName +
          " " +
          value.followUserInfoData?.userInformation?.lastName
      );
    } else if (companiesFlag) {
      setMessageReceiverName(
        value.followUserInfoData?.userInformation?.entityName
      );
    }

    setStartNewConversationFlag(true);
  };

  const msgSubjectChanged = (e, flag) => {
    e.preventDefault();
    setMessageSendBtnDisabled(false);
    let value = e.target.value;
    if (flag) {
      if (flag == "subject") {
        setMsgSubject(value);
      } else if (flag == "message") {
        setMsg(value);
      }
    }
  };

  const msgReplyFn = (e, flag) => {
    e.preventDefault();
    let value = e.target.value;
    if (flag) {
      if (flag == "reply") {
        setMsgReply(value);
      }
    }
  };

  const sendNewMessageFn = (e) => {
    e.preventDefault();
    if (msgSubject == "" || msgSubject == undefined || msgSubject == null) {
      toast.error("Please enter the Subject");
      return;
    }
    if (msg == "" || msg == undefined || msg == null) {
      toast.error("Please enter the Message");
      return;
    }
    let params = {
      subject: msgSubject,
      message: msg,
      followIdHash: followIdHash,
      userIdHash: props.auth.userProfile.userIdHash,
    };

    setMessageSendBtnDisabled(true);
    setSendNewMessageLoading(true);

    props.sendNewMessage(params, (res) => {
      setMessageSendBtnDisabled(false);
      setSendNewMessageLoading(false);
      if (res.status) {
        swal({
          text: `Message sent successfully`,
          icon: "success",
        }).then((val) => {
          setStartNewConversationFlag(false);
          setFollowIdHash();
          setMessageReceiverName();
          setMsgSubject();
          setMsg();
        });
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const replyToMsgFn = (e) => {
    e.preventDefault();

    if (msgReply == "" || msgReply == undefined || msgReply == null) {
      toast.error("Please write what to reply");
      return;
    }
    let params = {
      subject: msgReplySubject,
      message: msgReply,
      replyMessageIdHash: msgIdHash,
      userIdHash: props.auth.userProfile.userIdHash,
    };

    setChatHistoryLoading(true);

    props.replyToMessage(params, (res) => {
      if (res.status) {
        setMsgReply("");
        // setMsg('')
        getListOfMessagesForMessageThreadFn(res.data.messageInstanceId);
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const deleteMessageFromInboxFn = (e, value) => {
    e.preventDefault();

    swal({
      text: `Are you sure you want to delete this message?`,
      icon: "warning",
      buttons: true,
    }).then((val) => {
      if (val) {
        // setMessageListLoading(true);
        // setMessageDetailsFlag(true);
        props.deleteMessageFromInbox(
          {
            messageIdHash: value.messageIdHash,
            userIdHash: props.auth.userProfile.userIdHash,
            value: 1,
          },
          (res) => {
            if (res.status) {
              swal({
                text: `Message deleted successfully`,
                icon: "success",
              }).then((val) => {
                // if (inboxNewFlag) {
                //   getListOfNewMessages(entityTypeId, userTypeId);
                // } else if (inboxReadFlag) {
                //   getListOfReadMessages(entityTypeId, userTypeId);
                // } else if (inboxArchivedFlag) {
                //   getListOfArchivedMessages(entityTypeId, userTypeId);
                // }
                getListOfMessagesForMessageThreadFn(value.messageInstanceId);
              });
            } else {
              toast.error("Something went wrong");
              // setMessageListLoading(false);
              // setMessageDetailsFlag(false);
            }
          }
        );
      }
    });
  };

  const deleteMessageThreadFromInboxFn = (e, value) => {
    e.preventDefault();

    swal({
      text: `Are you sure you want to delete this message thread from inbox?`,
      icon: "warning",
      buttons: true,
    }).then((val) => {
      if (val) {
        setMessageListLoading(true);
        setMessageDetailsFlag(true);
        props.deleteMessageThreadFromInbox(
          {
            messageInstanceId: value.messageInstanceId,
            userIdHash: props.auth.userProfile.userIdHash,
            value: 1,
          },
          (res) => {
            if (res.status) {
              swal({
                text: `Message thread deleted successfully`,
                icon: "success",
              }).then((val) => {
                if (inboxNewFlag) {
                  getListOfNewMessages(entityTypeId, userTypeId);
                } else if (inboxReadFlag) {
                  getListOfReadMessages(entityTypeId, userTypeId);
                } else if (inboxArchivedFlag) {
                  getListOfArchivedMessages(entityTypeId, userTypeId);
                }
                //getListOfMessagesForMessageThreadFn(value.messageInstanceId)
              });
            } else {
              toast.error("Something went wrong");
              setMessageListLoading(false);
              setMessageDetailsFlag(false);
            }
          }
        );
      }
    });
  };
  
  const restoreMessageThreadFromArchivedFn = (e, value) => {
    e.preventDefault();

    swal({
      text: `Are you sure you want to restore this message thread to inbox?`,
      icon: "warning",
      buttons: true,
    }).then((val) => {
      if (val) {
        setMessageListLoading(true);
        setMessageDetailsFlag(true);
        props.restoreMessageThreadFromArchived(
          {
            messageInstanceId: value.messageInstanceId,
            userIdHash: props.auth.userProfile.userIdHash,
            value: 0,
          },
          (res) => {
            if (res.status) {
              swal({
                text: `Message thread restored successfully`,
                icon: "success",
              }).then((val) => {
                if (inboxNewFlag) {
                  getListOfNewMessages(entityTypeId, userTypeId);
                } else if (inboxReadFlag) {
                  getListOfReadMessages(entityTypeId, userTypeId);
                } else if (inboxArchivedFlag) {
                  getListOfArchivedMessages(entityTypeId, userTypeId);
                }
                //getListOfMessagesForMessageThreadFn(value.messageInstanceId)
              });
            } else {
              toast.error("Something went wrong");
              setMessageListLoading(false);
              setMessageDetailsFlag(false);
            }
          }
        );
      }
    });
  };

  const restoreMessageToInboxFromArchivedFn = (e, value) => {
    e.preventDefault();

    swal({
      text: `Are you sure you want to restore this to inbox?`,
      icon: "warning",
      buttons: true,
    }).then((val) => {
      if (val) {
        setMessageListLoading(true);
        setMessageDetailsFlag(true);
        props.restoreMessageToInboxFromArchived(
          {
            messageIdHash: value.messageIdHash,
            userIdHash: props.auth.userProfile.userIdHash,
            value: 0,
          },
          (res) => {
            if (res.status) {
              swal({
                text: `Message restored successfully`,
                icon: "success",
              }).then((val) => {
                if (inboxNewFlag) {
                  getListOfNewMessages(entityTypeId, userTypeId);
                } else if (inboxReadFlag) {
                  getListOfReadMessages(entityTypeId, userTypeId);
                } else if (inboxArchivedFlag) {
                  getListOfArchivedMessages(entityTypeId, userTypeId);
                }
              });
            } else {
              toast.error("Something went wrong");
              setMessageListLoading(false);
              setMessageDetailsFlag(false);
            }
          }
        );
      }
    });
  };

  const restoreMessageToInboxFromChatFn = (e, value) => {
    e.preventDefault();

    swal({
      text: `Are you sure you want to restore this message?`,
      icon: "warning",
      buttons: true,
    }).then((val) => {
      if (val) {
        // setMessageListLoading(true);
        // setMessageDetailsFlag(true);
        props.restoreMessageToInboxFromArchived(
          {
            messageIdHash: value.messageIdHash,
            userIdHash: props.auth.userProfile.userIdHash,
            value: 0,
          },
          (res) => {
            if (res.status) {
              swal({
                text: `Message restored successfully`,
                icon: "success",
              }).then((val) => {
                // if (inboxNewFlag) {
                //   getListOfNewMessages(entityTypeId, userTypeId);
                // } else if (inboxReadFlag) {
                //   getListOfReadMessages(entityTypeId, userTypeId);
                // } else if (inboxArchivedFlag) {
                //   getListOfArchivedMessages(entityTypeId, userTypeId);
                // }
                getListOfMessagesForMessageThreadFn(value.messageInstanceId);
              });
            } else {
              toast.error("Something went wrong");
              // setMessageListLoading(false);
              // setMessageDetailsFlag(false);
            }
          }
        );
      }
    });
  };

  const openSpacesWindow = (e, value) => {
    e.preventDefault();
    console.log("VALUE", value);
    setSpaceUserInfo({
      userInfo: value.followUserInfoData.userInformation,
      professionInfo: value.professionInfoData,
      isVerified: value.followUserInfoData.userInfo.isVerified,
    });
    setSpacesFlagForIndividual(true);
    setListLoading(true);
    props.getSpacesDataFromFollowingNetwork(
      {
        followIdHash: value.followIdHash,
        userIdHash: props.auth.userProfile.userIdHash,
      },
      (res) => {
        console.log(res);
        setListLoading(false);
        if (res.status) {
          setSpacesDataForIndividual(res.data);
        } else {
          setSpacesDataForIndividual([]);
          toast.error("Something went wrong");
        }
      }
    );
  };

  const getConnectionData = (e, value) => {
    e.preventDefault();
    console.log(value);
    setPostDetailsData(value);
    setFollowingConnectionFlag(true);
    setFollowersConnectionFlag(false);
    setConnectionFlag(true);
    setListLoading(true);
    props.getConnections(
      {
        userIdHash: props.auth.userProfile.userIdHash,
        userNameHash: value.followUserInfoData.userInfo.userNameHash,
        userTypeId: userTypeId
      },
      (res) => {
        setListLoading(false);
        if (res.status) {
          console.log(res);
          setConnectionData(res.data);
        } else {
          toast.error("Something went wrong");
          setConnectionData({});
        }
      }
    );
  };

  const connectWithUser = (e, value, data) => {
    e.preventDefault();
    console.log('Connection Data', value)
    console.log('Parent Data', data)
    let params = {
      userIdHash: props.auth.userProfile.userIdHash,
      entityTypeId: value.userSelectedEntityTypeId,
      entityTypeIdNetwork: value.followUserSelectedEntityTypeId,
      myFollowUserNameHash: data.followUserInfoData.userInfo.userNameHash,
      userNameHash: value.followUserInfoData?.userInfo?.userNameHash,
    };
    swal({
      text: `Are you sure you want to connect with ${value.followUserInfoData?.userInformation?.entityName ? value.followUserInfoData?.userInformation?.entityName : value.followUserInfoData?.userInformation?.firstName + ' ' + value.followUserInfoData?.userInformation?.lastName}?`,
      icon: "warning",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        setListLoading(true);
        props.followFromConnections(params, res => {
          if(res.status) {
            props.getConnections(
              {
                userIdHash: props.auth.userProfile.userIdHash,
                userNameHash: data.followUserInfoData.userInfo.userNameHash,
              },
              (res) => {
                setListLoading(false);
                if (res.status) {
                  console.log(res);
                  setConnectionData(res.data);
                } else {
                  toast.error("Something went wrong");
                  setConnectionData({});
                }
              }
            );
          } else {
            setListLoading(false)
            toast.error('Something went wrong!')
          }
        })
      }
    });
  };

  const reportUserBtnClicked = (e, value) => {
    e.preventDefault();
    
    setModal({modal:true, type:"Report", data: value})
    
    if(messagesFlag) {
      closeMsgPopup(e, value)
      closeMsgDetailsPopup(e, value)
    } else {
      closePopup(e, value)
    }
  }
  
  const blockUserBtnClicked = (e, value) => {
    e.preventDefault();
    
    setModal({modal:true, type:"Block", data: value})
    
    if(messagesFlag) {
      closeMsgPopup(e, value)
      closeMsgDetailsPopup(e, value)
    } else {
      closePopup(e, value)
    }
  }

  const reportCommentChanged = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setReportComment(value)
  }

  const reportUserFn = (value) => {
    setModalLoading(true)
    let params = {};
    if(messagesFlag) {
      params = {
        userIdHash: props.auth.userProfile.userIdHash,
        userNameHash: value.isSenderLoggedInUser === 1 ? value.receiverUserInfoData?.userInfo?.userNameHash : value.sendUserInfoData?.userInfo?.userNameHash,
        comment: reportComment
      }
    } else {
      params = {
        userIdHash: props.auth.userProfile.userIdHash,
        userNameHash: value.userInfoData?.userInfo?.userNameHash,
        comment: reportComment
      }
    }
    

    console.log('REPORT USER PARAMS', params)
  
    props.reportUser(params, res => {
      setModalLoading(false)
      if(res.status) {
        setModal(!modal)
        swal({
          text: professionalsFlag ? 'User reported successfully' : 'Company reported successfully',
          icon: 'success'
        }).then(() => {
          setReportComment('')
        })
      } else {
        toast.error('Something went wrong!')
      }
    })
  }
  
  const blockUserFn = (value) => {
    setModalLoading(true)
    let params = {};
    if(messagesFlag) {
      params = {
        userIdHash: props.auth.userProfile.userIdHash,
        userNameHash: value.isSenderLoggedInUser === 1 ? value.receiverUserInfoData?.userInfo?.userNameHash : value.sendUserInfoData?.userInfo?.userNameHash,
        comment: reportComment
      }
    } else {
      params = {
        userIdHash: props.auth.userProfile.userIdHash,
        userNameHash: value.userInfoData?.userInfo?.userNameHash,
        comment: reportComment
      }
    }

    console.log('BLOCK USER PARAMS', params)
  
    props.blockUser(params, res => {
      setModalLoading(false)
      if(res.status) {
        setModal(!modal)
        swal({
          text: 'User blocked successfully',
          icon: 'success'
        }).then(() => {
          setReportComment('')
        })
      } else {
        toast.error('Something went wrong!')
      }
    })
  }

  const sortMessageFn = (value) => {
    if(inboxNewFlag) {
      setMessageListLoading(true);
      setMessageDetailsFlag(true);
      if (entityTypeId) {
        props.getNewInboxMessagesForEntityTypeId(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            entityTypeId: entityTypeId,
            userTypeId: userTypeId,
            sortOldest: value
          },
          (res) => {
            console.log("NEW INBOX MESSAGES For ENTITY ID", res);
            setMessageListLoading(false);
            setMessageDetailsFlag(false);
            if (res.status) {
              if (res.data.length) {
                setMessageData(res.data);
                setMessageDetailsData({ user: {}, message: {} });
                // getListOfMessagesForMessageThreadFn(
                //   res.data[0].messageInstanceId
                // );
              } else {
                setMessageData([]);
                setMessageDetailsData({ user: {}, message: {} });
              }
            } else {
              toast.error("Something went wrong");
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          }
        );
      } else {
        props.getNewInboxMessagesForAll(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId,
            sortOldest: value
          },
          (res) => {
            console.log("NEW INBOX MESSAGES For ALL", res);
            setMessageListLoading(false);
            setMessageDetailsFlag(false);
            if (res.status) {
              if (res.data.length) {
                setMessageData(res.data);
                setMessageDetailsData({ user: {}, message: {} });
                // getListOfMessagesForMessageThreadFn(
                //   res.data[0].messageInstanceId
                // );
              } else {
                setMessageData([]);
                setMessageDetailsData({ user: {}, message: {} });
              }
            } else {
              toast.error("Something went wrong");
              setMessageData([]);
              setMessageDetailsData({});
            }
          }
        );
      }
    }
    if(inboxReadFlag) {
      setMessageListLoading(true);
      setMessageDetailsFlag(true);
      if (entityTypeId) {
        props.getReadInboxMessagesForEntityTypeId(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            entityTypeId: entityTypeId,
            userTypeId: userTypeId,
            sortOldest: value
          },
          (res) => {
            console.log("READ INBOX MESSAGES For ENTITY ID", res);
            setMessageListLoading(false);
            setMessageDetailsFlag(false);
            if (res.status) {
              if (res.data.length) {
                setMessageData(res.data);
                setMessageDetailsData({ user: {}, message: {} });
                // getListOfMessagesForMessageThreadFn(
                //   res.data[0].messageInstanceId
                // );
              } else {
                setMessageData([]);
                setMessageDetailsData({ user: {}, message: {} });
              }
            } else {
              toast.error("Something went wrong");
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          }
        );
      } else {
        props.getReadInboxMessagesForAll(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId,
            sortOldest: value
          },
          (res) => {
            console.log("READ INBOX MESSAGES For ALL", res);
            setMessageListLoading(false);
            setMessageDetailsFlag(false);
            if (res.status) {
              if (res.data.length) {
                setMessageData(res.data);
                setMessageDetailsData({ user: {}, message: {} });
                // getListOfMessagesForMessageThreadFn(
                //   res.data[0].messageInstanceId
                // );
              } else {
                setMessageData([]);
                setMessageDetailsData({ user: {}, message: {} });
              }
            } else {
              toast.error("Something went wrong");
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          }
        );
      }
    }
    if(inboxArchivedFlag) {
      setMessageListLoading(true);
      setMessageDetailsFlag(true);
      if (entityTypeId) {
        props.getDeletedInboxMessagesForEntityTypeId(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            entityTypeId: entityTypeId,
            userTypeId: userTypeId,
            sortOldest: value
          },
          (res) => {
            console.log("ARCHIVED INBOX MESSAGES For ENTITY ID", res);
            setMessageListLoading(false);
            setMessageDetailsFlag(false);
            if (res.status) {
              if (res.data.length) {
                setMessageData(res.data);
                setMessageDetailsData({ user: {}, message: {} });
                // getListOfMessagesForMessageThreadFn(
                //   res.data[0].messageInstanceId
                // );
              } else {
                setMessageData([]);
                setMessageDetailsData({ user: {}, message: {} });
              }
            } else {
              toast.error("Something went wrong");
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          }
        );
      } else {
        props.getDeletedInboxMessagesForAll(
          {
            userIdHash: props.auth.userProfile.userIdHash,
            userTypeId: userTypeId,
            sortOldest: value
          },
          (res) => {
            console.log("ARCHIVED INBOX MESSAGES For ALL", res);
            setMessageListLoading(false);
            setMessageDetailsFlag(false);
            if (res.status) {
              if (res.data.length) {
                setMessageData(res.data);
                setMessageDetailsData({ user: {}, message: {} });
                // getListOfMessagesForMessageThreadFn(
                //   res.data[0].messageInstanceId
                // );
              } else {
                setMessageData([]);
                setMessageDetailsData({ user: {}, message: {} });
              }
            } else {
              toast.error("Something went wrong");
              setMessageData([]);
              setMessageDetailsData({ user: {}, message: {} });
            }
          }
        );
      }
    }
  }

  const getFormatedDate = (date) => {
    let timestamp = Date.parse(date);
    let todayFullDate =
      new Date().getDate() +
      "-" +
      new Date().getMonth() +
      "-" +
      new Date().getFullYear();
    let newDate =
      new Date(timestamp).getDate() +
      "-" +
      new Date(timestamp).getMonth() +
      "-" +
      new Date(timestamp).getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let msgDate = new Date(timestamp).getDate();
    let msgMonth = new Date(timestamp).getMonth();
    let msgYear = new Date(timestamp).getFullYear();

    let dateToShow = monthNames[msgMonth] + " " + msgDate + " " + msgYear;

    let hours = new Date(timestamp).getHours();
    let minutes = new Date(timestamp).getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let time = hours + ":" + minutes.toString().padStart(2, 0) + " " + ampm;

    return newDate === todayFullDate
      ? "Today " + time
      : dateToShow + " " + time;
  };

  return (
    <div
      className="content d-flex flex-column flex-column-fluid pt-5 pb-0"
      id="kt_content"
    >
      <div className="d-flex flex-column-fluid">
        <div className="custom-container custom_contr5">
          <div className="Profile_form custom_profile4">
            {createPostFlag ? (
              <>
                <div className="white_bf6">
                  <div
                    className="new_whitr"
                    style={{
                      background: "#f2f2f2",
                      marginTop: "-30px",
                      height: "",
                    }}
                  >
                    {editPostFlag ? (
                      <>
                        <h4 className="mb-6" style={{ color: "gray" }}>
                          Network
                          <span
                            style={{ color: "black", fontSize: "24px" }}
                          >{` > Edit Your Story`}</span>
                        </h4>
                      </>
                    ) : (
                      <>
                        <h4 className="mb-6" style={{ color: "gray" }}>
                          Network
                          <span
                            style={{ color: "black", fontSize: "24px" }}
                          >{` > Add Your Story`}</span>
                        </h4>
                      </>
                    )}

                    <p
                      onClick={() => {
                        backToMainPage();
                      }}
                      style={{
                        color: "#3DBF04",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                    >
                      Back
                    </p>
                    {spaceCategoriesLoading ? (
                      <>
                        <div
                          className="row"
                          style={{ width: "100%", height: "50vh" }}
                        >
                          <div
                            className="col-12"
                            style={{ width: "100%", height: "100%" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                textAlign: "center",
                                verticalAlign: "middle",
                                lineHeight: "50vh",
                              }}
                            >
                              <div
                                className="spinner-grow"
                                role="status"
                                style={{ background: "#006838" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ bcakground: "#f2f2f2", height: "" }}>
                          <div className="row" style={{ paddingLeft: "10px" }}>
                            <div
                              className="col-12 p-5"
                              style={{ background: "white" }}
                            >
                              <h4
                                className="my-5"
                                style={{ fontWeight: "600", fontSize: "20px" }}
                              >
                                {editPostFlag
                                  ? "Edit Your Space Content"
                                  : "Create Your Space Content"}
                              </h4>
                              <p className="my-3" style={{ fontSize: "15px" }}>
                                TITLE OF YOUR POST
                              </p>
                              <input
                                onChange={(e) => postDataChanged(e, "title")}
                                type="text"
                                name="Search"
                                className="form-control col-12"
                                placeholder="Enter title here..."
                                value={spaceTitle}
                              />
                              <p
                                className="mt-5 mb-3"
                                style={{ fontSize: "15px" }}
                              >
                                SELECT CATEGORY OF YOUR POST :{" "}
                              </p>
                              <div className="d-flex">
                                {spaceCategoriesData.length && (
                                  <>
                                    {spaceCategoriesData.map((value, index) => {
                                      return (
                                        <>
                                          <div
                                            className="mr-5"
                                            id={value.name}
                                            style={spaceCategoryStyle}
                                            onClick={() => {
                                              spaceCategoryChanged(value);
                                            }}
                                          >
                                            <img
                                              src={spaceCategoryCircleIcon}
                                              id={value.name + "img"}
                                              alt="Create Post"
                                              style={{ color: "#6E7D8C" }}
                                            />{" "}
                                            {value.name}
                                          </div>
                                        </>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                              <p
                                className="mt-5 mb-3"
                                style={{ fontSize: "15px" }}
                              >
                                {" "}
                                POST{" "}
                              </p>
                              <textarea
                                onChange={(e) => postDataChanged(e, "desc")}
                                className="form-control col-12"
                                rows="10"
                                placeholder="Enter your post here..."
                                style={{
                                  width: "100%",
                                  border: "1px solid gainsboro",
                                }}
                                value={spaceDesc}
                              ></textarea>
                              <div
                                className="row"
                                style={{ marginTop: "100px" }}
                              >
                                <div className="col-12 text-center">
                                  <button
                                    className="btn"
                                    style={{
                                      background: "#3DBF04",
                                      color: "white",
                                      paddingLeft: "30px",
                                      paddingRight: "30px",
                                    }}
                                    onClick={(e) => {
                                      createNewSpacePost(e);
                                    }}
                                    disabled={spacePostBtnDisabled}
                                  >
                                    {editPostFlag ? "UPDATE" : "POST"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {whatInYourMindFlag ? (
                  <>
                    <div className="white_bf6">
                      <div
                        className="new_whitr"
                        style={{
                          background: "#f2f2f2",
                          marginTop: "-30px",
                          height: "",
                        }}
                      >
                        <h4 className="mb-6" style={{ color: "gray" }}>
                          Network
                          <span
                            style={{ color: "black", fontSize: "24px" }}
                          >{` > What's in your mind?`}</span>
                        </h4>

                        <p
                          onClick={() => {
                            backToMainPage();
                          }}
                          style={{
                            color: "#3DBF04",
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                        >
                          Back
                        </p>
                        <div style={{ bcakground: "#f2f2f2", height: "80vh" }}>
                          <div className="row" style={{ paddingLeft: "10px" }}>
                            <div
                              className="col-12 p-5"
                              style={{ background: "white" }}
                            >
                              <h4
                                className="my-5"
                                style={{ fontWeight: "600", fontSize: "20px" }}
                              >
                                {"Update Status"}
                              </h4>
                              {whatInYourMindStatusLoading ? (
                                <>
                                  <div
                                    className="row"
                                    style={{ width: "100%", height: "" }}
                                  >
                                    <div
                                      className="col-12"
                                      style={{ width: "100%", height: "100%" }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                          lineHeight: "20vh",
                                        }}
                                      >
                                        <div
                                          className="spinner-grow"
                                          role="status"
                                          style={{ background: "#006838" }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <p
                                    className="my-3"
                                    style={{ fontSize: "15px" }}
                                  >
                                    STATUS
                                  </p>
                                  <textarea
                                    onChange={(e) =>
                                      whatInYourMindStatusChanged(e, "status")
                                    }
                                    type="text"
                                    name="Search"
                                    className="form-control col-12"
                                    placeholder="Enter status here..."
                                    maxLength="150"
                                    value={whatInYourMindStatus}
                                  ></textarea>
                                </>
                              )}

                              <div
                                className="row"
                                style={{ marginTop: "100px" }}
                              >
                                <div className="col-12 text-center">
                                  <button
                                    className="btn"
                                    style={{
                                      background: "#3DBF04",
                                      color: "white",
                                      paddingLeft: "30px",
                                      paddingRight: "30px",
                                    }}
                                    onClick={(e) => {
                                      updateWhatInYourMindStatus(e);
                                    }}
                                    disabled={updateStatusBtnDisabled}
                                  >
                                    {"PUBLISH"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {postDetailsFlag ? (
                      <>
                        <div className="white_bf6">
                          <div
                            className="new_whitr"
                            style={{
                              background: "#f2f2f2",
                              marginTop: "-30px",
                              height: "",
                            }}
                          >
                            <h4 className="mb-6" style={{ color: "gray" }}>
                              Network
                              <span
                                style={{ color: "black", fontSize: "24px" }}
                              >{` > Post Details`}</span>
                            </h4>

                            <p
                              onClick={() => {
                                backToMainPage();
                              }}
                              style={{
                                color: "#3DBF04",
                                cursor: "pointer",
                                fontSize: "16px",
                              }}
                            >
                              Back
                            </p>
                            <div style={{ bcakground: "#f2f2f2", height: "" }}>
                              <div
                                className="row"
                                style={{ paddingLeft: "10px", height: "80vh" }}
                              >
                                <div
                                  className="col-12 p-5"
                                  style={{ background: "white" }}
                                >
                                  <div className="">
                                    <div className="geoge_outer">
                                      <div
                                        className="custom789 circleName"
                                        style={{
                                          height: "94px",
                                          width: "94px",
                                          fontSize: "22px",
                                          lineHeight: "95px",
                                        }}
                                      >
                                        {postDetailsData.professionInfoData
                                          ?.entityTypeNameAbbreviation
                                          ? postDetailsData.professionInfoData
                                              ?.entityTypeNameAbbreviation
                                          : "N/A"}
                                      </div>
                                      <div
                                        className="google_inner"
                                        style={{
                                          marginBottom: "60px",
                                          paddingLeft: "105px",
                                          paddingTop: "12px",
                                        }}
                                      >
                                        <div
                                          className="d-flex justify-content-between align-items-center"
                                          style={{ position: "relative" }}
                                        >
                                          {professionalsFlag && (
                                            <>
                                              <h5 style={{ fontSize: "20px" }}>
                                                {postDetailsData.userInfoData
                                                  ?.userInformation?.firstName
                                                  ? postDetailsData.userInfoData
                                                      ?.userInformation
                                                      .firstName
                                                  : "--"}{" "}
                                                {postDetailsData.userInfoData
                                                  ?.userInformation?.lastName
                                                  ? postDetailsData.userInfoData
                                                      ?.userInformation.lastName
                                                  : "--"}
                                              </h5>
                                            </>
                                          )}
                                          {companiesFlag && (
                                            <>
                                              <h5 style={{ fontSize: "20px" }}>
                                                {postDetailsData.userInfoData
                                                  ?.userInformation?.entityName
                                                  ? postDetailsData.userInfoData
                                                      ?.userInformation
                                                      .entityName
                                                  : "--"}
                                              </h5>
                                            </>
                                          )}
                                          {meFlag && (
                                            <>
                                              <h5 style={{ fontSize: "20px" }}>
                                                {postDetailsData.userInfoData
                                                  ?.userInformation?.firstName
                                                  ? postDetailsData.userInfoData
                                                      ?.userInformation
                                                      .firstName
                                                  : "--"}{" "}
                                                {postDetailsData.userInfoData
                                                  ?.userInformation?.lastName
                                                  ? postDetailsData.userInfoData
                                                      ?.userInformation.lastName
                                                  : "--"}
                                              </h5>
                                            </>
                                          )}

                                          <i
                                            className="fa fa-ellipsis-v"
                                            aria-hidden="true"
                                            style={{
                                              color: "black",
                                              fontSize: "18px",
                                              cursor: "pointer",
                                              fontWeight: "500",
                                            }}
                                            onClick={(e) => {
                                              openPopup(e, postDetailsData);
                                            }}
                                          ></i>
                                          {/* <img className='badgeClass mr-6' src={showBadge(postDetailsData.userProfileDataTypes.selectedUserDataRating)} alt="badge" /> */}
                                          {meFlag && (
                                            <>
                                              <div
                                                className="blush_outer"
                                                id={
                                                  postDetailsData.spaceIdHash +
                                                  "popup"
                                                }
                                                style={{
                                                  position: "absolute",
                                                  height: "100px",
                                                  width: "135px",
                                                  top: "25px",
                                                  right: "5px",
                                                  border: "1px solid gainsboro",
                                                  display: "none",
                                                }}
                                              >
                                                <div className="d-flex">
                                                  <p
                                                    className="p-2 mb-0"
                                                    style={{ color: "grey" }}
                                                  >
                                                    More Options
                                                  </p>
                                                  <i
                                                    className="fa fa-times"
                                                    aria-hidden="true"
                                                    style={{
                                                      color: "grey",
                                                      fontSize: "14px",
                                                      paddingTop: "10px",
                                                      marginLeft: "15px",
                                                    }}
                                                    onClick={(e) => {
                                                      closePopup(
                                                        e,
                                                        postDetailsData
                                                      );
                                                    }}
                                                  ></i>
                                                </div>
                                                <p
                                                  className="p-2 mt-0 mb-0"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid gainsboro",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={(e) => {
                                                    editPostBtnClicked(
                                                      e,
                                                      postDetailsData
                                                    );
                                                  }}
                                                >
                                                  Edit Post
                                                </p>
                                                <p
                                                  className="p-2 mt-0 mb-0"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) => {
                                                    deleteSpacePostData(
                                                      e,
                                                      postDetailsData
                                                    );
                                                  }}
                                                >
                                                  Delete Post
                                                </p>
                                              </div>
                                            </>
                                          )}

                                          {professionalsFlag && (
                                            <>
                                              <div
                                                className="blush_outer"
                                                id={
                                                  postDetailsData.spaceIdHash +
                                                  "popup"
                                                }
                                                style={{
                                                  position: "absolute",
                                                  height: "105px",
                                                  width: "135px",
                                                  top: "25px",
                                                  right: "5px",
                                                  border: "1px solid gainsboro",
                                                  display: "none",
                                                }}
                                              >
                                                <div className="d-flex">
                                                  <p
                                                    className="p-2 mb-0"
                                                    style={{ color: "grey" }}
                                                  >
                                                    More Options
                                                  </p>
                                                  <i
                                                    className="fa fa-times"
                                                    aria-hidden="true"
                                                    style={{
                                                      color: "grey",
                                                      fontSize: "14px",
                                                      paddingTop: "10px",
                                                      marginLeft: "15px",
                                                    }}
                                                    onClick={(e) => {
                                                      closePopup(
                                                        e,
                                                        postDetailsData
                                                      );
                                                    }}
                                                  ></i>
                                                </div>
                                                <p
                                                  className="p-2 mt-0 mb-0"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid gainsboro",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={e => {reportUserBtnClicked(e, postDetailsData)}}
                                                >
                                                  Report User
                                                </p>
                                                {/* <p
                                                  className="p-2 mt-0 mb-0"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid gainsboro",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Report Post
                                                </p> */}
                                                <p
                                                  className="p-2 mt-0 mb-0"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={e => {blockUserBtnClicked(e, postDetailsData)}}
                                                >
                                                  Block User
                                                </p>
                                              </div>
                                            </>
                                          )}

                                          {companiesFlag && (
                                            <>
                                              <div
                                                className="blush_outer"
                                                id={
                                                  postDetailsData.spaceIdHash +
                                                  "popup"
                                                }
                                                style={{
                                                  position: "absolute",
                                                  height: "105px",
                                                  width: "135px",
                                                  top: "25px",
                                                  right: "5px",
                                                  border: "1px solid gainsboro",
                                                  display: "none",
                                                }}
                                              >
                                                <div className="d-flex">
                                                  <p
                                                    className="p-2 mb-0"
                                                    style={{ color: "grey" }}
                                                  >
                                                    More Options
                                                  </p>
                                                  <i
                                                    className="fa fa-times"
                                                    aria-hidden="true"
                                                    style={{
                                                      color: "grey",
                                                      fontSize: "14px",
                                                      paddingTop: "10px",
                                                      marginLeft: "15px",
                                                    }}
                                                    onClick={(e) => {
                                                      closePopup(
                                                        e,
                                                        postDetailsData
                                                      );
                                                    }}
                                                  ></i>
                                                </div>
                                                <p
                                                  className="p-2 mt-0 mb-0"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid gainsboro",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={e => {reportUserBtnClicked(e, postDetailsData)}}
                                                >
                                                  Report Company
                                                </p>
                                                {/* <p
                                                  className="p-2 mt-0 mb-0"
                                                  style={{
                                                    borderBottom:
                                                      "1px solid gainsboro",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Report Post
                                                </p> */}
                                                <p
                                                  className="p-2 mt-0 mb-0"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={e => {blockUserBtnClicked(e, postDetailsData)}}
                                                >
                                                  Block Company
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        <p className="mt-0 mb-1">
                                          {postDetailsData.professionInfoData
                                            ?.profession
                                            ? postDetailsData.professionInfoData
                                                ?.profession
                                            : "--"}
                                        </p>
                                        <p
                                          className="mt-0"
                                          style={{ color: "#6E7D8C" }}
                                        >
                                          {"7 hours ago"}
                                          <span
                                            className="mx-4"
                                            style={{ color: "gainsboro" }}
                                          >
                                            |
                                          </span>
                                          {postDetailsData.spaceTypeInfoData
                                            ?.isPrivate ? (
                                            <>
                                              <img
                                                className="mr-2"
                                                src={privateIcon}
                                                alt="Private Icon"
                                                style={{
                                                  width: "12px",
                                                  paddingBottom: "4px",
                                                }}
                                              />
                                              Private
                                            </>
                                          ) : postDetailsData.spaceTypeInfoData
                                              ?.isPublic ? (
                                            <>
                                              <img
                                                className="mr-2"
                                                src={publicIcon}
                                                alt="Public Icon"
                                                style={{
                                                  width: "14px",
                                                  paddingBottom: "1px",
                                                }}
                                              />
                                              Public
                                            </>
                                          ) : postDetailsData.spaceTypeInfoData
                                              ?.isRestricted ? (
                                            <>
                                              <img
                                                className="mr-2"
                                                src={privateIcon}
                                                alt="Restricted Icon"
                                                style={{
                                                  width: "12px",
                                                  paddingBottom: "4px",
                                                }}
                                              />
                                              Restricted
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                className="mr-2"
                                                src={publicIcon}
                                                alt="Public Icon"
                                                style={{
                                                  width: "14px",
                                                  paddingBottom: "1px",
                                                }}
                                              />
                                              Public
                                            </>
                                          )}
                                        </p>
                                      </div>
                                      <p
                                        className="ml-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        <b>
                                          {postDetailsData.title ? (
                                            postDetailsData.title
                                          ) : (
                                            <></>
                                          )}
                                        </b>
                                      </p>
                                      <p
                                        className="ml-2"
                                        style={{ color: "#868686" }}
                                      >
                                        {postDetailsData.description ? (
                                          postDetailsData.description
                                        ) : (
                                          <></>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {startNewConversationFlag ? (
                          <>
                            <div className="white_bf6">
                              <div
                                className="new_whitr"
                                style={{
                                  background: "#f2f2f2",
                                  marginTop: "-30px",
                                  height: "",
                                }}
                              >
                                <h4 className="mb-6" style={{ color: "gray" }}>
                                  Network
                                  <span
                                    style={{ color: "black", fontSize: "24px" }}
                                  >{` > Start New Conversation`}</span>
                                </h4>

                                <p
                                  onClick={() => {
                                    backToMainPage();
                                  }}
                                  style={{
                                    color: "#3DBF04",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                  }}
                                >
                                  Back
                                </p>
                                <div
                                  style={{ bcakground: "#f2f2f2", height: "" }}
                                >
                                  <div
                                    className="row"
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <div
                                      className="col-12 p-5"
                                      style={{ background: "white" }}
                                    >
                                      <h4
                                        className="my-5"
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "20px",
                                        }}
                                      >
                                        {"Write a new message"}
                                      </h4>
                                      {sendNewMessageLoading ? (
                                        <>
                                          <div
                                            className="row"
                                            style={{
                                              width: "100%",
                                              height: "",
                                            }}
                                          >
                                            <div
                                              className="col-12"
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  textAlign: "center",
                                                  verticalAlign: "middle",
                                                  lineHeight: "60vh",
                                                }}
                                              >
                                                <div
                                                  className="spinner-grow"
                                                  role="status"
                                                  style={{
                                                    background: "#006838",
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <p>
                                            To : <br></br>
                                            <b>{msgReceiverName}</b>
                                          </p>
                                          <p
                                            className="my-3"
                                            style={{ fontSize: "15px" }}
                                          >
                                            SUBJECT
                                          </p>
                                          <input
                                            onChange={(e) =>
                                              msgSubjectChanged(e, "subject")
                                            }
                                            type="text"
                                            name="Search"
                                            className="form-control col-12"
                                            placeholder="Enter subject here..."
                                            value={msgSubject}
                                          />

                                          <p
                                            className="my-3"
                                            style={{ fontSize: "15px" }}
                                          >
                                            MESSAGE
                                          </p>
                                          <textarea
                                            onChange={(e) =>
                                              msgSubjectChanged(e, "message")
                                            }
                                            type="text"
                                            name="Search"
                                            className="form-control col-12"
                                            placeholder="Enter message here..."
                                            rows="10"
                                            value={msg}
                                          ></textarea>

                                          <div
                                            className="row"
                                            style={{ marginTop: "100px" }}
                                          >
                                            <div className="col-12 text-center">
                                              <button
                                                className="btn"
                                                style={{
                                                  background: "#3DBF04",
                                                  color: "white",
                                                  paddingLeft: "30px",
                                                  paddingRight: "30px",
                                                }}
                                                onClick={(e) => {
                                                  sendNewMessageFn(e);
                                                }}
                                                disabled={msgSendBtnDisabled}
                                              >
                                                {"SEND"}
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {spacesFlagForIndividual ? (
                              <>
                                <div className="white_bf6">
                                  <div
                                    className="new_whitr"
                                    style={{
                                      background: "#f2f2f2",
                                      marginTop: "-30px",
                                      height: "",
                                    }}
                                  >
                                    <h4
                                      className="mb-6"
                                      style={{ color: "gray" }}
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          fontSize: "24px",
                                        }}
                                      >{`Spaces`}</span>
                                    </h4>

                                    <p
                                      onClick={() => {
                                        backToMainPage();
                                      }}
                                      style={{
                                        color: "#3DBF04",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                      }}
                                    >
                                      Back
                                    </p>
                                    <div style={{ bcakground: "", height: "" }}>
                                      <div
                                        className="row"
                                        style={{
                                          paddingLeft: "0px",
                                          height: "80vh",
                                        }}
                                      >
                                        <div
                                          className="col-12"
                                          style={{ background: "" }}
                                        >
                                          <div className="">
                                            <div
                                              className="geoge_outer"
                                              style={{ padding: "0" }}
                                            >
                                              <div
                                                className="custom789 circleName"
                                                style={{
                                                  height: "94px",
                                                  width: "94px",
                                                  fontSize: "22px",
                                                  lineHeight: "95px",
                                                }}
                                              >
                                                {spaceUserInfo.professionInfo
                                                  ?.entityTypeNameAbbreviation
                                                  ? spaceUserInfo.professionInfo
                                                      ?.entityTypeNameAbbreviation
                                                  : "N/A"}
                                              </div>
                                              <img
                                                src={
                                                  spaceUserInfo.isVerified
                                                    ? verified
                                                    : unVerified
                                                }
                                                alt="verify"
                                                style={{
                                                  position: "absolute",
                                                  top: "70px",
                                                  left: "80px",
                                                  width: "22px",
                                                }}
                                              />
                                              <div
                                                className="google_inner"
                                                style={{
                                                  marginBottom: "60px",
                                                  paddingLeft: "105px",
                                                  paddingTop: "12px",
                                                }}
                                              >
                                                <div
                                                  className="d-flex justify-content-between align-items-center"
                                                  style={{
                                                    position: "relative",
                                                    paddingTop: "12px",
                                                  }}
                                                >
                                                  {professionalsFlag && (
                                                    <>
                                                      <h5
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      >
                                                        {spaceUserInfo.userInfo
                                                          ?.firstName
                                                          ? spaceUserInfo
                                                              .userInfo
                                                              ?.firstName
                                                          : "--"}{" "}
                                                        {spaceUserInfo.userInfo
                                                          ?.lastName
                                                          ? spaceUserInfo
                                                              .userInfo
                                                              ?.lastName
                                                          : "--"}
                                                      </h5>
                                                    </>
                                                  )}
                                                  {companiesFlag && (
                                                    <>
                                                      <h5
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      >
                                                        {spaceUserInfo.userInfo
                                                          ?.entityName
                                                          ? spaceUserInfo
                                                              .userInfo
                                                              ?.entityName
                                                          : "--"}
                                                      </h5>
                                                    </>
                                                  )}
                                                </div>
                                                <p style={{ color: "#494949" }}>
                                                  {
                                                    spaceUserInfo.professionInfo
                                                      .profession
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="Profile_form custom_spece5">
                                            {listLoading ? (
                                              <>
                                                <div
                                                  className="row"
                                                  style={{
                                                    width: "100%",
                                                    height: "",
                                                  }}
                                                >
                                                  <div
                                                    className="col-12"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        textAlign: "center",
                                                        verticalAlign: "middle",
                                                        lineHeight: "60vh",
                                                      }}
                                                    >
                                                      <div
                                                        className="spinner-grow"
                                                        role="status"
                                                        style={{
                                                          background: "#006838",
                                                        }}
                                                      ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {spacesDataForIndividual.length ==
                                                0 ? (
                                                  <>
                                                    <div className="row h-100 w-100">
                                                      <div className="col-12 text-center">
                                                        <img
                                                          className="mb-3"
                                                          src={
                                                            zeroFollowingImage
                                                          }
                                                          alt="No Data Found"
                                                        />
                                                        {meFollowingFlag && (
                                                          <>
                                                            <p>
                                                              You are not
                                                              following anyone.
                                                              Follow someone
                                                              from Space.
                                                            </p>
                                                          </>
                                                        )}
                                                        {myFollowersFlag && (
                                                          <>
                                                            <p>
                                                              You do not have
                                                              any followers
                                                              right now.
                                                            </p>
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="row">
                                                      {spacesDataForIndividual.map(
                                                        (value, index) => {
                                                          return (
                                                            <>
                                                              <div className="col-4">
                                                                <div className="blush_outer pt-5 px-5">
                                                                  <div
                                                                    className="d-flex justify-content-between align-items-center"
                                                                    style={{
                                                                      position:
                                                                        "relative",
                                                                    }}
                                                                  >
                                                                    <p className="ml-2">
                                                                      <b>
                                                                        {value.title ? (
                                                                          value.title
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                      </b>
                                                                    </p>

                                                                    <i
                                                                      className="fa fa-ellipsis-v"
                                                                      aria-hidden="true"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontSize:
                                                                          "18px",
                                                                        cursor:
                                                                          "pointer",
                                                                        fontWeight:
                                                                          "500",
                                                                        position:
                                                                          "relative",
                                                                        top: "-5px",
                                                                      }}
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        openPopup(
                                                                          e,
                                                                          value
                                                                        );
                                                                      }}
                                                                    ></i>

                                                                        {professionalsFlag && (
                                                                          <>
                                                                            <div
                                                                              className="blush_outer"
                                                                              id={
                                                                                value.spaceIdHash +
                                                                                "popup"
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                height:
                                                                                  "105px",
                                                                                width:
                                                                                  "135px",
                                                                                top: "25px",
                                                                                right:
                                                                                  "5px",
                                                                                border:
                                                                                  "1px solid gainsboro",
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                <p
                                                                                  className="p-2 mb-0"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                  }}
                                                                                >
                                                                                  More
                                                                                  Options
                                                                                </p>
                                                                                <i
                                                                                  className="fa fa-times"
                                                                                  aria-hidden="true"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                    paddingTop:
                                                                                      "10px",
                                                                                    marginLeft:
                                                                                      "15px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    closePopup(
                                                                                      e,
                                                                                      value
                                                                                    );
                                                                                  }}
                                                                                ></i>
                                                                              </div>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(e) => {
                                                                                  reportUserBtnClicked(e, value)
                                                                                }}
                                                                              >
                                                                                Report
                                                                                User
                                                                              </p>
                                                                              {/* <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              >
                                                                                Report
                                                                                Post
                                                                              </p> */}
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(e) => {
                                                                                  blockUserBtnClicked(e, value)
                                                                                }}
                                                                              >
                                                                                Block
                                                                                User
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}

                                                                        {companiesFlag && (
                                                                          <>
                                                                            <div
                                                                              className="blush_outer"
                                                                              id={
                                                                                value.spaceIdHash +
                                                                                "popup"
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                height:
                                                                                  "105px",
                                                                                width:
                                                                                  "135px",
                                                                                top: "25px",
                                                                                right:
                                                                                  "5px",
                                                                                border:
                                                                                  "1px solid gainsboro",
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                <p
                                                                                  className="p-2 mb-0"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                  }}
                                                                                >
                                                                                  More
                                                                                  Options
                                                                                </p>
                                                                                <i
                                                                                  className="fa fa-times"
                                                                                  aria-hidden="true"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                    paddingTop:
                                                                                      "10px",
                                                                                    marginLeft:
                                                                                      "15px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    closePopup(
                                                                                      e,
                                                                                      value
                                                                                    );
                                                                                  }}
                                                                                ></i>
                                                                              </div>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(e) => {
                                                                                  reportUserBtnClicked(e, value)
                                                                                }}
                                                                              >
                                                                                Report
                                                                                Company
                                                                              </p>
                                                                              {/* <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              >
                                                                                Report
                                                                                Post
                                                                              </p> */}
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(e) => {
                                                                                  blockUserBtnClicked(e, value)
                                                                                }}
                                                                              >
                                                                                Block
                                                                                Company
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}
                                                                  </div>

                                                                  <p
                                                                    className="ml-2"
                                                                    id={
                                                                      value.spaceIdHash
                                                                    }
                                                                    style={viewLessDescStyle()}
                                                                  >
                                                                    {value.description ? (
                                                                      value.description
                                                                    ) : (
                                                                      <></>
                                                                    )}
                                                                  </p>

                                                                  <p
                                                                    className="mt-0"
                                                                    style={{
                                                                      color:
                                                                        "#6E7D8C",
                                                                    }}
                                                                  >
                                                                    {
                                                                      "7 hours ago"
                                                                    }
                                                                    <span
                                                                      className="mx-4"
                                                                      style={{
                                                                        color:
                                                                          "gainsboro",
                                                                      }}
                                                                    >
                                                                      |
                                                                    </span>
                                                                    {value
                                                                      .spaceTypeInfoData
                                                                      ?.isPrivate ? (
                                                                      <>
                                                                        <img
                                                                          className="mr-2"
                                                                          src={
                                                                            privateIcon
                                                                          }
                                                                          alt="Private Icon"
                                                                          style={{
                                                                            width:
                                                                              "12px",
                                                                            paddingBottom:
                                                                              "4px",
                                                                          }}
                                                                        />
                                                                        Private
                                                                      </>
                                                                    ) : value
                                                                        .spaceTypeInfoData
                                                                        ?.isPublic ? (
                                                                      <>
                                                                        <img
                                                                          className="mr-2"
                                                                          src={
                                                                            publicIcon
                                                                          }
                                                                          alt="Public Icon"
                                                                          style={{
                                                                            width:
                                                                              "14px",
                                                                            paddingBottom:
                                                                              "1px",
                                                                          }}
                                                                        />
                                                                        Public
                                                                      </>
                                                                    ) : value
                                                                        .spaceTypeInfoData
                                                                        ?.isRestricted ? (
                                                                      <>
                                                                        <img
                                                                          className="mr-2"
                                                                          src={
                                                                            privateIcon
                                                                          }
                                                                          alt="Restricted Icon"
                                                                          style={{
                                                                            width:
                                                                              "12px",
                                                                            paddingBottom:
                                                                              "4px",
                                                                          }}
                                                                        />
                                                                        Restricted
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        <img
                                                                          className="mr-2"
                                                                          src={
                                                                            publicIcon
                                                                          }
                                                                          alt="Public Icon"
                                                                          style={{
                                                                            width:
                                                                              "14px",
                                                                            paddingBottom:
                                                                              "1px",
                                                                          }}
                                                                        />
                                                                        Public
                                                                      </>
                                                                    )}
                                                                  </p>

                                                                  <div className="">
                                                                    <p
                                                                      className="my-3"
                                                                      id={`viewMore${value.spaceIdHash}`}
                                                                      style={{
                                                                        color:
                                                                          "#2B2B2B",
                                                                        cursor:
                                                                          "pointer",
                                                                        borderTop:
                                                                          "1px solid gainsboro",
                                                                        textAlign:
                                                                          "center",
                                                                        paddingTop:
                                                                          "15px",
                                                                        paddingBottom:
                                                                          "15px",
                                                                      }}
                                                                      onClick={(
                                                                        e
                                                                      ) =>
                                                                        viewMoreBtnClicked(
                                                                          e,
                                                                          value
                                                                        )
                                                                      }
                                                                    >
                                                                      More...
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {connectionFlag ? (
                                  <>
                                    <div className="white_bf6">
                                      <div
                                        className="new_whitr"
                                        style={{
                                          background: "#f2f2f2",
                                          marginTop: "-30px",
                                          height: "",
                                        }}
                                      >
                                        <h4
                                          className="mb-6"
                                          style={{ color: "gray" }}
                                        >
                                          Network
                                          <span
                                            style={{
                                              color: "black",
                                              fontSize: "24px",
                                            }}
                                          >{` > Connections`}{" "}
                                          {professionalsFlag ? (
                                          <>
                                          <span 
                                            style={{
                                              border: "1px solid rgb(61, 191, 4)",
                                              borderRadius: "15px",
                                              background: "rgb(61, 191, 4)",
                                              color: "white",
                                              marginLeft: "10px",
                                              padding: "5px 10px"
                                            }}>Professionals</span>
                                          </>) : 
                                          (companiesFlag ? (
                                          <>
                                          <span 
                                            style={{
                                              border: "1px solid rgb(61, 191, 4)",
                                              borderRadius: "15px",
                                              background: "rgb(61, 191, 4)",
                                              color: "white",
                                              marginLeft: "10px",
                                              padding: "5px 10px"
                                            }}>Companies</span>
                                          </>) : '')}</span>
                                        </h4>

                                        <p
                                          onClick={() => {
                                            backToMainPage();
                                          }}
                                          style={{
                                            color: "#3DBF04",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Back
                                        </p>
                                        <div
                                          style={{
                                            bcakground: "#f2f2f2",
                                            height: "",
                                          }}
                                        >
                                          <div
                                            className="row"
                                            style={{
                                              paddingLeft: "10px",
                                              height: "80vh",
                                            }}
                                          >
                                            <div
                                              className="col-12 p-5"
                                              style={{ background: "white" }}
                                            >
                                              <div className="">
                                                <div className="geoge_outer">
                                                  <div
                                                    className="custom789 circleName"
                                                    style={{
                                                      height: "94px",
                                                      width: "94px",
                                                      fontSize: "22px",
                                                      lineHeight: "95px",
                                                    }}
                                                  >
                                                    {postDetailsData
                                                      .professionInfoData
                                                      ?.entityTypeNameAbbreviation
                                                      ? postDetailsData
                                                          .professionInfoData
                                                          ?.entityTypeNameAbbreviation
                                                      : "N/A"}
                                                  </div>
                                                  <img
                                                    src={
                                                      postDetailsData
                                                        .followUserInfoData
                                                        .userInfo.isVerified
                                                        ? verified
                                                        : unVerified
                                                    }
                                                    alt="verify"
                                                    style={{
                                                      position: "absolute",
                                                      top: "100px",
                                                      left: "100px",
                                                      width: "22px",
                                                    }}
                                                  />
                                                  <div
                                                    className="google_inner"
                                                    style={{
                                                      marginBottom: "60px",
                                                      paddingLeft: "105px",
                                                      paddingTop: "12px",
                                                    }}
                                                  >
                                                    <div
                                                      className="d-flex justify-content-between align-items-center"
                                                      style={{
                                                        position: "relative",
                                                      }}
                                                    >
                                                      {professionalsFlag && (
                                                        <>
                                                          <h5
                                                            style={{
                                                              fontSize: "20px",
                                                            }}
                                                          >
                                                            {postDetailsData
                                                              .followUserInfoData
                                                              ?.userInformation
                                                              ?.firstName
                                                              ? postDetailsData
                                                                  .followUserInfoData
                                                                  ?.userInformation
                                                                  .firstName
                                                              : "--"}{" "}
                                                            {postDetailsData
                                                              .followUserInfoData
                                                              ?.userInformation
                                                              ?.lastName
                                                              ? postDetailsData
                                                                  .followUserInfoData
                                                                  ?.userInformation
                                                                  .lastName
                                                              : "--"}
                                                          </h5>
                                                        </>
                                                      )}
                                                      {companiesFlag && (
                                                        <>
                                                          <h5
                                                            style={{
                                                              fontSize: "20px",
                                                            }}
                                                          >
                                                            {postDetailsData
                                                              .followUserInfoData
                                                              ?.userInformation
                                                              ?.entityName
                                                              ? postDetailsData
                                                                  .followUserInfoData
                                                                  ?.userInformation
                                                                  .entityName
                                                              : postDetailsData
                                                              .followUserInfoData
                                                              ?.userInformation
                                                              ?.firstName + ' ' + postDetailsData
                                                              .followUserInfoData
                                                              ?.userInformation
                                                              ?.lastName}
                                                          </h5>
                                                        </>
                                                      )}

                                                      {/* <i
                                                    className="fa fa-ellipsis-v"
                                                    aria-hidden="true"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "18px",
                                                      cursor: "pointer",
                                                      fontWeight: "500",
                                                    }}
                                                    onClick={(e) => {
                                                      openPopup(e, postDetailsData);
                                                    }}
                                                  ></i> */}
                                                      {/* <img className='badgeClass mr-6' src={showBadge(postDetailsData.userProfileDataTypes.selectedUserDataRating)} alt="badge" /> */}
                                                    </div>
                                                    <p className="mt-0 mb-1">
                                                      {postDetailsData
                                                        .professionInfoData
                                                        ?.profession
                                                        ? postDetailsData
                                                            .professionInfoData
                                                            ?.profession
                                                        : "--"}
                                                    </p>
                                                  </div>
                                                  <div className="row">
                                                    <div
                                                      className="col-md-6 d-flex"
                                                      style={{
                                                        paddingLeft: "26px",
                                                        fontFamily: "poppins",
                                                      }}
                                                    >
                                                      <div
                                                        className="row text-center"
                                                        style={{
                                                          marginTop: "",
                                                          marginBottom: "",
                                                        }}
                                                      >
                                                        <div
                                                          className="mr-2"
                                                          style={
                                                            followingConnectionFlag
                                                              ? followingStyleActive()
                                                              : followingStyleInActive()
                                                          }
                                                          onClick={() =>
                                                            fieldClicked(
                                                              "followingConnection"
                                                            )
                                                          }
                                                        >
                                                          Following
                                                        </div>
                                                        <div
                                                          className="mr-2"
                                                          style={
                                                            followersConnectionFlag
                                                              ? spacesStyleActive()
                                                              : spacesStyleInActive()
                                                          }
                                                          onClick={() =>
                                                            fieldClicked(
                                                              "followersConnection"
                                                            )
                                                          }
                                                        >
                                                          Followers
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                {followingConnectionFlag && (
                                                  <>
                                                    {connectionData?.following
                                                      ?.length === 0 ? (
                                                      <>
                                                        <div className="row h-100 w-100">
                                                          <div className="col-12 text-center">
                                                            <img
                                                              className="mb-3"
                                                              src={
                                                                zeroFollowingImage
                                                              }
                                                              alt="No Data Found"
                                                            />
                                                            <p>
                                                              Nothing to show.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {listLoading ? (
                                                          <>
                                                            <div
                                                              className="row"
                                                              style={{
                                                                width: "100%",
                                                                height: "",
                                                              }}
                                                            >
                                                              <div
                                                                className="col-12"
                                                                style={{
                                                                  width: "100%",
                                                                  height:
                                                                    "100%",
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    height:
                                                                      "100%",
                                                                    textAlign:
                                                                      "center",
                                                                    verticalAlign:
                                                                      "middle",
                                                                    lineHeight:
                                                                      "40vh",
                                                                  }}
                                                                >
                                                                  <div
                                                                    className="spinner-grow"
                                                                    role="status"
                                                                    style={{
                                                                      background:
                                                                        "#006838",
                                                                    }}
                                                                  ></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {connectionData?.following?.map(
                                                              (
                                                                value,
                                                                index
                                                              ) => {
                                                                return (
                                                                  <>
                                                                    <div className="row">
                                                                      <div
                                                                        className="col-6"
                                                                        style={{
                                                                          paddingLeft:
                                                                            "100px",
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="d-flex"
                                                                          style={{
                                                                            marginTop:
                                                                              "20px",
                                                                            position:
                                                                              "relative",
                                                                          }}
                                                                        >
                                                                          <div>
                                                                            <div
                                                                              className="custom789 circleName"
                                                                              style={{
                                                                                height:
                                                                                  "75px",
                                                                                width:
                                                                                  "75px",
                                                                                fontSize:
                                                                                  "18px",
                                                                                lineHeight:
                                                                                  "75px",
                                                                              }}
                                                                            >
                                                                              {/* {value
                                                                              .professionList[0]
                                                                              ?.entityTypeNameAbbreviation
                                                                              ? value
                                                                              .professionList[0]
                                                                              ?.entityTypeNameAbbreviation
                                                                              : "--"} */}
                                                                              {value
                                                                                .professionInfoData
                                                                                ?.entityTypeNameAbbreviation ? (
                                                                                <>
                                                                                  {
                                                                                    value
                                                                                      .professionInfoData
                                                                                      ?.entityTypeNameAbbreviation
                                                                                  }
                                                                                </>
                                                                              ) : (
                                                                                <>
                                                                                  {
                                                                                    value
                                                                                      .professionList[0]
                                                                                      ?.entityTypeNameAbbreviation
                                                                                  }
                                                                                </>
                                                                              )}
                                                                            </div>
                                                                            <img
                                                                              src={
                                                                                value
                                                                                  .followUserInfoData
                                                                                  .userInfo
                                                                                  .isVerified
                                                                                  ? verified
                                                                                  : unVerified
                                                                              }
                                                                              alt="verify"
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                top: "54px",
                                                                                left: "52px",
                                                                                width:
                                                                                  "18px",
                                                                              }}
                                                                            />
                                                                          </div>
                                                                          <div>
                                                                            <p
                                                                              style={{
                                                                                fontSize:
                                                                                  "18px",
                                                                                paddingTop:
                                                                                  "25px",
                                                                                paddingLeft:
                                                                                  "25px",
                                                                              }}
                                                                            >
                                                                              {professionalsFlag && (
                                                                                <>
                                                                                  {/* {value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.firstName
                                                                                    ? value
                                                                                        .followUserInfoData
                                                                                        ?.userInformation
                                                                                        ?.firstName
                                                                                    : "--"}{" "}
                                                                                  {value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.lastName
                                                                                    ? value
                                                                                        .followUserInfoData
                                                                                        ?.userInformation
                                                                                        ?.lastName
                                                                                    : "--"} */}
                                                                                    {value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.entityName
                                                                                    ? value
                                                                                        .followUserInfoData
                                                                                        ?.userInformation
                                                                                        ?.entityName
                                                                                    : value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.firstName + ' ' + value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.lastName}
                                                                                </>
                                                                              )}
                                                                              {companiesFlag && (
                                                                                <>
                                                                                  {value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.entityName
                                                                                    ? value
                                                                                        .followUserInfoData
                                                                                        ?.userInformation
                                                                                        ?.entityName
                                                                                    : value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.firstName + ' ' + value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.lastName}
                                                                                </>
                                                                              )}
                                                                            </p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div
                                                                        className="col-6"
                                                                        style={{
                                                                          paddingTop:
                                                                            "30px",
                                                                        }}
                                                                      >
                                                                        {value
                                                                          .followUserInfoData
                                                                          ?.userInfo
                                                                          ?.followingUser ==
                                                                        1 ? (
                                                                          <>
                                                                            <button
                                                                              className="btn"
                                                                              style={{
                                                                                border:
                                                                                  "1px solid #3DBF04",
                                                                                color:
                                                                                  "#3DBF04",
                                                                                fontSize:
                                                                                  "18px",
                                                                                cursor:
                                                                                  "not-allowed",
                                                                              }}
                                                                              disabled
                                                                            >
                                                                              Connected
                                                                            </button>
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <button
                                                                              className="btn"
                                                                              style={{
                                                                                border:
                                                                                  "1px solid #6E7D8C",
                                                                                color:
                                                                                  "#6E7D8C",
                                                                                fontSize:
                                                                                  "18px",
                                                                              }}
                                                                              onClick={e => {connectWithUser(e, value, postDetailsData)}}
                                                                            >
                                                                              Connect
                                                                            </button>
                                                                          </>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}

                                                {followersConnectionFlag && (
                                                  <>
                                                    {connectionData?.follower
                                                      ?.length === 0 ? (
                                                      <>
                                                        <div className="row h-100 w-100">
                                                          <div className="col-12 text-center">
                                                            <img
                                                              className="mb-3"
                                                              src={
                                                                zeroFollowingImage
                                                              }
                                                              alt="No Data Found"
                                                            />
                                                            <p>
                                                              Nothing to show.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {listLoading ? (
                                                          <>
                                                            <div
                                                              className="row"
                                                              style={{
                                                                width: "100%",
                                                                height: "",
                                                              }}
                                                            >
                                                              <div
                                                                className="col-12"
                                                                style={{
                                                                  width: "100%",
                                                                  height:
                                                                    "100%",
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    height:
                                                                      "100%",
                                                                    textAlign:
                                                                      "center",
                                                                    verticalAlign:
                                                                      "middle",
                                                                    lineHeight:
                                                                      "40vh",
                                                                  }}
                                                                >
                                                                  <div
                                                                    className="spinner-grow"
                                                                    role="status"
                                                                    style={{
                                                                      background:
                                                                        "#006838",
                                                                    }}
                                                                  ></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {connectionData?.follower?.map(
                                                              (
                                                                value,
                                                                index
                                                              ) => {
                                                                return (
                                                                  <>
                                                                    <div className="row">
                                                                      <div
                                                                        className="col-6"
                                                                        style={{
                                                                          paddingLeft:
                                                                            "100px",
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="d-flex"
                                                                          style={{
                                                                            marginTop:
                                                                              "20px",
                                                                            position:
                                                                              "relative",
                                                                          }}
                                                                        >
                                                                          <div>
                                                                            <div
                                                                              className="custom789 circleName"
                                                                              style={{
                                                                                height:
                                                                                  "75px",
                                                                                width:
                                                                                  "75px",
                                                                                fontSize:
                                                                                  "18px",
                                                                                lineHeight:
                                                                                  "75px",
                                                                              }}
                                                                            >
                                                                              {value
                                                                                .professionInfoData
                                                                                ?.entityTypeNameAbbreviation ? (
                                                                                <>
                                                                                  {
                                                                                    value
                                                                                      .professionInfoData
                                                                                      ?.entityTypeNameAbbreviation
                                                                                  }
                                                                                </>
                                                                              ) : (
                                                                                <>
                                                                                  {
                                                                                    value
                                                                                      .professionList[0]
                                                                                      ?.entityTypeNameAbbreviation
                                                                                  }
                                                                                </>
                                                                              )}
                                                                              {/* {professionalsFlag && (
                                                                            <>
                                                                            {value
                                                                              .professionInfoData
                                                                              ?.entityTypeNameAbbreviation
                                                                              ? value
                                                                              .professionInfoData
                                                                              ?.entityTypeNameAbbreviation
                                                                              : "--"}
                                                                            </>)}
                                                                            {companiesFlag && (
                                                                            <>
                                                                            {value
                                                                              .professionList[0]
                                                                              ?.entityTypeNameAbbreviation
                                                                              ? value
                                                                              .professionList[0]
                                                                              ?.entityTypeNameAbbreviation
                                                                              : "--"}
                                                                            </>)} */}
                                                                            </div>
                                                                            <img
                                                                              src={
                                                                                value
                                                                                  .followUserInfoData
                                                                                  .userInfo
                                                                                  .isVerified
                                                                                  ? verified
                                                                                  : unVerified
                                                                              }
                                                                              alt="verify"
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                top: "54px",
                                                                                left: "52px",
                                                                                width:
                                                                                  "18px",
                                                                              }}
                                                                            />
                                                                          </div>
                                                                          <div>
                                                                            <p
                                                                              style={{
                                                                                fontSize:
                                                                                  "18px",
                                                                                paddingTop:
                                                                                  "25px",
                                                                                paddingLeft:
                                                                                  "25px",
                                                                              }}
                                                                            >
                                                                              {professionalsFlag && (
                                                                                <>
                                                                                  {/* {value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.firstName
                                                                                    ? value
                                                                                        .followUserInfoData
                                                                                        ?.userInformation
                                                                                        ?.firstName
                                                                                    : "--"}{" "}
                                                                                  {value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.lastName
                                                                                    ? value
                                                                                        .followUserInfoData
                                                                                        ?.userInformation
                                                                                        ?.lastName
                                                                                    : "--"} */}
                                                                                    {value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.entityName
                                                                                    ? value
                                                                                        .followUserInfoData
                                                                                        ?.userInformation
                                                                                        ?.entityName
                                                                                    : value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.firstName + ' ' + value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.lastName}
                                                                                </>
                                                                              )}
                                                                              {companiesFlag && (
                                                                                <>
                                                                                  {value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.entityName
                                                                                    ? value
                                                                                        .followUserInfoData
                                                                                        ?.userInformation
                                                                                        ?.entityName
                                                                                    : value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.firstName + ' ' + value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    ?.lastName}
                                                                                </>
                                                                              )}
                                                                            </p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div
                                                                        className="col-6"
                                                                        style={{
                                                                          paddingTop:
                                                                            "30px",
                                                                        }}
                                                                      >
                                                                        {value
                                                                          .followUserInfoData
                                                                          ?.userInfo
                                                                          ?.followingUser ==
                                                                        1 ? (
                                                                          <>
                                                                            <button
                                                                              className="btn"
                                                                              style={{
                                                                                border:
                                                                                  "1px solid #3DBF04",
                                                                                color:
                                                                                  "#3DBF04",
                                                                                fontSize:
                                                                                  "18px",
                                                                                cursor:
                                                                                  "not-allowed",
                                                                              }}
                                                                              disabled
                                                                            >
                                                                              Connected
                                                                            </button>
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <button
                                                                              className="btn"
                                                                              style={{
                                                                                border:
                                                                                  "1px solid #6E7D8C",
                                                                                color:
                                                                                  "#6E7D8C",
                                                                                fontSize:
                                                                                  "18px",
                                                                              }}
                                                                              onClick={e => {connectWithUser(e, value, postDetailsData)}}
                                                                            >
                                                                              Connect
                                                                            </button>
                                                                          </>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="white_bf6">
                                      <div
                                        className="new_whitr"
                                        style={{
                                          background: "#f2f2f2",
                                          marginTop: "-30px",
                                        }}
                                      >
                                        <h4 className="mb-6">Network</h4>
                                        {tabsLoading ? (
                                          <></>
                                        ) : (
                                          <div
                                            className="custpom_tabs"
                                            style={{
                                              background:
                                                "#FFFFFF 0% 0% no-repeat padding-box",
                                              marginTop: "",
                                              paddingTop: "12px",
                                              paddingBottom: "2px",
                                              borderRadius: "10px",
                                              boxShadow:
                                                "0px 5px 6px #0000000d",
                                              opacity: "1",
                                            }}
                                          >
                                            <ul className="nav nav-tabs" style={{justifyContent:'start'}}>
                                              <li
                                                className="mb-2"
                                                onClick={() => {
                                                  getHomeScreenList("");
                                                  setFilterVisible(true);
                                                  setActiveTab(0);
                                                }}
                                              >
                                                <a
                                                  href="#"
                                                  className={
                                                    activeTab === 0
                                                      ? "active"
                                                      : ""
                                                  }
                                                  style={{
                                                    paddingBottom: "7px",
                                                  }}
                                                >
                                                  All
                                                </a>
                                              </li>
                                              {tabs.length > 0
                                                ? tabs.map((item) => (
                                                    <li
                                                      className="mb-2"
                                                      onClick={() => {
                                                        getHomeScreenList(
                                                          item.userProfileData
                                                            .selectedEntityTypeData
                                                            .entityTypeId
                                                        );
                                                        setFilterVisible(false);
                                                        setActiveTab(item.id);
                                                      }}
                                                    >
                                                      <a
                                                        style={{
                                                          fontSize: "14px",
                                                        }}
                                                        href="#"
                                                        className={
                                                          activeTab === item.id
                                                            ? "active"
                                                            : ""
                                                        }
                                                      >
                                                        {item.userProfileData.selectedEntityTypeData.profession.toUpperCase()}
                                                      </a>
                                                    </li>
                                                  ))
                                                : null}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        className="custpom_tabs"
                                        style={{
                                          background: "#f2f2f2",
                                          marginTop: "-81px",
                                        }}
                                      >
                                        <div className="tab-content">
                                          <div
                                            id="home"
                                            className=""
                                          >
                                            {tabsLoading ? (
                                              <></>
                                            ) : (
                                              <>
                                                <div className="row custom_spece5 mt-3 mb-5 justify-content-between">
                                                  <div
                                                    className="col-md-6 d-flex"
                                                    style={{
                                                      paddingLeft: "26px",
                                                      fontFamily: "poppins",
                                                    }}
                                                  >
                                                    <div
                                                      className="row text-center"
                                                      style={{
                                                        marginTop: "",
                                                        marginBottom: "",
                                                      }}
                                                    >
                                                      <div
                                                        className="mr-2"
                                                        style={
                                                          followingFlag
                                                            ? followingStyleActive()
                                                            : followingStyleInActive()
                                                        }
                                                        onClick={() =>
                                                          fieldClicked(
                                                            "following"
                                                          )
                                                        }
                                                      >
                                                        Following
                                                      </div>
                                                      <div
                                                        className="mr-2"
                                                        style={
                                                          spacesFlag
                                                            ? spacesStyleActive()
                                                            : spacesStyleInActive()
                                                        }
                                                        onClick={() =>
                                                          fieldClicked("spaces")
                                                        }
                                                      >
                                                        Spaces
                                                      </div>
                                                      <div
                                                        className="mr-2"
                                                        style={
                                                          messagesFlag
                                                            ? messagesStyleActive()
                                                            : messagesStyleInActive()
                                                        }
                                                        onClick={() =>
                                                          fieldClicked(
                                                            "messages"
                                                          )
                                                        }
                                                      >
                                                        Messages
                                                      </div>
                                                      <div
                                                        className=""
                                                        style={
                                                          statusUpdateFlag
                                                            ? statusUpdateStyleActive()
                                                            : statusUpdateStyleInActive()
                                                        }
                                                        onClick={() =>
                                                          fieldClicked(
                                                            "statusUpdate"
                                                          )
                                                        }
                                                      >
                                                        Status Update
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div
                                                    className="col-md-6 d-flex"
                                                    style={{
                                                      justifyContent: "end",
                                                      paddingTop: "10px",
                                                    }}
                                                  >
                                                    {!spacesFlag &&
                                                    !messagesFlag &&
                                                    !statusUpdateFlag ? (
                                                      <>
                                                        <img
                                                          src={
                                                            meFollowingFlag
                                                              ? meFollowingGreen
                                                              : meFollowingGrey
                                                          }
                                                          style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            marginRight: "5px",
                                                          }}
                                                          alt="Me Following"
                                                        />
                                                        <p
                                                          className="mr-5"
                                                          style={
                                                            meFollowingFlag
                                                              ? meFollowingStyleActive()
                                                              : meFollowingStyleInActive()
                                                          }
                                                          onClick={() =>
                                                            fieldClicked(
                                                              "meFollowing"
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          Me Following{" "}
                                                        </p>
                                                        <img
                                                          src={
                                                            myFollowersFlag
                                                              ? myFollowersGreen
                                                              : myFollowersGrey
                                                          }
                                                          style={{
                                                            width: "20px",
                                                            height: "15px",
                                                            marginRight: "5px",
                                                          }}
                                                          alt="My Followers"
                                                        />
                                                        <p
                                                          className=""
                                                          style={
                                                            myFollowersFlag
                                                              ? myFollowersStyleActive()
                                                              : myFollowersStyleInActive()
                                                          }
                                                          onClick={() =>
                                                            fieldClicked(
                                                              "myFollowers"
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          My Followers{" "}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="row custom_spece5 mt-3 mb-5 justify-content-between">
                                                {!meFlag && (
                                                  <>
                                                  <img
                                                    src={searchIcon}
                                                    alt="Search"
                                                    style={{
                                                      position: "absolute",
                                                      zIndex: "99999",
                                                      paddingTop: "9px",
                                                      paddingLeft: "20px",
                                                    }}
                                                  />
                                                  </>)}

                                                  {!meFlag && (
                                                  <>
                                                  <img
                                                    src={searchIcon}
                                                    alt="Search"
                                                    style={{
                                                      position: "absolute",
                                                      zIndex: "99999",
                                                      paddingTop: "9px",
                                                      paddingLeft: "20px",
                                                    }}
                                                  />
                                                  </>)}

                                                  {meFlag && (
                                                  <>
                                                  {spacesFlag && (
                                                  <>
                                                  <div
                                                    className="mr-2"
                                                    style={createPostStyle()}
                                                    onClick={() =>
                                                    createPostBtnClicked()
                                                    }
                                                  >
                                                    Add your story{" "}
                                                    <span>
                                                      <img
                                                        src={
                                                          paperPlane
                                                        }
                                                        alt="Create Post"
                                                        style={{
                                                        position:
                                                          "absolute",
                                                        right:
                                                          "0",
                                                        top: "8px",
                                                        width:
                                                          "20px",
                                                        marginRight:
                                                          "5px",
                                                        }}
                                                      />
                                                    </span>
                                                  </div>
                                                  </>)}
                                                  
                                                  {statusUpdateFlag && (
                                                  <>
                                                  <div
                                                    className=""
                                                    style={whatInMindStyle()}
                                                    onClick={() =>
                                                      whatInYourMindBtnClicked()
                                                    }
                                                  >
                                                    What's in your
                                                    mind?
                                                  </div>
                                                  </>)}

                                                  </>)}
                                                  
                                                  {!spacesFlag && (
                                                  <>
                                                  {!messagesFlag && (
                                                    <>
                                                    {professionalsFlag && (
                                                    <>
                                                    <input
                                                        onChange={
                                                          optimizedSearchFollowingProfessionals
                                                        }
                                                        type="text"
                                                        name="Search"
                                                        className="form-control col-xxl-4 col-xl-3 col-lg-3 col-md-3 col-sm-3"
                                                        placeholder="Search"
                                                        style={{
                                                          maxWidth: "350px",
                                                          float: "right",
                                                          marginRight: "12px",
                                                          paddingLeft: "33px",
                                                          marginLeft: "13px",
                                                        }}
                                                      />
                                                    </>)}
                                                    {companiesFlag && (
                                                    <>
                                                    <input
                                                        onChange={
                                                          optimizedSearchFollowingCompanies
                                                        }
                                                        type="text"
                                                        name="Search"
                                                        className="form-control col-xxl-4 col-xl-3 col-lg-3 col-md-3 col-sm-3"
                                                        placeholder="Search"
                                                        style={{
                                                          maxWidth: "350px",
                                                          float: "right",
                                                          marginRight: "12px",
                                                          paddingLeft: "33px",
                                                          marginLeft: "13px",
                                                        }}
                                                      />
                                                    </>)}
                                                      
                                                    </>
                                                  )}
                                                  </>)}
                                                  
                                                  {messagesFlag && (
                                                    <>
                                                      {professionalsFlag && (
                                                        <>
                                                          <input
                                                            onChange={
                                                              optimizedSearchMessagesProfessionals
                                                            }
                                                            type="text"
                                                            name="Search"
                                                            className="form-control col-md-4"
                                                            placeholder="Search Messages For Professionals"
                                                            style={{
                                                              maxWidth: "350px",
                                                              float: "right",
                                                              marginRight:
                                                                "12px",
                                                              paddingLeft:
                                                                "33px",
                                                              marginLeft:
                                                                "13px",
                                                            }}
                                                          />
                                                        </>
                                                      )}

                                                      {companiesFlag && (
                                                        <>
                                                          <input
                                                            onChange={
                                                              optimizedSearchMessagesCompanies
                                                            }
                                                            type="text"
                                                            name="Search"
                                                            className="form-control col-md-4"
                                                            placeholder="Search Messages For Companies"
                                                            style={{
                                                              maxWidth: "350px",
                                                              float: "right",
                                                              marginRight:
                                                                "12px",
                                                              paddingLeft:
                                                                "33px",
                                                              marginLeft:
                                                                "13px",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  )}

                                                  {spacesFlag && (
                                                    <>
                                                      {professionalsFlag && (
                                                        <>
                                                          <input
                                                            onChange={
                                                              optimizedSearchSpacesProfessionals
                                                            }
                                                            type="text"
                                                            name="Search"
                                                            className="form-control col-md-4"
                                                            placeholder="Search Spaces For Professionals"
                                                            style={{
                                                              maxWidth: "350px",
                                                              float: "right",
                                                              marginRight:
                                                                "12px",
                                                              paddingLeft:
                                                                "33px",
                                                              marginLeft:
                                                                "13px",
                                                            }}
                                                          />
                                                        </>
                                                      )}

                                                      {companiesFlag && (
                                                        <>
                                                          <input
                                                            onChange={
                                                              optimizedSearchSpacesCompanies
                                                            }
                                                            type="text"
                                                            name="Search"
                                                            className="form-control col-md-4"
                                                            placeholder="Search Spaces For Companies"
                                                            style={{
                                                              maxWidth: "350px",
                                                              float: "right",
                                                              marginRight:
                                                                "12px",
                                                              paddingLeft:
                                                                "33px",
                                                              marginLeft:
                                                                "13px",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  )}

                                                  <div
                                                    className="col-xxl-8 col-xl-9 col-lg-9 col-md-9 col-sm-9 d-flex"
                                                    style={{
                                                      paddingLeft: "26px",
                                                      fontFamily: "poppins",
                                                      justifyContent: "end",
                                                    }}
                                                  >
                                                    <div
                                                      className="row text-center"
                                                      style={{
                                                        marginTop: "",
                                                        marginBottom: "",
                                                      }}
                                                    >
                                                      <div
                                                        className="mr-4"
                                                        style={
                                                          professionalsFlag
                                                            ? professionalsStyleActive()
                                                            : professionalsStyleInActive()
                                                        }
                                                        onClick={() =>
                                                          fieldClicked(
                                                            "professionals"
                                                          )
                                                        }
                                                      >
                                                        Professionals
                                                      </div>
                                                      <div
                                                        className="mr-4"
                                                        style={
                                                          companiesFlag
                                                            ? companiesStyleActive()
                                                            : companiesStyleInActive()
                                                        }
                                                        onClick={() =>
                                                          fieldClicked(
                                                            "companies"
                                                          )
                                                        }
                                                      >
                                                        Companies
                                                      </div>
                                                      {spacesFlag ? (
                                                        <>
                                                          <div
                                                            className="mr-4"
                                                            style={
                                                              meFlag
                                                                ? meStyleActive()
                                                                : meStyleInActive()
                                                            }
                                                            onClick={() =>
                                                              fieldClicked("me")
                                                            }
                                                          >
                                                            Me
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {statusUpdateFlag ? (
                                                        <>
                                                          <div
                                                            className="mr-4"
                                                            style={
                                                              meFlag
                                                                ? meStyleActive()
                                                                : meStyleInActive()
                                                            }
                                                            onClick={() =>
                                                              fieldClicked("me")
                                                            }
                                                          >
                                                            Me
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row custom_spece5 mt-3 mb-5">
                                                  <div
                                                    className="col-md-12"
                                                    style={{
                                                      paddingLeft: "26px",
                                                      fontFamily: "poppins",
                                                    }}
                                                  >
                                                    <div
                                                      className="row text-center"
                                                      style={{
                                                        marginTop: "",
                                                        marginBottom: "",
                                                      }}
                                                    >
                                                      {spacesFlag && (
                                                        <>
                                                          {meFlag && (
                                                            <>
                                                              {/* <div
                                                                className="mr-2 mb-5"
                                                                style={createPostStyle()}
                                                                onClick={() =>
                                                                  createPostBtnClicked()
                                                                }
                                                              >
                                                                Add your story{" "}
                                                                <span>
                                                                  <img
                                                                    src={
                                                                      paperPlane
                                                                    }
                                                                    alt="Create Post"
                                                                    style={{
                                                                      position:
                                                                        "absolute",
                                                                      right:
                                                                        "0",
                                                                      top: "8px",
                                                                      width:
                                                                        "20px",
                                                                      marginRight:
                                                                        "5px",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div> */}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                      {statusUpdateFlag && (
                                                        <>
                                                          {meFlag && (
                                                            <>
                                                              {/* <div
                                                                className="mb-5"
                                                                style={whatInMindStyle()}
                                                                onClick={() =>
                                                                  whatInYourMindBtnClicked()
                                                                }
                                                              >
                                                                What's in your
                                                                mind?
                                                              </div> */}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {followingFlag && (
                                        <>
                                          <div
                                            className="Profile_form custom_spece5"
                                            style={{
                                              height: "",
                                              background: "",
                                              paddingLeft: "32px",
                                              paddingTop: "32px",
                                              marginTop: "-16px",
                                              paddingRight: "32px",
                                            }}
                                          >
                                            {listLoading ? (
                                              <div
                                                className="row"
                                                style={{
                                                  width: "100%",
                                                  height: "",
                                                }}
                                              >
                                                <div
                                                  className="col-12"
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      textAlign: "center",
                                                      verticalAlign: "middle",
                                                      lineHeight: "60vh",
                                                    }}
                                                  >
                                                    <div
                                                      className="spinner-grow"
                                                      role="status"
                                                      style={{
                                                        background: "#006838",
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                {followingData.length == 0 ? (
                                                  <>
                                                    <div className="row h-100 w-100">
                                                      <div className="col-12 text-center">
                                                        <img
                                                          className="mb-3"
                                                          src={
                                                            zeroFollowingImage
                                                          }
                                                          alt="No Data Found"
                                                        />
                                                        {meFollowingFlag && (
                                                          <>
                                                            <p>
                                                              You are not
                                                              following anyone.
                                                              Follow someone
                                                              from Space.
                                                            </p>
                                                          </>
                                                        )}
                                                        {myFollowersFlag && (
                                                          <>
                                                            <p>
                                                              You do not have
                                                              any followers
                                                              right now.
                                                            </p>
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="row">
                                                      {followingData.map(
                                                        (value, index) => {
                                                          return (
                                                            <>
                                                              <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <div className="blush_outer">
                                                                  <div className="geoge_outer">
                                                                    <div className="custom789 circleName">
                                                                      {value
                                                                        .professionInfoData
                                                                        ?.entityTypeNameAbbreviation
                                                                        ? value
                                                                            .professionInfoData
                                                                            ?.entityTypeNameAbbreviation
                                                                        : "N/A"}
                                                                    </div>
                                                                    <img
                                                                      src={
                                                                        value
                                                                          .followUserInfoData
                                                                          .userInfo
                                                                          .isVerified
                                                                          ? verified
                                                                          : unVerified
                                                                      }
                                                                      alt="verify"
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        top: "55px",
                                                                        left: "55px",
                                                                      }}
                                                                    />
                                                                    <div className="google_inner">
                                                                      <div className="d-flex justify-content-between align-items-center">
                                                                        {professionalsFlag && (
                                                                          <>
                                                                            <h5>
                                                                              {value
                                                                                .followUserInfoData
                                                                                ?.userInformation
                                                                                ?.firstName
                                                                                ? value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    .firstName
                                                                                : "--"}{" "}
                                                                              {value
                                                                                .followUserInfoData
                                                                                ?.userInformation
                                                                                ?.lastName
                                                                                ? value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    .lastName
                                                                                : "--"}
                                                                            </h5>
                                                                          </>
                                                                        )}
                                                                        {companiesFlag && (
                                                                          <>
                                                                            <h5>
                                                                              {value
                                                                                .followUserInfoData
                                                                                ?.userInformation
                                                                                ?.entityName
                                                                                ? value
                                                                                    .followUserInfoData
                                                                                    ?.userInformation
                                                                                    .entityName
                                                                                : "--"}
                                                                            </h5>
                                                                          </>
                                                                        )}

                                                                        {/* <img className='badgeClass mr-6' src={showBadge(value.userProfileDataTypes.selectedUserDataRating)} alt="badge" /> */}
                                                                        <p
                                                                          style={{
                                                                            cursor:
                                                                              "pointer",
                                                                            color:
                                                                              "rgb(61, 191, 4)",
                                                                            position:
                                                                              "relative",
                                                                            top: "3px",
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            unFollowFunction(
                                                                              e,
                                                                              value
                                                                            );
                                                                          }}
                                                                        >
                                                                          Unfollow
                                                                        </p>
                                                                      </div>

                                                                      <p className="custom_png mt-3 d-flex">
                                                                        <img
                                                                          className="custom78"
                                                                          src={
                                                                            locationGrey
                                                                          }
                                                                          alt=""
                                                                          style={{
                                                                            color:
                                                                              "#6E7D8C",
                                                                          }}
                                                                        />
                                                                        {
                                                                          value
                                                                            .followUserInfoData
                                                                            ?.userInformation
                                                                            ?.state
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                          value
                                                                            .followUserInfoData
                                                                            ?.userInformation
                                                                            ?.country
                                                                        }
                                                                        <span
                                                                          className="ml-3 mr-4"
                                                                          style={{
                                                                            height:
                                                                              "20px",
                                                                            width:
                                                                              "0px",
                                                                            border:
                                                                              "1px solid #707070",
                                                                            opacity:
                                                                              "0.2",
                                                                          }}
                                                                        ></span>{" "}
                                                                        <div>
                                                                          {
                                                                            value
                                                                              .professionInfoData
                                                                              ?.profession
                                                                          }
                                                                        </div>
                                                                      </p>
                                                                    </div>
                                                                    {professionalsFlag && (
                                                                      <>
                                                                        <p
                                                                          className="mt-5 mb-0 ml-1"
                                                                          style={{
                                                                            color:
                                                                              "#494949",
                                                                          }}
                                                                        >
                                                                          <img
                                                                            className="custom78"
                                                                            src={
                                                                              statusIcon
                                                                            }
                                                                            alt=""
                                                                            style={{
                                                                              color:
                                                                                "#6E7D8C",
                                                                            }}
                                                                          />
                                                                          {
                                                                            value
                                                                              .userUpdateStatusInfoData
                                                                              .description
                                                                          }
                                                                        </p>
                                                                      </>
                                                                    )}

                                                                    {companiesFlag && (
                                                                      <>
                                                                        <p
                                                                          className="mt-5 mb-0 ml-1"
                                                                          style={{
                                                                            color:
                                                                              "#494949",
                                                                          }}
                                                                        >
                                                                          <img
                                                                            className="custom78"
                                                                            src={
                                                                              statusIcon
                                                                            }
                                                                            alt=""
                                                                            style={{
                                                                              color:
                                                                                "#6E7D8C",
                                                                            }}
                                                                          />
                                                                          {
                                                                            value
                                                                              .userUpdateStatusInfoData
                                                                              .description
                                                                          }
                                                                        </p>
                                                                      </>
                                                                    )}
                                                                  </div>
                                                                  {/* <div className="button_list">
                                                        <a className="about_btn" href="javascript:void(0)" onClick={(e) => { openTalentDetailsPage(e, value) }}>About</a>
                                                        <a className="about_btn shift_outer5" href="javascript:void(0)" onClick={(e) => { openShiftModal(e, value) }}>Shift Type</a>
                                                    </div> */}
                                                                  <div className="button_list">
                                                                    {/* <a
                                                                  className="about_btn"
                                                                  href="javascript:void(0)"
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid gainsboro",
                                                                  }}
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    unFollowFunction(
                                                                      e,
                                                                      value
                                                                    );
                                                                  }}
                                                                >
                                                                  Unfollow
                                                                </a> */}
                                                                    <a
                                                                      className="about_btn"
                                                                      href="javascript:void(0)"
                                                                      style={{
                                                                        borderRight:
                                                                          "1px solid gainsboro",
                                                                        paddingLeft:
                                                                          "0px",
                                                                        paddingRight:
                                                                          "0px",
                                                                      }}
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        openSpacesWindow(
                                                                          e,
                                                                          value
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img
                                                                        className="mr-2"
                                                                        src={
                                                                          spacesIcon
                                                                        }
                                                                        alt="Spaces Icon"
                                                                      />
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "#2B2B2B",
                                                                        }}
                                                                      >
                                                                        Spaces
                                                                      </span>
                                                                    </a>
                                                                    <a
                                                                      className="about_btn"
                                                                      href="javascript:void(0)"
                                                                      style={{
                                                                        paddingLeft:
                                                                          "0px",
                                                                        paddingRight:
                                                                          "0px",
                                                                      }}
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        openNewConversationWindow(
                                                                          e,
                                                                          value
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img
                                                                        className="mr-2"
                                                                        src={
                                                                          messageIcon
                                                                        }
                                                                        alt="Spaces Icon"
                                                                      />
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "#2B2B2B",
                                                                        }}
                                                                      >
                                                                        Message
                                                                      </span>
                                                                    </a>
                                                                    <a
                                                                      className="about_btn"
                                                                      href="javascript:void(0)"
                                                                      style={{
                                                                        borderLeft:
                                                                          "1px solid gainsboro",
                                                                        paddingLeft:
                                                                          "0px",
                                                                        paddingRight:
                                                                          "0px",
                                                                      }}
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        getConnectionData(
                                                                          e,
                                                                          value
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img
                                                                        className="mr-2"
                                                                        src={
                                                                          myFollowersGrey
                                                                        }
                                                                        alt="Connections Icon"
                                                                      />
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "#2B2B2B",
                                                                        }}
                                                                      >
                                                                        Connections
                                                                      </span>
                                                                    </a>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}

                                      {spacesFlag && (
                                        <>
                                          <div
                                            className="Profile_form custom_spece5"
                                            style={{
                                              height: "",
                                              background: "",
                                              paddingLeft: "32px",
                                              paddingTop: "32px",
                                              marginTop: "-16px",
                                              paddingRight: "32px",
                                            }}
                                          >
                                            {listLoading ? (
                                              <div
                                                className="row"
                                                style={{
                                                  width: "100%",
                                                  height: "",
                                                }}
                                              >
                                                <div
                                                  className="col-12"
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      textAlign: "center",
                                                      verticalAlign: "middle",
                                                      lineHeight: "50vh",
                                                    }}
                                                  >
                                                    <div
                                                      className="spinner-grow"
                                                      role="status"
                                                      style={{
                                                        background: "#006838",
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                {spacesData.length == 0 ? (
                                                  <>
                                                    <div className="row h-100 w-100">
                                                      <div className="col-12 text-center">
                                                        <img
                                                          className="mb-3"
                                                          src={
                                                            zeroFollowingImage
                                                          }
                                                          alt="No Data Found"
                                                        />
                                                        <p>
                                                          No Spaces data to
                                                          show.
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="row">
                                                      {spacesData.map(
                                                        (value, index) => {
                                                          return (
                                                            <>
                                                              <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <div className="blush_outer">
                                                                  <div className="geoge_outer">
                                                                    <div className="custom789 circleName">
                                                                      {value
                                                                        .professionInfoData
                                                                        ?.entityTypeNameAbbreviation
                                                                        ? value
                                                                            .professionInfoData
                                                                            ?.entityTypeNameAbbreviation
                                                                        : "N/A"}
                                                                    </div>
                                                                    <img
                                                                      src={
                                                                        value
                                                                          .userInfoData
                                                                          .userInfo
                                                                          .isVerified
                                                                          ? verified
                                                                          : unVerified
                                                                      }
                                                                      alt="verify"
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        top: "55px",
                                                                        left: "55px",
                                                                      }}
                                                                    />
                                                                    <div className="google_inner">
                                                                      <div
                                                                        className="d-flex justify-content-between align-items-center"
                                                                        style={{
                                                                          position:
                                                                            "relative",
                                                                        }}
                                                                      >
                                                                        {professionalsFlag && (
                                                                          <>
                                                                            <h5>
                                                                              {value
                                                                                .userInfoData
                                                                                ?.userInformation
                                                                                ?.firstName
                                                                                ? value
                                                                                    .userInfoData
                                                                                    ?.userInformation
                                                                                    .firstName
                                                                                : "--"}{" "}
                                                                              {value
                                                                                .userInfoData
                                                                                ?.userInformation
                                                                                ?.lastName
                                                                                ? value
                                                                                    .userInfoData
                                                                                    ?.userInformation
                                                                                    .lastName
                                                                                : "--"}
                                                                            </h5>
                                                                          </>
                                                                        )}
                                                                        {companiesFlag && (
                                                                          <>
                                                                            <h5>
                                                                              {value
                                                                                .userInfoData
                                                                                ?.userInformation
                                                                                ?.entityName
                                                                                ? value
                                                                                    .userInfoData
                                                                                    ?.userInformation
                                                                                    .entityName
                                                                                : "--"}
                                                                            </h5>
                                                                          </>
                                                                        )}
                                                                        {meFlag && (
                                                                          <>
                                                                            <h5>
                                                                              {value
                                                                                .userInfoData
                                                                                ?.userInformation
                                                                                ?.firstName
                                                                                ? value
                                                                                    .userInfoData
                                                                                    ?.userInformation
                                                                                    .firstName
                                                                                : "--"}{" "}
                                                                              {value
                                                                                .userInfoData
                                                                                ?.userInformation
                                                                                ?.lastName
                                                                                ? value
                                                                                    .userInfoData
                                                                                    ?.userInformation
                                                                                    .lastName
                                                                                : "--"}
                                                                            </h5>
                                                                          </>
                                                                        )}
                                                                        {/* {!meFlag && (
                                                                                            <>
                                                                                            <p className="mt-2" style={{ color: '#3ABD00', cursor: 'pointer', position: 'absolute', top: '-5px', right: '20px' }} onClick={() => { followProfileFromSpaces(value) }}>+ Follow</p>
                                                                                            </>)} */}

                                                                        <i
                                                                          className="fa fa-ellipsis-v"
                                                                          aria-hidden="true"
                                                                          style={{
                                                                            color:
                                                                              "black",
                                                                            fontSize:
                                                                              "18px",
                                                                            cursor:
                                                                              "pointer",
                                                                            fontWeight:
                                                                              "500",
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            openPopup(
                                                                              e,
                                                                              value
                                                                            );
                                                                          }}
                                                                        ></i>
                                                                        {/* <img className='badgeClass mr-6' src={showBadge(value.userProfileDataTypes.selectedUserDataRating)} alt="badge" /> */}
                                                                        {meFlag && (
                                                                          <>
                                                                            <div
                                                                              className="blush_outer"
                                                                              id={
                                                                                value.spaceIdHash +
                                                                                "popup"
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                height:
                                                                                  "100px",
                                                                                width:
                                                                                  "135px",
                                                                                top: "25px",
                                                                                right:
                                                                                  "5px",
                                                                                border:
                                                                                  "1px solid gainsboro",
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                <p
                                                                                  className="p-2 mb-0"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                  }}
                                                                                >
                                                                                  More
                                                                                  Options
                                                                                </p>
                                                                                <i
                                                                                  className="fa fa-times"
                                                                                  aria-hidden="true"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                    paddingTop:
                                                                                      "10px",
                                                                                    marginLeft:
                                                                                      "15px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    closePopup(
                                                                                      e,
                                                                                      value
                                                                                    );
                                                                                  }}
                                                                                ></i>
                                                                              </div>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  editPostBtnClicked(
                                                                                    e,
                                                                                    value
                                                                                  );
                                                                                }}
                                                                              >
                                                                                Edit
                                                                                Post
                                                                              </p>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  deleteSpacePostData(
                                                                                    e,
                                                                                    value
                                                                                  );
                                                                                }}
                                                                              >
                                                                                Delete
                                                                                Post
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}

                                                                        {professionalsFlag && (
                                                                          <>
                                                                            <div
                                                                              className="blush_outer"
                                                                              id={
                                                                                value.spaceIdHash +
                                                                                "popup"
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                height:
                                                                                  "105px",
                                                                                width:
                                                                                  "135px",
                                                                                top: "25px",
                                                                                right:
                                                                                  "5px",
                                                                                border:
                                                                                  "1px solid gainsboro",
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                <p
                                                                                  className="p-2 mb-0"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                  }}
                                                                                >
                                                                                  More
                                                                                  Options
                                                                                </p>
                                                                                <i
                                                                                  className="fa fa-times"
                                                                                  aria-hidden="true"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                    paddingTop:
                                                                                      "10px",
                                                                                    marginLeft:
                                                                                      "15px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    closePopup(
                                                                                      e,
                                                                                      value
                                                                                    );
                                                                                  }}
                                                                                ></i>
                                                                              </div>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(e) => {
                                                                                  reportUserBtnClicked(e, value)
                                                                                }}
                                                                              >
                                                                                Report
                                                                                User
                                                                              </p>
                                                                              {/* <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              >
                                                                                Report
                                                                                Post
                                                                              </p> */}
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(e) => {
                                                                                  blockUserBtnClicked(e, value)
                                                                                }}
                                                                              >
                                                                                Block
                                                                                User
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}

                                                                        {companiesFlag && (
                                                                          <>
                                                                            <div
                                                                              className="blush_outer"
                                                                              id={
                                                                                value.spaceIdHash +
                                                                                "popup"
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                height:
                                                                                  "105px",
                                                                                width:
                                                                                  "135px",
                                                                                top: "25px",
                                                                                right:
                                                                                  "5px",
                                                                                border:
                                                                                  "1px solid gainsboro",
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                <p
                                                                                  className="p-2 mb-0"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                  }}
                                                                                >
                                                                                  More
                                                                                  Options
                                                                                </p>
                                                                                <i
                                                                                  className="fa fa-times"
                                                                                  aria-hidden="true"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                    paddingTop:
                                                                                      "10px",
                                                                                    marginLeft:
                                                                                      "15px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    closePopup(
                                                                                      e,
                                                                                      value
                                                                                    );
                                                                                  }}
                                                                                ></i>
                                                                              </div>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(e) => {
                                                                                  reportUserBtnClicked(e, value)
                                                                                }}
                                                                              >
                                                                                Report
                                                                                Company
                                                                              </p>
                                                                              {/* <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              >
                                                                                Report
                                                                                Post
                                                                              </p> */}
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(e) => {
                                                                                  blockUserBtnClicked(e, value)
                                                                                }}
                                                                              >
                                                                                Block
                                                                                Company
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}
                                                                      </div>
                                                                      {/* <h5>{value.userProfileDataTypes.Profession}</h5> */}
                                                                      <p className="mt-0 mb-1">
                                                                        {value
                                                                          .professionInfoData
                                                                          ?.profession
                                                                          ? value
                                                                              .professionInfoData
                                                                              ?.profession
                                                                          : "--"}
                                                                      </p>
                                                                      <p
                                                                        className="mt-0"
                                                                        style={{
                                                                          color:
                                                                            "#6E7D8C",
                                                                        }}
                                                                      >
                                                                        {
                                                                          "7 hours ago"
                                                                        }
                                                                        <span
                                                                          className="mx-4"
                                                                          style={{
                                                                            color:
                                                                              "gainsboro",
                                                                          }}
                                                                        >
                                                                          |
                                                                        </span>
                                                                        {value
                                                                          .spaceTypeInfoData
                                                                          ?.isPrivate ? (
                                                                          <>
                                                                            <img
                                                                              className="mr-2"
                                                                              src={
                                                                                privateIcon
                                                                              }
                                                                              alt="Private Icon"
                                                                              style={{
                                                                                width:
                                                                                  "12px",
                                                                                paddingBottom:
                                                                                  "4px",
                                                                              }}
                                                                            />
                                                                            Private
                                                                          </>
                                                                        ) : value
                                                                            .spaceTypeInfoData
                                                                            ?.isPublic ? (
                                                                          <>
                                                                            <img
                                                                              className="mr-2"
                                                                              src={
                                                                                publicIcon
                                                                              }
                                                                              alt="Public Icon"
                                                                              style={{
                                                                                width:
                                                                                  "14px",
                                                                                paddingBottom:
                                                                                  "1px",
                                                                              }}
                                                                            />
                                                                            Public
                                                                          </>
                                                                        ) : value
                                                                            .spaceTypeInfoData
                                                                            ?.isRestricted ? (
                                                                          <>
                                                                            <img
                                                                              className="mr-2"
                                                                              src={
                                                                                privateIcon
                                                                              }
                                                                              alt="Restricted Icon"
                                                                              style={{
                                                                                width:
                                                                                  "12px",
                                                                                paddingBottom:
                                                                                  "4px",
                                                                              }}
                                                                            />
                                                                            Restricted
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <img
                                                                              className="mr-2"
                                                                              src={
                                                                                publicIcon
                                                                              }
                                                                              alt="Public Icon"
                                                                              style={{
                                                                                width:
                                                                                  "14px",
                                                                                paddingBottom:
                                                                                  "1px",
                                                                              }}
                                                                            />
                                                                            Public
                                                                          </>
                                                                        )}
                                                                      </p>
                                                                    </div>
                                                                    <p className="ml-2">
                                                                      <b>
                                                                        {value.title ? (
                                                                          value.title
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                      </b>
                                                                    </p>
                                                                    <p
                                                                      className="ml-2"
                                                                      id={
                                                                        value.spaceIdHash
                                                                      }
                                                                      style={viewLessDescStyle()}
                                                                    >
                                                                      {value.description ? (
                                                                        value.description
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                    </p>

                                                                    {/* <p className="ml-2 my-3" id={`viewMore${value.spaceIdHash}`} style={{ color: '#3ABD00', cursor: 'pointer' }} onClick={(e) => viewMoreBtnClicked(e, value)}>
                                                                                        View More
                                                                                    </p> */}
                                                                  </div>
                                                                  {meFlag ? (
                                                                    <>
                                                                      <div className="">
                                                                        <p
                                                                          className="my-3"
                                                                          id={`viewMore${value.spaceIdHash}`}
                                                                          style={{
                                                                            color:
                                                                              "#2B2B2B",
                                                                            cursor:
                                                                              "pointer",
                                                                            borderTop:
                                                                              "1px solid gainsboro",
                                                                            textAlign:
                                                                              "center",
                                                                            paddingTop:
                                                                              "15px",
                                                                            paddingBottom:
                                                                              "15px",
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            viewMoreBtnClickedForMeSpaces(
                                                                              e,
                                                                              value
                                                                            )
                                                                          }
                                                                        >
                                                                          More...
                                                                        </p>
                                                                      </div>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <div className="button_list">
                                                                        <a
                                                                          className="about_btn"
                                                                          href="javascript:void(0)"
                                                                          style={{
                                                                            borderRight:
                                                                              "1px solid gainsboro",
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            followProfileFromSpaces(
                                                                              value
                                                                            );
                                                                          }}
                                                                        >
                                                                          +
                                                                          Follow
                                                                        </a>
                                                                        <a
                                                                          className="about_btn"
                                                                          href="javascript:void(0)"
                                                                          style={{
                                                                            color:
                                                                              "#2B2B2B",
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            viewMoreBtnClicked(
                                                                              e,
                                                                              value
                                                                            );
                                                                          }}
                                                                        >
                                                                          More...
                                                                        </a>
                                                                      </div>
                                                                      {/* <p className="mt-2" style={{ color: '#3ABD00', cursor: 'pointer', position: 'absolute', top: '-5px', right: '20px' }} onClick={() => { followProfileFromSpaces(value) }}>+ Follow</p> */}
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}

                                      {messagesFlag && (
                                        <>
                                          <div
                                            className="Profile_form custom_spece5"
                                            style={{
                                              height: "",
                                              background: "",
                                              paddingLeft: "32px",
                                              paddingTop: "32px",
                                              marginTop: "-16px",
                                              paddingRight: "32px",
                                            }}
                                          >
                                            <div className="row">
                                              <div className="col-md-4">
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    justifyContent:
                                                      "space-between",
                                                    position: "relative"
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      paddingTop: "10px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {/* <img src={plusIcon} alt="Plus" /><span className="ml-3" style={{ color: '#3DBF04' }}>Start New Conversation</span> */}
                                                  </div>
                                                  <button
                                                    className="btn"
                                                    style={{
                                                      background: "white",
                                                    }}
                                                    onClick={(e) => {openSortPopUp(e)}}
                                                  >
                                                    <img
                                                      src={sortIcon}
                                                      className="mr-3"
                                                      alt="Sort"
                                                    />
                                                    Sort
                                                  </button>

                                                  <div
                                                    className="blush_outer"
                                                    id="sortBtnId"
                                                    style={{
                                                      position: "absolute",
                                                      height: "100px",
                                                      width: "100px",
                                                      top: "40px",
                                                      right: "0%",
                                                      border:
                                                        "1px solid gainsboro",
                                                      display: "none",
                                                      zIndex: "99999",
                                                    }}
                                                  >
                                                    <div className="d-flex">
                                                      <p
                                                        className="p-2 mb-0"
                                                        style={{
                                                          color: "grey",
                                                        }}
                                                      >
                                                        Sort By
                                                      </p>
                                                      <i
                                                        className="fa fa-times"
                                                        aria-hidden="true"
                                                        style={{
                                                          color: "grey",
                                                          fontSize: "14px",
                                                          paddingTop: "10px",
                                                          marginLeft: "15px",
                                                        }}
                                                        onClick={(e) => {
                                                          closeSortPopUp(e)
                                                        }}
                                                      ></i>
                                                    </div>
                                                    
                                                    <p className="p-2 mt-0 mb-0"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={(e) => {
                                                        sortMessageFn(0)
                                                      }}
                                                    >
                                                      New
                                                    </p>
                                                    
                                                    <p className="p-2 mt-0 mb-0"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={(e) => {
                                                        sortMessageFn(1)
                                                      }}
                                                    >
                                                      Old
                                                    </p>
                                                  </div>
                                                </div>
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    position: "absolute",
                                                    top: "10px",
                                                  }}
                                                >
                                                  <p
                                                    className="mr-5"
                                                    style={
                                                      inboxNewFlag
                                                        ? {
                                                            color: "#3DBF04",
                                                            cursor: "pointer",
                                                          }
                                                        : {
                                                            color: "#3F4254",
                                                            cursor: "pointer",
                                                          }
                                                    }
                                                    onClick={(e) => {
                                                      changeMessageCategory(
                                                        e,
                                                        "new"
                                                      );
                                                    }}
                                                  >
                                                    Inbox (New)
                                                  </p>
                                                  <p
                                                    className="mr-5"
                                                    style={
                                                      inboxReadFlag
                                                        ? {
                                                            color: "#3DBF04",
                                                            cursor: "pointer",
                                                          }
                                                        : {
                                                            color: "#3F4254",
                                                            cursor: "pointer",
                                                          }
                                                    }
                                                    onClick={(e) => {
                                                      changeMessageCategory(
                                                        e,
                                                        "read"
                                                      );
                                                    }}
                                                  >
                                                    Inbox (Read)
                                                  </p>
                                                  <p
                                                    style={
                                                      inboxArchivedFlag
                                                        ? {
                                                            color: "#3DBF04",
                                                            cursor: "pointer",
                                                          }
                                                        : {
                                                            color: "#3F4254",
                                                            cursor: "pointer",
                                                          }
                                                    }
                                                    onClick={(e) => {
                                                      changeMessageCategory(
                                                        e,
                                                        "archived"
                                                      );
                                                    }}
                                                  >
                                                    Archived
                                                  </p>
                                                </div>
                                                <div
                                                  className="mt-5 pt-4"
                                                  style={
                                                    messageListLoading
                                                      ? inboxStyleLoading()
                                                      : inboxStyle()
                                                  }
                                                >
                                                  {messageListLoading ? (
                                                    <>
                                                      <div
                                                        className="row"
                                                        style={{
                                                          width: "100%",
                                                          height: "50vh",
                                                        }}
                                                      >
                                                        <div
                                                          className="col-12"
                                                          style={{
                                                            width: "100%",
                                                            height: "100%",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              width: "100%",
                                                              height: "100%",
                                                              textAlign:
                                                                "center",
                                                              verticalAlign:
                                                                "middle",
                                                              lineHeight:
                                                                "50vh",
                                                            }}
                                                          >
                                                            <div
                                                              className="spinner-grow"
                                                              role="status"
                                                              style={{
                                                                background:
                                                                  "#006838",
                                                              }}
                                                            ></div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {messageData.length ==
                                                      0 ? (
                                                        <>
                                                          <div className="row h-100 w-100">
                                                            <div className="col-12 text-center">
                                                              <img
                                                                className="mb-3"
                                                                src={
                                                                  zeroFollowingImage
                                                                }
                                                                alt="No Data Found"
                                                              />
                                                              <p>
                                                                No messages to
                                                                show.
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {messageData.map(
                                                            (value, index) => {
                                                              return (
                                                                <>
                                                                  <div
                                                                    className="pt-0 pl-5 pr-5"
                                                                    style={{
                                                                      cursor:
                                                                        "",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className="d-flex"
                                                                      style={{
                                                                        position:
                                                                          "relative",
                                                                        height:
                                                                          "85px",
                                                                      }}
                                                                    >
                                                                      {inboxNewFlag && (
                                                                        <>
                                                                          <span
                                                                            style={{
                                                                              height:
                                                                                "10px",
                                                                              width:
                                                                                "10px",
                                                                              borderRadius:
                                                                                "50%",
                                                                              background:
                                                                                "#319CFF 0% 0% no-repeat padding-box",
                                                                              position:
                                                                                "relative",
                                                                              top: "20px",
                                                                              marginRight:
                                                                                "10px",
                                                                            }}
                                                                          ></span>
                                                                        </>
                                                                      )}
                                                                      {inboxReadFlag && (
                                                                        <>
                                                                          <span
                                                                            style={{
                                                                              height:
                                                                                "10px",
                                                                              width:
                                                                                "10px",
                                                                              borderRadius:
                                                                                "50%",
                                                                              background:
                                                                                "#CCCCCC 0% 0% no-repeat padding-box",
                                                                              position:
                                                                                "relative",
                                                                              top: "20px",
                                                                              marginRight:
                                                                                "10px",
                                                                            }}
                                                                          ></span>
                                                                        </>
                                                                      )}
                                                                      {inboxArchivedFlag && (
                                                                        <>
                                                                          <span
                                                                            style={{
                                                                              height:
                                                                                "10px",
                                                                              width:
                                                                                "10px",
                                                                              borderRadius:
                                                                                "50%",
                                                                              background:
                                                                                "#FBBC05 0% 0% no-repeat padding-box",
                                                                              position:
                                                                                "relative",
                                                                              top: "20px",
                                                                              marginRight:
                                                                                "10px",
                                                                            }}
                                                                          ></span>
                                                                        </>
                                                                      )}

                                                                      <div
                                                                        className="custom789 circleName mr-3"
                                                                        style={{
                                                                          height:
                                                                            "50px",
                                                                          width:
                                                                            "50px",
                                                                          fontSize:
                                                                            "18px",
                                                                          lineHeight:
                                                                            "",
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          showMessageDetails(
                                                                            e,
                                                                            value
                                                                          );
                                                                        }}
                                                                      >
                                                                        {value
                                                                          .receiverProfessionInfoData
                                                                          ?.entityTypeNameAbbreviation
                                                                          ? value
                                                                              .receiverProfessionInfoData
                                                                              ?.entityTypeNameAbbreviation
                                                                          : "N/A"}
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                          paddingTop:
                                                                            "15px",
                                                                        }}
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          showMessageDetails(
                                                                            e,
                                                                            value
                                                                          );
                                                                        }}
                                                                      >
                                                                        {professionalsFlag && (
                                                                          <>
                                                                            {value.isSenderLoggedInUser ===
                                                                              1 && (
                                                                              <>
                                                                                <h5>
                                                                                  {
                                                                                    value
                                                                                      .receiverUserInfoData
                                                                                      ?.userInformation
                                                                                      ?.firstName
                                                                                  }{" "}
                                                                                  {
                                                                                    value
                                                                                      .receiverUserInfoData
                                                                                      ?.userInformation
                                                                                      ?.lastName
                                                                                  }
                                                                                </h5>
                                                                              </>
                                                                            )}
                                                                            {value.isReceiverLoggedInUser ===
                                                                              1 && (
                                                                              <>
                                                                                <h5>
                                                                                  {
                                                                                    value
                                                                                      .sendUserInfoData
                                                                                      ?.userInformation
                                                                                      ?.firstName
                                                                                  }{" "}
                                                                                  {
                                                                                    value
                                                                                      .sendUserInfoData
                                                                                      ?.userInformation
                                                                                      ?.lastName
                                                                                  }
                                                                                </h5>
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )}
                                                                        {companiesFlag && (
                                                                          <>
                                                                            {value.isSenderLoggedInUser ===
                                                                              1 && (
                                                                              <>
                                                                                <h5>
                                                                                  {
                                                                                    value
                                                                                      .receiverUserInfoData
                                                                                      ?.userInformation
                                                                                      ?.entityName
                                                                                  }
                                                                                </h5>
                                                                              </>
                                                                            )}
                                                                            {value.isReceiverLoggedInUser ===
                                                                              1 && (
                                                                              <>
                                                                                <h5>
                                                                                  {
                                                                                    value
                                                                                      .sendUserInfoData
                                                                                      ?.userInformation
                                                                                      ?.entityName
                                                                                  }
                                                                                </h5>
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )}

                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#494949",
                                                                            marginBottom:
                                                                              "5px",
                                                                            overflow:
                                                                              "hidden",
                                                                            textOverflow:
                                                                              "ellipsis",
                                                                            display:
                                                                              "-webkit-box",
                                                                            lineClamp:
                                                                              "2",
                                                                            webkitBoxOrient:
                                                                              "vertical",
                                                                            webkitLineClamp:
                                                                              "1",
                                                                            wordBreak:
                                                                              "break-all",
                                                                            wordSpacing:
                                                                              "pre-line",
                                                                            position:
                                                                              "absolute",
                                                                            top: "40px",
                                                                          }}
                                                                        >
                                                                          {
                                                                            value.message
                                                                          }
                                                                        </p>
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#6E7D8C",
                                                                            fontSize:
                                                                              "10px",
                                                                            position:
                                                                              "absolute",
                                                                            top: "65px",
                                                                          }}
                                                                        >
                                                                          {getFormatedDate(
                                                                            value.updatedDate
                                                                          )}
                                                                        </p>
                                                                      </div>

                                                                      <i
                                                                        className="fa fa-ellipsis-v"
                                                                        aria-hidden="true"
                                                                        style={{
                                                                          color:
                                                                            "black",
                                                                          fontSize:
                                                                            "18px",
                                                                          cursor:
                                                                            "pointer",
                                                                          fontWeight:
                                                                            "500",
                                                                          position:
                                                                            "absolute",
                                                                          right:
                                                                            "0px",
                                                                          top: "15px",
                                                                        }}
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          openMsgPopup(
                                                                            e,
                                                                            value
                                                                          );
                                                                        }}
                                                                      ></i>

                                                                      {!inboxArchivedFlag && (
                                                                        <>
                                                                          <div
                                                                            className="blush_outer"
                                                                            id={
                                                                              value.messageIdHash +
                                                                              "popup"
                                                                            }
                                                                            style={{
                                                                              position:
                                                                                "absolute",
                                                                              height:
                                                                                "135px",
                                                                              width:
                                                                                "135px",
                                                                              top: "5px",
                                                                              right:
                                                                                "0px",
                                                                              border:
                                                                                "1px solid gainsboro",
                                                                              display:
                                                                                "none",
                                                                              zIndex: '999'
                                                                            }}
                                                                          >
                                                                            <div className="d-flex">
                                                                              <p
                                                                                className="p-2 mb-0"
                                                                                style={{
                                                                                  color:
                                                                                    "grey",
                                                                                }}
                                                                              >
                                                                                More
                                                                                Options
                                                                              </p>
                                                                              <i
                                                                                className="fa fa-times"
                                                                                aria-hidden="true"
                                                                                style={{
                                                                                  color:
                                                                                    "grey",
                                                                                  fontSize:
                                                                                    "14px",
                                                                                  paddingTop:
                                                                                    "10px",
                                                                                  marginLeft:
                                                                                    "15px",
                                                                                }}
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  closeMsgPopup(
                                                                                    e,
                                                                                    value
                                                                                  );
                                                                                }}
                                                                              ></i>
                                                                            </div>
                                                                            <p
                                                                              className="p-2 mt-0 mb-0"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                                borderBottom: '1px solid gainsboro'
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                reportUserBtnClicked(
                                                                                  e,
                                                                                  value
                                                                                );
                                                                              }}
                                                                            >
                                                                              {professionalsFlag ? 'Report User' : 'Report Company'}
                                                                            </p>
                                                                            <p
                                                                              className="p-2 mt-0 mb-0"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                                borderBottom: '1px solid gainsboro'
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                blockUserBtnClicked(
                                                                                  e,
                                                                                  value
                                                                                );
                                                                              }}
                                                                            >
                                                                              {professionalsFlag ? 'Block User' : 'Block Company'}
                                                                            </p>
                                                                            <p
                                                                              className="p-2 mt-0 mb-0"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                deleteMessageThreadFromInboxFn(
                                                                                  e,
                                                                                  value
                                                                                );
                                                                              }}
                                                                            >
                                                                              Archive
                                                                              Thread
                                                                            </p>
                                                                          </div>
                                                                        </>
                                                                      )}

                                                                      {inboxArchivedFlag && (
                                                                        <>
                                                                          <div
                                                                            className="blush_outer"
                                                                            id={
                                                                              value.messageIdHash +
                                                                              "popup"
                                                                            }
                                                                            style={{
                                                                              position:
                                                                                "absolute",
                                                                              height:
                                                                                "135px",
                                                                              width:
                                                                                "135px",
                                                                              top: "5px",
                                                                              right:
                                                                                "0px",
                                                                              border:
                                                                                "1px solid gainsboro",
                                                                              display:
                                                                                "none",
                                                                              zIndex:  "999"
                                                                            }}
                                                                          >
                                                                            <div className="d-flex">
                                                                              <p
                                                                                className="p-2 mb-0"
                                                                                style={{
                                                                                  color:
                                                                                    "grey",
                                                                                }}
                                                                              >
                                                                                More
                                                                                Options
                                                                              </p>
                                                                              <i
                                                                                className="fa fa-times"
                                                                                aria-hidden="true"
                                                                                style={{
                                                                                  color:
                                                                                    "grey",
                                                                                  fontSize:
                                                                                    "14px",
                                                                                  paddingTop:
                                                                                    "10px",
                                                                                  marginLeft:
                                                                                    "15px",
                                                                                }}
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  closeMsgPopup(
                                                                                    e,
                                                                                    value
                                                                                  );
                                                                                }}
                                                                              ></i>
                                                                            </div>
                                                                            <p
                                                                              className="p-2 mt-0 mb-0"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                                borderBottom: '1px solid gainsboro'
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                reportUserBtnClicked(
                                                                                  e,
                                                                                  value
                                                                                );
                                                                              }}
                                                                            >
                                                                              {professionalsFlag ? 'Report User' : 'Report Company'}
                                                                            </p>
                                                                            <p
                                                                              className="p-2 mt-0 mb-0"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                                borderBottom: '1px solid gainsboro'
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                blockUserBtnClicked(
                                                                                  e,
                                                                                  value
                                                                                );
                                                                              }}
                                                                            >
                                                                              {professionalsFlag ? 'Block User' : 'Block Company'}
                                                                            </p>
                                                                            <p
                                                                              className="p-2 mt-0 mb-0"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                restoreMessageThreadFromArchivedFn(
                                                                                  e,
                                                                                  value
                                                                                );
                                                                              }}
                                                                            >
                                                                              Restore Thread
                                                                            </p>
                                                                          </div>
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                    <hr className="mt-0"></hr>
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className="col-md-8"
                                                style={{
                                                  background: "white",
                                                  padding: "25px",
                                                  borderRadius: "6px",
                                                  paddingTop: "15px",
                                                }}
                                              >
                                                {messageDetailsFlag ? (
                                                  <>
                                                    <div
                                                      className="row"
                                                      style={{
                                                        width: "100%",
                                                        height: "50vh",
                                                      }}
                                                    >
                                                      <div
                                                        className="col-12"
                                                        style={{
                                                          width: "100%",
                                                          height: "100%",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            textAlign: "center",
                                                            verticalAlign:
                                                              "middle",
                                                            lineHeight: "50vh",
                                                          }}
                                                        >
                                                          <div
                                                            className="spinner-grow"
                                                            role="status"
                                                            style={{
                                                              background:
                                                                "#006838",
                                                            }}
                                                          ></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    {messageDetailsData.user
                                                      .receiverProfessionInfoData ? (
                                                      <>
                                                        <div
                                                          className="d-flex"
                                                          style={{
                                                            position:
                                                              "relative",
                                                          }}
                                                        >
                                                          <div
                                                            className="custom789 circleName mr-3"
                                                            style={{
                                                              height: "50px",
                                                              width: "50px",
                                                              fontSize: "18px",
                                                              lineHeight: "",
                                                            }}
                                                          >
                                                            {messageDetailsData
                                                              .user
                                                              .receiverProfessionInfoData
                                                              ?.entityTypeNameAbbreviation
                                                              ? messageDetailsData
                                                                  .user
                                                                  .receiverProfessionInfoData
                                                                  ?.entityTypeNameAbbreviation
                                                              : "N/A"}
                                                          </div>
                                                          <div
                                                            style={{
                                                              paddingTop:
                                                                "15px",
                                                            }}
                                                          >
                                                            {professionalsFlag && (
                                                              <>
                                                                {messageDetailsData
                                                                  .user
                                                                  .isSenderLoggedInUser ===
                                                                  1 && (
                                                                  <>
                                                                    <h5>
                                                                      {
                                                                        messageDetailsData
                                                                          .user
                                                                          .receiverUserInfoData
                                                                          ?.userInformation
                                                                          ?.firstName
                                                                      }{" "}
                                                                      {
                                                                        messageDetailsData
                                                                          .user
                                                                          .receiverUserInfoData
                                                                          ?.userInformation
                                                                          ?.lastName
                                                                      }
                                                                    </h5>
                                                                  </>
                                                                )}
                                                                {messageDetailsData
                                                                  .user
                                                                  .isReceiverLoggedInUser ===
                                                                  1 && (
                                                                  <>
                                                                    <h5>
                                                                      {
                                                                        messageDetailsData
                                                                          .user
                                                                          .sendUserInfoData
                                                                          ?.userInformation
                                                                          ?.firstName
                                                                      }{" "}
                                                                      {
                                                                        messageDetailsData
                                                                          .user
                                                                          .sendUserInfoData
                                                                          ?.userInformation
                                                                          ?.lastName
                                                                      }
                                                                    </h5>
                                                                  </>
                                                                )}
                                                              </>
                                                            )}

                                                            {companiesFlag && (
                                                              <>
                                                                {messageDetailsData
                                                                  .user
                                                                  .isSenderLoggedInUser ===
                                                                  1 && (
                                                                  <>
                                                                    <h5>
                                                                      {
                                                                        messageDetailsData
                                                                          .user
                                                                          .receiverUserInfoData
                                                                          ?.userInformation
                                                                          ?.entityName
                                                                      }
                                                                    </h5>
                                                                  </>
                                                                )}
                                                                {messageDetailsData
                                                                  .user
                                                                  .isReceiverLoggedInUser ===
                                                                  1 && (
                                                                  <>
                                                                    <h5>
                                                                      {
                                                                        messageDetailsData
                                                                          .user
                                                                          .sendUserInfoData
                                                                          ?.userInformation
                                                                          ?.entityName
                                                                      }
                                                                    </h5>
                                                                  </>
                                                                )}
                                                              </>
                                                            )}

                                                            <p
                                                              style={{
                                                                color:
                                                                  "#868686",
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Subject :{" "}
                                                              {
                                                                messageDetailsData
                                                                  .user.subject
                                                              }
                                                            </p>
                                                          </div>

                                                          <i
                                                            className="fa fa-ellipsis-v"
                                                            aria-hidden="true"
                                                            style={{
                                                              color: "black",
                                                              fontSize: "18px",
                                                              cursor: "pointer",
                                                              fontWeight: "500",
                                                              position:
                                                                "absolute",
                                                              right: "0px",
                                                              top: "15px",
                                                            }}
                                                            onClick={(e) => {
                                                              openMsgDetailsPopup(
                                                                e,
                                                                messageDetailsData.user
                                                              );
                                                            }}
                                                          ></i>

                                                          {!inboxArchivedFlag && (
                                                            <>
                                                              <div
                                                                className="blush_outer"
                                                                id={
                                                                  messageDetailsData
                                                                    .user
                                                                    .messageIdHash +
                                                                  "popupMsgDetails"
                                                                }
                                                                style={{
                                                                  position:
                                                                    "absolute",
                                                                  height:
                                                                    "135px",
                                                                  width:
                                                                    "135px",
                                                                  top: "-2px",
                                                                  right: "0px",
                                                                  border:
                                                                    "1px solid gainsboro",
                                                                  display:
                                                                    "none",
                                                                }}
                                                              >
                                                                <div className="d-flex">
                                                                  <p
                                                                    className="p-2 mb-0"
                                                                    style={{
                                                                      color:
                                                                        "grey",
                                                                    }}
                                                                  >
                                                                    More Options
                                                                  </p>
                                                                  <i
                                                                    className="fa fa-times"
                                                                    aria-hidden="true"
                                                                    style={{
                                                                      color:
                                                                        "grey",
                                                                      fontSize:
                                                                        "14px",
                                                                      paddingTop:
                                                                        "10px",
                                                                      marginLeft:
                                                                        "15px",
                                                                    }}
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      closeMsgDetailsPopup(
                                                                        e,
                                                                        messageDetailsData.user
                                                                      );
                                                                    }}
                                                                  ></i>
                                                                </div>
                                                                <p
                                                                              className="p-2 mt-0 mb-0"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                                borderBottom: '1px solid gainsboro'
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                reportUserBtnClicked(
                                                                                  e,
                                                                                  messageDetailsData.user
                                                                                );
                                                                              }}
                                                                            >
                                                                              {professionalsFlag ? 'Report User' : 'Report Company'}
                                                                            </p>
                                                                            <p
                                                                              className="p-2 mt-0 mb-0"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                                borderBottom: '1px solid gainsboro'
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                blockUserBtnClicked(
                                                                                  e,
                                                                                  messageDetailsData.user
                                                                                );
                                                                              }}
                                                                            >
                                                                              {professionalsFlag ? 'Block User' : 'Block Company'}
                                                                            </p>
                                                                <p
                                                                  className="p-2 mt-0 mb-0"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    deleteMessageThreadFromInboxFn(
                                                                      e,
                                                                      messageDetailsData.user
                                                                    );
                                                                  }}
                                                                >
                                                                  Archive Thread
                                                                </p>
                                                              </div>
                                                            </>
                                                          )}

                                                          {inboxArchivedFlag && (
                                                            <>
                                                              <div
                                                                className="blush_outer"
                                                                id={
                                                                  messageDetailsData
                                                                    .user
                                                                    .messageIdHash +
                                                                  "popupMsgDetails"
                                                                }
                                                                style={{
                                                                  position:
                                                                    "absolute",
                                                                  height:
                                                                    "135px",
                                                                  width:
                                                                    "135px",
                                                                  top: "-2px",
                                                                  right: "0px",
                                                                  border:
                                                                    "1px solid gainsboro",
                                                                  display:
                                                                    "none",
                                                                  zIndex:'999'
                                                                }}
                                                              >
                                                                <div className="d-flex">
                                                                  <p
                                                                    className="p-2 mb-0"
                                                                    style={{
                                                                      color:
                                                                        "grey",
                                                                    }}
                                                                  >
                                                                    More Options
                                                                  </p>
                                                                  <i
                                                                    className="fa fa-times"
                                                                    aria-hidden="true"
                                                                    style={{
                                                                      color:
                                                                        "grey",
                                                                      fontSize:
                                                                        "14px",
                                                                      paddingTop:
                                                                        "10px",
                                                                      marginLeft:
                                                                        "15px",
                                                                    }}
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      closeMsgDetailsPopup(
                                                                        e,
                                                                        messageDetailsData.user
                                                                      );
                                                                    }}
                                                                  ></i>
                                                                </div>
                                                                <p
                                                                              className="p-2 mt-0 mb-0"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                                borderBottom: '1px solid gainsboro'
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                reportUserBtnClicked(
                                                                                  e,
                                                                                  messageDetailsData.user
                                                                                );
                                                                              }}
                                                                            >
                                                                              {professionalsFlag ? 'Report User' : 'Report Company'}
                                                                            </p>
                                                                            <p
                                                                              className="p-2 mt-0 mb-0"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                                borderBottom: '1px solid gainsboro'
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                blockUserBtnClicked(
                                                                                  e,
                                                                                  messageDetailsData.user
                                                                                );
                                                                              }}
                                                                            >
                                                                              {professionalsFlag ? 'Block User' : 'Block Company'}
                                                                            </p>
                                                                <p
                                                                  className="p-2 mt-0 mb-0"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    restoreMessageThreadFromArchivedFn(
                                                                      e,
                                                                      messageDetailsData.user
                                                                    );
                                                                  }}
                                                                >
                                                                  Restore Thread
                                                                </p>
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                        <hr></hr>

                                                        <div
                                                          className=""
                                                          style={
                                                            chatHistoryLoading
                                                              ? chatHistoryStyleLoading()
                                                              : chatHistoryStyle()
                                                          }
                                                        >
                                                          {chatHistoryLoading ? (
                                                            <>
                                                              <div
                                                                className="row"
                                                                style={{
                                                                  width: "100%",
                                                                  height:
                                                                    "50vh",
                                                                }}
                                                              >
                                                                <div
                                                                  className="col-12"
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    height:
                                                                      "100%",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      height:
                                                                        "100%",
                                                                      textAlign:
                                                                        "center",
                                                                      verticalAlign:
                                                                        "middle",
                                                                      lineHeight:
                                                                        "30vh",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className="spinner-grow"
                                                                      role="status"
                                                                      style={{
                                                                        background:
                                                                          "#006838",
                                                                      }}
                                                                    ></div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              {chatHistoryData.map(
                                                                (
                                                                  value,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <>
                                                                      <div
                                                                        className="d-flex pt-5 pl-5 pr-5 pb-0"
                                                                        style={{
                                                                          position:
                                                                            "relative",
                                                                        }}
                                                                      >
                                                                        {value.isSenderLoggedInUser ===
                                                                          1 && (
                                                                          <>
                                                                            {value.isSenderDeleted ===
                                                                              1 && (
                                                                              <>
                                                                                <span
                                                                                  style={{
                                                                                    height:
                                                                                      "10px",
                                                                                    width:
                                                                                      "10px",
                                                                                    borderRadius:
                                                                                      "50%",
                                                                                    background:
                                                                                      "orange 0% 0% no-repeat padding-box",
                                                                                    position:
                                                                                      "absolute",
                                                                                    top: "25px",
                                                                                    marginRight:
                                                                                      "10px",
                                                                                    left: "20px",
                                                                                    // zIndex:
                                                                                    //   "99999",
                                                                                  }}
                                                                                ></span>
                                                                              </>
                                                                            )}
                                                                            {value.isSenderDeleted ===
                                                                              0 && (
                                                                              <>
                                                                                {value.isReceiverRead ===
                                                                                  0 && (
                                                                                  <>
                                                                                    <span
                                                                                      style={{
                                                                                        height:
                                                                                          "10px",
                                                                                        width:
                                                                                          "10px",
                                                                                        borderRadius:
                                                                                          "50%",
                                                                                        background:
                                                                                          "#319CFF 0% 0% no-repeat padding-box",
                                                                                        position:
                                                                                          "absolute",
                                                                                        top: "25px",
                                                                                        marginRight:
                                                                                          "10px",
                                                                                        left: "20px",
                                                                                        // zIndex:
                                                                                        //   "99999",
                                                                                      }}
                                                                                    ></span>
                                                                                  </>
                                                                                )}
                                                                                {value.isReceiverRead ===
                                                                                  1 && (
                                                                                  <>
                                                                                    <span
                                                                                      style={{
                                                                                        height:
                                                                                          "10px",
                                                                                        width:
                                                                                          "10px",
                                                                                        borderRadius:
                                                                                          "50%",
                                                                                        background:
                                                                                          "#CCCCCC 0% 0% no-repeat padding-box",
                                                                                        position:
                                                                                          "absolute",
                                                                                        top: "25px",
                                                                                        marginRight:
                                                                                          "10px",
                                                                                        left: "20px",
                                                                                        // zIndex:
                                                                                        //   "99999",
                                                                                      }}
                                                                                    ></span>
                                                                                  </>
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )}
                                                                        {value.isReceiverLoggedInUser ===
                                                                          1 && (
                                                                          <>
                                                                            {value.isReceiverDeleted ===
                                                                              1 && (
                                                                              <>
                                                                                <span
                                                                                  style={{
                                                                                    height:
                                                                                      "10px",
                                                                                    width:
                                                                                      "10px",
                                                                                    borderRadius:
                                                                                      "50%",
                                                                                    background:
                                                                                      "orange 0% 0% no-repeat padding-box",
                                                                                    position:
                                                                                      "absolute",
                                                                                    top: "25px",
                                                                                    marginRight:
                                                                                      "10px",
                                                                                    left: "20px",
                                                                                    // zIndex:
                                                                                    //   "99999",
                                                                                  }}
                                                                                ></span>
                                                                              </>
                                                                            )}
                                                                            {value.isReceiverDeleted ===
                                                                              0 && (
                                                                              <>
                                                                                {value.isSenderRead ===
                                                                                  0 && (
                                                                                  <>
                                                                                    <span
                                                                                      style={{
                                                                                        height:
                                                                                          "10px",
                                                                                        width:
                                                                                          "10px",
                                                                                        borderRadius:
                                                                                          "50%",
                                                                                        background:
                                                                                          "#319CFF 0% 0% no-repeat padding-box",
                                                                                        position:
                                                                                          "absolute",
                                                                                        top: "25px",
                                                                                        marginRight:
                                                                                          "10px",
                                                                                        left: "20px",
                                                                                        // zIndex:
                                                                                        //   "99999",
                                                                                      }}
                                                                                    ></span>
                                                                                  </>
                                                                                )}
                                                                                {value.isSenderRead ===
                                                                                  1 && (
                                                                                  <>
                                                                                    <span
                                                                                      style={{
                                                                                        height:
                                                                                          "10px",
                                                                                        width:
                                                                                          "10px",
                                                                                        borderRadius:
                                                                                          "50%",
                                                                                        background:
                                                                                          "#CCCCCC 0% 0% no-repeat padding-box",
                                                                                        position:
                                                                                          "absolute",
                                                                                        top: "25px",
                                                                                        marginRight:
                                                                                          "10px",
                                                                                        left: "20px",
                                                                                        // zIndex:
                                                                                        //   "99999",
                                                                                      }}
                                                                                    ></span>
                                                                                  </>
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )}
                                                                        <div
                                                                          className="custom789 circleName mr-3"
                                                                          style={{
                                                                            height:
                                                                              "45px",
                                                                            width:
                                                                              "45px",
                                                                            fontSize:
                                                                              "15px",
                                                                            lineHeight:
                                                                              "",
                                                                            position:
                                                                              "relative",
                                                                            top: "10px",
                                                                            left: "10px",
                                                                            // zIndex:
                                                                            //   "99999",
                                                                          }}
                                                                        >
                                                                          {value.isSenderLoggedInUser ===
                                                                            1 && (
                                                                            <>
                                                                              {value
                                                                                .senderProfessionInfoData
                                                                                ?.entityTypeNameAbbreviation
                                                                                ? value
                                                                                    .senderProfessionInfoData
                                                                                    ?.entityTypeNameAbbreviation
                                                                                : "N/A"}
                                                                            </>
                                                                          )}

                                                                          {value.isReceiverLoggedInUser ===
                                                                            1 && (
                                                                            <>
                                                                              {value
                                                                                .receiverProfessionInfoData
                                                                                ?.entityTypeNameAbbreviation
                                                                                ? value
                                                                                    .receiverProfessionInfoData
                                                                                    ?.entityTypeNameAbbreviation
                                                                                : "N/A"}
                                                                            </>
                                                                          )}
                                                                        </div>
                                                                    {professionalsFlag && (
                                                                      <>
                                                                        <div
                                                                          style={{
                                                                            position:
                                                                              "absolute",
                                                                            top: "41px",
                                                                            left: "85px",
                                                                          }}
                                                                        >
                                                                          {value.isSenderLoggedInUser ===
                                                                            1 && (
                                                                            <>
                                                                              {
                                                                                value
                                                                                  .sendUserInfoData
                                                                                  .userInformation
                                                                                  .firstName
                                                                              }{" "}
                                                                              {
                                                                                value
                                                                                  .sendUserInfoData
                                                                                  .userInformation
                                                                                  .lastName
                                                                              }
                                                                            </>
                                                                          )}
                                                                          {value.isReceiverLoggedInUser ===
                                                                            1 && (
                                                                            <>
                                                                              {
                                                                                value
                                                                                  .sendUserInfoData
                                                                                  .userInformation
                                                                                  .firstName
                                                                              }{" "}
                                                                              {
                                                                                value
                                                                                  .sendUserInfoData
                                                                                  .userInformation
                                                                                  .lastName
                                                                              }
                                                                            </>
                                                                          )}
                                                                        </div>
                                                                      </>
                                                                    )}

                                                                    {companiesFlag && (
                                                                      <>
                                                                        <div
                                                                          style={{
                                                                            position:
                                                                              "absolute",
                                                                            top: "41px",
                                                                            left: "85px",
                                                                          }}
                                                                        >
                                                                          {value.isSenderLoggedInUser ===
                                                                            1 && (
                                                                            <>
                                                                              {
                                                                                value
                                                                                  .sendUserInfoData
                                                                                  .userInformation
                                                                                  .firstName
                                                                              }{" "}
                                                                              {
                                                                                value
                                                                                  .sendUserInfoData
                                                                                  .userInformation
                                                                                  .lastName
                                                                              }
                                                                            </>
                                                                          )}
                                                                          {value.isReceiverLoggedInUser ===
                                                                            1 && (
                                                                            <>
                                                                              {value
                                                                                .sendUserInfoData
                                                                                .userInformation
                                                                                .entityName
                                                                                ? value
                                                                                    .sendUserInfoData
                                                                                    .userInformation
                                                                                    .entityName
                                                                                : "--"}
                                                                            </>
                                                                          )}
                                                                        </div>
                                                                      </>
                                                                    )} 

                                                                        {value.isSenderLoggedInUser ===
                                                                          1 && (
                                                                          <>
                                                                            <div
                                                                              className=""
                                                                              style={{
                                                                                boxShadow:
                                                                                  "0px 0px 15px #0000000D",
                                                                                borderRadius:
                                                                                  "10px",
                                                                                padding:
                                                                                  "15px",
                                                                                minWidth:
                                                                                  "80%",
                                                                                maxWidth:
                                                                                  "80%",
                                                                                minHeight:
                                                                                  "90px",
                                                                                border:
                                                                                  "1px solid rgb(113, 234, 60, .3)",
                                                                                position:
                                                                                  "relative",
                                                                                left: "-62px",
                                                                              }}
                                                                            >
                                                                              <p
                                                                                style={{
                                                                                  color:
                                                                                    "#71EA3C",
                                                                                  marginBottom:
                                                                                    "5px",
                                                                                  wordBreak:
                                                                                    "break-word",
                                                                                  opacity:
                                                                                    "1",
                                                                                  paddingTop:
                                                                                    "50px",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  value.message
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}

                                                                        {value.isReceiverLoggedInUser ===
                                                                          1 && (
                                                                          <>
                                                                            <div
                                                                              className=""
                                                                              style={{
                                                                                boxShadow:
                                                                                  "0px 0px 15px #0000000D",
                                                                                borderRadius:
                                                                                  "10px",
                                                                                padding:
                                                                                  "15px",
                                                                                minWidth:
                                                                                  "80%",
                                                                                maxWidth:
                                                                                  "80%",
                                                                                minHeight:
                                                                                  "90px",
                                                                                position:
                                                                                  "relative",
                                                                                left: "-62px",
                                                                                // background:
                                                                                //   "#f4f4f4",
                                                                              }}
                                                                            >
                                                                              <p
                                                                                style={{
                                                                                  color:
                                                                                    "#868686",
                                                                                  marginBottom:
                                                                                    "5px",
                                                                                  wordBreak:
                                                                                    "break-word",
                                                                                  paddingTop:
                                                                                    "50px",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  value.message
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}

                                                                        <i
                                                                          className="fa fa-ellipsis-v"
                                                                          aria-hidden="true"
                                                                          style={{
                                                                            color:
                                                                              "black",
                                                                            fontSize:
                                                                              "18px",
                                                                            cursor:
                                                                              "pointer",
                                                                            fontWeight:
                                                                              "500",
                                                                            position:
                                                                              "absolute",
                                                                            left: "75%",
                                                                            top: "25px",
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            openMsgDetailsPopupSingle(
                                                                              e,
                                                                              value
                                                                            );
                                                                          }}
                                                                        ></i>

                                                                        {!inboxArchivedFlag && (
                                                                          <>
                                                                            <div
                                                                              className="blush_outer"
                                                                              id={
                                                                                value.messageIdHash +
                                                                                "popupMsgDetailsSingle"
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                height:
                                                                                  "70px",
                                                                                width:
                                                                                  "135px",
                                                                                top: "26px",
                                                                                right:
                                                                                  "21%",
                                                                                border:
                                                                                  "1px solid gainsboro",
                                                                                display:
                                                                                  "none",
                                                                                zIndex:
                                                                                  "99999",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                <p
                                                                                  className="p-2 mb-0"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                  }}
                                                                                >
                                                                                  More
                                                                                  Options
                                                                                </p>
                                                                                <i
                                                                                  className="fa fa-times"
                                                                                  aria-hidden="true"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                    paddingTop:
                                                                                      "10px",
                                                                                    marginLeft:
                                                                                      "15px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    closeMsgDetailsPopupSingle(
                                                                                      e,
                                                                                      value
                                                                                    );
                                                                                  }}
                                                                                ></i>
                                                                              </div>
                                                                              {value.isSenderDeleted ===
                                                                                1 && (
                                                                                <>
                                                                                  <p
                                                                                    className="p-2 mt-0 mb-0"
                                                                                    style={{
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                    onClick={(
                                                                                      e
                                                                                    ) => {
                                                                                      restoreMessageToInboxFromChatFn(
                                                                                        e,
                                                                                        value
                                                                                      );
                                                                                    }}
                                                                                  >
                                                                                    Restore
                                                                                  </p>
                                                                                </>
                                                                              )}
                                                                              {value.isSenderDeleted ===
                                                                                0 && (
                                                                                <>
                                                                                  <p
                                                                                    className="p-2 mt-0 mb-0"
                                                                                    style={{
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                    onClick={(
                                                                                      e
                                                                                    ) => {
                                                                                      deleteMessageFromInboxFn(
                                                                                        e,
                                                                                        value
                                                                                      );
                                                                                    }}
                                                                                  >
                                                                                    Archive
                                                                                  </p>
                                                                                </>
                                                                              )}
                                                                            </div>
                                                                          </>
                                                                        )}

                                                                        {inboxArchivedFlag && (
                                                                          <>
                                                                            <div
                                                                              className="blush_outer"
                                                                              id={
                                                                                value.messageIdHash +
                                                                                "popupMsgDetailsSingle"
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                height:
                                                                                  "70px",
                                                                                width:
                                                                                  "135px",
                                                                                top: "26px",
                                                                                right:
                                                                                  "21%",
                                                                                border:
                                                                                  "1px solid gainsboro",
                                                                                display:
                                                                                  "none",
                                                                                zIndex:
                                                                                  "99999",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                <p
                                                                                  className="p-2 mb-0"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                  }}
                                                                                >
                                                                                  More
                                                                                  Options
                                                                                </p>
                                                                                <i
                                                                                  className="fa fa-times"
                                                                                  aria-hidden="true"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                    paddingTop:
                                                                                      "10px",
                                                                                    marginLeft:
                                                                                      "15px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    closeMsgDetailsPopupSingle(
                                                                                      e,
                                                                                      value
                                                                                    );
                                                                                  }}
                                                                                ></i>
                                                                              </div>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  restoreMessageToInboxFromArchivedFn(
                                                                                    e,
                                                                                    value
                                                                                  );
                                                                                }}
                                                                              >
                                                                                Restore
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}
                                                                      </div>

                                                                      <div
                                                                        className="pt-3 d-flex"
                                                                        style={{
                                                                          minWidth:
                                                                            "80%",
                                                                          maxWidth:
                                                                            "80%",
                                                                          justifyContent:
                                                                            "end",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            color:
                                                                              "#6E7D8C",
                                                                            fontSize:
                                                                              "10px",
                                                                            position:
                                                                              "relative",
                                                                            top: "",
                                                                            left: "-4%",
                                                                          }}
                                                                        >
                                                                          {value.isSenderLoggedInUser ===
                                                                            1 && (
                                                                            <>
                                                                              <img
                                                                                src={
                                                                                  senderIcon
                                                                                }
                                                                                style={{
                                                                                  position:
                                                                                    "",
                                                                                  top: "",
                                                                                  left: "",
                                                                                }}
                                                                              />
                                                                            </>
                                                                          )}
                                                                          {value.isReceiverLoggedInUser ===
                                                                            1 && (
                                                                            <>
                                                                              <img
                                                                                src={
                                                                                  receiverIcon
                                                                                }
                                                                                style={{
                                                                                  position:
                                                                                    "",
                                                                                  top: "",
                                                                                  left: "",
                                                                                }}
                                                                              />
                                                                            </>
                                                                          )}
                                                                          {getFormatedDate(
                                                                            value.createdDate
                                                                          )}
                                                                        </p>
                                                                      </div>
                                                                      <div
                                                                        ref={
                                                                          divRef
                                                                        }
                                                                      />
                                                                    </>
                                                                  );
                                                                }
                                                              )}
                                                            </>
                                                          )}
                                                        </div>

                                                        {!inboxArchivedFlag && (
                                                          <>
                                                            <div className="">
                                                              <textarea
                                                                onChange={(e) =>
                                                                  msgReplyFn(
                                                                    e,
                                                                    "reply"
                                                                  )
                                                                }
                                                                type="text"
                                                                name="Search"
                                                                className="form-control col-12"
                                                                placeholder="Reply..."
                                                                rows="4"
                                                                value={msgReply}
                                                                style={{
                                                                  background:
                                                                    "#EFEFEF",
                                                                  position:
                                                                    "absolute",
                                                                  bottom: "0",
                                                                  width: "100%",
                                                                  left: "0",
                                                                  paddingRight:
                                                                    "50px",
                                                                }}
                                                              ></textarea>
                                                              <img
                                                                src={paperPlane}
                                                                style={{
                                                                  position:
                                                                    "absolute",
                                                                  right: "15px",
                                                                  bottom:
                                                                    "37px",
                                                                  width: "30px",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  replyToMsgFn(
                                                                    e
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div className="row h-100 w-100">
                                                          <div className="col-12 text-center">
                                                            <img
                                                              className="mb-3"
                                                              src={
                                                                zeroFollowingImage
                                                              }
                                                              alt="No Data Found"
                                                            />
                                                            <p>
                                                              No details to
                                                              show.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}

                                      {statusUpdateFlag && (
                                        <>
                                          <div
                                            className="Profile_form custom_spece5"
                                            style={{
                                              height: "",
                                              background: "",
                                              paddingLeft: "32px",
                                              paddingTop: "32px",
                                              marginTop: "-16px",
                                              paddingRight: "32px",
                                            }}
                                          >
                                            {listLoading ? (
                                              <div
                                                className="row"
                                                style={{
                                                  width: "100%",
                                                  height: "",
                                                }}
                                              >
                                                <div
                                                  className="col-12"
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                      textAlign: "center",
                                                      verticalAlign: "middle",
                                                      lineHeight: "50vh",
                                                    }}
                                                  >
                                                    <div
                                                      className="spinner-grow"
                                                      role="status"
                                                      style={{
                                                        background: "#006838",
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                {statusUpdateData.length ==
                                                0 ? (
                                                  <>
                                                    <div className="row h-100 w-100">
                                                      <div className="col-12 text-center">
                                                        <img
                                                          className="mb-3"
                                                          src={
                                                            zeroFollowingImage
                                                          }
                                                          alt="No Data Found"
                                                        />
                                                        <p>
                                                          No Status Updates to
                                                          show.
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="row">
                                                      {statusUpdateData.map(
                                                        (value, index) => {
                                                          return (
                                                            <>
                                                              <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                <div className="blush_outer">
                                                                  <div className="geoge_outer">
                                                                    <div className="custom789 circleName">
                                                                      {value
                                                                        .professionInfoData
                                                                        ?.entityTypeNameAbbreviation
                                                                        ? value
                                                                            .professionInfoData
                                                                            ?.entityTypeNameAbbreviation
                                                                        : "N/A"}
                                                                    </div>
                                                                    <img
                                                                      src={
                                                                        value
                                                                          .userInfoData
                                                                          .userInfo
                                                                          .isVerified
                                                                          ? verified
                                                                          : unVerified
                                                                      }
                                                                      alt="verify"
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        top: "55px",
                                                                        left: "55px",
                                                                      }}
                                                                    />
                                                                    <div className="google_inner">
                                                                      <div
                                                                        className="d-flex justify-content-between align-items-center"
                                                                        style={{
                                                                          position:
                                                                            "relative",
                                                                        }}
                                                                      >
                                                                        {professionalsFlag && (
                                                                          <>
                                                                            <h5>
                                                                              {value
                                                                                .userInfoData
                                                                                ?.userInformation
                                                                                ?.firstName
                                                                                ? value
                                                                                    .userInfoData
                                                                                    ?.userInformation
                                                                                    .firstName
                                                                                : "--"}{" "}
                                                                              {value
                                                                                .userInfoData
                                                                                ?.userInformation
                                                                                ?.lastName
                                                                                ? value
                                                                                    .userInfoData
                                                                                    ?.userInformation
                                                                                    .lastName
                                                                                : "--"}
                                                                            </h5>
                                                                          </>
                                                                        )}
                                                                        {companiesFlag && (
                                                                          <>
                                                                            <h5>
                                                                              {value
                                                                                .userInfoData
                                                                                ?.userInformation
                                                                                ?.entityName
                                                                                ? value
                                                                                    .userInfoData
                                                                                    ?.userInformation
                                                                                    .entityName
                                                                                : "--"}
                                                                            </h5>
                                                                          </>
                                                                        )}
                                                                        {meFlag && (
                                                                          <>
                                                                            <h5>
                                                                              {value
                                                                                .userInfoData
                                                                                ?.userInformation
                                                                                ?.firstName
                                                                                ? value
                                                                                    .userInfoData
                                                                                    ?.userInformation
                                                                                    .firstName
                                                                                : "--"}{" "}
                                                                              {value
                                                                                .userInfoData
                                                                                ?.userInformation
                                                                                ?.lastName
                                                                                ? value
                                                                                    .userInfoData
                                                                                    ?.userInformation
                                                                                    .lastName
                                                                                : "--"}
                                                                            </h5>
                                                                          </>
                                                                        )}
                                                                        {/* {!meFlag && (
                                                                                            <>
                                                                                            <p className="mt-2" style={{ color: '#3ABD00', cursor: 'pointer', position: 'absolute', top: '-5px', right: '20px' }} onClick={() => { followProfileFromSpaces(value) }}>+ Follow</p>
                                                                                            </>)} */}

                                                                        {!meFlag && (
                                                                          <>
                                                                            <i
                                                                              className="fa fa-ellipsis-v"
                                                                              aria-hidden="true"
                                                                              style={{
                                                                                color:
                                                                                  "black",
                                                                                fontSize:
                                                                                  "18px",
                                                                                cursor:
                                                                                  "pointer",
                                                                                fontWeight:
                                                                                  "500",
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                openPopup(
                                                                                  e,
                                                                                  value
                                                                                );
                                                                              }}
                                                                            ></i>
                                                                          </>
                                                                        )}

                                                                        {/* <img className='badgeClass mr-6' src={showBadge(value.userProfileDataTypes.selectedUserDataRating)} alt="badge" /> */}
                                                                        {meFlag && (
                                                                          <>
                                                                            <div
                                                                              className="blush_outer"
                                                                              id={
                                                                                value.spaceIdHash +
                                                                                "popup"
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                height:
                                                                                  "100px",
                                                                                width:
                                                                                  "135px",
                                                                                top: "25px",
                                                                                right:
                                                                                  "5px",
                                                                                border:
                                                                                  "1px solid gainsboro",
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                <p
                                                                                  className="p-2 mb-0"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                  }}
                                                                                >
                                                                                  More
                                                                                  Options
                                                                                </p>
                                                                                <i
                                                                                  className="fa fa-times"
                                                                                  aria-hidden="true"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                    paddingTop:
                                                                                      "10px",
                                                                                    marginLeft:
                                                                                      "15px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    closePopup(
                                                                                      e,
                                                                                      value
                                                                                    );
                                                                                  }}
                                                                                ></i>
                                                                              </div>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  editPostBtnClicked(
                                                                                    e,
                                                                                    value
                                                                                  );
                                                                                }}
                                                                              >
                                                                                Edit
                                                                                Post
                                                                              </p>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  deleteSpacePostData(
                                                                                    e,
                                                                                    value
                                                                                  );
                                                                                }}
                                                                              >
                                                                                Delete
                                                                                Post
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}

                                                                        {professionalsFlag && (
                                                                          <>
                                                                            <div
                                                                              className="blush_outer"
                                                                              id={
                                                                                value.spaceIdHash +
                                                                                "popup"
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                height:
                                                                                  "105px",
                                                                                width:
                                                                                  "135px",
                                                                                top: "25px",
                                                                                right:
                                                                                  "5px",
                                                                                border:
                                                                                  "1px solid gainsboro",
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                <p
                                                                                  className="p-2 mb-0"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                  }}
                                                                                >
                                                                                  More
                                                                                  Options
                                                                                </p>
                                                                                <i
                                                                                  className="fa fa-times"
                                                                                  aria-hidden="true"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                    paddingTop:
                                                                                      "10px",
                                                                                    marginLeft:
                                                                                      "15px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    closePopup(
                                                                                      e,
                                                                                      value
                                                                                    );
                                                                                  }}
                                                                                ></i>
                                                                              </div>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={e => {reportUserBtnClicked(e, value)}}
                                                                              >
                                                                                Report
                                                                                User
                                                                              </p>
                                                                              {/* <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              >
                                                                                Report
                                                                                Post
                                                                              </p> */}
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={e => {blockUserBtnClicked(e, value)}}
                                                                              >
                                                                                Block
                                                                                User
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}

                                                                        {companiesFlag && (
                                                                          <>
                                                                            <div
                                                                              className="blush_outer"
                                                                              id={
                                                                                value.spaceIdHash +
                                                                                "popup"
                                                                              }
                                                                              style={{
                                                                                position:
                                                                                  "absolute",
                                                                                height:
                                                                                  "105px",
                                                                                width:
                                                                                  "135px",
                                                                                top: "25px",
                                                                                right:
                                                                                  "5px",
                                                                                border:
                                                                                  "1px solid gainsboro",
                                                                                display:
                                                                                  "none",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                <p
                                                                                  className="p-2 mb-0"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                  }}
                                                                                >
                                                                                  More
                                                                                  Options
                                                                                </p>
                                                                                <i
                                                                                  className="fa fa-times"
                                                                                  aria-hidden="true"
                                                                                  style={{
                                                                                    color:
                                                                                      "grey",
                                                                                    fontSize:
                                                                                      "14px",
                                                                                    paddingTop:
                                                                                      "10px",
                                                                                    marginLeft:
                                                                                      "15px",
                                                                                  }}
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    closePopup(
                                                                                      e,
                                                                                      value
                                                                                    );
                                                                                  }}
                                                                                ></i>
                                                                              </div>
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={e => {reportUserBtnClicked(e, value)}}
                                                                              >
                                                                                Report
                                                                                Company
                                                                              </p>
                                                                              {/* <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  borderBottom:
                                                                                    "1px solid gainsboro",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                              >
                                                                                Report
                                                                                Post
                                                                              </p> */}
                                                                              <p
                                                                                className="p-2 mt-0 mb-0"
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                }}
                                                                                onClick={e => {blockUserBtnClicked(e, value)}}
                                                                              >
                                                                                Block
                                                                                Company
                                                                              </p>
                                                                            </div>
                                                                          </>
                                                                        )}
                                                                      </div>
                                                                      {/* <h5>{value.userProfileDataTypes.Profession}</h5> */}
                                                                      <p className="mt-0 mb-1">
                                                                        {value
                                                                          .professionInfoData
                                                                          ?.profession
                                                                          ? value
                                                                              .professionInfoData
                                                                              ?.profession
                                                                          : "--"}
                                                                      </p>
                                                                      <p
                                                                        className="mt-0"
                                                                        style={{
                                                                          color:
                                                                            "#6E7D8C",
                                                                        }}
                                                                      >
                                                                        {
                                                                          "7 hours ago"
                                                                        }
                                                                        <span
                                                                          className="mx-4"
                                                                          style={{
                                                                            color:
                                                                              "gainsboro",
                                                                          }}
                                                                        >
                                                                          |
                                                                        </span>
                                                                        {value
                                                                          .spaceTypeInfoData
                                                                          ?.isPrivate ? (
                                                                          <>
                                                                            <img
                                                                              className="mr-2"
                                                                              src={
                                                                                privateIcon
                                                                              }
                                                                              alt="Private Icon"
                                                                              style={{
                                                                                width:
                                                                                  "12px",
                                                                                paddingBottom:
                                                                                  "4px",
                                                                              }}
                                                                            />
                                                                            Private
                                                                          </>
                                                                        ) : value
                                                                            .spaceTypeInfoData
                                                                            ?.isPublic ? (
                                                                          <>
                                                                            <img
                                                                              className="mr-2"
                                                                              src={
                                                                                publicIcon
                                                                              }
                                                                              alt="Public Icon"
                                                                              style={{
                                                                                width:
                                                                                  "14px",
                                                                                paddingBottom:
                                                                                  "1px",
                                                                              }}
                                                                            />
                                                                            Public
                                                                          </>
                                                                        ) : value
                                                                            .spaceTypeInfoData
                                                                            ?.isRestricted ? (
                                                                          <>
                                                                            <img
                                                                              className="mr-2"
                                                                              src={
                                                                                privateIcon
                                                                              }
                                                                              alt="Restricted Icon"
                                                                              style={{
                                                                                width:
                                                                                  "12px",
                                                                                paddingBottom:
                                                                                  "4px",
                                                                              }}
                                                                            />
                                                                            Restricted
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <img
                                                                              className="mr-2"
                                                                              src={
                                                                                publicIcon
                                                                              }
                                                                              alt="Public Icon"
                                                                              style={{
                                                                                width:
                                                                                  "14px",
                                                                                paddingBottom:
                                                                                  "1px",
                                                                              }}
                                                                            />
                                                                            Public
                                                                          </>
                                                                        )}
                                                                      </p>
                                                                    </div>
                                                                    <p className="ml-2">
                                                                      <b>
                                                                        {value.title ? (
                                                                          value.title
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                      </b>
                                                                    </p>
                                                                    <p
                                                                      className="ml-2"
                                                                      id={
                                                                        value.spaceIdHash
                                                                      }
                                                                      style={viewLessDescStyle()}
                                                                    >
                                                                      {value.description ? (
                                                                        value.description
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                    </p>

                                                                    {/* <p className="ml-2 my-3" id={`viewMore${value.spaceIdHash}`} style={{ color: '#3ABD00', cursor: 'pointer' }} onClick={(e) => viewMoreBtnClicked(e, value)}>
                                                                                        View More
                                                                                    </p> */}
                                                                  </div>
                                                                  {meFlag ? (
                                                                    <>
                                                                      <div className="">
                                                                        <p
                                                                          className="my-3"
                                                                          id={`viewMore${value.spaceIdHash}`}
                                                                          style={{
                                                                            color:
                                                                              "#2B2B2B",
                                                                            cursor:
                                                                              "pointer",
                                                                            borderTop:
                                                                              "1px solid gainsboro",
                                                                            textAlign:
                                                                              "center",
                                                                            paddingTop:
                                                                              "15px",
                                                                            paddingBottom:
                                                                              "15px",
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            viewMoreBtnClickedForMeStatusUpdate(
                                                                              e,
                                                                              value
                                                                            )
                                                                          }
                                                                        >
                                                                          More...
                                                                        </p>
                                                                      </div>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <div className="button_list">
                                                                        <a
                                                                          className="about_btn"
                                                                          href="javascript:void(0)"
                                                                          style={{
                                                                            borderRight:
                                                                              "1px solid gainsboro",
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            followProfileFromSpaces(
                                                                              value
                                                                            );
                                                                          }}
                                                                        >
                                                                          +
                                                                          Follow
                                                                        </a>
                                                                        <a
                                                                          className="about_btn"
                                                                          href="javascript:void(0)"
                                                                          style={{
                                                                            color:
                                                                              "#2B2B2B",
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            viewMoreBtnClicked(
                                                                              e,
                                                                              value
                                                                            );
                                                                          }}
                                                                        >
                                                                          More...
                                                                        </a>
                                                                      </div>
                                                                      {/* <p className="mt-2" style={{ color: '#3ABD00', cursor: 'pointer', position: 'absolute', top: '-5px', right: '20px' }} onClick={() => { followProfileFromSpaces(value) }}>+ Follow</p> */}
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={modal.modal}
        size="md"
        centered
        style={{ background: "rgb(95 95 95 / 77%)" }}
      >
        <div className="text-center m-5">
          <Modal.Title>
            {messagesFlag ? (
            <>
            {modal.data?.isSenderLoggedInUser === 1 && (
            <>
            Are you sure, you want to {modal.type}{" "}
            {modal.data?.receiverUserInfoData?.userInformation ? modal.data?.receiverUserInfoData?.userInformation?.entityName ? modal.data?.receiverUserInfoData?.userInformation?.entityName : modal.data?.receiverUserInfoData?.userInformation?.firstName + ' ' + modal.data?.receiverUserInfoData?.userInformation?.lastName : ''} ?
            </>)}
            {modal.data?.isReceiverLoggedInUser === 1 && (
            <>
            Are you sure, you want to {modal.type}{" "}
            {modal.data?.sendUserInfoData?.userInformation ? modal.data?.sendUserInfoData?.userInformation?.entityName ? modal.data?.sendUserInfoData?.userInformation?.entityName : modal.data?.sendUserInfoData?.userInformation?.firstName + ' ' + modal.data?.sendUserInfoData?.userInformation?.lastName : ''} ?
            </>)}
            </>) : (
            <>
            Are you sure, you want to {modal.type}{" "}
            {modal.data?.userInfoData ? modal.data?.userInfoData?.userInformation?.entityName ? modal.data?.userInfoData?.userInformation?.entityName : modal.data?.userInfoData?.userInformation?.firstName + ' ' + modal.data?.userInfoData?.userInformation?.lastName : ''} ?
            </>)}
            
          </Modal.Title>
        </div>
        
        <div className="text-center m-5">
          <Modal.Body>
            <p style={{textAlign:'center'}}>Comment your reason (optional):</p>
            <textarea rows="4" value={reportComment} style={{width:'100%'}} onChange={(e) => {reportCommentChanged(e)}}></textarea>
          </Modal.Body>
        </div>

        {modalLoading ? (
          <div className="text-center m-5">
            <span className="spinner-border spinner-border-sm"></span>
          </div>
        ) : (
          <div className="text-center m-5">
            <Button
              className="mx-3"
              variant="danger"
              onClick={() => {setModal(!modal); setReportComment('');}}
            >
              No
            </Button>
            <Button className="mx-3" variant="primary" onClick={onClickAction}>
              Yes
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getHomeScreenTabs: bindActionCreators(getHomeScreenTabs, dispatch),
  getHomeScreenList: bindActionCreators(getHomeScreenList, dispatch),
  shortlist: bindActionCreators(shortlist, dispatch),
  follow: bindActionCreators(follow, dispatch),
  getUserTypeInfo: bindActionCreators(getUserTypeInfo, dispatch),
  getFollowingListForAll: bindActionCreators(getFollowingListForAll, dispatch),
  getFollowingListForEntity: bindActionCreators(
    getFollowingListForEntity,
    dispatch
  ),
  getFollowersListForAll: bindActionCreators(getFollowersListForAll, dispatch),
  getFollowersListForEntity: bindActionCreators(
    getFollowersListForEntity,
    dispatch
  ),
  unfollow: bindActionCreators(unfollow, dispatch),
  getSpacesDataFromFollowingNetwork: bindActionCreators(
    getSpacesDataFromFollowingNetwork,
    dispatch
  ),
  getSpaceDataForProfessionalsForAll: bindActionCreators(
    getSpaceDataForProfessionalsForAll,
    dispatch
  ),
  getSpaceDataForProfessionalsForEntityId: bindActionCreators(
    getSpaceDataForProfessionalsForEntityId,
    dispatch
  ),
  getSpaceDataForCompaniesForAll: bindActionCreators(
    getSpaceDataForCompaniesForAll,
    dispatch
  ),
  getSpaceDataForCompaniesForEntityId: bindActionCreators(
    getSpaceDataForCompaniesForEntityId,
    dispatch
  ),
  getSpaceDataForMeForAll: bindActionCreators(
    getSpaceDataForMeForAll,
    dispatch
  ),
  getSpaceDataForMeForEntityId: bindActionCreators(
    getSpaceDataForMeForEntityId,
    dispatch
  ),
  getSpaceCategories: bindActionCreators(getSpaceCategories, dispatch),
  createSpacePost: bindActionCreators(createSpacePost, dispatch),
  deleteSpacePost: bindActionCreators(deleteSpacePost, dispatch),
  updateSpacePost: bindActionCreators(updateSpacePost, dispatch),
  followFromSpaces: bindActionCreators(followFromSpaces, dispatch),
  getStatusUpdateDataForUsers: bindActionCreators(
    getStatusUpdateDataForUsers,
    dispatch
  ),
  getStatusUpdateDataForUsersForEntityTypeId: bindActionCreators(
    getStatusUpdateDataForUsersForEntityTypeId,
    dispatch
  ),
  getStatusUpdateDataForMeForEntityTypeId: bindActionCreators(
    getStatusUpdateDataForMeForEntityTypeId,
    dispatch
  ),
  getStatusUpdateDataForMe: bindActionCreators(
    getStatusUpdateDataForMe,
    dispatch
  ),
  createWhatsOnMyMindStatus: bindActionCreators(
    createWhatsOnMyMindStatus,
    dispatch
  ),
  getReadInboxMessagesForAll: bindActionCreators(
    getReadInboxMessagesForAll,
    dispatch
  ),
  getReadInboxMessagesForEntityTypeId: bindActionCreators(
    getReadInboxMessagesForEntityTypeId,
    dispatch
  ),
  getNewInboxMessagesForAll: bindActionCreators(
    getNewInboxMessagesForAll,
    dispatch
  ),
  getNewInboxMessagesForEntityTypeId: bindActionCreators(
    getNewInboxMessagesForEntityTypeId,
    dispatch
  ),
  getDeletedInboxMessagesForAll: bindActionCreators(
    getDeletedInboxMessagesForAll,
    dispatch
  ),
  getDeletedInboxMessagesForEntityTypeId: bindActionCreators(
    getDeletedInboxMessagesForEntityTypeId,
    dispatch
  ),
  deleteMessageFromInbox: bindActionCreators(deleteMessageFromInbox, dispatch),
  deleteMessageThreadFromInbox: bindActionCreators(
    deleteMessageThreadFromInbox,
    dispatch
  ),
  restoreMessageToInboxFromArchived: bindActionCreators(
    restoreMessageToInboxFromArchived,
    dispatch
  ),
  restoreMessageThreadFromArchived: bindActionCreators(
    restoreMessageThreadFromArchived,
    dispatch
  ),
  sendNewMessage: bindActionCreators(sendNewMessage, dispatch),
  getListOfMessagesForMessageThread: bindActionCreators(
    getListOfMessagesForMessageThread,
    dispatch
  ),
  replyToMessage: bindActionCreators(replyToMessage, dispatch),
  searchMessages: bindActionCreators(searchMessages, dispatch),
  searchSpaces: bindActionCreators(searchSpaces, dispatch),
  searchFollowing: bindActionCreators(searchFollowing, dispatch),
  getConnections: bindActionCreators(getConnections, dispatch),
  followFromConnections: bindActionCreators(followFromConnections, dispatch),
  reportUser: bindActionCreators(reportUser, dispatch),
  blockUser: bindActionCreators(blockUser, dispatch),
  reportPost: bindActionCreators(reportPost, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkEmployee);
