import React from "react";
import { connect } from "react-redux";
import {
  getProfessionsList,
  getSearchJobListForLandingPage,
} from "../../actions/common";
import zeroFollowingImage from "../../public/images/zeroFollowingImageSmall.png";
import {searchEmployer,searchEmployerJobs}  from "../../actions/employer/talent";
import Skill from "../../public/img/skill.svg";
import JobLocation from "../../public/img/joblocation.svg";
import CompanyLogo1 from "../../public/images/defaultEmployer.png";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress
} from "react-places-autocomplete";
import { toast } from "react-toastify";
import RightMenu from "./rightMenu.js"

const SearchEmployer = (props) => {
  
  const [loading, setLoading] = useState(false);
  const [employer, setEmployerList] = useState([]);
  const [keyword, setkeyword] = useState('');
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [zipCode, setZipCode] = useState();
  const [address, setAddress] = useState();
  const [latestData, setLatestData] = useState();
  const [savedcity, setsavedCity] = useState();
  const [savedstate, setsavedState] = useState();
  const [savedcountry, setsavedCountry] = useState();

  const queryParams = new URLSearchParams(window.location.search)

  useEffect(() => {

    queryParams.get("city")
    ? setAddress(
          queryParams.get("city") +
          ", " +
          queryParams.get("state") +
          ", " +
          queryParams.get("country")
      )
    : setAddress();

    setkeyword(queryParams.get("keyword") ? queryParams.get("keyword") : keyword);
    setCity(queryParams.get("city") ? queryParams.get("city") : city);
    setState(queryParams.get("state") ? queryParams.get("state") : state);
    setCountry(queryParams.get("country") ? queryParams.get("country") : country);
    setCountry(queryParams.get("zipCode") ? queryParams.get("zipCode") : zipCode);

    if(queryParams.get("keyword")){
      searchEmployer()
    }

  }, []);

  const searchEmployer = () => {
   
    setLoading(true)
    if(!queryParams.get("keyword")){
      toast.warn('Please add keyword on what?')
      setLoading(false)
      return false;
    }
    var params={
      'search' :queryParams.get("keyword") ? queryParams.get("keyword") : keyword,
      'city' : queryParams.get("city") ? queryParams.get("city") : city,
      'country' : queryParams.get("country") ? queryParams.get("country") : country,
      'state' : queryParams.get("state") ? queryParams.get("state") : state,
      'zipCode' : queryParams.get("zipCode") ? queryParams.get("zipCode") : zipCode
    }
    
    
    props.searchEmployer(params, (res) => {
      if(res.status == false){
        toast.warn(res.message)
        setEmployerList([])
      }
      else{
        setsavedCity(city);
        setsavedState(state);
        setsavedCountry(country);
        setEmployerList(res.data)
        setLatestData(res.data[0])
      }
      setLoading(false)
    });

  };

  const setEmployer = (index) => {
    setLatestData(employer[index])
  }

  const setPrarmsInUrl = (key,value) => {
    const url = new URL(window.location.href);
    if(value){
      url.searchParams.set(key,value);
    }else{
      url.searchParams.delete(key);
    }
    window.history.replaceState(null, null, url);
  }

  const updateKeyword = (e) => {
    setkeyword(e.target.value)
    setPrarmsInUrl('keyword',e.target.value)
  }

  const autoCompleteHandleChange = (address) => {
    if (address === "") {
      setCity();
      setState();
      setCountry();
      setAddress();
    } 
    else{
      setAddress(address);
    }
  };

  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        document.getElementById("searchTextField").blur();
        fillAddressDetails(results);
      })
      .catch((error) => {});
  };

  const google = (window.google = window.google ? window.google : {});

  const searchOptions = {
    // location: new this.google.maps.LatLng(51.509865, -0.118092),
    // radius: 424000,
    //types: ["address", "uk"],
    componentRestrictions: { country: "" },
  };

  const fillAddressDetails = (results) => {
    setAddress(results[0].formatted_address);
    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        setZipCode(results[0].address_components[j].short_name);
        setPrarmsInUrl('zipCode ',results[0].address_components[j].short_name);

      } else if (results[0].address_components[j].types[0] == "locality") {
        setCity(results[0].address_components[j].long_name);
        setPrarmsInUrl('city',results[0].address_components[j].long_name);

      } else if (
        results[0].address_components[j].types[0] ==
        "administrative_area_level_1"
      ) {
        setState(results[0].address_components[j].long_name);
        setPrarmsInUrl('state',results[0].address_components[j].long_name);

      } else if (results[0].address_components[j].types[0] == "country") {
        setCountry(results[0].address_components[j].long_name);
        setPrarmsInUrl('country',results[0].address_components[j].long_name);

      }
    }
  };

  return (
    <>
      <section className="findJob_">
        <div className="container">
          <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-5">
              <div className="findjob_deatils">
                <p>What?</p>
                <span className="jon_skills d-flex">
                  <a>
                    {" "}
                    <img src={Skill} alt="skillIcon" />
                  </a>
                  <input
                    type="text"
                    placeholder="keywords, company, skills..."
                    onChange={(e) => {updateKeyword(e)}}
                    value={keyword}
                  />
                </span>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-5">
              <div className="findjob_deatils">
                <p>Where?</p>
                <span className="jon_skills d-flex">
                  <a>
                    {" "}
                    <img src={JobLocation} alt="skillIcon" />
                  </a>
                  {/* <input type="text" placeholder="city, state, country ..." /> */}
                  <div className="form-group w-100 mb-0" style={{ height: "10px" }}>
                    <PlacesAutocomplete
                      value={address}
                      onChange={autoCompleteHandleChange}
                      onSelect={autoCompleteHandleSelect}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            id="searchTextField"
                            {...getInputProps({
                              placeholder: "Search Places...",
                              className:
                                "location-search-input form-control inputStyle",
                            })}
                            // style={{ height: "32px" }}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#41b6e6",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </span>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2 mt-3">
              <div className="findjob_deatils d-flex">
              {/* <div className="findjob_deatils"> */}
                <div className="jobbtn_ text-center">
                  <button className="jobbtn_main disabled" onClick={()=>{
                    searchEmployer()
                  }} type="submit">Find Employer </button>
                </div>
                {/* <div className="filterbtn_ text-center">
                  <button className="filterbtn_main" type="submit">
                    <span>
                      <img src={BtnFilter} alt="filter" className="img-fluid"/>
                    </span>Find Job </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="jobdescription_">
        <div className="container">
            {queryParams.get("city") ? (
              <>
                <h5>
                  Employers In{" "}
                  {
                    queryParams.get("city") +
                    ", " +
                    queryParams.get("state") +
                    ", " +
                    queryParams.get("country")
                    }
                </h5>
              </>
            ) : savedcity ? (
              <>
                <h5>
                  Employers In {savedcity + ", " + savedstate + ", " + savedcountry}
                </h5>
              </>
            ) : (
              <></>
            )}
            <div className="row">
            {loading ? (
              <>
                <div className="row" style={{ width: "100%", height: "80vh" }}>
                  <div
                    className="col-12"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                        verticalAlign: "middle",
                        lineHeight: "40vh",
                      }}
                    >
                      <div
                        className="spinner-grow"
                        role="status"
                        style={{ background: "#006838" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
              <div className={employer.length > 0 ? "col-sm-12 col-md-12 col-lg-6" : "col-sm-12 col-md-12 col-lg-12"}>
                <div className="myScroller">
                <div className="nav flex-column nav-pills jobdescription_links"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {employer && employer.length ?
                  employer.map((empl,index)=>{
                    return (
                      <div  
                          key={index}
                          className="nav-link jobDescriptionTab"
                          data-id={index + 1}
                          onClick={()=>{
                            setEmployer(index)
                          }}
                          data-toggle="pill"
                          href={"#jobdetails" + (index + 1)}
                          role="tab"
                          aria-controls={"jobdetails" + (index + 1)}
                          aria-selected="true"
                        >
                          <div className="jobdescription_box d-flex p-5">
                            <a
                              className="jobdescription_box_img"
                             
                            >
                              <img
                                src={empl.userProfileData.selectedUserData.userProfileImageUrl ? empl.userProfileData.selectedUserData.userProfileImageUrl : CompanyLogo1}
                                //src="http://62.151.181.34:8080/rest/file/download?fileHashId=e17360aeb8ba8de9f59d3c09f31e07798902864192fc400242ae324f3a625967"
                                alt="logo"
                                className="img-fluid"
                              />
                            </a>
                            <div className="job_details">
                              <h4>
                              {empl?.userProfileData?.selectedEntityData?.title}
                              </h4>
                              <p>{empl.firstName} {empl.lastName}</p>
                              <p className="job_location d-flex">
                                <a >
                                  <img src={JobLocation} alt="logo"className="mr-2" />
                                  <span>{empl.address1}</span>
                                  {/* <span>{empl.address}</span> */}
                                </a>
                                <span>
                                  {/* <a h>
                                    <img src={JobLocation} alt="logo" />
                                    <span className="salery">$700 - $1500 p.a.</span>
                                  </a> */}
                                </span>
                              </p>
                              {/* <span className="job_description_details">
                                Job Description. Responsibilities. Competent
                                professional with over 1. 6 years of rich experience in
                                Recruiting, Temp Staffing. Strong expertise in Talent
                                Acquisition sites. Meeting TA demand a...
                              </span> */}
                            </div>
                          </div>
                        </div>
                  
                    );
                  })
                  :
                  <div
                    className="nav-link"
                    data-id="1"
                    data-toggle="pill"
                    href="#jobdetails1"
                    role="tab"
                    aria-controls="jobdetails1"
                    aria-selected="true"
                  >
                          <div className="row mb-5 h-100 w-100">
                            <div
                              className="col-6 text-center"
                              style={{
                                position: "relative",
                                left: "25%",
                                // height: "45vh",
                              }}
                            >
                              <img
                                className="mb-3"
                                src={zeroFollowingImage}
                                alt="No Data Found"
                              />
                              <p>No employers found!</p>
                            </div>
                          </div>
                        
                  </div>
                  }
                </div>
                </div>
              </div>
              {employer.length > 0 ?
                <>
                <div className="col-sm-12 col-md-12 col-lg-6 findJob_Box">
                  <div className="tab-content " id="v-pills-tabContent">

                  <RightMenu empl={latestData}/>
                  
                  </div>
                </div>
                </>
              :
                null
              }
              </>
            )}
            </div>
        </div>
      </section>
      {/* <div className="pageNavigation">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link " href="#">
                        &laquo;
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link active" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        &raquo;
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getProfessionsList: bindActionCreators(getProfessionsList, dispatch),
  getSearchJobListForLandingPage: bindActionCreators(
    getSearchJobListForLandingPage,
    dispatch
  ),
  searchEmployer: bindActionCreators(
    searchEmployer,
    dispatch
  ),
  searchEmployerJobs: bindActionCreators(
    searchEmployerJobs,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchEmployer);
