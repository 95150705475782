import moment from "moment-timezone";
import goldBadge from "../app/public/images/goldBadge.svg";
import starBadge from "../app/public/images/starBadge.svg";
import bronzeBadge from "../app/public/images/bronzeBadge.svg";
import platinumBadge from "../app/public/images/platinumBadge.svg";
import diamondBadge from "../app/public/images/diamondBadge.svg";
import silverBadge from "../app/public/images/silverBadge.svg";

// MMMM Do YYYY, h:mm:ss a
export const getFormatedDate = (
  date,
  format = "MM-DD-YYYY, h:mm:ss A",
  timeZone = ""
) => {
  if (date && moment(date).isValid()) {
    if (timeZone) {
      return moment(date).format(format).tz(timeZone);
    }
    return moment(date).format(format);
  }
  return false;
};

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export const showBadge = (val) => {
  if (val?.isTypeEmployer == 1) {
    if (val?.level == 0) {
      return starBadge;
    } else if (val?.level == 1) {
      return diamondBadge;
    } else if (val?.level == 2) {
      return goldBadge;
    }
  } else {
    if (val?.level == 0) {
      return bronzeBadge;
    } else if (val?.level == 1) {
      return silverBadge;
    } else if (val?.level == 2) {
      return platinumBadge;
    }
  }
}

export const showBadgeForNullValueEmployee = (val) => {
  if (val == null) {
    return bronzeBadge;
  } else {
    if (val.level == 0) {
      return bronzeBadge;
    } else if (val.level == 1) {
      return silverBadge;
    } else if (val.level == 2) {
      return platinumBadge;
    }
  }
}
