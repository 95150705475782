import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

export const getFormFieldsForPostJob = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.entityTypeId}/entityType/formFields?userProfileId=${params.userProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const postJob = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.entityTypeId}/entityType?userIdHash=${params.userIdHash}&userProfileId=${params.userProfileId}`,
      params.data,
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            statusCode: result.status,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getJobList = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/entityType/filter?entityTypeId=${params.entityTypeId}&includeCreatedAndUpdatedDate=1&userIdHash=${params.userIdHash}&userProfileId=${params.userProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            statusCode: result.status,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getSkillBio = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/employer/${params.userIdHash}/bio/${params.userProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getFormFieldsEntity = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.entityId}/formFields`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getFieldValuesForEditing = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.entityId}/formFields`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const editJob = (params, cb) => {
  return (dispatch) => {
    RestClient.put(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.entityId}?userIdHash=${params.userIdHash}&userProfileId=${params.userProfileId}`,
      params.data,
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            statusCode: result.status,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getJobDetails = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.entityId}/entity`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getDailyCalendarDetails = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/jobShiftTypeAvailability/actives/formatted/${params.userProfileId}/${params.entityId}`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const postJobDateAndTime = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/jobShiftTypeAvailability/set/${params.userProfileId}/${params.entityId}?isDailyCalendar=1&isPublished=1`,
      params.data,
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            statusCode: result.status,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getShortlisted = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/shortlist/actives/${params.userProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getFollowed = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/follow/actives/${params.userProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getInvited = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/invite/actives/${params.userProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const postJobSettings = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.perspectiveId}/perspective?userIdHash=${params.userIdHash}&userProfileId=${params.userProfileId}`,
      params.data,
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            statusCode: result.status,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const updateJobSettings = (params, cb) => {
  return (dispatch) => {
    RestClient.put(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.entityId}?userIdHash=${params.userIdHash}&userProfileId=${params.userProfileId}`,
      params.data,
      true
    )
      .then((result) => {
        if (result.dataEntityID) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            statusCode: result.status,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getAllApplicants = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/apply/${params.userProfileId}/pending`,
      {},
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const removeShortlist = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(
      `${EXPRESS_MIDDLEWARE_API_URL}/shortlist?shortlistId=${params.shortlistId}`,
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const removeFollow = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(
      `${EXPRESS_MIDDLEWARE_API_URL}/follow?followId=${params.followId}`,
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const removeInvite = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(
      `${EXPRESS_MIDDLEWARE_API_URL}/invite?inviteId=${params.inviteId}`,
      true
    )
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};
