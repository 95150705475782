import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import left_small from "../../public/images/left_small.png";
import password from "../../public/images/password.png";
import { toast } from "react-toastify";
import { userChangePassword, userLogOut } from "../../actions/user";
import { bindActionCreators } from "redux";
import swal from "sweetalert";

const ChangePassword = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const logout = () => {
    props.onLogout(
      {},
      (res) => {
        if (res.status) {
          history.push("/");
          toast.success(
            res.data
              ? "Login with new credentials"
              : "Session timed out. Please login again."
          );
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        toast.error("Something went wrong!");
      }
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (newPassword != confirmPassword) {
      toast.error("New password & Confirm Password should be same.");
      return;
    }
    if (oldPassword == newPassword) {
      toast.error("New password & Old Password should not be same.");
      return;
    }
    setLoading(true);
    props.userChangePassword({ oldPassword, newPassword }, (res) => {
      setLoading(false);
      if (res.status) {
        swal({
          text: "Password changed successfully",
          icon: "success",
        })
          .then(() => {
            logout();
          })
          .finally(() => {
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
          });
      } else {
        toast.error("Something went wrong.");
      }
    });
  };

  return (
    <div
      className="content d-flex flex-column flex-column-fluid pt-5 pb-0"
      id="kt_content"
    >
      <div className="d-flex flex-column-fluid">
        <div className="custom-container">
          <div className="row  pb-5">
            <div className="col-lg-12 col-md-12">
              <div className="inner_txecy job_list">
                <h4>Settings </h4>
                <p>
                  <span>
                    <img src={left_small} />
                  </span>
                  Change Password
                </p>
              </div>
            </div>
          </div>

          <div className="Profile_form">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="password_outer">
                  
                  {loading ? (
                    <>
                      <div
                        className="row"
                        style={{ width: "100%", height: "50vh" }}
                      >
                        <div
                          className="col-12"
                          style={{ width: "100%", height: "100%" }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              verticalAlign: "middle",
                              lineHeight: "50vh",
                            }}
                          >
                            <div
                              className="spinner-grow"
                              role="status"
                              style={{ background: "#006838" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                    <h5>Change Password</h5>
                      <form className="middle_part" onSubmit={onSubmit}>
                        <div className="paaswod_outer">
                          <img src={password} />
                          <div className="input67">
                            <p>Old Password</p>
                            <input
                              type="password"
                              required
                              placeholder="Enter old password"
                              value={oldPassword}
                              onChange={(e) => setOldPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="paaswod_outer">
                          <img src={password} />
                          <div className="input67">
                            <p>New Password</p>
                            <input
                              type="password"
                              required
                              placeholder="Enter new password"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="paaswod_outer">
                          <img src={password} />
                          <div className="input67">
                            <p>Confirm Password</p>
                            <input
                              type="password"
                              required
                              placeholder="Confirm your new password"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="list_btn">
                          <ul>
                            <li>
                              <button
                                type="submit"
                                className="btn btn_cancek dark_btn"
                              >
                                Update
                              </button>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  userChangePassword: bindActionCreators(userChangePassword, dispatch),
  onLogout: bindActionCreators(userLogOut, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
