import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Vector from "../../public/img/Vector.svg";
import Reliability from "../../public/img/reliability(1).svg";
import Flexibility from "../../public/img/flexibility.svg";
import Shield from "../../public/img/shield_1.svg";
import Diagram from "../../public/img/diagram.svg";
import Driver from "../../public/img/driver.svg";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";

const WhyUs = (props) => {
  const history = useHistory();

  return (
    <>
      <section
        className="services_section  services_home_an p-60   aos-item"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="home_main_title">
            <h2>Why Choose us</h2>
            <p>It was popularised in the 1960s with the release </p>
          </div>

          <div className="row choose_us__homebg">
            <div className="col-md-5">
              <div className="choose_us">
                <img src={Vector} alt="" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-6">
                  <div className="service_secon">
                    <span>
                      <img src={Reliability} alt="" />{" "}
                    </span>
                    <h3>Health Care</h3>
                    <p>
                      It was popularised in the 1960s with the release of
                      Letraset sheets
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="service_secon">
                    <span>
                      <img src={Flexibility} alt="" />{" "}
                    </span>
                    <h3>Engineering</h3>
                    <p>
                      It was popularised in the 1960s with the release of
                      Letraset sheets
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="service_secon">
                    <span>
                      <img src={Shield} alt="" />{" "}
                    </span>
                    <h3>Plumbers</h3>
                    <p>
                      It was popularised in the 1960s with the release of
                      Letraset sheetss
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="service_secon">
                    <span>
                      <img src={Diagram} alt="" />{" "}
                    </span>
                    <h3>Plumbers</h3>
                    <p>
                      It was popularised in the 1960s with the release of
                      Letraset sheetss
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="service_secon">
                    <span>
                      <img src={Driver} alt="" />{" "}
                    </span>
                    <h3>Plumbers</h3>
                    <p>
                      It was popularised in the 1960s with the release of
                      Letraset sheetss
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WhyUs);
