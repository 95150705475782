import { Switch, Route } from "react-router-dom";
import * as Layouts from "./../app/layouts";
import * as Containers from "./../app/containers";
import TermsNconditions from "../app/components/TermsNconditions";
import PrivacyPolicy from "../app/components/PrivacyPolicy";
import AboutUs from "../app/components/AboutUs";
import ContactUs from "../app/components/ContactUs";

function Router() {
  return (
    <Switch>
      {/* Auth Routes*/}
      <Layouts.AuthRoute exact path="/" component={Containers.Home} />
      <Layouts.AuthRoute exact path="/login/:userType" component={Containers.Login} />
      <Layouts.AuthRoute exact path="/signup/:userType" component={Containers.Login} />
      <Layouts.AuthRoute exact path="/search-job" component={Containers.SearchJob} />
      <Layouts.AuthRoute exact path="/search-employer" component={Containers.SearchEmployer} />
      <Layouts.AuthRoute exact path="/search-job/:city/:state/:country" component={Containers.SearchJob} />
      <Layouts.AuthRoute exact path="/search-job/:profession/:professionTitle/:city/:state/:country" component={Containers.SearchJob} />
      <Layouts.AuthRoute exact path="/search-job/:profession/:city/:state/:country" component={Containers.SearchJob} />
      <Layouts.AuthRoute exact path="/search-job/:professionTitle/:city/:state/:country" component={Containers.SearchJob} />
      <Layouts.AuthRoute exact path="/fav-jobs" component={Containers.FavJob} />
      <Layouts.AuthRoute exact path="/apply-job/:entityId/:userProfileId" component={Containers.ApplyJob}/>
      <Layouts.AuthRoute exact path="/interested/:entityId/:userProfileId" component={Containers.Interested}/>
      <Layouts.AuthRoute exact path="/details" component={Containers.Details}/>
      {/* <Layouts.AuthRoute exact path="/" component={Containers.Login} />
      <Layouts.AuthRoute exact path="/login/:userType" component={Containers.Login} />
      <Layouts.AuthRoute exact path="/search-job" component={Containers.SearchJob} />
      <Layouts.AuthRoute exact path="/search-job/:city/:state/:country" component={Containers.SearchJob} /> */}

      {/* Non Private Routes*/}
      <Layouts.AuthRoute exact path="/terms_n_conditions"component={TermsNconditions} />
      <Layouts.AuthRoute exact path="/privacy_policy" component={PrivacyPolicy} />
      <Layouts.NonPrivateRoute exact path="/aboutUs" component={AboutUs} />
      {/* <Layouts.NonPrivateRoute exact path="/contactUs" component={ContactUs} /> */}
      <Layouts.AuthRoute exact path="/contactUs" component={ContactUs} />

      {/* Private Routes*/}
      <Layouts.PrivateRoute
        exact
        path="/home"
        component={Containers.Dashboard}
      />
      <Layouts.PrivateRoute
        exact
        path="/candidate"
        component={Containers.CandidateDetail}
      />
      <Layouts.PrivateRoute
        exact
        path="/talent"
        component={Containers.Talent}
      />
      <Layouts.PrivateRoute
        exact
        path="/talent/:id"
        component={Containers.TalentDetails}
      />
      <Layouts.PrivateRoute exact path="/post" component={Containers.Post} />
      <Layouts.PrivateRoute
        exact
        path="/jobList/:talentId/:entityTypeId"
        component={Containers.JobList}
      />
      <Layouts.PrivateRoute
        exact
        path="/jobDetails/:talentId/:entityId/:entityTypeId"
        component={Containers.JobDetails}
      />
      <Layouts.PrivateRoute
        exact
        path="/jobApplications/:userProfileId"
        component={Containers.JobApplications}
      />
      <Layouts.PrivateRoute
        exact
        path="/candidates/:talentId"
        component={Containers.Candidates}
      />
      <Layouts.PrivateRoute
        exact
        path="/bookingEmployer"
        component={Containers.BookingEmployer}
      />
      <Layouts.PrivateRoute
        exact
        path="/bookingEmployee"
        component={Containers.BookingEmployee}
      />
      <Layouts.PrivateRoute
        exact
        path="/networkEmployer"
        component={Containers.NetworkEmployer}
      />
      <Layouts.PrivateRoute
        exact
        path="/networkEmployee"
        component={Containers.NetworkEmployee}
      />
      <Layouts.PrivateRoute
        exact
        path="/change-password"
        component={Containers.ChangePassword}
      />
      <Layouts.PrivateRoute
        exact
        path="/manage-payment"
        component={Containers.ManagePayment}
      />
      <Layouts.PrivateRoute
        exact
        path="/contact"
        component={Containers.Contact}
      />
      <Layouts.PrivateRoute
        exact
        path="/skills"
        component={Containers.Skills}
      />
      <Layouts.PrivateRoute
        exact
        path="/availability"
        component={Containers.Availability}
      />
      <Layouts.PrivateRoute
        exact
        path="/manage-bank"
        component={Containers.ManageBankAcc}
      />
      <Layouts.PrivateRoute
        exact
        path="/emergency-contact"
        component={Containers.EmergencyContact}
      />
      <Layouts.PrivateRoute
        exact
        path="/profileEmployer"
        component={Containers.ProfileEmployer}
      />
      <Layouts.PrivateRoute
        exact
        path="/profileEmployee"
        component={Containers.ProfileEmployee}
      />

      <Route component={Containers.NotFound} />
    </Switch>
  );
}

export default Router;
