import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import contact_left from '../../public/images/contact_left.png';
import left_small from '../../public/images/left_small.png';


const EmergencyContact = (props) => {
    const history = useHistory();

    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">

                <div className="d-flex flex-column-fluid">

                    <div className="custom-container">


                        <div className="custom_skki8">
                            <h3>Settings<img src={left_small} /><span>Emergency Contact  Details</span></h3>
                        </div>

                        <div className="Profile_form mange_ban5">



                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="email_outer custom_pu56">
                                        <label>First Name</label>
                                        <input type="text" placeholder="Antony" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="email_outer custom_pu56">
                                        <label>Middle Name</label>
                                        <input type="text" placeholder="Antony" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="email_outer custom_pu56">
                                        <label>Last Name</label>
                                        <input type="text" placeholder="Will" />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="email_outer">
                                        <label>Email Address</label>
                                        <input type="text" placeholder="Enter Here" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="email_outer custom_pu56">
                                        <label>Contact Number</label>
                                        <input type="text" placeholder="+123" />
                                    </div>
                                </div>


                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="email_outer">
                                        <label>Address 1</label>
                                        <input type="text" placeholder="Enter Here" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="email_outer custom_pu56">
                                        <label>Address 2</label>
                                        <input type="text" placeholder="+123" />
                                    </div>
                                </div>


                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="email_outer">
                                        <label>City</label>
                                        <input type="text" placeholder="Enter Here" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="email_outer custom_pu56">
                                        <label>State</label>
                                        <input type="text" placeholder="+123" />
                                    </div>
                                </div>


                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="email_outer">
                                        <label>Zip Code</label>
                                        <input type="text" placeholder="Enter Here" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="email_outer custom_pu56">
                                        <label>Country</label>
                                        <input type="text" placeholder="+123" />
                                    </div>
                                </div>


                            </div>

                        </div>

                        <div className="forgott6 custom_detalis7">

                            <div className="list_btn">
                                <ul>
                                    <li><button className="btn btn_cancek">Cancel</button></li>
                                    <li><button className="btn btn_cancek dark_btn">Submit</button></li>
                                </ul>
                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyContact);