import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ImageVideo from "../../public/img/Image_video.png";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";
import { useEffect } from "react";

const WorkYourWay = (props) => {
  const history = useHistory();
  const [isActive1, setActiveOne] = useState(false);
  const [isActive2, setActiveTwo] = useState(false);
  const [isActive3, setActiveThree] = useState(false);
  const [isActive4, setActiveFour] = useState(false);

  useEffect(() => {
    setActiveOne(false)
    setActiveTwo(false)
    setActiveThree(false)
    setActiveFour(false)
  }, [])

  const accordionDataOne = {
    titleOne: "Fixed Price",
    contentOne: `Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.`,
  };
  const accordionDataTwo = {
    titleTwo: "Fixed Price",
    contentTwo: `Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.`,
  };
  const accordionDataThree = {
    titleThree: "Fixed Price",
    contentThree: `Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.`,
  };
  const accordionDataFour = {
    titleFour: "Fixed Price",
    contentFour: `Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.`,
  };

  const { titleOne, contentOne } = accordionDataOne;
  const { titleTwo, contentTwo } = accordionDataTwo;
  const { titleThree, contentThree } = accordionDataThree;
  const { titleFour, contentFour } = accordionDataFour;

  const expand = (value) => {
    if(value === 1) {
        setActiveOne(!isActive1)
    } else if(value === 2) {
        setActiveTwo(!isActive2)
    } else if(value === 3) {
        setActiveThree(!isActive3)
    } else if(value === 4) {
        setActiveFour(!isActive4)
    }
  }

  return (
    <>
      <section
        className="services_section choose_us__jersey  aos-item"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="home_main_title button_padding ">
            <h2>Why Easy</h2>
            <p>Work Your Way </p>
          </div>

          <div className="row job_post_now_inner">
            <div className="col-md-6">
              <div className="work_way">
                <img src={ImageVideo} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="work_way_section">
                <h3>Lorem Ipsum is simply dummy text of the printing </h3>
                <div className="accordion">
                  <div className="accordion-item">
                    <div className="accordion-title">
                      <div className="d-flex card-header pl-2 pt-1 pb-0 pr-2">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            role="button"
                            onClick={() => expand(1)}
                          >
                            {!isActive1 ? (
                              <>
                                <i
                                  className="more-less fa fa-angle-down"
                                  style={{ paddingLeft: "6px", paddingTop: "1px" }}
                                ></i>{" "}
                                {titleOne}
                              </>
                            ) : (
                              <>
                                <i
                                  className="more-less fa fa-angle-up"
                                  style={{ paddingLeft: "6px", paddingTop:'0px' }}
                                ></i>{" "}
                                {titleOne}
                              </>
                            )}
                          </button>
                        </h5>
                      </div>
                    </div>
                    {isActive1 ? (
                    <>
                    <div className="panel-body" data-aos={'fade'}>
                      <div className="accordion-content">{contentOne}</div>
                    </div>
                    </>) : (<></>)}
                  </div>
                </div>

                <div className="accordion">
                  <div className="accordion-item">
                    <div className="accordion-title">
                      <div className="d-flex card-header pl-2 pt-1 pb-0 pr-2">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            role="button"
                            onClick={() => expand(2)}
                          >
                            {!isActive2 ? (
                              <>
                                <i
                                  className="more-less fa fa-angle-down"
                                  style={{ paddingLeft: "6px", paddingTop: "1px" }}
                                ></i>{" "}
                                {titleTwo}
                              </>
                            ) : (
                              <>
                                <i
                                  className="more-less fa fa-angle-up"
                                  style={{ paddingLeft: "6px", paddingTop:'0px' }}
                                ></i>{" "}
                                {titleTwo}
                              </>
                            )}
                          </button>
                        </h5>
                      </div>
                    </div>
                    {isActive2 ? (
                    <>
                    <div className="panel-body" data-aos={'fade'}>
                      <div className="accordion-content">{contentTwo}</div>
                    </div>
                    </>) : (<></>)}
                  </div>
                </div>

                <div className="accordion">
                  <div className="accordion-item">
                    <div className="accordion-title">
                      <div className="d-flex card-header pl-2 pt-1 pb-0 pr-2">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            role="button"
                            onClick={() => expand(3)}
                          >
                            {!isActive3 ? (
                              <>
                                <i
                                  className="more-less fa fa-angle-down"
                                  style={{ paddingLeft: "6px", paddingTop: "1px" }}
                                ></i>{" "}
                                {titleThree}
                              </>
                            ) : (
                              <>
                                <i
                                  className="more-less fa fa-angle-up"
                                  style={{ paddingLeft: "6px", paddingTop:'0px' }}
                                ></i>{" "}
                                {titleThree}
                              </>
                            )}
                          </button>
                        </h5>
                      </div>
                    </div>
                    {isActive3 ? (
                    <>
                    <div className="panel-body" data-aos={'fade'}>
                      <div className="accordion-content">{contentThree}</div>
                    </div>
                    </>) : (<></>)}
                  </div>
                </div>

                <div className="accordion">
                  <div className="accordion-item">
                    <div className="accordion-title">
                      <div className="d-flex card-header pl-2 pt-1 pb-0 pr-2">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            role="button"
                            onClick={() => expand(4)}
                          >
                            {!isActive4 ? (
                              <>
                                <i
                                  className="more-less fa fa-angle-down"
                                  style={{ paddingLeft: "6px", paddingTop: "1px" }}
                                ></i>{" "}
                                {titleFour}
                              </>
                            ) : (
                              <>
                                <i
                                  className="more-less fa fa-angle-up"
                                  style={{ paddingLeft: "6px", paddingTop:'0px' }}
                                ></i>{" "}
                                {titleFour}
                              </>
                            )}
                          </button>
                        </h5>
                      </div>
                    </div>
                    {isActive4 ? (
                    <>
                    <div className="panel-body" data-aos={'fade'}>
                      <div className="accordion-content">{contentFour}</div>
                    </div>
                    </>) : (<></>)}
                  </div>
                </div>

                <div className="pay_form button_padding ">
                  <button
                    type="button"
                    name="next"
                    className=" post_job btn  button-5"
                    value="Next"
                  >
                    <div className="eff-5"></div>Post A Job Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WorkYourWay);
