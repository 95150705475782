import React, { useState, useEffect} from 'react';
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getPrivacyPolicy } from "../actions/common";
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import parse from "html-react-parser";
//const parse = require('html-react-parser');

const PrivacyPolicy = (props) => {
    const location = useLocation();
    const [privacyPolicy, setPrivacyPolicy] = useState({});
    const [privacyPolicyInfo, setPrivacyPolicyInfo] = useState({});
    const [loading, setLoading] = useState(false);

    const hashAccessKeyId = location && location.state && location.state.hashAccessKeyId;
    const serviceTag = location && location.state && location.state.serviceTag;

    const getPrivacyPolicy = () => {
        let params = {
            hashAccessKeyId: hashAccessKeyId,
            serviceTag: serviceTag,
        }
        setLoading(true);
        props.getPrivacyPolicy(params, (res) => {
          setLoading(false);
          if (res.status) {
            setPrivacyPolicy(res.data);
            setPrivacyPolicyInfo(res.data.whatinfo);
          } else {
            setPrivacyPolicy("");
          }
        },
        err => {
            setLoading(false);
            toast.error("Something went wrong!");
          })
      };

    useEffect(() => {
        getPrivacyPolicy();
    }, []);
      
  return (
    <section className="healthcase  bg-color" id="healthcase">
	    <div className="container">
		    <div className="title ">
			    {/* <h2>Privacy Policy</h2> */}
			</div>
            <div className="health aos-item soft_detail_detail"  data-aos="fade-up" id="about_us">
                <div className="row">
                    <div className="col-md-12">
                    { loading ?
                        <>
                        <div className="row" style={{ width: "100%", height: "80vh" }}>
                          <div className="col-12" style={{ width: "100%", height: "100%" }}>
                            <div style={{ width: "100%", height: "100%", textAlign: "center",verticalAlign: "middle",
                                lineHeight: "40vh", }} >
                              <div className="spinner-grow" role="status" style={{ background: "#006838" }} >
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      :
                        <>
                        <div className="job_detail softwarelist4 Privacy_div">	
                            <h1>{privacyPolicy && privacyPolicy.introduction && parse(privacyPolicy.introduction.title)}</h1>
                            <p>{privacyPolicy && privacyPolicy.introduction && privacyPolicy.introduction.description}</p>
                        </div>
                        <div className="job_detail softwarelist4 Privacy_div">	
                            <h1>{privacyPolicy && privacyPolicy.whatinfo && parse(privacyPolicy.whatinfo.title)}</h1>
                            <p>{privacyPolicy && privacyPolicy.whatinfo && privacyPolicy.whatinfo.description}</p>
                        </div>
                        </>
                        }
                        {/* <div className="job_detail softwarelist4 Privacy_div">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            <p>
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            <p>
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>		
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>		
                            <ul >
                                <li>Lorem Ipsum has been the industry's</li>
                                <li>Lorem Ipsum has been the industry's</li>
                                <li>Lorem Ipsum has been the industry's</li>
                                <li>Lorem Ipsum has been the industry's</li>											
                            </ul>
                            <p>
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            <p>
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>																					
                        </div> */}
                    </div>
                </div>	
            </div>
		</div>
	</section>
  )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
    getPrivacyPolicy: bindActionCreators(getPrivacyPolicy,dispatch),
   
});
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);

