import React from "react";
import { connect } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import MobileHeader from "./MobileHeader";
import Sidebar from "./Sidebar";
import SidebarCustomer from "./SidebarCustomer";
import '../../public/css/providerStyles.css';
import "../../public/css/dashboard.bundle.css";
import "../../public/css/style.css";

const DashboardLayout = (props) => {
  return (
    <div
      id="kt_body"
      className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable page-loading"
    >
      <MobileHeader {...props} />
      <div className="d-flex flex-column flex-root">
        {/* <div className="d-flex flex-row flex-column-fluid page border-right custom_header_bg"> */}
        <div className="d-flex">
          {props.auth.userProfile.userType.userTypeId == 1 ? (
            <>
              <SidebarCustomer {...props} />
            </>
          ) : (
            <>
              <Sidebar {...props} />
            </>
          )}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header {...props} />
            <div className="" style={{ height: "100%" }}>
              {props.children}
            </div>
            {/* <Footer {...props} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
