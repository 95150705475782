import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

export const getDailyShiftDetails = (params, cb) => {
    return (dispatch) => {
        RestClient.get(
            `${EXPRESS_MIDDLEWARE_API_URL}/dailyShiftTypeAvailability/actives/formatted/${params.userProfileId}`,
            {},
            true
        )
            .then((result) => {
                if (result) {
                    let res = {
                        status: true,
                        message: result.message ? result.message : message.success,
                        statusCode: result.status,
                        type: message.success,
                        data: result.data,
                    };
                    cb(res);
                } else {
                    let res = {
                        status: false,
                        message: result.message ? result.message : message.commonError,
                        type: message.error,
                    };
                    cb(res);
                }
            })
            .catch((error) => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error,
                };
                cb(res);
            });
    };
};

export const postDailyShift = (params, cb) => {
    return (dispatch) => {
        RestClient.post(
            `${EXPRESS_MIDDLEWARE_API_URL}/dailyShiftTypeAvailability/set/${params.userProfileId}`,
            params.data,
            true
        )
            .then((result) => {
                if (result.status === 200 && result.data) {
                    let res = {
                        status: true,
                        message: result.message ? result.message : message.success,
                        statusCode: result.status,
                        type: message.success,
                        data: result.data,
                    };
                    cb(res);
                } else {
                    let res = {
                        status: false,
                        statusCode: result.status,
                        message: result.message ? result.message : message.commonError,
                        type: message.error,
                    };
                    cb(res);
                }
            })
            .catch((error) => {
                let res = {
                    status: false,
                    message: message.commonError,
                    type: message.error,
                };
                cb(res);
            });
    };
};