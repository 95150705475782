import axios from "axios";
import { toast } from "react-toastify";
import { userLogOut } from "../app/actions/user";
import Cookies from "universal-cookie";

const queryString = require("query-string");
var RestClient;

const handleErrors = (error) => {

    const cookies = Cookies();
    var status = error?.response?.status;
    var data = error?.response?.data;
    if (
        error?.response?.data?.error === "Invalid token supplied with request." ||
        error?.response?.data?.error ===
        "No identity has been assigned to this client"
    ) {
    }
    switch (status) {
        case 401:    
            let type = window.localStorage.getItem('loginType') == 1 ? 'employer' : 'employee';
            // window.location = '/login/'+type

            throw new Error(data.message);
        case 403:
            throw new Error(data.message);
        default:
        break;
    }
    return Promise.reject(error);

}

axios.interceptors.response.use(function (response) {
    return response;
}, handleErrors);
  
var config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Credential: "same-origin",
    },
};


const post = (url, params , isAuthRequired = false) => {

    if (isAuthRequired) {
        config = { ...config, withCredentials: true };
    }
    return new Promise(function (fulfill, reject) {
        axios
        .post(url, JSON.stringify(params), config)
        .then(function (response) {
            fulfill({ status: response.status, data: response.data });
        })
        .catch(function (error) {
            if (error && error.response) {
            fulfill({
                status: error.response.status,
                data: error.response.data,
            });
            } else {
            reject(error);
            }
        });
    });

}

const get = (url, params , isAuthRequired = false) =>{


    let query = queryString.stringify(params);
    if (isAuthRequired) {

      config = { ...config, withCredentials: true };
    }
    return new Promise(function (fulfill, reject) {
      axios
        .get(url + "" + query, config)

        .then(function (response) {
          fulfill({ status: response.status, data: response.data });
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill({
              status: error.response.status,
              data: error.response.data,
            });
          } else {
            reject(error);
          }
        });
    });
}

const put = (url, params , isAuthRequired = false) => {
    if (isAuthRequired) {
        config = { ...config, withCredentials: true };
    }
    return new Promise(function (fulfill, reject) {
    axios
        .put(url, JSON.stringify(params), config)
        .then(function (response) {
        fulfill(response.data);
        })
        .catch(function (error) {
        if (error && error.response) {
            fulfill({
            status: error.response.status,
            data: error.response.data,
            });
        } else {
            reject(error);
        }
        });
    });
}

const patch = (url, params , isAuthRequired = false) => {
    if (isAuthRequired) {
    config = { ...config, withCredentials: true };
    }
    return new Promise(function (fulfill, reject) {
    axios
        .patch(url, JSON.stringify(params), config)
        .then(function (response) {
        fulfill(response.data);
        })
        .catch(function (error) {
        if (error && error.response) {
            fulfill({
            status: error.response.status,
            data: error.response.data,
            });
        } else {
            reject(error);
        }
        });
    });
}

const postDelete = (url, params , isAuthRequired = false) => {
    if (isAuthRequired) {
        config = { ...config, withCredentials: true };
    }
    return new Promise(function (fulfill, reject) {
    axios
        .delete(url, config)
        .then(function (response) {
        fulfill(response.data);
        })
        .catch(function (error) {
        if (error && error.response) {
            fulfill({
            status: error.response.status,
            data: error.response.data,
            });
        } else {
            reject(error);
        }
        });
    });
}
  
const postFormData = (url, params , isAuthRequired = false) => {
    var configOne = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Credential: "same-origin",
        },
      };
  
      console.log(params,"--------------PARAMS");
  
      if (isAuthRequired) {
        config = { configOne, withCredentials: true };
      }
  
      return new Promise(function (fulfill, reject) {
        axios
          .post(url, params, config)
  
          .then(function (response) {
            fulfill({ status: response.status, data: response.data });
          })
          .catch(function (error) {
            if (error && error.response) {
              fulfill({
                status: error.response.status,
                data: error.response.data,
              });
            } else {
              reject(error);
            }
          });
      });
}

const deleteWithData = (url, isAuthRequired = false, data = {}) => {
    if (isAuthRequired) {
        config = { ...config, withCredentials: true };
    }
    return new Promise(function (fulfill, reject) {
    axios
        .delete(url, { ...config, data: data })
        .then(function (response) {
        fulfill(response.data);
        })
        .catch(function (error) {
        if (error && error.response) {
            fulfill(error.response.data);
        } else {
            reject(error);
        }
        });
    });
}

export default RestClient = {
    post:post,
    get:get,
    put:put,
    patch:patch,
    delete:postDelete,
    postFormData:postFormData,
    deleteWithData:deleteWithData
}   