import React from 'react';
import location from '../public/images/location.svg';
import call_about from '../public/images/call_about.svg';
import mail from '../public/images/mail.svg';
import web from '../public/images/web.svg';


const AboutUs = () => {
  return (
    <section className="healthcase bg-color" id="healthcase">
	    <div className="container">
		    <div className="title">
			    <h2>About Us</h2>
			</div>
            <div className="health aos-item" data-aos="fade-up" id="about_us">
                <div className="soft_detail_detail">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="job_detail">
                                <div className="softwarelist4">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>										
                                </div>
                            </div>
                        </div>
                    </div>
            <div className="row">
                <div className="col-md-12 softwarelist_22">
                    <div className="softwarelist"/>
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="softwarelist softwarelist1">
                                    <p><a href="#"><img src={location} alt=""/> i#102A, Street Road, Tempa, FL, USA</a></p>										
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="softwarelist ">
                                    <p><a href=""><img src={call_about} alt=""/> +1-234-532-0098</a></p>										
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className="col-md-12 softwarelist_22">
                        <div className="softwarelist"/>
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="softwarelist softwarelist1">
                                    <p> <a href="#"><img src={mail} alt=""/> support@timelyhire.com</a></p>										
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="softwarelist">
                                    <p><a href="#"> <img src={web} alt=""/>  https://www.facebook.com/timelyhire</a></p>										
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
	</section>
  )
}

export default AboutUs;