import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getNotificationCount } from "../../actions/user";
import { toast } from "react-toastify";
import notification from "../../public/images/notification.png";
import profile from "../../public/images/profile.png";
import logo_home from '../../public/images/logo_home.png';
import Notification from '../../public/images/Shape.svg'; 
import NotificationModal from "./modal/notificationModal";


const Header = (props) => {
  const history = useHistory();
  const [showNotiModal, setShowNotiModal] = useState(false);
  const [notificationCount, setNotificationCount] = useState("");

  const toggleShow = () => setShowNotiModal(!showNotiModal);

  let userName = "User";
  let emailId = "Email Id";
  if (props.auth.isLoggedIn && props.auth.userProfile) {
    if (
      props.auth.userProfile.userType &&
      props.auth.userProfile.userType.userType == "Admin"
    ) {
      userName = "Admin";
    } else if (
      (props.auth.userProfile.userType &&
        props.auth.userProfile.userType.userType == "Employer") ||
      (props.auth.userProfile.userType &&
        props.auth.userProfile.userType.userType == "Employee")
    ) {
      if (props.auth.userProfile.userName) {
        userName = props.auth.userProfile.userName;
      }
      if (props.auth.userProfile.emailAddress) {
        emailId = props.auth.userProfile.emailAddress;
      }
    }
  }
  let nameFirstLetter = userName.charAt(0).toUpperCase();

  

  const getNotificationCount = () => {
    let params = {
        userIdHash: props.auth.token,
    }
      props.getNotificationCount(params, res => {
        //console.log("res",res);
        if (res.status) {
           let notifcationCount = res.data
           setNotificationCount(notifcationCount);
        }
        else {
          toast.error(res.message);
        }
      },
      err => {
        toast.error('Something went wrong! Please try again later.');
      });
  }


useEffect(() => {
   getNotificationCount();
}, []);


  return (
    <>
    <div
      id="kt_header"
      className="header header-fixed custom_main_bg custom_main_border"
    >
      <div className="container-fluid d-flex align-items-stretch justify-content-between custom_header_bg rounded-top_main">
        <div
          className="header-menu-wrapper header-menu-wrapper-left"
          id="kt_header_menu_wrapper"
        >
          <div
            id="kt_header_menu"
            className="header-menu header-menu-mobile header-menu-layout-default"
          ></div>
          <i className="fa fa-home" aria-hidden="true" style={{position:'absolute', top:'20px', left:'70px', color:'green', fontSize:'25px', cursor:'pointer'}} onClick={(e) => {e.preventDefault(); history.push('/')}}></i>
        </div>
        <div className="topbar">
          <div className="topbar-item">
            <div className="notification_icon">
              <button 
              data-bs-toggle="modal"
              data-bs-target="#notiModal"
              onClick={() => {
                setShowNotiModal(!showNotiModal);
              }}>
              <img src={Notification}  alt="notification"/>
              <span className="notification_count">{notificationCount ? notificationCount : "0"}</span>
              </button>
            </div>
            <div
              className="  btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2 hover_bg_t"
              id="kt_quick_user_toggle"
            >
              {/* <span className="symbol symbol-lg-35 symbol-25 ">
                <span className="notification_top"><img src={notification} /></span>
              </span> */}
            </div>
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
              <div className="userName">{nameFirstLetter}</div>
            </span>
            <div>
              {/* <div className="user_name Gilroy_font2 mr-5 ml-3">{userName}</div> */}
              <div className="user_name Gilroy_font2 mr-5 ml-3">{emailId}</div>
              <div
                className="user_name Gilroy_font2 mr-5 ml-3"
                style={{ fontWeight: "500" }}
              >
                {props.auth?.userProfile?.userCategoryInfo?.name ? (
                  <>
                    <i
                      className="fas fa-briefcase mr-2"
                      style={{ color: "green" }}
                    ></i>{" "}
                    {props.auth?.userProfile?.userCategoryInfo?.name}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {
      showNotiModal ?
      <NotificationModal show={showNotiModal} toggleShow={toggleShow} /> 
      :null
    }
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getNotificationCount: bindActionCreators(getNotificationCount, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
