import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import contact_left from '../../public/images/contact_left.png';
import left_small from '../../public/images/left_small.png';


const Contact = (props) => {
    const history = useHistory();

    return (
        <>
          <div className="content d-flex flex-column flex-column-fluid pt-5 pb-0" id="kt_content">
                  <div className="d-flex flex-column-fluid">
                     <div className="custom-container">
                        <div className="row  pb-5">
                           <div className="col-lg-12 col-md-12">
                              <div className="inner_txecy job_list">
                                 <h4>Settings </h4>
                                 <p><span><img src={left_small} /></span>Contact Us</p>
                              </div>
                           </div>
                        </div>
                        <div className="Profile_form">
                           
                           <div className="row">
                              <div className="col-md-6 col-sm-6">
                                 <div className="left_img5">
                                    <img src={contact_left} />
                                 </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                 <div className="contact_right7">
                                    <h4>Contact us</h4>
                                    <div className="email_outer">
                                       <label>Your Name</label>
                                       <input type="text" placeholder="Enter here" />
                                    </div>
                                    <div className="email_outer">
                                       <label>Email</label>
                                       <input type="text" placeholder="Enter here" />
                                    </div>
                                    <div className="email_outer custom_pu56">
                                       <label>Select Category</label>
                                       <select>
                                          <option selected="">Select</option>
                                       </select>
                                    </div>
                                    <div className="email_outer custom_pu56 dect6">
                                       <label>Describe More</label>
                                       <textarea placeholder="Enter here"></textarea>
                                    </div>
                                    <div className="forgott6">
                                       <button className="btn">Submit</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           
                        </div>
                        
                     </div>
                  </div>
               </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);