import RestClient from "../../../utilities/RestClient";
import message from "../../constants/messages";
import { EXPRESS_MIDDLEWARE_API_URL } from "../../config";

export const deleteResumeCred = (params, cb) => {
  return (dispatch) => {
    RestClient.patch(
      `${EXPRESS_MIDDLEWARE_API_URL}/file/delete/action?fileHashId=${params.fileHashId}&userIdHash=${params.userIdHash}&value=${params.value}`,
      {},
      true
    )
    .then((result) => {
      if (result && result.status !== 400) {
        let res = {
          status: true,
          message: result.message ? result.message : message.success,
          statusCode: result.status,
          type: message.success,
          data: result.data,
        };
        cb(res);
      } else {
        let res = {
          status: false,
          message: result.message ? result.message : message.commonError,
          type: message.error,
        };
        cb(res);
      }
    })
    .catch((error) => {
      let res = {
        status: false,
        message: message.commonError,
        type: message.error,
      };
      cb(res);
    });
  };
};

export const uploadResumeCred = (params, cb) => {
  return (dispatch) => {
    RestClient.postFormData(
      `${EXPRESS_MIDDLEWARE_API_URL}/file/${params.userIdHash}?fileTypeTag=profile_resume&userProfileId=${params.userProfileId}`,
      params.data,
      true
    )
    .then((result) => {
      if (result && result.status !== 400) {
        let res = {
          status: true,
          message: result.message ? result.message : message.success,
          statusCode: result.status,
          type: message.success,
          data: result.data,
        };
        cb(res);
      } else {
        let res = {
          status: false,
          message: result.message ? result.message : message.commonError,
          type: message.error,
        };
        cb(res);
      }
    })
    .catch((error) => {
      let res = {
        status: false,
        message: message.commonError,
        type: message.error,
      };
      cb(res);
    });
  };
};

export const getResumeCred = (params,cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/file/${params.userIdHash}/fileType?fileTypeTag=profile_resume&userProfileId=${params.userProfileId}`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
}

export const getResume = (params,cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/file/${params.userIdHash}/fileType?fileTypeTag=resume`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
}

export const getFileMimeType = (params,cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/fileType/mimeType?fileTag=${params.fileTag}`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
}

export const getSkillBio = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/employee/${params.userIdHash}/bio/${params.userProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getFormFieldsPrespective = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.perspectiveId}/perspective/formFields?userProfileId=${params.userProfileId}`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getFormFieldsEntity = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.entityId}/formFields`,
      {},
      true
    )
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const addSkillBio = (params, cb) => {
  return (dispatch) => {
    RestClient.post(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.perspectiveId}/perspective?userIdHash=${params.userIdHash}&userProfileId=${params.userProfileId}`,
      params.data,
      true
    )
      .then((result) => {
        if (result && result.status !== 400) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const editSkillBio = (params, cb) => {
  return (dispatch) => {
    RestClient.put(
      `${EXPRESS_MIDDLEWARE_API_URL}/entity/${params.entityId}?userIdHash=${params.userIdHash}&userProfileId=${params.userProfileId}`,
      params.data,
      true
    )
      .then((result) => {
        if (result && result.status !== 400) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getAppliedJobs = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/apply/${params.id}/pending`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getShortlistedJobs = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/shortlist/actives/${params.id}`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const unShortlist = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(
      `${EXPRESS_MIDDLEWARE_API_URL}/shortlist?shortlistId=${params.id}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getFollowedJobs = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/follow/actives/${params.id}`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const unFollow = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(
      `${EXPRESS_MIDDLEWARE_API_URL}/follow?followId=${params.id}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const getInvitedJobs = (params, cb) => {
  return (dispatch) => {
    RestClient.get(
      `${EXPRESS_MIDDLEWARE_API_URL}/invite/actives/${params.id}`, {}, true)
      .then((result) => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};

export const unInvite = (params, cb) => {
  return (dispatch) => {
    RestClient.delete(
      `${EXPRESS_MIDDLEWARE_API_URL}/invite?inviteId=${params.id}`, {}, true)
      .then((result) => {
        if (result) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data,
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : message.commonError,
            type: message.error,
          };
          cb(res);
        }
      })
      .catch((error) => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error,
        };
        cb(res);
      });
  };
};