import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { EXPRESS_MIDDLEWARE_API_URL, API_URL } from "../../config";


export const searchEmployer = (params, cb) => {
  return dispatch => {

    var city = params.city,
    country = params.country,
    search = params.search,
    state = params.state,
    zipCode = params.zipCode

    var url = new URL(`${EXPRESS_MIDDLEWARE_API_URL}/main/searchEmployer`);
    if(city){
      url.searchParams.append('City', city); 
    }
    if(country){
      url.searchParams.append('Country', country); 
    }
    if(search){
      url.searchParams.append('search', search); 
    }
    if(state){
      url.searchParams.append('state', state); 
    }
    if(zipCode){
      url.searchParams.append('ZipCode', zipCode); 
    }

    RestClient.get(url
    , {}, true) 
    .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const searchEmployerJobs = (params, cb) => {
  return dispatch => {

    var employerHashName = params.employerHashName

    var url = new URL(`${EXPRESS_MIDDLEWARE_API_URL}/main/jobsEmployer`);
    if(employerHashName){
      url.searchParams.append('userNameHash', employerHashName); 
    }

    RestClient.get(url
    , {}, true) 
    .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


export const getTalents = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/userProfile/list?userIdHash=${params.userIdHash}`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getTalentProfile = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/userProfile/${params.userProfileId}`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getProfession = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/entityType/tops`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getSkill = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/entityType/${params.professionId}/children`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getEmployment = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/employmentType/actives`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getSchedule = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/scheduleType/actives`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getFacility = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/facilityType/actives`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getPayroll = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/payrollType/actives`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getShift = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/shiftType/actives`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getPayment = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/paymentType/actives`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const createTalent = (params, cb) => {
  let payload = params.data ? params.data : {
    employmentTypeIds: params.employmentTypeIds,
    facilityTypeIds: params.facilityTypeIds,
    paymentTypeIds: params.paymentTypeIds,
    payrollTypeIds: params.payrollTypeIds,
    scheduleTypeIds: params.scheduleTypeIds,
    shiftTypeIds: params.shiftTypeIds,
    selectedEntityTypeIds: params.selectedEntityTypeIds,
    entityName: params.entityName,
    about: params.about,
    address: params.address,
    address1: params.address1,
    city: params.city,
    state: params.state,
    zipCode: params.zipCode,
    country: params.country,
    unitId: params.unitId
  }
  return dispatch => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/${params.userTypeId}`, payload, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Profile not created",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const updateTalent = (params, cb) => {
  let payload = params.data ? params.data : {
    employmentTypeIds: params.employmentTypeIds,
    facilityTypeIds: params.facilityTypeIds,
    paymentTypeIds: params.paymentTypeIds,
    payrollTypeIds: params.payrollTypeIds,
    scheduleTypeIds: params.scheduleTypeIds,
    shiftTypeIds: params.shiftTypeIds,
    //   selectedEntityTypeIds: params.selectedEntityTypeIds,
    entityName: params.entityName,
    about: params.about,
    address: params.address,
    address1: params.address1,
    city: params.city,
    state: params.state,
    zipCode: params.zipCode,
    country: params.country,
    unitId: params.unitId
  }
  return dispatch => {
    RestClient.put(`${EXPRESS_MIDDLEWARE_API_URL}/accountSetup/${params.userProfileId}`, payload, true)
      .then(result => {
        if (result.userProfileInfo) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "Profile not updated",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getDaysList = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/weeklyShiftTypeAvailability/daysList`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const getActiveDaysList = (params, cb) => {
  return dispatch => {
    RestClient.get(`${EXPRESS_MIDDLEWARE_API_URL}/weeklyShiftTypeAvailability/actives/${params.userProfileId}`, {}, true)
      .then(result => {
        if (result.status === 200 && result.data) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const updateWeeklyShiftDataForEmptyActiveDaysList = (params, cb) => {
  let payload = params.payload
  return dispatch => {
    RestClient.post(`${EXPRESS_MIDDLEWARE_API_URL}/weeklyShiftTypeAvailability/set/${params.userProfileId}`, payload, true)
      .then(result => {
        if (result.status === 200 || result.data || result.length) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

export const updateWeeklyShiftDataForActiveDaysList = (params, cb) => {
  let payload = params.payload
  return dispatch => {
    RestClient.put(`${EXPRESS_MIDDLEWARE_API_URL}/weeklyShiftTypeAvailability/update/${params.userProfileId}`, payload, true)
      .then(result => {
        console.log('Result', result)
        if (result.status === 200 || result.data || result.length) {
          let res = {
            status: true,
            message: result.message ? result.message : message.success,
            statusCode: result.status,
            type: message.success,
            data: result.data
          };
          cb(res);
        } else {
          let res = {
            status: false,
            message: result.message ? result.message : "No Records Found",
            type: message.error
          };
          cb(res);
        }
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

