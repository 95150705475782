import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  userLogin,
  userLogOut,
  createEmployer,
  createEmployee,
  forgotPassword,
  resetPassword,
  getAllCategories,
  verifyEmail,
  verifyPhone,
  getUserInformation,
  updateUserInformation,
  addUserInformation,
  getUserTypeList,
} from "../../actions/user";
import { loginSuccess, logout } from "../../../redux/actions/auth";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import PlacesAutocomplete, {
  geocodeByAddress,
  getStateBounds,
} from "react-places-autocomplete";
import talent_edit from "../../public/images/talent_edit.png";
import Home from "../home";
import logo_home from "../../public/images/logo_home.png";
import SignInIcon from "../../public/images/sign_in_icon4.png";
import SignInIcon2 from "../../public/images/sign_in_icon1.png";
import SignInIcon3 from "../../public/images/sign_in_icon.png";
import SignInIcon4 from "../../public/images/sign_in_icon2.png";
import CarouselComp from "../home/carousel";
import FindTalent from "../home/findTalent";
import WhyUs from "../home/whyUs";
import Numbers from "../home/numbers";
import WhyChooseUs from "../home/whyChooseUs";
import WorkYourWay from "../home/workYourWay";
import WhyEasy from "../home/whyEasy";
import LandingFooter from "../../components/shared/LandingFooter";
import "../../public/css/landingStyle.css";
import "../../public/css/landingResponsive.css";
import "../../public/css/landingLogin-1.css";

const cookies = new Cookies();

const Login = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState();
  const [emailOtp, setEmailOtp] = useState();
  const [phoneOtp, setPhoneOtp] = useState();
  const [firstNameCus, setFirstNameCus] = useState("");
  const [lastNameCus, setLastNameCus] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phoneNumCus, setPhoneNumCus] = useState("");
  const [countryCodeCus, setCountryCodeCus] = useState("");
  const [countryISOCodeCus, setCountryISOCodeCus] = useState("");
  const [isTermConditionChecked, setIsTermConditionChecked] = useState(false);
  const [showPasswordClicked, setShowPasswordClicked] = useState(false);
  const [showLoginPasswordClicked, setShowLoginPasswordClicked] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCreateCus, setLoadingCreateCus] = useState(false);
  const [loadingForgotPassword, setLoadingForgotPassword] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const [loadingVerifyEmail, setLoadingVerifyEmail] = useState(false);
  const [loadingVerifyPhone, setLoadingVerifyPhone] = useState(false);
  const [isLoginScreen, setIsLoginScreen] = useState(true);
  const [isSignUpScreen, setIsSignUpScreen] = useState(false);
  const [isForgotPasswordScreen, setIsForgotPasswordScreen] = useState(false);
  const [isResetPasswordScreen, setIsResetPasswordScreen] = useState(false);
  const [isVerifyEmailScreen, setIsVerifyEmailScreen] = useState(false);
  const [isVerifyPhoneScreen, setIsVerifyPhoneScreen] = useState(false);
  const [isEmployer, setIsEmployer] = useState(false);
  const [categories, setCategories] = useState([]);
  const [userCategoryID, setUserCategoryId] = useState("");
  const [newUserIdHash, setNewUserIdHash] = useState();
  const [newUserInformation, setNewUserInformation] = useState();

  const [loginInformation, setLoginInformation] = useState();

  const [isUserAnEmployer, setIsUserAnEmployer] = useState(false);
  const [userIdHash, setUserIdHash] = useState();
  const [userInformationId, setUserInformationId] = useState("");
  const [loadingEditUserInfo, setLoadingEditUserInfo] = useState(false);

  const [isNewSignUp, setIsNewSignUp] = useState(false);

  const [openUserProfile, setOpenUserProfile] = useState("");

  const [userInformationData, setUserInformationData] = useState();

  const [isUserInformationScreen, setIsUserInformationScreen] = useState(false);
  const [isEditUserInformationScreen, setIsEditUserInformationScreen] =
    useState(false);
  const [firstNameUserInfo, setFirstNameUserInfo] = useState("");
  const [lastNameUserInfo, setLastNameUserInfo] = useState("");
  const [businessNameUserInfo, setBusinessNameUserInfo] = useState("");
  const [addressUserInfo, setAddressUserInfo] = useState("");
  const [address1UserInfo, setAddress1UserInfo] = useState("");
  const [zipCodeUserInfo, setZipCodeUserInfo] = useState("");
  const [cityUserInfo, setCityUserInfo] = useState("");
  const [stateUserInfo, setStateUserInfo] = useState("");
  const [countryUserInfo, setCountryUserInfo] = useState("");
  const [businessType, setBusinessType] = useState("");

  const [userTypeId, setUserTypeId] = useState();
  const [employerFlag, setEmployerFlag] = useState();
  const [employeeFlag, setEmployeeFlag] = useState();
  const [userTypeData, setUserTypeData] = useState([]);
  const [userType, setUserType] = useState();
  const [loginFlag, setLoginFlag] = useState(false);

  const onChangeInput = (e, fieldName) => {
    const value = e.target.value;
    if (fieldName === "userName") {
      setEmail(value.trim());
    } else if (fieldName === "password") {
      setPassword(value);
    } else if (fieldName === "forgotEmail") {
      setEmail(value.trim());
    } else if (fieldName === "businessName") {
      setBusinessName(value.trim());
    } else if (fieldName === "otp") {
      setOtp(value);
    } else if (fieldName === "confirmPassword") {
      setConfirmPassword(value);
    } else if (fieldName === "firstNameCus") {
      setFirstNameCus(value);
    } else if (fieldName === "lastNameCus") {
      setLastNameCus(value);
    } else if (fieldName === "phoneNumCus") {
      setPhoneNumCus(value);
    } else if (fieldName === "countryCode") {
      let arr = value.split("-");
      setCountryISOCodeCus(arr[0]);
      setCountryCodeCus(arr[1]);
    } else if (fieldName === "termCondition") {
      setIsTermConditionChecked(!isTermConditionChecked);
    } else if (fieldName === "userTypeId") {
      setIsEmployer(!isEmployer);
    } else if (fieldName === "categoryId") {
      setUserCategoryId(value);
    } else if (fieldName === "verifyEmail") {
      setEmailOtp(value);
    } else if (fieldName === "verifyPhone") {
      setPhoneOtp(value);
    } else if (fieldName === "firstNameUserInfo") {
      setFirstNameUserInfo(value);
    } else if (fieldName === "lastNameUserInfo") {
      setLastNameUserInfo(value);
    } else if (fieldName === "businessNameUserInfo") {
      setBusinessNameUserInfo(value);
    } else if (fieldName === "addressUserInfo") {
      setAddressUserInfo(value);
    } else if (fieldName === "address1UserInfo") {
      setAddress1UserInfo(value);
    } else if (fieldName === "cityUserInfo") {
      setCityUserInfo(value);
    } else if (fieldName === "stateUserInfo") {
      setStateUserInfo(value);
    } else if (fieldName === "zipCodeUserInfo") {
      setZipCodeUserInfo(value);
    } else if (fieldName === "countryUserInfo") {
      setCountryUserInfo(value);
    }
  };

  const autoCompleteHandleChange = (address) => {
    setAddressUserInfo(address);
  };

  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        document.getElementById("searchTextField").blur();
        setTimeout(() => {
          document.getElementById("searchTextField").value = "";
        }, 10);
        fillAddressDetails(results);
      })
      .catch((error) => {});
  };

  const google = (window.google = window.google ? window.google : {});

  const searchOptions = {
    // location: new this.google.maps.LatLng(51.509865, -0.118092),
    // radius: 424000,
    //types: ["address", "uk"],
    componentRestrictions: { country: "" },
  };

  const fillAddressDetails = (results) => {
    setAddressUserInfo(results[0].formatted_address);
    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        setZipCodeUserInfo(results[0].address_components[j].short_name);
      } else if (results[0].address_components[j].types[0] == "locality") {
        setCityUserInfo(results[0].address_components[j].long_name);
      } else if (
        results[0].address_components[j].types[0] ==
        "administrative_area_level_1"
      ) {
        setStateUserInfo(results[0].address_components[j].long_name);
      } else if (results[0].address_components[j].types[0] == "country") {
        setCountryUserInfo(results[0].address_components[j].long_name);
      }
    }
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    setIsLoginScreen(false);
    setIsSignUpScreen(false);
    setIsResetPasswordScreen(false);
    setIsForgotPasswordScreen(true);
  };

  const signUpScreen = (e) => {
    setIsLoginScreen(false);
    setIsSignUpScreen(true);
    setIsForgotPasswordScreen(false);
    setIsResetPasswordScreen(false);
    setIsEmployer(false);
    setBusinessName();
    setIsTermConditionChecked(false);
  };

  const backToLogin = (e) => {
    setEmail("");
    setIsLoginScreen(true);
    setIsSignUpScreen(false);
    setIsForgotPasswordScreen(false);
    setIsResetPasswordScreen(false);
    setShowLoginPasswordClicked(false);
    setIsNewSignUp(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter user name or email address");
      return;
    } else if (!password) {
      toast.error("Please enter the password");
      return;
    }

    let emailValidation = validateEmail(email);
    if (!emailValidation) {
      toast.error("Please enter a valid email");
      return;
    }


    // cookies.remove('JSESSIONID',{ path: '/login' })
    // cookies.remove('JSESSIONID',{ path: '/' })

    let params = {
      userNameOrEmailAddress: email,
      password: password,
      userTypeId: userTypeId,
    };
    setLoading(true);
    props.userLogin(
      params,
      (res) => {
        // setLoading(false);
        if (res.status) {
          let userData = {
            userProfile: {
              ...res.data.userInfo,
              userType: res.data.userType,
              userCategoryInfo: res.data.userTypeCategoryInfo,
            },
            token: res.data.userInfo.userIdHash,
          };
          cookies.set(
            res.data.userType.sessionIdName,
            res.data.userType.sessionId
          );
          props.onLoginSuccess(userData);

          setUserIdHash(res.data.userInfo.userIdHash);
          if (res.data?.userType?.userTypeId == 1) {
            setBusinessType(res.data?.userTypeCategoryInfo?.name);
            setIsUserAnEmployer(true);
            setOpenUserProfile(
              res.data?.userTypeCategoryInfo?.isOpenUserProfile
            );
          }

          setLoginInformation(res.data);

          if (res.data?.userType?.userTypeId == 1) {
            if (
              res.data?.userInformationInfo?.firstName == null ||
              res.data?.userInformationInfo?.lastName == null ||
              res.data?.userInformationInfo?.entityName == null
            ) {
              setIsLoginScreen(false);
              props.getUserInformation({}, (res) => {
                console.log("USER INFO RES", res);
                if (res.status) {
                  setUserInformationData(res.data);
                  setUserInformationId(res.data?.id);
                  setFirstNameUserInfo(res.data?.firstName);
                  setLastNameUserInfo(res.data?.lastName);
                  setBusinessNameUserInfo(res.data?.entityName);
                  setAddressUserInfo(res.data?.address);
                  setAddress1UserInfo(res.data?.address1);
                  setCityUserInfo(res.data?.city);
                  setStateUserInfo(res.data?.state);
                  setCountryUserInfo(res.data?.country);
                  setZipCodeUserInfo(res.data?.zipCode);
                  setLoading(false);
                  setIsLoginScreen(false);
                  setIsUserInformationScreen(true);
                }
              });
            } else {
              history.push("/home");
            }
          } else {
            if (
              res.data?.userInformationInfo?.firstName == null ||
              res.data?.userInformationInfo?.lastName == null
            ) {
              setIsLoginScreen(false);
              props.getUserInformation({}, (res) => {
                console.log("USER INFO RES", res);
                if (res.status) {
                  setUserInformationData(res.data);
                  setUserInformationId(res.data?.id);
                  setFirstNameUserInfo(res.data?.firstName);
                  setLastNameUserInfo(res.data?.lastName);
                  setBusinessNameUserInfo(res.data?.entityName);
                  setAddressUserInfo(res.data?.address);
                  setAddress1UserInfo(res.data?.address1);
                  setCityUserInfo(res.data?.city);
                  setStateUserInfo(res.data?.state);
                  setCountryUserInfo(res.data?.country);
                  setZipCodeUserInfo(res.data?.zipCode);
                  setLoading(false);
                  setIsLoginScreen(false);
                  setIsUserInformationScreen(true);
                }
              });
            } else {
              history.push("/home");
            }
          }
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const handleSignUp = (e) => {
    e.preventDefault();

    var letters = /^[A-Za-z]+$/;

    if (!firstNameCus.match(letters)) {
      toast.error("First Name must contain only alphabets");
      return;
    }

    if (!lastNameCus.match(letters)) {
      toast.error("Last Name must contain only alphabets");
      return;
    }

    let emailValidation = validateEmail(email);
    if (!emailValidation) {
      toast.error("Please enter a valid email");
      return;
    }

    if (isEmployer) {
      if (businessName == null || businessName == undefined) {
        toast.error("Please enter business name");
        return;
      }
      setIsUserAnEmployer(true);
    }

    if (confirmPassword !== password) {
      toast.error("Confirm Password must be equal to Password");
      return;
    }

    if (!isTermConditionChecked) {
      toast.error(
        "Please agree to our terms and conditions to proceed further."
      );
      return;
    }

    let params = {};

    if (isEmployer) {
      params = {
        countryAbbr: countryISOCodeCus,
        firstName: firstNameCus,
        lastName: lastNameCus,
        userEmailAddress: email,
        entityName: businessName,
        password: password,
        phoneNumber: countryCodeCus + phoneNumCus,
        // phoneNumber: phoneNumCus,
        userTypeId: 1,
        userTypeCategoryId: userCategoryID,
      };

      setLoadingCreateCus(true);

      props.createEmployer(
        params,
        (res) => {
          setLoadingCreateCus(false);
          console.log("SIGN UP RES", res);
          if (res.status) {
            setNewUserInformation(res.data);
            let userData = {
              userProfile: {
                ...res.data?.userInfo,
                userType: res.data?.userType,
                userCategoryInfo: res.data?.userTypeCategoryInfo,
              },
              token: res.data?.userInfo?.userIdHash,
            };
            cookies.set(
              res.data?.userType?.sessionIdName,
              res.data?.userType?.sessionId
            );
            props.onLoginSuccess(userData);

            if (res.data) {
              setNewUserIdHash(res.data?.userInfo?.userIdHash);
              setUserInformationId(
                res.data.userInformationInfo.userInformationId
              );
            }

            setIsNewSignUp(true);
            setFirstNameUserInfo(res.data?.userInformationInfo?.firstName);
            setLastNameUserInfo(res.data?.userInformationInfo.lastName);
            setBusinessNameUserInfo(res.data?.userInformationInfo.entityName);
            setOpenUserProfile(
              res.data?.userTypeCategoryInfo?.isOpenUserProfile
            );
            setBusinessType(res.data?.userTypeCategoryInfo?.name);

            // props.getUserInformation({userProfileId: res.data.userInformationInfo.userInformationId}, res => {
            //   console.log('USER INFO RES', res)
            //   if (res.status) {
            //     setIsSignUpScreen(false)
            //     setIsVerifyEmailScreen(true)
            //     if (res.data == "" || res.data == undefined) {
            //       setUserInformationData(res.data)
            //     } else {
            //       setUserInformationData(res.data)
            //     }
            //   }
            // })

            setIsSignUpScreen(false);
            setIsVerifyEmailScreen(true);
          } else {
            let message = "Something went wrong!";
            if (res.message) {
              message = res.message;
            }
            toast.error(message);
          }
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong!");
        }
      );
    } else {
      params = {
        countryAbbr: countryISOCodeCus,
        firstName: firstNameCus,
        lastName: lastNameCus,
        userEmailAddress: email,
        password: password,
        phoneNumber: countryCodeCus + phoneNumCus,
        userTypeId: 0,
      };

      setLoadingCreateCus(true);
      props.createEmployee(
        params,
        (res) => {
          setLoadingCreateCus(false);
          console.log("SIGN UP RES", res);
          if (res.status) {
            setNewUserInformation(res.data);
            let userData = {
              userProfile: {
                ...res.data?.userInfo,
                userType: res.data?.userType,
              },
              token: res.data?.userInfo?.userIdHash,
            };
            cookies.set(
              res.data?.userType?.sessionIdName,
              res.data?.userType?.sessionId
            );
            props.onLoginSuccess(userData);

            if (res.data) {
              setNewUserIdHash(res.data?.userInfo?.userIdHash);
            }

            setIsSignUpScreen(false);
            setIsVerifyEmailScreen(true);
            setIsNewSignUp(true);
            setFirstNameUserInfo(res.data?.userInformationInfo?.firstName);
            setLastNameUserInfo(res.data?.userInformationInfo.lastName);
          } else {
            let message = "Something went wrong!";
            if (res.message) {
              message = res.message;
            }
            toast.error(message);
          }
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong!");
        }
      );
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter user name or email address");
      return;
    }

    let emailValidation = validateEmail(email);
    if (!emailValidation) {
      toast.error("Please enter a valid email");
      return;
    }

    let params = {
      userNameOrEmailAddress: email,
    };
    setLoadingForgotPassword(true);
    props.forgotPassword(
      params,
      (res) => {
        setLoadingForgotPassword(false);
        if (res.status) {
          swal({
            title:
              "An OTP sent to your email. Please use the OTP to reset your password",
            text: "",
            icon: "success",
          }).then((onUpdate) => {
            e.preventDefault();
            setPassword("");
            setIsResetPasswordScreen(true);
            setIsForgotPasswordScreen(false);
          });
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        setLoadingForgotPassword(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (!otp) {
      toast.error("Please enter OTP");
      return;
    } else if (!password) {
      toast.error("Please enter password");
      return;
    } else if (!confirmPassword) {
      toast.error("Please enter confirm password");
      return;
    }

    if (confirmPassword !== password) {
      toast.error("Confirm Password must be same as New Password");
      return;
    }

    let params = {
      otp: otp,
      newPassword: password,
      userNameOrEmailAddress: email,
    };
    setLoadingResetPassword(true);
    props.resetPassword(
      params,
      (res) => {
        setLoadingResetPassword(false);
        if (res.status) {
          swal({
            title:
              "Password reset successfully! Use the new password to login.",
            text: "",
            icon: "success",
          }).then((onUpdate) => {
            setIsLoginScreen(true);
            setIsResetPasswordScreen(false);
            setEmail("");
            setPassword("");
          });
        } else {
          let message = "Something went wrong!";
          if (res.message) {
            message = res.message;
          }
          toast.error(message);
        }
      },
      (err) => {
        setLoadingResetPassword(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const handleVerifyEmail = (e) => {
    e.preventDefault();
    if (!emailOtp) {
      toast.error("Please enter OTP");
      return;
    }

    let params = {
      userIdHash: newUserIdHash,
      code: emailOtp,
    };

    setLoadingVerifyEmail(true);
    props.verifyEmail(params, (res) => {
      setLoadingVerifyEmail(false);
      if (res.status) {
        toast.success("Email verified successfully");
        let userData = {};
        if (isEmployer) {
          userData = {
            userProfile: {
              ...newUserInformation.userInfo,
              userType: newUserInformation.userType,
              userCategoryInfo: newUserInformation.userTypeCategoryInfo,
            },
            token: newUserInformation.userInfo.userIdHash,
          };
        } else {
          userData = {
            userProfile: {
              ...newUserInformation.userInfo,
              userType: newUserInformation.userType,
            },
            token: newUserInformation.userInfo.userIdHash,
          };
        }

        cookies.set(
          newUserInformation.userType.sessionIdName,
          newUserInformation.userType.sessionId
        );
        console.log("USER DATA", userData);
        props.onLoginSuccess(userData);
        setIsVerifyEmailScreen(false);
        setIsVerifyPhoneScreen(true);
      } else {
        let message = "Something went wrong!";
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    });
  };

  const handleVerifyPhone = (e) => {
    e.preventDefault();
    if (!phoneOtp) {
      toast.error("Please enter OTP");
      return;
    }

    let params = {
      userIdHash: newUserIdHash,
      code: phoneOtp,
    };

    setLoadingVerifyPhone(true);
    props.verifyPhone(params, (res) => {
      if (res.status) {
        setPassword("");
        setEmail("");
        setEmailOtp();
        setPhoneOtp();
        //setNewUserIdHash()

        // setIsLoginScreen(true)
        toast.success("Phone verified successfully");
        let userData = {};

        if (isEmployer) {
          userData = {
            userProfile: {
              ...newUserInformation.userInfo,
              userType: newUserInformation.userType,
              userCategoryInfo: newUserInformation.userTypeCategoryInfo,
            },
            token: newUserInformation.userInfo.userIdHash,
          };
        } else {
          userData = {
            userProfile: {
              ...newUserInformation.userInfo,
              userType: newUserInformation.userType,
            },
            token: newUserInformation.userInfo.userIdHash,
          };
        }

        cookies.set(
          newUserInformation.userType.sessionIdName,
          newUserInformation.userType.sessionId
        );
        console.log("USER DATA", userData);
        props.onLoginSuccess(userData);

        props.getUserInformation({}, (res) => {
          console.log("USER INFO RES", res);
          if (res.status) {
            setIsVerifyPhoneScreen(false);
            setLoadingVerifyPhone(false);
            setUserInformationData(res.data);
            setLoading(false);
            setIsLoginScreen(false);
            setIsVerifyPhoneScreen(false);
            setIsUserInformationScreen(true);
          }
        });
      } else {
        let message = "Something went wrong!";
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    });
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const getAllCategoriesData = () => {
    props.getAllCategories({ userTypeId: 1 }, (res) => {
      if (res.status) {
        if (res.data?.length) {
          setCategories(res.data);
          setUserCategoryId(res.data[0].id);
        }
      }
    });
  };

  const showLoginPassword = () => {
    setShowLoginPasswordClicked(!showLoginPasswordClicked);
    var x = document.getElementById("login-password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const showPassword = () => {
    setShowPasswordClicked(!showPasswordClicked);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const continueToNewTalent = (e) => {
    e.preventDefault();
    if (isNewSignUp) {
      let userData = {};
      if (isEmployer) {
        userData = {
          userProfile: {
            ...newUserInformation.userInfo,
            userType: newUserInformation.userType,
            userCategoryInfo: newUserInformation.userTypeCategoryInfo,
          },
          token: newUserInformation.userInfo.userIdHash,
        };
      } else {
        userData = {
          userProfile: {
            ...newUserInformation.userInfo,
            userType: newUserInformation.userType,
          },
          token: newUserInformation.userInfo.userIdHash,
        };
      }

      cookies.set(
        newUserInformation.userType.sessionIdName,
        newUserInformation.userType.sessionId
      );
      props.onLoginSuccess(userData);
    }

    if (isUserAnEmployer) {
      if (openUserProfile == "0") {
        if (
          addressUserInfo == "" ||
          cityUserInfo == "" ||
          stateUserInfo == "" ||
          countryUserInfo == "" ||
          businessNameUserInfo == null ||
          addressUserInfo == null ||
          cityUserInfo == null ||
          stateUserInfo == null ||
          countryUserInfo == null
        ) {
          toast.error(
            "Please edit your primary information and fill in the required fields to continue"
          );
          return;
        } else {
          history.push("/talent", { fromLogin: true });
        }
      } else {
        if (
          businessNameUserInfo == "" ||
          addressUserInfo == "" ||
          cityUserInfo == "" ||
          stateUserInfo == "" ||
          countryUserInfo == "" ||
          businessNameUserInfo == null ||
          addressUserInfo == null ||
          cityUserInfo == null ||
          stateUserInfo == null ||
          countryUserInfo == null
        ) {
          toast.error(
            "Please edit your primary information and fill in the required fields to continue"
          );
          return;
        } else {
          history.push("/talent", { fromLogin: true });
        }
      }
    } else {
      if (
        firstNameUserInfo == "" ||
        lastNameUserInfo == "" ||
        addressUserInfo == "" ||
        cityUserInfo == "" ||
        stateUserInfo == "" ||
        countryUserInfo == "" ||
        addressUserInfo == null ||
        cityUserInfo == null ||
        stateUserInfo == null ||
        countryUserInfo == null
      ) {
        toast.error(
          "Please edit your primary information and fill in the required fields to continue"
        );
        return;
      } else {
        history.push("/skills", { fromLogin: true });
      }
    }
  };

  const saveUserInformation = (e) => {
    e.preventDefault();
    if (isNewSignUp) {
      let userData = {};
      if (isEmployer) {
        userData = {
          userProfile: {
            ...newUserInformation.userInfo,
            userType: newUserInformation.userType,
            userCategoryInfo: newUserInformation.userTypeCategoryInfo,
          },
          token: newUserInformation.userInfo.userIdHash,
        };
      } else {
        userData = {
          userProfile: {
            ...newUserInformation.userInfo,
            userType: newUserInformation.userType,
          },
          token: newUserInformation.userInfo.userIdHash,
        };
      }

      cookies.set(
        newUserInformation.userType.sessionIdName,
        newUserInformation.userType.sessionId
      );
      props.onLoginSuccess(userData);
    }

    if (isUserAnEmployer || isEmployer) {
      if (businessNameUserInfo == "") {
        toast.error("Please enter your business name to continue");
        return;
      }
    } else {
      if (firstNameUserInfo == "" || lastNameUserInfo == "") {
        toast.error("Please enter your first name and last name to continue");
        return;
      }
    }

    let params = {
      data: {
        address: addressUserInfo,
        address1: address1UserInfo ? address1UserInfo : addressUserInfo,
        city: cityUserInfo,
        country: countryUserInfo,
        countryAbbr: "IN",
        entityName: businessNameUserInfo,
        firstName: firstNameUserInfo,
        id: 0,
        isDefault: 0,
        isValidated: 0,
        isVendorManaged: 0,
        isVerified: 0,
        lastName: lastNameUserInfo,
        middleName: "MN",
        phoneNumber: "6666666666",
        state: stateUserInfo,
        stateAbbr: "SA",
        unitId: "Unit Id",
        uploadDirectory: "UD",
        zipCode: zipCodeUserInfo,
      },
      userInformationId: userInformationId,
      userIdHash: isNewSignUp ? newUserIdHash : userIdHash,
      token: isNewSignUp ? newUserIdHash : userIdHash,
    };

    setLoadingEditUserInfo(true);

    props.updateUserInformation(params, (res) => {
      if (res.status) {
        setLoadingEditUserInfo(false);
        setIsEditUserInformationScreen(false);
        setIsUserInformationScreen(true);
      }
    });
  };

  const openEditUserInfoScreen = () => {
    setIsUserInformationScreen(false);
    setIsEditUserInformationScreen(true);
  };

  const backToUserInfoScreen = () => {
    setIsUserInformationScreen(true);
    setIsEditUserInformationScreen(false);
  };

  const logout = () => {
    
  
    props.userLogOut(
    {},
    (res) => {
      if (res.status) {
          
      } else {
        let message = "Something went wrong!";
        if (res.message) {
          message = res.message;
        }
        toast.error(message);
      }
    },
    (err) => {
      toast.error("Something went wrong!");
    }
    );
  }

  useEffect(() => {
    setLoginFlag(true)
    getListOfUserTypes();
    if (window.location.pathname === "/login/employer") {
      selectedUser("", "employer");
      setLoginFlag(true);
      setIsLoginScreen(true)
      setIsSignUpScreen(false)
    } else if (window.location.pathname === "/signup/employer") {
      selectedUser("", "employer");
      setLoginFlag(true);
      setIsSignUpScreen(true)
      setIsLoginScreen(false)
    } else if (window.location.pathname === "/login/employee") {
      selectedUser("", "employee");
      setLoginFlag(true);
      setIsLoginScreen(true)
      setIsSignUpScreen(false)
    } else if (window.location.pathname === "/signup/employee") {
      selectedUser("", "employee");
      setLoginFlag(true);
      setIsSignUpScreen(true)
      setIsLoginScreen(false)
    }
    // props.userLogOut({}, (res) => {
    //   console.log(res);
    // });
    getAllCategoriesData();
    setCountryCodeCus("+1");
    setCountryISOCodeCus("US");
    // setIsUserInformationScreen(false);
    // setIsEditUserInformationScreen(false);
    // setIsLoginScreen(false)
    // setIsSignUpScreen(false)
    // setIsVerifyEmailScreen(false)
    // setIsVerifyPhoneScreen(false)
    // setIsForgotPasswordScreen(false)
    // setIsResetPasswordScreen(false)
    logout()

  }, []);

  const getListOfUserTypes = () => {
    props.getUserTypeList({}, (res) => {
      if (res.status) {
        console.log("User Types", res);
        setUserTypeData(res.data);
        if (window.location.pathname === "/login/employer") {
          setLoginFlag(true);
          setEmployerFlag(true);
          res.data.map((user) => {
            if (user.name.toLowerCase() === "employer") {
              setUserTypeId(user.id);
              setUserType("employer");
            }
          });
        } else if (window.location.pathname === "/login/employee") {
          setLoginFlag(true);
          setEmployeeFlag(true);
          res.data.map((user) => {
            if (user.name.toLowerCase() === "employee") {
              setUserTypeId(user.id);
              setUserType("employee");
            }
          });
        }
      } else {
        setUserTypeData([]);
      }
    });
  };

  const employerActiveStyle = () => {
    return {
      width: "100%",
      height: "170px",
      padding: "27px 20px",
      textAlign: "center",
      borderRadius: "6px",
      border: "1px solid green",
      cursor: "pointer",
    };
  };

  const employerInActiveStyle = () => {
    return {
      width: "100%",
      height: "170px",
      padding: "27px 20px",
      textAlign: "center",
      borderRadius: "6px",
      border: "1px solid gainsboro",
      cursor: "pointer",
    };
  };

  const selectedUser = (e, flag) => {
    if (e) {
      e.preventDefault();
    }
    if (flag === "employer") {
      setEmployerFlag(true);
      setEmployeeFlag(false);
      userTypeData.map((user) => {
        if (user.name.toLowerCase() === "employer") {
          setUserTypeId(user.id);
          setUserType("employer");
        }
      });
    } else if (flag === "employee") {
      setEmployeeFlag(true);
      setEmployerFlag(false);
      userTypeData.map((user) => {
        if (user.name.toLowerCase() === "employee") {
          setUserTypeId(user.id);
          setUserType("employee");
        }
      });
    }
  };

  const continueToLoginPage = (e) => {
    e.preventDefault();
    if (!employerFlag && !employeeFlag) {
      toast.error("Please select an user type first");
      return;
    }
    setLoginFlag(true);
    setIsLoginScreen(true);
    setIsSignUpScreen(false);
    setTimeout(() => {
      history.push("/login/" + userType);
      selectedUser(e, userType);
    }, 500);
  };

  const continueToSignupPage = (e) => {
    e.preventDefault();
    if (!employerFlag && !employeeFlag) {
      toast.error("Please select an user type first");
      return;
    }
    setLoginFlag(true);
    setIsSignUpScreen(true);
    setIsLoginScreen(false);
    setTimeout(() => {
      history.push("/login/" + userType);
      selectedUser(e, userType);
    }, 500);
  };

  return (
    <>
      <main className="login-page">
              <div className="container" style={{ height: "auto" }}>
                <div className="row justify-content-center py-5">
                  <div className="col-lg-5">
                    <div id="accountDetails mb-5">
                      {/* <!-- Start Login Page --> */}
                      {isLoginScreen ? (
                        <>
                          <div
                            className="card loginContainer login_page"
                            style={{
                              height: "100%",
                              marginTop: "4px",
                              border: "none",
                            }}
                          >
                            <div className="card-body p-0">
                              <div className="content_container2">
                                <h3 className="subheading_">Welcome !</h3>
                                <p
                                  className="subtext_"
                                  style={{ color: "black" }}
                                >
                                  Sign In as{" "}
                                  {employerFlag ? "Employer" : "Employee"}{" "}
                                  <br />
                                  <span>
                                    or{" "}
                                    <a
                                      onClick={() => signUpScreen()}
                                      className=""
                                      style={{
                                        cursor: "pointer",
                                        color: "#3abd00",
                                      }}
                                    >
                                      Create an account
                                    </a>
                                  </span>
                                </p>
                              </div>
                              <form
                                className="form_horizontal row"
                                role="form"
                                onSubmit={handleLogin}
                              >
                                <div className="form-group mt-3 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="inputEmailAddress"
                                  >
                                    Email<span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={email}
                                    onChange={(e) => {
                                      onChangeInput(e, "userName");
                                    }}
                                    className="form-control login-signup-screen"
                                    id="inputEmailAddress"
                                    placeholder=""
                                    name="email"
                                    required
                                    style={{ color: "black" }}
                                  />
                                </div>
                                <div className="form-group mb-2 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="inputPassword"
                                  >
                                    Password<span className="required">*</span>
                                  </label>
                                  <input
                                    id="login-password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => {
                                      onChangeInput(e, "password");
                                    }}
                                    className="form-control id_password login-signup-screen"
                                    placeholder=""
                                    name="password"
                                    autoComplete="current-password"
                                    required
                                    style={{ color: "black" }}
                                  />
                                  <span className="toggle_password_ info_icon">
                                    {/* <span className="show-icon togglePassword" id=""></span> */}
                                    {showLoginPasswordClicked ? (
                                      <>
                                        <i
                                          className="fa fa-eye-slash"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "20px",
                                            color: "#3abd00",
                                            position: "relative",
                                            top: "-15px",
                                            right: "10px",
                                          }}
                                          onClick={showLoginPassword}
                                        ></i>
                                      </>
                                    ) : (
                                      <>
                                        <i
                                          className="fa fa-eye"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "20px",
                                            color: "#3abd00",
                                            position: "relative",
                                            top: "-15px",
                                            right: "10px",
                                          }}
                                          onClick={showLoginPassword}
                                        ></i>
                                      </>
                                    )}
                                  </span>
                                </div>
                                <div
                                  className="form-group mb-4 col-md-12 mt-5"
                                  style={{ textAlign: "end" }}
                                >
                                  <span
                                    className="forgot_password_"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <a
                                      onClick={(e) => {
                                        forgotPassword(e);
                                      }}
                                    >
                                      Forgot Password?
                                    </a>
                                  </span>
                                </div>
                                <div className="form-group text-center col-md-12">
                                  {/* <button className="primary_btn_" type="submit">Login</button> */}
                                  <button
                                    className="primary_btn_"
                                    disabled={loading}
                                    type="submit"
                                  >
                                    {loading ? (
                                      <>
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      </>
                                    ) : (
                                      <>Login</>
                                    )}
                                  </button>
                                </div>
                                <div
                                  id="or-separator"
                                  className="or-separator mt-3 col-md-12"
                                >
                                  <span className="or-text">
                                    or continue with
                                  </span>
                                </div>
                              </form>
                              <ul className="login-social">
                                <li>
                                  <a href="#" className="login-social_link">
                                    <i className="fab fa-facebook-f"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" className="login-social_link">
                                    <i className="fab fa-twitter"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#" className="login-social_link">
                                    <i className="fab fa-instagram"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {/* <!-- End Login Page --> */}
                          <div className="createAccount text-center">
                            <span>
                              New to Timelyhire?&nbsp;{" "}
                              <a
                                onClick={() => signUpScreen()}
                                className="createLink"
                                style={{ cursor: "pointer", color: "#3abd00" }}
                              >
                                Create One
                              </a>
                            </span>
                          </div>
                        </>
                      ) : isForgotPasswordScreen ? (
                        <>
                          <div
                            className="card loginContainer login_page"
                            style={{
                              height: "100%",
                              marginTop: "232px",
                              border: "none",
                              position: "relative",
                              top: "-115px",
                            }}
                          >
                            <div className="card-body p-0">
                              <div className="content_container2">
                                <p
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "1.5rem",
                                    color: "#3abd00",
                                  }}
                                >
                                  Forgot Password
                                </p>
                                <p
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "15px",
                                  }}
                                >
                                  Enter your email to receive password reset
                                  instructions
                                </p>
                              </div>
                              <form
                                className="form_horizontal row"
                                role="form"
                                onSubmit={handleForgotPassword}
                              >
                                <div className="form-group mt-3 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="inputEmailAddress"
                                  >
                                    Email <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={email}
                                    onChange={(e) => {
                                      onChangeInput(e, "forgotEmail");
                                    }}
                                    className="form-control login-signup-screen"
                                    id="inputEmailAddress"
                                    placeholder=""
                                    name="email"
                                    required
                                    style={{ color: "black" }}
                                  />
                                </div>

                                <div className="form-group text-center col-md-12">
                                  <button
                                    className="primary_btn_"
                                    disabled={loadingForgotPassword}
                                    type="submit"
                                  >
                                    {loadingForgotPassword ? (
                                      <>
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      </>
                                    ) : (
                                      <>Send</>
                                    )}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div
                              className="col-12 text-center"
                              style={{
                                fontSize: "15px",
                                color: "#3abd00",
                                position: "relative",
                                top: "-115px",
                              }}
                            >
                              <span
                                onClick={() => {
                                  backToLogin();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Back to Login
                              </span>
                            </div>
                          </div>
                        </>
                      ) : isSignUpScreen ? (
                        <>
                          <div className="alreadyAccount text-center">
                            <span>
                              Already on Timelyhire ?&nbsp;{" "}
                              <a
                                onClick={() => backToLogin()}
                                className="createLink"
                                style={{ cursor: "pointer", color: "#3abd00" }}
                              >
                                Login
                              </a>
                            </span>
                          </div>
                          {/* <!-- Start Register Page --> */}
                          <div
                            className="card loginContainer mt-4 register_page"
                            style={{ border: "none" }}
                          >
                            <div className="card-body p-0">
                              <div className="content_container2">
                                <h3 className="subheading_">Welcome!</h3>
                                <p
                                  className="subtext_"
                                  style={{ color: "#3abd00" }}
                                >
                                  Create an account or sign up
                                </p>
                              </div>
                              <form
                                className="form_horizontal row"
                                role="form"
                                onSubmit={handleSignUp}
                              >
                                <div className="form-group mt-3 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="FirstName"
                                  >
                                    First Name
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control login-signup-screen"
                                    id="FirstName"
                                    type="name"
                                    placeholder=""
                                    name="name"
                                    required
                                    onChange={(e) => {
                                      onChangeInput(e, "firstNameCus");
                                    }}
                                    style={{ color: "black" }}
                                  />
                                </div>
                                <div className="form-group mb-2 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="LastName"
                                  >
                                    Last Name<span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control login-signup-screen"
                                    id="LastName"
                                    type="name"
                                    placeholder=""
                                    name="name"
                                    required
                                    onChange={(e) => {
                                      onChangeInput(e, "lastNameCus");
                                    }}
                                    style={{ color: "black" }}
                                  />
                                </div>
                                <div className="form-group mt-3 mb-2 col-md-12">
                                  <label className="label_text" htmlFor="Email">
                                    Email Address
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control login-signup-screen"
                                    id="Email"
                                    type="email"
                                    placeholder=""
                                    name="email"
                                    required
                                    onChange={(e) => {
                                      onChangeInput(e, "userName");
                                    }}
                                    style={{ color: "black" }}
                                  />
                                </div>
                                {isEmployer ? (
                                  <>
                                    <div className="form-group mt-3 mb-2 col-md-12">
                                      <label
                                        className="label_text"
                                        htmlFor="BusinessName"
                                      >
                                        Business Name
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        className="form-control login-signup-screen"
                                        id="businessName"
                                        type="text"
                                        placeholder=""
                                        name="businessName"
                                        required
                                        onChange={(e) => {
                                          onChangeInput(e, "businessName");
                                        }}
                                        style={{ color: "black" }}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {isEmployer ? (
                                  <>
                                    <div className="form-group mt-3 mb-2 col-md-12">
                                      <label
                                        className="label_text"
                                        htmlFor="confirmPassword"
                                      >
                                        Type of Business
                                        <span className="required">*</span>
                                      </label>
                                      <select
                                        name="category"
                                        className="form-control form-control-solid login-signup-screen-phonecode"
                                        onChange={(e) => {
                                          onChangeInput(e, "categoryId");
                                        }}
                                      >
                                        {categories.length ? (
                                          <>
                                            {categories.map((value, index) => {
                                              return (
                                                <option value={value.id}>
                                                  {value.name}
                                                </option>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </select>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <div className="form-group mt-3 mb-2 col-md-12">
                                  <label className="label_text" htmlFor="Code">
                                    Code<span className="required">*</span>
                                  </label>
                                  {/* <select className="form-control form-control-solid ">
                                  <option>+91</option>
                                  <option>+44</option>
                                  <option>+93</option>
                                </select> */}
                                  <select
                                    name="countryCode"
                                    id=""
                                    className="form-control form-control-solid login-signup-screen-phonecode"
                                    onChange={(e) => {
                                      onChangeInput(e, "countryCode");
                                    }}
                                  >
                                    <option data-countryCode="GB" value="GB-44">
                                      UK (+44)
                                    </option>
                                    <option
                                      data-countryCode="US"
                                      value="US-1"
                                      selected
                                    >
                                      USA (+1)
                                    </option>
                                    <optgroup label="Other countries">
                                      <option
                                        data-countryCode="DZ"
                                        value="DZ-213"
                                      >
                                        Algeria (+213)
                                      </option>
                                      <option
                                        data-countryCode="AD"
                                        value="AD-376"
                                      >
                                        Andorra (+376)
                                      </option>
                                      <option
                                        data-countryCode="AO"
                                        value="AO-244"
                                      >
                                        Angola (+244)
                                      </option>
                                      <option
                                        data-countryCode="AI"
                                        value="AI-1264"
                                      >
                                        Anguilla (+1264)
                                      </option>
                                      <option
                                        data-countryCode="AG"
                                        value="AG-1268"
                                      >
                                        Antigua &amp; Barbuda (+1268)
                                      </option>
                                      <option
                                        data-countryCode="AR"
                                        value="AR-54"
                                      >
                                        Argentina (+54)
                                      </option>
                                      <option
                                        data-countryCode="AM"
                                        value="AM-374"
                                      >
                                        Armenia (+374)
                                      </option>
                                      <option
                                        data-countryCode="AW"
                                        value="AW-297"
                                      >
                                        Aruba (+297)
                                      </option>
                                      <option
                                        data-countryCode="AU"
                                        value="AU-61"
                                      >
                                        Australia (+61)
                                      </option>
                                      <option
                                        data-countryCode="AT"
                                        value="AT-43"
                                      >
                                        Austria (+43)
                                      </option>
                                      <option
                                        data-countryCode="AZ"
                                        value="AZ-994"
                                      >
                                        Azerbaijan (+994)
                                      </option>
                                      <option
                                        data-countryCode="BS"
                                        value="BS-1242"
                                      >
                                        Bahamas (+1242)
                                      </option>
                                      <option
                                        data-countryCode="BH"
                                        value="BH-973"
                                      >
                                        Bahrain (+973)
                                      </option>
                                      <option
                                        data-countryCode="BD"
                                        value="BD-880"
                                      >
                                        Bangladesh (+880)
                                      </option>
                                      <option
                                        data-countryCode="BB"
                                        value="BB-1246"
                                      >
                                        Barbados (+1246)
                                      </option>
                                      <option
                                        data-countryCode="BY"
                                        value="BY-375"
                                      >
                                        Belarus (+375)
                                      </option>
                                      <option
                                        data-countryCode="BE"
                                        value="BE-32"
                                      >
                                        Belgium (+32)
                                      </option>
                                      <option
                                        data-countryCode="BZ"
                                        value="BZ-501"
                                      >
                                        Belize (+501)
                                      </option>
                                      <option
                                        data-countryCode="BJ"
                                        value="BJ-229"
                                      >
                                        Benin (+229)
                                      </option>
                                      <option
                                        data-countryCode="BM"
                                        value="BM-1441"
                                      >
                                        Bermuda (+1441)
                                      </option>
                                      <option
                                        data-countryCode="BT"
                                        value="BT-975"
                                      >
                                        Bhutan (+975)
                                      </option>
                                      <option
                                        data-countryCode="BO"
                                        value="BO-591"
                                      >
                                        Bolivia (+591)
                                      </option>
                                      <option
                                        data-countryCode="BA"
                                        value="BA-387"
                                      >
                                        Bosnia Herzegovina (+387)
                                      </option>
                                      <option
                                        data-countryCode="BW"
                                        value="BW-267"
                                      >
                                        Botswana (+267)
                                      </option>
                                      <option
                                        data-countryCode="BR"
                                        value="BR-55"
                                      >
                                        Brazil (+55)
                                      </option>
                                      <option
                                        data-countryCode="BN"
                                        value="BN-673"
                                      >
                                        Brunei (+673)
                                      </option>
                                      <option
                                        data-countryCode="BG"
                                        value="BG-359"
                                      >
                                        Bulgaria (+359)
                                      </option>
                                      <option
                                        data-countryCode="BF"
                                        value="BF-226"
                                      >
                                        Burkina Faso (+226)
                                      </option>
                                      <option
                                        data-countryCode="BI"
                                        value="BI-257"
                                      >
                                        Burundi (+257)
                                      </option>
                                      <option
                                        data-countryCode="KH"
                                        value="KH-855"
                                      >
                                        Cambodia (+855)
                                      </option>
                                      <option
                                        data-countryCode="CM"
                                        value="CM-237"
                                      >
                                        Cameroon (+237)
                                      </option>
                                      <option
                                        data-countryCode="CA"
                                        value="CA-1"
                                      >
                                        Canada (+1)
                                      </option>
                                      <option
                                        data-countryCode="CV"
                                        value="CV-238"
                                      >
                                        Cape Verde Islands (+238)
                                      </option>
                                      <option
                                        data-countryCode="KY"
                                        value="KY-1345"
                                      >
                                        Cayman Islands (+1345)
                                      </option>
                                      <option
                                        data-countryCode="CF"
                                        value="CF-236"
                                      >
                                        Central African Republic (+236)
                                      </option>
                                      <option
                                        data-countryCode="CL"
                                        value="CL-56"
                                      >
                                        Chile (+56)
                                      </option>
                                      <option
                                        data-countryCode="CN"
                                        value="CN-86"
                                      >
                                        China (+86)
                                      </option>
                                      <option
                                        data-countryCode="CO"
                                        value="CO-57"
                                      >
                                        Colombia (+57)
                                      </option>
                                      <option
                                        data-countryCode="KM"
                                        value="KM-269"
                                      >
                                        Comoros (+269)
                                      </option>
                                      <option
                                        data-countryCode="CG"
                                        value="CG-242"
                                      >
                                        Congo (+242)
                                      </option>
                                      <option
                                        data-countryCode="CK"
                                        value="CK-682"
                                      >
                                        Cook Islands (+682)
                                      </option>
                                      <option
                                        data-countryCode="CR"
                                        value="CR-506"
                                      >
                                        Costa Rica (+506)
                                      </option>
                                      <option
                                        data-countryCode="HR"
                                        value="HR-385"
                                      >
                                        Croatia (+385)
                                      </option>
                                      <option
                                        data-countryCode="CU"
                                        value="CU-53"
                                      >
                                        Cuba (+53)
                                      </option>
                                      <option
                                        data-countryCode="CY"
                                        value="CY-90392"
                                      >
                                        Cyprus North (+90392)
                                      </option>
                                      <option
                                        data-countryCode="CY"
                                        value="CY-357"
                                      >
                                        Cyprus South (+357)
                                      </option>
                                      <option
                                        data-countryCode="CZ"
                                        value="CZ-42"
                                      >
                                        Czech Republic (+42)
                                      </option>
                                      <option
                                        data-countryCode="DK"
                                        value="DK-45"
                                      >
                                        Denmark (+45)
                                      </option>
                                      <option
                                        data-countryCode="DJ"
                                        value="DJ-253"
                                      >
                                        Djibouti (+253)
                                      </option>
                                      <option
                                        data-countryCode="DM"
                                        value="DM-1809"
                                      >
                                        Dominica (+1809)
                                      </option>
                                      <option
                                        data-countryCode="DO"
                                        value="DO-1809"
                                      >
                                        Dominican Republic (+1809)
                                      </option>
                                      <option
                                        data-countryCode="EC"
                                        value="EC-593"
                                      >
                                        Ecuador (+593)
                                      </option>
                                      <option
                                        data-countryCode="EG"
                                        value="EG-20"
                                      >
                                        Egypt (+20)
                                      </option>
                                      <option
                                        data-countryCode="SV"
                                        value="SV-503"
                                      >
                                        El Salvador (+503)
                                      </option>
                                      <option
                                        data-countryCode="GQ"
                                        value="GQ-240"
                                      >
                                        Equatorial Guinea (+240)
                                      </option>
                                      <option
                                        data-countryCode="ER"
                                        value="ER-291"
                                      >
                                        Eritrea (+291)
                                      </option>
                                      <option
                                        data-countryCode="EE"
                                        value="EE-372"
                                      >
                                        Estonia (+372)
                                      </option>
                                      <option
                                        data-countryCode="ET"
                                        value="ET-251"
                                      >
                                        Ethiopia (+251)
                                      </option>
                                      <option
                                        data-countryCode="FK"
                                        value="FK-500"
                                      >
                                        Falkland Islands (+500)
                                      </option>
                                      <option
                                        data-countryCode="FO"
                                        value="FO-298"
                                      >
                                        Faroe Islands (+298)
                                      </option>
                                      <option
                                        data-countryCode="FJ"
                                        value="FJ-679"
                                      >
                                        Fiji (+679)
                                      </option>
                                      <option
                                        data-countryCode="FI"
                                        value="FI-358"
                                      >
                                        Finland (+358)
                                      </option>
                                      <option
                                        data-countryCode="FR"
                                        value="FR-33"
                                      >
                                        France (+33)
                                      </option>
                                      <option
                                        data-countryCode="GF"
                                        value="GF-594"
                                      >
                                        French Guiana (+594)
                                      </option>
                                      <option
                                        data-countryCode="PF"
                                        value="PF-689"
                                      >
                                        French Polynesia (+689)
                                      </option>
                                      <option
                                        data-countryCode="GA"
                                        value="GA-241"
                                      >
                                        Gabon (+241)
                                      </option>
                                      <option
                                        data-countryCode="GM"
                                        value="GM-220"
                                      >
                                        Gambia (+220)
                                      </option>
                                      <option
                                        data-countryCode="GE"
                                        value="GE-7880"
                                      >
                                        Georgia (+7880)
                                      </option>
                                      <option
                                        data-countryCode="DE"
                                        value="DE-49"
                                      >
                                        Germany (+49)
                                      </option>
                                      <option
                                        data-countryCode="GH"
                                        value="GH-233"
                                      >
                                        Ghana (+233)
                                      </option>
                                      <option
                                        data-countryCode="GI"
                                        value="GI-350"
                                      >
                                        Gibraltar (+350)
                                      </option>
                                      <option
                                        data-countryCode="GR"
                                        value="GR-30"
                                      >
                                        Greece (+30)
                                      </option>
                                      <option
                                        data-countryCode="GL"
                                        value="GL-299"
                                      >
                                        Greenland (+299)
                                      </option>
                                      <option
                                        data-countryCode="GD"
                                        value="GD-1473"
                                      >
                                        Grenada (+1473)
                                      </option>
                                      <option
                                        data-countryCode="GP"
                                        value="GP-590"
                                      >
                                        Guadeloupe (+590)
                                      </option>
                                      <option
                                        data-countryCode="GU"
                                        value="GU-671"
                                      >
                                        Guam (+671)
                                      </option>
                                      <option
                                        data-countryCode="GT"
                                        value="GT-502"
                                      >
                                        Guatemala (+502)
                                      </option>
                                      <option
                                        data-countryCode="GN"
                                        value="GN-224"
                                      >
                                        Guinea (+224)
                                      </option>
                                      <option
                                        data-countryCode="GW"
                                        value="GW-245"
                                      >
                                        Guinea - Bissau (+245)
                                      </option>
                                      <option
                                        data-countryCode="GY"
                                        value="GY-592"
                                      >
                                        Guyana (+592)
                                      </option>
                                      <option
                                        data-countryCode="HT"
                                        value="HT-509"
                                      >
                                        Haiti (+509)
                                      </option>
                                      <option
                                        data-countryCode="HN"
                                        value="HN-504"
                                      >
                                        Honduras (+504)
                                      </option>
                                      <option
                                        data-countryCode="HK"
                                        value="HK-852"
                                      >
                                        Hong Kong (+852)
                                      </option>
                                      <option
                                        data-countryCode="HU"
                                        value="HU-36"
                                      >
                                        Hungary (+36)
                                      </option>
                                      <option
                                        data-countryCode="IS"
                                        value="IS-354"
                                      >
                                        Iceland (+354)
                                      </option>
                                      <option
                                        data-countryCode="IN"
                                        value="IN-91"
                                      >
                                        India (+91)
                                      </option>
                                      <option
                                        data-countryCode="ID"
                                        value="ID-62"
                                      >
                                        Indonesia (+62)
                                      </option>
                                      <option
                                        data-countryCode="IR"
                                        value="IR-98"
                                      >
                                        Iran (+98)
                                      </option>
                                      <option
                                        data-countryCode="IQ"
                                        value="IQ-964"
                                      >
                                        Iraq (+964)
                                      </option>
                                      <option
                                        data-countryCode="IE"
                                        value="IE-353"
                                      >
                                        Ireland (+353)
                                      </option>
                                      <option
                                        data-countryCode="IL"
                                        value="IL-972"
                                      >
                                        Israel (+972)
                                      </option>
                                      <option
                                        data-countryCode="IT"
                                        value="IT-39"
                                      >
                                        Italy (+39)
                                      </option>
                                      <option
                                        data-countryCode="JM"
                                        value="JM-1876"
                                      >
                                        Jamaica (+1876)
                                      </option>
                                      <option
                                        data-countryCode="JP"
                                        value="JP-81"
                                      >
                                        Japan (+81)
                                      </option>
                                      <option
                                        data-countryCode="JO"
                                        value="JO-962"
                                      >
                                        Jordan (+962)
                                      </option>
                                      <option
                                        data-countryCode="KZ"
                                        value="KZ-7"
                                      >
                                        Kazakhstan (+7)
                                      </option>
                                      <option
                                        data-countryCode="KE"
                                        value="KE-254"
                                      >
                                        Kenya (+254)
                                      </option>
                                      <option
                                        data-countryCode="KI"
                                        value="KI-686"
                                      >
                                        Kiribati (+686)
                                      </option>
                                      <option
                                        data-countryCode="KP"
                                        value="KP-850"
                                      >
                                        Korea North (+850)
                                      </option>
                                      <option
                                        data-countryCode="KR"
                                        value="KR-82"
                                      >
                                        Korea South (+82)
                                      </option>
                                      <option
                                        data-countryCode="KW"
                                        value="KW-965"
                                      >
                                        Kuwait (+965)
                                      </option>
                                      <option
                                        data-countryCode="KG"
                                        value="KG-996"
                                      >
                                        Kyrgyzstan (+996)
                                      </option>
                                      <option
                                        data-countryCode="LA"
                                        value="LA-856"
                                      >
                                        Laos (+856)
                                      </option>
                                      <option
                                        data-countryCode="LV"
                                        value="LV-371"
                                      >
                                        Latvia (+371)
                                      </option>
                                      <option
                                        data-countryCode="LB"
                                        value="LB-961"
                                      >
                                        Lebanon (+961)
                                      </option>
                                      <option
                                        data-countryCode="LS"
                                        value="LS-266"
                                      >
                                        Lesotho (+266)
                                      </option>
                                      <option
                                        data-countryCode="LR"
                                        value="LR-231"
                                      >
                                        Liberia (+231)
                                      </option>
                                      <option
                                        data-countryCode="LY"
                                        value="LY-218"
                                      >
                                        Libya (+218)
                                      </option>
                                      <option
                                        data-countryCode="LI"
                                        value="LI-417"
                                      >
                                        Liechtenstein (+417)
                                      </option>
                                      <option
                                        data-countryCode="LT"
                                        value="LT-370"
                                      >
                                        Lithuania (+370)
                                      </option>
                                      <option
                                        data-countryCode="LU"
                                        value="LU-352"
                                      >
                                        Luxembourg (+352)
                                      </option>
                                      <option
                                        data-countryCode="MO"
                                        value="MO-853"
                                      >
                                        Macao (+853)
                                      </option>
                                      <option
                                        data-countryCode="MK"
                                        value="MK-389"
                                      >
                                        Macedonia (+389)
                                      </option>
                                      <option
                                        data-countryCode="MG"
                                        value="MG-261"
                                      >
                                        Madagascar (+261)
                                      </option>
                                      <option
                                        data-countryCode="MW"
                                        value="MW-265"
                                      >
                                        Malawi (+265)
                                      </option>
                                      <option
                                        data-countryCode="MY"
                                        value="MY-60"
                                      >
                                        Malaysia (+60)
                                      </option>
                                      <option
                                        data-countryCode="MV"
                                        value="MV-960"
                                      >
                                        Maldives (+960)
                                      </option>
                                      <option
                                        data-countryCode="ML"
                                        value="ML-223"
                                      >
                                        Mali (+223)
                                      </option>
                                      <option
                                        data-countryCode="MT"
                                        value="MT-356"
                                      >
                                        Malta (+356)
                                      </option>
                                      <option
                                        data-countryCode="MH"
                                        value="MH-692"
                                      >
                                        Marshall Islands (+692)
                                      </option>
                                      <option
                                        data-countryCode="MQ"
                                        value="MQ-596"
                                      >
                                        Martinique (+596)
                                      </option>
                                      <option
                                        data-countryCode="MR"
                                        value="MR-222"
                                      >
                                        Mauritania (+222)
                                      </option>
                                      <option
                                        data-countryCode="YT"
                                        value="YT-269"
                                      >
                                        Mayotte (+269)
                                      </option>
                                      <option
                                        data-countryCode="MX"
                                        value="MX-52"
                                      >
                                        Mexico (+52)
                                      </option>
                                      <option
                                        data-countryCode="FM"
                                        value="FM-691"
                                      >
                                        Micronesia (+691)
                                      </option>
                                      <option
                                        data-countryCode="MD"
                                        value="MD-373"
                                      >
                                        Moldova (+373)
                                      </option>
                                      <option
                                        data-countryCode="MC"
                                        value="MC-377"
                                      >
                                        Monaco (+377)
                                      </option>
                                      <option
                                        data-countryCode="MN"
                                        value="MN-976"
                                      >
                                        Mongolia (+976)
                                      </option>
                                      <option
                                        data-countryCode="MS"
                                        value="MS-1664"
                                      >
                                        Montserrat (+1664)
                                      </option>
                                      <option
                                        data-countryCode="MA"
                                        value="MA-212"
                                      >
                                        Morocco (+212)
                                      </option>
                                      <option
                                        data-countryCode="MZ"
                                        value="MZ-258"
                                      >
                                        Mozambique (+258)
                                      </option>
                                      <option
                                        data-countryCode="MN"
                                        value="MN-95"
                                      >
                                        Myanmar (+95)
                                      </option>
                                      <option
                                        data-countryCode="NA"
                                        value="NA-264"
                                      >
                                        Namibia (+264)
                                      </option>
                                      <option
                                        data-countryCode="NR"
                                        value="NR-674"
                                      >
                                        Nauru (+674)
                                      </option>
                                      <option
                                        data-countryCode="NP"
                                        value="NP-977"
                                      >
                                        Nepal (+977)
                                      </option>
                                      <option
                                        data-countryCode="NL"
                                        value="NL-31"
                                      >
                                        Netherlands (+31)
                                      </option>
                                      <option
                                        data-countryCode="NC"
                                        value="NC-687"
                                      >
                                        New Caledonia (+687)
                                      </option>
                                      <option
                                        data-countryCode="NZ"
                                        value="NZ-64"
                                      >
                                        New Zealand (+64)
                                      </option>
                                      <option
                                        data-countryCode="NI"
                                        value="NI-505"
                                      >
                                        Nicaragua (+505)
                                      </option>
                                      <option
                                        data-countryCode="NE"
                                        value="NE-227"
                                      >
                                        Niger (+227)
                                      </option>
                                      <option
                                        data-countryCode="NG"
                                        value="NG-234"
                                      >
                                        Nigeria (+234)
                                      </option>
                                      <option
                                        data-countryCode="NU"
                                        value="NU-683"
                                      >
                                        Niue (+683)
                                      </option>
                                      <option
                                        data-countryCode="NF"
                                        value="NF-672"
                                      >
                                        Norfolk Islands (+672)
                                      </option>
                                      <option
                                        data-countryCode="NP"
                                        value="NP-670"
                                      >
                                        Northern Marianas (+670)
                                      </option>
                                      <option
                                        data-countryCode="NO"
                                        value="NO-47"
                                      >
                                        Norway (+47)
                                      </option>
                                      <option
                                        data-countryCode="OM"
                                        value="OM-968"
                                      >
                                        Oman (+968)
                                      </option>
                                      <option
                                        data-countryCode="PW"
                                        value="PW-680"
                                      >
                                        Palau (+680)
                                      </option>
                                      <option
                                        data-countryCode="PA"
                                        value="PA-507"
                                      >
                                        Panama (+507)
                                      </option>
                                      <option
                                        data-countryCode="PG"
                                        value="PG-675"
                                      >
                                        Papua New Guinea (+675)
                                      </option>
                                      <option
                                        data-countryCode="PY"
                                        value="PY-595"
                                      >
                                        Paraguay (+595)
                                      </option>
                                      <option
                                        data-countryCode="PE"
                                        value="PE-51"
                                      >
                                        Peru (+51)
                                      </option>
                                      <option
                                        data-countryCode="PH"
                                        value="PH-63"
                                      >
                                        Philippines (+63)
                                      </option>
                                      <option
                                        data-countryCode="PL"
                                        value="PL-48"
                                      >
                                        Poland (+48)
                                      </option>
                                      <option
                                        data-countryCode="PT"
                                        value="PT-351"
                                      >
                                        Portugal (+351)
                                      </option>
                                      <option
                                        data-countryCode="PR"
                                        value="PR-1787"
                                      >
                                        Puerto Rico (+1787)
                                      </option>
                                      <option
                                        data-countryCode="QA"
                                        value="QA-974"
                                      >
                                        Qatar (+974)
                                      </option>
                                      <option
                                        data-countryCode="RE"
                                        value="RE-262"
                                      >
                                        Reunion (+262)
                                      </option>
                                      <option
                                        data-countryCode="RO"
                                        value="RO-40"
                                      >
                                        Romania (+40)
                                      </option>
                                      <option
                                        data-countryCode="RU"
                                        value="RU-7"
                                      >
                                        Russia (+7)
                                      </option>
                                      <option
                                        data-countryCode="RW"
                                        value="RW-250"
                                      >
                                        Rwanda (+250)
                                      </option>
                                      <option
                                        data-countryCode="SM"
                                        value="SM-378"
                                      >
                                        San Marino (+378)
                                      </option>
                                      <option
                                        data-countryCode="ST"
                                        value="ST-239"
                                      >
                                        Sao Tome &amp; Principe (+239)
                                      </option>
                                      <option
                                        data-countryCode="SA"
                                        value="SA-966"
                                      >
                                        Saudi Arabia (+966)
                                      </option>
                                      <option
                                        data-countryCode="SN"
                                        value="SN-221"
                                      >
                                        Senegal (+221)
                                      </option>
                                      <option
                                        data-countryCode="CS"
                                        value="CS-381"
                                      >
                                        Serbia (+381)
                                      </option>
                                      <option
                                        data-countryCode="SC"
                                        value="SC-248"
                                      >
                                        Seychelles (+248)
                                      </option>
                                      <option
                                        data-countryCode="SL"
                                        value="SL-232"
                                      >
                                        Sierra Leone (+232)
                                      </option>
                                      <option
                                        data-countryCode="SG"
                                        value="SG-65"
                                      >
                                        Singapore (+65)
                                      </option>
                                      <option
                                        data-countryCode="SK"
                                        value="SK-421"
                                      >
                                        Slovak Republic (+421)
                                      </option>
                                      <option
                                        data-countryCode="SI"
                                        value="SI-386"
                                      >
                                        Slovenia (+386)
                                      </option>
                                      <option
                                        data-countryCode="SB"
                                        value="SB-677"
                                      >
                                        Solomon Islands (+677)
                                      </option>
                                      <option
                                        data-countryCode="SO"
                                        value="SO-252"
                                      >
                                        Somalia (+252)
                                      </option>
                                      <option
                                        data-countryCode="ZA"
                                        value="ZA-27"
                                      >
                                        South Africa (+27)
                                      </option>
                                      <option
                                        data-countryCode="ES"
                                        value="ES-34"
                                      >
                                        Spain (+34)
                                      </option>
                                      <option
                                        data-countryCode="LK"
                                        value="LK-94"
                                      >
                                        Sri Lanka (+94)
                                      </option>
                                      <option
                                        data-countryCode="SH"
                                        value="SH-290"
                                      >
                                        St. Helena (+290)
                                      </option>
                                      <option
                                        data-countryCode="KN"
                                        value="KN-1869"
                                      >
                                        St. Kitts (+1869)
                                      </option>
                                      <option
                                        data-countryCode="SC"
                                        value="SC-1758"
                                      >
                                        St. Lucia (+1758)
                                      </option>
                                      <option
                                        data-countryCode="SD"
                                        value="SD-249"
                                      >
                                        Sudan (+249)
                                      </option>
                                      <option
                                        data-countryCode="SR"
                                        value="SR-597"
                                      >
                                        Suriname (+597)
                                      </option>
                                      <option
                                        data-countryCode="SZ"
                                        value="SZ-268"
                                      >
                                        Swaziland (+268)
                                      </option>
                                      <option
                                        data-countryCode="SE"
                                        value="SE-46"
                                      >
                                        Sweden (+46)
                                      </option>
                                      <option
                                        data-countryCode="CH"
                                        value="CH-41"
                                      >
                                        Switzerland (+41)
                                      </option>
                                      <option
                                        data-countryCode="SI"
                                        value="SI-963"
                                      >
                                        Syria (+963)
                                      </option>
                                      <option
                                        data-countryCode="TW"
                                        value="TW-886"
                                      >
                                        Taiwan (+886)
                                      </option>
                                      <option
                                        data-countryCode="TJ"
                                        value="TJ-7"
                                      >
                                        Tajikstan (+7)
                                      </option>
                                      <option
                                        data-countryCode="TH"
                                        value="TH-66"
                                      >
                                        Thailand (+66)
                                      </option>
                                      <option
                                        data-countryCode="TG"
                                        value="TG-228"
                                      >
                                        Togo (+228)
                                      </option>
                                      <option
                                        data-countryCode="TO"
                                        value="TO-676"
                                      >
                                        Tonga (+676)
                                      </option>
                                      <option
                                        data-countryCode="TT"
                                        value="TT-1868"
                                      >
                                        Trinidad &amp; Tobago (+1868)
                                      </option>
                                      <option
                                        data-countryCode="TN"
                                        value="TN-216"
                                      >
                                        Tunisia (+216)
                                      </option>
                                      <option
                                        data-countryCode="TR"
                                        value="TR-90"
                                      >
                                        Turkey (+90)
                                      </option>
                                      <option
                                        data-countryCode="TM"
                                        value="TM-7"
                                      >
                                        Turkmenistan (+7)
                                      </option>
                                      <option
                                        data-countryCode="TM"
                                        value="TM-993"
                                      >
                                        Turkmenistan (+993)
                                      </option>
                                      <option
                                        data-countryCode="TC"
                                        value="TC-1649"
                                      >
                                        Turks &amp; Caicos Islands (+1649)
                                      </option>
                                      <option
                                        data-countryCode="TV"
                                        value="TV-688"
                                      >
                                        Tuvalu (+688)
                                      </option>
                                      <option
                                        data-countryCode="UG"
                                        value="UG-256"
                                      >
                                        Uganda (+256)
                                      </option>
                                      {/* <option data-countryCode="GB" value="44">UK (+44)</option> */}
                                      <option
                                        data-countryCode="UA"
                                        value="UA-380"
                                      >
                                        Ukraine (+380)
                                      </option>
                                      <option
                                        data-countryCode="AE"
                                        value="AE-971"
                                      >
                                        United Arab Emirates (+971)
                                      </option>
                                      <option
                                        data-countryCode="UY"
                                        value="UY-598"
                                      >
                                        Uruguay (+598)
                                      </option>
                                      {/* <option data-countryCode="US" value="1">USA (+1)</option> */}
                                      <option
                                        data-countryCode="UZ"
                                        value="UZ-7"
                                      >
                                        Uzbekistan (+7)
                                      </option>
                                      <option
                                        data-countryCode="VU"
                                        value="VU-678"
                                      >
                                        Vanuatu (+678)
                                      </option>
                                      <option
                                        data-countryCode="VA"
                                        value="VA-379"
                                      >
                                        Vatican City (+379)
                                      </option>
                                      <option
                                        data-countryCode="VE"
                                        value="VE-58"
                                      >
                                        Venezuela (+58)
                                      </option>
                                      <option
                                        data-countryCode="VN"
                                        value="VN-84"
                                      >
                                        Vietnam (+84)
                                      </option>
                                      <option
                                        data-countryCode="VG"
                                        value="VG-84"
                                      >
                                        Virgin Islands - British (+1284)
                                      </option>
                                      <option
                                        data-countryCode="VI"
                                        value="VI-84"
                                      >
                                        Virgin Islands - US (+1340)
                                      </option>
                                      <option
                                        data-countryCode="WF"
                                        value="WF-681"
                                      >
                                        Wallis &amp; Futuna (+681)
                                      </option>
                                      <option
                                        data-countryCode="YE"
                                        value="YE-969"
                                      >
                                        Yemen (North)(+969)
                                      </option>
                                      <option
                                        data-countryCode="YE"
                                        value="YE-967"
                                      >
                                        Yemen (South)(+967)
                                      </option>
                                      <option
                                        data-countryCode="ZM"
                                        value="ZM-260"
                                      >
                                        Zambia (+260)
                                      </option>
                                      <option
                                        data-countryCode="ZW"
                                        value="ZW-263"
                                      >
                                        Zimbabwe (+263)
                                      </option>
                                    </optgroup>
                                  </select>
                                </div>
                                <div className="form-group mt-3 mb-2 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="phoneNumber"
                                  >
                                    Phone Number
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control login-signup-screen"
                                    id="phoneNumber"
                                    type="number"
                                    placeholder=""
                                    name="number"
                                    required
                                    onChange={(e) => {
                                      onChangeInput(e, "phoneNumCus");
                                    }}
                                    style={{ color: "black" }}
                                  />
                                </div>
                                <div className="form-group mt-3 mb-2 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="inputPassword"
                                  >
                                    Password<span className="required">*</span>
                                  </label>
                                  <input
                                    id="password"
                                    className="form-control id_password2 login-signup-screen"
                                    type="password"
                                    placeholder=""
                                    name="password"
                                    autoComplete="current-password"
                                    required
                                    onChange={(e) => {
                                      onChangeInput(e, "password");
                                    }}
                                    style={{ color: "black" }}
                                  />
                                  <span className="toggle_password_ info_icon">
                                    {/* <span className="show-icon togglePassword2" id="" onClick={showPassword}></span> */}
                                    {showPasswordClicked ? (
                                      <>
                                        <i
                                          className="fa fa-eye-slash"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "20px",
                                            color: "#3abd00",
                                            position: "relative",
                                            top: "-12px",
                                            right: "10px",
                                          }}
                                          onClick={showPassword}
                                        ></i>
                                      </>
                                    ) : (
                                      <>
                                        <i
                                          className="fa fa-eye"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "20px",
                                            color: "#3abd00",
                                            position: "relative",
                                            top: "-12px",
                                            right: "10px",
                                          }}
                                          onClick={showPassword}
                                        ></i>
                                      </>
                                    )}
                                  </span>
                                </div>
                                <div className="form-group mt-3 mb-2 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="confirmPassword"
                                  >
                                    Confirm Password
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control id_password login-signup-screen"
                                    type="text"
                                    placeholder=""
                                    name="password"
                                    autoComplete="current-password"
                                    required
                                    onChange={(e) => {
                                      onChangeInput(e, "confirmPassword");
                                    }}
                                    style={{ color: "black" }}
                                  />
                                </div>
                                <div className="form-group mt-3 mb-2 col-md-12 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    className="form-check-input mr-3"
                                    id="checkbox"
                                    onChange={(e) => {
                                      onChangeInput(e, "userTypeId");
                                    }}
                                  />
                                  <label
                                    htmlFor="checkbox"
                                    className="checbox_text_"
                                  >
                                    <span>Are you an employer ?</span>
                                  </label>
                                </div>
                                <div className="form-group mt-3 mb-2 col-md-12">
                                  <input
                                    type="checkbox"
                                    className="form-check-input mr-3"
                                    id="checkbox"
                                    onChange={(e) => {
                                      onChangeInput(e, "termCondition");
                                    }}
                                  />
                                  <label
                                    htmlFor="checkbox"
                                    className="checbox_text_"
                                  >
                                    <span>
                                      By continuing,{" "}
                                      <a
                                        href="#"
                                        style={{
                                          textDecoration: "underline",
                                          color: "#3abd00",
                                        }}
                                      >
                                        {" "}
                                        you agree to our Terms &amp; Conditions
                                      </a>
                                    </span>
                                  </label>
                                </div>
                                <div className="form-group text-center col-md-12 mt-3">
                                  <button
                                    className="primary_btn_"
                                    disabled={loadingCreateCus}
                                    type="submit"
                                  >
                                    {loadingCreateCus ? (
                                      <>
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      </>
                                    ) : (
                                      <>Sign Up</>
                                    )}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {isResetPasswordScreen ? (
                        <>
                          <div
                            className="card loginContainer login_page"
                            style={{
                              height: "100%",
                              marginTop: "64px",
                              border: "none",
                            }}
                          >
                            <div className="card-body p-0">
                              <div className="content_container2">
                                <p
                                  style={{
                                    fontWeight: "500",
                                    color: "#3abd00",
                                    fontSize: "1.5rem",
                                  }}
                                >
                                  Reset Password
                                </p>
                                <p
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "15px",
                                  }}
                                >
                                  Enter OTP that you received in your email and
                                  create a new password
                                </p>
                              </div>
                              <form
                                className="form_horizontal row"
                                role="form"
                                onSubmit={handleResetPassword}
                              >
                                <div className="form-group mt-3 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="inputEmailAddress"
                                  >
                                    OTP <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      onChangeInput(e, "otp");
                                    }}
                                    className="form-control login-signup-screen"
                                    id="inputEmailAddress"
                                    placeholder=""
                                    name="otp"
                                    required
                                    style={{ color: "black" }}
                                  />
                                  <label
                                    className="label_text"
                                    htmlFor="inputEmailAddress"
                                  >
                                    Password <span className="required">*</span>
                                  </label>
                                  <input
                                    type="password"
                                    onChange={(e) => {
                                      onChangeInput(e, "password");
                                    }}
                                    className="form-control login-signup-screen"
                                    id="inputEmailAddress"
                                    placeholder=""
                                    name="password"
                                    required
                                    style={{ color: "black" }}
                                  />
                                  <label
                                    className="label_text"
                                    htmlFor="inputEmailAddress"
                                  >
                                    Confirm Password{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="password"
                                    onChange={(e) => {
                                      onChangeInput(e, "confirmPassword");
                                    }}
                                    className="form-control login-signup-screen"
                                    id="inputEmailAddress"
                                    placeholder=""
                                    name="password"
                                    required
                                    style={{ color: "black" }}
                                  />
                                </div>

                                <div className="form-group text-center col-md-12">
                                  <button
                                    className="primary_btn_"
                                    disabled={loadingResetPassword}
                                    type="submit"
                                  >
                                    {loadingResetPassword ? (
                                      <>
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      </>
                                    ) : (
                                      <>Reset Password</>
                                    )}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div
                              className="col-12 text-center"
                              style={{
                                fontSize: "15px",
                                cursor: "pointer",
                                color: "#3abd00",
                              }}
                            >
                              <span
                                onClick={() => {
                                  backToLogin();
                                }}
                              >
                                Back to Login
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {isVerifyEmailScreen && (
                        <>
                          <div
                            className="card loginContainer login_page"
                            style={{
                              height: "100%",
                              marginTop: "271px",
                              border: "none",
                              position: "relative",
                              top: "-115px",
                            }}
                          >
                            <div className="card-body p-0">
                              <div className="content_container2">
                                <p
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "1.5rem",
                                    color: "#3abd00",
                                  }}
                                >
                                  Verify Email Address
                                </p>
                                <p
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "15px",
                                  }}
                                >
                                  Enter OTP that you received in your mail to
                                  verify your email
                                </p>
                              </div>
                              <form
                                className="form_horizontal row"
                                role="form"
                                onSubmit={handleVerifyEmail}
                              >
                                <div className="form-group mt-3 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="inputEmailAddress"
                                  >
                                    OTP <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      onChangeInput(e, "verifyEmail");
                                    }}
                                    className="form-control login-signup-screen"
                                    id="inputEmailAddress"
                                    placeholder=""
                                    name="verifyEmail"
                                    required
                                    style={{ color: "black" }}
                                  />
                                </div>

                                <div className="form-group text-center col-md-12">
                                  <button
                                    className="primary_btn_"
                                    disabled={loadingVerifyEmail}
                                    type="submit"
                                  >
                                    {loadingVerifyEmail ? (
                                      <>
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      </>
                                    ) : (
                                      <>Send</>
                                    )}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          {/* <div className="row mt-5">
                      <div className='col-12 text-center' style={{ fontSize: '15px', color:'#3abd00' }}><span onClick={() => { backToLogin() }} style={{ cursor: 'pointer' }}>Back to Login</span></div>
                    </div> */}
                        </>
                      )}

                      {isVerifyPhoneScreen && (
                        <>
                          <div
                            className="card loginContainer login_page"
                            style={{
                              height: "100%",
                              marginTop: "271px",
                              border: "none",
                              position: "relative",
                              top: "-115px",
                            }}
                          >
                            <div className="card-body p-0">
                              <div className="content_container2">
                                <p
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "1.5rem",
                                    color: "#3abd00",
                                  }}
                                >
                                  Verify Phone Number
                                </p>
                                <p
                                  style={{
                                    fontWeight: "100",
                                    fontSize: "15px",
                                  }}
                                >
                                  Enter OTP that you received on your number to
                                  verify your phone number
                                </p>
                              </div>
                              <form
                                className="form_horizontal row"
                                role="form"
                                onSubmit={handleVerifyPhone}
                              >
                                <div className="form-group mt-3 col-md-12">
                                  <label
                                    className="label_text"
                                    htmlFor="inputEmailAddress"
                                  >
                                    OTP <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      onChangeInput(e, "verifyPhone");
                                    }}
                                    className="form-control login-signup-screen"
                                    id="inputEmailAddress"
                                    placeholder=""
                                    name="verifyPhone"
                                    required
                                    style={{ color: "black" }}
                                  />
                                </div>

                                <div className="form-group text-center col-md-12">
                                  <button
                                    className="primary_btn_"
                                    disabled={loadingVerifyPhone}
                                    type="submit"
                                  >
                                    {loadingVerifyPhone ? (
                                      <>
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      </>
                                    ) : (
                                      <>Send</>
                                    )}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          {/* <div className="row mt-5">
                      <div className='col-12 text-center' style={{ fontSize: '15px', color:'#3abd00' }}><span onClick={() => { backToLogin() }} style={{ cursor: 'pointer' }}>Back to Login</span></div>
                    </div> */}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {isUserInformationScreen ? (
                  <>
                    <div className="row justify-content-center py-5">
                      <div className="col-12">
                        {isUserInformationScreen && (
                          <>
                            <div
                              className="card"
                              style={{
                                height: "92%",
                                marginTop: "5%",
                                border: "none",
                                padding: "35px",
                              }}
                            >
                              <div className="card-body p-0">
                                <div className="content_container2">
                                  <p
                                    style={{
                                      fontWeight: "100",
                                      fontSize: "1.5rem",
                                      color: "#3abd00",
                                      textAlign: "center",
                                    }}
                                  >
                                    Primary Information
                                  </p>
                                  {/* <p style={{ fontWeight: '100', fontSize: '15px' }}>Enter OTP that you received on your number to verify your phone number</p> */}
                                  <p
                                    style={{
                                      fontSize: "1.4rem",
                                      fontWeight: "500",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Welcome!
                                  </p>
                                  {isUserAnEmployer ? (
                                    <>
                                      <p
                                        style={{
                                          fontSize: "1.2rem",
                                          fontWeight: "500",
                                          marginBottom: "5px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-user mr-3"
                                          style={{ color: "#3abd00" }}
                                        ></i>
                                        {firstNameUserInfo
                                          ? firstNameUserInfo
                                          : "--"}{" "}
                                        {lastNameUserInfo}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p
                                        style={{
                                          fontSize: "1.2rem",
                                          fontWeight: "500",
                                          marginBottom: "25px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-user mr-3"
                                          style={{ color: "#3abd00" }}
                                        ></i>
                                        {firstNameUserInfo
                                          ? firstNameUserInfo
                                          : "--"}{" "}
                                        {lastNameUserInfo}
                                      </p>
                                    </>
                                  )}

                                  {isUserAnEmployer ? (
                                    <>
                                      <p
                                        style={{
                                          fontSize: "1.2rem",
                                          fontWeight: "500",
                                          marginBottom: "5px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-industry mr-3"
                                          style={{ color: "#3abd00" }}
                                        ></i>
                                        {businessNameUserInfo
                                          ? businessNameUserInfo
                                          : "--"}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "1.2rem",
                                          fontWeight: "500",
                                          marginBottom: "25px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <i
                                          className="fas fa-briefcase mr-3"
                                          style={{ color: "#3abd00" }}
                                        ></i>
                                        {businessType}
                                      </p>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {isUserAnEmployer ? (
                                  <>
                                    <img
                                      src={talent_edit}
                                      style={{
                                        width: "3%",
                                        position: "absolute",
                                        top: "200px",
                                        right: "30px",
                                      }}
                                      onClick={openEditUserInfoScreen}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={talent_edit}
                                      style={{
                                        width: "3%",
                                        position: "absolute",
                                        top: "160px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                      onClick={openEditUserInfoScreen}
                                    />
                                  </>
                                )}

                                <form
                                  className="form_horizontal row"
                                  style={{
                                    border: "1px solid #3abd00",
                                    padding: "10px",
                                  }}
                                  role="form"
                                  onSubmit={continueToNewTalent}
                                >
                                  <div className="form-group mt-3 col-md-12">
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      First Name
                                      <span className="required">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        onChangeInput(e, "firstNameUserInfo");
                                      }}
                                      className="form-control login-signup-screen"
                                      style={{ opacity: "0.5" }}
                                      id="inputEmailAddress"
                                      placeholder=""
                                      name="firstNameUserInfo"
                                      value={firstNameUserInfo}
                                      disabled
                                    />
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      Last Name
                                      <span className="required">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        onChangeInput(e, "lastNameUserInfo");
                                      }}
                                      className="form-control login-signup-screen"
                                      style={{ opacity: "0.5" }}
                                      id="inputEmailAddress"
                                      placeholder=""
                                      name="lastNameUserInfo"
                                      value={lastNameUserInfo}
                                      disabled
                                    />
                                    {isUserAnEmployer ? (
                                      <>
                                        <label
                                          className="label_text"
                                          htmlFor="inputEmailAddress"
                                        >
                                          Business Name
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            onChangeInput(
                                              e,
                                              "businessNameUserInfo"
                                            );
                                          }}
                                          className="form-control login-signup-screen"
                                          style={{ opacity: "0.5" }}
                                          id="inputEmailAddress"
                                          placeholder=""
                                          name="businessNameUserInfo"
                                          value={businessNameUserInfo}
                                          disabled
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      Address<span className="required">*</span>
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        style={{ opacity: "0.5" }}
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "addressUserInfo");
                                        }}
                                        value={addressUserInfo}
                                        disabled
                                      />
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      Address 1
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        style={{ opacity: "0.5" }}
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "address1UserInfo");
                                        }}
                                        value={address1UserInfo}
                                        disabled
                                      />
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      City<span className="required">*</span>
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        style={{ opacity: "0.5" }}
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "cityUserInfo");
                                        }}
                                        value={cityUserInfo}
                                        disabled
                                      />
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      State<span className="required">*</span>
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        style={{ opacity: "0.5" }}
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "stateUserInfo");
                                        }}
                                        value={stateUserInfo}
                                        disabled
                                      />
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      Zip Code
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        style={{ opacity: "0.5" }}
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "zipCodeUserInfo");
                                        }}
                                        value={zipCodeUserInfo}
                                        disabled
                                      />
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      Country<span className="required">*</span>
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        style={{ opacity: "0.5" }}
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "countryUserInfo");
                                        }}
                                        value={countryUserInfo}
                                        disabled
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group text-center col-md-12">
                                    <button
                                      className="primary_btn_"
                                      style={{
                                        width: "30%",
                                        position: "relative",
                                        left: "35%",
                                      }}
                                      disabled={loadingVerifyPhone}
                                      type="submit"
                                    >
                                      {loadingVerifyPhone ? (
                                        <>
                                          <span className="spinner-border spinner-border-sm mr-1"></span>
                                        </>
                                      ) : (
                                        <>
                                          {isUserAnEmployer ? (
                                            <>Continue to Create New Talent</>
                                          ) : (
                                            <>Continue to Create New Skill</>
                                          )}
                                        </>
                                      )}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {isEditUserInformationScreen ? (
                  <>
                    <div className="row justify-content-center py-5">
                      <div className="col-12">
                        {isEditUserInformationScreen && (
                          <>
                            <div
                              className="card"
                              style={{
                                height: "90%",
                                marginTop: "5%",
                                border: "none",
                                padding: "35px",
                              }}
                            >
                              <div className="card-body p-0">
                                <div className="content_container2">
                                  <p
                                    style={{
                                      fontWeight: "100",
                                      fontSize: "1.5rem",
                                      color: "#3abd00",
                                      textAlign: "center",
                                      marginBottom: "35px",
                                    }}
                                  >
                                    Edit Primary Information
                                  </p>
                                  {/* <p style={{ fontWeight: '100', fontSize: '15px' }}>Enter OTP that you received on your number to verify your phone number</p> */}
                                  {/* <p style={{ fontSize: '1.2rem', fontWeight: '500', marginBottom: '0px' }}>Welcome!</p>
                        <p style={{ fontSize: '1.2rem', fontWeight: '500', marginBottom: '0px' }}>{firstNameUserInfo} {lastNameUserInfo}</p>
                        <p style={{ fontSize: '1.2rem', fontWeight: '500', marginBottom: '0px' }}>{businessNameUserInfo}</p>
                        <p style={{ fontSize: '1.2rem', fontWeight: '500' }}><i className="fas fa-briefcase mr-3" style={{ color: '#3abd00' }}></i>{businessType}</p> */}
                                </div>
                                <div>
                                  <i
                                    className="fas fa-arrow-left"
                                    style={{
                                      color: "#3abd00",
                                      position: "absolute",
                                      top: "37px",
                                      cursor: "pointer",
                                    }}
                                    onClick={backToUserInfoScreen}
                                  ></i>
                                </div>
                                <form
                                  className="form_horizontal row"
                                  role="form"
                                  onSubmit={saveUserInformation}
                                >
                                  <div className="form-group mt-3 col-md-12">
                                    {loginInformation?.userInformationInfo
                                      .firstName == null ||
                                    loginInformation?.userInformationInfo
                                      .lastName == null ||
                                    newUserInformation?.userInformationInfo
                                      .firstName == null ||
                                    newUserInformation?.userInformationInfo
                                      .lastName == null ? (
                                      <>
                                        <label
                                          className="label_text"
                                          htmlFor="inputEmailAddress"
                                        >
                                          First Name
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            onChangeInput(
                                              e,
                                              "firstNameUserInfo"
                                            );
                                          }}
                                          className="form-control login-signup-screen"
                                          id="inputEmailAddress"
                                          placeholder=""
                                          name="firstNameUserInfo"
                                          value={firstNameUserInfo}
                                          required
                                        />
                                        <label
                                          className="label_text"
                                          htmlFor="inputEmailAddress"
                                        >
                                          Last Name
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            onChangeInput(
                                              e,
                                              "lastNameUserInfo"
                                            );
                                          }}
                                          className="form-control login-signup-screen"
                                          id="inputEmailAddress"
                                          placeholder=""
                                          name="lastNameUserInfo"
                                          value={lastNameUserInfo}
                                          required
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {isUserAnEmployer ? (
                                      <>
                                        {isNewSignUp ? (
                                          <>
                                            {newUserInformation
                                              ?.userInformationInfo
                                              .entityName == null ? (
                                              <>
                                                <label
                                                  className="label_text"
                                                  htmlFor="inputEmailAddress"
                                                >
                                                  Business Name
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  onChange={(e) => {
                                                    onChangeInput(
                                                      e,
                                                      "businessNameUserInfo"
                                                    );
                                                  }}
                                                  className="form-control login-signup-screen"
                                                  id="inputEmailAddress"
                                                  placeholder=""
                                                  name="businessNameUserInfo"
                                                  value={businessNameUserInfo}
                                                  required
                                                />
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {loginInformation
                                              ?.userInformationInfo
                                              .entityName == null ? (
                                              <>
                                                <label
                                                  className="label_text"
                                                  htmlFor="inputEmailAddress"
                                                >
                                                  Business Name
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  onChange={(e) => {
                                                    onChangeInput(
                                                      e,
                                                      "businessNameUserInfo"
                                                    );
                                                  }}
                                                  className="form-control login-signup-screen"
                                                  id="inputEmailAddress"
                                                  placeholder=""
                                                  name="businessNameUserInfo"
                                                  value={businessNameUserInfo}
                                                  required
                                                />
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      Pick Address
                                      <span className="required">*</span>
                                    </label>
                                    <div className="form-group">
                                      <PlacesAutocomplete
                                        value={addressUserInfo}
                                        onChange={autoCompleteHandleChange}
                                        onSelect={autoCompleteHandleSelect}
                                        searchOptions={searchOptions}
                                      >
                                        {({
                                          getInputProps,
                                          suggestions,
                                          getSuggestionItemProps,
                                          loading,
                                        }) => (
                                          <div>
                                            <input
                                              id="searchTextField"
                                              {...getInputProps({
                                                placeholder:
                                                  "Search Places ...",
                                                className:
                                                  "location-search-input form-control inputStyle login-signup-screen",
                                              })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                              {loading && <div>Loading...</div>}
                                              {suggestions.map((suggestion) => {
                                                const className =
                                                  suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                  ? {
                                                      backgroundColor:
                                                        "#41b6e6",
                                                      cursor: "pointer",
                                                    }
                                                  : {
                                                      backgroundColor:
                                                        "#ffffff",
                                                      cursor: "pointer",
                                                    };
                                                return (
                                                  <div
                                                    {...getSuggestionItemProps(
                                                      suggestion,
                                                      {
                                                        className,
                                                        style,
                                                      }
                                                    )}
                                                  >
                                                    <span>
                                                      {suggestion.description}
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        )}
                                      </PlacesAutocomplete>
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      Address<span className="required">*</span>
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "addressUserInfo");
                                        }}
                                        value={addressUserInfo}
                                      />
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      Address 1
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "address1UserInfo");
                                        }}
                                        value={address1UserInfo}
                                      />
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      City<span className="required">*</span>
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "cityUserInfo");
                                        }}
                                        value={cityUserInfo}
                                      />
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      State<span className="required">*</span>
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "stateUserInfo");
                                        }}
                                        value={stateUserInfo}
                                      />
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      Zip Code
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "zipCodeUserInfo");
                                        }}
                                        value={zipCodeUserInfo}
                                      />
                                    </div>
                                    <label
                                      className="label_text"
                                      htmlFor="inputEmailAddress"
                                    >
                                      Country<span className="required">*</span>
                                    </label>
                                    <div className="form-group">
                                      <input
                                        className="form-control col-12 login-signup-screen"
                                        name="report.granularity"
                                        id="report.granularity"
                                        onChange={(e) => {
                                          onChangeInput(e, "countryUserInfo");
                                        }}
                                        value={countryUserInfo}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group text-center col-md-12">
                                    <button
                                      className="primary_btn_"
                                      style={{
                                        width: "15%",
                                        position: "relative",
                                        left: "43%",
                                      }}
                                      disabled={loadingEditUserInfo}
                                      type="submit"
                                    >
                                      {loadingEditUserInfo ? (
                                        <>
                                          <span className="spinner-border spinner-border-sm mr-1"></span>
                                        </>
                                      ) : (
                                        <>Save</>
                                      )}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  // userLogin: () => dispatch(userLogin())
  userLogin: bindActionCreators(userLogin, dispatch),
  userLogOut: bindActionCreators(userLogOut, dispatch),
  createEmployer: bindActionCreators(createEmployer, dispatch),
  createEmployee: bindActionCreators(createEmployee, dispatch),
  forgotPassword: bindActionCreators(forgotPassword, dispatch),
  resetPassword: bindActionCreators(resetPassword, dispatch),
  onLoginSuccess: (payload) => dispatch(loginSuccess(payload)),
  logout: bindActionCreators(logout, dispatch),
  getAllCategories: bindActionCreators(getAllCategories, dispatch),
  verifyEmail: bindActionCreators(verifyEmail, dispatch),
  verifyPhone: bindActionCreators(verifyPhone, dispatch),
  getUserInformation: bindActionCreators(getUserInformation, dispatch),
  updateUserInformation: bindActionCreators(updateUserInformation, dispatch),
  addUserInformation: bindActionCreators(addUserInformation, dispatch),
  getUserTypeList: bindActionCreators(getUserTypeList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
