import React from "react";

export default function daysFieldGenerator(fieldMetaData, value, onChange) {
    return (
        <>
            <label>
                <b>{fieldMetaData.day}</b>
            </label>
            <div className="row my-5">
                <div className="col-6">
                    <label>
                        Start Time
                    </label>
                    <input
                        type="text"
                        name={fieldMetaData.entityAttributeFieldKey}
                        //defaultValue={fieldMetaData.day}
                        value={fieldMetaData.startTime}
                        className="form-control col-12"
                        disabled={true}
                    />
                </div>
                <div className="col-6">
                    <label>
                        End Time
                    </label>
                    <input
                        type="text"
                        name={fieldMetaData.entityAttributeFieldKey}
                        //defaultValue={fieldMetaData.day}
                        value={fieldMetaData.endTime}
                        className="form-control col-12"
                        disabled={true}
                    />
                </div>
            </div>

        </>
    );

    // We have to add more type based on requirement
}
